import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcHtmlProcessorEntry extends ControlledReactComponent {
    constructor(props) {
        super(props);

        if (!this.props.htmlProcessor || !this.props.wfCtrl || !this.props.id_child || !this.props.parent) {
            console.error(
                this.constructor.name, 
                "An obligatory props-object is missing!", 
                "(htmlProcessor, wfCtrl, id_child)"
            );
        }
    }

    getHtmlProcessor() {
        return(this.props.htmlProcessor);
    }

    getCrcMountedEntryList() {
        return(this.getHtmlProcessor()?.getCRC_entryList());
    }

    getCrcMountedEntryListFrame() {
        return(this.getHtmlProcessor()?.getCRC_entryList()?.getCrcMountedFrame());
    }

    // function, designed to override
    renderEntry(edb) { 
        return("");
    }

    /** **/
    isSelected_inParentEntryList() {
        return(!!this.getHtmlProcessor()?.getCRC_entryList()?.isSelectedEntry(this.getIdEntry()));
    }

    getActiveIdEntry() {
        // if an alternative "getActiveIdEntry-Function" was provided ... use the alternative
        if (this.props.func_getActiveIdEntry) {
            return(this.props.func_getActiveIdEntry());
        }
        
        return(this.getHtmlProcessor().getActiveIdEntry());
    }

    isActiveEntry() {
        return(this.getIdEntry() === this.getActiveIdEntry());
    }

    // Checks, if the "id_entry" is always adjusted to fit the "active" entry of
    // an htmlProcessor.
    isAlwaysLinkedToActiveEntry() {
        return(String(this.props.id_child).substr(0, 7) === "ACTIVE_");
    }

    getIdEntry() {
        if (this.isAlwaysLinkedToActiveEntry()) return(this.getActiveIdEntry());    
        
        // alternatively ... we assume that the "id_child" is equal to the "id_entry"
        return(this.props.id_child);
    }

    getEdb() {
        var edb = this.getHtmlProcessor().getEntryDataBundle(this.getIdEntry()); 
        if (!edb) edb = this.getHtmlProcessor().getEntryDataBundle_Empty(this.getIdEntry());
        return(edb);
    }

    render() {    
        return(this.renderEntry(this.getEdb()));                
    }
}

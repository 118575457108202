import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class CrcNavHeader_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this.state.bNavExpanded = true;
        this.state.bNavLock = true;
    }

    componentDidMount() {
        super.componentDidMount();

        // Notification, Profile, Translation, Settings Dropdown & Horizontal Dropdown
        $(
            ".notification-button, .profile-button, .translation-button, .dropdown-settings, .dropdown-menu"
        ).dropdown({
            inDuration: 300,
            outDuration: 225, 
            constrainWidth: false,
            hover: false,
            gutter: 0,
            coverTrigger: false,
            alignment: "right"
            // stopPropagation: false
        });
    }
    
    render() {        
        var arrResult = [];

        arrResult.push(
            <header key={this.getIdDOM()} className={"page-topbar"} id={this.getIdDOM()}>
        
                <div className={"navbar navbar-fixed fab-header-height"}> 
                    <nav className={
                          "navbar-main navbar-color nav-collapsible navbar-dark gradient-45deg-blue-grey-blue no-shadow"
                        + (this.state.bNavExpanded?" nav-expanded":" nav-collapsed")
                        + (this.state.bNavLock?" sideNav-lock nav-lock":"")
                        }>
                        <div className={"nav-wrapper"}>
                            <div className={"header-search-wrapper hide-on-med-and-down"}><i className={"material-icons"}>search</i>
                                <input className={"header-search-input z-depth-2 fab-swapping"} type="text" id="search-all" name="Search" placeholder="search everywhere ..."/>                
                                <div id="searchresults_01"></div>
                                <div className={"row fab-search invisible"}>
                                    <div className={"card card-default border-radius-6 fixed-width"} style={{ margin: "0px", width: "inherit", height: "inherit" }}>
                                        <div className={"card-content p-0;"} style={{ width: "inherit", height: "inherit", padding: "0px", margin: "0px" }}>        
                                            <div className={"fab-perfect-scrollbar-y2"} style={{ width: "inherit", height: "inherit" }}>
                                                <ul id="search-div-section-DUMMY" style={{ width: "inherit", height: "inherit" }}>                            
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                                                    
                            <ul className={"navbar-list right"}>
                                    <li className={"hide-on-med-and-down"}><a className={"waves-effect waves-block waves-light translation-button"} data-target="translation-dropdown"><span className={"flag-icon flag-icon-gb"}></span></a></li>              
                                    <li className={"hide-on-large-only"}><a className={"waves-effect waves-block waves-light search-button"}><i className={"material-icons"}>search</i></a></li>
                                    <li><a className={"waves-effect waves-block waves-light notification-button"} data-target="notifications-dropdown"><i className={"material-icons"}>notifications_none<small className={"notification-badge"}>13</small></i></a></li>              
                                    <li><a className={"hide-on-large-only waves-effect waves-block waves-light toggle-fullscreen"}><i className={"material-icons"} style={{ position: "relative", top: "12px" }}>settings_overscan</i></a></li>                              
                                    <li>
                                        <a 
                                            className={"waves-effect waves-block waves-light profile-button avatar-online"} 
                                            data-target="profile-dropdown" 
                                            id="div_user_active_for_menu" 
                                            style={{  display: "flex", flexWrap: "no-wrap", alignItems: "center" }}>
                                                <div style={{ background: "rgb(0,230,118)", width: "5px", height: "5px" }}></div>
                                                <i className={"material-icons"}></i>
                                        </a>
                                        <div id="users-div-section" style={{ display: "none" }}></div>
                                    </li>                
                            </ul>
                
                            <ul className={"dropdown-content"} id="translation-dropdown">
                                <li><a className={"grey-text text-darken-1"}><i className={"flag-icon flag-icon-gb"}></i> English</a></li>
                                <li><a className={"grey-text text-darken-1"}><i className={"flag-icon flag-icon-fr"}></i> French</a></li>
                                <li><a className={"grey-text text-darken-1"}><i className={"flag-icon flag-icon-cn"}></i> Chinese</a></li>
                                <li><a className={"grey-text text-darken-1"}><i className={"flag-icon flag-icon-de"}></i> German</a></li>
                            </ul>
        
                            <ul className={"dropdown-content"} id="notifications-dropdown">
                                <li>
                                    <h6>NOTIFICATIONS<span className={"new badge"}>0</span></h6>
                                </li>
                                <li className={"divider"}></li>
                                <li><a className={"grey-text text-darken-2"}><span className={"material-icons icon-bg-circle cyan small"}>add_shopping_cart</span> A new order has been placed!</a>
                                    <time className={"media-meta"} datetime={"2015-06-12T20:50:48+08:00"}>2 hours ago</time>
                                </li>
                                <li><a className={"grey-text text-darken-2"}><span className={"material-icons icon-bg-circle cyan small"}>add_shopping_cart</span> A new order has been placed!</a>
                                    <time className={"media-meta"} datetime={"2015-06-12T20:50:48+08:00"}>2 hours ago</time>
                                </li>
                            </ul>
                
                            <ul className={"dropdown-content"} id="profile-dropdown">
                                <li><a className={"grey-text text-darken-1"} onClick={ (event) => { g_wfCtrl.editUser2(); }}><i className={"material-icons"}>person_outline</i> Profile</a></li>
                                <li><a className={"grey-text text-darken-1"} href="" onClick={(event) => g_wfCtrl.signOut() }><i className={"material-icons"}>keyboard_tab</i> Logout</a></li>
                            </ul>
                        </div>
                
                        <nav className={"display-none search-sm"}>
                            <div className={"nav-wrapper"}>
                                <form>                
                                    <div className={"input-field"}>
                                        <input className={"search-box-sm"} type="search" required=""/>
                                        <label className={"label-icon"} htmlFor="search">
                                            <i className={"material-icons search-sm-icon"}>search</i>
                                        </label>
                                        <i className={"material-icons search-sm-close"}>close</i>
                                    </div>
                                </form>
                            </div>
                        </nav>
                    </nav>
                </div>
            </header>
        );

        return(arrResult);
    }    
}
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcFamilyMissionObjectives_Entry_01 from "./fabbi_crc_objectives_entry_01.jsx";
import CrcExt_GroupOrganizer from "./fabbi_crc_ext_group_organizer.jsx";
import CrcExt_Scrollbar from "./fabbi_crc_ext_scrollbar.jsx";

export default class HtmlProcessor_Objectives extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        var sb = new CrcExt_Scrollbar({
            container: "#objectives-div-scrollarea",
            options:   { suppressScrollX: true }
        });

        var org = new CrcExt_GroupOrganizer();

        props.HtmlElementIdPrefix = "objectives";
        props.crcExtensions_entryList = [ /* ...props.crcExtensions_entryList, */ org, sb ];

        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcFamilyMissionObjectives_Entry_01
        }

        // setup writeSortData-Function
        org.setFunc_writeSortData(
            function(underlyingCRC, objSortData) {
                underlyingCRC.getEdb().dataset["ts_sort"] = objSortData;

                var szId_Entry = underlyingCRC.getIdEntry();    
                // console.log("SORTTEST", this.getUnderlying().constructor.name);
                // this.getUnderling() returns the "CrcEntryList_01"
                // the CrcEntryList_01-Class has a function "getHtmlProcessor"
                // and the HtmlProcessor has "getQP"
                var qryP = this.getUnderlying().getHtmlProcessor().getQP(); 
                var dataset_chabuf = {
                    ts_sort: objSortData
                }; // dataset-change-buffer

                // console.log(this.constructor.name, "writeSortData", szId_Entry, "dataset_chabuf", dataset_chabuf);
                if (!dataset_chabuf || dataset_chabuf.length <= 0 || !qryP || !szId_Entry) {
                    // no update!
                    console.warn(this.constructor.name, "writeSortData", szId_Entry, "dataset_chabuf", dataset_chabuf, "update impossible!");
                } else {                
                    qryP.updateDoc(szId_Entry, dataset_chabuf);
                }
   
                return(true);
            }.bind(org)
        );

        // implement readSortData-Function
        org.setFunc_readSortDataObject(
            function(underlyingCRC) {
                return(underlyingCRC.getEdb().dataset["ts_sort"]);
            }
        );

        return(props);
    }
}
HtmlProcessor_Objectives.ID_DIV_SECTION     = "objectives-div-section";
HtmlProcessor_Objectives.ID_DIV_ENTRY 	    = "objectives-div-entry";
HtmlProcessor_Objectives.ID_DIV_UPPER_PART 	= "objectives-div-upper-part";
HtmlProcessor_Objectives.ID_TRG_EDIT 	    = "objectives-trg-edit";
HtmlProcessor_Objectives.ID_IMG_AVATAR 	    = "objectives-img-avatar";
HtmlProcessor_Objectives.ID_IMG_MAIN 	    = "objectives-img-main";
HtmlProcessor_Objectives.ID_TXT_NAME 	    = "objectives-txt-name";
HtmlProcessor_Objectives.ID_TXT_STATUS 	    = "objectives-txt-status";
HtmlProcessor_Objectives.ID_TXT_COMMENT 	= "objectives-txt-comment";
HtmlProcessor_Objectives.ID_TXT_TIME 	    = "objectives-txt-time";
HtmlProcessor_Objectives.ID_TS_SORT 	    = "objectives-ts-sort";

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import * as FabGM from "./init_google_maps.js";
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

// ************************************************************************
class StdOverlay {
    /** @constructor */
    constructor(bounds, map, props) {
        // Initialize all properties.
        this.map_ = undefined;
        this.bounds_ = bounds;                    
        this.bDisplayDetails = false;
        this._props = props;
    
        // Define a property to hold the image's div. We'll
        // actually create this div upon receipt of the onAdd()
        // method so we'll leave it null for now.
        this.div_ = null;

        // console.log(this.constructor.name, this._props);
    
        // Explicitly call setMap on this overlay.
        this.setMap(map);   // only if the "map"-parameter is not null, the overlay will be visible!
    }

    setMap(map) {
        if (map !== this.map_) {
            this.map_ = map;
            super.setMap(map);
        }
    }

    setAmount(fAmount) {
        this._props.fAmount = fAmount;

        if (!!this.map_) {
            this.draw(); // draw immediately
        }
    }

    /**
     * onAdd is called when the map's panes are ready and the overlay has been
     * added to the map.
     */
    onAdd() {
        // console.log("onAdd");
        var div = document.createElement('div');
        div.classList.add("z-depth-2");
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';    
        div.style.backgroundColor = 'red';
        div.style.opacity = '50%';
        div.style.height = 'auto';
        div.style.width = 'auto';
        div.style.borderRadius = '50%';
        
        div.style.display = 'flex';
        div.style.alignItems = 'center';
        div.style.justifyContent = 'center';

        // <div style="width: 50px; height: 50px; border-radius: 50%;">You can put text in here.....</div>
        // div.style.height = 'auto';
        // div.style.width = '10px';

        /*
        // Create the img element and attach it to the div.
        var img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';                
        div.appendChild(img);
        */
        // div.innerHTML += "<b style='font-size: 2rem; color: red;'>Fabbis Realm</b>";                            
    
        this.div_ = div;
    
        // Add the element to the "overlayLayer" pane.
        var panes = this.getPanes();
        // panes.overlayLayer.appendChild(div);
        panes.markerLayer.appendChild(div);
    }

    displayDetails(bDisplayDetails) {
        if (bDisplayDetails !== this.bDisplayDetails_) {
            this.bDisplayDetails_ = bDisplayDetails;
            this.draw();
        }
    }

    getAmount() {
        return(this._props.fAmount);
    }

    draw() {
        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        var overlayProjection = this.getProjection();

        if (!overlayProjection) return; // cancel!

        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        var sw, ne;

        if (this.bDisplayDetails_) {
            this.div_.style.backgroundColor = 'green';
        } else {
            this.div_.style.backgroundColor = 'red';
        }
        
        // feature.center, 
        if (!this.bounds_.getSouthWest) {
            sw = overlayProjection.fromLatLngToDivPixel(this.bounds_);
            ne = sw;
        } else {
            var fArea =  this.getAmount();
            var fRadius = Math.sqrt(fArea/3.14) * 5;

            // console.log("RADIUS", fRadius);

            var newBounds = new google.maps.LatLngBounds(
                google.maps.geometry.spherical.computeOffset(this.bounds_.getSouthWest(), fRadius , 225),   // Southwest
                google.maps.geometry.spherical.computeOffset(this.bounds_.getNorthEast(), fRadius, 45)     // Northeast                            
            ); 

            // console.log("NewBounds", newBounds);

            // console.log("BouNDS");
            sw = overlayProjection.fromLatLngToDivPixel(newBounds.getSouthWest()); 
            ne = overlayProjection.fromLatLngToDivPixel(newBounds.getNorthEast());                                               
        }                                   

        // Resize the image's div to fit the indicated dimensions.
        var div = this.div_;                    
        div.style.left = sw.x + 'px';                    
        div.style.top = ne.y + 'px';
        div.style.height = Math.abs(sw.y - ne.y) + 'px';
        div.style.width = Math.abs(sw.x - ne.x) + 'px';

        if (this.bDisplayDetails_) {
            div.innerHTML =                         
                "<div class='animate fadeUp' style='opacity: 100%; width: 100%; text-align: center; font-size: 1rem; color: black;z-index: 99;'>" + 
                "<span class='z-depth-2' style='background-color: yellow; border-radius: 10%;'>" +
                Number(this.getAmount() / 1000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2}) +
                "</span>" +
                "<br>" + this._props.szPLZ +
                "</div>";
        } else {
            div.innerHTML = "";
        }
    }
                    
    // The onRemove() method will be called automatically from the API if
    // we ever set the overlay's map property to 'null'.
    onRemove() {
        console.log("onRemove");

        this.div_.parentNode.removeChild(this.div_);
        this.div_ = null;
    }    
}
// ************************************************************************


export default class CrcGoogleMain_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    
        this._googleMap = undefined;
        this._dataMap = new StdMap();       
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
    
        this.initMapWhenReady();
    }

    render() {      
        return(
            <div id={this.getIdDOM()} style={{width: "inherit", height: "inherit"}}>
                GoogleMap - Datainput: <a onClick={() => { 
                    console.log("GOOGLE", this.getParent().getDOMElement());
                    var cl = this.getParent().getDOMElement().classList;
                    var style = this.getParent().getDOMElement().style;

                    if (cl.contains("fab-full-screen-size")) {
                        cl.remove("fab-full-screen-size");
                    } else {
                        cl.add("fab-full-screen-size");
                        setTimeout(() => { window.print(); cl.remove("fab-full-screen-size"); }, 0);
                    }

                    /*
                    // if (style.top && style.top !== "0px") {
                        style.top = "0px";
                        style.left = "0px";
                        style.width="100vw";
                        style.height="100vh";
                        style.maxHeight="100%";
                    // } 
                    */
                    /* else {
                        style.top = "5vh";
                        style.left = "5vw";
                        style.width="95vw";
                        style.height="95vh";
                        style.maxHeight="85%";
                    }*/

                    /*
                    // console.log("BUTTON");
                    var child = document.getElementById("modal_map"); // .parentNode.removeChild(document.getElementById("modal_map"));
                    document.getElementById("print").style.width = "100vw";
                    document.getElementById("print").style.height = "100vh";
                    document.getElementById("print").appendChild(child);
                    document.getElementById("print").classList.remove("hide");
                    document.getElementById("no_print").classList.add("hide");
                    */
                }}>print</a> <input type="file" id="csv-test"/>
                <div id="modal_map" style={{background: "red", width: "100%", height: "calc(100% - 2rem)"}}>
                </div>
            </div>
        );
    }


    initMapWhenReady() {
        if (typeof google.maps.Map === "function") {
            this.initMap();
        } else {
            // wait on and retest!
            setTimeout(() => { this.initMapWhenReady(); }, 100);
        }
    }

    loadGeoJson() {
        this._googleMap.data.setStyle({
            // fillColor: 'white',
            fillOpacity: 0,     // completely transparent
            strokeOpacity: 0.3,
            strokeWeight: 0.5
        });

        // clean up the "features" of the map!
        this._googleMap.data.forEach((feature) => {
            this._googleMap.data.remove(feature);
        });

        console.log(this.constructor.name, "loadGeoJson");
        this._googleMap.data.loadGeoJson(
            FabStdBro.getEffectiveUrlViaCSS("fabbi_styles", "geojson-plz3"), 
            undefined,
            (arrFeature) => {
                arrFeature.filter((feature) => {
                    var szPLZ = feature.getProperty("plz");  
                    return(!!this._dataMap.get(szPLZ));
                }).
                forEach((feature) => {
                    var arrP = [];
                    feature.bounds = new google.maps.LatLngBounds();
                    // create the "bounds" of the feature ... and prepare the coordinates
                    // of the polygon
                    feature.getGeometry().forEachLatLng((coord) => {
                        feature.bounds.extend(coord);
                        arrP.push(coord);
                    }); 

                    // activate the polygon
                    feature.polygon = new google.maps.Polygon(
                        { paths: [ arrP ] } 
                    );
                    feature.center = feature.polygon.getApproximateCenter();
                    /*
                    var ne = feature.bounds.getNorthEast();
                    var sw = feature.bounds.getSouthWest();
                    var lng_dist = -(ne.lng() - sw.lng());
                    var lng_step = lng_dist/10;
                    var lat_dist = -(ne.lat() - sw.lat());
                    var lat_step = lat_dist/10;

                    var tmp;
                    console.log("CHECK POLY!", feature.getGeometry().getType(), "lng", ne.lng(), "lat", ne.lat());
                    var poly = new google.maps.Polygon( {paths: arrP } ); // feature.getGeometry()); //  { paths:  
                        // arrP });
                    console.log("Poly", poly);
                    // var poly = feature.getGeometry();
                    for (var y=0; y < 10; ++y) {   
                        for (var x=0; x < 10; ++x) {
                            tmp = new google.maps.LatLng(ne.lat()+(lat_step*x), ne.lng()+(y*lng_step)); 
                            console.log("CHECK POLY!", tmp.lat(),tmp.lng(), "ne lat/lng", ne.lat(), ne.lng(), "sw lat/lng", sw.lat(), sw.lng());
                            if (google.maps.geometry.poly.containsLocation(tmp, poly)) {
                                console.log("in the poly!");
                            }
                        }
                    }
                    asdasd.xas = 12;
                    */


                    // OLD IMPlEMENTATION
                    // mapPLZ2.set(feature.getProperty("plz"), feature);
            
                    /*
                    var marker = new google.maps.Marker({
                        position: feature.polygon.getApproximateCenter(),
                        map: map,
                        label: "Middle of " + feature.getProperty("plz"),
                        title: 'PLZ-2'
                    });
                    */
                    var szId = feature.getProperty("plz");
                    feature.overlay = new StdOverlay(
                        new google.maps.LatLngBounds(
                            feature.center, 
                            google.maps.geometry.spherical.computeOffset(
                                feature.center, 1000, 45)), 
                        this._googleMap,  // DISABLED     // map,
                        this._dataMap.get(szId)
                        );                        

                    // console.log("feature plz", feature.getProperty("plz"));
                });
                // console.log("features", arrFeature, mapPLZ2.get("59").bounds);
        });
    }

    initMap() {                        
        Object.setPrototypeOf(StdOverlay.prototype, new google.maps.OverlayView());

        var domElem = document.getElementById("modal_map");
        console.log("INITIALIZED GOOGLE MAPS ELEMENT", "Map-DIV", domElem, typeof google.maps.Map);
        this._googleMap = new google.maps.Map(domElem, {
          center: {lat: 51, lng: 8},
          zoom: 7
        });
        FabGM.prepGoogleMaps(); // prepare some extras!

        var geocoder = new google.maps.Geocoder();

        // var myLatLng = { lat: 51.5538912, lng: 7.6860143 };

        geocodeAddress(geocoder, "44137 Dortmund, Freistuhl 2", this._googleMap, {
                label: "SpkDo",
                title: "Sparkasse Dortmund"
            });

        /*
        var marker = new google.maps.Marker({
            position: myLatLng,
            map: this._googleMap,
            label: "SpkDo",
            title: 'Sparkasse Dortmund'
        });      
        */

        function geocodeAddress(geocoder, address, resultsMap, objMarkerDataToSet) {
            geocoder.geocode({'address': address}, function(results, status) {
                if (status === 'OK') {
                    resultsMap.setCenter(results[0].geometry.location);
                    console.log("GEOCODE OF ADDRESS", address, "MAP", resultsMap, "LENGTH", results.length, "BOUNDS", results[0].geometry.bounds);
                    objMarkerDataToSet.map = resultsMap;
                    objMarkerDataToSet.position = results[0].geometry.location;
                    var marker = new google.maps.Marker(objMarkerDataToSet);                    
                } else {
                    alert('Geocode was not successful for the following reason: ' + status);
                }
            });
        }                        

        // ****************************************************************************************************************************************
        if (document.getElementById("csv-test")) 
            document.getElementById("csv-test").addEventListener("change", function(inp) {
                console.log(inp.target.files[0]);

                var reader = new FileReader();        
                reader.onload = (event) => {        
                    var rows = FabStd.csvFileParse(event.target.result);

                    var szPLZ;
                    var szPLZ2;
                    var fAmount;
                    var szId;
                    rows.forEach((row) => {
                        szId = row[0];
                        szPLZ = row[0];                            
                        szPLZ2 = String(row[0]).padStart(2, '0');
                        fAmount = Number.parseFloat(row[1]);                               
                        
                        console.log("PLZ2, Amount", szPLZ2, fAmount);

                        /*
                        feature = mapPLZ2.get(szPLZ2);
                        if (!!feature) {
                            feature.overlay.setAmount(fAmount);                                    
                            feature.overlay.setMap(map);    // ensure visibility                                    
                        }
                        */

                        this._dataMap.set(szId, {
                            szPLZ,
                            fAmount, 
                            szPLZ2
                        });
                    });

                    this.loadGeoJson();
                };     
                reader.readAsText(inp.target.files[0]);
            }.bind(this), false);
            
            // ****************************************************************************************************************************************

            // mapPLZ2.set(feature.plz, feature);
            // console.log("feature loaded", feature);


        this._googleMap.data.addListener('mouseover', (event) => {
            // map.data.revertStyle();
            // map.data.overrideStyle(event.feature, {strokeWeight: 8});                
            if (!!event.feature && !!event.feature.overlay) {           
                // console.log("EVENT-OUT", event.feature, event.feature.overlay.fAmount_);     
                this._googleMap.data.overrideStyle(event.feature, { fillColor: 'green', fillOpacity: 0.3 });            
                event.feature.overlay.displayDetails(true);
            }
        });

        this._googleMap.data.addListener('mouseout', (event) => {
            // map.data.revertStyle();
            // map.data.overrideStyle(event.feature, {strokeWeight: 8});                
            if (!!event.feature && !!event.feature.overlay) {           
                // console.log("EVENT-OVER", event, "AMOUNT", event.feature.overlay.fAmount_);   
                // map.data.overrideStyle(event.feature, {});
                this._googleMap.data.revertStyle();      
                event.feature.overlay.displayDetails(false);
            }
        });              
          
        /*
        var ctaLayer = new google.maps.KmlLayer({
            url: "https://www.fabcom.eu" + FabStdBro.getEffectiveUrlViaCSS("fabbi_styles", "kml-plz2"),
            map: map
          });
        */
        // console.log("FEATURE 59", mapPLZ2.get("59"));            

        // var proto = ;
        // console.log("PROTOTYPE", proto);
        
        // USGSOverlay.prototype = new google.maps.OverlayView();

        /*
        var bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(51.5, 7),
            new google.maps.LatLng(52, 7.5));
      
        // The custom USGSOverlay object contains the USGS image,
        // the bounds of the image, and a reference to the map.
        let overlay = new StdOverlay(bounds, map);
        */
        
        /*
        google.charts.load("current", {packages:['corechart']});
        google.charts.setOnLoadCallback(drawChart);

        function drawChart() {
          var data = google.visualization.arrayToDataTable([
            ["Element", "Density", { role: "style" } ],
            ["Copper", 8.94, "#b87333"],
            ["Silver", 10.49, "silver"],
            ["Gold", 19.30, "gold"],
            ["Platinum", 21.45, "color: #e5e4e2"]
          ]);
    
          var view = new google.visualization.DataView(data);
          view.setColumns([0, 1,
                           { calc: "stringify",
                             sourceColumn: 1,
                             type: "string",
                             role: "annotation" },
                           2]);
    
          var options = {
            title: "Density of Precious Metals, in g/cm^3",
            width: 150,
            height: 100,
            bar: {groupWidth: "95%"},
            legend: { position: "none" },
          };
          var chart = new google.visualization.ColumnChart(document.getElementById("sta")); // overlay.div_);
          chart.draw(view, options);
        }
        */
    }        
}
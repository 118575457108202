import qryProcessorStatistics from "./fabbi_qry_processor_statistics.js";

export default class qryProcessorStatistics_Objectives extends qryProcessorStatistics {    
    constructor(qryProcessor_Parent) {
        super(qryProcessor_Parent);
    }
    
    init() {
        super.init();  
        
        // this.m_dataObj = new Object();
        Object.defineProperty(
            this.m_data, 
            "count_OK", 
            { value: 0, enumerable: true, writable: true }
        );
        Object.defineProperty(
            this.m_data, 
            "count_FD", 
            { value: 0, enumerable: true, writable: true }
        );
        Object.defineProperty(
            this.m_data, 
            "count_IP", 
            { value: 0, enumerable: true, writable: true }
        );
    }

    tsaUpdates_processEntry_Level2(szId, szState, dataset, dataset_old) {
        // console.log(this.constructor.name, "tsaUpdates_processEntry_Level2", szState);
        var szTmp1 = "count_" + dataset.status;
        var szTmp2 = "";
        
        if (szState !== "added") {
            szTmp2 = "count_" + dataset_old.status;
        }
        
        switch(szState) {
            case "added":                
                this.m_data[szTmp1] = this.m_data[szTmp1] + 1;
                break;
                
            case "modified":
                this.m_data[szTmp1] = this.m_data[szTmp1] + 1;
                this.m_data[szTmp2] = this.m_data[szTmp2] - 1;
                break;
                
            // case "removed":      ==> should never been seen here ?
            case "deleted":
                this.m_data[szTmp1] = this.m_data[szTmp1] - 1;
                break;
                
            default:
                break;
        }

        // console.log(this.constructor.name, "STAT_OBJ", szState, "NEW", szTmp1, "OLD", szTmp2, this.m_data, "Initialized:", this.m_bInitialized);
    }
}

import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";

export default class qryProcessor_UserUsersAuthorized_FamilyReferences extends qryProcessor_CloudFirestore {
   /**
    * Constructor for Promise Chain
    * @constructor
    * @param {workflowControl} wfCtrl The overwatching workFlow-Control.
    * @param {qryProcessor?} triggering_qryProcessor The qryProcessor that "triggered" the start of this qryProcessor.
    * @param {String} szUserId The Id of the User (DocumentId) whose authorizations are looked up (authorizer)
    * @param {String} szUserAuthorizedId The Id of the authorized User (authorizee)
    */
    constructor(props) {
        super({
                bDirectlyRunProcessQueryAfterInitialization: false,
                ... props
            }
        );
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;        
        this.m_szUserAuthorizedId = props.szUserAuthorizedId;
        
        // console.log(this.getClassName(), "constructor", this.m_szUserId);        
    }

    getClassName() {
        return("qryProcessor_UserUsersAuthorized_FamilyReferences");    // to FIX the classname!
    }
    
    getCollectionPath() {
        return('users/' + this.m_szUserId + '/users_authorized/' + this.m_szUserAuthorizedId + '/family_references');
    }
}

import React from 'react';
import HtmlProcessor_Scripts_01 from './fabbi_htmlprocessor_scripts_01.js';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import { Timestamp } from "firebase/firestore";

export default class CrcModalScripts_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
        
        this._qryP = this.getWfCtrl().m_listenerUserIndividualities;
        this._id_HP = "";
        this._id_htmlSuperSection = "script_01-div-section";

        this._crcEditor = props.crcEditor|props.crcMountedEditor;
    }

    isClickAwayAllowed() {
        var hp = this.getCRC(this._id_HP);
        if (!hp) return(true);

        var cd = hp.getCustomData();
        console.debug(this.constructor.name, "isClickAwayAllowed", "cd", cd);
        return(!cd.clickAwayProhibited_entryList || cd.clickAwayProhibited_entryList <= 0);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        // console.debug(this.constructor.name, "componentDidUpdate");
        // this.getWfCtrl().getCRC(this._id_HP).buildAll();
        // console.debug(this.constructor.name, "componentDidUpdate", "buildAll");
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._qryP.removeHtmlProcessorViaDom(this._id_HP);
        this._qryP = undefined;
    
        this.getWfCtrl().removeHidden(this._id_HP);
    }

    componentDidMount() {
        super.componentDidMount();

        var htmlProcessorProps = { 
            id_htmlSuperSection: this._id_htmlSuperSection,
            customData: {
                parentModal: this 
            }
        };
        
        this._id_HP = 
            this.getWfCtrl().addHidden(
                HtmlProcessor_Scripts_01, 
                { 
                    leadingDataPool: this._qryP.getPrivateDataPool(), 
                    ...htmlProcessorProps
                }
            );
        this._qryP.addHtmlProcessorViaIdDom(this._id_HP); 
        
        // console.log(this.constructor.name, "componentDidMount", "planRun"); // , this._htmlP.getId_Div_SuperSection());
        this._qryP.planRun();
    }

    render() {      
        // console.log(this.constructor.name, "htmlProcessor", this._htmlP, "getId_Div_SuperSection", this._htmlP.getId_Div_SuperSection);
 
        var objDum = { 
            type: "gdtTs_script",  
            name: "new ts Script",
            code: this._crcEditor.getPlainText()
        };

        console.log("script_01", "objDum", objDum, this.getIdDOM(), this._id_htmlSuperSection);
        
        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"} style={{backgroundColor: "wheat"}}>
                    <div className={"fab-headline"}>
                        <span>Scripts</span>         
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <div 
                            style={{width: "100%", maxHeight: "400px", overflowY: "auto" }} 
                            key={this._id_htmlSuperSection}
                            id={this._id_htmlSuperSection}>    
                        </div>
                    </div>
                    <div>
                        <a className={"btn waves-effect waves-light send"} onClick={() => {
                            console.log(this.constructor.name, "trying to SAVE", objDum);
                            objDum.modified_by = this.getWfCtrl().getMainUserId();
                            objDum.added_by = this.getWfCtrl().getMainUserId();
                            objDum.ts_modified = Timestamp.now();
                            objDum.ts_added = Timestamp.now();

                            this._qryP.addDoc(objDum, (result) => {
                                console.log(this.constructor.name, "SAVE-Action", result);
                            })
                        }}>Save script</a> 
                    </div>
                </div>
            </form>
        );
    }
}
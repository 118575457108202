import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import { Timestamp } from "firebase/firestore";

export default class CrcTimeLeftDisplay_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this.handleTimeout = null;
    }

    componentDidMount() {
        super.componentDidMount();

        // let's go!
        this._restartTimer();
    }

    _resetDeadline() {
        this.state.datDeadline = FabStd.convertTimestampToDate(this.props.deadline, this.props.deadline);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.deadline !== this.props.deadline) {
            this._resetDeadline();
            this._restartTimer();
        }
    }
    
    componentWillUnmount() {
        this._clearNextTimeout();
    }

    _recalcStateData() {
        var nDiff_msec = 0;
        var szTimeLeft = "";
  
        if (!this.state.datDeadline) {
            this._resetDeadline();
        }

        if (this.state.datDeadline) {
          nDiff_msec = this.state.datDeadline - new Date();
          var nDivSec = 1000;
          var nDivMin = 60000;
          var nDivHou = 60 * nDivMin;
          var nDivDay = 24 * nDivHou;
          var nDivWee = 7 * nDivDay;
          var nDivMon = 30 * nDivDay;
          var nDivYea = 365 * nDivMon;
  
          if (nDiff_msec < 0) {
            szTimeLeft = "out";
          } else if (nDiff_msec < nDivMin * 2) {
            // display Seconds
            szTimeLeft = Math.floor(nDiff_msec / nDivSec) + "s";
          } else if (nDiff_msec < nDivHou * 2) {
            // display Minutes
            szTimeLeft = Math.floor(nDiff_msec / nDivMin) + "m";
          } else if (nDiff_msec < nDivDay * 2) {
            // display Hours
            szTimeLeft = Math.floor(nDiff_msec / nDivHou) + "h";
          } else if (nDiff_msec < nDivWee * 2) {
            // display Days
            szTimeLeft = Math.floor(nDiff_msec / nDivDay) + " Days";
          } else if (nDiff_msec < nDivMon * 2) {
            // display Weeks
            szTimeLeft = Math.floor(nDiff_msec / nDivWee) + " Weeks";
          } else if (nDiff_msec < nDivYea * 2) {
            // display Months
            szTimeLeft = Math.floor(nDiff_msec / nDivMon) + " Months";
          } else {
            // display Years
            szTimeLeft = Math.floor(nDiff_msec / nDivYea) + " Years";
          }
        }      

        this.state.szTimeLeft = szTimeLeft;
        this.state.nDiff_msec = nDiff_msec;
    }
  
    _restartTimer() {
        this._clearNextTimeout();
        this._startNextTimeout(1);
    }
  
    _clearNextTimeout() {
        if (!!this.handleTimeout) {
            clearTimeout(this.handleTimeout);
            this.handleTimeout = null;
        }
    }

    _startNextTimeout(millis) {
        this.handleTimeout = setTimeout(
            function() {
                // because "bind" was used, we are inside the class-orientated scope and can use "this"
                this._recalcStateData();
                this.update(); // force Update!

                if (this.state.nDiff_msec > 0) {
                    if (this.state.nDiff_msec < 2 * 60 * 1000) {
                        this._startNextTimeout(1000);
                    } else {
                        this._startNextTimeout(30 * 1000);
                    }
                }
            }.bind(this),
            millis
        );
    }  

    render() {      
        this._recalcStateData();
        return(
            <div>
            {this.state.szTimeLeft}
            </div>  
        );
    }    
}
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";

export default class qryProcessor_MembersInvited extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);                
        this.m_szFamilyId = props.szFamilyId?props.szFamilyId:props.szTriggerId;        
        
        // console.log(this.getClassName(), "constructor", this.m_szFamilyId);                    
    }

    // Overrides existing!
    getClassName() {
        return("qryProcessor_MembersInvited");    // to FIX the classname!
    }

    getFamilyId() {        
        return this.m_szFamilyId;
    }
    
    getCollectionPath() {
        return('families/' + this.m_szFamilyId + "/members_invited");
    }

    getMembers() {
        var arrMembersInvited = [];
        this.getPrivateDataPool().getDataMap().filter((entry) => {                        
            return(entry["state"] !== "deleted");
        }).forEach((entry) => {
            arrMembersInvited.push(entry["id"]);
        });
        return(arrMembersInvited);
    }

    onRun() {                
        return(super.onRun());
    }        
}

import dataPool from "./fabbi_datapool.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

export default class dataPool_qryProcessor extends dataPool {    
    constructor(parentQryProcessor, szDataPoolId) {
        super(parentQryProcessor, szDataPoolId);
    }

    initializedEntry(szIdEntry) {
        var entry = {
            id:                 !szIdEntry?"":szIdEntry,
            id_entry:           !szIdEntry?"":szIdEntry,
            id_qp:              "",
            state:              "empty",
            dataset:            {},
            dataset_old:        {},
            pending_onRun:      "n",
            pending_broadcast:  "n"
        };

        return(entry);        
    }
        
    /** @return {qryProcessor} returns the Datapool's Parent-qryProcessor */
    getQP() {
        return(this.getParent());
    }
}
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import Lib_GdtBasis from "./fabbi_lib_gdt_basis.js";
import moment from 'moment';
import jStat from 'jstat';

export default class Lib_GdtTimeseries extends Lib_GdtBasis {
    constructor() {        
        super();
    }

    gdtGetDataColumnLabels(gdt) {
        if (!gdt) return([]);

        var arrLabels = [];
        for (var idxCol=1; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
            arrLabels.push(gdt.getColumnLabel(idxCol));
        }
        return(arrLabels);
    }

    /** 
     * @param {GoogleDataTable} gdt  
     * @param {Array?} arrIdxCol Filter-Array of column-indices
    */
    gdtGetDataColumnProperties(gdt, arrIdxCol) {
        if (!gdt) return([]);

        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);

        return( 
            arrIdxCol.map((idxCol) => { return({ ... gdt.getColumnProperties(idxCol), label: gdt.getColumnLabel(idxCol) }) })
        );
    }


    // optional: funcFill
    // optional: arrIdxCol
    gdtFillNullInterimValues(gdt, arrIdxCol, funcFill) {
        if (funcFill === undefined) {
            funcFill = (gdt, idxRow, idxCol, idxFillPart, cntFillParts, valPrev, valNext) => 0;
        }

        // console.log(this.constructor.name, "gdtFillNullInterimValues", gdt.getColumnLabel(3));
        
        var idxRow2= 0;
        var valPrev = 0;
        var valNext = 0;
        var valAct = 0;
        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);

        arrIdxCol.forEach((idxCol) => {
            for (var idxRow=1; idxRow < gdt.getNumberOfRows() - 1; ++idxRow) {
                valAct = gdt.getValue(idxRow, idxCol);
                if (valAct === null) {
                    valPrev = gdt.getValue(idxRow-1, idxCol);
                    if (valPrev !== null) {
                        valNext = null;
                        for (idxRow2=idxRow+1; idxRow2 < gdt.getNumberOfRows(); ++idxRow2) {
                            valNext = gdt.getValue(idxRow2, idxCol);
                            if (valNext !== null) break;
                        }        
                        if (valNext !== null) {
                            for (var idxRow3 = idxRow; idxRow3 < idxRow2; ++idxRow3) {
                                gdt.setValue(
                                    idxRow3, idxCol, 
                                    funcFill(gdt, idxRow3, idxCol, idxRow3-idxRow, idxRow2-idxRow, valPrev, valNext)
                                );   // set 0 value        
                            }
                        }
                        // adjust count up
                        idxRow = idxRow2; 
                    }
                }
            }
        });
    }

    gdtRemoveFirstRow_ifDateColIsNull(gdt) {
        // if a "null"-Date is seen, it should be seen at rownumber 0 ... and will be deleted
        if (gdt.getNumberOfRows() > 0 && gdt.getNumberOfColumns() > 0) {
            if (!gdt.getValue(0, 0)) {
                this.gdtRemoveRows(gdt, [0]);
            }
        }
    }

    gdtRemoveRows_ifAllColsAre0orNull() {
        var arrKill = [];
        var idxCol = 0;
        for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
            for (idxCol=1; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
                if (gdt.getValue(idxRow, idxCol) === 0 || gdt.getValue(idxRow, idxCol) === null) break;
            }
            if (idxCol < gdt.getNumberOfColumns()) arrKill.push(idxRow);
        }
        this.gdtRemoveRows(gdt, arrKill);
    }

    gdtRemoveRows_ResetLastCaldenderDayOfMonth(gdt, arrIdxCol, funcModifier) {
        
        if (!funcModifier) funcModifier = (gdt, idxRow, idxCol, valActu, valPrev, valNext) => 0;

        // moment().endOf("month");
        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
    
        var datTest;
        for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
            datTest = gdt.getValue(idxRow, 0);
            // it's getDate() or date() returns the numer of the calendar-day ... for DAY is usually interpreted as the weekday
            if (datTest && datTest.getDate() === moment(datTest).endOf("month").date()) {
                // ok ... this is the last day of a period ... what to do know ?
                arrIdxCol.forEach((idxCol) => {
                    var valPrev = null;
                    var valActu = null; 
                    var valNext = null;
                    valActu = gdt.getValue(idxRow, idxCol);
                    if (valActu !== null) {
                        if (idxRow > 0) valPrev = gdt.getValue(idxRow-1, idxCol);
                        if (idxRow < gdt.getNumberOfRows()-1) valNext = gdt.getValue(idxRow+1, idxCol);

                        gdt.setValue(idxRow, idxCol, funcModifier(gdt, idxRow, idxCol, valActu, valPrev, valNext));
                    }
                });
            }
        }
    }

    gdtRemoveRows_Weekends(gdt) {
        var arrKill = [];
        for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
            var nDay = gdt.getValue(idxRow, 0).getDay();
            if (nDay <= 0 || nDay >= 6) {   // Sa/Su
                arrKill.push(idxRow);
            }
        }
        this.gdtRemoveRows(gdt, arrKill);
    }

    gdtRemoveRows_NotInDateRange(gdt, dateStart, dateEnd) {
        if (!dateStart && !dateEnd) return;
        
        var arrIdxRow_ToKill = [];
        for (var nRow=0; nRow < gdt.getNumberOfRows(); ++nRow) {
            var dateTest = moment(gdt.getValue(nRow, 0));
            if (!dateTest.isValid() || (dateStart && dateTest < dateStart) || (dateEnd && dateTest > dateEnd)) {
                arrIdxRow_ToKill.push(nRow);
            } 
        }
        this.gdtRemoveRows(gdt, arrIdxRow_ToKill);
    }

    gdtRemoveRows(gdt, arrIdxCol) {
        for (var i=arrIdxCol.length-1; i >= 0; --i) {
            gdt.removeRow(arrIdxCol[i]);
        }
    }

    gdtValues_Modify(gdt, funcModifier) {
        var arrCorrCol= FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
        var valAct;
        arrCorrCol.forEach((idxCol) => {
            for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                valAct = gdt.getValue(idxRow, idxCol);
                gdt.setValue(idxRow, idxCol, funcModifier(valAct));
            }
        });
    }

    gdtValues_CalcPeriodicalDifferences(gdt) {
        console.log(this.constructor.name, "gdtValues_CalcPeriodicalDifferences");
        // we produce DIFFERENCES from e.g. Yields ...
        var arrCorrCol= FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
        var valAct, valPrv;
        var idxRowPrv;
        arrCorrCol.forEach((idxCol) => {
            idxRowPrv = undefined;
            for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                valAct = gdt.getValue(idxRow, idxCol);
                if (valAct !== null) {
                    if (idxRowPrv !== undefined) {
                        for (var i=idxRowPrv+1; i < idxRow;++i) {
                            gdt.setValue(i, idxCol, 0);
                        }
                        // console.log(gdt.getValue(idxRow, 0), valAct-valPrv);
                        gdt.setValue(idxRow, idxCol, valAct-valPrv);    // set to difference
                    } else {
                        gdt.setValue(idxRow, idxCol, null);        // set to "empty"
                    }
                    valPrv = valAct;
                    idxRowPrv = idxRow;
                }
            }
            // set rest to empty
            for (var idxRow=idxRowPrv+1; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                gdt.setValue(idxRow, idxCol, null);        // set to "empty"
            }
        });

        console.log(this.constructor.name, "gdtValues_CalcPeriodicalDifferences", "END");
    }

    /** */
    gdtMix(gdt, gdtWeights) {
         
        var szLabelMix = "mix";
        var arrTmp = [];

        // console.log(">>>>", "gdtWeights", gdtWeights);

        for (var i=0; i < gdtWeights.getNumberOfRows(); ++i) {
            var colLabel  = 0;
            var colWeight = 1;
            var idx = gdt.getColumnIndex(gdtWeights.getValue(i, colLabel));
            if (idx >= 0) {
                arrTmp.push({ idxCol: idx, fWeight: gdtWeights.getValue(i, colWeight) });
            }
        }

        if (arrTmp.length > 0) {
            // console.log(">>>>", "arrTmp", arrTmp, "0", arrTmp[0], "props", gdt.getColumnProperties(arrTmp[0].idxCol));
            
            var nDstColIdx = -1

            if (nDstColIdx < 0) { 
                nDstColIdx = 
                    gdt.addColumn({
                        type: "number",
                        label: szLabelMix
                    });
                
                gdt.setColumnProperties(nDstColIdx, gdt.getColumnProperties(arrTmp[0].idxCol));
            }

            console.log(">>>>", gdt.getColumnProperties(nDstColIdx));

            // and now: MIX ON!
            var fTmp1, fTmp2;
            for (var nRowIdx = 0; nRowIdx < gdt.getNumberOfRows(); ++nRowIdx) {
                fTmp2 = 0;
                if (!arrTmp.some((elem) => {
                        fTmp1 = gdt.getValue(nRowIdx, elem.idxCol);
                        if (fTmp1 === null) return(true);
                        // console.log(">>>>", fTmp1, elem.fWeight);
                        fTmp2 += fTmp1 * elem.fWeight;
                        return(false);
                    })) {
                    gdt.setValue(nRowIdx, nDstColIdx, fTmp2);
                }
            }
        }
        return(true);
    }

    // gdt
    createBuildParam_NormalDistribution(gdt, arrIdxCol, gdt_stdev, gdt_avg) {
        var arrBuildParam = [];
        var arrLabels = this.gdtGetColumnLabelArray(gdt, arrIdxCol);

        var arrIdxCol_StDev = this.gdtGetColumnIndexArray(gdt_stdev, arrLabels);
        var arrIdxCol_Avg = this.gdtGetColumnIndexArray(gdt_avg, arrLabels);

        // Errorcheck?
        if (arrIdxCol.length !== arrLabels.length || arrIdxCol.length !== arrIdxCol_StDev.length || arrIdxCol.length !== arrIdxCol_Avg.length) {
            return(undefined);  // ERROR!
        }

        console.log(this.constructor.name, "createBuildParam_NormalDistribution", "arrIdxCol", arrIdxCol);

        arrIdxCol.forEach((idxCol, idx) => {
            var srcLabel = arrLabels[idx];
            var fStDev = gdt_stdev.getValue(0, arrIdxCol_StDev[idx]);
            var fAvg = gdt_avg.getValue(0, arrIdxCol_Avg[idx]);

            arrBuildParam.push({
                label: srcLabel + " theo",
                fStDev, 
                fAvg,
                idxColSrc: idxCol,
                columnProperties: gdt.getColumnProperties(idxCol) 
            });
        });

        console.log(this.constructor.name, "createBuildParam_NormalDistribution", "arrBuildParam", arrBuildParam);

        return(arrBuildParam);
    }

    gdtAddCols_StDevE0_ToQ(gdt, arrIdxColSrc) {

        if (!arrIdxColSrc) arrIdxColSrc = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
        var idxColDst = gdt.getNumberOfColumns();
        var arrJob = arrIdxColSrc.map((idxColSrc) => { 
                    var tmp = {
                        idxColSrc:      idxColSrc,
                        idxColDst:      idxColDst,
                        label:          gdt.getColumnLabel(idxColSrc) + "_" + "Q999",
                        p:              0.999,
                        scaleFactor:    Math.pow(250, 0.5) 
                    };
                    ++idxColDst;

                    return(tmp);
                }
            );

        arrJob.forEach((job) => {
            job.idxColDst = gdt.addColumn({
                label: job.label,
                type:  "number",
                role: "data"
            });
            gdt.setColumnProperties(job.idxColDst, gdt.getColumnProperties(job.idxColSrc));            

            // actVal, { idxRow, idxCol, idxRowSel, idxColSel, gdt, arrIdxCol, arrIdxRow })
            var funcModify = (actVal, info) => {
                var val = info.gdt.getValue(info.idxRow, job.idxColSrc);
                if (val === null) return(undefined);    // no correction
                if (val < 0) return(null);  // error
                return( jStat.normal.inv( job.p, 0, val ) * job.scaleFactor );
            };
            this.gdtModifyValues(gdt, funcModify, [ job.idxColDst ]);
        });

        return(true);
    }

    gdtAddCols_ThDi_NormalDistribution(gdt, arrBuildParam) {
        
        console.log(this.constructor.name, "gdtAddCols_ThDi_NormalDistribution", arrBuildParam);

        var view;

        // ADDITIONAL views
        arrBuildParam.forEach((buildParam) => {
            if (!buildParam.idxColDst || buildParam.idxColDst < 0 || buildParam.idxColDst >= gdt.getNumberOfColumns()) {
                buildParam.idxColDst = 
                    gdt.addColumn({
                        label: buildParam.label,
                        type:  "number",
                        role: "data"
                    });
            } else {
                gdt.setColumnLabel(buildParam.idxColDst);
            }

            // adopt properties
            if (buildParam.columnProperties) {
                gdt.setColumnProperties(buildParam.idxColDst, buildParam.columnProperties);
            }

            var arrIdxRow;
            if (buildParam.idxColSrc && buildParam.idxColSrc >= 0) {
                if (!view) view = new google.visualization.DataView(gdt);
                arrIdxRow = view.getSortedRows([{column: buildParam.idxColSrc, desc: false}]);
                // console.log(this.constructor.name, "sorted-rows", arrIdxRow);
            } else {
                arrIdxRow = FabStd.buildIntegerArrayFromTo(0, gdt.getNumberOfRows()-1);
            }  
            
            arrIdxRow.forEach((idxRow, idx) => {
                var fNeu;
                // fNeu = (FabStd.randomNormStDev()*fStDev+fAvg);
                // bislang
                // fNeu =      FabStd.NormStDevInv((idx+0.5)/arrIdxRow.length)
                //        *   buildParam.fStDev   // Standard-deviation
                //        +   buildParam.fAvg;    // Average
                
                // fNeu = 
                //    jStat.normal.inv((idx+0.5)/arrIdxRow.length, buildParam.fAvg, buildParam.fStDev);
                
                fNeu = 
                    jStat.studentt.inv((idx+0.5)/arrIdxRow.length, 6) 
                  * buildParam.fStDev;
                
                    // console.log(this.constructor.name, "fNeu", fNeu);
                gdt.setValue(idxRow, buildParam.idxColDst, fNeu);
            });
        });

        return(true);
    }

   /*
    gdtAddCols_Overlapping
    Modifies the input-Table directly
    */
    gdtAddCols_Overlapping(gdt, jobMain) {

        // jobMain
        //    arrJobSub   
        //        arrJobAnalysis
        
        if (!jobMain.arrJobSub) return(false);

        console.log(this.constructor.name, "gdtAddCols_Overlapping", jobMain);

        var funcLabelStd = (objInfo) => {
            var labelBase, label;
            var i = 0;
            labelBase = this.gdtGetColumnLabelArray(gdt, objInfo.arrColSrc).join("-");
            label = labelBase;
            while (this.gdtGetFirstColumnIndex(gdt, label)) {
                label = labelBase + "-Job" + (objInfo.idxJobSub+1);
                if (i > 0) label = label + "-" + i;
            }
            return(label);
        }
        var getFuncAggStd = (objInfo) => gdt.getColumnProperty(objInfo.arrColSrc[0], "funcAggStd");

        var arrToDo = [];

        jobMain.arrJobSub.forEach((jobSub, idxJobSub) => {

            if (!jobSub.aoaSrcCol || jobSub.aoaSrcCol.length <= 0) {
                console.error("gdtAddCols_Overlapping", "aoaSrcCol in jobSub (arrJobSub) is missing! Must be provided!");
                return(false);
            }

            arrToDo.push(...
                // arrColSrc = "row" of Columns, whose values shall be analyzed/aggregated parallely
                jobSub.aoaSrcCol.map((arrColSrc) => {
                    return({   
                        arrColSrc,
                        funcAgg :   jobSub.funcAgg?jobSub.funcAgg:(jobMain.funcAgg?jobMain.funcAgg:getFuncAggStd({ gdt, arrColSrc, jobSub, idxJobSub })),
                        arrJobAnalysis: 
                            jobSub.arrJobAnalysis.map((jobAnalysis, idxJobAnalysis) => {
                                var tmpJobAnalysis = { ... jobAnalysis };

                                if (!tmpJobAnalysis.nMaxInPeWi && tmpJobAnalysis.nMinInPeWi)
                                    tmpJobAnalysis.nMaxInPeWi = tmpJobAnalysis.nMinInPeWi;
                                if (tmpJobAnalysis.nMaxInPeWi && !tmpJobAnalysis.nMinInPeWi)
                                    tmpJobAnalysis.nMinInPeWi = tmpJobAnalysis.nMaxInPeWi;

                                var aoaTmp = [];
                                arrColSrc.forEach(() => {
                                    aoaTmp.push([]);
                                });

                                // prepare jobAnalysis
                                var jobPreparedAnalysis = {
                                    ... tmpJobAnalysis,
                                    aoaTmp: aoaTmp,
                                    calcOnlyLastPeWi: tmpJobAnalysis.calcOnlyLastPeWi,
                                    idxRowSrcLastOk: -1, 
                                    idxColDst:  
                                        gdt.addColumn({
                                            label: (jobSub.funcLabel?
                                                        jobSub.funcLabel:
                                                            (jobMain.funcLabel?jobMain.funcLabel:funcLabelStd)
                                                    ) ({ gdt, arrColSrc, jobSub, idxJobSub, ... tmpJobAnalysis }),
                                            type:  jobSub.type?jobSub.type:gdt.getColumnType(arrColSrc[0]),
                                            role:  jobSub.role?jobSub.role:gdt.getColumnRole(arrColSrc[0]),
                                        })
                                };

                                // copy columnProperties (including the "dataNumberFormat")
                                var p = gdt.getColumnProperties(arrColSrc[0]);
                                if (jobSub.columnProperties) { 
                                    gdt.setColumnProperties(jobPreparedAnalysis.idxColDst, jobSub.columnProperties);
                                } else {
                                    if (p) {
                                        gdt.setColumnProperties(jobPreparedAnalysis.idxColDst, p);
                                    }    
                                }

                                return(jobPreparedAnalysis);
                            })
                    });
                })
            );
        });

        var val;
        var tmp;
        arrToDo.forEach((toDo) => {
            for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                val = [];
                for (var i=0; i < toDo.arrColSrc.length; ++i) {
                    tmp = gdt.getValue(idxRow, toDo.arrColSrc[i]);
                    if (tmp !== null) val.push(tmp);
                }
                if (val.length === toDo.arrColSrc.length) {
                    toDo.idxRowSrcLastOk = idxRow;  // save the last row-index containing valid data
                    toDo.arrJobAnalysis.forEach((jobAnalysis, idxJobAnalysis) => {
                        val.forEach((v, idxV) => {
                            jobAnalysis.aoaTmp[idxV].push(v);
                            // remove first elements if nMaxInPeWi exceeded
                            if (jobAnalysis.nMaxInPeWi > 0) {
                                while (jobAnalysis.aoaTmp[idxV].length > jobAnalysis.nMaxInPeWi) jobAnalysis.aoaTmp[idxV].shift();
                            }
                        });
                        if (!jobAnalysis.calcOnlyLastPeWi) {
                            if (jobAnalysis.aoaTmp[0].length >= jobAnalysis.nMinInPeWi) {
                                // console.log(this.constructor.name, "overlap", idxRow, jobAnalysis.aoaTmp[0]);
                                gdt.setValue(idxRow, jobAnalysis.idxColDst, toDo.funcAgg(...jobAnalysis.aoaTmp));
                            }
                        }
                    });
                }
            }
        });

        // finishing procedures ... if just the last valid PeWi shall be calculate (calcOnlyLastPeWi === true)
        arrToDo.forEach((toDo) => {
            toDo.arrJobAnalysis.forEach((jobAnalysis, idxJobAnalysis) => {
                if (toDo.idxRowSrcLastOk >= 0 &&        //
                    jobAnalysis.calcOnlyLastPeWi &&   
                    jobAnalysis.aoaTmp[0].length >= jobAnalysis.nMinInPeWi) {
                        // console.log(this.constructor.name, "overlap", idxRow, jobAnalysis.aoaTmp[0]);
                        gdt.setValue(toDo.idxRowSrcLastOk, jobAnalysis.idxColDst, toDo.funcAgg(...jobAnalysis.aoaTmp));
                }
            });
        });

        return(true);
    }

    gdtModifyValues(gdt, funcModify, arrIdxCol, arrIdxRow) {
        try {
            if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
            if (!arrIdxRow) arrIdxRow = FabStd.buildIntegerArrayFromTo(0, gdt.getNumberOfRows()-1);

            var valResult = undefined;
            arrIdxCol.forEach((idxCol, idxColSel) => {
                arrIdxRow.forEach((idxRow, idxRowSel) => {
                    valResult = funcModify(gdt.getValue(idxRow, idxCol), { idxRow, idxCol, idxRowSel, idxColSel, gdt, arrIdxCol, arrIdxRow });
                    // if result is "undefined" then this is interpreted as "don't modify anything"
                    // any other result shall be written back to table
                    if (valResult !== undefined) {
                        gdt.setValue(idxRow, idxCol, valResult);
                    }
                });
            });
        } catch(error) { return(false); }
        return(true);
    }

  /*
    gdtAgg_NonOverlapping
    @param {googleDataTable}  input TS-Table  
    @param {Integer=} options.nMinInPeWi, options.nPeWiSize Optional paramater. Default for "month" is 12, for "year" is 200. 
    @return {googleDataTable} new TS-Table
    */
    gdtAgg_NonOverlapping(gdt, options, arrIdxCol) {
        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);
        var bSecondRunNecessary = false; 

        if (!options) options = {}; 
        if (!options.arr_nPeWiSize || options.arr_nPeWiSize.length <= 0) options.arr_nPeWiSize = [1];
        if (!options.nPeWiSize) options.nPeWiSize = arr_nPeWiSize[0];

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "options", options);

        var szType;
        var funcModifier;

        var smapDtoC = undefined;
        var smapCtoD = undefined;
        var szLabel = gdt.getColumnLabel(0);    // use original Column-0-Label as default
     
        if (!options.szPeName || options.szPeName === "p" || options.szPeName === "d") {
            if (!options.nOffset || options.nOffset < 0) options.nOffset = 0;

            var nConso = options.nPeWiSize;
            smapDtoC = new StdMap();
            smapCtoD = new StdMap();
            for (var idxRow=0+options.nOffset; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                var datDate = gdt.getValue(idxRow, 0);
                var tmpIdxRowDst = Math.floor((idxRow-options.nOffset) / nConso);
                smapDtoC.set(datDate, tmpIdxRowDst);
                smapCtoD.set(tmpIdxRowDst, datDate);
            }
            // and we establish the appropriate modifier-function
            funcModifier = (dat) => smapCtoD.get(smapDtoC.get(dat));
            szType = "date";
            // szLabel = "CoPe_" + nConso;
            
            if (!options.nMinInPeWi || options.nMinInPeWi <= 0) options.nMinInPeWi = nConso; // Math.floor(nConso*3/4);    // 3/4 of conso
        } else {
            switch(options.szPeName) {
                case "w":
                    funcModifier = 
                        (dat) => moment(dat).endOf('week').toDate();
                    // szType = "string";
                    szType = "date";
                    if (!options.nMinInPeWi || options.nMinInPeWi <= 0) options.nMinInPeWi = 5*3/4; // 3/4 of 5 Days
                    break;

                case "m":
                    funcModifier = 
                        (dat) => moment(dat).endOf('month').toDate();
                    // szType = "string";
                    szType = "date";
                    if (!options.nMinInPeWi || options.nMinInPeWi <= 0) options.nMinInPeWi = 20*3/4; // 3/4 of 20 Days
                    break;

                case "y": 
                    funcModifier = 
                        (dat) => moment(dat).endOf('year').toDate(); // dat.getFullYear();
                    // szType = "number";
                    szType = "date";
                    if (!options.nMinInPeWi || options.nMinInPeWi <= 0) options.nMinInPeWi = 250*3/4;    // 3/4 of 250 Days
                    break;

                default:
                    // ERROR ... parameter not ok
                    return(undefined);
            }

            if (options.nPeWiSize > 1) {
                bSecondRunNecessary = true;
            }
        }
        console.log(this.constructor.name, "gdtAgg_NonOverlapping (0b)", options, funcModifier);

        var arrFuncAgg = arrIdxCol.map((idxCol) => gdt.getColumnProperty(idxCol, "funcAggStd") );

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(1)");

        var gdt_result =
            google.visualization.data.group(gdt, 
                // keys
                [    
                    { 
                        column: 0,
                        label: szLabel, 
                        role: "domain",
                        type: szType,
                        modifier: funcModifier
                    }
                ],
                // aggregation (map returns an array of objects)
                arrIdxCol.map((idxCol, idxAggCol) => {
                    return({
                       column: idxCol,
                       aggregation: // aggregation function
                           (arrV) => {
                               if (arrV.length < options.nMinInPeWi) return(null);
                               return(arrFuncAgg[idxAggCol](arrV));
                           }
                           ,
                       type: "number"
                    });    
                })
        );
        if (!gdt_result) {
            console.log(this.constructor.name, "gdtAgg_NonOverlapping", "consolidation failed");
            return(undefined);
        }

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(2)");

        this.gdtRemoveFirstRow_ifDateColIsNull(gdt_result);

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(3)");

        // set/copy some properties
        arrIdxCol.forEach((idxCol, idxAggCol) => {            
            var p = gdt.getColumnProperties(idxCol);
            gdt_result.setColumnProperties(idxAggCol+1, {
                    ... p, 
                    funcAggStd: arrFuncAgg[idxAggCol],
                }
            );
        });

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(4)");

        if (bSecondRunNecessary) {
            console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(5)", "start second run");

            gdt_result 
                = this.gdtAgg_NonOverlapping(
                            gdt_result, 
                            { 
                                ... options, 
                                nMinInPeWi: null,
                                szPeName: "p"       // another second run will be suppressed by this parameter
                            });
        }

        console.log(this.constructor.name, "gdtAgg_NonOverlapping", "(6)", "returning");
        return(gdt_result);
}

    /** Clones a Dattable whilst reducing the total number of rows to max. 100. */
    gdtCloneShortend(gdt) { 
        var gdtClone = new google.visualization.DataTable();
        var idxRow, idxCol;

        for (idxCol=0; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
            gdtClone.addColumn(
                { 
                    label: gdt.getColumnLabel(idxCol),
                    role: gdt.getColumnRole(idxCol),
                    type: gdt.getColumnType(idxCol)
                });
            gdtClone.setColumnProperties(idxCol, gdt.getColumnProperties(idxCol));
        }

        var cntNew = Math.min(100, gdt.getNumberOfRows());
        var nStep = gdt.getNumberOfRows()/cntNew;

        gdtClone.addRows(cntNew);
        var idxRowDst = 0;
        for (idxRow = 0; idxRow <= gdt.getNumberOfRows()-1; idxRow += nStep) {
            for (idxCol = 0; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
                gdtClone.setValue(idxRowDst, idxCol, gdt.getValue(Math.round(idxRow), idxCol));
            }
            ++idxRowDst;
        }

        return(gdtClone);
    }


  /*
    gdtAgg_ShiftedView
    @param {googleDataTable}  input TS-Table  
    @param {number=5} nPeriodsToAggregate
    @return {googleDataTable} new TS-Table, aggregated, Column-Count = 1 + Value-Columns*nPeriodsToAggregate 
    */
    gdtAgg_ShiftedView(gdt, nPeriodsToAggregate, arrIdxCol) {

        console.log(this.constructor.name, "gdtAgg_ShiftedView", "Start ...");

        if (!nPeriodsToAggregate) nPeriodsToAggregate = 5;

        var gdt_tmp = gdt.clone();

        // BUILD SHIFTED, PARAMETER: NUMBER of PERIODS
        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt_tmp.getNumberOfColumns()-1);
        console.log(this.constructor.name, "gdtAgg_ShiftedView", "arrIdxCol", arrIdxCol);

        var arrShift = FabStd.buildIntegerArrayFromTo(0, nPeriodsToAggregate-1);
        var arrFuncAgg = arrIdxCol.map((idxCol) => gdt_tmp.getColumnProperty(idxCol, "funcAggStd") );

        var gdt_new = undefined;
        var smapCtoD = new StdMap();
        var nConso = nPeriodsToAggregate;
        var funcLabel = (idxColSrc, nShift) => gdt_tmp.getColumnLabel(idxColSrc) + " /PeWi(" + nConso + ") /Shift(" + nShift + ")";

        arrShift.forEach((nShift) => {
            var smapDtoC = new StdMap();

            for (var idxRow=nShift; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                var datDate = gdt.getValue(idxRow, 0);
                var tmpIdxRowDst = Math.floor((idxRow-nShift) / nConso);
                smapDtoC.set(datDate, tmpIdxRowDst);
                if (nShift === 0) smapCtoD.set(tmpIdxRowDst, datDate);
            }
            // and we establish the appropriate modifier-function
            var funcModifier = (dat) => smapCtoD.get(smapDtoC.get(dat));
            var szType = "date";

            var gdt_right  = 
                google.visualization.data.group(gdt_tmp, 
                    [   // keys
                        {
                            column: 0,
                            label: gdt_tmp.getColumnLabel(0),
                            type: szType,
                            role: "domain",
                            modifier: (dat) => funcModifier(dat)
                        }
                    ],
                    // aggregation (map returns an array of objects)
                    arrIdxCol.map((idxCol, idxAggCol) => {
                        return({
                            column: idxCol,
                            label: funcLabel(idxCol, nShift),
                            aggregation: // aggregation function
                                // google.visualization.data.count,    
                                // funcAggPerformance
                                // funcAggSum
                                (arrV) => {
                                    if (arrV.length < nPeriodsToAggregate) return(null);
                                    return(arrFuncAgg[idxAggCol](arrV));
                                }
                                ,
                            type: "number"
                        });    
                    })
                );

            this.gdtCopyColumnProperties(gdt_tmp, arrIdxCol, gdt_right, FabStd.buildIntegerArrayFromTo(1, gdt_right.getNumberOfColumns()-1));

            gdt_new = FabStd.googleDataTableJoinAll(gdt_new, gdt_right);
        });

        // check first row ... if date is "null" then: remove first row
        this.gdtRemoveFirstRow_ifDateColIsNull(gdt_new);

        console.log(this.constructor.name, "gdtAgg_ShiftedView", "... finished!");

        return(gdt_new);
    }

    funcAggPerformance(arrV) {
        // for PERFORMANCE-Values
        var fPerf = 1;
        arrV.forEach((fSinglePerf) => { fPerf *= (1+fSinglePerf); });
        return(fPerf-1);
        // return(arrV[arrV.length-1]);
    }

    funcAggFirst(arrV) {
        for (var i=0; i < arrV.length; ++i) {
            if (arrV[i] !== null) return(arrV[i]);
        }
        return(null);
    }

    funcAggLast(arrV) {
        for (var i=arrV.length-1; i >= 0; --i) {
            if (arrV[i] !== null) return(arrV[i]);
        }
        return(null);
    }

    // "options" is optional
    funcAggAverage(arrVal, options) {
        return(google.visualization.data.avg(arrVal));
    }

    // "options" is optional
    funcAggStDev(arrVal, options) {
        var sum=0;
        var qsum=0;
        var cnt=0;
        var tmpV=0;
    
        arrVal.forEach((v) => {
            if (v !== null && v !== undefined) {
                tmpV = v;
                if (options && options.fFactor) tmpV *= options.fFactor;

                // if ((v < -0.02) || (v > 0.02)) {
                    sum += tmpV;
                    qsum += tmpV*tmpV;
                    ++cnt;
                // }
            }
        });
        if (cnt <= 0) return(undefined);
        var Ex = sum/cnt;
        var Ex2 = qsum/cnt;
        return(Math.sqrt(Ex2 - (Ex*Ex)));
    }

    gdt_correlation(gdt, arrColX, arrColY, idxRowFrom, idxRowTo) {
        var gdt_tmp = new google.visualization.DataTable();

        if (!idxRowFrom || idxRowFrom < 0) idxRowFrom = 0;
        if (!idxRowTo || idxRowTo >= gdt.getNumberOfRows()) idxRowTo = gdt.getNumberOfRows()-1;

        gdt_tmp.addColumn({
            label:  "vs",
            type:  "string"
        })
        arrColY.forEach((idxCol) => {
            gdt_tmp.addColumn(
                "number", 
                gdt.getColumnLabel(idxCol));
        });
        gdt_tmp.addRows(arrColX.length);
        arrColX.forEach((idxCol, idxRowDst) => {
            gdt_tmp.setValue(idxRowDst, 0, gdt.getColumnLabel(idxCol));
        });

        // console.log("gdt_correlation", "Step 1", google.visualization.dataTableToCsv(gdt_tmp));

        arrColX.forEach((idxColSrc1, idxDstRow) => {
            arrColY.forEach((idxColSrc2, idxDstCol) => {
                if (gdt_tmp.getValue(idxDstRow, idxDstCol+1) === null) {
                    var x, y;
                    var sumX = 0;
                    var sumY = 0;
                    var qsumX = 0;
                    var qsumY = 0;
                    var cnt = 0;
                    var sumXY = 0;
                    for (var idxRowSrc=idxRowFrom; idxRowSrc<=idxRowTo; ++idxRowSrc) {
                        x = gdt.getValue(idxRowSrc, idxColSrc1);
                        y = gdt.getValue(idxRowSrc, idxColSrc2);
                        if (x !== null && y !== null) {
                            sumX += x;
                            sumY += y;
                            qsumX += x*x;
                            qsumY += y*y;
                            sumXY += x*y;
                            ++cnt;
                        }
                    }
                    if (cnt > 0) {
                        var Ex = sumX/cnt;
                        var Ey = sumY/cnt;
                        var Ex2 = qsumX/cnt;
                        var Ey2 = qsumY/cnt;
                        var Exy = sumXY/cnt;
                        var varX = Ex2 - Ex*Ex;
                        var varY = Ey2 - Ey*Ey;
                        var k = (Exy - (Ex*Ey))/(Math.sqrt(varX*varY));
                        if (!isNaN(k)) {
                            // console.log("KorrKoeff", idxColSrc2, k);
                            gdt_tmp.setValue(idxDstRow, idxDstCol+1, k);
                        }
                    }
                } 
            });
        }); 

        // console.log("gdt_correlation", "Step 2", google.visualization.dataTableToCsv(gdt_tmp));

        return(gdt_tmp);
    }

    funcAggCorrelation(arrValX, arrValY) {
        var sumX=0;
        var sumY=0;
        var qsumX=0;
        var qsumY=0
        var cnt=0;
        var sumXY=0;
        var x,y;

        if (arrValX.length !== arrValY.length) return(null);

        for (var idx=0; idx < arrValX.length; ++idx) {
            x = arrValX[idx];
            y = arrValY[idx];
            if (x !== null && y !== null) {
                sumX += x;
                qsumX += x*x;
                sumY += y;
                qsumY += y*y;
                sumXY += x*y;
                ++cnt;
            }
        }

        if (cnt <= 0) return(null);

        var Ex = sumX/cnt;
        var Ey = sumY/cnt;
        var Ex2 = qsumX/cnt;
        var Ey2 = qsumY/cnt;
        var Exy = sumXY/cnt;
        var varX = Ex2 - Ex*Ex;
        var varY = Ey2 - Ey*Ey;
        var k = (Exy - (Ex*Ey))/Math.sqrt(varX*varY);
        if (isNaN(k)) return(null);
        return(k);
    }

    gdtAggHorizontally(gdt, arrJob) {
        var arrIdxColKill = [];

        arrJob.forEach((objJob) => {
            var bFirstRow = true;
            for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                var arrVal = [];
                var val;
    
                objJob.arrIdxCol.forEach((idxCol, idx) => {
                    val = gdt.getValue(idxRow, idxCol);
                    if (val) arrVal.push(val);
                });
                if (bFirstRow) {
                    objJob.arrIdxCol.forEach((idxCol, idx) => {
                        if (idx > 0) arrIdxColKill.push(idxCol);
                    });    
                }

                if (arrVal.length > 0) {
                    val = this.funcAggAverage(arrVal);
                } else {
                    val = null;
                }

                
                gdt.setValue(idxRow, objJob.arrIdxCol[0], val);
                if (bFirstRow) {
                    gdt.setColumnLabel(objJob.arrIdxCol[0], objJob.label);
                }
                bFirstRow = false; 
            }
        });

        arrIdxColKill.sort((a, b) => a>b?-1:+1 );
        console.log("arrIdxColKill", arrIdxColKill);

        arrIdxColKill.forEach((idxCol) => {
            gdt.removeColumn(idxCol);
        });

        return(gdt);
    }

    // for multiple parameters that shall be calculated simultaneously
    gdtBuildDistributionParameterMatrix(gdt, arrIdxCol, arrJob) {
        var gdt_param = undefined;

        arrJob.forEach((job) => {
            if (!job.options) job.options = {};

            var gdt_tmp;
            var szShortcut = job.paramShortcut.toLowerCase();

            switch(szShortcut) {
                case "standardabweichung":
                case "stdabw":
                case "stdev": 
                    job.funcAgg = this.funcAggStDev;
                    job.options.titleResultRow = "StDev";
                    break;

                case "avg":
                case "mean":
                case "mw":
                case "mittelwert":
                case "average": 
                    job.funcAgg = this.funcAggAverage,
                    job.options.titleResultRow =  "Avg";
                    break;

                    case "min":
                    case "minimum": 
                        job.funcAgg = google.visualization.data.min,
                        job.options.titleResultRow =  "Min";
                        break;
    
                    case "max":
                    case "maximum": 
                        job.funcAgg = google.visualization.data.max,
                        job.options.titleResultRow =  "Max";
                        break;
    
                    case "fst":
                    case "ers":
                    case "first":
                    case "erste":
                    case "erstes":
                    case "erster":
                        job.funcAgg = this.funcAggFirst,
                        job.options.titleResultRow =  "First";
                        break;
    
                    case "last":
                    case "ltz":
                    case "lst":
                    case "letzte":
                    case "letztes":
                    case "letzter":
                        job.funcAgg = this.funcAggLast,
                        job.options.titleResultRow =  "Last";
                        break;

                default:
                    if (szShortcut.startsWith("q")) {
                        var match;
                        var szBracket;
                        var reExtension = /^([^\(]*)\((.*)\)$/g;   // to look for "blabla(value)"
                        var fPct = 0;

                        if (match = reExtension.exec(szShortcut)) {
                            szShortcut = match[1];
                            szBracket = match[2];
                        } 
                        fPct = +szShortcut.substr(1);
                        
                        console.log(fPct, "szBracket", szBracket);
                        job.funcAgg = (arrVal) => quantileSeq(arrVal.filter((v) => v !== null), fPct/100);                      
                        job.options.titleResultRow = "Q" + fPct;
                        console.log(job);
                    }
                    break;
            }

            if (job.funcAgg) {
                gdt_tmp = 
                    this.gdtCalcDistributionParam(
                        gdt, 
                        arrIdxCol,
                        job.funcAgg,
                        job.options
                    );
                gdt_param = this.gdtUnion(gdt_param, gdt_tmp);
            }
        });

        return(gdt_param);
    }

    // returns new gdt
    gdtCalcDistributionParam(
            gdt, 
            arrIdxCol, 
            funcAgg,
            options) {

        if (!options) options = {};
        if (!options.titleResultRow) options.titleResultRow = "total";

        var gdt_agg =  
            google.visualization.data.group(gdt, 
                // keys
                [
                    {
                        column: 0,
                        label: "result", 
                        type: "string",
                        modifier: (v) => { return(options.titleResultRow); } 
                    }
                ],
                // aggregation (map returns an array of objects)
                arrIdxCol.map((idxCol) => {
                    return({
                        column: idxCol,
                        aggregation: (arrVal) => funcAgg(arrVal, options),  // lauch Aggregation with options!
                        type: gdt.getColumnType(idxCol) // "number"
                    });    
                })
            );

        this.gdtCopyColumnProperties(gdt, arrIdxCol, gdt_agg);

        return(gdt_agg);
    }

    gdtFormatData(gdt) {
        // do some data-formatting
        new google.visualization.DateFormat({ pattern: "dd.MM.YY ccc" }).format(gdt, 0);
        
        FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1).forEach((idxCol) => {
            var p = gdt.getColumnProperties(idxCol);
            // console.log(this.constructor.name, "properties for format", idxCol, p);
            if (p.dataNumberFormat) {
                // console.log(this.constructor.name, "Formatting", idxCol, p.dataNumberFormat);
                new google.visualization.NumberFormat(p.dataNumberFormat).format(gdt, idxCol);
            }
        });

        return(true);
    }

}

import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcSearch_Entry_01 from "./fabbi_crc_search_entry_01.jsx";

export default class HtmlProcessor_Search extends HtmlProcessor {    
    constructor(props) {
        super(props);

        console.debug(this.constructor.name, "constructor-props", props);
    }    

    static completeProps(props) {
        super.completeProps(props);

        props.HtmlElementIdPrefix = "search";

        console.debug(this.constructor.name, "completeProps", props);
        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcSearch_Entry_01
        }
        
        return(props);
    }
     
    onPrepareEntry(id_entry, entry) {

        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;
        
        console.log(this.constructor.name, "onPrepareEntry", "id", id_entry, "dataset", dataset);
        
        /*
        templateHtml.prepareDatasetHtml(id_entry, dataset, datasetExtra, objOptions);
    
        var datasetCopy = { ...dataset };
        datasetExtra[HtmlProcessor_Search.ID_DIV_ENTRY] = { 
            click: function(e) { 
                console.log("search", "click", this);
                if (!!this.div_entry) {
                    this.div_entry.scrollIntoView({ behavior: "smooth", block: "start" });  
                    
                    this.div_entry.classList.add("highlight_01");
                    setTimeout(function () {
                        console.log("search", "removing highlight");
                        this.div_entry.classList.remove("highlight_01");
                    }.bind(this), 1500);
                }
            }.bind(datasetCopy)
        };
        */
    }
}

HtmlProcessor_Search.ID_DIV_ENTRY 	    = "search-div-entry";
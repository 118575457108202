import qryProcessor from "./fabbi_qry_processor.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class qryProcessor_Manual extends qryProcessor {
    constructor(props) {
        super(props);                

        this.m_mapNewData = new StdMap();

        // console.log(this.getClassName(), "constructor", this.m_szUserId, "TriggerClassId", this.getId_ofTriggerClass());        
        setTimeout(() => { this.planNewDataIncoming(); }, 0);
    }

    getClassName() {
        return("qryProcessor_Manual");    // to FIX the classname!
    }

    processQuery() {
    }

    deleteAllEntries() {
        console.log(this.getId(), "deleteAllEntries()");
        var iter = this.getPrivateDataPool().getDataIdEntryIterator();
        var result;
        while (!(result = iter.next()).done) {
            this.processEntry(result.value, undefined, "deleted");
        }
    }
    
    addTest() {
        console.log(this.getId(), "addTest");
        for (var i = 0; i < 1000; ++i) {
            this.processEntry(undefined, { subject: "Testeintrag " + FabStd.randomInt(1000,0), info: "blabla" }, "added");
        }
    }

    // add something
    processEntry(szId, dataset, szChangeType) {
        console.log(this.getId(), "new Entry", "dataset", dataset);

        var dat = new Date();

        if (!szId) {
            do {
                szId = "T_" + Date.now() + "_" + FabStd.randomInt(1000,0);                            
            } while(
                !!this.m_mapNewData.get(szId) && 
                this.getPrivateDataPool().existsDataEntry(szId));
        }

        this.m_mapNewData.set(szId, { id: szId, type: szChangeType, dataset: dataset instanceof Object?dataset:{} } );
        // signal new data arrived!
        this.planNewDataIncoming();
    }

    onNewDataProcessing() {        
        var iter = this.m_mapNewData.keys();
        var result = iter.next();
        var tmp;

        while (!result.done) {
            tmp = this.m_mapNewData.get(result.value);

            // console.log(this.getId(), "PROCESSING", tmp.id, tmp.type, tmp.dataset);
            this.datasetChanged(
                tmp.id, 
                tmp.dataset, 
                tmp.type);    

            result = iter.next();
        }
            
        this.m_mapNewData.clear();
        return(super.onNewDataIncoming());
    }

    onRunCompleted() {      
        // console.log(this.getClassName(), "onRunCompleted", this.m_szUserId);
        super.onRunCompleted();
    }    
}
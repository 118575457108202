import React, { useRef } from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import CrcCarousel_01 from "./fabbi_crc_carousel_01.jsx"
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import StdMap from '/app-assets/js/fabstd/fabbi_stdmap.js';
import { easeOutQuad } from 'js-easing-functions';

/**
 * Construct Carousel instance
 * @constructor
 * @param {ReactProperties} props The member "options" of the props is resposnible for the appearance of the carousel.
 */
export default class CrcCarousel_01_Csv extends CrcCarousel_01 {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        
        console.log(this.constructor.name, "componentDidUpdate");

        if (this.props.csv !== prevProps.csv) {
            console.log(this.constructor.name, "componentDidUpdate", "_rebuildItemDataMap");
            this._rebuildItemDataMap();
            this.update();
        }
    }

    getDbValue() {
        return(this.getCsvImgSrc());
    }

    getCsvImgSrc() { 
        console.log(this.constructor.name, "getCsvImgSrc", this.props.csv);
        var arrTmp = FabStd.csvToArray(this.props.csv);
        arrTmp = FabStd.arrayRollTo(arrTmp, this._wrap(this.center));
        var result = FabStd.arrayToCsv(arrTmp);
        console.log(this.constructor.name, "getCsvImgSrc", this.props.csv, "arrTmp", arrTmp);
        return(result);
    }

    /*
    setCsvImgSrc(csv) {
        console.log(this.constructor.name, "setCsvImgSrc", csv);

        this._rebuildMap = true;
        this.setState({csv: csv});
    }
    */

    _rebuildItemDataMap() {
        this._smapData = new StdMap();

        // console.log(this.constructor.name, "_getItemDataMap", "START");
        FabStd.csvToArray(this.props.csv, true)
            .forEach((part, index) => {
                // console.log(this.constructor.name, "_getItemDataMap", index);
                if (part) {
                    this._smapData.set(index.toString(), {
                        id: index.toString(), 
                        image_src: part, 
                        long_name: "" 
                    });
                }
            }
        );

        return(this._smapData);
    }

    _getItemDataMap() {
        if (!this._smapData) return(this._rebuildItemDataMap()); else return(this._smapData);
    }
}
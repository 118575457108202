import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import qryProcessor from "./fabbi_qry_processor.js";
import { collection, doc, getDoc, onSnapshot, updateDoc, addDoc } from "firebase/firestore"; 

export default class qryProcessor_CloudFirestore extends qryProcessor {
    constructor(props) {
        super(props);
        this.m_snapData = null;
    }    

    updateOrSetDoc(szDocId, data) {
        if (!this.getPrivateDataPool().getDataEntry(szDocId)) {            
            return(this.setDoc(szDocId, data, true));
        } else  {
            return(this.updateDoc(szDocId, data));
        }        
    }  /**
     * Used, to read a document from cloud-firestore
     * @param {String} szDocumentId The document's id that shall be read. (cloud-firestore-document-id)
     * @returns {Promise} The Promise-Object used for handling success/failure of the asynchronous read-action. 
     * Some finishing action can be taken via "then". The "resolve"-function is supplied with the "snapshot"-Data from the cloud firestore
     * storage.
     */
    readDoc(szDocId) {
        var szCollectionPath= this.getCollectionPath();
        let thisClass = this;
        let p1 = new Promise(
            function(resolve_readDoc, reject_notUsed) {
                var docRef = doc(thisClass.getWfCtrl().getFirestore(), szCollectionPath, szDocId);

                getDoc(docRef).then(function (dataSnapshot) {
                    resolve_readDoc({rc: "OK", dataSnapshot: dataSnapshot, error: undefined });
                }).catch((error) => {
                    resolve_readDoc({rc: "ERROR", dataSnapshot: undefined, error: error });
                });
            }
        );
                
        return(p1);
    }
    
   /**
     * Used, to read a query or collecion from cloud-firestore
     * @param {String=} options Additional options (not implemented yet).
     * @returns {Promise} The Promise-Object used for handling success/failure of the asynchronous read-action. 
     * Some finishing action can be taken via "then". The "resolve"-function is supplied with the "snapshot"-Data from the cloud firestore
     * storage.
     */
    readQuery(options) {
        var szCollectionPath = this.getCollectionPath();
        let thisClass = this;
        let p1 = new Promise(
            function(resolve_readQuery, reject_notUsed) {
                var colRef = collection(thisClass.getWfCtrl().getFirestore(), szCollectionPath);
                getDoc(colRef).then(function (querySnapshot) {
                    resolve_readQuery({rc: "OK", querySnapshot: querySnapshot, error: undefined });
                }).catch((error) => {
                    resolve_readQuery({rc: "ERROR", querySnapshot: undefined, error: error });
                });
            }
        );
                
        return(p1);
    }

    addDoc(data, funcOnCompleted) {
        let thisClass = this;        
        let tmpData = data;        
        let szCollectionPath = this.getCollectionPath();
        let p1 = new Promise(
            function(resolve_addDoc, reject_notUsed) {
                addDoc(collection(thisClass.getWfCtrl().getFirestore(), szCollectionPath), data).then(function(docRef) {            
                    console.log(thisClass.getClassName(), "addDoc", "OK", "new Id:", docRef.id);                        
                    if (funcOnCompleted != undefined) {
                        funcOnCompleted(docRef.id, tmpData);
                    }
                    resolve_addDoc( { rc: "OK", szAction: "ADD", szDocId: docRef.id, szCollectionPath: szCollectionPath, data: tmpData, error: undefined } );
                }).catch(function(error) {
                        console.log(thisClass.getClassName(), "addDoc", "Error", error);    // log the error!            
                        if (funcOnCompleted != undefined) {
                            funcOnCompleted(undefined, tmpData, error);
                        }
                        resolve_addDoc( { rc: "ERROR", szAction: "ADD", szDocId: undefined, szCollectionPath: szCollectionPath, data: tmpData, error: error } );
                    });

            }
        );                
        return(p1);
    }    

    setDoc_viaTransaction(tsa, szDocId, data) {
        var szCollectionPath= this.getCollectionPath();
        var docRef = doc(this.getWfCtrl().getFirestore(), szCollectionPath, szDocId);
        return(tsa.set(docRef, data));
    }    
    
    setDoc(szDocId, data, bMergeNewDataIfExisting, funcOnCompleted) {
        let thisClass = this;        
        let tmpData = data;        
        let szCollectionPath = this.getCollectionPath();

        let p1 = new Promise(
            function(resolve_setDoc, reject_notUsed) {
                try {
                    var docRef = doc(thisClass.getWfCtrl().getFirestore(), szCollectionPath, szDocId);
                    docRef.set(data, { merge: bMergeNewDataIfExisting }).then(function() {            
                        console.log(thisClass.getClassName(), "setDoc", "OK", "Data", tmpData);                        
                        if (funcOnCompleted != undefined) {
                            funcOnCompleted(szDocId, tmpData, undefined);
                        }
                        resolve_setDoc( { rc: "OK", szAction: "SET", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: undefined } );
                    }).catch(function(error) {
                        console.log(thisClass.getClassName(), "setDoc", "Error", error, "DocId", szDocId, "Data", data);    // log the error!            
                        if (funcOnCompleted != undefined) {
                            funcOnCompleted(szDocId, tmpData, error);
                        }
                        resolve_setDoc( { rc: "ERROR", szAction: "SET", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: error } );
                    });                
                } catch (error) {
                    console.log(thisClass.getClassName(), "setDoc", "Error in call", error, "DocId", szDocId, "Data", data);    // log the error!
                    resolve_setDoc( { rc: "ERROR", szAction: "SET", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: error } );
                }
            });
        return(p1);
    }

    updateDoc_viaTransaction(tsa, szDocId, data) {        
        var szCollectionPath= this.getCollectionPath();
        var docRef = doc(this.getWfCtrl().getFirestore(), szCollectionPath, szDocId);
        return(tsa.update(docRef, data));
    }

    updateDoc(szDocId, data, funcOnCompleted) {
        let thisClass = this;        
        let tmpData = data;        
        let szCollectionPath = this.getCollectionPath();

        let p1 = new Promise(
            function(resolve_updateDoc, reject_notUsed) {
                try {
                    var docRef = doc(thisClass.getWfCtrl().getFirestore(), szCollectionPath, szDocId);
                    updateDoc(docRef, data).then(function() {            
                        console.log(thisClass.getClassName(), "updateDoc", "OK", "Data", tmpData);                        
                        if (funcOnCompleted != undefined) {
                            funcOnCompleted(szDocId, tmpData, undefined);
                        }
                        resolve_updateDoc( { rc: "OK", szAction: "UPD", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: undefined } );
                    }).catch(function(error) {
                        console.log(thisClass.getClassName(), "updateDoc", "Error", error);    // log the error!            
                        if (funcOnCompleted != undefined) {
                            funcOnCompleted(szDocId, tmpData, error);
                        }
                        resolve_updateDoc( { rc: "ERROR", szAction: "UPD", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: error } );
                    });                
                } catch(error) {
                    console.log(thisClass.getClassName(), "updateDoc", "Error in call", error, "DocId", szDocId, "Data", data);    // log the error!
                    resolve_updateDoc( { rc: "ERROR", szAction: "UPD", szDocId: szDocId, szCollectionPath: szCollectionPath, data: tmpData, error: error } );
                }
            }
        );

        return(p1);
    }
    
    deleteDoc_viaTransaction(tsa, szDocId) {    
        var szCollectionPath= this.getCollectionPath();    
        var docRef = doc(this.getWfCtrl().getFirestore(), szCollectionPath, szDocId);
        return(tsa.delete(docRef));
    }

    deleteDoc(szDocId, funcOnCompleted) {
        let thisClass = this;   
        let szCollectionPath = this.getCollectionPath();

        let p1 = new Promise(
            function(resolve_deleteDoc, reject_notUsed) {
                doc(thisClass.getWfCtrl().getFirestore(), szCollectionPath, szDocId).delete().then(function() {            
                    console.log(thisClass.getClassName(), "deleteDoc", "OK");                        
                    if (funcOnCompleted != undefined) {
                        funcOnCompleted(szDocId);
                    } else {
                        M.toast({html: 'Document deleted!'});
                    }
                    resolve_deleteDoc( { rc: "OK", szAction: "DEL", szDocId: szDocId, szCollectionPath: szCollectionPath, data: undefined, error: undefined } );
                }).catch(function(error) {
                    console.log(thisClass.getClassName(), "deleteDoc", "deleteFailed", "path", szCollectionPath, "docId", szDocId, "Error", error);    // log the error!            
                    if (funcOnCompleted != undefined) {
                        funcOnCompleted(szDocId, error);
                    }
                    resolve_deleteDoc( { rc: "ERROR", szAction: "DEL", szDocId: szDocId, szCollectionPath: szCollectionPath, data: undefined, error: error } );
                });                
            }
        );

        return(p1);
    }

    // must be overridden!
    getCollectionPath() {        
        return("a datbase-path of A COLLECTION (not a document) must be returned!");
    }
        
    buildQuery() {        
        return(collection(this.getWfCtrl().getFirestore(), this.getCollectionPath()));
    }
        
    processQuery() {        
        var qry = this.buildQuery();

        this.subscription = onSnapshot(qry, (snap) => {
            // console.log(thisClass.getClassName(), "new Snapshotdata", snap);            
            this.setLastError(null);    // obviously NO error !
            this.m_snapData = snap;
            this.privOnNewDataIncoming();
        }, (error) => {
            console.log(this.getClassName(), "processQuery", "ErrorCode", error.code, "ErrorObj", error, "Query", qry);                
            // Permission-denied
            switch(error.code) {            
                case "cancelled":                   break;
                case "unknown":                     break;
                case "invalid-argument":            break;
                case "deadline-exceeded":           break;
                case "not-found":                   break;
                case "already-exists":              break;
                case "permission-denied":           break;
                case "resource-exhausted":          break;
                case "failed-precondition":         break;
                case "aborted":                     break;
                case "out-of-range":                break;
                case "unimplemented":               break;
                case "internal":                    break;
                case "unavailable":                 break;
                case "data-loss":                   break;
                case "unauthenticated":             break;
            }

            // set the error!
            this.setLastError(error);

            // act as if data was received ...            
            this.privOnNewDataIncoming();

            // if a "terminating" qryProcessor reports an error, we don't report it ...
            if (!this.isTerminating()) {
                this.getWfCtrl().reportProblem({ 
                    problem_id: error.code + "_" + this.getId(), 
                    problem_type: "error_cloudfirestore_queryProcessor", 
                    data_type: this.getClassName(),     
                    data_id: this.getId(),              // query-processor-id
                    data_qp: this                       // pointer to query-processor-id
                });
            }
        });                        
    }
        
    // standard implementation
    onNewDataProcessing() {                
        // check, if whether we are dealing with a document-snapshot or with a query/collection-snapshot
        if (!!this.m_snapData) {
            if (typeof this.m_snapData.docChanges != 'function') {      
                // Snapshot for a single document
                let szType = "added";                                
                // snapshots of a single document
                if (!this.isDataEmpty()) {                     
                    if (this.m_snapData.exists) {
                        szType = "modified";
                    } else {                    
                        szType = "deleted";
                    }
                } else {
                    if (!this.m_snapData.exists) {
                        szType = "empty";
                    }
                }                
                this.datasetChanged(
                    this.m_snapData.id, 
                    this.m_snapData.data(), 
                    szType);                
            } else {            
                // snapshots for collection-updates
                
                // snapshots of a collection or query
                this.m_snapData.docChanges().forEach((change) => {
                    this.datasetChanged(
                        change.doc.id, 
                        change.doc.data(), 
                        change.type);
                    }
                );
            }            
        } else {
            // EMPTY SNAPSHOT!            
        }
        this.m_snapData = null;    // cleanup actual data
        
        return(true);
    }
}
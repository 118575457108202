import React, { useRef } from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";

export default class CrcInfoLine_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        // infoContent
        // noAnimation (true/false)

        this.__waitTimeout = undefined;
        this.__animListener = undefined;

        // use "deadline" given via props ...
        this.state.stage = 0;

        this.state.updateIntervall_sec = 5;
        this.state.scrollSpeed_pxPerSec = FabStdBro.cnvRemToPx(3);
    
        // console.log(this.constructor.name, "constructor", this.getUniqueTempId(), props, this.props.infoContent, this.state.infoContent);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.stage > 0 && nextProps.infoContent !== this.props.infoContent) {
            // console.log(this.constructor.name, "shouldComponentUpdate()", this.getIdDOM(), "next", nextProps.infoContent, "actual", this.props.infoContent, "stage", this.state.stage);
            // this._startInitialization(nextProps.infoContent);
            this._shutdownListeners();
            this._subStopAnimation();
            this.state.stage = 0;
            return(true);
            // return(false);
        } else {
            return(true);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log(this.constructor.name, "componentDidUpdate()", this.getUniqueTempId(), this.state.stage, this.props.infoContent, prevProps.infoContent);

        // saveWidth 
        this._saveWidth();

        // console.log("CRCif", "stage", this.state.stage, "widthBlank", this.__widthTarget);
        switch (this.state.stage) {
            case 0:
                this.__widthFull = this.__widthTarget;
                this.setState({ stage: 1 });
                break;

            case 1:
                this.__widthFull = this.getDOMElement().scrollWidth;
                // console.log(this.constructor.name, "componentDidUpdate()", "calculated full width", this.__widthFull);
                this.setState({ stage: 2 });
                break;

            case 2:
                // console.log("CRCif", "final Update");
                this._startAnimation();
                break;
        }
    } 

    // just run once after DOM-entry is created ... afterward componentDidUpdate(..) is used
    componentDidMount() {
        super.componentDidMount();
        // console.log(this.constructor.name, "componentDidMount()", this.getUniqueTempId(), this.state.stage, this.props.infoContent, this.state.infoContent);

        this._saveWidth();
        this.setState({ stage: 1 });  // provoke new update
    }

    _saveWidth() {
        if (this.state.stage === 0) {     
            var elem = this.getDOMElement();
            if (elem) {
                this.__widthTarget = elem.clientWidth;   
                elem.parentElement.style.width = this.__widthTarget+ "px";   
                elem.parentElement.style.overflow = "hidden"; 
            }
        }
    }
    
    componentWillUnmount() {
        // console.log(this.constructor.name, "componentWillUnmount()", this.state.stage);
        this._shutdownListeners();
    }

    _lengthOf_infoContent() {
        if (this.props.infoContent) return(this.props.infoContent.length);
        return(0);
    }

    _startAnimation() {
        // console.log(this.constructor.name, "_startAnimation", this.getUniqueTempId(), this.__widthFull, this.__widthTarget);
        
        this._shutdownListeners();

        if (this.props.noAnimation) return;
        
        if (this.__widthFull > this.__widthTarget) {
            this.__scrollText = true;
            this.__scrollPos = 0;
            this.__approxVisibleLength = Math.max(1, this._lengthOf_infoContent() * this.__widthTarget/this.__widthFull - 5);
            this.__widthToMove = this.__widthFull - this.__widthTarget + 15;
            this.__animDurationFwd = this.__widthToMove/this.state.scrollSpeed_pxPerSec + "s";
            this.__animDurationRev = this.__widthToMove/this.state.scrollSpeed_pxPerSec/3 + "s";
            this.__animNameFwd = "__tmp_info_line_f" + this.getUniqueTempId();
            this.__animNameRev = "__tmp_info_line_b" + this.getUniqueTempId();
        } else {
            this.__scrollText = false;
            this.__scrollPos = 0;
            this.__approxVisibleLength = this._lengthOf_infoContent();
            this.__widthToMove = 0;
            this.__animDurationFwd = "";
            this.__animDurationRev = "";
            this.__animNameFwd = "";
            this.__animNameRev = "";
        } 
      
        if (this.__scrollText) {        
          // console.log(this.constructor.name, "_startAnimation", this.getUniqueTempId(), "__scrollText", this.state.infoContent);

            
            FabStdBro.addAnimation(
                this.__animNameFwd, 
                    "0% {left: 0px; width: " + this.__widthTarget + "px;} " + 
                    "100% {left: -" + this.__widthToMove + "px; width: " + (this.__widthTarget + this.__widthToMove) + "px;}");
            this.getDOMElement().style.animation = this.__animNameFwd + " " + this.__animDurationFwd + " linear 5s 1 normal forwards running";

            this.__animListener = 
                this.getDOMElement().addEventListener("animationend", function() {          
                    var old_animationName = this.getDOMElement().style.animationName;
                    if (old_animationName === this.__animNameFwd) {
                        this.__waitTimeout = setTimeout(function() {
                            try { this._subStartAnimation_Reverse(); } catch(error) { this._shutdownListeners(); }
                        }.bind(this), 5000);
                    } else {
                        try { this._subStartAnimation_Forward(); } catch(error) { this._shutdownListeners(); }
                    }                
                }.bind(this), { once: false } );
        }
    }

    _subStopAnimation() {
        var elem = this.getDOMElement();
        if (elem) {
            if (elem.style.animation && elem.style.animation !== "none") {
                elem.style.animation = "none";  // empty
                FabStdBro.forceReflow(elem);  // trigger "reflow" => browser processes the style          
            }
        }
    }

    _subStartAnimation_Reverse() {
        this.getDOMElement().style.animation = "none";  // empty
        FabStdBro.forceReflow(this.getDOMElement());  // trigger "reflow" => browser processes the style    

        FabStdBro.addAnimation(
            this.__animNameRev, 
            "0% {left: 0px; width: " + this.__widthTarget + "px;} " + 
            "100% {left: -" + this.__widthToMove + "px; width: " + (this.__widthTarget + this.__widthToMove) + "px;}");
        this.getDOMElement().style.animation = 
            this.__animNameRev + " " + this.__animDurationRev + 
            " linear 0s 1 " + "reverse" + " forwards running";
    }

    _subStartAnimation_Forward() {

        if (!FabStdBro.isInViewport(this.getDOMElement())) {
            this._clearTimeout();
            this.__waitTimeout = setTimeout(function() {
                // console.log(this.constructor.name, "_subStartAnimation_Forward", "postponed", this.state.infoContent);
                this._subStartAnimation_Forward();    
            }.bind(this), 5000)
        } else {
            this.getDOMElement().style.animation = "none";  // empty
            FabStdBro.forceReflow(this.getDOMElement());  // trigger "reflow" => browser processes the style                      
            
            FabStdBro.addAnimation(
                this.__animNameFwd, 
                "0% {left: 0px;} " + 
                "100% {left: -" + this.__widthToMove + "px;}");                  

            this.getDOMElement().style.animation = 
                this.__animNameFwd + " " + this.__animDurationFwd + 
                " linear 5s 1 normal forwards running";
        }
    }

    _shutdownListeners() {

        this._clearTimeout();

        if (this.__animListener) {
            this.__animListener.removeEventListener();
            this.__animListener = undefined;
            // console.log(this.constructor.name, "_shutdownListeners()", this.getUniqueTempId(), "removeEventListener");
        }

        FabStdBro.freeAnimation(this.__animNameFwd);
        FabStdBro.freeAnimation(this.__animNameRev);
    }

    _clearTimeout() {
        if (this.__waitTimeout) {
            clearTimeout(this.__waitTimeout);
            this.__waitTimeout = undefined;
            // console.log(this.constructor.name, "_shutdownListeners()", this.getUniqueTempId(), "clearTimeout");
        }
    }
  
    render() {    

      var renderResult;

      // console.log(this.constructor.name, "render()", this.getUniqueTempId(), this.state.stage, this.state.infoContent);

      renderResult =
        <div  
            id={this.getUniqueTempId()}
            style={{
                position: "relative", 
                overflow: "hidden", 
                whiteSpace: "nowrap", 
                textOverflow: this.state.stage>=2?"ellipsis":"" 
            }}
            className={this.props.className}>      
            { this.state.stage===0?"":this.props.infoContent }
        </div>;

      return(renderResult);
    }  
}
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

export default class ControlledReactComponentRegister {
   /** Static function used to set values of DOM-Elements in a standardized way !
    *  @param {String=id_dom} id_name optional Default is property-name "id_dom" 
    */ 
    constructor(id_name) {
        if (!id_name) id_name = "id_dom";
        this.m_smCRC = new StdMap();
        this.m_id_name = id_name;
    }

    forEachCRC(funcCallbackEntryKeyIdx) {
        return(this.m_smCRC.forEach(funcCallbackEntryKeyIdx));
    }

    // *********************************************
    // ControlledReactComponents
    // **********************************************
    getCRC(id_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself) {
        if (this.m_id_name === "id_dom" && id_or_htmlElement instanceof HTMLElement) {
            var elem = id_or_htmlElement;
            var result = undefined;
            if (!!elem.id) {
                result = this.m_smCRC.get(elem.id);
            }
            if (!bForHtmlElements_ReturnNextParentCRCIfNoCRCItself || !!result || !elem.parentElement) return(result);
            return(this.getCRC(elem.parentElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself));
        } else {
            return(this.m_smCRC.get(id_or_htmlElement));
        }
    }

    registerControlledReactComponent(crc) {
        if (!!crc.props[this.m_id_name]) {
            this.m_smCRC.set(crc.props[this.m_id_name], crc);
        }
    }

    deregisterAndUnmountAll() {
        var iter = this.m_smCRC.keys();
        var result;
        var crc;
        while (!(result = iter.next()).done) {
            crc = this.m_smCRC.get(result.value);
            if (crc) crc.unmount(); // force unmount
            this.m_smCRC.delete(result.value);
        }
    }

    deregisterControlledReactComponent(crc) {
        if (!!crc.props[this.m_id_name]) {
            this.m_smCRC.delete(crc.props[this.m_id_name]);
        } 
    }
}
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import { doc } from "firebase/firestore";

export default class qryProcessor_User extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);                
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;        

        // console.log(this.getClassName(), "constructor", this.m_szUserId, "TriggerClassId", this.getId_ofTriggerClass());        
    }

    getClassName() {
        return("qryProcessor_User");    // to FIX the classname!
    }

    getMainUserId() {        
        return this.m_szUserId;
    }
    
    getCollectionPath() {
        return('users');
    }
    
    buildQuery() {
        return(doc(this.getWfCtrl().getFirestore(), this.getCollectionPath(), this.m_szUserId));
    }
    
    onRunCompleted() {      
        // console.log(this.getClassName(), "onRunCompleted", this.m_szUserId);
        super.onRunCompleted();
    }    
}
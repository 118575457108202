// import firebase from "firebase/app";
import "babel-polyfill";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
// import "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, getRedirectResult, signInWithRedirect, signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// import { getAuth, getRedirectResult, signInWithRedirect, GoogleAuthProvider } from "firebase/auth";
// import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";

export default class firebase_ctrl {
    constructor(firebaseConfig) {
        if (!firebaseConfig) console.error(this.constructor.name, "FirebaseCtrl", "firebaseConfig was not specified!");

        console.log(this.constructor.name, "Constructor", "firebase_ctrl");
        this._firebaseConfig = firebaseConfig;

        this._auth = undefined;
        this._smapResolver = new StdMap();
        this._cntAuthStateChanged = 0;
        this._unsubscribeListener_AuthStateChanged = undefined;

        // Initialize Firebase
        console.log(this.constructor.name, "Firebase-Config", this._firebaseConfig);
        this._firebaseApp = initializeApp(this._firebaseConfig);
        console.log(this.constructor.name, "AppName is " + this._firebaseApp.name, this.getStorage(this._firebaseApp)); // "[DEFAULT]"
    }

    getFirestore() {
        return(getFirestore(this._firebaseApp));
    }

    getStorage() {
        return(getStorage(this._firebaseApp));
    }

    _setPromiseTimeout(szPromiseCategory) {
        console.log(this.constructor.name, "_setPromiseTimeout", "PromiseCategory", szPromiseCategory);
        setTimeout(60*1000, () => {
            this._resolvePromise(
                szPromiseCategory, 
                {rc: "ERR", errorMessage: "Authentication timeout!"});
        });
    }

    _setPromiseResolver(szPromiseCategory, func_resolve) {
        this._smapResolver.set(szPromiseCategory, { func_resolve, szPromiseCategory });
    }

    _resolvePromise(szPromiseCategory, objResult) {
        var obj = this._smapResolver.get(szPromiseCategory);
        if (obj) {
            obj.func_resolve(objResult);
            this._smapResolver.delete(szPromiseCategory);
            console.log(this.constructor.name, "_resolvePromise", "promise found", "PromiseCategory", szPromiseCategory, "objResult", objResult);
        } else {
            console.log(this.constructor.name, "_resolvePromise", "event seen but no corresponding promise", "PromiseCategory", szPromiseCategory, "objResult", objResult);
        }
    }

    // *******************************************************************************************
    // setup Authentication-State listener
    // *******************************************************************************************
    setupAuthStateListener(funcUser_signin, funcUser_signout) {
        if (!this._auth) this._auth = getAuth(this._firebaseApp);
        
        // Listening for auth state changes.
        this._unsubscribeListener_AuthStateChanged =         
            onAuthStateChanged(this._auth, (userData) => {
                    ++this._cntAuthStateChanged;
                    if (userData) {
                        console.log(this.constructor.name, "onAuthStateChanged - signedIn", userData);     
                        funcUser_signin(userData);
                    } else {
                        // User is signed out.
                        console.log(this.constructor.name, "onAuthStateChanged - signedOut", userData);
                        funcUser_signout(userData);
                    }
                });
    }

    getEventCount_AuthStateChanged() {
        return(this._cntAuthStateChanged);
    }
    // *******************************************************************************************

    // *******************************************************************************************
    // signIn (login) with Email and Password
    // *******************************************************************************************
    signInWithEmailAndPassword(email, password) {
        this._signInPromise 
            = new Promise((resolve, reject) => {
                this._setPromiseResolver(firebase_ctrl.signIn, resolve);
                this._setPromiseTimeout(firebase_ctrl.signIn);
                this._sub_signInWithEmailAndPassword(email, password);
            });
        return(this._signInPromise);
    }

    _sub_signInWithEmailAndPassword(email, password) {
        console.log(this, "_sub_signInWithEmailAndPassword", email, password);
        
        var result =  
            firebase.auth().signInWithEmailAndPassword(email, password);

        result.catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            
            console.log(this.constructor.name, "_sub_signInWithEmailAndPassword", "Failed", errorCode, errorMessage);            
            this._resolvePromise(firebase_ctrl.signIn, 
                {rc: "ERR", errorMessage }
            );
        });

        result.then((user) => {
            console.log(this.constructor.name, "_sub_signInWithEmailAndPassword", "result");

            this._resolvePromise(firebase_ctrl.signIn, 
                {rc: "OK", user }
            );
        })

        result.finally(() => {
            console.log(this.constructor.name, "_sub_signInWithEmailAndPassword", "FINALLY");            
        })
    }

    // *******************************************************************************************
    // signUp (register new) with Email and Password
    // *******************************************************************************************
    signUpWithEmailAndPassword(email, password) {
        // emails must be provide as lowerCase
        email = email.toLowerCase();

        this._signUpPromise 
            = new Promise((resolve, reject) => {
                this._setPromiseResolver(firebase_ctrl.signUp, resolve);
                this._setPromiseTimeout(firebase_ctrl.signUp);
                this._sub_signUpWithEmailAndPassword(email, password);
            });
        return(this._signUpPromise);
    }

    _sub_signUpWithEmailAndPassword(email, password) {
        var result = 
            firebase.auth().createUserWithEmailAndPassword(email, password);
            
        result.catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;

            console.log(this.constructor.name, "_sub_signUpWithEmailAndPassword", "Failed", errorCode, errorMessage);            
            this._resolvePromise(firebase_ctrl.signUp, 
                {rc: "ERR", errorMessage }
            );
        });               

        result.then((user) => {
            console.log(this.constructor.name, "_sub_signUpWithEmailAndPassword", "result");

            this._resolvePromise(firebase_ctrl.signUp, 
                {rc: "OK", user }
            );
        })

        result.finally(() => {
            console.log(this.constructor.name, "_sub_signUpWithEmailAndPassword", "FINALLY");            
        })
    }
    // *******************************************************************************************

    // *******************************************************************************************
    // signIn with Google
    // *******************************************************************************************
    signInWithGoogle() {
        this._signInPromise 
            = new Promise((resolve, reject) => {
                this._setPromiseResolver(firebase_ctrl.signIn, resolve);
                this._setPromiseTimeout(firebase_ctrl.signIn);
                this._sub_signInWithGoogle();
            });
        return(this._signInPromise);
    }

    _sub_signInWithGoogle() {        
        
        console.log(this.constructor.name, "_sub_signInWithGoogle", "Start");
        
        var provider = new GoogleAuthProvider();
        if (!this._auth) this._auth = getAuth();
        
        this._auth.useDeviceLanguage();
        console.log(this.constructor.name, "_sub_signInWithGoogle", "provider", provider);
        signInWithPopup(this._auth, provider).then((result) => {
            console.log(this.constructor.name, "_sub_signInWithGoogle", result);
            // Object.getOwnPropertyNames(redirectResult).forEach((prop) => {
            //     console.log(prop, ":", result[prop]);
            // });
                
            if (result.credential) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = redirectResult.credential.accessToken;
            }
            
            if (result.user) {
                // The signed-in user info.
                var user = redirectResult.user;
                console.log(this.constructor.name, "_sub_signInWithGoogle", "result");

                this._resolvePromise(firebase_ctrl.signIn, 
                    {rc: "OK", user }
                );
            }
        })

        .catch((error) => {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;

            console.log(this.constructor.name, "_sub_signInWithGoogle", "Failed", errorCode, errorMessage);            
            this._resolvePromise(firebase_ctrl.signIn, 
                {rc: "ERR", errorMessage }
            );
        })  

        .finally(() => {
            console.log(this.constructor.name, "_sub_signInWithGoogle", "FINALLY");            
        });    

        // signInWithRedirect(this._auth, provider); 

        /*
        getRedirectResult(this._auth, provider).then((result) => {
            console.log(this.constructor.name, "result", result);
        });
        */

        /* .then((dummy) => {
            console.log(this.constructor.name, "signInWithRedirect", "dummy", dummy);
            var result = getRedirectResult(this._auth);
        
            result.then((redirectResult) => {
                console.log(this.constructor.name, "_sub_signInWithGoogle", redirectResult);
                // Object.getOwnPropertyNames(redirectResult).forEach((prop) => {
                //     console.log(prop, ":", result[prop]);
                // });
                    
                if (redirectResult.credential) {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    var token = redirectResult.credential.accessToken;
                }
                
                if (redirectResult.user) {
                    // The signed-in user info.
                    var user = redirectResult.user;
                    console.log(this.constructor.name, "_sub_signInWithGoogle", "redirectResult");
    
                    this._resolvePromise(firebase_ctrl.signIn, 
                        {rc: "OK", user }
                    );
                }
            });
    
            result.catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
    
                console.log(this.constructor.name, "_sub_signInWithGoogle", "Failed", errorCode, errorMessage);            
                this._resolvePromise(firebase_ctrl.signIn, 
                    {rc: "ERR", errorMessage }
                );
            });  
    
            result.finally(() => {
                console.log(this.constructor.name, "_sub_signInWithGoogle", "FINALLY");            
            });    
        });
        */
    }      
    // *******************************************************************************************

    // *******************************************************************************************
    // signOut
    // *******************************************************************************************
    signOut() {
        console.log(this.constructor.name, "signOut");
        if (firebase.auth() && firebase.auth().currentUser) {
            firebase.auth().signOut();
        } else {
            console.log(this.constructor.name, "signOut", "no active user");
            // nothing to do
            // location.reload();
        }
    }
    // *******************************************************************************************

    // *******************************************************************************************
    // send reset eMail-Password-Link
    // *******************************************************************************************
    sendPasswordResetEmail(email, urlToSignIn) {
        this._resetPwPromise
            = new Promise((resolve, reject) => {
                this._setPromiseResolver(firebase_ctrl.resetPw, resolve);
                this._setPromiseTimeout(firebase_ctrl.resetPw);
                this._sub_sendPasswordResetEmail(email, urlToSignIn);
            });
        return(this._resetPwPromise);
    }

    _sub_sendPasswordResetEmail(email, urlToSignIn) {
        var actionCodeSettings = undefined;
        if (urlToSignIn) {
            actionCodeSettings = {
                url: urlToSignIn
            };
        }
        /*
        var actionCodeSettings = {
            url: 'https://www.example.com/?email=user@example.com',
            iOS: {
            bundleId: 'com.example.ios'
            },
            android: {
            packageName: 'com.example.android',
            installApp: true,
            minimumVersion: '12'
            },
            handleCodeInApp: true
        };
        */
        var result = firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
        result.then(() => {
                // Password reset email sent.
                this._resolvePromise(firebase_ctrl.resetPw, 
                    {rc: "OK" }
                );
        });

        result.catch((error) => {
                // Error occurred. Inspect error.code.
                this._resolvePromise(firebase_ctrl.resetPw, 
                    {rc: "ERR", error }
                );
        });
    }
}

// constants
firebase_ctrl.resetPw = "resetPw";
firebase_ctrl.signIn = "signIn";
firebase_ctrl.signUp = "signUp";

import qryProcessor from "./fabbi_qry_processor.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor_Ticker from "./fabbi_htmlprocessor_ticker.js";
import qryProcessor_Manual from "./fabbi_qry_processor_manual.js";

export default class qryProcessor_Ticker extends qryProcessor_Manual {    
    constructor(props) {  
        super({ 
                htmlProcessorPrototype: HtmlProcessor_Ticker, 
                ...props 
            }
        );    
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;
    }

    getClassName() {
        return("qryProcessor_Ticker");    // to FIX the classname!
    }

    getMainUserId() {        
        return this.m_szUserId;
    }    
}
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
// import { Timestamp } from "firebase/firestore";
// window.firebase must be set!
import { getDownloadURL, ref } from "firebase/storage";

export default class StorageManager {
  /**
   * Constructor for Firebase-Storage-Management.
   * @constructor
   */
    constructor(firebaseCtrl) {
        this._firebaseCtrl = firebaseCtrl;
        this.m_smapSrcToUrl = new StdMap();
        this.m_smapSrcInProgress = new StdMap();
    }
    
    /**
    * Is used, to create a unique ID of a File in the storage. 
    * @param {String} szHeadId ID-String which is used to build a StorageFileId containing a unique timestamp.
    * @returns {String} The fully build-up-Id of the file.
    */
    buildStorageFileId(szHeadId) {        
        var tsNow = this._firebaseCtrl.getFirestore().Timestamp.now();        
        // console.log(tsNow);
        var szId = szHeadId + "_" + tsNow.seconds + "_" + tsNow.nanoseconds;
        // console.log(this.constructor.name, "STORAGE", szId);
        
        return(szId);
    }
    
    /**
     * Used, to put a file to the storage
     * @param {File} file File (type File) that shall be put into the file-storage, for example 
     * it's supplied via "files"-property of a DOM-Element.
     * @param {String} szHeadId ID-String which is used to build a unique Filename in the storage
     * @returns {Promise} The Promise-Object used for handling success/failure of the asynchronous put-action. 
     * Some finishing action can be taken via "then". The "resolve"-function is supplied with the "snapshot"-Data from the firebase
     * storage.
     */
    putFile(file, szHeadId) {
        let thisClass = this;
        let p1 = new Promise(function(resolve, reject) {
            let szId = thisClass.buildStorageFileId(szHeadId);
            let thisPromise = this;
            
            ref(thisClass._firebaseCtrl.getStorage(), szId).put(file).then(function(snapshot) {
                // UPLOADED!
                console.log("UPLOADED", szId, snapshot);            
                resolve(snapshot);  
            });    
        });
    
        return(p1); // return the Promise-object!
    }

    /**
     * Delete a file from the Storage
     * @param {String} szId Storage-ID of the file in the storage that shall be deleted.     
     * @returns {Promise} The Promise-Object used for handling success/failure of the asynchronous put-action. 
     * Some finishing action can be taken via "then". The "resolve"-function is supplied with the "snapshot"-Data from the firebase
     * storage.
     */
    deleteFile(szId) {
        var p1 = new Promise(function(resolve, reject) {            
            ref(thisClass._firebaseCtrl.getStorage(), szId).delete().then(
                function(snapshot) {
                    // DELETED, SUCCESS
                    console.log("DELETED", szId, snapshot);            
                    resolve(snapshot);                  
                },
                function(error) {
                    console.log("DELETION FAILED", szId, error);
                    reject(error);
                });    
        });
    
        return(p1); // return the Promise-object!
    }

    /**
     * Used, to get a file's-URL that is kept in the storage. For it last some time, till firebase delivers a URL, this
     * class buffers the results and returns URLs that have been requested before, almost immediately. A callback function "funcToUse"
     * must be supplied. This function is called, when the URL has been determined and is ready to be used.
     * @param {String} szSrc File-ID in the firebase-storage, from which a URL shall be determined.
     * @param {function} funcToUse Callback-Function that will be called when the URL-Determination-process has been finished.
     * The determined URL is a parameter of this function. funcToUse(url).
     * @returns {String} Undefined or - if a buffered URL was found - the buffered URL. 
     * (Nevertheless the Callback-Function will be called in all cases).
     */
    getURL(szSrc, funcToUse) {
        if (!szSrc || szSrc === "") {
            funcToUse(undefined);
            return(undefined);            
        }
        
        let szTmpSrc = szSrc;
        let url = this.m_smapSrcToUrl.get(szSrc);
        let thisClass = this;
        
        // do we have a buffered url ?
        if (!!url) {
            funcToUse(url);
            return(url);
        }
        
        var subMap = this.m_smapSrcInProgress.get(szSrc);
        let bFirst = false;
        if (subMap === undefined) {
            bFirst = true;
            subMap = new StdMap();            
        } 
        subMap.set(subMap.length+1, funcToUse); 
        // console.log(this.constructor.name, funcToUse, subMap.length+1)
        
        if (bFirst === true) {
            this.m_smapSrcInProgress.set(szSrc, subMap);
            getDownloadURL(ref(thisClass._firebaseCtrl.getStorage(), szSrc)).then(function(url) {                                        
                    let subMap = thisClass.m_smapSrcInProgress.get(szTmpSrc);                    
                    thisClass.m_smapSrcInProgress.delete(szTmpSrc);                    
                
                    thisClass.m_smapSrcToUrl.set(szTmpSrc, url);
                    if (subMap != undefined && subMap.length > 0) {
                        let iter = subMap.values();
                        let result = iter.next();

                        while (!result.done) {                            
                            let tmpFunc = result.value;                            
                            // the "value" should be a function and is herewith executed ...
                            tmpFunc(url);
                            result = iter.next();                
                        }
                        
                    }                    
                }).catch(function(error) {
                    thisClass.m_smapSrcInProgress.delete(szTmpSrc);
                    funcToUse(undefined);
                });            
        }            
        
        return(undefined);
    }
}

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcSearchModal_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._qryP = this.props.qryP; // this.getWfCtrl().m_listenerUserInvitations;
        // set a signal "when no html-data-can be set! Selfdestruction!"
        this._htmlP = this._qryP.getTopHtmlProcessor();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        console.log(this.constructor.name, "componentWillUnmount()");
        // inform the htmlProcessor that there's no longer 
        // a connection ...
        if (this._htmlP) this._htmlP.onComponentWillUnmount(this);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        setTimeout(() => { console.log(this.constructor.name, "componentDidUpdate", "buildAll"); this._htmlP.buildAll(false)}, 0);
    }

    componentDidMount() {
        super.componentDidMount();
   
        setTimeout(() => { console.log(this.constructor.name, "componentDidMount", "buildAll");  this._htmlP.buildAll(false)}, 0);
    }

    render() {      
        return(
            <div id={this.getIdDOM()}>
                <div className={"modal-content"}>          
                    <div className={"row"}>                   
                        <div style={
                            {width: "100%", maxHeight: "400px", overflowY: "auto" }} 
                            id={this._htmlP.getId_Div_Section()}>    
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}
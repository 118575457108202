import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class CrcNavSide_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this.state.bNavExpanded = true;
        this.state.bNavLock = true;
    }

    componentDidMount() {
        super.componentDidMount();

        // let's initialize collapsibles!
        // we look in the "DOM"

        // this is a jquery-command ... must be imported before!
        $("#" + this.getIdDOM() + " .collapsible").collapsible();
    }

    render() {        
        var arrResult = [];
        var p = this.getParent();

        arrResult.push(
            <aside 
                id={this.getIdDOM()} key={this.getIdDOM()} 
                className={
                    "sidenav-main" 
                    + (this.state.bNavExpanded?" nav-expanded":" nav-collapsed")
                    + (this.state.bNavLock?" nav-lock":"")
                    + " nav-collapsible sidenav-light sidenav-active-square"}

                onMouseEnter={(event) => {
                        if (!this.state.bNavExpanded) {
                            p.setState_Children({ bNavExpanded: true });
                        }
                    }
                }

                onMouseLeave={(event) => {
                        if (!this.state.bNavLock) {
                            p.setState_Children({ bNavExpanded: false });
                        }
                    }
                }
                >

                <div className={"brand-sidebar"}>
                    <h1 className={"logo-wrapper"}>
                        <a className={"brand-logo darken-1"} href="index.html">
                            <span>F</span><span className={"logo-text hide-on-med-and-down"}>Fab's</span>
                        </a>
                        <a className={"navbar-toggler"} href="#" onClick={() => {
                            p.setState_Children({ bNavLock: this.state.bNavLock?false:true });
                        }}>
                            <i className={"material-icons"}>
                                {this.state.bNavLock?"radio_button_checked":"radio_button_unchecked"}
                            </i>
                        </a>
                    </h1>
                </div>
            
                <ul className={"sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"} 
                    id="slide-out" 
                    data-menu="menu-navigation" 
                    data-collapsible="menu-accordion">
                        
                    <li className={"bold"}>
                        <a className={"collapsible-header waves-effect waves-cyan"} href="#">
                            <i className={"material-icons"}>settings_input_svideo</i>
                            <span className={"menu-title"} data-i18n="">Dashboard</span>
                            <span className={"badge badge pill orange float-right mr-10"}>3</span>
                        </a>

                        <div className={"collapsible-body"}>
                            <ul className={"collapsible collapsible-sub"} data-collapsible="accordion">
                                <li><a className={"collapsible-body"} href="" data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>Modern</span></a>
                                </li>
                                <li><a className={"collapsible-body"} href="" data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>eCommerce</span></a>
                                </li>
                                <li><a className={"collapsible-body"} href="" data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>Analytics</span></a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className={"navigation-header"}>
                        <a className={"navigation-header-text"}>Applications</a>
                        <i className={"navigation-header-icon material-icons"}>more_horiz</i>
                    </li>

                    <li className={"bold"}>
                        <a className={"waves-effect waves-cyan"} href="#">
                            <i className={"material-icons"}>mail_outline</i>
                            <span className={"menu-title"} data-i18n="">Mail</span>
                            <span className={"badge new badge pill pink accent-2 float-right mr-10"}>5</span>
                        </a>
                    </li>

                    <li className={"bold"}>
                        <a className={"collapsible-header waves-effect waves-cyan" + (p.getActiveModule() === "comm"?" active":"")} 
                            onClick={ 
                                (event) => { p.setActiveModule("comm"); return(true); 
                            }}>
                            <i className={"material-icons"}>chat_bubble_outline</i>
                            <span className={"menu-title"} data-i18n="">Comm</span>
                        </a>

                        <div className={"collapsible-body"}>
                            <ul className={"collapsible collapsible-sub"} data-collapsible="accordion">
                                <li>
                                    <a  className={"collapsible-body"} data-i18n="" 
                                        onClick={ (event) => { g_wfCtrl.dlgReceivedInvitations(); }} >

                                        <i className={"material-icons"}>
                                            radio_button_unchecked
                                        </i>
                                        <span>Received Invitations</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li className={"bold"}>
                        <a className={"collapsible-header waves-effect waves-cyan"}>
                            <i className={"material-icons"}>face</i>
                            <span className={"menu-title"} data-i18n="">User</span>
                            <span className={"badge badge pill purple float-right mr-10"}>10</span>
                        </a>

                        <div className={"collapsible-body"}>
                            <ul className={"collapsible collapsible-sub"} data-collapsible="accordion">
                                <li><a className={"collapsible-body"} onClick={ (event) => { g_wfCtrl.editUser2(); }} data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>User Profile</span></a>
                                </li>
                                <li><a className={"collapsible-body"} onClick={ (event) => { g_wfCtrl.signIn(); }} data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>Login</span></a>
                                </li>
                                <li><a className={"collapsible-body"} onClick={ (event) => { g_wfCtrl.signOut(); }} data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>Logout</span></a>
                                </li>                
                                <li><a className={"collapsible-body"} onClick={ (event) => { g_wfCtrl.signUp(); }} data-i18n=""><i className={"material-icons"}>radio_button_unchecked</i><span>Register</span></a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    {
                        this.getWfCtrl().isUserAuthenticated()?

                            <li className={"bold"}><a className={"waves-effect waves-cyan" + (p.getActiveModule() === "statistics"?" active":"")} onClick={ (event) => { 
                                this.getWfCtrl().setActiveModule("statistics");  
                                }}>

                                <i className={"material-icons"}>show_chart</i><span className={"menu-title"} data-i18n="">Timeseries</span></a>                                
                            </li>

                        :   // if not authenticated NOTHING
                        []                    
                    }

                    {
                        this.getWfCtrl().isUserAuthenticated()?

                            <li className={"bold"}><a className={"waves-effect waves-cyan" + (p.getActiveModule() === "google"?" active":"")} onClick={ (event) => { 
                                this.getWfCtrl().setActiveModule("google");  
                                }}>
                                <i className={"material-icons"}>show_chart</i><span className={"menu-title"} data-i18n="">Maps</span></a>                                
                            </li>

                        :   // if not authenticated NOTHING
                        []                    
                    }
                </ul>

                <div className={"navigation-background"}></div>
                
                <a className={"sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"} href="#" data-target="slide-out">
                    <i className={"material-icons"}>menu</i>
                </a>
            </aside>
        );

        return(arrResult);
    }    
}
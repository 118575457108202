import React from 'react';
import CrcWait_01 from "./fabbi_crc_wait_01.jsx";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";

export default class CrcModalWait_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        return(
            <div id={this.getIdDOM()} className={"modal-content"} style={{marginTop: "35vh"}}>
                <div className="fab-center" style={{height: "25vh", width: "25vh"}}>
                    <CrcWait_01/>
                </div>
            </div>
      );
    }
}
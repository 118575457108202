import React from 'react';
import NumberFormat from 'react-number-format';
import Crc_SC_Base from "./fabbi_crc_sc_base.jsx";
import { DataGrid } from '@material-ui/data-grid';
import numeral from "numeral";
import "numeral/locales/de.js";    // ATTENTION! THIS IS ESSENTIAL ... 
import TextField from '@material-ui/core/TextField';
// import { GRID_CELL_EDIT_PROPS_CHANGE } from '@material-ui/data-grid/constants/eventsConstants';
import { withStyles } from '@material-ui/core/styles';
import Stack from '@material-ui/core/Stack';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const styles = {
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        /* border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 48,
        padding: '0 30px',
        */
        '& .MuiDataGrid-cell.MuiDataGrid-cellEditing': {
            alignItems: "center",
            // padding: "2px 10px 2px 10px",
            // display: 'block',
            boxShadow: "none", // theme.shadows[2],
            backgroundColor: "transparent", // theme.palette.background.paper,
            '&:focus-within': {
                // outline: `solid ${theme.palette.primary.main} 1px`,
                outlineOffset: '-2px',
                // outlineOffset: '0px'
            }
        }
    }
};

class Crc_SC_WeightsSelect_01 extends Crc_SC_Base {
    constructor(props) {
        super(props);

        console.log(this.constructor.name, "init", "this", this, "props", this.props);

        numeral.locale("de");          

        this._changedRowValues = {};

        this.state._page = 0;
        this.state._rows = props.rows;
        this.state._columns = props.columns;

        this.state._editRowsModel = {};
        
        this.state._columns[3].valueGetter = (params) => { 
                var changed = this.getChangedRowField(params.id, params.field);
                return(changed?changed:params.value); 
            };
        this.state._columns[3].valueFormatter = (obj) => { 
            obj.formattedValue = numeral(obj.value).format("0,0%"); 
            // console.log("valueFormatter", obj); 
            return(obj.formattedValue); 
        }
        this.state._columns[3].renderCell = (params) => {
            // this._api = params.api; // catch the reference to the datagrid-api
            return(
                <div> { params.formattedValue } </div>
            );
        }
        
        this.state._columns[3].renderEditCell  = (params) => { 
            // this._api = params.api; // catch the reference to the datagrid-api
            console.debug("renderEditCell", "value", params.value, "formattedValue", params.formattedValue); 

            if (!params.value && params.formattedValue) {
                params.value = numeral(params.formattedValue).value();
                // console.log("renderEditCell", "EMPTY VALUE"); 
            }

            // NumberFormat ... dispatches all properties to the "inputComponent" which is - here - TextField
            return(
                <NumberFormat
                    // InputProps={{ style: { textAlign: "end", padding: "0px" } }}
                    inputProps={{ style: { textAlign: "end", padding: "3px" } }}
                    // autoFocus={true}
                    // disableUnderline={true}

                    onKeyDown={(event) => {
                        // console.debug(this.constructor.name, "TextField-renderEditCell-1", "onKeyDown()", "event", event, "value", params.value);
                        if (["ArrowDown", "ArrowUp", "Enter"].includes(event.code)) { 
                            this.setEditRowsModel(params.id, params.field);
                            this.changeCellValue(params, event);
                        }
                    }}
                
                    customInput={TextField}
                    inputRef =  { (el) => { 
                                        if (el && params.hasFocus) { 
                                            if (this._timeoutInput) clearTimeout(this._timeoutInput);
                                            this._timeoutInput = setTimeout(() => { 
                                                el.focus();
                                            }, 0)
                                        }
                                    }
                                }
                    decimalScale={0}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    isNumericString
                    suffix="%"

                    defaultValue={(params.value == 0 || params.value == "" || !params.value)?"":(Math.round(params.value*100))} 
                    onFocus={ () => console.log("onFocus") }
                    onBlur={ (event) =>  this.changeCellValue(params, event) }
                />);
        }

        this._result = {};
    }

    /** 
     * @params {Object} params renderParams of the cell
     * @params {Object} event  The event on which occurrence the cell-change is triggered (event.target.value is used to determine the actual vallue of the input)
     * 
    */
    changeCellValue(params, event) {
        if (event.target && event.target.value && this.isColumnEditable(params.field)) {
            var val = event.target.value;
            var col = params.api.getColumn(params.field);

            if (col.type === "number") {
                val = numeral(event.target.value).value();
            }

            if (!this._changedRowValues[params.id]) this._changedRowValues[params.id] = {};
            this._changedRowValues[params.id][params.field] = val;
        }
    }

    /** */
    getChangedRow(id) {
        return(this._changedRowValues[id]); 
    }

    /** */
    getChangedRowField(id, field) {
        var changedRow = this.getChangedRow(id);
        return(changedRow?changedRow[field]:undefined);
    }

    /** @override */
    getResult() {
        var result = 
            this.state._rows.map((row, idx) => {
                var changedRow = this.getChangedRow(row.id);
                var newRow = {... row};
                if (changedRow) Object.keys(changedRow).forEach((key) => { if (changedRow[key]) newRow[key] = changedRow[key] });
                return(newRow);
            });

        return(result);
    }

    render() {
        var result = 
            <div id={this.getIdDOM()}>
                <Stack style={{height: "300px"}}>
                    <DataGrid 
                        density={"compact"}
                        classes={{ root: this.props.classes.root }}
                        hideFooterSelectedRowCount={true}
                        disableSelectionOnClick 
                        
                        // autoPageSize
                        pagination
                        page={this.state._page}
                        onPageChange= {(params) => { console.debug(this.constructor.name, "onPageChange", params); this.setState({ _page: params.page }) } }
                        pageSize={5}
                        rowsPerPageOptions={[]}

                        rows={this.state._rows} 
                        columns={this.state._columns}
                        editRowsModel={ this.state._editRowsModel }
                        
                        // onEditChangeCommitted={(params) => console.log("committed") }
                        // onKeyDown={(event) => { console.log(this.constructor.name, "DataGrid-onKeyDown()", params, event); }}
                        // onCellDoubleClick={(params) => console.log(this.constructor.name, "onCellDoubleClick", params) }
                        onCellKeyDown={ (params, event) => {
                                    console.log(this.constructor.name, "DataGrid-onCellKeyDown()", params, event, "containerSizes", params.api.state.containerSizes, "rowIndex", params.api.getRowIndex(params.id)); 

                                    if (["ArrowLeft", "ArrowRight"].includes(event.code)) {
                                        try { params.api.setCellFocus(params.id, params.field); } catch(e) { }

                                        // console.log("getVisibleColumns", params.api.getVisibleColumns());
                                        var vc = params.api.getVisibleColumns();
                                        
                                        var bStopNext = false;
                                        var newCol = undefined;
                                        var lstCol = undefined;
                                        vc.some((col) => {
                                            if (bStopNext) {
                                                newCol = col;
                                                return(true);
                                            }
                                            if (col.field === params.field) { 
                                                if (event.code === "ArrowLeft" ) { 
                                                    newCol = lstCol;
                                                    return(true);
                                                } else bStopNext = true;
                                            }
                                            lstCol = col;
                                            return(false);
                                        });

                                        if (newCol) {
                                            try { params.api.setCellFocus(params.id, newCol.field); } catch(e) { }
                                        }

                                        event.preventDefault();
                                        event.stopPropagation();
                                    } else 
                                    if (["ArrowUp","ArrowDown","PageDown","PageUp","Enter", "End", "Home"].includes(event.code)) {
                                        var pi = this.getPageInfo(params.api);
                                        var nMove = 1;
                                        if (event.code.startsWith("Home")) nMove = -9999999;
                                        if (event.code.startsWith("End")) nMove = 9999999;
                                        if (event.code.startsWith("Page")) nMove = pi.pageSize;
                                        if (event.code.endsWith("Up")) nMove = nMove * (-1);

                                        var rowIdx = params.api.getRowIndex(params.id);
                                        var next_rowIdx = rowIdx + nMove;

                                        // correct to the possible area
                                        next_rowIdx = Math.min(Math.max(next_rowIdx, 0), params.api.getRowsCount()-1);
                                        
                                        var pageMove = 0;
                                        var idxKorr = 0;
                                        while (next_rowIdx > pi.idxRow_End+idxKorr) {
                                            ++pageMove; 
                                            idxKorr += pi.pageSize;
                                        }
                                        while (next_rowIdx < pi.idxRow_Start+idxKorr) {
                                            --pageMove;
                                            idxKorr -= pi.pageSize; 
                                        }

                                        var funcToDo = () => {
                                            console.debug(this.constructor.name, "we do, what we have to");
                                            /*
                                            pi = this.getPageInfo(params.api);

                                            next_rowIdx = Math.min(
                                                Math.max(
                                                    next_rowIdx, 
                                                    pi.idxRow_Start 
                                                ), pi.idxRow_End
                                            );
                                            */
                                            var next_idRow = params.api.getRowIdFromRowIndex(next_rowIdx);

                                            console.debug(this.constructor.name, "funcToDo", next_idRow, next_rowIdx);

                                            // try { params.api.setCellMode(rowId, params.field, 'view'); } catch(e) { }
                                            try { params.api.selectRow(next_idRow); } catch(e) { }
                                            try { params.api.setCellFocus(next_idRow, params.field); } catch(e) { }
                                            // try { params.api.setCellMode(next_idRow, params.field, cellMode); } catch(e) { }
    
                                            this.setEditRowsModel(next_idRow, params.field);
                                            this.scrollToIdx(params.api, next_rowIdx);    
                                        }

                                        console.log(this.constructor.name, "pageMove", pageMove, idxKorr);

                                        if (pageMove === 0) {
                                            funcToDo();
                                        } else {
                                            // order update and add to do-Function
                                            this.setState({_page: pi.page + pageMove });
                                            this.doOnNextComponentUpdate(funcToDo);
                                        }
                                        
                                        event.preventDefault();
                                        event.stopPropagation();
                                    } else {
                                        if (params.cellMode !== "edit") {                                       
                                            try { params.api.setCellFocus(params.id, params.field); } catch(e) { }
                                            this.setEditRowsModel(params.id, params.field);

                                            // other key
                                            event.preventDefault();
                                            event.stopPropagation();
                                        }
                                    }
                                } 
                            }   
                    />
                    <IconButton onClick={() => this.onSet() }><CheckCircleOutlineIcon aria-label="ok"/></IconButton>
                </Stack>
            </div>;
        return(result);
    }

    isColumnEditable(field) {
        return(this.state._columns.some((col) => (col.field === field && !!col.editable)));
    }

    setEditRowsModel(id, field) {
        var newRows = {};
        if (this.isColumnEditable(field)) {             
            newRows = { 
                    [id]: { 
                        [field]: { 
                                mode: "edit" 
                            } 
                        }
                };
        }
        this.setState({ _editRowsModel: newRows});
    }

    getPageInfo(api) {
        var pageSize = api.state.pagination.pageSize;
        var page = api.state.pagination.page;

        return({
            page,
            pageSize,
            pageCount:      api.state.pagination.pageCount,
            idxRow_Start:   page*pageSize,
            idxRow_End:     Math.min((page+1)*pageSize - 1, api.getRowsCount()-1)
        });
    }

    scrollToIdx(api, idx) {
        var scrPos = api.getScrollPosition();
        var cS = api.state.containerSizes;
        var tS = cS.totalSizes;
        var totalHeight = tS.height;
        var cntVP = cS.viewportPageSize;
        var cntVR = cS.virtualRowsCount;
        var vS = api.state.viewportSizes;
        var viewportHeight = vS.height;
        var rowHeight = api.state.density.rowHeight;
        var pageSize = api.state.pagination.pageSize;
        var page = api.state.pagination.page;

        // idx = idx - (page*pageSize);
        while (idx-pageSize >= 0) idx = idx-pageSize;
        if (idx < 0 || idx > pageSize-1) return; // do nothing

        // console.log(this.constructor.name, "DataGrid-onCellKeyDown()", "setting", next_idRow, "to edit mode", params.api.getScrollPosition(), "height", event.target.offsetHeight, "event.target", event.target); 

        // params.api.scroll({ left: 0, top: params.api.getScrollPosition().top + (nMove*event.target.offsetHeight) });
        
        var dstPos = Math.max(0,(totalHeight-viewportHeight)*(((idx<cntVR/2)?(idx-1):(idx+1))/cntVR));
        // console.log("SCROLL To", "api", api, api.getState(), "idx", idx, "pos", dstPos, "api", api, totalHeight, viewportHeight, idx, cntVR, api.state);
        
        api.forceUpdate();
        this.doOnNextComponentUpdate(() => {        
            api.scroll({ left: scrPos.left, top: dstPos });
        });
        // setTimeout(() => api.scroll({ left: scrPos.left, top: dstPos }), 100);
        // console.log("SCROLL To", "new Scrollpos", api.getScrollPosition());
    }
}

export default withStyles(styles)(Crc_SC_WeightsSelect_01);
// Original JavaScript code by Chirp Internet: www.chirp.com.au
// Please acknowledge use of this code by including this header.

import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

export default class Hilitor {
    constructor(id, tag) {
        // private variables
        this.targetNode = document.getElementById(id) || document.body;
        this.hiliteTag = tag || "MARK";
        this.skipTags = new RegExp("^(?:" + this.hiliteTag + "|SCRIPT|FORM|SPAN)$");
        this.colors = ["#ff6", "#a0ffff", "#9f9", "#f99", "#f6f"];
        this.wordColor = [];
        this.colorIdx = 0;
        this.matchRegExp = "";
        this.openLeft = false;
        this.openRight = false;
        this.m_smOccurences = new StdMap();

        // characters to strip from start and end of the input string
        this.endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', "g");

        // characters used to break up the input string into words
        this.breakRegExp = new RegExp('[^\\w\'-äöüÄÖÜ]+', "g");
    }

    setEndRegExp(regex) {
        this.endRegExp = regex;
        return this.endRegExp;
    }

    setBreakRegExp(regex) {
        this.breakRegExp = regex;
        return this.breakRegExp;
    }

    setMatchType(type) {
        switch(type)
        {
            case "left":
                this.openLeft = false;
                this.openRight = true;
                break;

            case "right":
                this.openLeft = true;
                this.openRight = false;
                break;

            case "open":
                this.openLeft = this.openRight = true;
                break;

            default:
                this.openLeft = this.openRight = false;
        }
    }

    setRegex(input) {
        input = input.replace(this.endRegExp, "");
        // console.log("hilitor", "1", input);
        input = input.replace(this.breakRegExp, "|");
        // console.log("hilitor", "2", input);
        input = input.replace(/^\||\|$/g, "");
        // console.log("hilitor", "3", input);
        if(input) {
            var re = "(" + input + ")";
            if(!this.openLeft) {
                re = "\\b" + re;
            }
            if(!this.openRight) {
                re = re + "\\b";
            }
            // console.log("hilitor", re, input);
            this.matchRegExp = new RegExp(re, "i");            
            return this.matchRegExp;
        }
        return false;
    }

    getRegex() {
        var retval = this.matchRegExp.toString();
        retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
        retval = retval.replace(/\|/g, " ");
        return retval;
    }

    // recursively apply word highlighting
    hiliteWords(node, entry_node) {
        if(node === undefined || !node) return;
        if(!this.matchRegExp) return;        
        if(this.skipTags.test(node.nodeName)) return; 
        
        if (!entry_node) {
            switch (node.tagName) {
                case "LI":
                case "DIV":
                    var reDivEntry = /(.*?-div-entry.*?)/;
                    if (!!reDivEntry.exec(node.id)) entry_node = node;                
                    break;
            }                
        }

        if(node.hasChildNodes()) {
            for(var i=0; i < node.childNodes.length; i++) {
                this.hiliteWords(node.childNodes[i], entry_node);
            }
        }

        if(node.nodeType == 3) { // NODE_TEXT
            var nv;
            if((nv = node.nodeValue) && (this.regs = this.matchRegExp.exec(nv))) {
                var foundTxt = this.regs[0];
                var foundTxtLC = this.regs[0].toLowerCase();

                if(!this.wordColor[foundTxtLC]) {
                    this.wordColor[foundTxtLC] = this.colors[this.colorIdx++ % this.colors.length];
                }

                var match = document.createElement(this.hiliteTag);
                match.appendChild(document.createTextNode(foundTxt));
                match.style.backgroundColor = this.wordColor[foundTxtLC];
                match.style.color = "#000";

                var after = node.splitText(this.regs.index);
                after.nodeValue = after.nodeValue.substring(foundTxt.length);
                node.parentNode.insertBefore(match, after);

                console.log(this.constructor.name, "node", node, "parentElement", node.parentElement);

                this.m_smOccurences.set("result_" + (1+this.m_smOccurences.size), {
                    txt_node: nv,
                    node_entry: entry_node,
                    id_node: node.id,
                    id_div_entry: !!entry_node?entry_node.id:undefined,
                    div_entry: entry_node
                });

                if (!!entry_node) {
                    // console.log("hilitor", entry_node.id, entry_node);
                    // entry_node.scrollIntoView({ behavior: "smooth", block: "start" });                    
                }
            }
        }
    }

    // remove highlighting
    remove() {
        var arr = document.getElementsByTagName(this.hiliteTag);
        var el;
        while(arr.length && (el = arr[0])) {
            var parent = el.parentNode;
            parent.replaceChild(el.firstChild, el);
            parent.normalize();
        }
        // delete all entries!
        this.m_smOccurences.clear();
    }

    // start highlighting at target node
    apply(input) {
        this.remove();
        if(input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ""))) {
            return;
        }
        if(this.setRegex(input)) {            
            this.hiliteWords(this.targetNode);

            this.m_smOccurences.log("searchLog");
        }
        return this.matchRegExp;
    }
}
import React from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import * as FabStdBro from "./fabbi_standardbrowser.js";

const propStyle_Default = {
    position: "absolute",
    top: "10px",
    left: "1vw",
    right: "unset",
    height: "98vh", 
    width: "98vw",
    maxHeight: "85%",
    opacity: 1,
    zIndex: 1000, 
    display: "block",
    overflow: "auto"
};

const propStyle_Middle1 = {
    position: "absolute",
    left: "25vw",
    right: "unset",
    top: "5vh",
    height: "80vh", 
    width: "50vw",
    maxHeight: "100%",
    opacity: 1,
    zIndex: 1001, 
    display: "block",
    overflow: "auto"
};

const propStyle_DropDown = {
    top: "0px",
    left: "0px",
    right: "unset",
    position: "relative",
    height: "200px", 
    width: "100%",
    maxHeight: "85%",
    opacity: 1,
    zIndex: 1000, 
    display: "block",
    overflow: "auto"
};

const propStyle_FullScreen = {
    top: "0px",
    left: "0px",
    right: "unset",
    height: "100vw", 
    width: "100vw",
    maxHeight: "100%",
    opacity: 1,
    zIndex: 1000, 
    display: "block",
    overflow: "auto"
};

export default class CrcModal_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        this._modalFrameCrcProps = {... props.modalFrameCrcProps};
        this._modalContentCrcPrototype = props.modalContentCrcPrototype;
        this._modalContentCrcProps = { ... props.modalContentCrcProps};

        this._closeOnOutsideClick = true;
    }

    handleClickAway(event) {

        var crcContent = this.getWfCtrl().getCRC(this._modalContentCrcProps.id_dom);

        /*
        if (FabStdBro.getZIndex(event.target) > FabStdBro.getZIndex(getDomElement(this._modalContentCrcProps.id_dom))) {
            console.debug();
        }
        */

        console.debug(this.constructor.name, "handleClickAway", this, "crcContent", crcContent);
        if (crcContent && crcContent.isClickAwayAllowed) {
            var bCheck = crcContent.isClickAwayAllowed(event);
            console.debug(this.constructor.name, "handleClickAway", "isClickAwayAllowed", "bCheck", bCheck);
            if (!bCheck) return;    // don't do anything!
        }

        var dom = this.getDOMElement();
        if (dom) {
            // console.log(this.constructor.name, "MODAL2", "click", "TH", dom.offsetTop, dom.offsetHeight, "LW", dom.offsetLeft, dom.offsetWidth, "XY", event.clientX, event.clientY); 
            var bCloseForbidden = false; 
            this.forEachChildCRC((crcChild, id) => {
                console.debug(this.constructor.name, "handleClickAway", "CHECK IF MAY CLOSE", "id", id, "func", crcChild.modalParentMayClose, "crcChild", crcChild);
                if (crcChild.modalParentMayClose) {
                    if (!crcChild.modalParentMayClose(event)) { 
                        bCloseForbidden = true;
                        console.log(this.constructor.name, "handleClickAway", "CLOSE FORBIDDEN BY CHILD");
                    }
                }
            });
        }

        console.debug(this.constructor.name, "handleClickAway", event);
        // a click outside shall not be propagated ...
        event.stopPropagation();
        event.preventDefault();  
        
        if (!bCloseForbidden && this._closeOnOutsideClick) {
            console.debug(this.constructor.name, "handleClickAway", "closeModal", "event", event, "dom", dom);
            this.close();
        }          
    }

    close() {
        console.log(this.constructor.name, "close()");
        CrcModal_01.closeModal(this.getWfCtrl(), this.getIdDOM());
        
        // if a close property exists we use it ...
        if (this.props.onClose) this.props.onClose();
    }

    componentDidMount() {
        super.componentDidMount();

        console.log(this.constructor.name, "start animate");
        // this.animate();
    }
  
    animate() {
        console.log(this.constructor.name, "componentDidMount()", this.getParentDOMElement());

        var dom = // this.getParentDOMElement();
                this.getDOMElement();
        
        /*
        var pxMove = -500;
        var pxLeftFrom = dom.offsetLeft;
        var pxLeftTo = dom.offsetLeft + pxMove;

        var szAnim = 
                "0%   {left: " + pxLeftFrom  + "px; width: " + (dom.offsetWidth + 0     ) + "px } " + 
                "100% {left: " + pxLeftTo    + "px; width: " + (dom.offsetWidth - pxMove) + "px } ";
        var szAnimName = this.getIdDOM() + "_anim";
        */
        var minW = 50;
        var szAnim = 
                "0%   { " +
                    "left: " + dom.offsetLeft + ((dom.offsetWidth-minW)/2)   + "px; " +
                    "width: " + (minW + 0     ) + "px; "+ 
                "} " + 
                "100% { " + 
                    "left: " + dom.offsetLeft   + "px; " + 
                    "width: " + dom.offsetWidth + "px;  " + 
                "} ";
        var szAnimName = this.getIdDOM() + "_anim";
        console.log(this.constructor.name, "szAnim", szAnim, dom);

        // set Animation running!
        this._animationRunning = true;

        FabStdBro.addAnimation(szAnimName, szAnim);                  
        
        var duration = 0.5;
        var delay = 0
        var szAnimCmd = szAnimName + " " + duration + "s" + " ease " + delay + "s 1 normal forwards running";
        dom.style.animation = szAnimCmd;

        var onAnimationStart = function() {
            console.log('Animation started');
        }
        var onAnimationEnd = () => {
            console.log('Animation ended');
            this._animationRunning = false;
            dom.removeEventListener("animationstart", onAnimationStart);
            dom.removeEventListener("animationend", onAnimationEnd);
        }
        dom.addEventListener('animationstart', onAnimationStart);
        dom.addEventListener('animationend', onAnimationEnd);

        FabStdBro.freeAnimation(szAnimName);                  

        console.log(this.constructor.name, "componentDidMount()", "anim", szAnim, "animCmd", szAnimCmd);
    }

    render() {    
       
        var renderResult;

        console.log(this.constructor.name, "render", "MODALPROPS", this.props.modalFrameCrcProps);

        renderResult =
            <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={(event) => { 
                    if (this.props?.modalFrameCrcProps?.onClickAway) {
                        this.props.modalFrameCrcProps.onClickAway(event);
                    } else {
                        this.handleClickAway(event);
                    }
                }}
                >
                <div  
                    // onAnimationStart={() => { console.log(this.constructor.name, "animate started"); } }
                    // onAnimationEnd  ={() => { if (this._animationRunning) { console.log(this.constructor.name, "animate ended"); this._animationRunning = false; this.setState({}); } } }
                    id={this.getIdDOM()}
                    className={
                        this._modalFrameCrcProps.className?this._modalFrameCrcProps.className:"modal open"
                    }
                    style={{
                        // border: "5px solid red",
                        ...CrcModal_01.propStyle_Default,
                        ...this._modalFrameCrcProps.style
                        }}>
                    { 
                        // if isClosing ... then he Childs must be eliminated
                        (!this.isClosing() /* && this.isMounted() && !this._animationRunning */)?
                        React.createElement(
                            this._modalContentCrcPrototype, 
                            { 
                                ...this._modalContentCrcProps,
                                wfCtrl: this.getWfCtrl(), 
                                parent: this 
                            }):undefined
                    } 
                </div>
            </ClickAwayListener>;

        return(renderResult);
    }  

    /**
     * STATIC function
     * Opens a ControlledReactComponent as a modal window
     * @param {String} crcInstance The ControlledReactComponent that shall be used as ModalInput.
     * @param {boolean} [bOpenDismissible=false] Optional Variable. If the modal window shall be "removable" 
     * by clicking somewhere outside of it, than you should set this one to "true".   
     *       
     * @return {Object} The DOM-Object of the modal created.
     */        
    static openAsModal(wfCtrl, 
                crcFrameProps, 
                crcContentPrototype, 
                crcContentProps
            ) {

        console.log("openAsModal", "props", crcContentProps);

        var modalContentCrcProps = {...crcContentProps};
        if (!modalContentCrcProps.id_dom) {
            modalContentCrcProps.id_dom = ControlledReactComponent.getNewUniqueTempId();
        }

        if (!modalContentCrcProps.id_key) {
            modalContentCrcProps.id_key = modalContentCrcProps.id_dom;
        }

        return(wfCtrl.addVisible(
            CrcModal_01, 
            {
                wfCtrl,
                id_dom:                     crcFrameProps.id_dom,
                id_child:                   crcFrameProps.id_child,
                key:                        crcFrameProps.id_key,

                // this will just be "passed through" to the "CrcModal_01"-frame
                modalFrameCrcProps:         crcFrameProps, 
                modalContentCrcPrototype:   crcContentPrototype, 
                modalContentCrcProps
            }));
    }

    static closeModal(wfCtrl, id_dom) {
        wfCtrl.removeVisible(id_dom); 
    }
}

CrcModal_01.propStyle_Middle1 = propStyle_Middle1;
CrcModal_01.propStyle_Default = propStyle_Default;
CrcModal_01.propStyle_DropDown = propStyle_DropDown;
CrcModal_01.propStyle_FullScreen = propStyle_FullScreen;
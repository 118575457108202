import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import ControlledReactComponentExtension from "./fabbi_controlled_react_component_extension.jsx";
import { Timestamp } from "firebase/firestore";

export default class CrcExt_GroupOrganizerItem extends ControlledReactComponentExtension {
    constructor(props) {
        super(props); 

        this.m_tempSortDataObject = undefined;
    }

    underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot) {
        super.underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot);

        this.m_tempSortDataObject = undefined;
        // console.log(this.constructor.name, "underlyingComponentDidUpdate", "HERE WE GO");
    }

    underlyingComponentDidMount(underlyingCRC) {
        // this.getDOMElement().addEventListener("dragstart", this._listenerDragstart);
        // this.getDOMElement().addEventListener("dragend", this._listenerDragend);

        var DOME = underlyingCRC.getDOMElement();

        this.m_listenerDragstartBound = function fnc(dragevent) { this.getParentExtension()._onDragstart(this, dragevent); }.bind(this);
        this.m_listenerDragenterBound = function fnc(dragevent) { this.getParentExtension()._onDragenter(this, dragevent); }.bind(this);
        this.m_listenerDragendBound = function fnc(dragevent) { this.getParentExtension()._onDragend(this, dragevent); }.bind(this);
        this.m_listenerDragoverBound = function fnc(dragevent) { this.getParentExtension()._onDragover(this, dragevent); }.bind(this);
        this.m_listenerDropBound = function fnc(dragevent) { this.getParentExtension()._onDrop(this, dragevent); }.bind(this);


        DOME.addEventListener("dragstart", this.m_listenerDragstartBound, false);
        DOME.addEventListener("dragenter", this.m_listenerDragenterBound, false);
        DOME.addEventListener("dragend", this.m_listenerDragendBound, false);
        DOME.addEventListener("dragover", this.m_listenerDragoverBound, false);
        DOME.addEventListener("drop", this.m_listenerDropBound, false);

        return(super.underlyingComponentDidMount(underlyingCRC));
    }

    underlyingComponentWillUnmount(underlyingCRC) {

        var DOME = underlyingCRC.getDOMElement();

        DOME.removeEventListener("dragstart", this.m_listenerDragstartBound);
        DOME.removeEventListener("dragenter", this.m_listenerDragenterBound);
        DOME.removeEventListener("dragend", this.m_listenerDragendBound);
        DOME.removeEventListener("dragover", this.m_listenerDragoverBound);
        DOME.removeEventListener("drop", this.m_listenerDropBound);

        return(super.underlyingComponentWillUnmount(underlyingCRC));
    }

    _isUnderlyingSiblingBefore(otherExt) {
        var thisDOME = this.getUnderlyingDOMElement(),
            otherDOME = otherExt.getUnderlyingDOMElement();

        if (!thisDOME) {
            console.log(this.constructor.name, "_isUnderlyingSiblingBefore", "thisDOME is undefined", "returning false");
            return(false); 
        }

        // console.log(this.constructor.name, "_isUnderlyingSiblingBefore", "HERE WE GO", this, otherExt);

        while (thisDOME.nextSibling && thisDOME.nextSibling !== otherDOME) {
            thisDOME = thisDOME.nextSibling;
        }
        // console.log(this.constructor.name, "_isUnderlyingSiblingBefore", "HERE WE GO", thisDOME.nextSibling, otherDOME);
        
        if (thisDOME.nextSibling === otherDOME) return(true);
        return(false);
    }

    _setTemporarySortDataObject(objSortData) {
        var bChange = !this.m_tempSortDataObject?true:this.compareSortDataObject(objSortData, this.m_tempSortDataObject)
        if (bChange) {
            this.m_tempSortDataObject = objSortData;
            this.getParentExtension()._setDirty(this);
        }
    }

    _readSortDataObject() {
        // console.log(this.constructor.name, "_readSortDataObject", "HERE WE", this.getParentExtension()._readSortDataObject(this.getUnderlying()));
        return(this.getParentExtension()._readSortDataObject(this.getUnderlying()));
    }

    _writeSortData(objSortData) {
        if (!objSortData) objSortData = this._getSortDataObject();
    
        if (!objSortData) return(false);

        // console.log(this.constructor.name, "_writeSortData", objSortData);
        if (this.compareSortDataObject(objSortData, this._readSortDataObject()) != 0) {
            return(this.getParentExtension()._writeSortData(this.getUnderlying(), objSortData));
        } else {
            console.log(this.constructor.name, "_writeSortData", objSortData, "no change! no write!");
        }
        return(true);
    } 

    _getSortDataObject(bIgnoreTempSortDataObject) {
        if (this.m_tempSortDataObject) return(this.m_tempSortDataObject);
        return(this._readSortDataObject());
    }

    compareSortDataObject(objA, objB) {

        if (objA > objB) return(1);
        if (objA < objB) return(-1); 
        return(0);
    }

    calcSortDataObject_Top(actualTopObj) {
        return(new Timestamp(actualTopObj.seconds+2, actualTopObj.nanoseconds));
    }

    calcSortDataObject_Bottom(actualBottomObj) {
        return(new Timestamp(actualBottomObj.seconds-2, actualBottomObj.nanoseconds));
    }

    calcSortDataObject_Between(prevObj, nextObj) {
        return(FabStd.Timestamp_calcMid(prevObj, nextObj));
    }

    /* Compares THIS Extension(-data) to the extension(-data) of another extension.
    * returns: 0 = equal, -1 = this.extension is less than the other, 1 = this.extension is greater than the other
    */
    _compareTo(otherExtension) {
        return(this.compareSortDataObject(this._getSortDataObject(), otherExtension._getSortDataObject()));
    }

    _setTemporarySortPosition_Between(prevExt, nextExt) {

        var prevObj, nextObj, tempObj, actuObj;

        prevObj = prevExt?prevExt._getSortDataObject():undefined;
        nextObj = nextExt?nextExt._getSortDataObject():undefined;
        actuObj = this._getSortDataObject();

        // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "actuObj", actuObj);
      
        if (!actuObj) return(false);
        // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "prevExt", prevExt, "nextExt", nextExt);
        
        if (!prevObj && !nextObj) return(false);

        // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "prevObj", prevObj, "nextObj", nextObj);
 
        // sort element to top ?
        if (prevObj === undefined) {
            // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "variant 1");
            if (this.compareSortDataObject(actuObj, nextObj) < 0) {
                tempObj = this.calcSortDataObject_Top(nextObj);
            } else {
                tempObj = actuObj;
            }
        } else
        // sort element to bottom ?
        if (nextObj === undefined) {
            // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "variant 2");
            if (this.compareSortDataObject(actuObj, prevObj) > 0) {
                tempObj = this.calcSortDataObject_Bottom(prevObj);
            } else {
                tempObj = actuObj;
            }
        } else
        // then: really sort element between
        {
            // console.log(this.constructor.name, "_setTemporarySortPosition_Between", "variant 3");
            if (this.compareSortDataObject(actuObj, nextObj) > 0 || this.compareSortDataObject(actuObj, prevObj) < 0) {
                tempObj = this.calcSortDataObject_Between(prevObj, nextObj);
            } else {
                tempObj = actuObj;
            }
        }
        
        console.log(this.constructor.name, "_setTemporarySortPosition_Between", "tempObj", tempObj);

        this._setTemporarySortDataObject(tempObj);
        return(true);
    }
}
import * as FabStd from "../fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import cash from "cash-dom";
import dataPool_qryProcessor from "./fabbi_datapool_qryprocessor.js";
import workflowControl_FamiPla from "./fabbi_workflowcontrol_famipla.js"

// this function is started by cash, after the browser finished loading document
(function($) {
    console.log("appStart", "initialization");

    // ******************************************************************
    class dataPool_FamiPla extends dataPool_qryProcessor {    
        constructor(parent, szDataPoolId) {
            super(parent, szDataPoolId);
        }
    }

    console.log("fabbi_firebase", "settingUp workFlowControl");
    // after initialization of workflowControl_FamiPla
    // the system waits for user-authorization via firebase to startUp
    window.g_wfCtrl = new workflowControl_FamiPla(
        new dataPool_FamiPla(null, ""), 
        // Your web app's Firebase configuration
        /* firebaseConfig = */ {
            apiKey: "AIzaSyBob1KnHorqylACIwTC3BY-ldak336sW50",
            authDomain: "famipla-bbeb9.firebaseapp.com",
            databaseURL: "https://famipla-bbeb9.firebaseio.com",
            projectId: "famipla-bbeb9",
            storageBucket: "famipla-bbeb9.appspot.com",
            messagingSenderId: "269540160334",
            appId: "1:269540160334:web:f515b84629f04966"
        }
    );
})(cash);

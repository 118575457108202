console.log("import", "app-assets");
import "./app-assets/images/logo/*.*";
/*
function requireAll(r) { 
    r.keys().forEach(r); 
 }
 requireAll(require.context('./assets/images/logo', true, /\.jpg$/));
*/

console.log("import", "init_sub1.js");
import "./init_sub1.js";

console.log("import", "init_firebase.js");
import "./app-assets/js/custom/init_firebase.js";

console.log("import", "appStart.js");
import "./app-assets/js/custom/appStart.js";
import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcMainListBase_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        // propsAreaHeader
        // id_container_header

        // propsAreaList
        // id_container_list

        // func_onSubmit(value, inputElement)
        // onSubmit
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    getIdDOM_Form_01() {
        return(this.getIdDOM() + "_form_01");
    }

    getIdDOM_Input_01() {
        return(this.getIdDOM() + "_input_01");
    }
    getDOMElement_Input_01() {
        return(document.getElementById(this.getIdDOM_Input_01()));
    }

    render() {        
        var arrResult = [];

        // console.log(this.constructor.name, "render");

        var func_onSubmit_01 = () => {
            if (this.props.func_onSubmit) {
                return(
                    this.props.func_onSubmit(
                        this.getDOMElement_Input_01().value,
                        this.getDOMElement_Input_01()
                    )
                );
            }
            return(false);
        };

        arrResult.push(
            <span 
                style={{ 
                        height: "100%", 
                        display: "flex", // "inline"
                        flexDirection: "column",
                        ... this.props.style
                    }
                }
                className={this.props.className}

                key={this.getIdDOM()} 
                id={this.getIdDOM()}>
                <div 
                    className={"no-resize fab-main-base-list-header-01"} 
                    
                    {... this.props.propsAreaHeader}>
                    
                    {this.props.childrenHeader}

                    <ul id={ this.props.id_container_header } style={{ width: "100%" }}>
                    </ul>
                </div>                       

                <div 
                    className={ this.props.classNameAreaList?this.props.classNameAreaList:""} 
                    style={{ 
                            // padding: "1rem",
                            flexGrow: "1",

                            width: "calc(100% - 0rem)", 
                            overflowY: "auto", 
                            // height: "calc(50% - 9rem - 5px)" 
                            ...this.props.styleAreaList
                        }} 
                    id={ this.props.id_area_list }
                    {... this.props.propsAreaList }
                    >

                    <div className="-chats" id={ this.props.id_container_list }>
                    </div> 
                </div>

                <div 
                    className={"chat-footer no-resize"} 
                    style={{ 
                        minHeight: "4rem", 
                        margin: "0px", 
                        padding: "5px", 
                        backgroundColor: "aliceblue", 
                        borderTop: "1px solid #bdbdbd"
                        }}>
                    <form 
                        id={ this.getIdDOM_Form_01() }
                        onSubmit={ () => func_onSubmit_01() } 
                        className={"-chat-input"}
                        style={{
                            display: "flex",
                            boxPack: "justify",
                            justifyContent: "space-between"
                        }}
                        >  
                        
                        <div style={{display: "flex", marginRight: "0.5rem", flexDirection: "column", justifyContent: "center", flexGrow: 1 }}>
                            <input 
                                id={ this.getIdDOM_Input_01() }
                                type="text" 
                                placeholder="Type message here.." 
                                className={"-message"} 
                                name="message"
                                style={{
                                    marginBottom: "0px", 
                                    marginRight: "0px",
                                    padding: "0 0rem",
                                
                                    color: "#9e9e9e", 
                                    borderBottom: 0,
                                    borderRadius: "5px",
                                    backgroundColor: "#eceff1"
                                }}
                                {... this.props.propsInput}
                                />
                        </div>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", flexGrow: 0}}>
                            <a  className={"btn waves-effect waves-light send"}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    height: "100%",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem"
                                }} 
                                onClick={ () => func_onSubmit_01() }
                                >
                                    <i className={"material-icons"}>{!this.props.iconSubmit?"send":this.props.iconSubmit}</i>
                            </a>
                        </div>
                    </form>
                </div>

            </span>
        );
        return(arrResult);
    }    
}
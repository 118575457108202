import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import CrcTimeLeftDisplay_01 from "./fabbi_crc_time_left_display_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import { Timestamp } from "firebase/firestore";

export default class CrcFamilyMissions_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidMount() {
        super.componentDidMount();
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
        var dataObjstat = datasetExtra["data-objstat"]; 
        
        // console.log("CRC", dataObjstat);

        if (!dataObjstat) dataObjstat = {};

        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = (e) => {
            if (this.isActiveEntry() || this.isAlwaysLinkedToActiveEntry()) {
                this.getWfCtrl().editMission2(this.getIdEntry());
            } else {
                this.getWfCtrl().setSelectedId_Missions(this.getIdEntry());                
            }
        };
        
        // style={{ backgroundColor: lightblue }}
        // style={{color:red}}
        return(
            <li id={this.getIdDOM()}
                className={
                    (this.isAlwaysLinkedToActiveEntry()?"fab-list-entry-01-activeEntry":"fab-list-entry-01") + 
                    " animate fadeUp CSS_DELAY" + 
                    ((!this.isAlwaysLinkedToActiveEntry() && this.isActiveEntry())?" active":"")
                } 
                onClick={(e) => {fncClick(e)}}
                style={{
                        width: "inherit", 
                        display: "flex", 
                        flexDirection: "column", 
                        paddingLeft: "0rem",
                        paddingRight: "0rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        borderStyle: "groove",
                        borderColor: "gray",
                        borderWidth: "2px",
                        margin: "0.1rem",
                        marginRight: "15px",
                        marginBottom: "15px",
                        boxShadow: "5px 10px 8px gray",
                        background: this.isAlwaysLinkedToActiveEntry()?"cornsilk":undefined,
                        backgroundImage: this.isAlwaysLinkedToActiveEntry()?undefined:"linear-gradient(to bottom right, red, yellow)"
                    }}
                >
                <div style={{width: "inherit", display: "flex", flexDirection: "column"}}>
                    <div style={{paddingLeft: "0.5rem", width: "inherit", display: "flex", flexDirection: "row" }}>
                        <div style={{width: "3.5rem", flexShrink: 0, flexGrow: 0}}>
                            <CrcImageOrInitials_01 wfCtrl={this.getWfCtrl()} image_src={dataset.image_src} long_name={dataset.name}/>
                        </div>
                        <div style={{flexGrow: 1, marginRight: "1rem"}}>
                            <p className={"blue-grey-text text-darken-4 font-weight-700"}>{dataset.name}</p>
                            <p className={"fab-info-text-fontstyle info-line"}>{dataset.goal}</p>
                        </div>            
                    </div>
                </div>

                <div style={{paddingRight: "1rem", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "right", alignItems: "flex-end"}}>
                    <span className={"badge pill purple"}>{dataObjstat.count}</span>
                    <span className={"badge pill green"}>{dataObjstat.count_OK}</span><i className={"material-icons"} style={{color: "green"}}>check</i>
                    <span className={"badge pill grey"}>{dataObjstat.count_IP}</span><i className={"material-icons"} style={{color: "grey"}}>hourglass_empty</i>
                    <span className={"badge pill red"}>{dataObjstat.count_FD}</span><i className={"material-icons"} style={{color: "red"}}>close</i>
                </div>
                            
                <div style={{paddingRight: "1rem", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "right", alignItems: "flex-end"}}>
                    <span className={"fab-time-01"}>
                        <CrcTimeLeftDisplay_01 wfCtrl={this.getWfCtrl()} parent={this} deadline={dataset.ts_deadline}/>
                    </span>
                </div>

            </li>
        );
    }
}
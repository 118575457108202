// Original JavaScript code by Chirp Internet: www.chirp.com.au
// Please acknowledge use of this code by including this header.

import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import Lib_GdtTimeseries from "./fabbi_lib_gdt_timeseries.js";
import numeral from "numeral";
import moment from 'moment';

numeral.locale("de");

/**
 * @classdesc For managing the content of a scriptParameter or a scriptCmd
 * @class
 */
class ScriptLinePartComponentProcessor_Base {
    /** 
     * @param {ScriptLinePart} part The ScriptInterpreterInfo-Object containing the link to the part it's related to. 
     */
     constructor(part, initProps, cfgUserDialog) {
        this._part = part;
        this._props = initProps?initProps:{};
        this._cfgUserDialog = cfgUserDialog?cfgUserDialog:{};
        this.init(this._props);
        this.initCfgUserDialog(this._cfgUserDialog);
    }

   /** duplicate the relevanta data and connect to another ScriptInterpreterInfo-Object 
    * @param {ScriptLinePart} part Alternative ScriptInterpreterInfo-Object that shall be used.
   */
    duplicate(part) {
        return(new this.constructor(part, {... this._props}, {... this._cfgUserDialog}));
    }

   /** function automatically called initially.
    * allows the user to do some initializing work, based on the 
    * props inially given.
    * @param {Object} props The "initProps" of the the PCP.
    * @override
    */
    init(props) {
        // default: do nothing
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.multiple = true;
        cfgUserDialog.freesolo = false;
        cfgUserDialog.type = "";
    }

   /** marks the start of the user-interaction, we read the script code and parse/interprete the scriptcodepart 
    * @override
   */
    startUserDialog() {
    }

   /** marks the end of the user-interaction; user-input-data is written to scriptcodepart 
    * @override
   */
    endUserDialog() {
    }

    /** build the "code" */
    buildAndSetPartCode() {
        var part = this.getPart();
        part.setCode(part.buildCode());
    }

    getScriptContext() {
        return(this.getPart()?.getScriptContext());
    }

    getProps() {
        return(this._props);
    }

    getScriptInterpreter() {
        return(this.getPart()?.getScriptInterpreter());
    }


    getInterpreterInfoBase() {
        return(this.getPart()?.getInterpreterInfoBase());
    }

    getInterpreterInfo() {
        return(this.getPart()?.getInterpreterInfo());
    }

    getPart() {
        return(this._part);
    }

   /** Returns general information regarding the appropriate input-variant and related specifications. 
     * @returns {Object} Configuration-data
     * @override 
    */
     getCfgUserDialog() {
        return(this._cfgUserDialog);
    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    * @override
    */
    buildSelectOptions() {
        return(undefined);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction.
    * @override
    */
    setResultSelectOptions(result) {
    }
}

/**
 * @classdesc For enlisting the Parameters of a ceterain cmd and so on.
 * @class
 */
class CmdParameterProcessor extends ScriptLinePartComponentProcessor_Base {

    /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.multiple = false;
        cfgUserDialog.freesolo = false;
        cfgUserDialog.open = true;
    }

   /** marks the start of the user-interaction, we read the script code and parse/interprete the scriptcodepart 
    * @override
   */
    startUserDialog() {
        this._szCmd = "";
    }

   /** marks the end of the user-interaction; user-input-data is written to scriptcodepart 
    * @override
    */
    endUserDialog() {
        super.endUserDialog();
        // console.debug(this.constructor.name, "endUserDialog", this.getPart().key, this.getPart().val);

        this.getPart().setKey(this._szCmd);
        this.buildAndSetPartCode();
        // console.debug(this.constructor.name, "endUserDialog", this.getPart().code);

    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    * @override
    */
    buildSelectOptions() {
        // maybe we build this - by default - via keyInputType ?
        return({ label: "no_idea" });
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction.
    * @override
    */
    setResultSelectOptions(result) {
        // console.debug(this.constructor.name, "setResultSelectOptions()", result);
        if (result?.arrOptionsSelected?.length > 0) {
            // console.debug(this.constructor.name, "setResultSelectOptions()", "label", result.arrOptionsSelected[0].label);
            this._szCmd = result.arrOptionsSelected[0].label;
        }
    }
}

/**
 * @classdesc For managing the content of a scriptParameter and switching between data-object and code-string
 * @class
 */
class ParameterContentProcessor extends ScriptLinePartComponentProcessor_Base {
   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.multiple = false;
        cfgUserDialog.freesolo = false;
    }

   /** marks the start of the user-interaction, we read the script code and parse/interprete the scriptcodepart 
    * @override
    */
    startUserDialog() {
        super.startUserDialog();
        // we do some parsing
        this.parsePartValTxt();
    }

   /** marks the end of the user-interaction; user-input-data is written to scriptcodepart 
    * @override 
    * */  
    endUserDialog() {
        super.endUserDialog();
        this.buildAndSetPartValTxt();
        this.buildAndSetPartCode();
        // console.debug(this.constructor.name, "endUserDialog", this.getPart());
    }

   /** Parses the "ValTxt" of the related "part"-object and fills the   
    * dedicated CustomData with "ready-for-work"-Data.
    * @returns {Object} reference to the CustomData-Object filled.
    */
    parsePartValTxt() {
        // console.debug(this.constructor.name, "parsePartValTxt");
        var part = this.getPart();
        var result = this.convertContentCodeToData(part.getValTxt(), part);
        var cd = part.getCustomData();
        FabStd.objPropsDelete(cd);
        FabStd.objPropsAddOrSet(cd, result);
        return(cd);
    }

   /** Builds a codestring using the "CustomData"-Object of the related part-Object.
    * The scriptcode ist written to the "part" as ValTxt
    * @returns {String} scriptCode written to "part" as ValTxt  
    */
    buildAndSetPartValTxt() {
        var part = this.getPart();
        var szValTxt = this.convertContentDataToCode(part.getCustomData(), part);
        part.setValTxt(szValTxt);
        return(szValTxt);
    }

   /** Function proposed to be overriden by derived classes. Conversion of "contentCode" to "contentObject"
    * @param {String} szContentCode input szContentCode, that shall be "objectized".
    * @param {Object} part The part object, where the code comes from.
    * @returns {Object} The objContentData containg the "codified" data
    * @override 
    */
    convertContentCodeToData(szContentCode, part) {
        return(JSON.parse(szContentCode));
    }

   /** Function proposed to be overriden by derived classes. Conversion of "contentObject" to "contentCode"
    * @param {Object} objContentData input objContentData, that shall be "codified" in String-form.
    * @param {Object} part The part object, where the objectData comes from.
    * @returns {String} The szContentCode containing the object's-data
    * @override 
    */
     convertContentDataToCode(objContentData, part) {
        return(JSON.stringify(objContentData));
    }
}

class pcpLabels extends ParameterContentProcessor {
    // overriden function
    init(initProps) {
        super.init(initProps);
        // console.log(this.constructor.name, "init", this._props);
        if (!initProps.szPropName_SelectionArray) initProps.szPropName_SelectionArray = "arrLabels";
        this._szPropName_SelectionArray = initProps.szPropName_SelectionArray;
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.multiple = true;
        cfgUserDialog.open = true;
        cfgUserDialog.freesolo = false;
    }

    // overriden function
    convertContentCodeToData(szContentCode, part) {
        var objContentData = {};
        objContentData[this._szPropName_SelectionArray] = szContentCode.split(",").filter((szElem) => szElem.trim() != "").map((szElem) => { return({ label: szElem.trim() } ); });
        return(objContentData);
    }
    
    // overriden function
    convertContentDataToCode(objContentData, part) {
        var szContentCode = "";
        if (objContentData && objContentData[this._szPropName_SelectionArray]?.length > 0) {
            szContentCode=objContentData[this._szPropName_SelectionArray].map((elem) => elem.label.trim()).join(",");
        }
        return(szContentCode);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction.
    * @override
    */
    setResultSelectOptions(result) {
        if (result?.arrOptionsSelected?.map) {
            this.getPart().getCustomData()[this._szPropName_SelectionArray] 
                = result.arrOptionsSelected.map((elem) => { return({ label: elem.label }) } );
        }

        console.log(this.constructor.name, "setResultSelectOptions", this.getPart().getCustomData());
    }

    getSelectedLabelArray() {
        return(this.getPart()?.getCustomData()[this._szPropName_SelectionArray]);
    }
}

class cppMainCmds extends CmdParameterProcessor {
    /** Delivers info for selectable cmds 
     * @returns {Object} Info-Object
     */ 
     buildSelectOptions() {    
         var arrOptResult = [];
         var arrOptResultSelected = [];
         var arrSP = this.getScriptInterpreter().getMainCmdList();
         var keyTxt = this.getPart().getKey();
         var bFound = false;
         
         if (keyTxt === "") keyTxt = this.getPart().getCode();
 
         // console.debug(this.constructor.name, "keyTxt", keyTxt, "arrSP", arrSP, "part", this.getPart());
 
         if (arrSP) {
             arrOptResult = 
                 arrSP.map((elem, idx) => { 
                     var label = elem;
                     var bCheck = label.toLowerCase() === keyTxt.toLowerCase();
                     bFound = bFound | bCheck;
                     return({ 
                         label,
                         check: bCheck,
                         index: idx }); 
                 }); 
         } else {
             arrOptResult = [];
         }

         arrOptResultSelected = arrOptResult.filter((elem) => elem.check);
 
         var result = { valTextfield: keyTxt, arrOptionsSelectable: arrOptResult, arrOptionsSelected: arrOptResultSelected };
         // console.debug(this.constructor.name, "result", result);
         return(result);
     }
}
 
class cppSubParams extends CmdParameterProcessor {
   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    */ 
    buildSelectOptions() {    
        var arrOptResult = [];
        var arrOptResultSelected = [];
        var arrSP = this.getInterpreterInfoBase()?.getSubParameters();
        var keyTxt = this.getPart().getKey();
        var bFound = false;
        
        if (keyTxt === "") keyTxt = this.getPart().getCode();

        // console.debug(this.constructor.name, "keyTxt", keyTxt, "arrSP", arrSP, "part", this.getPart());

        if (arrSP) {
            arrOptResult = 
                arrSP.map((elem, idx) => { 
                    var sii_tmp = this.getScriptInterpreter()._findKeyInfo(elem.key);
                    var label = sii_tmp?.getKeyUtterance();
                    if (!label) label = elem.key;
                    var bCheck = label.toLowerCase() === keyTxt.toLowerCase();
                    bFound = bFound | bCheck;
                    return({ 
                        label,
                        check: bCheck,
                        index: idx, 
                        sii_tmp }); 
                }); 
        } else {
            arrOptResult = [];
        }

        arrOptResultSelected = arrOptResult.filter((elem) => elem.check);
        var result = { valTextfield: keyTxt, arrOptionsSelectable: arrOptResult, arrOptionsSelected: arrOptResultSelected };
        // console.debug(this.constructor.name, "result", result);
        return(result);
    }
}

class pcpTsLabels extends pcpLabels {  
    // overriden function
    init(initProps) {
        super.init(initProps);
        // console.log(this.constructor.name, "init", this._props);
        if (!initProps.szPropName_SelectionArray) initProps.szPropName_SelectionArray = "arrLabels";
        this._szPropName_SelectionArray = initProps.szPropName_SelectionArray;
    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    */ 
    buildSelectOptions() {
        var arrTsP = this.getPart()?.getScriptLine()?.getTsPropertiesAvailable();
        var arrOptResult = [];
        var arrOptResultSelected = [];
        var arrSelected = [];
        if (arrTsP) {        
            arrSelected = this.getSelectedLabelArray();

            // console.debug(this.constructor.name, "buildSelectOptions", arrSelected);

            arrOptResult = 
                arrTsP.map((TsP, idx) => {
                    // console.debug(this.constructor.name, "TsP", TsP); 
                    return({ 
                        label:          TsP.label,
                        description:    TsP.description || TsP.Name || TsP.name,
                        check:          arrSelected.filter(
                                            (elemSelected) => { 
                                                if (elemSelected.label.toLowerCase() === TsP.label.toLowerCase()) {
                                                    elemSelected.used = true;
                                                    return(true);
                                                }
                                            }
                                        ).length > 0 
                    }); 
                });

            // console.log(this.constructor.name, "arrOptResult", arrOptResult);

            // console.debug("arrSelected", arrSelected);
            arrSelected.filter((elemSelected) => !elemSelected.used).forEach((elemSelected) => {
                arrOptResult.push({ ... elemSelected, check: true, unknown: true });
            });

            arrOptResultSelected = arrOptResult.filter((elem) => elem.check);
        }
        return({ valTextfield: "", arrOptionsSelectable: arrOptResult, arrOptionsSelected: arrOptResultSelected });
    }
}

class FabDataTable {
    constructor() {
        this._rows = [];
        this._columns = [];
    }

    allRowsWithColumnValue(colLabel, valueToLookUp) {
        return(this._rows.filter((row) => row[colLabel] === valueToLookUp));
    }

    someInColumn(colLabel, valueToLookUp) {
        return(this._rows.some((row) => row[colLabel] === valueToLookUp));
    }

    setColumnProperties(col, newColProps) {
        if (!newColProps) return(undefined);

        if (this._columns.length === col) {
            if (!newColProps.field) newColProps.field = "column_" + col;
            if (!newColProps.type) newColProps.type = "string";
            if (!newColProps.label) newColProps.label = newColProps.field;
    
            this._columns.push(newColProps);
            return(newColProps);
        } else {
            return(Object.assign(this.getColumnProperties(col), newColProps));
        }
    }

    /** */
    addRows(nCount_or_arrRows) {
        if (Array.isArray(nCount_or_arrRows)) {
            nCount_or_arrRows.forEach((row) => { this.addRow(row); })
        } else {
            for (var i=0; i < nCount; ++i) this._rows.push({});
        }
        return(this.getNumberOfRows());
    }

    /** */
    addRow(objRow) {
        var obj = {};

        if (Array.isArray()) {
            this._columns.forEach((col, idx) => {
                var field = col.field;

                obj[field] = null;
                if (objRow[idx] !== undefined) obj[field] = objRow[idx];
            });

        } else {
            this._columns.forEach((col) => {
                var field = col.field;

                obj[field] = null;
                if (objRow[field] !== undefined) obj[field] = objRow[field];
            });
        }

        this._rows.push(obj);
    }

    getColumnProperties(col) {
        return(this._columns[col]);
    }

    setValue(row, col, value) {
        try {
            this._rows[row][this._columns[col].field] = value;
            return(this._rows[row][this._columns[col].field]);
        } catch(e) {
            return(undefined);
        }
    }

    getValue(row, col) {
        try {
            return(this._rows[row][this._columns[col].field]);
        } catch(e) {
            return(undefined);
        }
    }

    getNumberOfRows() {
        return(this._rows.length);
    }

    getNumberOfColumns() {
        return(this._columns.length);
    }

    getColumns_forDatagrid() {
        return(this._columns);
    }

    getRows_forDatagrid() {
        return(this._rows);
    }    
} 

class pcpTsWeights extends pcpLabels {  
    // overriden function
    init(initProps) {
        super.init(initProps);
        // console.log(this.constructor.name, "init", this._props);
        if (!initProps.szPropName_Matrix) initProps.szPropName_Matrix = "arrTsWeights";
        this._szPropName_Matrix = initProps.szPropName_Matrix;
    }

    /** */
    getMatrix() {
        return(this.getPart().getCustomData()[this._szPropName_Matrix]);
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.multiple = true;
        cfgUserDialog.open = true;
        cfgUserDialog.freesolo = false;
        cfgUserDialog.type = "weights_01";
    }

    /** @override */
    convertContentCodeToData(szContentCode, part) {
        var arrS = [];
        var objContentData = {
            [this._szPropName_Matrix]: arrS
        };
        
        var match;
        var rxP = /(?:^|[;])(.*?)[/]([01234567890,.\-+\%]*)/gm;

        while (match = rxP.exec(szContentCode)) {
            var weight = 100;
            if (match[2].trim() !== "") weight = numeral(match[2]).value()

            arrS.push({ 
                tsLabel: match[1], 
                weight: weight 
            });
            // objContentData[this._szPropName_TRR].count = +match[1];
            // if (match[2]) objContentData[this._szPropName_TRR].type = match[2];
        }
        // console.debug(this.constructor.name, "weights_parse", arrS);

        return(objContentData);
    }
    
    // overriden function
    convertContentDataToCode(objContentData, part) {
        var szContentCode = "";
        var arrS = objContentData[[this._szPropName_Matrix]];
        
        arrS.forEach((obj) => {
            if (obj.weight && obj.weight !== 0) szContentCode += obj.tsLabel + "/" + numeral(obj.weight).format("0,0%") + ";";
        });
        return(szContentCode);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction. 
    * @override
    */
    setResultSelectOptions(result) {
        var arrS = this.getPart()?.getCustomData()[this._szPropName_Matrix];
        FabStd.resetArray(arrS);
        result.forEach((row) => arrS.push({
            tsLabel: row.tsLabel,
            weight: row.weight
        }));
    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    */ 
    buildSelectOptions() {
        var arrTsP = this.getPart()?.getScriptLine()?.getTsPropertiesAvailable();

        var fdt = new FabDataTable();
        fdt.setColumnProperties(0, {
            field: "id",
            label: "id",
            type: "number",
            width: 80,
            disableColumnMenu: true
        });
        fdt.setColumnProperties(1, {
                field: "tsLabel",
                label: "tsId",
                type: "string",
                hide: true,
                width: 200,
                disableColumnMenu: true
            });
        fdt.setColumnProperties(2, {
            field: "tsLabelAndDescription",
            label: "Beschreibung",
            type: "string",
            // resizable: true,
            // width: 200,
            flex: 1
        });
        fdt.setColumnProperties(3, {
            field: "weight",
            label: "Gewicht",
            type: "number",
            editable: true,
            disableColumnMenu: true,
            width: 150
        });

        var arrS = this.getMatrix();
        arrS.forEach((obj, idx) => { 
            fdt.addRow({ 
                id: idx+1, 
                tsLabel: obj.tsLabel, 
                tsLableAndDescription: null, 
                weight: obj.weight 
            });
        });
        
        if (arrTsP) {
            var bFound;
            arrTsP.forEach((TsP) => { 
                bFound = false;
                fdt.allRowsWithColumnValue("tsLabel", TsP.label).forEach((row) => {
                    bFound = true;
                    row.tsLabelAndDescription =
                        TsP.label + (TsP.description?(" (" + TsP.description + ")"):"")
                });
                
                if (!bFound) {
                    fdt.addRow({ 
                        id: fdt.getNumberOfRows()+1, 
                        tsLabel: TsP.label, 
                        tsLabelAndDescription: TsP.label + (TsP.description?(" (" + TsP.description + ")"):""),
                        weight: 0 
                    });
                }    
            });
        }

        // console.debug(this.constructor.name, "rows", fdt.getRows_forDatagrid());
        // console.debug(this.constructor.name, "cols", fdt.getColumns_forDatagrid());

        return({ columns: fdt.getColumns_forDatagrid(), rows: fdt.getRows_forDatagrid() });
    }
}

class pcpDateSelect extends ParameterContentProcessor {  
    // overriden function
    init(initProps) {
        super.init(initProps);
        // console.debug(this.constructor.name, "initProps", this.initProps);
        if (!initProps.szPropName_Date) initProps.szPropName_Date = "datSelection";
        this._szPropName_Date = initProps.szPropName_Date;
        this[this._szPropName_Date] = null;
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.type = "dateSelection_01";
        cfgUserDialog.open = false;
    }

    // overriden function
    convertContentCodeToData(szContentCode, part) {
        var objContentData = {};
        objContentData[this._szPropName_Date] = moment(szContentCode.trim(), "L", "de");
        // console.debug("DATEPICKER", "inputCODE", szContentCode, "outputDATE", objContentData[this._szPropName_Date])
        return(objContentData);
    }
    
    // overriden function
    convertContentDataToCode(objContentData, part) {
        var szContentCode = "";
        if (objContentData[this._szPropName_Date]) szContentCode = moment(objContentData[this._szPropName_Date]).format("L", "de");
        // console.debug("DATEPICKER", "inputDATE", objContentData[this._szPropName_Date], "outputCODE", szContentCode);
        return(szContentCode);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction. An datValue-property (moment) is expected => this will be used as the result-content
    * @override
    */
    setResultSelectOptions(result) {
        // console.debug("DATEPICKER", "setResultSelectOptions", result);
        this.setSelected(result?.datValue);
    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    * @override
    */
    buildSelectOptions() {
        return({ datMax: null, datMin: null, datValue: this.getSelected() });
    }

    getSelected() {
        return(this.getPart().getCustomData()[this._szPropName_Date]);
    }

    setSelected(datValue) {
        this.getPart().getCustomData()[this._szPropName_Date] = datValue;
    }
}

class pcpTimeRangeRelativeSelect extends ParameterContentProcessor {  
    // overriden function
    init(initProps) {
        super.init(initProps);

        if (!initProps.szPropName_TRR) initProps.szPropName_TRR = "trrSelection";
        this._szPropName_TRR = initProps.szPropName_TRR;
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.type = "trrSelection_01";

        cfgUserDialog.open = false;

        cfgUserDialog.percountDialogCfg = {
            open: false,
            multiple: false,
            freesolo: true
        }
        cfgUserDialog.pertypeDialogCfg = {
            open: false,
            multiple: false,
            freesol: false
        }
    }

    // overriden function
    convertContentCodeToData(szContentCode, part) {
        var objContentData = {
            [this._szPropName_TRR]: {
                type  :  "p",
                count :  0
            }
        };

        var rxP = /(?:^((?:\d*)))([pdtmwyj]|$)/si;
        // match somethin like: "12t" or "12" or "12w"

        var match;
        if (match = rxP.exec(szContentCode.toLowerCase())) {
            objContentData[this._szPropName_TRR].count = +match[1];
            if (match[2]) objContentData[this._szPropName_TRR].type = match[2];
        }


        // console.debug(this.constructor.name, "convertContentCodeToData", "objContentData", objContentData, "szContentCode", szContentCode);

        return(objContentData);
    }
    
    // overriden function
    convertContentDataToCode(objContentData, part) {
        // console.debug(this.constructor.name, "objContentData", objContentData);

        var objTrr = objContentData[this._szPropName_TRR];
        var szContentCode = 
              objTrr?.count + objTrr?.type ;
        return(szContentCode);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction. An datValue-property (moment) is expected => this will be used as the result-content
    * @override
    */
    setResultSelectOptions(result) {
        // console.debug(this.constructor.name, "setResultSelectOptions", "result", result);
        var trrSelected = this.getSelected();
        if (result["count"]?.valTextfield) trrSelected.count = numeral(result["count"].valTextfield).value();
        if (result["type"]?.valTextfield) trrSelected.type = result["type"].valTextfield;

        this.setSelected(trrSelected);
    }

   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    * @override
    */
    buildSelectOptions() {
        var arrPeriodTypes = [ 
            { label:  "d", countMin: 0, countMax: 99999 },
            { label:  "w", countMin: 0, countMax: 99999 },
            { label:  "m", countMin: 0, countMax: 99999 },
            { label:  "y", countMin: 0, countMax: 99999 },
            { label:  "p", countMin: 0, countMax: 99999 }
        ];

        return(
            { 
                pertypeDialogData: { 
                    arrOptionsSelectable: arrPeriodTypes,
                    valTextfield: this.getSelected().type
                }, 
                percountDialogData: {
                    arrOptionsSelectable: [
                        { label: "1"   },
                        { label: "10"  },
                        { label: "20"  },
                        { label: "250" } 
                    ],
                    // FabStd.buildIntegerArrayFromTo(-1, 250).map((elem) => { return({ label: numeral(elem).format("000") }); } ),
                    valTextfield: this.getSelected().count
                }
            }
        );
    }

    getSelected() {
        return(this.getPart().getCustomData()[this._szPropName_TRR]);
    }

    setSelected(trrSelected) {
        this.getPart().getCustomData()[this._szPropName_TRR] = trrSelected;
    }
}

class pcpDateSelectGdtTs extends pcpDateSelect { 
   /** Delivers info for selectable options-list and related input etc.
    * @returns {Object} Info-Object
    * @override
    */
    buildSelectOptions() {
        var gdt = this.getScriptContext().getGdtActive();
        var datMin = gdt.getValue(2, 0);
        var datMax = gdt.getValue(gdt.getNumberOfRows()-1, 0);
        
        // console.debug("pcpDateSelectGdtTs", "datMin", datMin, "datMax", datMax, "datValue", this.getSelected()?this.getSelected():moment(datMax));
        return({ datMax: moment(datMax), datMin: moment(datMin), datValue: this.getSelected()?this.getSelected():moment(datMax) });
    }
}

class pcpSimpleSingleSelect extends ParameterContentProcessor {  
    // overriden function
    init(initProps) {
        super.init(initProps);
        // console.debug(this.constructor.name, "init", this._props);
        if (!initProps.szPropName_SingleSelection) initProps.szPropName_SingleSelection = "szSingleSelection";
        this._szPropName_SingleSelection = initProps.szPropName_SingleSelection;

        this._arrOptions = 
            [
                { label: "histogramm", description: "" },
                { label: "kumuliert" , description: "" }
            ];
    }

   /** this function can be overriden to configure the type of UserDialog expected.
    * 
    * @param {Object} cfgUserDialog Some Information concerning the user-input.
    * @override 
    */ 
    initCfgUserDialog(cfgUserDialog) {
        cfgUserDialog.type = "options_01";
        cfgUserDialog.open = true;
    }

    // overriden function
    convertContentCodeToData(szContentCode, part) {
        var objContentData = {};
        objContentData[this._szPropName_SingleSelection] = szContentCode.trim();
        return(objContentData);
    }
    
    // overriden function
    convertContentDataToCode(objContentData, part) {
        var szContentCode = "";
        if (objContentData[this._szPropName_SingleSelection]) szContentCode = objContentData[this._szPropName_SingleSelection];
        return(szContentCode);
    }

   /** The result of the user-interaction shall be transformed to contentData.
    * @param {Object} result Object, holding the results of the user-interaction.
    * @override
    */
    setResultSelectOptions(result) {
        if (result?.arrOptionsSelected?.map && result?.arrOptionsSelected?.length > 0) {
            this.getPart().getCustomData()[this._szPropName_SingleSelection] = result.arrOptionsSelected[0].label;
        } else {
            this.getPart().getCustomData()[this._szPropName_SingleSelection] = "";
        }
    }

    getSelected() {
        return(this.getPart().getCustomData()[this._szPropName_SingleSelection]);
    }

    /** Delivers info for selectable options-list and related input etc.
     * @returns {Object} Info-Object
     */ 
     buildSelectOptions() {
         var arrOptRaw = this._arrOptions;
         var arrOptResult = [];
         var arrOptResultSelected = [ 
                { label: this.getSelected() } 
            ];
         
         if (arrOptRaw) {        
             // console.debug(this.constructor.name, "buildSelectOptions", arrSelected);
             arrOptResult = 
                arrOptRaw.map((opt, idx) => { 
                     return({ 
                        label:          opt.label,
                        description:    opt.description,
                        check:          arrOptResultSelected.filter(
                                            (elemSelected) => { 
                                                if (elemSelected?.label.toLowerCase() === opt.label.toLowerCase()) {
                                                    elemSelected.used = true;
                                                    return(true);
                                                }
                                            }
                                        ).length > 0, 
                     }); 
                 });
 
             arrOptResultSelected.filter((elemSelected) => (elemSelected && !elemSelected.used)).forEach((elemSelected) => {
                 arrOptResult.push({ ... elemSelected, check: true, unknown: true });
             });
         }
         return({ valTextfield: "", arrOptionsSelectable: arrOptResult, arrOptionsSelected: arrOptResultSelected });
     }
}

class pcpShowType extends pcpSimpleSingleSelect {  
    // overriden function
    init(initProps) {
        super.init({...initProps, szPropName_SingleSelection: "showType"});

        this._arrOptions = 
            [
                { label: "histogramm", description: "" },
                { label: "kumuliert" , description: "" }
            ];
    }
}

class pcpShowMode extends pcpSimpleSingleSelect {  
    // overriden function
    init(initProps) {
        super.init({...initProps, szPropName_SingleSelection: "showMode"});

        this._arrOptions = 
            [
                { label: "table", description: "" },
                { label: "chart" , description: "" }
            ];
    }
}


class pcpAggFunc extends pcpSimpleSingleSelect {  
    // overriden function
    init(initProps) {
        super.init({...initProps, szPropName_SingleSelection: "szAggFunc"});

        this._arrOptions = 
            [
                { label: "Standard", description: "", func: undefined },
                { label: "Std.abweichung" , description: "",  func: this.getScriptInterpreter()._libGdtTs.funcAggStDev }
            ];
    }

    // overriden function
    convertContentCodeToData(szContentCode, part) {
        var objContentData = {}; 

        objContentData[this._szPropName_SingleSelection] = szContentCode.trim();
        objContentData["funcAgg"] = undefined;
        this._arrOptions.some((elem) => { 
            if (elem.label === objContentData[this._szPropName_SingleSelection]) { 
                objContentData["funcAgg"] = elem.func;
                return(true); 
            } else { 
                return(false);
            } 
        });

        return(objContentData);
    }
}

class ScriptInterpreterInfo {
    constructor(initProps) {
        this.scriptInterpreter = undefined;

        this.key = "";
        this.arrKeyUtterance = [];
        this.szFuncCmd = undefined;
        this.checkColIdxSel = undefined;
        this.szPcp = undefined;
        this.szCpp = undefined;
        this.szFuncParseValTxt = undefined;
        
        var szId_preferredPPT = initProps.szId_preferredPPT?initProps.szId_preferredPPT:initProps.szId_preferredParameterParserType; 
        this.parameterParser = szId_preferredPPT?new ParameterParser(szId_preferredPPT):undefined;

        this.arrSubParameters = undefined;

        Object.assign(this, initProps); // copy all props to this object
    }

    isEmpty() {
        return(!this.key || this.key === "");
    }

    getProps() {
        return({... this});
    }

    getKey() {
        return(this.key);
    }

    getKeyUtterance() {
        return(this.arrKeyUtterance?this.arrKeyUtterance[0]:"?keyUtteranceUndefined");
    }

    getSubParameters() {
        return(this.arrSubParameters?this.arrSubParameters:[]);
    }

    getParameterParser() {
        return(this.parameterParser);
    }

    setPart(part) {
        this._part = part;
    }

    getScriptInterpreter() {
        return(this.scriptInterpreter);
    }

    getPart() {
        return(this._part);
    }
}

class ScriptContext {
    constructor(initProps, initCustomData) {
        // set defaults
        this.interpreter = null;    // should be given everytime
        
        this.arrScriptLinesDone = [];
        this.arrScriptLinesGiven = [];
        this.running = false;
        this.next = null;
        
        Object.assign(this, initProps); // copy all props to this object

        if (initCustomData) this._customData = initCustomData; 
            else if (!this._customData) this._customData = {};

        this.initCustomData(this._customData);
    }

    /**
     */
    hasErrors() {
        return(this.arrScriptLinesDone.some((line) => !line.bOk));
    }

    /** function to initialize the custom Data
     * @param {Object} customData The customData-Object of the Context.
     * @override */
    initCustomData(customData) {
        // do nothing
    }

    /** Is called immediately before start to interprete the script to which this context belongs to.
     * Allows to do some last preparations (if overriden). 
     * @override
    */
    onBeforeStartScriptInterpretation() {
        // do nothing
    }

    getCustomData() {
        return(this._customData);
    }

    getScriptInterpreter() {
        return(this.interpreter);
    }

    findLineDone(key) {
        return(this._findLine(this.getScriptLinesDone(), key));
    }

    findLineGiven(key) {
        return(this._findLine(this.getScriptLinesGiven(), key));
    }

    _findLine(arrLines, key) {
        var result = undefined; 
        arrLines.some((scriptLine, idx) => {
            // console.debug(this.constructor.name, "sl.getKey", sl.getKey(), idxLine);
            // we look for the key
            if (scriptLine.getKey() === key) {
                result = { 
                    scriptLine, 
                    idx 
                };
                return(true);   // yeah, found and exit loop 
            }
            return(false);  // go ahead
        });

        return(result);
    }

    getScriptLinesGiven() {
        return(this.arrScriptLinesGiven);
    }

    getScriptLinesDone() {
        return(this.arrScriptLinesDone);
    }

    getAllCustomData() {
        var cdContext = this.getCustomData();
        var cdLine = this.getActiveScriptLine().getCustomData();
        var cdParts = this.getActiveScriptLine().collectAllPartsCustomDataAsOne();
        var cd = {};

        Object.assign(cd, cdContext );
        Object.assign(cd, cdLine    );
        Object.assign(cd, cdParts   )

        return(cd);
    }

    getActiveScriptLine() {
        // console.debug(this.constructor.name, "getActiveScriptLine()", this.activeScriptLine);
        return(this.activeScriptLine);
    }

    activeScriptLine_getLineCustomData() {
        return(this.getActiveScriptLine()?.getCustomData());
    }

    activeScriptLine_collectAllPartsCustomDataAsOne() {
        return(this.getActiveScriptLine()?.collectAllPartsCustomDataAsOne());
    }

    activeScriptLine_somePart(funcSome_part_idx, nStartPartIndex) {
        if (!this.activeScriptLine) return(false);
        return(this.activeScriptLine.somePart(funcSome_part_idx, nStartPartIndex));
    }

    activeScriptLine_forEachPart(funcForEach_part_idx, nStartPartIndex) {
        if (!this.activeScriptLine) return(false);
        return(this.activeScriptLine.forEachPart(funcForEach_part_idx, nStartPartIndex));
    }
}

class ScriptContextGdtTs extends ScriptContext {
    /** function automatically called to initialize the custom Data when constructing the Context.
     * @param {Object} customData The customData-Object of the Context.
     * @override */
     initCustomData(customData) {
        if (!customData.gdtMain) customData.gdtMain = null; 
        if (!customData.gdtActive) customData.gdtActive = null;
        if (!customData.arrIdxCol_curSel) customData.arrIdxCol_curSel = [];
        if (!customData.smapTmpGdt) customData.smapTmpGdt = new StdMap();

        // console.debug(this.constructor.name, "initCustomData");
    }

    onBeforeStartScriptInterpretation() {
        super.onBeforeStartScriptInterpretation();

        if (!this.getGdtActive() && this.getGdtMain()) {
            // clone the MAIN-Table to the Active one
            this.setGdtActive(this.checking?this.getGdtMainCloneShortened():this.getGdtMainClone());
        }
    }

    getGdtMain() {
        return(this.getCustomData().gdtMain);
    }

    getGdtMainClone() {
        console.debug(this.constructor.name, "getGdtMainClone()", "Start", new Date());
        var gdtClone = this.getGdtMain().clone();
        console.debug(this.constructor.name, "getGdtMainClone()", "Ende", new Date());
        return(gdtClone);
    }

    getGdtMainCloneShortened() {
        console.debug(this.constructor.name, "getGdtMainCloneShortened()", "Start", new Date());
        var gdtClone = this.getScriptInterpreter()._libGdtTs.gdtCloneShortend(this.getGdtMain());
        console.debug(this.constructor.name, "getGdtMainCloneShortened()", "Ende", new Date());
        return(gdtClone);
    }

    setGdtMain(gdtMain) {
        this.getCustomData().gdtMain = gdtMain;
    }


    getGdtActive() {
        return(this.getCustomData().gdtActive);
    }

    getGdtActiveClone() {
        console.debug(this.constructor.name, "getGdtActiveClone()", "Start", new Date());
        var gdtClone = this.getGdtActive().clone();
        console.debug(this.constructor.name, "getGdtActiveClone()", "Ende", new Date());
        return(gdtClone);
    }

    setGdtActive(gdtActive) {
        this.getCustomData().gdtActive = gdtActive;
    }

    getCurSel_WorkInfo() {
        var arrIdxCol_curSel = this.getCurSel_IdxCol();
        var bExplicitSelection = !!(arrIdxCol_curSel?.length>0);
        arrIdxCol_curSel = 
            arrIdxCol_curSel.length>0
                ?arrIdxCol_curSel
                :FabStd.buildIntegerArrayFromTo(1, this.getGdtActive().getNumberOfColumns()-1);

        return({
            bExplicitSelection,
            arrIdxCol_curSel
        });
    }

    getCurSel_IdxCol() {
        return(this.getCustomData().arrIdxCol_curSel);
    }

    addToCurSel_IdxCol(arrIdxCol_curSel_toAdd) {
        var arrIdx = this.getCurSel_IdxCol();
        return(arrIdx.push(... arrIdxCol_curSel_toAdd.filter((n) => !arrIdx.includes(n))));
    }

    setCurSel_IdxCol(arrIdxCol_curSel) {
        if (arrIdxCol_curSel === this.getCurSel_IdxCol()) return;
        FabStd.resetArray(this.getCurSel_IdxCol());
        return(this.getCurSel_IdxCol().push(... arrIdxCol_curSel));
    }

}

class ScriptLine {
    constructor(initProps, initCustomData) {
        // set defaults
        this.scriptContext = undefined;
        this.code = "";         // line code
        this.key = "";
        this.offset = 0;
        this.length = 0; 
        this.arrPart = [];

        Object.assign(this, initProps); // copy all props to this object

        if (initCustomData) this._customData = initCustomData; 
            else if (!this._customData) this._customData = {};

        this.initCustomData(this._customData);
    }

    /** function to initialize the custom Data
     * @param {Object} customData The customData-Object of the line.
     * @override */
    initCustomData(customData) {
        // do nothing
    }

    getCustomData() {
        return(this._customData);
    }

    getScriptInterpreter() {
        return(this.scriptContext?.getScriptInterpreter());
    }

    getScriptContext() {
        return(this.scriptContext);
    }

    getKey() {
        return(this.key);
    }

    findPart(offsetPart) {
        var result = undefined;
        this.arrPart.some((part, idx) => {
            if (part.offset+part.length > offsetPart) {
                result = { 
                    part, 
                    idx
                };
                return(true);
            }
            return(false);
        });

        return(result);
    }

    setScriptContext(scriptContext) {
        this.scriptContext = scriptContext;
    }

    getInterpreterInfoBase() {
        return(this.getInterpreterInfoByPartIdx(0));
    }

    getInterpreterInfoByPartIdx(idx) {
        if (idx < 0 || idx >= this.getNumberOfParts()) return(undefined);
        return(this.arrPart[idx].interpreterInfo);
    }

    getNumberOfParts() {
        return(this.arrPart.length);
    }

    getPart(idx) {
        return(this.arrPart?.[idx]);
    }

    // interpreter step 1 - looking up all "interpreterInfo" for parts
    preinterprete() {
        if (!this.scriptContext || !this.scriptContext.getScriptInterpreter()) return;
        
        // 
        this.arrPart.forEach((part, idx) => { this.scriptContext.getScriptInterpreter().preinterpretePart(part); });
    }

    // interpreter Step 2 - parsing of parameter-data, if the "baseInterpreterInfo" (first part info) was found ...
    interprete_2() {
        if (!this.scriptContext || !this.scriptContext.getScriptInterpreter()) return;

        var cpp = undefined;
        this.arrPart.forEach((part, idx) => {
            var ii = part.getInterpreterInfo();

            // if (idx === 0) console.log(this.constructor.name, "interprete_2", idx, "cpp", part.getCpp(), "part", part);
            if (idx === 0) cpp = part.getCpp(); else part.useCpp_fromBase(cpp);

            // connect the actual "part"-object to the "interpreterInfo"
            ii?.setPart(part);

            var cd = part.getCustomData();
            try {
                part.parsePartValTxt();
            } catch(e) {
                console.debug(this.constructor.name, "interprete_2", "exception thrown while parsing part", part);
                // exception! while parsing part !
                cd.exception = e;
                cd.bOk = false;
            }
        });
    }

    collectAllPartsCustomDataAsOne() {
        var cdCollect = {};
        var cdCollectErr = {};

        this.forEachPart((part, idx) => {
            var cd = part.getCustomData();
            if (cd) {
                if (!cdCollectErr.bOk && cd.bOk !== undefined && cd.bOk === false) {
                    cdCollectErr = { 
                        bOk: cd.bOk, 
                        exception: cd.exception, 
                        txtError: cd.txtError 
                    };
                }
                cdCollect = { ...cdCollect, ...cd };
            }
        });
        cdCollect = { ...cdCollect, ...cdCollectErr };
        return(cdCollect);
    }

    somePart(funcSome_part_idx, nStartPartIndex) {
        if (nStartPartIndex === undefined) nStartPartIndex = 1; // skipt part 0
        this.arrPart.filter((part, idx) => idx >= nStartPartIndex).some(funcSome_part_idx);
    }

    forEachPart(funcForEach_part_idx, nStartPartIndex) {
        if (nStartPartIndex === undefined) nStartPartIndex = 1; // skipt part 0
        this.arrPart.filter((part, idx) => idx >= nStartPartIndex).forEach(funcForEach_part_idx);
    }

    /** */
    isEmpty() {
        return(this.arrPart.length === 0);
    }

    /** */
    parseParts() {
        var si = this.getScriptInterpreter();

        this.arrPart = [];  // reset by caution

        if (!si) {
            console.debug(this.constructor.name, "parseParts()", "CANCELLED! not interpreter found! Please add an interpreter (in initProps) if constructing a new ScriptLine!");
            return(false);
        }

        if (this.code.trim().length === 0) {
            return(true);
        }

        var matchPart;
        var lastPart = undefined;
        var rxPart = /(\S+)(?:\s|$)/gm;

        while (matchPart = rxPart.exec(this.code)) {
            // console.debug("matchPart", matchPart);
            var part = new (this.getScriptInterpreter()._constructor_ScriptLinePart)({
                code:               matchPart[1],
                offset:             matchPart.index,
                length:             matchPart[1].length,
                scriptLine:         this,
                idx:                this.arrPart.length     // idx of part in ScriptLine
            });
            this.arrPart.push(part);
            lastPart = part;
        }

        if (lastPart) {
            if (lastPart.offset+lastPart.length < this.code.length) {
                var part = new (this.getScriptInterpreter()._constructor_ScriptLinePart)({
                    code:               "",
                    offset:             lastPart.offset+lastPart.length+1,
                    length:             0,
                    scriptLine:         this,
                    idx:                this.arrPart.length     // idx of part in ScriptLine
                });    
                this.arrPart.push(part);
            }
            else {

            }
        }

        // some corrections
        this.arrPart = this._subRejoinBracketedParts(this.arrPart);
        return(true);
    }

    _subRejoinBracketedParts(arrPart) {
        var nBracketsOpen = 0;
        var idxStart = -1;
        var arrPartCorrected = [];
        
        arrPart.map((part, idx) => {
            var rxBrackets = /(\()|(\))/g; 
            var matchBrackets;
            while (matchBrackets = rxBrackets.exec(part.code)) {
                if (matchBrackets) {
                    if  (matchBrackets[1]) ++nBracketsOpen; else --nBracketsOpen;
                }
            }
            arrPartCorrected.push(part);
    
            if (nBracketsOpen > 0 && idxStart < 0) idxStart = idx;
            if (nBracketsOpen === 0) {
                if (idxStart >= 0) {
                    var nTmp = idx - idxStart;
                    for (var i=0; i < nTmp; ++i) arrPartCorrected.pop();
                    for (var idxTmp=idxStart+1; idxTmp <= idx; ++idxTmp) {
                        arrPartCorrected[arrPartCorrected.length-1].code += arrPart[idxTmp].code;
                        arrPartCorrected[arrPartCorrected.length-1].length = 
                            arrPart[idxTmp].length 
                            + arrPart[idxTmp].offset 
                            - arrPartCorrected[arrPartCorrected.length-1].offset;
                    }
                }
                idxStart = -1;
            }
            // console.debug("matchBrackets", param, matchBrackets, nBracketsOpen);
        });
        return(arrPartCorrected);
    }
}

class ScriptLineGdtTs extends ScriptLine {
    initCustomData(customData) {
        super.initCustomData(customData);
        customData.arrTsPropertiesAvailable = [];
    }
    
    setTsPropertiesAvailable(arrTsProperties) {
        FabStd.resetArray(this.getCustomData().arrTsPropertiesAvailable);
        this.getCustomData().arrTsPropertiesAvailable.push(... arrTsProperties);
    }

    getTsPropertiesAvailable() {
        return(this.getCustomData().arrTsPropertiesAvailable);
    }
}

class ScriptLinePart {
    constructor(initProps, initCustomData) {
        // set defaults
        this.interpreterInfo = undefined;
        this.scriptLine = undefined;
        this.idx = undefined;           // idx of part in ScriptLine (-1 )
        
        this.code = "";                 // part code
        this.offset = 0;
        this.length = 0; 
        this.key = "";          // parsed-key
        this.val = "";          // parsed-content (without open/close-strings)
        
        this._cpp = undefined;
        this._pcp = undefined;

        Object.assign(this, initProps); // copy all props to this object
  
        if (initCustomData) this._customData = initCustomData; 
            else if (!this._customData) this._customData = {};

        this.initCustomData(this._customData);
    }

    /** function to initialize the custom Data
     * @param {Object} customData The customData-Object of the part.
     * @override */
    initCustomData(customData) {
        // do nothing
    }

    getCustomData() {
        return(this._customData);
    }

    isBase() {
        return(this.idx === 0);
    }

    getScriptInterpreter() {
        return(this.scriptLine?.getScriptInterpreter());
    }

    getScriptContext() {
        return(this.scriptLine?.getScriptContext());
    }

    // get "parent" scriptLine
    getScriptLine() {
        return(this.scriptLine);
    }

    setKeyValTxt(key, txtVal) {
        if (key) this.key = key;
        if (txtVal) this.val = txtVal;
    }

    parsePartValTxt() {
        return(this.getPcp()?.parsePartValTxt());
    }

    buildAndSetPartValTxt() {
        return(this.getPcp()?.buildAndSetPartValTxt());
    }

    getPartComponentProcessor(szPartComponent) {
        return(szPartComponent === "key"?((this.idx===0)?this.getLcp():this.getCpp()):this.getPcp());
    }

    useCpp_fromBase(cpp) {
        this._cpp = cpp?.duplicate(this);
    }

    getLcp() {
        if (!this._lcp) {
            this._lcp = new cppMainCmds(this);
        }
        return(this._lcp);
    }

    getCpp() {
        if (!this._cpp && this.interpreterInfo?.szCpp) {
            this._cpp = eval("new " + this.interpreterInfo.szCpp + "(this, this.interpreterInfo.cppProps)");
        }
        return(this._cpp);   
    }

    getPcp() {
        if (!this._pcp && this.interpreterInfo?.szPcp) {
            this._pcp = eval("new " + this.interpreterInfo.szPcp + "(this, this.interpreterInfo.pcpProps)");
        }
        return(this._pcp);
    }

    getScriptInterpreter() {
        return(this.scriptLine.getScriptInterpreter());
    }

    preinterprete(bKeyChanged) {
        this.getScriptInterpreter()?.preinterpretePart(this, bKeyChanged);
    }

    setInterpreterInfo(interpreterInfo) {
        this.interpreterInfo = interpreterInfo;
    }

    isKeyRecognized() {
        return(!!this.getCpp());
    }

    isValRecognized() {
        return(!!this.getPcp());
    }

    hasVal() {
        return(!this.val?false:(this.val.length>0));
    }

    getParameterParser() {
        return(this.getInterpreterInfo()?.getParameterParser());
    }

    getInterpreterInfo() {
        return(this.interpreterInfo);
    }

    getInterpreterInfoBase() {
        return(this.scriptLine?.getInterpreterInfoBase());
    }

    /** use actual key/valTxt to build the "code" of the part
     * 
    */
    buildCode() {
        var key = this.key;
        var valTxt = this.val;
    
        var szCode = this.getInterpreterInfo()?.parameterParser?.buildCode(key, valTxt);

        // console.debug(this.constructor.name, "buildCode", "szCode", szCode, "key", key, "valTxt", valTxt);
        if (!szCode) szCode = (key?key:"")+(valTxt?valTxt:"");
        return(szCode);
    }

    setCode(szCode) {
        this.code = szCode;
    }

    getCode() {
        return(this.code);
    }

    getKey() {
        return(this.key);
    }

    setKey(szKey) {
        if (szKey !== this.key) {
            this.key = szKey;
            // console.debug(this.constructor.name, "setKey()", szKey);

            // re-preinterprete the part
            this.preinterprete(true);
        }
    }

    setValTxt(szValTxt) {
        this.val = szValTxt;
    }

    getValTxt() {
        return(this.val);
    }
}

class ScriptLinePartGdtTs extends ScriptLinePart {
}

class ParameterParser {

   /*
    nId_ParameterParserType_Preferred is optional: default.value is "0"
   */
    constructor(szId_ParameterParserType_Preferred) {

        class ParameterParserType{
            constructor(rx, szOpen_val, szClose_val) {
                this.rx = rx;
                this.szOpen_val = szOpen_val?szOpen_val:"";
                this.szClose_val = szClose_val?szClose_val:"";
            }

            buildCode(key, val) {
                return(key + this.szOpen_val + val + this.szClose_val);
            }
        }

        // Type 0:    rx = /(?:(.*?)(=)(.*)){1}/s
        //             xxx=yyy
        //             szOpen_val = "="
        //             szClose_val = ""
        
        // Type 1:    rx = /^([^\(]*)(\()(.*)(\))$/s
        //              xxx(yyy)
        //              szOpen_val = "("
        //              szClose_val= ")"

        this._smapPPT = new StdMap();
        this._szId_ParameterParserType_Preferred = szId_ParameterParserType_Preferred?szId_ParameterParserType_Preferred:"0";
        this._smapPPT.set("0", new ParameterParserType(/(?:(.*?)(=)(.*)){1}/s, "="));
        this._smapPPT.set("1", new ParameterParserType(/^([^\(]*)(\()(.*)(\))$/s, "(", ")")); 
    }

    _parseSub1(szCode, szId_ParserType) {
        var ppt = this._smapPPT.get(szId_ParserType);
        if (!ppt) return(undefined);
        return(this._parseSub2(szCode, ppt));
    }

    _parseSub2(szCode, ppt) {
        if (!ppt) return(undefined);

        var match;
        if (match = ppt.rx.exec(szCode)) {
            if (match.length >= 3) {
                return({
                    key:    match[1],
                    valTxt: match[3]
                });
            }
        }
        return(undefined);
    }

    buildCode(key, val) {
        return(this.getPreferredParameterParserType()?.buildCode(key, val));
    }

    getPreferredParameterParserType() {
        var ppt = undefined;

        if (this._szId_ParameterParserType_Preferred) {
            ppt = this._smapPPT.get(this._szId_ParameterParserType_Preferred);
        }

        if (!ppt) {
            this._smapPPT.some((tmpPPT) => {
                ppt = tmpPPT;
                return(true);
            });
        }        
        
        return(ppt);
    }

    getValTxt(szCode) {
        return(this.parse(szCode)?.val);
    }

    getKeyUtterance(szCode) {
        return(this.parse(szCode)?.key);
    }

    parse(szCode) {
        var result = undefined;

        if (this._szId_ParameterParserType_Preferred) {
            result = this._parseSub1(szCode, this._szId_ParameterParserType_Preferred);
        }

        if (!result)  {
            this._smapPPT.some((ppt) => {
                result = this._parseSub2(szCode, ppt);
                return(!!result);        
            });
        } 
        return(result);
    }
}

export default class ScriptInterpreter_GdtTimeseries {
    constructor(config) {

        this._constructor_ScriptLinePart  = ScriptLinePartGdtTs ;
        this._constructor_ScriptLine      = ScriptLineGdtTs     ;
        this._constructor_ScriptContext   = ScriptContextGdtTs  ;

        this._config = config;
        this._libGdtTs = new Lib_GdtTimeseries();

        var arrInput1 = [
            {
                key:        "showmode",
                
                freeSolo:   "false",
                arrOptions: [
                    { label: "table" },
                    { label: "chart" }
                ]
            }, {
                key:        "showtype",
                
                freeSolo:   "false",
                arrOptions: [
                    { label: "histogramm" },
                    { label: "cumulated" }
                ]
            }, {
                key:        "distribution", 

                freeSolo:   "false",
                arrOptions: [
                    { label: "normal" },
                    { label: "beta" },
                    { label: "studentt" },
                    { label: "chiquadrat" }
                ]
            } , {
                key:                    "labels",

                freeSoloWithWildcards:  true,
                multiple:               true,
                szFuncBuildOptions:     "_buildOptions_labels",
                arrOptions:             []
            }
        ];

        var arrInterpretation2 = [ 
            {
                key:                "param_mean",
                arrKeyUtterance:    ["mean", "mw", "mittelwert"],
                szId_preferredPPT:  "0"       
            },{
                key:                "param_periods",
                arrKeyUtterance:    ["periods", "per", "period", "perioden"],
                szId_preferredPPT:  "0", 
                szFuncBuildOptions: "_buildOptions_periods",
                szFuncParseValTxt:  "_parseParam_periods"
            }, {
                key:                "param_offset",
                arrKeyUtterance:    ["offset", "startperiode", "start_period", "start_periode", "versatz"],
                szId_preferredPPT:  "0", 
                szFuncParseValTxt:  "_parseParam_offset"   
            }, {
                key:                "param_aggfunc",
                arrKeyUtterance:    [ "aggfunc" ],
                szId_preferredPPT:  "0", 
                szPcp:              "pcpAggFunc"
            }, {
                key:                "param_showmode",
                arrKeyUtterance:    [ "mode" ],
                szId_preferredPPT:  "0", 
                // szFuncBuildOptions: "_buildOptions_param_showmode",
                // szFuncParseValTxt:  "_parseParam_showmode",
                szPcp:              "pcpShowMode",
                keyInputType:       "showmode"
            }, {
                key:                "param_showtype",
                arrKeyUtterance:    [ "type" ],
                szId_preferredPPT:  "0", 
                szPcp:              "pcpShowType",
                // szFuncBuildOptions: "_buildOptions_param_showtype",
                // szFuncParseValTxt:  "_parseParam_showtype",
                keyInputType:         "showtype"
            }, {
                key:                "param_distribution",
                arrKeyUtterance:    [ "distribution", "verteilung", "dist", "vt" ],
                szId_preferredPPT:  "0", 
                // szFuncBuildOptions: "_buildOptions_param_distribution",
                szFuncParseValTxt:  "_parseParam_distribution",
                keyInputType:         "distribution"
            }, {
                key:                "param_labels",
                arrKeyUtterance:    ["ts", "labels", "timeseries", "zeitreihen"],
                szId_preferredPPT:  "0",
                szPcp:              "pcpTsLabels",      
                pcpProps:           {
                                        szPropName_SelectionArray: "arrTsLabels"
                                    },
                keyInputType:       "labels"
            }, {
                key:                "param_weights",
                arrKeyUtterance:    ["wts", "weights"],
                szId_preferredPPT:  "0",
                szPcp:              "pcpTsWeights",      
                pcpProps:           {
                                        szPropName_WeightsArray: "arrTsWeights"
                                    }
            }, {
                key:                "param_date_start",
                arrKeyUtterance:    ["startdatum", "startdate", "start_date", "start_datum"],
                szId_preferredPPT:  "0",
                szPcp:              "pcpDateSelectGdtTs",
                pcpProps:           {
                                        szPropName_Date: "dateStart"
                                    },
                keyInputType:       "date"
            }, {
                // UNDEFINED/UNUSED YET
                key:                "param_timerange_relative",
                arrKeyUtterance:    ["zeithorizont_relativ", "timerange_relative", "zhr", "trr"],
                szId_preferredPPT:  "0",
                szPcp:              "pcpTimeRangeRelativeSelect",
                pcpProps:           {
                                        szPropName_TRR: "trrBase"
                                    }
            }, {
                key:                "param_date_end",
                arrKeyUtterance:    ["enddatum", "enddate", "end_date", "end_datum", "endedatum", "ende_datum"],
                szId_preferredPPT:  "0",
                szPcp:              "pcpDateSelectGdtTs",
                pcpProps:           {
                                        szPropName_Date: "dateEnd"
                                    },
                keyInputType:       "date"
            }, {
                key:                "param_scale",
                arrKeyUtterance:    [
                                    "skalieren",
                                    "scale", "skaliere", "scaleStd", 
                                    "scaleViaSRoT",     // scale via square-root-of-T
                                    "skaliereStd",
                                    "skaliereViaWTF",   // skaliere via Wurzel-T-Formel
                                    "scalePeWi"                        
                                    ], 
                szId_preferredPPT:  "1",    
                szFuncParseValTxt:   "_parseParam_scale"
            }, {
                key:                "param_multiply",
                arrKeyUtterance:    [ "multiplizieren", "multipliziere", "multi", "multiply" ], 
                szId_preferredPPT:  "1",
                szFuncParseValTxt:  "_parseParam_multiply"
            }, {
                key:                "param_modify_raw",
                arrKeyUtterance:    [ "modifiziere", "modify", "modif" ], 
                szId_preferredPPT:  "1",
                szFuncParseValTxt:  "_parseParam_modifyRaw"
            }
        ];

        var arrInterpretation1 = [
            {  
                key:                "reset",               //  key (main)
                arrKeyUtterance:    ["reset"],             //  substitutes key-words
                szFuncCmd:          "cmd_resetCurSel_IdxCol",
                checkColIdxSel:     false
            }, {
                key:                "lade",         //  key (main)
                arrKeyUtterance:    ["loadtmp", "load", "lade", "lt"],  //  substitutes key-words
                szFuncCmd:          "cmd_gdtActiveLoadFromTmp",
                checkColIdxSel:     false
            }, {  
                key:                "select",        //  key (main)
                arrKeyUtterance:    ["select"],      //  substitutes key-words
                szFuncCmd:            "cmd_gdtSelectColumnsViaLabels",
                arrSubParameters:   [ 
                                        { key: "param_labels", optional: false } 
                                    ], 
                szCpp:              "cppSubParams",
                checkColIdxSel:     false
            }, , {  
                key:                "mix",        //  key (main)
                arrKeyUtterance:    ["mix"],      //  substitutes key-words
                szFuncCmd:            "cmd_gdtMix",
                arrSubParameters:   [ 
                                        // { key: "param_labels" , optional: false },
                                        { key: "param_weights", optional: false } 
                                    ], 
                szCpp:              "cppSubParams",
                checkColIdxSel:     false
            }, {  
                key:                "config",        //  key (main)
                arrKeyUtterance:    ["config", "konfig"],      //  substitutes key-words
                szFuncCmd:          "cmd_gdtConfig",
                arrSubParameters:   [ 
                                        { key: "param_date_start", optional: true },
                                        { key: "param_date_end"  , optional: true } ,
                                        { key: "param_timerange_relative", optional: true }
                                    ], 
                szCpp:              "cppSubParams",
                checkColIdxSel:     false
            }, {  
                key:                "deselect",        //  key (main)
                arrKeyUtterance:    ["deselect"],                //  substitutes key-words
                szFuncCmd:            "cmd_gdtDeselectColumnsViaLabels",
                arrSubParameters:   [ 
                                        { key: "param_labels", optional: false } 
                                    ], 
                szCpp:              "cppSubParams",
                checkColIdxSel:     false
            }, {  
                key:                "speichere",        //  key (main)
                arrKeyUtterance:    ["savetmp", "save", "sichere", "speichere", "sz", "st"],             //  substitutes key-words
                szFuncCmd:            "cmd_gdtActiveSaveToTmp",
                checkColIdxSel:     false
            }, {  
                key:                "konsolidiere_überlappend",        //  key (main)
                arrKeyUtterance:    ["konsolidiere_überlappend", "konso_ül", "conso_ovl", "conso_ol", "conso_overlay", "conso_overlayed", "consolidate_overlayed", "conso_over", "consolidate_overlapping", "conso_ov"],             //  substitutes key-words
                szFuncCmd:            "cmd_gdtConsolidateOverlapping",
                arrSubParameters:   [
                                        // { key: "param_periods", optional: false } 
                                        { key: "param_timerange_relative", optional: true },
                                        { key: "param_aggfunc", optional: true },
                                        // { key: "param_offset" , optional: false },
                                        // { key: "param_aggregation_function", optional: true }
                                    ],
                szCpp:              "cppSubParams",
                checkColIdxSel:     true
            }, {  
                key:                "konsolidiere",        //  key (main)
                arrKeyUtterance:    ["konsolidiere", "consolidate", "conso", "konso"],             //  substitutes key-words
                szFuncCmd:            "cmd_gdtConsolidate",
                arrSubParameters:   [
                                        { key: "param_timerange_relative", optional: true },
                                        // { key: "param_periods", optional: false }, 
                                        { key: "param_offset" , optional: false }
                                    ],
                checkColIdxSel:     true
            }, {  
                key:                "korrigiere_werte",        //  key (main)
                arrKeyUtterance:    ["korrigiere_wert", "correct_values", "kw", "cv"], //  substitutes key-words
                szFuncCmd:            "cmd_gdtCorrectValues",
                checkColIdxSel:     true
            },
            {  
                key:                "modifiziere_werte",        //  key (main)
                arrKeyUtterance:    ["modifiziere_werte", "modify_values", "modify", "modifiziere", "modif", "mv"], //  substitutes key-words
                szFuncCmd:            "cmd_gdtModifyValues",
                arrSubParameters:   [
                                        { key: "param_scale", optional: false },
                                        { key: "param_multiply", optional: false },
                                        { key: "param_modify_raw", optional: false }
                                    ],
                checkColIdxSel:     true
            },
            {  
                key:                "kalkuliere_verteilungsparameter", //  key (main)
                arrKeyUtterance:    ["kalkuliere_verteilungsparameter", "calculate_distribution_parameter", "calc_dist_param", "kalk_vert_param", "kvp", "cdp"], //  substitutes key-words
                szFuncCmd:            "cmd_gdtCalcDistributionParam",
                checkColIdxSel:     true
            },
            {  
                key:                "theoretische_verteilung", //  key (main)
                arrKeyUtterance:    ["theoretische_verteilung", "tv", "theoratical_distribution", "theo", "td"],             //  substitutes key-words
                szFuncCmd:            "cmd_gdtAddColumnsTheoreticallyDistributed",
                arrSubParameters:   [
                                        { key: "param_distribution", optional: false },
                                        { key: "param_mean", optional: false, funcCheckIfRequired: (part) => { console.log("SUPERCHECK", part?.getScriptLine()?.collectAllPartsCustomDataAsOne()); } },
                                        { key: "param_stdev", option: false },
                                        { key: "param_dof", option: false }
                                    ],
                checkColIdxSel: true
            },
            {  
                key:                "kalkuliere_korrelationen", //  key (main)
                arrKeyUtterance:    ["kalkuliere_korrelationen", "kalk_korrelation", "calc_correlation", "calc_correl", "correl", "korrel"],   //  substitutes key-words
                szFuncCmd:            "cmd_gdtAddColumnsCorrelations",
                checkColIdxSel:     true
            },
            {  
                key:                "bereinige_trend", //  key (main)
                arrKeyUtterance:    ["bereinige_trend", "bereinigetrend", "entferne_trend", "entfernetrend", "remove_trend", "removetrend", "et", "bt", "rt"], //  substitutes key-words
                szFuncCmd:            "cmd_gdtRemoveTrend",
                checkColIdxSel:     true
            },
            {  
                key:                "aggregiere_zeilenweise", //  key (main)
                arrKeyUtterance:    ["aggregiere_zeilenweise", "aggregiere_reihenweise", "aggregiere_horizontal", "agg_zei", "agg_rei", "agg_hori", "agg_hori", "aggregate_rowwise", "agg_rowwise", "agg_row"],   //  substitutes key-words
                szFuncCmd:            "cmd_gdtAggHorizontally",
                checkColIdxSel:     true
            },
            {  
                key:                "erzeuge_versetzte_sicht", //  key (main)
                arrKeyUtterance:    ["erzeuge_versetzte_sicht", "create_shifted_view", "build_shifted_view", "shifted_view", "erstelle_versetzte_sicht", "versetzte_sicht", "evs", "bsv", "csv", "sv", "vs"],  //  substitutes key-words
                szFuncCmd:            "cmd_gdtBuildShiftedView",
                checkColIdxSel:     true
            }, {  
                key:                "kalk_nvq",        //  key (main)
                arrKeyUtterance:    ["kalk_nvq", "stdeve0_toq"], //  substitutes key-words
                szFuncCmd:          "gdtAddCols_StDevE0_ToQ",
                arrSubParameters:   [
                                    ],
                szCpp:              "cppSubParams",
                checkColIdxSel:     true
            },
            {  
                key:                "zeige", //  key (main)
                arrKeyUtterance:    ["zeige", "show"],  //  substitutes key-words
                szFuncCmd:            "cmd_gdtShow",
                arrSubParameters:   [
                                        { key: "param_showmode", optional: false },
                                        { key: "param_showtype", optional: false }
                                    ],

                szCpp:              "cppSubParams",
                checkColIdxSel:     true
            }
        ];

        // load Interpretation array
        this._smapKeyUtteranceToKey = new StdMap();
        this._smapKeyInfo = new StdMap();

        this._smapKeyInputType = new StdMap();
        
        var funcLoad_01 = (arrInterpretation) => {
            arrInterpretation.forEach((obj) => {
                this._smapKeyInfo.set(obj.key, new ScriptInterpreterInfo({ ...obj, scriptInterpreter: this }));
                // this._smapKeyUtteranceToKey.set(obj.key, obj.key);
                [... obj.arrKeyUtterance, obj.key].forEach((keyUtterance) => {
                    var mapTmp = this._smapKeyUtteranceToKey.get(keyUtterance, true, () => new StdMap());
                    mapTmp.set(obj.key, {});
                    // this._smapKeyUtteranceToKey.set(keyUtterance, obj.key);
                });
            });           
        };

        var funcLoad_02 = (arrInputType) => {
            arrInputType.forEach((obj) => {
                this._smapKeyInputType.set(obj.key, obj);
            });           
        };

        funcLoad_01(arrInterpretation1);
        funcLoad_01(arrInterpretation2);
        
        funcLoad_02(arrInput1);

        // promise chain for code-works
        this._chainJobCode = new PromiseChain({});
    }

    /** */
    executeCmd(cmdInfo, scriptContext) {
        var funcCmd = this[cmdInfo.szFuncCmd];
        if (funcCmd) funcCmd = funcCmd.bind(this);
        
        var bOk = false;
        
        /*
        if (scriptContext.checking) {
            bOk = true;
        } else {
        if (!bOk) {
            // something went wrong ... we'll just check the rest ...
            scriptContext.checking = true;
        }
        */


        if (funcCmd) {
            /*
            if (scriptContext.checking) {
                bOk = true;
            } else {
            */
                bOk = false;
                try {
                    // start primary cmd
                    bOk = funcCmd(scriptContext);
                } catch (e) {
                    // error seen!
                    console.debug(cmdInfo.szFuncCmd, e);
                }

            /*
            }
            */
        }

        return({
            ok:     bOk 
        });
    }

    getMainCmdList() {
        return(
            this._smapKeyInfo.map((val, key) => { return(key) })
        );
    }

    checkScriptLineArray(arrScriptLinesGiven, customData_forScriptContext) {

        if (!customData_forScriptContext.gdtMain || !arrScriptLinesGiven) {
            console.debug(this.constructor.name, "checkScriptLineArray", "cancelled");
            return(undefined);
        }

        var scriptContext = new (this._constructor_ScriptContext)(
            { 
                interpreter:            this,
                checking:               true,
                arrScriptLinesGiven:    arrScriptLinesGiven
            }, 
            customData_forScriptContext
        );

        // "manually" clone the main Gdt to active ...
        scriptContext.onBeforeStartScriptInterpretation();

        // console.debug(this.constructor.name, "checkScriptLineArray", "scriptContext(1)", scriptContext);

        scriptContext.getScriptLinesGiven().some((scriptLine, idx) => {
            // console.debug(this.constructor.name, "checkScriptLineArray", "scriptContext(2)", scriptContext);
            scriptContext.getScriptInterpreter()._funcForEach_processLine(scriptLine, idx, { scriptContext });
            return(false);  // so we have  a "forEach"-equivalent
        });
        return(scriptContext);
    }

    /*
    lookUpSingleLine(code, cursorPosition) {
        var arrResult = this._parseLines(code);
        console.debug(arrResult, arrResult.length);
        for (var i=0; i < arrResult.length; ++i) {
            if ((cursorPosition >= arrResult[i].offset) && (cursorPosition <= arrResult[i].offset+arrResult[i].length)) {
                return(arrResult[i]);
            } 
        }
        return(0);
    }
    */

    convertToScriptLineArray_DraftJsEditorContent(content) {
        var arrResult = [];
        var sc = new (this._constructor_ScriptContext)({ interpreter: this }); 

        if (content && content.blocks) {
            arrResult = 
                content.blocks.map((block, index) => {
                    var scriptLine = 
                        new (this._constructor_ScriptLine)({
                            scriptContext:  sc,
                            code:           block.text,
                            key:            block.key,
                            index,
                            length:         block.text.length
                        });

                    scriptLine.parseParts();
                    return(scriptLine);
                });
        }
        return(arrResult);
    }

    convertToScriptLineArray_txt(lines) {
        var rxLine = /(.*)(\n|$)/gm;
        var matchLine;
        var arrResult = [];
        var lenMaxLines = lines.length;
           while (!(matchLine = rxLine.exec(lines)).done) {
            var scriptLine = new (this._constructor_ScriptLine)({
                code: matchLine[1], 
                key: "" + matchLine.index,
                offset: matchLine.index,
                length: matchLine[1].length
            });

            scriptLine.parseParts();
 
            arrResult.push(scriptLine);
            if (matchLine.index >= lenMaxLines-1) break;
        }

        return(arrResult);
    }

    _onStart(scriptContext) {
        if (this._config.funcCallback_onStart) {
            this._config.funcCallback_onStart(scriptContext);
        }
        scriptContext.onBeforeStartScriptInterpretation();
    }

    _onScriptLineDone(scriptContext) {
        if (this._config.funcCallback_onScriptLineDone) {
            this._config.funcCallback_onScriptLineDone(scriptContext);
        }
    }

    _onResult(scriptContext) {
        if (this._config.funcCallback_onResult) {
            this._config.funcCallback_onResult(scriptContext);
        }
    }

    _onEnd(scriptContext) {
        // console.debug(this.constructor.name, "_onEnd", "scriptContext.arrScriptLinesDone", scriptContext.arrScriptLinesDone);
        if (this._config.funcCallback_onEnd) {
            this._config.funcCallback_onEnd(scriptContext);
        }
    }

    preinterpretePart(part, bKeyChanged) {        
        var bInfoSet = false;
        var infoBase = undefined;

        var infoBase = part.getInterpreterInfoBase();

        if (!part.isBase() && 
            (infoBase = part.getInterpreterInfoBase()) && infoBase.arrSubParameters) {
            // console.debug(this.constructor.name, "addInterpreterInfoToPart", "arrSubParameters.length", infoBase.arrSubParameters.length);
            infoBase.arrSubParameters.some((param, idx) => {
                // param.key
                // param.optional
                var infoCandidate1 = this._findKeyInfo(param.key);

                // console.debug(this.constructor.name, "preinterpretePart", "paramKey", param.key,  "infoCandidate1", idx, infoCandidate1, "for", param.key, "code", part.code);
                if (infoCandidate1 && infoCandidate1.parameterParser) {
                    var result1 = { key: part.getKey(), valTxt: part.getValTxt() };

                    if (result1.key === "" || !result1.key) {
                        result1 = infoCandidate1.parameterParser.parse(part.getCode());
                    }

                    if (result1) {
                        var mapCandidate2 = this._findKeyCandidates(result1.key);
                        if (mapCandidate2) {
                            mapCandidate2.some((obj, key) => {
                                // console.debug("key", key, "obj", obj, "param.key", param.key);
                                if (key === param.key) {
                                    part.setInterpreterInfo(new ScriptInterpreterInfo(infoCandidate1.getProps()));
                                    part.setKeyValTxt(result1.key, result1.valTxt);

                                    // console.debug("key", key, "obj", obj, "param.key", param.key, "INFO WAS SET");
                                    bInfoSet = true;        
                                }
                                return(bInfoSet);
                            });
                        } 
                    } 
                }                
                return(bInfoSet);
            });
        
            if (!bInfoSet) {
                var PP = new ParameterParser();
                var result1 = PP.parse(part.getCode());
                if (result1) {
                    part.setInterpreterInfo(new ScriptInterpreterInfo({}));
                    part.setKeyValTxt(result1.key, result1.valTxt);
                    bInfoSet = true;        
                } 
                // console.debug(this.constructor.name, "NOT bInfoSet", "code", part.getCode(), "result1", result1);
            }
        } 

        // console.debug(this.constructor.name, "part.key", part.key, part.isBase(), "infoBase", infoBase);
        if (!bInfoSet && part.isBase() && (bKeyChanged || !infoBase || infoBase?.isEmpty())) {
            var szKey = bKeyChanged?part.key:part.code;
            var ii = this._findKeyInfo(szKey);
            if (ii) {
                part.setInterpreterInfo(ii);
                part.setKeyValTxt(szKey, "");
                bInfoSet = true;
            }
        }

        if (!bInfoSet) {
            part.setInterpreterInfo(new ScriptInterpreterInfo({}));
            part.setKeyValTxt(part.code, "");
        }

        // console.debug(this.constructor.name, "ii", idx, part.interpreterInfo);
        return(part.getInterpreterInfo());
    }

    _findInputType(keyInputType) {
        return(this._smapKeyInputType.get(keyInputType));
    }

    _findKeyCandidates(key_or_keyUtterance) {
        return(this._smapKeyUtteranceToKey.get(key_or_keyUtterance.toLowerCase()));
    }

    _findKeyInfo(key_or_keyUtterance) {
        var mapCand = this._findKeyCandidates(key_or_keyUtterance);
        // console.debug("_findKeyInfo", "(1)", mapCand, "found for", key_or_keyUtterance);
        if (!mapCand) return(mapCand);

        // now determine the key that should be meant ...
        var keyInfo = undefined;

        // check candidates
        mapCand.some((objKeyCandInfo, keyCand) => {
            keyInfo = this._smapKeyInfo.get(keyCand);
            return(true);
        });
        return(keyInfo);
    }

    // sub-function for scriptprocessing
    _funcForEach_processLine(scriptLine, idx, ccd) {
        var { scriptContext } = ccd;

        // console.debug("_funcForEach_processLine", "scriptContext", scriptContext);
        scriptLine.setScriptContext(scriptContext);
        scriptLine.preinterprete(); 

        // initialize parsed line (new object!)
        scriptContext.activeScriptLine = scriptLine;

        var bOk = scriptLine.isEmpty();

        if (!bOk) {
            var cmdInfo = scriptLine.getInterpreterInfoBase();

            // console.debug("cmdInfo", cmdInfo, "scriptLine", scriptLine);
            if (cmdInfo) {
                // check and correct the current selection
                if (cmdInfo.checkColIdxSel) scriptContext.getScriptInterpreter().cmd_checkCurSel_IdxCol(scriptContext);

                // second interpretation-step
                scriptLine.interprete_2();

                // console.debug("_funcForEach_processLine", "scriptContext", scriptContext);
        
                // get column-names and save "in the scriptLine"
                // console.debug("_funcForEach_processLine", "activeScriptLine", scriptContext.activeScriptLine);
                scriptContext.activeScriptLine.setTsPropertiesAvailable(
                    scriptContext.getScriptInterpreter()._libGdtTs.gdtGetDataColumnProperties(
                        scriptContext.getGdtActive(),
                        scriptContext.getCurSel_WorkInfo().arrIdxCol_curSel
                    )
                );

                // final step: run command
                var { ok: bOk } = scriptContext.getScriptInterpreter().executeCmd(cmdInfo, scriptContext);
            }
        }
        scriptContext.activeScriptLine.bOk = bOk;    // save state

        // push/save the active Line to buffer
        scriptContext.arrScriptLinesDone.push(scriptContext.activeScriptLine);

        return(true); // bOk);
    }

    run(arrScriptLinesGiven, scriptContext_customData) {

        // abort jobChain-Processing ...
        this._chainJobCode.abort();
        // *****************************

        var ccd = this._chainJobCode.getCustomChainData();
        ccd.next = { 
            interpreter: this,
            arrScriptLinesGiven,
            _customData: {
                ... scriptContext_customData    
            }
        };

        // console.debug(this.constructor.name, "chainJobCode", this._chainJobCode);
        this._chainJobCode.set_onAllSettled(function() {
            var ccd = this.getCustomChainData();
            // console.debug(this.constructor.name, "onAllSettled", ccd.nextCode);
            
            if (ccd.scriptContext && ccd.scriptContext.running) {
                ccd.scriptContext.interpreter._onEnd(ccd.scriptContext);
                ccd.scriptContext.running = false;
            }

            if (ccd.next) {
                this.reset(true);   // enforce reset (promisesQueue etc. is emptied)
                ccd = this.resetCustomChainData({ 
                        scriptContext: new (ccd.next.interpreter._constructor_ScriptContext)(ccd.next)
                    });
                var { scriptContext } = ccd;

                scriptContext.interpreter._onStart(scriptContext);
                scriptContext.running = true;
                this.pushToDoListProcessing( 
                    scriptContext.arrScriptLinesGiven, 
                    scriptContext.interpreter._funcForEach_processLine
                );
            }
        });
    }

    // CMDs
    cmd_checkCurSel_IdxCol(scriptContext) {
        var bErr1 = scriptContext.getCurSel_IdxCol().some((idxCol) => (idxCol >= scriptContext.getGdtActive().getNumberOfColumns()));
        if (bErr1) {
            // console.debug(this.constructor.name, "cmd_checkCurSel_IdxCol", "RESETTING");
            this.cmd_resetCurSel_IdxCol(scriptContext);
        }
    }

    cmd_resetCurSel_IdxCol(scriptContext) {
        FabStd.resetArray(scriptContext.getCurSel_IdxCol());
    }

    // includeIdx_0 : default-value is TRUE
    cmd_getCurSel_IdxCol(scriptContext, bIncludeIdxCol0) {
        // console.debug(this.constructor.name, "cmd_getCurSel_IdxCol", scriptContext, "scriptContext.getCurSel_IdxCol", scriptContext.getCurSel_IdxCol());
        return(this.getCurSel_IdxCol(scriptContext.getCurSel_IdxCol(), bIncludeIdxCol0));
    } 

    // helper-function for cmd_getCurSel_IdxCol
    getCurSel_IdxCol(arrIdxCol_curSel, bIncludeIdxCol0) {
        if (
            (bIncludeIdxCol0 === undefined || bIncludeIdxCol0) 
            && arrIdxCol_curSel.length > 0 
            && arrIdxCol_curSel[0] !== 0) {
            return([0, ...arrIdxCol_curSel]);
        } else {
            return([... arrIdxCol_curSel]);
        }
    }

    cmd_gdtModifyValues(scriptContext) {
        var arrIdxCol = scriptContext.arrIdxCol_curSel.length>0?scriptContext.arrIdxCol_curSel:FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);
        var bOk = true;
        var gdt = scriptContext.gdtActive;

        bOk = 
            scriptContext.activeScriptLine_somePart((part, index) => {
                var cd = part.getCustomData();

                if (cd.funcModifier) {
                    // console.debug("cmd_gdtModifyValues", "<", part.getKey(), ">", "cd", cd);
                    return(!this._libGdtTs.gdtModifyValues(gdt, cd.funcModifier, arrIdxCol));
                } else {
                    console.debug("cmd_gdtModifyValues", "part", part, "NO MODIFIER!");
                    // parser problem
                    return(false);  //         
                }
            });

        return(bOk);
    }


    cmd_gdtBuildShiftedView(scriptContext, arrCmdParam) {

        var objPP = {}

        console.log(this.constructor.name, "cmd_gdtBuildShiftedView");

        this._parseCmdParams(objPP, arrCmdParam);

        var bExplicitSelection = scriptContext.arrIdxCol_curSel.length > 0;
        var arrIdxCol = scriptContext.arrIdxCol_curSel.length>0?scriptContext.arrIdxCol_curSel:FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);
        var cntOld = scriptContext.gdtActive.getNumberOfColumns();

        scriptContext.gdtActive = 
            this._libGdtTs.gdtAgg_ShiftedView(scriptContext.gdtActive, objPP.nPeWiSize, arrIdxCol);

        // update selection 
        scriptContext.arrIdxCol_curSel 
            = FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);
        /*
        if (bExplicitSelection) {
            arrIdxCol = [...arrIdxCol, ...FabStd.buildIntegerArrayFromTo(cntOld, scriptContext.gdtActive.getNumberOfColumns()-1)];
            scriptContext.arrIdxCol_curSel = arrIdxCol;
        } else {
            scriptContext.arrIdxCol_curSel = [];
        }
        */

        return(true);
    }

    cmd_gdtMix(scriptContext) {
        console.debug(this.constructor.name, "cmd_gdtMix");

        var cd = scriptContext.activeScriptLine_collectAllPartsCustomDataAsOne();
        var bOk = false;

        /*
        console.debug(
            this.constructor.name, 
            "cmd_gdtMix", 
            scriptContext.activeScriptLine_collectAllPartsCustomDataAsOne(),
            scriptContext.activeScriptLine_getLineCustomData(),
            scriptContext.getAllCustomData(), 
            scriptContext);
        */

        var gdtActive = scriptContext.getGdtActive();
        var { arrIdxCol_curSel: arrIdxCol, bExplicitSelection} = scriptContext.getCurSel_WorkInfo();
    
        var fdt = new FabDataTable();
        fdt.setColumnProperties(0, {
            field: "tsLabel",
            label: "ts",
            type: "string"
        });
        fdt.setColumnProperties(1, {
            field: "weight",
            label: "Gewicht",
            type: "number",
            editable: "true"
        });

        fdt.addRows(cd.arrTsWeights);

        var cntOld = gdtActive.getNumberOfColumns();

        // console.log(this.constructor.name, "fdt", fdt, "arrTsWeights", cd.arrTsWeights, "cd", cd);

        bOk = this._libGdtTs.gdtMix(gdtActive, fdt);

        // adjust selection!
        if (bExplicitSelection) {
            scriptContext.addToCurSel_IdxCol(
                FabStd.buildIntegerArrayFromTo(cntOld, gdtActive.getNumberOfColumns()-1));
        }

        // console.log(this.constructor.name, "cmd_gdtMix", gdtActive);

        return(bOk);
    }


    gdtAddCols_StDevE0_ToQ(scriptContext) {

        var cd = scriptContext.activeScriptLine_collectAllPartsCustomDataAsOne();

        var gdtActive = scriptContext.getGdtActive();
        var { arrIdxCol_curSel: arrIdxCol, bExplicitSelection} = scriptContext.getCurSel_WorkInfo();

        var cntOld = gdtActive.getNumberOfColumns();

        if (!this._libGdtTs.gdtAddCols_StDevE0_ToQ(gdtActive, arrIdxCol)) {
            return(false);
        }

        // adjust selection!
        if (bExplicitSelection) {
            scriptContext.addToCurSel_IdxCol(
                FabStd.buildIntegerArrayFromTo(cntOld, gdtActive.getNumberOfColumns()-1));
        }

        return(true);
    }

    cmd_gdtConsolidateOverlapping(scriptContext) {

        var cd = scriptContext.activeScriptLine_collectAllPartsCustomDataAsOne();

        var gdtActive = scriptContext.getGdtActive();
        var { arrIdxCol_curSel: arrIdxCol, bExplicitSelection} = scriptContext.getCurSel_WorkInfo();
        var arrFuncAgg = arrIdxCol.map((idxCol) => cd.funcAgg?cd.funcAgg:gdtActive.getColumnProperty(idxCol, "funcAggStd") );
        var szPeNameForLabel = "p";
        var arr_nPeWiSize = [ (cd.trrBase?.count)?cd.trrBase.count:250 ]; 
        if (cd.trrBase?.length > 0) szPeNameForLabel = cd.trrBase.type;
        var nOffset = 0;

        if (["m", "w", "y"].includes(cd.trrBase?.type)) {
            // NOCH WIEDER EINBAUEN!
            /*
            console.log("cmd_gdtConsolidateOverlapping", "zwischen", objPP);
            scriptContext.gdtActive = this._libGdtTs.gdtAgg_NonOverlapping(scriptContext.gdtActive, { szPeName: cd.szPeName, nPeWiSize: 1 }, arrIdxCol);
            
            cd.szPeName = "p";   // switch to "period"-mode
            arrIdxCol = FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);
            */
        }

        var cntOld = gdtActive.getNumberOfColumns();

        // build job Overlapping
        var arrJobSub = [];
        var funcLabel = (objInfo) => {
            return(
                objInfo.gdt.getColumnLabel(objInfo.arrColSrc[0]) 
              + "_ol(" + objInfo.nPeWiSize + szPeNameForLabel + ")"
            );
        }

        arr_nPeWiSize.forEach((nPeWiSize) => {
            // console.log("nPeWiSize", nPeWiSize);
            arrIdxCol.forEach((idxCol, idx) => {
                // console.log("nPeWiSize", nPeWiSize, "idxCol", idxCol);
                arrJobSub.push( { 
                    // array of arrays (each array is an arrSrcCol consisting of columns that shall be analyzed parallely)
                    aoaSrcCol: [ [idxCol] ],
                    columnProperties: gdtActive.getColumnProperties(idxCol),
                    funcAgg: (arrA) => arrFuncAgg[idx](arrA),
                    funcLabel: (objInfo) => funcLabel(objInfo),  // use function "funcLabel" further up
                    arrJobAnalysis: [
                        {
                            nOffset: nOffset,
                            nPeWiSize: nPeWiSize,   // just for information
                            nMinInPeWi: nPeWiSize,
                            nMaxInPeWi: nPeWiSize,
                            calcOnlyLastPeWi: false
                        }
                    ]
                });
            });
        });
        // consolidate overlapping!
        this._libGdtTs.gdtAddCols_Overlapping(gdtActive, { arrJobSub: arrJobSub });

        // adjust selection!
        if (bExplicitSelection) {
            scriptContext.addToCurSel_IdxCol(
                FabStd.buildIntegerArrayFromTo(cntOld, gdtActive.getNumberOfColumns()-1));
        }

        return(true);
    }

    _parseParam_scale(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        // just two numbers separated by something 
        var match = /(?=\d)(\d*)[^\d]*(\d*)$/s.exec(part.getValTxt());
        
        if (match) {
            var nFrom = parseInt(match[1]);  // from
            var nTo = parseInt(match[2]);    // to
            var fFac = Math.sqrt(nTo/nFrom);
            objCD.funcModifier = (val) => (val===null)?null:(val*fFac);
        }
    }

    _parseParam_multiply(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        var fFac = numeral(part.getValTxt()).value();
        objCD.funcModifier = (val) => (val===null)?null:(val*fFac);
    }

    _parseParam_modifyRaw(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        var cmdRawJavascript = part.getValTxt();
        objCD.funcModifier = (val) => (val===null)?null:eval(cmdRawJavascript);
    }

    _parseParam_offset(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        objCD.nOffset = parseInt(part.getValTxt());
        if (isNaN(objCD.nOffset)) objCD.nOffset = undefined;    // set to undefined! 
    }

    _buildOptions_periods(part) {
    }

    _buildOptions_show(part) {
    }

    _buildOptions_labels(scriptPositionInfo) {
        var arrLA = scriptPositionInfo?.getScriptLine()?.arrTsPropertiesAvailable;
        if (!arrLA) return([]);
        
        return(
            arrLA.map((label, idx) => { return({ label: label }); })
        );
    }

    _buildOptions_param_distribution(part) {
        return([
            "normal",
            "beta",
            "studentt",
            "chiquadrat"
        ]);
    }

    _parseParam_distribution(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        objCD.showMode = part.getValTxt();

        return(true);
    }

    _buildOptions_param_showmode(part) {
        return([
            "table",
            "chart"
        ]);
    }

    _buildOptions_param_showtype(part) {
        return([ 
            "histogramm",
            "kumuliert"
        ]);
    }

    _parseParam_showmode(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        objCD.showMode = part.getValTxt();

        return(true);
    }

    _parseParam_showtype(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data

        objCD.showType = part.getValTxt();

        return(true);
    }

    _parseParam_periods(part) {
        var objCD = part.getCustomData();   // data-object to be filled with parsed-part-data
        
        var rePeriods = /(?:^((?:\d*)|(?:\d*-\d*))|(^(?:(?:(?:\d*)|(?:\d*-\d*)),(?:(?:\d*)|(?:\d*-\d*)))+))([pdtmwyj]|$)/si;
        // gi = case INsensitive
        var match;
        var arrP, arrCSP;

        if (match = rePeriods.exec(part.getValTxt().toLowerCase())) {
            var arrCSP = [];
            // not comma-delimited ?
            if (match[1]) {
                arrCSP.push(match[1]);
            } else
            // comma delimited ?
            if (match[2]) {
                arrCSP.push(... match[2].split(","));
            }

            // console.log("arrCSP", arrCSP);

            var arrP = [];
            arrCSP.forEach((csp) => {
                // console.log("csp", csp);
                var arrE = csp.split("-");
                var arrTmp;
                var nFrom = parseInt(arrE[0]);
                var nTo = arrE.length>1?parseInt(arrE[1]):nFrom;
                arrTmp = FabStd.buildIntegerArrayFromTo(nFrom, nTo);
                // console.log("arrTmp", arrTmp);
                arrTmp.forEach((n) => { if (!arrP.includes(n)) arrP.push(n); });
            });
            objCD.arr_nPeWiSize = arrP;
            if (arrP.length > 0) objCD.nPeWiSize = arrP[0];
            objCD.szPeName = match[match.length-1]; // last match is always "Periodname"
            
            // harmonize Name 
            switch(objCD.szPeName) {
                case "" :  
                case "t": objCD.szPeName = "d"; break;
                case "j": objCD.szPeName = "y"; break;
            }
        
        } else {
            // parsing not possible
        }
    }
    
    cmd_gdtConsolidate(scriptContext) {
        console.log(this.constructor.name, "cmd_gdtConsolidate");

        var arrIdxCol = scriptContext.arrIdxCol_curSel.length>0?scriptContext.arrIdxCol_curSel:FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);

        var cd = {};
        scriptContext.activeScriptLine_forEachPart((part, idx) => {
            Object.assign(cd, part.getCustomData()); 
        });

        scriptContext.gdtActive = 
            this._libGdtTs.gdtAgg_NonOverlapping(scriptContext.gdtActive, cd, arrIdxCol);

        arrIdxCol = FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);
        scriptContext.arrIdxCol_curSel = arrIdxCol;

        console.log(this.constructor.name, "cmd_gdtConsolidate", "end");
        return(true);
    }

    // show-command
    cmd_gdtShow(scriptContext) {

        var cd;

        console.log(this.constructor.name, "cmd_gdtShow");

        cd = scriptContext?.getAllCustomData();

        // showMode
        // showType
        var obj = {
                gdtToVisualize: scriptContext.getGdtActiveClone(),
                ...cd
            };

        var arrIdxCol = this.cmd_getCurSel_IdxCol(scriptContext, true);

        // if nothing selected ... we select at least something
        if (arrIdxCol.length <= 0) {
            arrIdxCol = 
                obj.gdtToVisualize.getNumberOfColumns()>3
                    ?[0, 1,2,3]
                    :FabStd.buildIntegerArrayFromTo(0, obj.gdtToVisualize.getNumberOfColumns()-1);
        } else {
            if (arrIdxCol.some((idxCol) => (idxCol > obj.gdtToVisualize.getNumberOfColumns()-1))) {
                // use ALL columns
                arrIdxCol = FabStd.buildIntegerArrayFromTo(0, obj.gdtToVisualize.getNumberOfColumns()-1);
                this.cmd_resetCurSel_IdxCol(scriptContext);  // reset selection ...
            }
        }

        // use selection to visualize
        obj.gdtToVisualize = this._libGdtTs.gdtSelectColumns(obj.gdtToVisualize, arrIdxCol);
        this._libGdtTs.gdtRemoveRows_NotInDateRange(obj.gdtToVisualize, cd.dateStart, cd.dateEnd);

        if (!this._libGdtTs.gdtFormatData(obj.gdtToVisualize)) { 
            console.debug(this.constructor.name, "cmd_gdtShow", "failed gdtFormatData");
            return(false);
        }

        // set "script result"
        scriptContext.interpreter._setResult(scriptContext, obj);
        // console.log(this.constructor.name, "cmd_gdtShow", "end");

        return(true);
    }

    _setResult(scriptContext, result) {
        scriptContext.result = result;
        scriptContext.interpreter._onResult(scriptContext);

        // console.debug(this.constructor.name, "_setResult", "scriptContext", scriptContext);
    }

    _parseCmdParams(objPP, arrCmdParam) {
        if (!objPP) return(false);
        
        // analyse Parameter/Value-Pairs
        arrCmdParam.forEach((szParam) => {
            var { param, value } = FabStd.parseParamAndValue(szParam, "=");
            
            param = param.toLowerCase();
            
            // periods
            if (param.startsWith("per")) {
                // parameter "periods"

                var rePeriods = /(?:^((?:\d*)|(?:\d*-\d*))|(^(?:(?:(?:\d*)|(?:\d*-\d*)),(?:(?:\d*)|(?:\d*-\d*)))+))([pdtmwyj]|$)/gi;
                    // /(^\d*)([pdtmwyj]|$)/gi;        // gi = case INsensitive
                var match;

                if (match = rePeriods.exec(value.toLowerCase())) {
                    var arrCSP = [];
                    // not comma-delimited ?
                    if (match[1]) {
                        arrCSP.push(match[1]);
                    } else
                    // comma delimited ?
                    if (match[2]) {
                        arrCSP.push(... match[2].split(","));
                    }

                    var arrP = [];
                    arrCSP.forEach((csp) => {
                        console.log("csp", csp);
                        var arrE = csp.split("-");
                        var arrTmp;
                        var nFrom = +arrE[0];
                        var nTo = arrE.length>1?+arrE[1]:nFrom;
                        arrTmp = FabStd.buildIntegerArrayFromTo(nFrom, nTo);
                        console.log("arrTmp", arrTmp);
                        arrTmp.forEach((n) => { if (!arrP.includes(n)) arrP.push(n); });
                    });
                    objPP.arr_nPeWiSize = arrP;
                    if (arrP.length > 0) objPP.nPeWiSize = arrP[0];
                    objPP.szPeName = match[match.length-1]; // last match is always "Periodname"
                }

                // harmonize periodname
                switch(objPP.szPeName) {
                    case "":
                    case "z":
                    case "per":
                    case "period":
                    case "periode":
                    case "periods":
                    case "perioden": 
                        objPP.szPeName = "p";
                        break;

                    case "t":
                    case "d":
                    case "day":
                    case "tag":
                    case "days":
                    case "tage":
                        objPP.szPeName = "d";
                        break;
                
                    case "w":
                    case "week":
                    case "woche":
                    case "weeks":
                    case "wochen":
                        objPP.szPeName = "w";
                        break;        

                    case "m":
                    case "mon":
                    case "month":
                    case "monat":
                    case "months":
                    case "monate":
                        objPP.szPeName = "m";
                        break;

                    case "j":
                    case "y":
                    case "year":
                    case "jahr":
                    case "years":
                    case "jahre":
                        objPP.szPeName = "y";
                        break;  
                    
                    default:
                        objPP.szPeName = "u";      // unknown, error
                        break;
                } 
            }

            // aggregation
            if (param.startsWith("agg")) {
                objPP.szAggregationShortcut = value.toLowerCase();
            }

            // offset (or starting period)
            if (param.startsWith("off") || param.startsWith("sta") || param.startsWith("ver")) {
                objPP.nOffset = +value;
            }
    
        });

        return(true);
    }

    cmd_gdtAddColumnsCorrelations(scriptContext, arrCmdParam) {

        // CALCULATION OF CORRELATIONS
        var aoaSrcCol = [];
        var gdt = scriptContext.gdtActive;
        var cntColOrig = gdt.getNumberOfColumns();
        var idxColDstNxtNew = cntColOrig; 
        var arrTmp = [];
        var arrCurSel = this.cmd_getCurSel_IdxCol(scriptContext, false);
        var arrIdxColNew = [];
        var nMaxInPeWi = null;
        var nMinInPeWi = null;
        var objPP = {};
        this._parseCmdParams(objPP, arrCmdParam);

        if (objPP.nPeWiSize) {
            if (objPP.nPeWiSize > 0) {
                nMaxInPeWi = objPP.nPeWiSize;
                nMinInPeWi = objPP.nPeWiSize;
            }
            if (objPP.nPeWiSize < 0) {
                nMaxInPeWi = -1;
                nMinInPeWi = null;
            }
        }

        // Period-Window-Size standard correction
        if (!nMaxInPeWi) nMaxInPeWi = -1;  // all
        if (!nMinInPeWi) {
            nMinInPeWi = (nMaxInPeWi <= 0)?gdt.getNumberOfRows()/2:nMaxInPeWi;
        }

        arrCurSel.forEach((idxCol_a, idx_a) => {
            arrCurSel.forEach((idxCol_b, idx_b) => {
                if (idx_b > idx_a) {
                    aoaSrcCol.push([idxCol_a, idxCol_b]);
                    arrTmp.push({
                        idxColDst: idxColDstNxtNew,
                        idxCol_a, 
                        idx_a,
                        idxCol_b, 
                        idx_b
                    });
                    arrIdxColNew.push(idxColDstNxtNew);
                    ++idxColDstNxtNew;
                }
            });
        });

        // ""   Standard: Calculate Correlation-Matrix, use all rows given (at least half of the rows must be populated)
        // Q01 P250
        // Q95%_"number of periods":     Calculate the 95%-Quantil-Matrix with given periodsize (overlapping or not overlapping) 
        
        this._libGdtTs.gdtAddCols_Overlapping(gdt, { 
            arrJobSub: [
                { 
                    // array of arrays (each array is an arrSrcCol consisting of columns that shall be analyzed parallely)
                    aoaSrcCol: aoaSrcCol
                        // FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1).map((b) => {
                        //    return([1, b]);
                        // })
                    ,
                    columnProperties: {
                        dataNumberFormat: CrcStatistics_Main_01.dataNumberFormat_Pct0
                    },
                    funcAgg: (arrA, arrB) => this.funcAggCorrelation(arrA, arrB),
                    arrJobAnalysis: [
                        {
                            calcOnlyLastPeWi: false,    // first ...
                            // nMinInPeWi: 250,
                            // nMaxInPeWi: 250, 
                            nMinInPeWi: nMinInPeWi,
                            nMaxInPeWi: nMaxInPeWi
                        }
                    ]
                } 
            ]
        });

        var gdt_param = 
            this._libGdtTs.gdtBuildDistributionParameterMatrix(
                    gdt, 
                    arrIdxColNew, 
                    [{ paramShortcut: objPP.agg?objPP.agg:"last" }]
            );

        // console.log(this.constructor.name, "DP-MMX", google.visualization.dataTableToCsv(gdt), "agg", szFinalAggregation, "arrCurSel", arrCurSel, "minPWS", nMinInPeWi, "maxPWS", nMaxInPeWi);

        var gdtCorr = new google.visualization.DataTable();

        var val;
        gdtCorr.addColumn({type: "string", role: "domain", label: "Corr"});
        arrCurSel.forEach((idxCol, idx) => {
            var szLabel = gdt.getColumnLabel(idxCol);
            var p = gdt.getColumnProperties(idxCol);
            
            var idxColNew = gdtCorr.addColumn({type: "number", role: "data", label: szLabel, id: szLabel });
            gdtCorr.setColumnProperties(idxColNew, { ... p, dataNumberFormat: CrcStatistics_Main_01.dataNumberFormat_Pct0 });
        
            gdtCorr.addRows(1);
            gdtCorr.setValue(idxColNew-1, 0, szLabel);
            gdtCorr.setValue(idxColNew-1, idxColNew, 1); // fix cross-correlation to 100%
        });

        arrTmp.forEach((obj, idx) => {
            val = null;
            for (var idxRow = gdt_param.getNumberOfRows()-1; idxRow >= 0; --idxRow) {
                val = gdt_param.getValue(idxRow, idx + 1 /* obj.idxColDst */);
                if (val) break; // ok, last value found!
            }

            gdtCorr.setValue(obj.idx_b, 1+obj.idx_a, val);
            gdtCorr.setValue(obj.idx_a, 1+obj.idx_b, val);
        });

        // console.log("gdtCorr", google.visualization.dataTableToCsv(gdtCorr));

        scriptContext.gdtActive = gdtCorr;
        // update current selection to "all"
        scriptContext.arrIdxCol_curSel = FabStd.buildIntegerArrayFromTo(1, scriptContext.gdtActive.getNumberOfColumns()-1);

        return(true);
    }

    cmd_gdtAggHorizontally(scriptContext, arrCmdParam) {
        var szLabel;
        var arrTmp;
        var arrNew;
        var arrCurSel = this.cmd_getCurSel_IdxCol(scriptContext, false);
        var smapArrIdxCol = new StdMap();
        var szConsoLabel;
        var bPush = false;
        var objPP = {}
        var objJob;
        var arrJob = [];
        var gdt = scriptContext.gdtActive;

        console.log(this.constructor.name, "cmd_gdtAggHorizontally");

        this._parseCmdParams(objPP, arrCmdParam);

        arrCurSel.forEach((idxCol) => {
            szLabel = gdt.getColumnLabel(idxCol);
            szConsoLabel = "";
            // parseLabel
            // remove "extension" e.g. /shift ...
            arrTmp = FabStd.parseDelimitedLines(szLabel, " ")[0];
            
            arrNew = [];
            
            // go through "parts"
            arrTmp.forEach((part) => {
                var match;
                var reExtension = /^([^\(]*)\((.*)\)$/g;   // to look for "/blabla(value)"

                console.log("part", part);
                bPush = true;
                if (match = reExtension.exec(part)) {
                    console.log("reExtension", match);
                    switch(match[1]) {
                        case "/Shift":
                            bPush = false;
                            break;
                        default:
                    }
                }

                if (bPush) arrNew.push(part);
                // here we shall check for "extension"-identifiers
            });

            szConsoLabel = arrNew.join(" ");

            objJob = smapArrIdxCol.get(szConsoLabel);
            if (!objJob) {
                objJob = { arrIdxCol: [], label: szConsoLabel };
                smapArrIdxCol.set(szConsoLabel, objJob);
                arrJob.push(objJob);
            }
            objJob.arrIdxCol.push(idxCol); 
        });

        this._libGdtTs.gdtAggHorizontally(scriptContext.gdtActive, arrJob);
    
        return(true);
    }

    cmd_gdtAddColumnsTheoreticallyDistributed(scriptContext, arrCmdParam) {
        console.log(this.constructor.name, "cmd_gdtAddColumnsTheoreticallyDistributed()");

        var bOk = false;
        var diPaOptions_StDev = {};
        var diPaOptions_Avg = {};
        var fFactorStDev = arrCmdParam?(+arrCmdParam[0]):1;    // "+" is used to parse the number ...

        console.log(this.constructor.name, "cmd_gdtAddColumnsTheoreticallyDistributed()", "1");

        // fFactorStdDev = 1;

        if (!isNaN(fFactorStDev)) {
            diPaOptions_StDev.fFactor = fFactorStDev;
        }

        console.log(this.constructor.name, "cmd_gdtAddColumnsTheoreticallyDistributed()", "1.5");

        var arrBuildParam = 
            this._libGdtTs.createBuildParam_NormalDistribution( 
                scriptContext.gdtActive,
                this.cmd_getCurSel_IdxCol(scriptContext, false),

                this._libGdtTs.gdtCalcDistributionParam(scriptContext.gdtActive, 
                    this.cmd_getCurSel_IdxCol(scriptContext, false),
                    this._libGdtTs.funcAggStDev, 
                    diPaOptions_StDev
                    ),

                this._libGdtTs.gdtCalcDistributionParam(scriptContext.gdtActive, 
                    this.cmd_getCurSel_IdxCol(scriptContext, false),
                    this._libGdtTs.funcAggAverage,
                    diPaOptions_Avg
                    )
            );

        console.log(this.constructor.name, "cmd_gdtAddColumnsTheoreticallyDistributed()", "2");

        bOk = this._libGdtTs.gdtAddCols_ThDi_NormalDistribution(scriptContext.gdtActive, arrBuildParam);
        
        console.log(this.constructor.name, "cmd_gdtAddColumnsTheoreticallyDistributed()", "result", bOk);
        return(bOk);
    }

    cmd_gdtActiveLoadFromTmp(scriptContext, arrCmdParam) {
        var objSave = scriptContext.smapTmpGdt.get(arrCmdParam[0]);
        if (objSave && objSave.gdtActive) {
            scriptContext.gdtActive = objSave.gdtActive.clone();
            scriptContext.arrIdxCol_curSel = objSave.arrIdxCol_curSel;
            return(true);
        } else {
            return(false);
        }
    }

    cmd_gdtActiveSaveToTmp(scriptContext, arrCmdParam) {
        if (arrCmdParam[0] && arrCmdParam[0].length > 0) {
            var objSave = {
                gdtActive: 
                    (!scriptContext.arrIdxCol_curSel || scriptContext.arrIdxCol_curSel.length<=0)?
                        scriptContext.gdtActive.clone():
                        this._libGdtTs.gdtSelectColumns(scriptContext.gdtActive, this.cmd_getCurSel_IdxCol(scriptContext, true)),
                arrIdxCol_curSel: []
            };
        
            scriptContext.smapTmpGdt.set(arrCmdParam[0], objSave);
            return(true);
        }
        return(false);
    }

    cmd_gdtCorrectValues(scriptContext, arrCmdParam) {
        var arrIdxCol = this.cmd_getCurSel_IdxCol(scriptContext, false);
        while (arrCmdParam.length < 2) arrCmdParam.push("");

        switch(arrCmdParam[0].toLowerCase()) {
            default:
            case "0_to_null":
                this._libGdtTs.gdtModifyValues(
                    scriptContext.gdtActive, 
                    (valResult, info) => {
                        if (valResult === 0) {
                            return(null);   // "eliminate"
                        }
                        return(undefined);  // to tell: no correction needed!
                    },
                    arrIdxCol
                );
                break;
        }
        return(true);
    }

    cmd_gdtRemoveTrend(scriptContext, arrCmdParam) {

        var arrIdxCol = this.cmd_getCurSel_IdxCol(scriptContext, false);
        while (arrCmdParam.length < 2) arrCmdParam.push("");

        switch(arrCmdParam[0].toLowerCase()) {
            case "linear":
            default:
                var gdt_param = 
                    this._libGdtTs.gdtCalcDistributionParam(
                                scriptContext.gdtActive, 
                                this.cmd_getCurSel_IdxCol(scriptContext, false), // don't include Index 0
                                this.funcAggAverage,
                                { titleResultRow: "Avg" }
                    );

                this._libGdtTs.gdtModifyValues(
                    scriptContext.gdtActive, 
                    (valResult, info) => {
                        if (valResult !== null) {
                            return(valResult - gdt_param.getValue(0, 1+info.idxColSel));
                        }
                        return(undefined);  // to tell: no correction needed!
                    },
                    arrIdxCol
                );
                break;
        }

        return(true);
    }

    cmd_gdtCalcDistributionParam(scriptContext, arrCmdParam) {
        var szNameDstTmpGdt;
        var gdt_param = undefined;
        var arrJob = [];

        arrCmdParam.forEach((param) => {
            if (param.substr(param, 0, 3).toLowerCase() === "tab") {
                szNameDstTmpGdt = param;
            } else {
                arrJob.push({ paramShortcut: param });
            }
        });

        if (arrJob.length > 0) {
            gdt_param 
                = this._libGdtTs.gdtBuildDistributionParameterMatrix(
                        scriptContext.gdtActive, 
                        this.cmd_getCurSel_IdxCol(scriptContext, false), 
                        arrJob 
            );
        }

        if (!gdt_param) return(false);

        if (szNameDstTmpGdt) {
            this.cmd_gdtActiveSaveToTmp({
                smapTmpGdt: scriptContext.smapTmpGdt, 
                gdtActive: gdt_param,
                arrIdxCol_curSel: []
            }, [ szNameDstTmpGdt ]);
        } else {
            scriptContext.gdtActive = gdt_param;   // use "as active"
            this.cmd_resetCurSel_IdxCol(scriptContext);
            this.cmd_gdtSelectColumnsViaLabels(scriptContext, ["*"]); // select all
        }
    }

    cmd_gdtConfig(scriptContext) {  
        console.log(this.constructor.name, "cmd_gdtConfig()");

        var result = scriptContext.getActiveScriptLine().collectAllPartsCustomDataAsOne();

        var cd = scriptContext.getCustomData();
        Object.assign(cd, result);  // we add the contents of RESULT to the customdata-object

        return(true);
    }

    cmd_gdtDeselectColumnsViaLabels(scriptContext) {
        return(this.cmd_gdtSelectColumnsViaLabels(scriptContext, true));
    }

    // result: idxColArray
    cmd_gdtSelectColumnsViaLabels(scriptContext, bDeSelect) {  
        console.log(this.constructor.name, "cmd_gdtSelectColumnsViaLabels()");

        if (!scriptContext.getGdtActive()) return(false);
        var gdtActive = scriptContext.getGdtActive();
        var arrIdxCol_curSel = scriptContext.getCurSel_IdxCol();

        // FabStd.resetArray(arrIdxCol_curSel);

        scriptContext.activeScriptLine_forEachPart((part, index) => {
            // console.debug(this.constructor.name, "cmd_gdtSelectColumnsViaLabels", "part", part);

            part.getCustomData().arrTsLabels?.forEach((obj) => {
                var tmpParam = obj.label;
                // console.debug(this.constructor.name, "cmd_gdtSelectColumnsViaLabels", "obj", obj);
                if (typeof tmpParam === "string") {
                    tmpParam = "^" + tmpParam.replace(/\*/g, ".*").replace(/\_/g, "\\_").replace(/\?/g, ".?") + "$";
                }
    
                // console.debug(this.constructor.name, "cmd_gdtSelectColumnsViaLabels", "part", part, "scriptContext", scriptContext, "tmpParam", tmpParam, "bDeselect", bDeSelect);
                for (var idxCol=1; idxCol < gdtActive.getNumberOfColumns();++idxCol) {
                    var szLabel = gdtActive.getColumnLabel(idxCol);
                    // console.log("idxCol", idxCol, szLabel, tmpParam);
    
                    if (szLabel.match(tmpParam)) {
                        // console.log("idxCol", idxCol, szLabel, "FOUND", tmpParam);
    
                        var nF = arrIdxCol_curSel.indexOf(idxCol);
    
                        // we already have the labe-columnidx and we shall deselect ? ... so remove it!
                        if (nF >= 0 && bDeSelect) {
                            arrIdxCol_curSel.splice(nF, 1);
                        }
                        // label-columnidx not found and we shall not deselect ? ... so: add id!
                        if (nF < 0 && !bDeSelect) {
                            arrIdxCol_curSel.push(idxCol);
                        }
                    }
                }    
            }); 
        });
        return(true);
    }
}
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import ControlledReactComponentRegister from './fabbi_controlled_react_component_register.js';
import ControlledReactComponentExtensionParent from "./fabbi_controlled_react_component_extension_parent.jsx";

export default class ControlledReactComponentParent extends ControlledReactComponent {
    constructor(props) {
        super(props);

        // will contain only MOUTEND components
        this.m_regCRC = new ControlledReactComponentRegister("id_child");
        this._closing = false;
        this._closed = false;
        this._fncToDoWhenClosed = undefined;
    }

    close(fncToDoWhenClosed) {
        if (this.isMounted()) {
            this._closing = true;
            this._fncToDoWhenClosed = fncToDoWhenClosed;
            this.setState({ }); // a rerendering is initiated ...
        } 
    }

   /*
    Returns, if the Component is about to close ... this is essential
    for the RENDER-operation, because we have to exclude all childs from rerendering in 
    order to close them.
    */
    isClosing() {
        return(this._closing);
    }

    isClosed() {
        return(this._closed);
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        // console.log(this.constructor.name, "componentDidUpdate()", "isClosing() ?");
        if (this.isClosing()) {
            console.log(this.constructor.name, "componentDidUpdate()", "force unmount");
            setTimeout(() => { 
                this.unmount();
                this._closed = true; 
            }, 0);
        }
    }

    componentDidMount() {
        super.componentDidMount();
    
        // console.log(this.constructor.name, "componentDidMount", this.getIdDOM());
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        // console.log(this.constructor.name, "componentWillUnmount()", "finished");
        // this.m_regCRC.deregisterAndUnmountAll(); // and her we unmount all the children ...
    }

    // must be called by every child of this parent-component (whithin "componentDidMount")
    childComponentDidMount(childCRC) {
        this.m_regCRC.registerControlledReactComponent(childCRC);   // childCRC must have a "id_child" to be recognized!

        // console.log(this.constructor.name, "CRCXX", "child", this.__arrExtensions.length, this._allParentExtensions().length, this._allParentExtensions());
        // go through all (parent) extensions and report the newly added child
        this._allParentExtensions().forEach((ext) => {
            ext.underlyingChildComponentDidMount(childCRC);
        });
    }

    childComponentDidUpdate(childCRC, prevProps, prevState, snapshot) {
        // go through all (parent) extensions and report update
        this._allParentExtensions().forEach((ext) => {
            ext.underlyingChildComponentDidUpdate(childCRC, prevProps, prevState, snapshot);
        });
    }

    _allParentExtensions() {
        return(this.__arrExtensions.filter((ext) => { return(ext instanceof ControlledReactComponentExtensionParent); }));
    }

    _doForEachExtensionParent(szFuncToCall) {
        // go through all (parent) extensions and report the newly added child
        this.__arrExtensions.filter((ext) => { return(ext instanceof ControlledReactComponentExtensionParent); }).forEach((ext) => {
            ext[szFuncToCall](ext);
        });
    }

    childComponentWillUnmount(childCRC) {
        // go through all (parent) extensions and report the newly added child
        this._allParentExtensions().forEach((ext) => {
            ext.underlyingChildComponentWillUnmount(childCRC);
        });

        this.m_regCRC.deregisterControlledReactComponent(childCRC);
    }

    /** */
    moveFocusToNextChildCrc(crcChildActive) {
        var crcFirst = undefined;
        var crcFound = undefined;
        var bFound = false;
        this.forEachChildCRC((crcTmp) => {
            if (!crcFirst) crcFirst = crcTmp;
            if (crcTmp === crcChildActive) bFound = true; else if (!crcFound) crcFound = crcTmp;      
        });
        setTimeout(() => { if (crcFound) crcFound.focus(); else crcFirst = crcFirst?.focus(); });
    }

    forEachChildCRC(funcForEach_crcChild) {
        this.m_regCRC.forEachCRC((crcChild, id) => funcForEach_crcChild(crcChild, id));
    }

    setState_Children(stateChange) {
        // console.log(this.constructor.name, "stateChange", this.m_regCRC);
        this.forEachChildCRC((crcChild, id) => {
            console.debug(this.constructor.name, "stateChange", id, stateChange);
            crcChild.setState(stateChange);
        });
    }

    getChildCRC(id_child) {
        return(this.m_regCRC.getCRC(id_child));
    }

    /** Force an update of the react-Parent or just a single Item of the List
    *  @param {String=} id_child optional if specified the single entry ("id_child") will be updated!
    */ 
    updateChild(id_child) {  
        if (id_child) {
            // console.log(this.constructor.name, "CRC-Update 1", id_child);
            var crc = this.m_regCRC.getCRC(id_child);
            if (crc) {
                // console.log(this.constructor.name, "CRC-Update 2", id_child, crc);
                return(crc.update());
            } else {
                // nothing ... no promise delivered
                return(undefined);
            }
        } else {
            return(this.update());     
        }
    }
}

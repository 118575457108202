import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcFamilies_Entry_01 from "./fabbi_crc_families_entry_01.jsx";
import CrcFamilies_Entry_02 from "./fabbi_crc_families_entry_02.jsx";
import CrcExt_Scrollbar from "./fabbi_crc_ext_scrollbar.jsx";

export default class HtmlProcessor_Families extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        var sb = new CrcExt_Scrollbar({
            container: "#families-div-scrollarea",
            options:   { suppressScrollX: true }
        });

        props.HtmlElementIdPrefix = "families",
        props.crcExtensions_entryList = [ /* ... props.crcExtensions_entryList, */ sb ]; 
        
        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcFamilies_Entry_01
        }

        props.portalsForActiveEntry = [
            { 
                id_dom: "div_family_active",
                crcPrototype_entry: CrcFamilies_Entry_01 
            },
            { 
                id_dom: "div_family_active_for_messages",
                crcPrototype_entry: CrcFamilies_Entry_02 
            }
        ];

        return(props);
    }
     
    onPrepareEntry(id_entry, entry) {

        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;
        
        var szMsg = "";
        var szMsgTime = "";
        var msg;   
        let szTmpId = id_entry;
        var qryP_Messages;
        try {
            qryP_Messages = this.getLeadingDataPool().getParent().getChildQP_Messages(id_entry);       
        } catch(e) {
            console.log(this.constructor.name, "ERROR", this.getLeadingDataPool().getParent().constructor.name);
        }                 
        var msgStat;
        var msgDataset
        
        if (qryP_Messages != undefined) {
            msgStat = qryP_Messages.getStatistics();  
            // console.log("msgStat", msgStat);
            
            msg = qryP_Messages.getLastMessage();                                    
            if (msg != undefined && msg.entry != undefined) {            
                msgDataset = msg.entry["dataset"];
                szMsg = msgDataset.content;                
                szMsgTime = FabStd.convertTimestampToLocaleDateString(msgDataset.ts_added, "");
            
                // templateHtml.prepareDatasetHtml(id_entry, msgDataset, datasetExtra, { ...objOptions, datafieldPrefix: "msg" });
            }

            datasetExtra[HtmlProcessor_Families.ID_TXT_MSG_COUNT] =  { innerHTML: msgStat.m_data.count };
            datasetExtra[HtmlProcessor_Families.ID_TXT_MSG_COUNT_NEW] = { innerHTML: msgStat.m_data.count_new };

            // templateHtml.prepareDatasetHtml(id_entry, msgStat.m_data, datasetExtra, { ...objOptions, datafieldPrefix: "msgstat" });
        }
        // templateHtml.prepareDatasetHtml(id_entry, dataset, datasetExtra, objOptions);

        datasetExtra["data-last_msg"] = msgDataset;
        datasetExtra["data-msgstat"] = msgStat.m_data;
    }
}
HtmlProcessor_Families.ID_DIV_SECTION           = "families-div-section"        ;
HtmlProcessor_Families.ID_DIV_ENTRY             = "families-div-entry"          ;
HtmlProcessor_Families.ID_IMG_MAIN              = "families-img-main"           ;
HtmlProcessor_Families.ID_SRC_IMAGE             = "families-src-image"          ;
HtmlProcessor_Families.ID_TXT_NAME              = "families-txt-name"           ;
HtmlProcessor_Families.ID_TXT_INITIALS          = "families-txt-initials"       ;
HtmlProcessor_Families.ID_TXT_INFO              = "families-txt-info"           ;
HtmlProcessor_Families.ID_TXT_TIME              = "families-txt-time"           ;
HtmlProcessor_Families.ID_TXT_MSG_COUNT         = "families-txt-msg-count"      ;
HtmlProcessor_Families.ID_TXT_MSG_COUNT_NEW     = "families-txt-msg-count-new"  ;

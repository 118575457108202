import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import CrcTimeLeftDisplay_01 from "./fabbi_crc_time_left_display_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";

export default class CrcTicker_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidMount() {
        super.componentDidMount();
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        return(
            <li id={this.getIdDOM()} className={"animate fadeUp"} 
                style={
                    {
                        width: "inherit",
                        borderBottomStyle: "solid", 
                        borderBottomWidth: "thin", 
                        borderBottomColor: "black", 
                        paddingBottom: "5px", 
                        marginBottom: "5px"
                    }}
                >
                <div className={"row valign-wrapper"} style={{ width: "inherit" }}>
                    <div className={""} style={{ width: "inherit", display: "flex" }}>
                        <div 
                            className={"col pr-0"} 
                            style={{ 
                                paddingLeft: "0px", 
                                width: "3.5rem", 
                                flexGrow: 0
                                }}>
                            <div className={"z-depth-2 fab-thumb-1rem circle fab-flexbox-center"} style={{ backgroundColor: "lightblue" }}>!</div> 
                            <img alt="" className={"circle z-depth-2 fab-thumb-1rem invisible"}/>
                        </div>
                        <div className={"col pr-0"} style={{ paddingLeft: "0px", flexGrow: 1, maxWidth: "96%", display: "inline-flex" }}>
                            <div style={{width: "20%"}}><p class="m-0 blue-grey-text text-darken-4 font-weight-700" id="ticker-txt-subject">TICKERTEXT</p></div>
                            <div style={{maxWidth: "80%", flexGrow: 1}}><p className={"m-0 fab-info-text"}>Infotext</p></div>
                        </div>
                    </div>
                </div>        
            </li>  
        );
   }
}
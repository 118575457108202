import React from 'react';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import googleImg from "../../images/logo/btn_google_signin_dark_normal_web.png";
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
import CrcModalWait_01 from "./fabbi_crc_modalwait_01.jsx";
import qryProcessor_KnownEmails from "./fabbi_qry_processor_knownemails.js";

export default class CrcSignUp_ModalInput_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._ctrl = this.getWfCtrl().getFirebaseCtrl();

        this.state.eMailValid = false;
        this.state.eMailCheck = "please enter the email-adress";

        this.state.pwc = {};    // password check

        this._chainCheckEmail = new PromiseChain({ parent: this, wfCtrl: this.getWfCtrl() });
    }

    getIdDOM_InputEmail() {
        return(this.getIdDOM() + "_input_email");
    }

    getDbValue_InputEmail() {
        return(this.getCRC(this.getIdDOM_InputEmail()).getDbValue());
    }

    getIdDOM_InputPassword() {
        return(this.getIdDOM() + "_input_password");
    }

    getIdDOM_InputPassword2() {
        return(this.getIdDOM() + "_input_password2");
    }

    getDbValue_InputPassword() {
        return(this.getCRC(document.getElementById(this.getIdDOM_InputPassword())).getDbValue());
    }

    getDbValue_InputPassword2() {
        return(this.getCRC(document.getElementById(this.getIdDOM_InputPassword2())).getDbValue());
    }

    onInput_eMail(email) {
        this._chainCheckEmail.abort();
        this._chainCheckEmail.set_onAllSettled(function() {                    
            var { parent, wfCtrl } = this.getCustomChainData();

            parent.setState({ 
                eMailCheck: "...checking ...", 
                eMailValid: false,
                btnSend: "disabled"
            });

            if (FabStd.validateEmail(email)) {                            
                // wfCtrl.domeSet(elemInfo, "eMail formally OK", "innerHTML");
                this.getCustomChainData().parent.setState({ 
                    eMailCheck: "formally OK"
                });

                this.pushPromise(function(resolve, reject) {
                    var { parent, wfCtrl } = this.getCustomChainData();
                    // console.log(this.constructor.name, "wfCtrl", wfCtrl, "parent", parent);                    
                    var test = new qryProcessor_KnownEmails({
                        wfCtrl, 
                        szTriggerId: wfCtrl.getMainUserId(),
                        szUserId: wfCtrl.getMainUserId()
                    });                           

                    test.readDoc(email).then((result) => {
                        var { parent, wfCtrl } = this.getCustomChainData();
                        // console.log(this.constructor.name, "eMail-check-result", result);

                        if (result.rc === "OK") {
                            var dataSnapshot = result.dataSnapshot;
                            if (dataSnapshot.exists) {
                                // console.log(this.constructor.name, "eMail in use");
                                // eMail is in already use! can't be used to register a new !
                                parent.setState(
                                    {
                                        eMailCheck: "already in use",
                                    });
                            } else {
                                // console.log(this.constructor.name, "eMail unknown! good!");
                                // eMail unknown! we can use it to register new user!    
                                parent.setState(
                                    {
                                        eMailCheck: "eMail ok",
                                        eMailValid: true,
                                        btnSend: "enabled" 
                                    });
                            }
                        } else {
                            // console.log(this.constructor.name, "eMail error while checking");
                            // eMail is in already use! can't be used to register a new !
                            parent.setState(
                                {
                                    eMailCheck: "error while checking",
                                });
                        }
                    }).finally(() => { 
                        resolve( { pd: this, rc: "OK" } ); 
                    });
                });                      
            } else {                          
                parent.setState({
                    eMailCheck: "formally invalid"
                });
                // resolve( { pd: this, rc: "OK" });
            }  
        });    
    }

    onInput_Password(pw, pw2) {
        console.log(this.constructor.name, "onInput_password");

        var lowerCaseLetters = /[a-z]/g;    // lower case letters
        var upperCaseLetters = /[A-Z]/g;    // upper case letters (capital letters)
        var numbers = /[0-9]/g;             // numnber appearances           

        var pwc = {
            lowercase: !!pw.match(lowerCaseLetters),
            uppercase: !!pw.match(upperCaseLetters),
            number: !!pw.match(numbers),
            length: pw.length>=8,
            repeat: pw === pw2
        }

        console.log(this.constructor.name, "pwc.repeat", pwc.repeat, "pw", pw, "pw2", pw2);

        pwc.formal = pwc.lowercase & pwc.uppercase & pwc.number & pwc.length;
        pwc.all =  pwc.formal & pwc.repeat;

        this.setState({ pwc });
    }

    render() {
        return(
            <form id={this.getIdDOM()}>
                <span id={"dummy_123"}/>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>Please choose Method to register</div>        
                    <p/>             
                    <div className="row">
                        <a><img id={"signup-via-google"} src={googleImg} alt="Sign up with Google" href="" data-i18n="" 
                            onClick={(event) => {

                            }}
                        />
                        </a>
                    </div>
                    <hr/>
                    <div className="row">
                        <h5>via eMail/Password</h5>
                        <CrcInput_01 
                            id_dom={this.getIdDOM_InputEmail()} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder={ "eMail - " + this.state.eMailCheck }
                            type="email"
                            required 
                            valueIsValid={this.state.eMailValid}
                            onInput={(crcInp) => { this.onInput_eMail(crcInp.getDbValue()); }}
                            defaultValue={this.props.email}/>

                        <CrcInput_01 
                            id_dom={this.getIdDOM_InputPassword()} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Password"
                            valueIsValid={this.state.pwc.formal}
                            type="password"
                            required
                            onInput={(crcInp) => { this.onInput_Password(crcInp.getDbValue(), this.getDbValue_InputPassword2()); }}
                            defaultValue={""}/>

                        <span id="check-password">                            
                        <b>Password must contain the following:</b>                    
                        <p className={this.state.pwc.lowercase?"valid":"invalid"}>A <b>lowercase</b> letter</p>
                        <p className={this.state.pwc.uppercase?"valid":"invalid"}>A <b>capital (uppercase)</b> letter</p>
                        <p className={this.state.pwc.number?"valid":"invalid"}>A <b>number</b></p>
                        <p className={this.state.pwc.length?"valid":"invalid"}>Minimum <b>8 characters</b></p>        
                        </span>   

                        <CrcInput_01 
                            id_dom={this.getIdDOM_InputPassword2()} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Password (repetition)"
                            valueIsValid={this.state.pwc.all}
                            type="password"
                            required 
                            onInput={(crcInp) => { this.onInput_Password(this.getDbValue_InputPassword(), crcInp.getDbValue()); }}
                            defaultValue={""}/>
 
                        <div className="col s12">
                            <a className={"btn waves-effect waves-light send modal-close " + (this.state.pwc.all & this.state.eMailValid?"enabled":"disabled")} 
                                id="signup-via-email-and-password" 
                                onClick={
                                    (event) =>  {
                                        // close modal window ... which is the parent of this window
                                        var email = this.getDbValue_InputEmail();
                                        var pw = this.getDbValue_InputPassword();

                                        var id_dom_wait = 
                                        CrcModal_01.openAsModal(this.getWfCtrl(), 
                                            { 
                                                style: { 
                                                    ...CrcModal_01.propStyle_FullScreen, 
                                                    opacity: "50%"
                                                } 
                                            },
                                            CrcModalWait_01, {
                                            } 
                                        );

                                        // this.getParent().close();
                                        console.log("SignUp", email, pw);
                                        this._ctrl.signUpWithEmailAndPassword(email, pw).then((result) => {
                                            console.log(this.constructor.name, "close", "id_dom_wait", id_dom_wait);
                                            CrcModal_01.closeModal(this.getWfCtrl(), id_dom_wait);
                                            if (result.rc === "OK") {
                                                this.close();
                                            }
                                        }).finally(() => {
                                            console.log(this.constructor.name, "finally", "id_dom_wait", id_dom_wait);
                                         });
                                        // this.props.func_SignUpWithEmailAndPassword(email, pw);
                                    }
                                }>
                                Sign up
                            </a>
                        </div>      
                    </div>                            
                </div>
            </form>  
        );
    }
}
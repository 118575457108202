import React, { useRef } from 'react';
import CrcWait_01 from "./fabbi_crc_wait_01.jsx";
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import CrcStatistics_Main_01 from "./fabbi_crc_statistics_main_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import * as FabXlsx from "../fabstd/fabbi_xlsx.js";
import Lib_GdtBasis from "./fabbi_lib_gdt_basis.js";
import moment from 'moment';
// import { imul } from 'core-js/fn/number';

export default class CrcStatisticsVisualizer_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._show = undefined;
        this._wb = undefined; 
        this._ws = undefined;
        // this._includeColumns = [2];

        this.libGdtBasis = new Lib_GdtBasis();

        this._bFirstUpdate = true;
        this._bSysReady = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this._bFirstUpdate || prevProps.showData !== this.props.showData) {
            this._bFirstUpdate = false;
            this.draw();
        }
        return(super.componentDidUpdate(prevProps, prevState, snapshot));
    } 

    componentWillUnmount() {
        super.componentWillUnmount();
        this._removeListeners();
    }

    // just run once after DOM-entry is created ... afterward componentDidUpdate(..) is used
    componentDidMount() {
        super.componentDidMount();

        console.log(this.constructor.name, "componentDidMount");

        if (!google.visualization || !google.visualization.Table  || !google.visualization.ComboChart) { 
            google.charts.load("current", {packages:["corechart", "table"], 'language': 'de'});
            google.charts.setOnLoadCallback(() => { this._onLoadCallback_Statistics() });
        } else {
            this._onLoadCallback_Statistics();  // call directly - chartsystem is already loaded!
        }
    }

    _selectHandler(selection) {
        console.log(this.constructor.name, "item selected", selection);
        if (selection.length === 1) {
            if (!selection[0].row && selection[0].column) {
                
                var idxCol_selected = selection[0].column;
                var idxSeries_selected = -1;
                var szRole;
                
                console.log(this.constructor.name, "_selectHandler", idxCol_selected);

                for (var idxCol = 0; idxCol <= idxCol_selected; ++idxCol) {
                    szRole = this._gdtChart.getColumnRole(idxCol);
                    if (!szRole || szRole === "" || szRole === "data") ++idxSeries_selected;
                    console.log(idxCol, idxCol_selected, this._gdtChart.getColumnRole(idxCol), idxSeries_selected);
                }

                if (idxSeries_selected >= 0) {
                    this._chartOptions.series[idxSeries_selected] = {
                        pointShape: { type: 'triangle', pointSize: 8 },
                        type: "line"
                    };
                }

                this._drawPreparedChart();
            }
        }
    }

    _setupListeners() {
      
        console.log(this.constructor.name, "_setupListeners");

        if (this._show && this.getShowMode() === "chart") {
            this._listenerChartSelect = 
                google.visualization.events.addListener(
                    this._show, 
                    "select", 
                    () => { 
                        console.log(this.constructor.name, "select-listener", "before _selectHandler", this._show.getSelection()); 
                        this._selectHandler(this._show.getSelection()) 
                    }
                );    
        }
    }

    _removeListeners() {
        if (this._listenerChartReady) google.visualization.events.removeListener(this._show, "ready", this._listenerChartReady);    
        this._listenerChartReady = undefined;

        if (this._listenerChartSelect) google.visualization.events.removeListener(this._show, "select", this._listenerChartSelect);    
        this._listenerChartSelect = undefined;

        if (this._listenerKeyDownBound) window.removeEventListener("keydown", this._listenerKeyDownBound);
        this._listenerKeyDown = undefined;
    }

    getIdDOM_Showroom() {
        return(this.getIdDOM() + "_showroom");
    }

    getIdDOM_Toolbar() {
        return(this.getIdDOM() + "_toolbar");
    }

    getShowMode() {
        // console.log(this.constructor.name, "getShowMode", this.props.showData);
        if (!this.props.showData || !this.props.showData.showMode) return("table");
        
        switch(this.props.showData.showMode.toLowerCase()) {
            case "tabelle": return("table");
        }
        return(this.props.showData.showMode.toLowerCase());
    }

    getShowType() {
        if (!this.props.showData || !this.props.showData.showType) return("");
        return(this.props.showData.showType);
    }

    _onLoadCallback_Statistics() {
        this._bSysReady = true;
          
        this.update();
        console.log(this.constructor.name, "_onLoadCallback_Statistics", "listeners were set up");
    }

    draw() {

        this._removeListeners();
        this._gdtChart = null;
        this._chartOptions = null;

        var domSR  = document.getElementById(this.getIdDOM_Showroom());
        if (domSR) {
            switch (this.getShowMode()) {
                case "chart":
                        this._show = 
                            // new google.visualization.Histogram(document.getElementById(this.getIdDOM()));
                            // new google.visualization.LineChart(document.getElementById(this.getIdDOM()));
                            // new google.visualization.ScatterChart(document.getElementById(this.getIdDOM()));
                            new google.visualization.ComboChart(domSR);
                    break;
                
                default:
                case "table":
                    this._show = 
                        new google.visualization.Table(domSR);
                    break;
            } 
    
            this._setupListeners();
        }

        switch (this.getShowMode()) {
            case "chart": this.drawChart(this._show); break;
            case "table": this.drawTable(this._show); break;
        }
    }

    drawTable(table) {
        console.log(this.constructor.name, "drawTable", table, this._bSysReady);

        // if (!this._setupChartData()) return(false);

        if (!table || !this._bSysReady || !this.props.showData || !this.props.showData.gdtToVisualize) return(false);

        console.log(this.constructor.name, "drawTable", "go");

        table.draw(
            this.props.showData.gdtToVisualize,
            {
                allowHtml: true, 
                showRowNumber: true, 
                height: "100%" }
        );  

        return(true);
    }

    _onChartReady(e) {
    }

    drawChart(chart) {
        // if (!this._setupChartData()) return(false);
        var chartOptions = {
            series: []  // dummy
        };
        var szError = "";

        if (!chart || !this._bSysReady || !this.props.showData || !this.props.showData.gdtToVisualize) return(false);

        console.log(this.constructor.name, "drawChart");

        /*
        chartOptions = {

            title: 'Verteilung ' + this.props.showData.gdtToVisualize.getColumnLabel(1),
            legend: { position: 'bottom' }, // position: 'none'
            // colors: ['#4285F4'],
            vAxis: { 
                title: 'Beobachtungen'
            },
            // chartArea: { width: 405 },
            hAxis: {
                title: "Performance (Bucket)",
                // ticks: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
                format: '#,###.#%'
            },
            bar: { gap: 0 },
      
            histogram: {
                bucketSize: 0.001,  // 0.01, // prozentweise
                maxNumBuckets: 400,
                // minValue: -1,
                // maxValue: 1
            },

            tooltip: {
                isHtml: true
            },

            pointShape: { 
                type: 'star', 
                sides: 4 
            },
            seriesType: "scatter"
        };
        */

        var dt = undefined;
        var bNegative = false;
        var szShowType = this.getShowType();

        switch(szShowType) {
            case "qq_neg":
            case "qqplot_neg":
            case "qq-plot_neg":
            case "qq_plot_neg":
                bNegative = true;
                console.log(this.constructor.name, "QQ-PLOT NEGATIV", bNegative);

            case "qq":
            case "qqplot":
            case "qq-plot":
            case "qq_plot":
                console.log(this.constructor.name, "QQ-PLOT", this.getShowType(), "negativ", bNegative);

                var pBase = this.props.showData.gdtToVisualize.getColumnProperties(1);
                var szBaseLabel = this.props.showData.gdtToVisualize.getColumnLabel(1);

                // no histogram, direct display
                chartOptions = {

                    title: 'QQ-Plot ' + szBaseLabel  + " vs. ...",
                    legend: { position: 'bottom' }, // position: 'none'
                    // colors: ['#4285F4'],
                    vAxis: { 
                        title: 'Normalized Quantiles (Base, ' + pBase.timeseriesValues + ') of ' 
                                + szBaseLabel,
                        format: '#,##0.0%'
                    },
                    // chartArea: { width: 405 },
                    hAxis: {
                        title: 'Normalized Quantile (Comparison)',
                        format: '#,##0.0%'
                    },

                    explorer: {
                        keepInBounds: true,
                        maxZoomIn: 0.01
                        // axis: 'horizontal'
                    },
        
                    tooltip: {
                        isHtml: true
                    },
        
                    pointShape: { 
                        type: 'circle',     // default: "circle"
                        // sides: 4,        // e.g. for type: "star"
                        pointSize: 7        // default: 7
                    },
                    seriesType: "scatter",
                    series: []
                };


                var view = new google.visualization.DataView(this.props.showData.gdtToVisualize);
                for (var idxCol = 1; idxCol < this.props.showData.gdtToVisualize.getNumberOfColumns()-1; idxCol += this.props.showData.gdtToVisualize.getNumberOfColumns()) {
                    var aoaIdxRow = [];
                    var arrIdxColQQ = FabStd.buildIntegerArrayFromTo(idxCol, this.props.showData.gdtToVisualize.getNumberOfColumns()-1);
                    var arrColTmpData = new Array(arrIdxColQQ.length);

                    // view.setRows(view.getFilteredRows([{column: idxColQQ, test: (value /*, rowId, columnId, datatable */) => { return(value !== null) }}]));
                    
                    aoaIdxRow = 
                        arrIdxColQQ.map((idxColQQ, idx) => {
                                var arrTmp = 
                                    view
                                        .getSortedRows([{
                                            column: idxColQQ, 
                                            desc: bNegative
                                        }]);
                                // eliminate rows with value "null" (empty)
                                arrTmp = arrTmp.filter((idxRow) => this.props.showData.gdtToVisualize.getValue(idxRow, idxColQQ) !== null);
                         
                                console.log(this.constructor.name, "arrTmp", arrTmp, "negative", bNegative);

                                arrColTmpData[idx] = { min: null, max: null };
                                if (arrTmp.length >= 1) {
                                    arrColTmpData[idx].valTop = this.props.showData.gdtToVisualize.getValue(arrTmp[0], idxColQQ);
                                    arrColTmpData[idx].valBot = this.props.showData.gdtToVisualize.getValue(arrTmp[arrTmp.length-1], idxColQQ);
                                    /*
                                    if (bNegative) {
                                        var tmp = arrColTmpData[idx].valTop;
                                        arrColTmpData[idx].valTop = arrColTmpData[idx].valBot;
                                        arrColTmpData[idx].valBot = tmp;
                                    }
                                    */
                                    arrColTmpData[idx].range = arrColTmpData[idx].valBot - arrColTmpData[idx].valTop;
                                }
                                return(arrTmp);        
                            }
                        );
                    
                    // search for row-array with minimum length and save length as well as the idx of the array
                    var cntMin = 0;
                    aoaIdxRow.forEach((arrIdxRow, idx) => {
                        if (idx === 0 || cntMin > arrIdxRow.length) { 
                            cntMin = arrIdxRow.length;
                        }
                    });

                    console.log(this.constructor.name, "QQ", "building destination table", "cntMin", cntMin);
                    // ***********************************************************************************
                    // build destination table
                    // ***********************************************************************************
                    var gdt_new = new google.visualization.DataTable();
                    arrIdxColQQ.forEach((idxColQQ, idx) => {
                        gdt_new.addColumn({
                            label: "Quant_" + this.props.showData.gdtToVisualize.getColumnLabel(idxColQQ),
                            id: "Quant_" + this.props.showData.gdtToVisualize.getColumnLabel(idxColQQ),
                            type: "number",
                            role: idx===0?"domain":"data"
                        });    
                    });
                    gdt_new.addRows(cntMin);
                    
                    var idxColDiag = 
                        gdt_new.addColumn({
                            label: "optimum",
                            id: "optimum",
                            type: "number"
                        });
                    // ***********************************************************************************

                    console.log(this.constructor.name, "QQ", "populating destination table");

                    for (var idxRowLead=0; idxRowLead < cntMin; ++idxRowLead) {
                        var cumProbLead = (idxRowLead+1)/cntMin;
                        var cumProbFlwLw, cumProbFlwHg;
                        var idxRowLw, idxRowHg;
                        var valHg, valLw, valFlw, valFlwInPercent;

                        // go through columns
                        aoaIdxRow.forEach((arrIdxRow, idx) => {
                            var idxRowFlw = idxRowLead;
                            while ((idxRowFlw+1)/arrIdxRow.length <= cumProbLead) {
                                ++idxRowFlw;
                            }
                            idxRowLw = idxRowFlw-1;
                            idxRowHg = idxRowFlw>=arrIdxRow.length?idxRowFlw-1:idxRowFlw;
                            cumProbFlwLw = (idxRowLw+1)/arrIdxRow.length;
                            cumProbFlwHg = (idxRowHg+1)/arrIdxRow.length;

                            valLw = this.props.showData.gdtToVisualize.getValue(
                                arrIdxRow[idxRowLw],
                                arrIdxColQQ[idx]
                            );
                            valHg = this.props.showData.gdtToVisualize.getValue(
                                arrIdxRow[idxRowHg],
                                arrIdxColQQ[idx]
                            );
                            valFlw = valLw + (
                                (cumProbFlwHg <= cumProbFlwLw)?0:(((cumProbLead-cumProbFlwLw)/(cumProbFlwHg-cumProbFlwLw))*(valHg-valLw))
                            );
                            valFlwInPercent = (valFlw - arrColTmpData[idx].valTop) / arrColTmpData[idx].range;

                            // console.log(this.constructor.name, idxRowLead, "of", cntMin, "valFlw", valFlw, "valLw", valLw, "valHg", valHg, "Lw", idxRowLw, "Hg", idxRowHg, arrIdxRow[idxRowLw], arrIdxRow[idxRowHg], arrIdxColQQ[idx], valFlwInPercent);
                            gdt_new.setValue(idxRowLead, idx, valFlwInPercent);
                        });
                    
                        // build a diagonale
                        gdt_new.setValue(idxRowLead, idxColDiag, gdt_new.getValue(idxRowLead, 0));
                    }

                    console.log(this.constructor.name, "idxColDiag", idxColDiag);

                    chartOptions.crosshair = { trigger: "both", orientation: "both" };

                    chartOptions.series = {};
                    chartOptions.series[idxColDiag-1] = {};
                    chartOptions.series[idxColDiag-1].type = "line";
                    
                    // add trendline
                    chartOptions.trendlines = 
                    {
                        // 0 = data-column-Index
                        0: {
                          color: 'purple',
                          lineWidth: 10,
                          opacity: 0.2,
                          // type: 'exponential',
                          // type: 'polynomial',
                          // degree: 3
                        }
                    };
                    
                    console.log(this.constructor.name, "QQ", "setting dt");
                    dt = gdt_new;
                }
                // dt = gdt_new; // this.props.showData.gdtToVisualize;
                break;

            case "histo":
            case "histogram":
            case "histogramm":
                    
                chartOptions = {
                    title: 'Verteilung ' + this.props.showData.gdtToVisualize.getColumnLabel(1),
                    legend: { position: 'bottom' }, 
                    vAxis: { 
                        title: 'Beobachtungen'
                    },
                    // chartArea: { width: 405 },
                    hAxis: {
                        title: "Performance (Bucket)",
                        // ticks: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
                        format: '#,##0.0%'
                    },
                    bar: { gap: 0 },

                    explorer: {
                        keepInBounds: true,
                        maxZoomIn: 0.01,
                        axis: 'horizontal'
                    },

                    tooltip: {
                        isHtml: true
                    },
        
                    pointShape: { 
                        type: 'star', 
                        sides: 4 
                    },
                    seriesType: "scatter",
                    series: {} // dummy
                };

                chartOptions.crosshair = { trigger: "both", orientation: "both" };
            
                // Histogram via SCATTER (to display several series at once)

                var bucketsize = 0.01;            // bucket-cluster-size 0.01 = 1 %
                var halfBucketsize = bucketsize/2;
                var factor = 1/bucketsize;
                var cntDec = Math.floor(Math.log10(factor))-2;
                var format_bucket = '#,###';
                if (cntDec > 0) format_bucket = format_buck + "." + Array(cntDec).join("0"); 
                format_bucket = format_bucket + "%"; 

                console.log(this.constructor.name, "drawChart", "buildHistogram");

                for (var idxCol = 1; idxCol < this.props.showData.gdtToVisualize.getNumberOfColumns(); ++idxCol) {
                    console.log(this.constructor.name, "idxCol", idxCol);
                    var dt_neu = new google.visualization.DataView(this.props.showData.gdtToVisualize);
                    dt_neu.setRows(dt_neu.getFilteredRows([{column: idxCol, minValue: null, test: (value) => { 
                        if (value === null) console.log("JIPP! 0-VALUE!");
                        return (value !== null); 
                    }}]));

                    var dt_neu = 
                        google.visualization.data.group(dt_neu, 
                            [
                                {   // keys
                                    column: idxCol  ,
                                    label: "bucket" , 
                                    type: "number"  ,
                                    role: "domain"  ,
                                    modifier: (val) => { return(Math.floor(factor * (val + halfBucketsize))/factor); }
                                }
                            ], 
                            [   // aggregation
                                {
                                    column: idxCol,
                                    aggregation: // google.visualization.data.count,    // aggregation function
                                        google.visualization.data.count,
                                    type: "number",
                                    role: "data"
                                }
                            ]      // aggregations
                        );       

                    // set "COUNTER"-dataNumberFormat
                    dt_neu.setColumnProperties(1, {
                        dataNumberFormat: CrcStatistics_Main_01.dataNumberFormat_Count,
                        role: "data"
                    });            
                    dt = FabStd.googleDataTableJoinAll(dt, dt_neu);
                }

                console.log(this.constructor.name, "drawChart", "buidPercentages");

                    // we look at the newly build-histogram-table
                    // and switch to "percent"-presentation (for the "count" may differ from series to series)
                    var dt_total = 
                        google.visualization.data.group(this.props.showData.gdtToVisualize, 
                            [
                                {   // keys
                                    column: 0  ,
                                    label: "total" , 
                                    type: "string"  ,
                                    role: "domain"  ,
                                    modifier: (val) => { "total" }
                                }
                            ]
                        , 
                            // aggregation
                            FabStd.buildIntegerArrayFromTo(1, this.props.showData.gdtToVisualize.getNumberOfColumns()-1).map((idxCol) => {
                                    return({
                                        column: idxCol,
                                        aggregation: // google.visualization.data.count,    // aggregation function
                                            google.visualization.data.count,
                                        type: "number",
                                        role: "data"
                                    })
                                }
                            )
                        ); 

                    // console.log(this.constructor.name, "drawChart", "dt_total", google.visualization.dataTableToCsv(dt_total));
                    
                    for (var idxCol = 1; idxCol < dt.getNumberOfColumns(); ++idxCol) {
                        var total = dt_total.getValue(0, idxCol);
                        if (total > 0) {
                            var val;
                            for (var idxRow = 0; idxRow < dt.getNumberOfRows(); ++idxRow) {
                                val = dt.getValue(idxRow, idxCol);
                                if (val !== null) {
                                    dt.setValue(idxRow, idxCol, val/total); // build percentage
                                }
                            }
                        }

                        // set "PERCENT"-dataNumberFormat
                        dt.setColumnProperties(idxCol, {
                            dataNumberFormat: CrcStatistics_Main_01.dataNumberFormat_Pct0,
                            role: "data"
                        });            
                    }
                    chartOptions.vAxis.format = "percent";

                // set dataNumberFormat for the "buckets"
                dt.setColumnProperties(0, { 
                    ... CrcStatistics_Main_01.dataNumberFormat_Pct0,
                    pattern: format_bucket,
                    role: "domain"
                });       
                chartOptions.hAxis.format = format_bucket;
                // console.log(this.constructor.name, "format", format, factor, cntDec, Math.log10(factor), google.visualization.dataTableToCsv(dt));

                chartOptions.series = {};
                for (var idxCol = 1; idxCol < dt.getNumberOfColumns(); ++idxCol) {
                    if (dt.getColumnLabel(idxCol).endsWith("theo")) {
                        chartOptions.series[idxCol-1] = {
                            pointShape: { type: 'triangle' },
                            pointSize: 8,
                            type: "line"
                        };
                    }
                }

                // Histogram
                // add "Tooltip"-Columns
                if (dt.getNumberOfColumns() < 10) {
                    for (var idxCol = dt.getNumberOfColumns()-1; idxCol >= 1; --idxCol) {
                        // console.log(this.constructor.name, "processing column", idxCol);
                        dt.insertColumn(idxCol+1, {type: 'string', role: 'tooltip', p: {'html': true}});
                        for (var idxRow = 0; idxRow < dt.getNumberOfRows(); ++idxRow) {
                            var buck = dt.getValue(idxRow, 0);
                            var freq = dt.getValue(idxRow, idxCol);
                            var szLabel= dt.getColumnLabel(idxCol);
                            var cntTotal = dt_total.getValue(0, idxCol);
                            var buckFrom = buck - halfBucketsize;
                            var buckTo = buck + halfBucketsize;
                            var val;
                            var arrTmp = [];

                            if (!freq) freq = 0;
                            if (!cntTotal) cntTotal = 0;

                            for (var idxRow2=0; idxRow2 < this.props.showData.gdtToVisualize.getNumberOfRows(); ++idxRow2) {
                                val = this.props.showData.gdtToVisualize.getValue(idxRow2, idxCol);
                                if (val >= buckFrom && val <= buckTo) {
                                    arrTmp.push(
                                            moment(this.props.showData.gdtToVisualize.getValue(idxRow2, 0)
                                        ).format("YYYY-MM-DD")
                                    );
                                    if (arrTmp.length > 10) {
                                        arrTmp.push("...");
                                        break;  // Exit for-Loop
                                    }
                                }
                            }

                            var szHtml = 
                                "<div style='padding: 5px, width: 20rem'>" 
                                + "<b>" + szLabel + "</b>"
                                + "<table>"
                                    + "<tr>" 
                                        + "<td style='padding: 5px'>Bucket</td>"
                                        + "<td style='padding: 5px'>" 
                                            + "["
                                            + (buck - halfBucketsize).toLocaleString({}, {style: "percent", minimumFractionDigits: cntDec+1, maximumFractionDigits: cntDec+1}) 
                                            + " ; "
                                            + (buck + halfBucketsize).toLocaleString({}, {style: "percent", minimumFractionDigits: cntDec+1, maximumFractionDigits: cntDec+1}) 
                                            + "["
                                        + "</td>"
                                    + "</tr>"
                                    + "<tr>"
                                        + "<td style='padding: 5px'>Frequency</td>" 
                                        + "<td style='padding: 5px'>" 
                                            + freq.toLocaleString({}, {style: "percent", minimumFractionDigits: 1, maximumFractionDigits: 1})
                                            + "<br>" 
                                            + "(" + (cntTotal*freq) + "/" + cntTotal + ")" 
                                        + "</td>" 
                                    + "</tr>"
                                + "</table>"
                                + "Dates: " 
                                + arrTmp.join(", ")
                                + "</div>";
                            
                            dt.setValue(idxRow, idxCol+1, szHtml);
                        }
                    }
                }
                break;

            case "cum":
            case "kum":
            case "kumuliert":
            case "cumulated":
                this.libGdtBasis.gdtCumulate(this.props.showData.gdtToVisualize);                

            default: 
                var bSorted = !!szShowType.match(".*sort");
                // console.log(this.constructor.name, "shShowTypeMatch", szShowType.match(".*sort"));

                // no histogram, direct display
                chartOptions = {

                    title: 'Verteilung ' + this.props.showData.gdtToVisualize.getColumnLabel(1),
                    legend: { position: 'bottom' }, // position: 'none'
                    // colors: ['#4285F4'],
                    vAxis: { 
                        title: 'Performance',
                        format: '#,##0.0%'
                    },
                    // chartArea: { width: 405 },
                    hAxis: {
                        title: "Date",
                        // ticks: [-1, -0.75, -0.5, -0.25, 0, 0.25, 0.5, 0.75, 1],
                        format: 'yyyy-MM-dd'
                    },

                    explorer: {
                        keepInBounds: true,
                        maxZoomIn: 0.01,
                        axis: 'horizontal'
                    },
        
                    tooltip: {
                        isHtml: true
                    },
        
                    pointShape: { 
                        type: 'circle',     // default: "circle"
                        // sides: 4,        // e.g. for type: "star"
                        pointSize: 7        // default: 7
                    },
                    seriesType: "scatter",
                    series: {},

                    crosshair: { 
                        trigger: "both", 
                        orientation: "both" 
                    }
                };

                if (bSorted) {
                    var gdt_old = this.props.showData.gdtToVisualize;
                    var gdt_new = new google.visualization.DataTable();

                    // switch first column to "number"
                    gdt_new.addColumn({ label: "sortorder", id: "sortorder", role: "domain", type: "number"});
                    gdt_new.addRows(gdt_old.getNumberOfRows());

                    // console.log(this.constructor.name, "drawChart", "gdt_new.rows", gdt_new.getNumberOfRows(), gdt_new.getNumberOfColumns());
                    for (var idxRow=0; idxRow < gdt_new.getNumberOfRows(); ++idxRow) {
                        gdt_new.setValue(idxRow, 0, idxRow+1);  // sort
                    }

                    // console.log(this.constructor.name, "drawChart", "(2)");
                    var cntCol = gdt_old.getNumberOfColumns();
                    var view = new google.visualization.DataView(gdt_old);
                    for (var idxCol=1; idxCol < cntCol; ++idxCol) {
                        var arrIdxRows = [];
                        var arrTmp = [];
                        var idxColDst = gdt_new.addColumn({
                                label: gdt_old.getColumnLabel(idxCol),
                                id: gdt_old.getColumnId(idxCol),
                                role: gdt_old.getColumnRole(idxCol),
                                type: gdt_old.getColumnType(idxCol),
                                pattern: gdt_old.getColumnPattern(idxCol)
                            }
                        );
                        gdt_new.setColumnProperties(idxColDst, gdt_old.getColumnProperties(idxCol));

                        arrIdxRows = view.getSortedRows({column: idxCol, desc: false});
                        arrIdxRows.forEach((idxRow, idx) => {
                            gdt_new.setValue(idx, idxColDst, gdt_old.getValue(idxRow, idxCol));
                        });
                    }

                    chartOptions.pointShape = {};
                    chartOptions.seriesType = "line";
                    chartOptions.hAxis = {
                        title: "SortNr",
                        format: "#,##0"
                    }

                    dt = gdt_new;
                } else {
                    dt = this.props.showData.gdtToVisualize.clone();
                }
                break;
        }

        if (dt && szError === "") {
            // precaution ... first column MUST bei "domain"
            dt.setColumnProperty(0, "role", "domain");

            // console.log(this.constructor.name, "drawChart", "dt", google.visualization.dataTableToCsv(dt));

            if (this._listenerChartReady) google.visualization.events.removeListener(this._show, "ready", this._listenerChartReady);
            this._listenerChartReady =
                google.visualization.events.addListener(chart, "ready", (e) => this._onChartReady(e));
            
            this._gdtChart = dt;
            this._chartOptions = chartOptions;
    
            this._drawPreparedChart();
        } else {

        }
        
        return(true);
    }

    _drawPreparedChart() {
        this._show.draw(
            this._gdtChart, // this.props.showData.gdtToVisualize, 
            this._chartOptions
        );
    }

    
    componentWillUnmount() {
        super.componentWillUnmount();

    }

    _isBusy() {
        return(!this._bSysReady);
    }

    render() {    

        var renderResult;

        // this._jobCode_parse();

        // console.log(this.constructor.name, "render()", "style2", this.props.style2);

        renderResult =
            <div id={this.getIdDOM()} 
                style={{
                    width: "inherit",
                    minWidth: "50%",
                    height: "100%", 
                    padding: "1rem"
                    }}>
                { 
                    this._isBusy()?
                        <span><CrcWait_01 style={{ margin: "1rem", width: "2rem", height: "2rem" }}/> ... please wait</span>
                    :   [] 
                }
                <div id={this.getIdDOM_Showroom()} 
                    style={{ 
                        width: "100%", 
                        height: "100%", 
                        opactiy: this._isBusy()?"100%":"0%"
                    }}/>
            </div>;

      return(renderResult);
    }  
}

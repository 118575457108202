import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

export default class dataPool {
    constructor(parent, szDataPoolId) {
        this.m_parent = parent;
        this.m_szId = szDataPoolId;
        this.m_smapData = new StdMap();
        this.m_smapDataShadow = new StdMap();
    }
    
    getParent() {
        return(this.m_parent);
    }
    
    hasId() {
        return(!!this.m_szId);
    }

    setId(szDataPoolId) {
        // can only be set, if not already defined!
        if (!this.hasId()) {
            this.m_szId = szDataPoolId;
        }        
    }    
    
    getId() {
        return(this.m_szId);
    }
    
    clear() {
        this.m_smapData.clear();
        this.m_smapDataShadow.clear();
    }
    
    isDataEmpty() {
        return(this.m_smapData.length <= 0);
    }

    getFirstDataIdEntry() {
        var result = this.m_smapData.keys().next();
        if (result.done) return(undefined);
        return(result.value);
    }
    
    getDataIdEntryIterator() {
        return(this.m_smapData.keys());
    }
    
    getDataIdEntryIterator_Sorted() {
        return(this.m_smapData.keys_sorted().values());
    }
    
    getDataShadowIdEntryIterator() {
        return(this.m_smapDataShadow.keys());
    }        

    existsDataEntry(szIdEntry) {
        return(!!this.getDataEntry(szIdEntry, false));
    }
    
    getDataEntry(szIdEntry, bAddIfEntryNotExisting) {        
        return(this.privGetMapEntry(this.m_smapData, szIdEntry, bAddIfEntryNotExisting));
    }

    deleteDataEntry(szIdEntry) {
        this.m_smapData.delete(szIdEntry)
        this.m_smapDataShadow.delete(szIdEntry);
    }

    setDataEntry(szIdEntry, entry) {
        /*
        var dum = this.initializedEntry(szIdEntry);
        // we take care, that the "base structure" of the is existing ...
        // so we just extend an initialized Entry
        Object.assign(dum, ...entry);
        dum.id = szIdEntry;
        smapStdMap.set(szIdEntry, dum);
        */
        this.m_smapData.set(szIdEntry, entry)
        return(entry);
    }
    
    getDataShadowEntry(szIdEntry, bAddIfEntryNotExisting) {        
        return(this.privGetMapEntry(this.m_smapDataShadow, szIdEntry, bAddIfEntryNotExisting));
    }    

    initializedEntry(szIdEntry) {
        var objEntry = {
            id: !szIdEntry?"":szIdEntry
        };
        return(objEntry);        
    }
    
    privGetMapEntry(smapStdMap, szIdEntry, bAddIfEntryNotExisting) {
        var smapEntry = 
            smapStdMap.get( 
                szIdEntry, 
                bAddIfEntryNotExisting, 
                this.initializedEntry
            );
        return(smapEntry);
    }
    
    
    getDataMap() {        
        return(this.m_smapData);
    }
    
    getDataShadowMap() {        
        return(this.m_smapDataShadow);
    }
    
    /*
    syncWithSubDataPool_Begin() {
        this.m_bSyncing = true;
        this.m_arrSyncList = [];
    }

    syncWithSubDataPool_End() {
        this.m_bSyncing = false; 
    }
    */
    search(szSearchArgument) {
        var result;
        var iter = this.m_smapData.keys();
        var szKey;
        var smSub;
        var objDataset;
        var newRE = new RegExp("(" + szSearchArgument + ")", "g");
        var smResult = new StdMap();
        while (!(result = iter.next()).done) {
            szKey = result.value;
            smSub = this.m_smapData.get(szKey);
            if (!!smSub) {
                objDataset = smSub["dataset"];
                if (!!objDataset) {
                    var nGef = 0;
                    var tmpGef;

                    Object.getOwnPropertyNames(objDataset).forEach((prop) => {
                        var tmp = objDataset[prop];
                        if (isString(tmp)) {
                            tmpGef = tmp.replace(newRE, (match, p1, p2, offset) => {
                                ++nGef;
                                return("<MARK>" + match + "</MARK>");
                            });
                        }
                    });

                    if (nGef>0) {
                        // console.log("SDA", tmpGef, nGef);
                        smResult.set(szKey, { count: nGef });
                    }
                }
            }
        }
        smResult.log("searchResults");
    }

    logContent() {
        var iter = this.m_smapData.keys();
        var result = iter.next();
        var szKey;
        
        iter = this.m_smapData.keys();
        result = iter.next();
        
        console.log(this.constructor.name, "Entries");
        while (!result.done) {
            szKey = result.value;
            console.log(this.constructor.name, "Key", szKey, 
                "Content", 
                this.m_smapData.get(szKey),
                "ShadowContent",
                this.m_smapDataShadow.get(szKey));
            result = iter.next();  

            var smSub = this.m_smapData.get(szKey);
            if (!!smSub) {
                // smSub.log(this.constructor.name + " SUBMAP");
                
                var objContent = smSub["dataset"];
                if (!!objContent) {
                    Object.getOwnPropertyNames(objContent).forEach((prop) => {
                        console.log(this.constructor.name, "prop", prop, "val", objContent[prop]);
                    });
                }
            }
        }
    }

    syncWithSubDataPool(SubDataPool, szSubDataPoolId_Override = undefined) {
        // console.log(this.constructor.name, "syncWithSubDataPool", SubDataPool);        
        var szSubDataPoolId;
        
        // if SubDataPool was given !
        if (!!SubDataPool) {
            szSubDataPoolId = SubDataPool.getId();  // the "real" id is primary
  
        } else {
            // if no datapool was given, we use the "OVERRIDE"-Id as the real one ...
            szSubDataPoolId = szSubDataPoolId_Override;
        }
 
        if (!szSubDataPoolId) {
            console.log(this.constructor.name, "syncWithSubDataPool", "Error! No SubDataPoolId given!");
            return(false);
        }

        // first run - we follow the data in the "PrivateDataPool"
        if (!!SubDataPool) {
            var iter = SubDataPool.getDataIdEntryIterator();
            var subMap = SubDataPool.getDataMap();
            var result = iter.next();            
            var szKey, szId, szPoolDatasetId;
            var entry, entryShadow;

            while (!result.done) {
                szKey = result.value;
                entry = subMap.get(szKey);

                szId = entry["id"];

                if (szSubDataPoolId_Override === "") {
                    szPoolDatasetId = szId;
                } else {
                    if (!!szSubDataPoolId_Override) {
                        szPoolDatasetId = szId + "@" + szSubDataPoolId_Override;     
                    } else {
                        szPoolDatasetId = szId + "@" + szSubDataPoolId;
                    }               
                }
                
                if (this.m_smapDataShadow.has(szPoolDatasetId)) {
                    entryShadow = this.m_smapDataShadow.get(szPoolDatasetId);
                } else {
                    entryShadow = {};
                    entryShadow["id"] = szPoolDatasetId;
                    entryShadow["id_sub"] = szId;        // original id, used in SubDataPool
                    entryShadow["id_dp_sub"] = szSubDataPoolId;
                    this.m_smapDataShadow.set(szPoolDatasetId, entryShadow);
                }                

                this.m_smapData.set(szPoolDatasetId, entry);

                result = iter.next();
            }
        }

        // second run - we follow the data in this DataPool (main)        
        this.m_smapDataShadow.filter((entryShadow) => { return(entryShadow["id_dp_sub"] === szSubDataPoolId) }).forEach(
            function(entryShadow) {                 
                var szId_Sub = entryShadow["id_sub"];
                var szId = entryShadow["id"];

                var entry = this.m_smapData.get(szId);

                if (entry["state"] !== "deleted") {                    
                    if (!SubDataPool || !SubDataPool.getDataMap().get(szId_Sub)) {
                        entry["state"] = "deleted";
                        entry["pending_onRun"] = "y";
                        entry["pending_broadcast"] = "y";
                    }
                }
            }.bind(this));
        return(true);
    }
}

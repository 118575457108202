import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import qryProcessor_KnownEmails from "./fabbi_qry_processor_knownemails.js";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
import CrcModalWait_01 from "./fabbi_crc_modalwait_01.jsx";
import qryProcessor_UserInvitations from "./fabbi_qry_processor_userinvitations.js";
import TextField from '@material-ui/core/TextField';
import { Timestamp } from "firebase/firestore";

export default class CrcSendInvitation_ModalInput_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
        this.state.eMailValid = false;
        this.state.eMailCheck = "please enter the email-adress of the user you wish to invite!";
    
        this.state.szGreeting = "You're wanted!";

        this._chainCheckEmail = new PromiseChain({ parent: this, wfCtrl: this.getWfCtrl() });
        this._chainSendInvitation = new PromiseChain({ parent: this, wfCtrl: this.getWfCtrl() });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getTxt_Greeting() {
        return(this.state.szGreeting);
    }

    getId_Family() {
        return(this.props.id_family);
    }

    getId_UserInvited() {
        return(this.state.id_userInvited);
    }

    getName_User() {
        return(this.getWfCtrl().m_bundleUsers.getSingleDatasetField(this.getWfCtrl().getMainUserId(), "name"));
    }

    getName_Family() {
        var dataset = this.getWfCtrl().m_bundleFamilies.getSingleDataset(this.props.id_family);
        return(dataset?dataset.name:"");
    }

    render() {      

        var id_dom_input_email;
        id_dom_input_email = this.getIdDOM() + "_input_email";

        console.log(this.constructor.name, "render", "eMailValid", this.state.eMailValid);

        return(
            <form 
                id={this.getIdDOM()} 
                style={{ 
                    border: "black solid 1px", 
                    margin: "0.5rem", 
                    padding: "0.5rem", 
                    borderRadius: "5px",
                    backgroundColor: "inherit" }}>
                <div style={{ 
                    display: "inline-block", 
                    backgroundColor: "inherit", 
                    transform: "translate(-1rem, -1.33rem) scale(0.75)"
                }}>
                    <b>Send an Invitation</b>         
                </div> 

                <span id="hook_modal_wait_user_invitation"></span><div className={"row"}>
                    Send an inviation to join '{ this.getName_Family() }' to 

                    <TextField   
                        inputProps={{ style: { height: "1.5rem", margin: "0.8rem 0.5rem" } }}
                        label={"eMail of recipient"} 
                                        onChange={(event) => {
                                            console.log(this.constructor.name, "TEXTFIELD", "onChange", event.target.value);
                                        }} variant="outlined" />
        
                    <CrcInput_01 
                            id_dom={id_dom_input_email} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="eMail of recipient"
                            valueIsValid={this.state.eMailValid}
                            onInput={(e) => { this.onInput_eMail(e.target.value); }}
                            defaultValue={""}/>
                    <p>{this.state.eMailCheck}</p>
                </div>
                <div className="col s12">
                    <a className={"btn waves-effect waves-light send modal-close " + (this.state.eMailValid?"enabled":"disabled")}
                        onClick={(e) => { this.onSendButton(); } }>
                        Send
                    </a>  
                </div>                
            </form>
        );
    }

    onInput_eMail(email) {
        // to specify:
        // element, check-function to use, must return "OK" if next check shall be done ...
        // element, check-function to use 
        // ..
        // button to disable/enable

        // console.log("TYPE EMAIL - KEY UP", e, PromiseChain); 

        this._chainCheckEmail.abort();
        this._chainCheckEmail.set_onAllSettled(function() {                    
            var { parent, wfCtrl } = this.getCustomChainData();

            parent.setState({ 
                eMailCheck: "...checking ...", 
                btnSend: "disabled"
            });
            // g_wfCtrl.domeSet(elemInfo, "...checking...", "innerHTML");
            // g_wfCtrl.domeSet(btnSend, "disabled", "class_change");

            if (FabStd.validateEmail(email)) {                            
                // wfCtrl.domeSet(elemInfo, "eMail formally OK", "innerHTML");
                this.getCustomChainData().parent.setState({ 
                    eMailCheck: "eMail formally OK" 
                });

                this.pushPromise(function(resolve, reject) {
                    var { parent, wfCtrl } = this.getCustomChainData();
                    console.log(this.constructor.name, "wfCtrl", wfCtrl, "parent", parent);                    
                    
                    var test = new qryProcessor_KnownEmails({
                        wfCtrl,
                        szTriggerId: wfCtrl.getMainUserId(),
                        szUserId:  wfCtrl.getMainUserId()
                    });

                    test.readDoc(email).then(function(result) {
                        var { parent, wfCtrl } = this.getCustomChainData();

                        var dataSnapshot = result.dataSnapshot;
                        if (dataSnapshot && dataSnapshot.exists) {
                            // eMail exists ... we take a look at the user-id behind it
                            var szInvitedUserId = dataSnapshot.data().modified_by?dataSnapshot.data().modified_by:dataSnapshot.data().added_by;
                            console.log("eMail-found! an invitation can be sent! The User's id is: ", szInvitedUserId);
                            var qryP_Mem = wfCtrl.m_bundleFamilies.getChildQP_Members(parent.getId_Family());
                            if (!!qryP_Mem) {
                                if (qryP_Mem.getMembers().includes(szInvitedUserId)) {
                                    // Member found!
                                    parent.setState({eMailCheck: "eMail/user already member" });
                                } else {
                                    // Member not found!
                                    parent.setState(
                                        {
                                            id_userInvited: szInvitedUserId,
                                            eMailCheck: "eMail OK",
                                            eMailValid: true 
                                        });
                                }
                            }
                        } else {
                            // eMail unknown!                                        
                            // g_wfCtrl.domeSet(elemInfo, "eMail not existing", "innerHTML");
                            parent.setState(
                                {
                                    eMailCheck: "eMail doesn't exist",
                                    btnSend: "disabled" 
                                });
                        }
                    }.bind(this)).finally(() => { 
                        resolve( { pd: this, rc: "OK" } ); 
                    });
                });                      
            } else {                          
                parent.setState({eMailCheck: "invalid eMail" });
                // resolve( { pd: this, rc: "OK" });
            }  
        });                    
    }

    onSendButton() {
        console.log(this.constructor.name, "sendButton");
        // if another send-operation is yet underway ... don't react at all!
        if (!this._chainSendInvitation.reset()) {
            return;
        }

        console.log(this.constructor.name, "sendButton (2)");

        this._chainSendInvitation.set_onAllSettled(function() {
            console.log(this.constructor.name, "_chainSendInvitation settled!");

            var  {parent, wfCtrl } = this.getCustomChainData();
            CrcModal_01.closeModal(wfCtrl, "modal_wait_user_invitation");
        });

        this._chainSendInvitation.pushPromise(function(resolve, reject) {
            var {parent, wfCtrl} = this.getCustomChainData();
            console.log(this.constructor.name, "_chainSendInvitation started!");

            CrcModal_01.openAsModal(wfCtrl,
                { 
                    style: { 
                        ...CrcModal_01.propStyle_FullScreen, 
                        opacity: "50%"
                    } 
                },
                CrcModalWait_01, 
                {
                    id_dom: "modal_wait_user_invitation"
                } 
            );

            // setTimeout(() => { resolve({rc: "ERR"}); }, 3000);
            // return;

            console.log("TRYING TO WRITE INVITATION"); 
            var qpTmp = new qryProcessor_UserInvitations({ 
                            wfCtrl, 
                            szTriggerId: parent.getId_UserInvited(), 
                            szUserId: parent.getId_UserInvited()
                        });

            qpTmp.setDoc(parent.getId_Family(), {
                name: parent.getName_User(), 
                name_family: parent.getName_Family(), 
                greeting: parent.getTxt_Greeting(), 
                modified_by: wfCtrl.getMainUserId(),
                added_by: wfCtrl.getMainUserId(),
                ts_modified: Timestamp.now(),
                ts_added: Timestamp.now()                                                                                        
            }).then((result) => {
                console.log("INVITATION", "result", result); 
                resolve(result); 
            });
        }).then((result) => {
            if (result.rc === "OK") {
                // 
                console.log("SECOND OK!");
                this._chainSendInvitation.pushPromise(function(resolve, reject) {
                    console.log("TRYING TO WRITE SEND INVITATION"); 
                    var qryP_MemInv = wfCtrl.m_bundleFamilies.getChildQP_MembersInvited(parent.getId_Family());
                    if (!qryP_MemInv) {
                        console.log("ERROR QP NOT FOUND");
                        resolve({rc: "ERR"});
                    } else {
                        qryP_MemInv.setDoc(szInvitedUserId, {
                            name: parent.getName_User(), 
                            name_family: parent.getName_Family(),
                            modified_by: parent.getWfCtrl().getMainUserId(),
                            added_by: parent.getWfCtrl().getMainUserId(),
                            ts_modified: Timestamp.now(),
                            ts_added: Timestamp.now()                                                                                        
                        }).then((result) => {
                            console.log("INVITATION-PART 2", "result", result); 
                            resolve(result); 
                        });        
                    }
                });
            }
        });

    }
}
import React from 'react';
import Crc_SC_Base from "./fabbi_crc_sc_base.jsx";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import DatePicker from '@material-ui/lab/DatePicker';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// or @material-ui/lab/Adapter{DateFns,DayJS,Luxon,Moment} or any valid date-io adapter
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import moment from 'moment';
import deLocale from 'moment/locale/de';    // ATTENTION! THIS IS ESSENTIAL ... 

export default class Crc_SC_DatePicker_01 extends Crc_SC_Base {
    constructor(props) {
        super(props);

        this._refInput = React.createRef();

        this.state.open = !!props.open;
        this.state.datMax = props.datMax;
        this.state.datMin = props.datMin;
        this.state.datValue = props.datValue;

        console.log(this.constructor.name, "init", "this", this, "props", this.props);
    }

    /** @override */
    getResult() {
        var result = {
            datValue: this.state.datValue
        }
        return(result);
    }

    /** @override */
    componentDidMount() {
        super.componentDidMount();
    }

    /** @override */
    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    render() {
        var result = 
            <LocalizationProvider dateAdapter={AdapterMoment} locale={moment.locale("de")}>
                <DatePicker
                    id={this.getIdDOM()} 
                    label={ this.getPickerLabel() }
                    mask="__.__.____"
                    // toolbarTitle="toolbar"
                    open={this.state.open}
                    value={this.state.datValue}
                    maxDate={this.state.datMax}
                    minDate={this.state.datMin}
                    onChange={(newValue) => { this.setState({ datValue: newValue }); }}
                    renderInput={(params) => this.renderInput_03(params) }
                    />
            </LocalizationProvider>
            ;
        return(result);
    }

    getPickerLabel() {
        return(this.props.label?this.props.label:"Datum");
    }

    renderInput_03(params) {
        console.log("renderInput_03", params);
        return(
            <span>
            <TextField
                autoFocus={this.props.autoFocus}
                
                // style={{height: "1em"}} 
                {...params } 

                InputProps={{
                    ...params.InputProps,
                    helperText: null,

                    // startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton onClick={() => this.onSet()}><CheckCircleOutlineIcon aria-label="ok"/></IconButton>
                            <IconButton onClick={() => { this.setState({open: !this.state.open}); console.log("ONCLICK"); }}><TodayIcon></TodayIcon></IconButton>
                        </InputAdornment>
                }}

                inputProps={{
                    ...params.inputProps,
                    placeholder: "TT.MM.JJJJ"
                }}

                onFocus={(e) => { 
                    console.log(this.constructor.name, "onFocus()", e, "params", params); 
                } }

                onKeyDown={(e) => {
                    console.log(this.constructor.name, "onKeyDown", "renderInput_03()", e.key); 
                    if (e.key === "Escape") {
                        console.log(this.constructor.name, "simulateClickAway", "reason", "ESCAPE");
                        this.onCancel({ reason: "Escape" });                    
                    }

                    if (e.key === "Tab") {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }

                    if (e.key === "Enter") {
                        this.onSet();
                        return;
                    }
                }} 
                variant="outlined" 
                helperText={null}
                />
            </span>
        );
    }
}
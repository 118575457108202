import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import workflowControl from "./fabbi_workflowcontrol.js";
import { Timestamp } from "firebase/firestore";

export default class qryProcessor_UserIndividualities extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);                
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;

        this.m_szProcessed_SelectedId_Families = "";
        this.m_szProcessed_SelectedId_Missions = "";
        
        // console.log(this.getClassName(), "constructor", this.m_szUserId);                      
    }

    getClassName() {
        return("qryProcessor_UserIndividualities");    // to FIX the classname!
    }
    
    getMainUserId() {        
        return this.m_szUserId;
    }
    
    getSelectedId_gdtTsScript() {        
        var szSelected = this.getSingleDatasetField(this.getMainUserId(), "selected_id_gdtTsScript");
        return(szSelected); 
    }
    
    setSelectedId_gdtTsScript(id_gdtTsScript) {                
        this.setSelectedId("selected_id_gdtTsScript", id_gdtTsScript, this.getMainUserId());
    }

    getSelectedId_Families() {        
        var szSelected = this.getSingleDatasetField(this.getMainUserId(), "selected_id_families");
        // if (!this.getWfCtrl().m_bundleFamilies.getSingleDataset(szSelected)) {            
        //    szSelected = "";    // delvier an EMPTY id, if the Family CAN'T be the selected one ...
        // }
        return(szSelected); 
    }
    
    setSelectedId_Families(szFamilyId) {                
        this.setSelectedId("selected_id_families", szFamilyId, this.getMainUserId());
    }
    
    getLastChecked_Messages(szFamilyId) {
        return(this.getSingleDatasetField(szFamilyId, "ts_lc_messages", null));
    }
    
    refreshLastChecked_Messages(szFamilyId) {          
        this.refreshLastChecked("ts_lc_messages", szFamilyId);
    }
    
    refreshLastChecked(szDatafieldNameForLastChecked, szParentId) {          
        if (szParentId === undefined) {
            return; // cancel
        }                
        // console.log(this.getClassName(), "setSelectedId", szDatafieldNameForSelectedId, szSelectedId, "from", szParentId); 
        
        var obj = {
            [szDatafieldNameForLastChecked]: Timestamp.now()
        };
        // Object.defineProperty(obj, szDatafieldNameForLastChecked, { value: Timestamp.now(), enumerable: true, writable: true });
        
        this.updateOrSetDoc(szParentId, obj);
    }
    
    setSelectedId(szDatafieldNameForSelectedId, szSelectedId, szParentId) {          
        if (szParentId === undefined) {
            szParentId = this.getMainUserId();
        }                
        // console.log(this.getClassName(), "setSelectedId", szDatafieldNameForSelectedId, szSelectedId, "from", szParentId); 
        
        var obj = {
            [szDatafieldNameForSelectedId]: szSelectedId
        };
        // Object.defineProperty(obj, szDatafieldNameForSelectedId, { value: szSelectedId, enumerable: true, writable: true });
        
        this.updateOrSetDoc(szParentId, obj);
    }    
        
    getSelectedId_Missions() {        
        return(this.getSingleDatasetField(this.getSelectedId_Families(), "selected_id_missions")); 
    }
    
    setSelectedId_Missions(szMissionId) {                
        this.setSelectedId("selected_id_missions", szMissionId, this.getSelectedId_Families());
    }        
    
    getCollectionPath() {
        return('users/' + this.getMainUserId() + '/individualities');
    }
    
    onRunCompleted() {
        // console.log(this.getClassName(), "onRunCompleted");

        if (this.getSelectedId_Families() !== this.m_szProcessed_SelectedId_Families) {            
            var szOldId = this.m_szProcessed_SelectedId_Families;
            var szNewId = this.getSelectedId_Families();
            
            this.m_szProcessed_SelectedId_Families = szNewId;
                                    
            this.m_wfCtrl.enqueueWorkflowControlEvent(this, workflowControl.EVENT, () => {                                
                this.getWfCtrl().onChanged_SelectedId_Families(szOldId, szNewId); 
            });            
            // now we have to inform workflowControl about this, so it can decide what to do ...
        }
        
        if (this.getSelectedId_Missions() !== this.m_szProcessed_SelectedId_Missions) {            
            var szOldId = this.m_szProcessed_SelectedId_Missions;
            var szNewId = this.getSelectedId_Missions();
            
            this.m_szProcessed_SelectedId_Missions = szNewId;
                        
            this.m_wfCtrl.enqueueWorkflowControlEvent(this, workflowControl.EVENT, () => {                                
                this.getWfCtrl().onChanged_SelectedId_Missions(szOldId, szNewId); 
            });            
            // now we have to inform workflowControl about this, so it can decide what to do ...
        }

        return(super.onRunCompleted());
    }
}

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import qryProcessor_Members from './fabbi_qry_processor_members.js';
import HtmlProcessor_Members from './fabbi_htmlprocessor_members.js';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import { Timestamp } from "firebase/firestore";

export default class CrcFamilies_ModalInput_01 extends CrcHtmlProcessorEntry {
    constructor(props) {
        super(props);

        this._idFamily = this.getHtmlProcessor().getActiveIdEntry();

        this._qryP_Mem = new qryProcessor_Members({ 
            wfCtrl: this.getWfCtrl(),
            szFamilyId: this._idFamily,
            szTriggerId: this._idFamily
        });

        // set a signal "when no html-data-can be set! Selfdestruction!"
        this._htmlP = new HtmlProcessor_Members({
            wfCtrl: this.getWfCtrl(), 
            leadingDataPool: this._qryP_Mem.getPrivateDataPool() 
        });           
        this._qryP_Mem.setHtmlProcessor(this._htmlP);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._qryP_Mem.destroy();
        this._qryP_Mem = undefined;

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().derequestSpecialDataAction(
                this.getIdDOM()    // id_sda
                );
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this._qryP_Mem.processQuery();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().requestSpecialDataAction(
                this.getIdDOM(),    // id_sda
                this.getIdEntry(),  // id_entry
                () => this.update()  // force a re-rendering
            );
        }
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
        var dataMsgstat = datasetExtra["data-msgstat"];
        var dataLastMsg = datasetExtra["data-last_msg"];
        var dataAddedBy = datasetExtra["data-added_by"];
        var szSenderImgSrc = !!dataAddedBy?dataAddedBy.image_src:"";
        var szSenderName = !!dataAddedBy?dataAddedBy.name:"";

        if (!dataMsgstat) dataMsgstat = {};
        if (!dataLastMsg) dataLastMsg = {};

        var szLstMsgAdded = FabStd.convertTimestampToLocaleDateTimeString(dataLastMsg.ts_added, "");

        if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
            this.state.new = true; // direct access to "state" !! important in this case!
        } else {
            this.state.new = false;
        }

        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = function(e, id_family) {
                if (this.getWfCtrl().m_listenerUserIndividualities.getSelectedId_Families() === id_family) {
                    // g_wfCtrl.editFamily(szTmpId);
                } else {
                    this.getWfCtrl().setSelectedId_Families(id_family);
                    this.getWfCtrl().refreshLastChecked_Messages(id_family);
                }
            }.bind(this);

        // var bActive = (this.getWfCtrl().getSelectedId_Families() === this.getIdEntry());

        var id_dom_carousel = this.getIdDOM() + "_cl";
        var id_dom_input_name = this.getIdDOM() + "_input_name";

        // console.log(this.constructor.name, "RENDERMI",
        //    this.getWfCtrl().m_bundleFamilies,
        //    this.getIdEntry(),
        //    this.getWfCtrl().m_bundleFamilies.getBundleQP(this.getIdEntry(), "trigger"));

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>Family-Profile</span>
                        <span className={"fab-dragdestination"}>
                            <i className={"material-icons trash-bin"} id="trash-bin">delete</i>
                        </span>            
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <CrcInput_01 
                            id_dom={id_dom_input_name} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Familyname" 
                            defaultValue={dataset.name}/>

                        <div className={"col s12"}>         
                            <label className={"btn waves-effect waves-light fabbi-file-upload"}>
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    capture="user" 
                                    id="families-new-image" 
                                    onDrop={(event) => {
                                        console.log(this.constructor.name, "onDrop", event);
                                    }}
                                    onChange={
                                        (inp) => {
                                            var car = this.getWfCtrl().getCRC(id_dom_carousel); 
                                            g_wfCtrl.processNewImage_ImgSrc2(
                                                inp.target.files, 
                                                car.getCsvImgSrc.bind(car),
                                                () => {},
                                                this.getWfCtrl().m_bundleFamilies.getBundleQP(this.getIdEntry(), "trigger"),
                                                this.getIdEntry(), 
                                                "image_src")
                                        }
                                    }/>
                                <span
                                    onDragLeave={(ev) => { console.log(this.constructor.name, "onDragLeave", ev); } }
                                    onDragOver={(ev) => { 
                                        ev.preventDefault(); console.log(this.constructor.name, "onDragOver", ev); 
                                    }}  
                                    onDrop={(ev) => {
                                    // Prevent default behavior (Prevent file from being opened)
                                    ev.preventDefault();
                                    console.log(this.constructor.name, "onDrop", ev.dataTransfer.items, ev.dataTransfer.files);
                                }}>Image upload</span>
                            </label>&nbsp;
                        </div>
                        
                        <div id="modal1_sub"/>
                    </div>

                    <div className="{row}">
                        <CrcCarousel_01_Csv 
                            wfCtrl={this.getWfCtrl()}
                            id_dom={id_dom_carousel} 
                            csv={dataset.image_src} 
                            style={{ border: "ridge", width: "100%", maxWidth: "300px", height: "15vh"}}/>
                    </div>

                    <div className={"row"}>                   
                        <div style={
                            {width: "100%", maxHeight: "400px", overflowY: "auto" }} 
                            id={this._htmlP.getId_Div_SuperSection()}>    
                        </div>
                        
                        <div className={"modal-footer"}>
                            <a className={"btn waves-effect waves-light send"} id="families-btn-send-invitation" onClick={
                                    () => { this.getWfCtrl().dlgSendInvitation(); }}>Send Invititation</a>
    
                            <a className={"btn waves-effect waves-light"} id="families-btn-leave" onClick={
                                () => { this.getWfCtrl().leaveFamily(this.getIdEntry()); }}>Leave Family</a>

                            <a className={"btn waves-effect waves-light send modal-close"} id="families-btn-save" 
                                onClick={
                                    (event) => { 
                                        console.log(this.constructor.name, id_dom_input_name, document.getElementById(id_dom_input_name));
                                        var obj = {};
                                        FabStd.addProp(obj, "name", this.getWfCtrl().getCRC(id_dom_input_name).getDbValue());   
                                        FabStd.addProp(obj, "image_src", this.getWfCtrl().getCRC(id_dom_carousel).getDbValue()); 
                
                                        this.getWfCtrl().m_bundleFamilies.getBundleQP(this.getIdEntry(), "trigger").updateDoc(this.getIdEntry(), obj);
                                        
                                        console.log(this.constructor.name, "this", this, "this.close", this.close);

                                        // close modal window ... which is the parent of this window
                                        this.getParent().close();
                                    }
                                }>Save</a>
                        </div>  
                    </div>                
                    <p/>                            
                </div> 

            </form>
        );
    }
}
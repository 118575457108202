import SortedMap from "collections/sorted-map";
// import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStd from "./fabbi_standard.js";

export default class StdMap extends SortedMap {
    constructor() {
        super();                
    } 

    log(szLogMark) {
        var iter = super.keys();        
        var res = iter.next();        
        
        while (!res.done) {
            console.log(szLogMark, "id", res.value, "content", this.get(res.value));            
            res = iter.next();
        }      
    }

    /*
    forEach(funcCallbackEntryKeyIdx) {
        var iter = super.keys();
        var res;
        var idx = 0;
        while (!(res = iter.next()).done) {
            funcCallbackEntryKeyIdx(this.get(res.value), res.value, idx);
            ++idx;
        }      
    }
    */

    isEmpty() {
        return(this.size === 0);
    }

    getFirstIdEntry() {  
        if (this.isEmpty()) return(undefined);     
        return(super.keys().next().value);
    }

    getEntryViaIndex(nIndex) {
        var id = this.getIdEntryViaIndex(nIndex);
        if (!id) return(undefined);
        return(this.get(id));
    }

    getIdEntryViaIndex(nIndex) {

        if (nIndex < 0 || nIndex >= this.size) {
            return(undefined);
        }
    
        var iter = super.keys();
        var result = iter.next();    // now we are on the first "key"
        for (var i=0; i < nIndex; ++i) { 
            // console.log("getIdEntryViaIndex", "tmp", iter.value);
            result = iter.next(); 
        }

        return(result.value);
    }

    getRandomIdEntry() {  
        if (this.isEmpty()) return(undefined);
        var nNum = FabStd.randomInt(this.size-1);
        return(this.getIdEntryViaIndex(nNum));
    }
    
    get(szIdEntry, bAddIfEntryNotExisting, function_returningDefaultContent) {
        var entryValue = super.get(szIdEntry);
        if (entryValue === undefined && bAddIfEntryNotExisting) {
            // console.log(function_returningDefaultContent, bAddIfEntryNotExisting, typeof function_returningDefaultContent);
            entryValue = function_returningDefaultContent(szIdEntry);
            this.set(szIdEntry, entryValue);
        }
        return entryValue;
    }    
    
    bind_funcCompare(mapToSort, funcCompare) {
        return(function(szKey_a, szKey_b) { return(funcCompare(mapToSort, szKey_a, szKey_b)); });
    }
    
    compare_TsAdded(mapToSort, szKey_a, szKey_b) {
        var value_a = mapToSort.get(szKey_a)["dataset"].ts_added;
        var value_b = mapToSort.get(szKey_b)["dataset"].ts_added;
        var nVgl = 0;
        
        if ((value_a === undefined || value_a === null) && (value_b === undefined || value_b === null)) {
            return(szKey_a.localeCompare(szKey_b));
        }

        if ((value_a === undefined || value_a === null) && value_b !== undefined && value_b !== null) {
            return(1);
        }
        if ((value_b === undefined || value_b === null) && value_a !== undefined && value_a !== null) {
            return(-1);
        }

        if (value_a > value_b) {
            nVgl = 1;
        } else {
            if (value_a < value_b) {
                nVgl = -1;
            } else {                                    
                nVgl = szKey_a.localeCompare(szKey_b);
            }                
        }
        return(nVgl);        
    }
    
    keys_sorted(funcCompareBound) {       
        
        // set default compare-function
        if (funcCompareBound === undefined) {            
            funcCompareBound = this.bind_funcCompare(this, this.compare_TsAdded);            
        }             
        
        var iter = super.keys();
        var res = iter.next();
        var arr = [];
        
        while (!res.done) {
            arr.push(res.value);
            res = iter.next();
        }

        if (arr.length <= 1) return(arr);
        
        var erg = arr.sort(funcCompareBound);
    
        return(erg);
    }
    
    set(szIdEntry, entryValue) {
        super.set(szIdEntry, entryValue);
    }
    
    delete(szIdEntry) {
        super.delete(szIdEntry);
    }

    clear() {
        super.clear();
    }

    removeAll() {
        this.clear();
    }

    deleteAll() {
        this.clear();
    }
}
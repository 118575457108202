import React from 'react';
import ReactDOM from 'react-dom';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import googleImg from "../../images/logo/btn_google_signin_dark_normal_web.png";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
import CrcModalWait_01 from "./fabbi_crc_modalwait_01.jsx";

export default class CrcSignIn_Main_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._ctrl = this.getWfCtrl().getFirebaseCtrl();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getIdDOM_InputEmail() {
        return(this.getIdDOM() + "_input_email");
    }

    getDbValue_InputEmail() {
        console.log("MODAL", this.getIdDOM_InputEmail(), document.getElementById(this.getIdDOM_InputEmail()));
        return(this.getCRC(this.getIdDOM_InputEmail()).getDbValue());
    }

    getIdDOM_InputPassword() {
        return(this.getIdDOM() + "_input_password");
    }

    getDbValue_InputPassword() {
        return(this.getCRC(document.getElementById(this.getIdDOM_InputPassword())).getDbValue());
    }

    render() {
        return(
            <form id={this.getIdDOM()}>
                <div className={"-modal-content"} 
                    style={{ 
                        margin: "1rem auto", 
                        padding: "2rem", 
                        border: "black", 
                        borderWidth: "2px", 
                        borderRadius: "5px", 
                        borderStyle: "solid", 
                        width: "40rem", 
                        boxShadow: "5px 10px 8px gray"}}>
                    <div className={"fab-headline"}>Please choose Login-Method</div>        
                    <p/>             
                    <div className="row">
                        <a><img id={"signin-via-google"} style={{cursor: "grab"}} src={googleImg} alt="Sign in with Google" href="" data-i18n="" 
                            onClick={(event) => {
                                // close modal window ... which is the parent of this window
                                // this.getParent().close();

                                var id_dom_wait = 
                                    CrcModal_01.openAsModal(this.getWfCtrl(),
                                        { 
                                            style: { 
                                                ...CrcModal_01.propStyle_FullScreen, 
                                                opacity: "50%"
                                            } 
                                        },
                                        CrcModalWait_01, 
                                        {
                                        } 
                                    );

                                // this.props.func_signInWithGoogle();
                                console.log(this.constructor.name, "signInWithGoogle");
                                this._ctrl.signInWithGoogle().then((result) => {
                                    console.log(this.constructor.name, "close", "id_dom_wait", id_dom_wait);

                                    CrcModal_01.closeModal(this.getWfCtrl(), id_dom_wait);
                                    if (result.rc === "OK") {
                                        this.close();
                                    }

                                }).finally(() => {
                                    console.log(this.constructor.name, "finally", "id_dom_wait", id_dom_wait);
                                });
                            }}
                        />
                        </a>
                    </div>
                    <hr/>
                    <div className="row">
                        <h5>via eMail/Password</h5>
                        <CrcInput_01 
                            id_dom={this.getIdDOM_InputEmail()} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="eMail"
                            type="email"
                            required 
                            defaultValue={this.props.email}/>

                        <CrcInput_01 
                            id_dom={this.getIdDOM_InputPassword()} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Password"
                            type="password"
                            required 
                            defaultValue={""}/>

                        <a onClick={(event) => {
                            var email = this.getDbValue_InputEmail();
                            var id_dom_wait = 
                            CrcModal_01.openAsModal(this.getWfCtrl(),
                                { 
                                    style: { 
                                        ...CrcModal_01.propStyle_FullScreen, 
                                        opacity: "50%"
                                    } 
                                },
                                CrcModalWait_01, 
                                {
                                } 
                            );

                            // this.getParent().close();
                            console.log("resetPw", email);
                            this._ctrl.sendPasswordResetEmail(email, this.getWfCtrl().getUrlToSignIn(email)).then((result) => {
                                CrcModal_01.closeModal(this.getWfCtrl(), id_dom_wait);
                                if (result.rc === "OK") {
                                    CrcModal_01.closeModal(this.getWfCtrl(), this);
                                } else {
                                    console.log(this.constructor.name, "resetPw", rc.error);
                                }
                            });
                        }}>forgot my password</a>
                                                
                        <div className="col s12">
                            <a className={"btn waves-effect waves-light send modal-close"} 
                                id="signin-via-email-and-password" 
                                onClick={
                                    (event) =>  {
                                        // close modal window ... which is the parent of this window
                                        var email = this.getDbValue_InputEmail();
                                        var pw = this.getDbValue_InputPassword();

                                        var id_dom_wait = 
                                        CrcModal_01.openAsModal(this.getWfCtrl(),
                                            { 
                                                style: { 
                                                    ...CrcModal_01.propStyle_FullScreen, 
                                                    opacity: "50%"
                                                } 
                                            },
                                            CrcModalWait_01, 
                                            {
                                            } 
                                        );

                                        // this.getParent().close();
                                        console.log("signIn", email, pw);
                                        this._ctrl.signInWithEmailAndPassword(email, pw).then((result) => {
                                            console.log(this.constructor.name, "close", "id_dom_wait", id_dom_wait);
                                            CrcModal_01.closeModal(this.getWfCtrl(), id_dom_wait);
                                            if (result.rc === "OK") {
                                                this.close();
                                            }
                                        }).finally(() => {
                                            console.log(this.constructor.name, "finally", "id_dom_wait", id_dom_wait);
                                         });
                                    }
                                }>
                                Sign in
                            </a>
                        </div>      
                    </div>                            
                </div>
            </form>  
        );
    }
}
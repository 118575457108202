import qryProcessorBundle from "./fabbi_qry_processor_bundle.js";

export default class qryProcessorBundle_CloudFirestore extends qryProcessorBundle {
    constructor(props) {
        super(props);
    }
    
    // can be overriden to simulate a similar behaviour as a "normal" CloudFirestore-Collection
    getCollectionPath() {
        return("");
    }
}

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import HtmlProcessor_UserInvitations from './fabbi_htmlprocessor_userinvitations.js';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcReceivedInvitations_ModalInput_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._qryP = this.getWfCtrl().m_listenerUserInvitations;
        // set a signal "when no html-data-can be set! Selfdestruction!"
        this._htmlP = new HtmlProcessor_UserInvitations(this.getWfCtrl(), this._qryP.getPrivateDataPool());
        this._qryP.setHtmlProcessor(this._htmlP);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._qryP = undefined;
    }

    componentDidMount() {
        super.componentDidMount();
        
        this._qryP.planRun();
    }

    render() {      

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>Received Invitations</span>         
                    </div>        
                    <p/>             
                    <div className={"row"}>                   
                        <div style={
                            {width: "100%", maxHeight: "400px", overflowY: "auto" }} 
                            id={this._htmlP.getId_Div_SuperSection()}>    
                        </div>
                    </div>
                </div> 
            </form>
        );
    }
}
import React from 'react';
import ControlledReactComponentParent from "./fabbi_controlled_react_component_parent.js";

export default class Crc_SC_Base extends ControlledReactComponentParent {
    constructor(props) {
        super(props);
    }

    /** @override */
    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    } 

    /** @override */
    componentDidMount() {
        super.componentDidMount();
    }

    /** @override */
    onCancel(cancelInfo) {
        if (this.props.onCancel) return(this.props.onCancel(this, cancelInfo)); 
    }

    /** @override */
    onSet() {
        if (this.props.onSet) return(this.props.onSet(this, this.getResult()));
    }

    /** @override */
    getResult() {
        var result = {
            dummyResult: "should be overridden"
        }
        return(result);
    }

    /** @override */
    focus() {
        var dom;
        if (dom = this.getDOMElement()) {
            var arrE = dom.getElementsByTagName("input");
            if (arrE?.length > 0) arrE[0].focus();
        }
    }

    /** @override */
    render() {
        return(<div>DUMMY</div>);
    }
}
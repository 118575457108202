import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import PerfectScrollbar from "./perfect-scrollbar.min.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import Resizable from "./fabbi_resizable.js";
import CrcExt_Resizable from "./fabbi_crc_ext_resizable.jsx";
import CrcNavMainComm_01 from "./fabbi_crc_nav_main_comm_01.jsx";
import CrcStatistics_Main_01 from "./fabbi_crc_statistics_main_01.jsx";
import CrcGoogle_Main_01 from "./fabbi_crc_google_main_01.jsx";
import CrcSignIn_Main_01 from './fabbi_crc_sign_in_main_01.jsx';

export default class CrcNavMain_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this.state.bNavExpanded = true;
        this.state.bAuthenticated = false;

        // var res = new CrcExt_Resizable();
        // this.addExtension([ res ]);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        console.log(this.constructor.name, "componentDidUpdate", "prevState", prevState);
        if (prevState.bAuthenticated !== this.state.bAuthenticated) {
            console.log(this.constructor.name, "componentDidUpdate", "authenticated", this.state.bAuthenticated);   
        }
    }

    render() {        
        var arrResult = [];

        console.log(this.constructor.name, "render", "authenticated", this.state.bAuthenticated);

        arrResult.push(
            // <!-- START: Page Main-->
            <div 
                key={this.getIdDOM()} 
                id={this.getIdDOM()} 
                className={"fab-main-height" + (!this.state.bNavExpanded?" main-full":"")} 
                style={{ width: "inherit" }}
                >
                <div id="modal1"></div>    
                <div className={"fab-main-height"} id="main_section_01">
                    { 
                        this.getWfCtrl().isUserAuthenticated()?
                            this.getReactFragment_Authenticated():
                            this.getReactFragment_NotAuthenticated() 
                    }
                </div>
            </div>
            // <!-- END: Page Main--> 
        );
        return(arrResult);
    }    

    getReactFragment_NotAuthenticated() {
        console.log(this.constructor.name, "getReactFragment_NotAuthenticated");
        return(
            <React.Fragment>
                <CrcSignIn_Main_01 wfCtrl={this.getWfCtrl()} parent={this}/>
            </React.Fragment>
        );
    }

    // <h5>login, please!</h5>   
    // <div id="log" style={{ width: "100%", height: "100%", overflowY: "scroll"}}>
    // </div>


    getReactFragment_Authenticated() {
        console.log(this.constructor.name, "getReactFragment_Authenticated");
        return(
            <React.Fragment>
                <CrcNavMainComm_01 
                    parent={this} 
                    wfCtrl={this.getWfCtrl()} 
                    style={{ 
                        display: (this.getWfCtrl().getActiveModule() === "comm")?"":"none" 
                    }}/>
                { 
                    (this.getWfCtrl().getActiveModule() === "statistics")?
                        <CrcStatistics_Main_01 parent={this} wfCtrl={this.getWfCtrl()}/>:
                        []
                }
                { 
                    (this.getWfCtrl().getActiveModule() === "google")?
                        <CrcGoogle_Main_01 parent={this} wfCtrl={this.getWfCtrl()}/>:
                        []
                }
            </React.Fragment>
        );
    }
}
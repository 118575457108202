import React from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import CrcNavMain_01 from './fabbi_crc_nav_main_01.jsx';
import CrcNavSide_01 from './fabbi_crc_nav_side_01.jsx';
import CrcNavHeader_01 from './fabbi_crc_nav_header_01.jsx';
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { LinearProgress } from '@material-ui/core';

class DoAfterTime extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.state.running = (this.props.onTimeout && this.props.timeout);
        this.state.finished = false;
        this.state.datStart = 0;
        this.state.progress = 0;
        this._interval = undefined;
    }

    componentWillUnmount() {
        if (this._interval) clearInterval(this._interval);
        this._interval = undefined;
    }

    componentDidUpdate() {
        if (this.state.finished) { 
            this.setState({ finished: false, running: false }); 
            this.props.onTimeout(this); 
        } 
    }
    
    componentDidMount() {
        if (this.props.onTimeout && this.props.timeout) {
            this.datStart = new Date();
            this._interval = 
                setInterval(
                    () => { 
                        var progress = (new Date() - this.datStart)/this.props.timeout * 100 ;
                        var finished = false;
                        if (progress >= 100) { 
                            finished = true; 
                            if (this._interval) clearInterval(this._interval);
                            this._interval = undefined;
                        }
                        this.setState({progress: Math.min(100, progress), finished });
                    }, 
                    250
                );
            // setTimeout(() => { this.props.onTimeout(this) }, this.props.timeout);
        }
    }

    render() {
        return( 
            <React.Fragment>{ this.props.children }
                { this.state.running?(<LinearProgress variant="determinate" value={Math.min(100, this.state.progress)}/>):undefined }
            </React.Fragment>);
    }
}


class CrcNavMainSnackbar_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
        this.state.showSnackbar = true;

        this._snackId = 0;
        this._arrSnacks = [];
     
        /*
        for (var i=0; i < 100; ++i) {
            this.addSnack("info", "TESTTEXT " + i);
            this.addSnack("success", "TESTTEXT " + i);
            this.addSnack("warning", "TESTTEXT " + i);
            this.addSnack("error", "TESTTEXT " + i);
        }
        */
    }

    componentDidMount() {
        super.componentDidMount();

        console.debug(this.constructor.name, "componentDidMount", this.getIdDOM());
    }

    /** 
     * @param {string} severity Please choose from "error", "warning", "info" (default), "success"
     * @param {string} content Some text to display
     * @param {Object=} optProps Optional props-Object to use within content 
    */
    addSnack(severity, content, optProps) {
        this._snackId = this._snackId + 1;
        this._arrSnacks.push({
            severity, content, optProps, added: new Date(), snackId: this._snackId
        });
        this.setState({});
    }

    render() {
        var count = 0;

        return(
            <Snackbar
                id={this.getIdDOM()}
                key={this.getIdDOM()}
                // autoHideDuration={5000}
                open={ this.state.showSnackbar }
                // onClose={  () => this.setState({ showSnackbar: false }) }
                // onClose={handleClose}
                // TransitionComponent={state.Transition}                
                // key={state.Transition.name}
            >
                <div key={"snackstack"} style={{ 
                    display: 'flexbox', 
                    flexDirection: "row"
                }}>
                {
                    this._arrSnacks.filter((elem) => { if (elem.collapsed) return(false); count=count+1; return(count < 5); } ).map((elem, idx) => {
                        return(
                            <DoAfterTime 
                                key={elem.snackId}
                                onTimeout={() => { if (!elem.collapsed) { elem.collapsed = true; this.setState({}) } }  } 
                                timeout={5000}>

                                <Collapse style={{margin: "5px 0px"}} in={!elem.collapsed}>
                                    <MuiAlert
                                        severity={elem.severity} 
                                        onClose={() => {elem.collapsed = true; this.setState({})} } 
                                        elevation={6} 
                                        variant="filled" 
                                        {...elem.optProps}>
                                        { elem.content }
                                    </MuiAlert>
                                </Collapse>
                            </DoAfterTime>
                        );
                    })
                }
                </div>
            </Snackbar>
        );
    }
}

export default class CrcNavMainFrame_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        this._intializedMaterialize = false;
        this._tc = new timeoutCtrl();

        this._arrNavParts = [];
        this._arrNavParts.push({ crcPrototype: CrcNavHeader_01, id: "nav_header" });
        this._arrNavParts.push({ crcPrototype: CrcNavSide_01, id: "nav_side" });
        this._arrNavParts.push({ crcPrototype: CrcNavMain_01, id: "main" });

        this._smapHiddenParts = new StdMap();
        this._arrHiddenParts = [];
        this._arrVisibleParts = [];

        this._activeModule = "comm";
    }

    updateAllNav(stateChange) {
        // update all Navivgation-Parts ...
        this._arrNavParts.forEach((part) => {
            this.getChildCRC(part.id).update(stateChange);
        });
        return(this.update(stateChange));
    }

    setActiveModule(szModule) {
        if (szModule !== this.getActiveModule()) {
            this._activeModule = szModule;

            this.updateAllNav();
        }
    }

    getActiveModule() {
        return(this._activeModule);
    }

    componentDidMount() {
        super.componentDidMount();

        console.log(this.constructor.name, "componentDidMount");

        // OUTER FRAME IS READY NOW

        /*
        // the resizer
        var arr_domResizable = document.getElementsByClassName("resizable");
        if (arr_domResizable.length > 0) {
            this.m_resizable = new Resizable(arr_domResizable[0]); // , options);
        }
        */
    }    

    _updateMaterializeComponents() {

        // this.prepareTrashbins();        
    
        // "tabs" is something form "JQuery-UI"
        // FabStdBro.initMaterializeItems_Collapsibles();
        // FabStdBro.initMaterializeItems_DropDowns();
        // FabStdBro.initMaterializeItems_Navigation();
        // FabStdBro.initMaterializeItems_Tabs();

        // g_wfCtrl.m_qpTicker.processEntry(undefined, { subject: "login finished", info: "Login of user " + g_wfCtrl.getMainUserId() + " accomplished." }, "added");
        // g_wfCtrl.m_qpTicker.processEntry(undefined, { subject: "randomNumber", info: "Number " + FabStd.randomInt(1000,0) }, "added");
    }

    childComponentDidUpdate(childCRC) {
        super.childComponentDidUpdate(childCRC);

        // console.log(this.constructor.name, "childComponentDidUpdate");
    }

    childComponentDidMount(childCRC) {
        super.childComponentDidMount(childCRC);
    }

    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate(prevProps, prevState);
    }

    removeVisible(id_dom) {
        var cntOld = this._arrVisibleParts.length;
        this._arrVisibleParts = this._arrVisibleParts.filter((visiblePart) => visiblePart.id_dom !== id_dom);

        if (cntOld !== this._arrVisibleParts.length) {
            console.log(this.constructor.name, "removeVisible", "removed", id_dom);
            // reduced ! force an update!
            this._tc.setAutoAbortTimeout(() => { this.update() }, 0);
        }
    }

    addVisible(crcPrototype, props) {
        var obj = { crcPrototype, ...props, parent: this, wfCtrl: this.getWfCtrl() };
        var bCheck = false;

        if (!obj.id_dom) { 
            obj.id_dom = ControlledReactComponent.getNewUniqueTempId();
        } else {
            bCheck = true;
        }
        if (!obj.id_child) obj.id_child = obj.id_dom;
        if (!obj.key) obj.key = obj.id_dom;
        // console.log(this.constructor.name, "addVisible", obj);

        if (bCheck && this._arrVisibleParts.some(
            (visiblePart, idx) => { 
                var bFound = (visiblePart.id_dom === obj.id_dom); 
                if (bFound) this._arrVisibleParts[idx] = obj;   // set new object
                return(bFound); 
            })) {
            // ok ... array-element was modified!
        } else {
            this._arrVisibleParts.push(obj);
        }
        this._tc.setAutoAbortTimeout(() => { this.update() }, 0);
    
        return(obj.id_dom);
    }

    doWithHidden(crcPrototypeFilter, funcAdditionalFilter, funcToDo) {
        this.collectHidden(crcPrototypeFilter, funcAdditionalFilter).forEach((objCrcBlueprint) => {
            var crc = this.getCRC(objCrcBlueprint.id_dom);
            if (crc) {
                funcToDo(crc, objCrcBlueprint);
            }
        });
    }

    collectHidden(crcPrototypeFilter, funcAdditionalFilter) {
        console.log("collectHidden", crcPrototypeFilter);
        return(
            this._arrHiddenParts.filter((objCrcBlueprint) => {
                var crc = this.getCRC(objCrcBlueprint.id_dom);
                if (!crc) return(false);
                if (!crcPrototypeFilter || crc instanceof crcPrototypeFilter) {    
                    return(!funcAdditionalFilter || funcAdditionalFilter(crc, objCrcBlueprint));
                } 
                return(false);
            })
        );
    }

    removeHidden(id_dom) {
        var cntOld = this._arrHiddenParts.length;
        this._arrHiddenParts = this._arrHiddenParts.filter((visiblePart) => visiblePart.id_dom !== id_dom);

        if (cntOld !== this._arrHiddenParts.length) {
            console.log(this.constructor.name, "removeHidden", "removed", id_dom);
            // reduced ! force an update!
            this._tc.setAutoAbortTimeout(() => { this.update() }, 0);
        }
    }

    addHidden(crcPrototype, props) {
        var objCrcBlueprint = { crcPrototype, ...props, parent: this, wfCtrl: this.getWfCtrl() };

        if (!objCrcBlueprint.id_dom) objCrcBlueprint.id_dom = ControlledReactComponent.getNewUniqueTempId();
        if (!objCrcBlueprint.id_child) objCrcBlueprint.id_child = objCrcBlueprint.id_dom;
        if (!objCrcBlueprint.key) objCrcBlueprint.key = objCrcBlueprint.id_dom;

        // console.log(this.constructor.name, "addHidden", objCrcBlueprint);

        // call static function that should be provided!
        if (crcPrototype.completeProps) {
            crcPrototype.completeProps(objCrcBlueprint);
        }
        // console.log("COMPLETE_PROPS", crcPrototype, crcPrototype.completeProps);

        this._arrHiddenParts.push(objCrcBlueprint);
        this._smapHiddenParts.set(objCrcBlueprint.id_dom, {
            objCrcBlueprint:  objCrcBlueprint
        });

        this._tc.setAutoAbortTimeout(() => { this.update() }, 0);
    
        return(objCrcBlueprint.id_dom);
    }

    getCRC_SnackBar() {
        return(this.getWfCtrl().getCRC(this.getIdDOM_SnackBar()));
    }
    
    getIdDOM_SnackBar() {
        return(this.getIdDOM() + "_snackbar");
    }

    render() {        
        var arrResult = [];

        // console.log(this.constructor.name, "render", "hiddenParts", this._arrHiddenParts);

        arrResult.push(
            <div id={this.getIdDOM()} key={this.getIdDOM()}>
                <div style={{ display: "none" }} id={this.getIdDOM() + "_hidden"} key={this.getIdDOM() + "_hidden"}>
                { 
                    this._arrHiddenParts.map((hiddenPart) => 
                        <hiddenPart.crcPrototype {...hiddenPart}/>
                    )
                }
                </div>

                <div id={this.getIdDOM() + "_visible"} key={this.getIdDOM() + "_visible"}> 
                {
                    this._arrVisibleParts.map((visiblePart) => 
                        <visiblePart.crcPrototype {...visiblePart}/>
                    )
                }
                </div>
                {
                    this._arrNavParts.map((navPart) => 
                        <navPart.crcPrototype 
                            id_dom={navPart.id} 
                            id_child={navPart.id} 
                            key={navPart.id} 
                            parent={this} 
                            wfCtrl={this.getWfCtrl()}
                        />
                    )
                }

                <CrcNavMainSnackbar_01 wfCtrl={this.getWfCtrl()} id_dom={this.getIdDOM_SnackBar()}/>
            </div>
        );

        return(arrResult);
    }    
}
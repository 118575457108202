import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import HtmlProcessor_Families from "./fabbi_htmlprocessor_families.js";
import qryProcessor from "./fabbi_qry_processor.js";
import qryProcessorBundle_CloudFirestore from "./fabbi_qry_processor_bundle_cloudfirestore.js";
import qryProcessor_Messages from "./fabbi_qry_processor_messages.js";
import qryProcessor_Missions from "./fabbi_qry_processor_missions.js";
import qryProcessor_Members from "./fabbi_qry_processor_members.js";
import qryProcessor_MembersInvited from "./fabbi_qry_processor_membersinvited.js";

export default class qryProcessorBundle_Family extends qryProcessorBundle_CloudFirestore {
    constructor(props) {
        super({ ... props, htmlProcessorPrototype: HtmlProcessor_Families });
    }    

    // Overrides existing!
    getClassName() {
        return("qryProcessorBundle_Family");    // to FIX the classname!
    }
    
    getCollectionPath() {
        return("families");
    }
    
    getChildQP_Missions(szFamilyId) {        
        return(this.getSingleChildQP("qryProcessor_Missions" + ".*" + szFamilyId));        
    }
    
    getChildQP_Messages(szFamilyId) {        
        return(this.getSingleChildQP("qryProcessor_Messages" + ".*" + szFamilyId));        
    }

    getFamilyMemberIds(szFamilyId) {
        return(qryProcessor.getDocumentIdsArray(this.getChildQP_Members(szFamilyId))); 
    }

    getFamilyMemberInvitedIds(szFamilyId) {
        return(qryProcessor.getDocumentIdsArray(this.getChildQP_MembersInvited(szFamilyId))); 
    }

    getChildQP_Members(szFamilyId) {                
        return(this.getSingleChildQP("qryProcessor_Members_" + ".*" + szFamilyId));
    }

    getChildQP_MembersInvited(szFamilyId) {        
        return(this.getSingleChildQP("qryProcessor_MembersInvited_" + ".*" + szFamilyId));
    }

   /**
    * Collects Family-Ids.
    * @param {String?} szFilterUserId A UserId (optional), if given only FamilyIds are collected, the UserId is a member of.
    * @returns {String[]} An String-Array is returned, containing the (matching) FamilyIds. It's empty, if no (or no matching) families were found.
    */    
    getFamilies(szFilterUserId) {
        var arrFamilies =  [];
        this.getPrivateDataPool().getDataMap().filter((entry) => {                        
            return(entry["state"] !== "deleted");
        }).forEach((entry) => {
            var szFamilyId = entry["id"];
            var bPush = false;

            // if (!!szFilterUserId) {
            //    console.log(this.getId(), "getFamilies", "FamilyId", szFamilyId);
            // }

            // console.log("tryToFix", "members", this.getChildQP_Members(szFamilyId));            
            if (!szFilterUserId || szFilterUserId === "") {
                bPush = true ;
            } else {
                var qryP_Mem = this.getChildQP_Members(szFamilyId);
                // console.log(this.getId(), "getFamilies", qryP_Mem?qryP_Mem.getMembers():qryP_Mem, "szFilterUserId", szFilterUserId);
                if (!!qryP_Mem && qryP_Mem.getMembers().includes(szFilterUserId)) {
                    // console.log(this.getId(), "getFamilies", qryP_Mem?qryP_Mem.getMembers():qryP_Mem, "szFilterUserId", szFilterUserId, "PUSHs");
                    bPush = true;
                }
            }
            
            if (bPush) {
                arrFamilies.push(szFamilyId);                
            }
        });
        return(arrFamilies);
    }   

    onRun() {                
        console.debug(this.constructor.name, "onRun");
        
        this.getPrivateDataPool().getDataMap().filter((entry) => {                        
            // ".*" is equivalent to "*" within RegExp-Expressions 
            // ... so we look for some QueryProcessor-Ids ending with "id"
            return(entry["state"] === "added" && entry["pending_onRun"] === "y" && !this.hasChildQP(".*" + entry["id"]));
        }).forEach((entry) => {
            var szIdEntry = entry["id"];    // this is the id (FamilyId) of the FAMILY we're looking on ...                        
            var szQueryProcessorId = entry["id_qp"];    // the id of the queryProcessor, delivering the entry
            var qryP_Family = this.getBundleQP(szQueryProcessorId);

            console.log(this.getClassName(), "onRun", "attaching Child-Messages-Processor", "id", szIdEntry);
            var msg = 
                new qryProcessor_Messages({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: qryP_Family, 
                    szTriggerId: szIdEntry,
                    szFamilyId: szIdEntry
                });
            this.addChildQP(msg);
            
            // register the msg-qry-processor-id of this FAMILY (FamilyId) in the user-individualities
            this.getWfCtrl().m_listenerUserIndividualities.addRequestForReport(
                msg.getId(),    // inform the qryProcessor_Messages ... if something happend to its family-referencing data (e.g. the "ts_lc_messages"-Date)
                msg,            // recipient-eventTarget
                szIdEntry, 
                qryProcessor.EVENT_subType_reportOnRunCompleted);

            // set the Callback-function to get the "LastChecked"-item
            msg.setLastChecked(undefined, () => { 
                // console.log("getLastChecked_Messages", "__");
                return(this.getWfCtrl().m_listenerUserIndividualities.getLastChecked_Messages(szIdEntry)); 
            });

            var mis = 
                new qryProcessor_Missions({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: qryP_Family, 
                    szTriggerId: szIdEntry,
                    szFamilyId: szIdEntry
                });
            this.addChildQP(mis);
            
            var mem = 
                new qryProcessor_Members({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: qryP_Family, 
                    szTriggerId: szIdEntry,
                    szFamilyId: szIdEntry
                });
            this.addChildQP(mem);

            var meminv = 
                new qryProcessor_MembersInvited({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: qryP_Family, 
                    szTriggerId: szIdEntry,
                    szFamilyId: szIdEntry
                });
            this.addChildQP(meminv);            
        });
        
        return(super.onRun());
    }    
    
    onRunCompleted() {        
        super.onRunCompleted();
    }
    
    // this handler is called by subordinated "messages"-qryProcessors, if a change was recognized
    handleQryProcessorEvent(event) {
        super.handleQryProcessorEvent(event);
                
        // console.log(this.getClassName(), "handleQryProcessorEvent", qryProcessor_Messages.prototype.constructor.name);

        // we change, if new messages concerning the family were incoming ...
        if (event.detail.sender.constructor.name === qryProcessor_Messages.prototype.constructor.name) {                    
            if (event.detail.szSubType === qryProcessor.EVENT_subType_reportOnRunCompleted) {                        
                let qP = event.detail.sender;
                let szFamilyId = qP.getFamilyId();            
                
                // we are in "qryProcessorBundle_Family": a new message or e. was recognized, so we update the chat-list!
                if (this._hasHtmlProcessor()) {
                    this.useHtmlProcessors()?.prepareAndProcessEntry(szFamilyId);
                }
            }
        }
    }
}

import React, { useState }  from 'react';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import Lib_GdtTimeseries from "./fabbi_lib_gdt_timeseries.js";
import { Editor, Entity, CharacterMetadata, convertToRaw, getVisibleSelectionRect, Modifier, SelectionState, EditorState, ContentState } from "draft-js";
import 'draft-js/dist/Draft.css';
import CrcInputRich_01 from './fabbi_crc_input_rich_01.jsx';
import CrcModalSelect_01 from "./fabbi_crc_modalselect_01.jsx";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';

class ScriptPositionInfo {
    constructor(scriptContext, domContent, editorState) {
        this.scriptContext = scriptContext;
        this.foundLine = undefined; // infoObject { scriptLine, idx }
        this.foundPart = undefined; // infoObject { part, idx }
        this.editorState = undefined;
        this.origSel = editorState.getSelection();

        console.debug(this.constructor.name, "origSel", this.origSel);

        var offsetKey = domContent.dataset?domContent.dataset.offsetKey:"";
        var match = /(.*)-(\d*)-(\d*)/s.exec(offsetKey);
        if (match && match.length > 3) {
            var key = match[1];
            var off1= match[2];
            var off2= match[3];
        
            var blockParent = domContent;
            if (blockParent.parentElement.dataset && blockParent.parentElement.dataset.offsetKey) {
                blockParent = blockParent.parentElement;
            }

            // find actual offset ... 
            var offset = 0;
            for (var i=0; i < blockParent.children.length; ++i) {
                if (blockParent.children[i].dataset.offsetKey === offsetKey) {
                    break;
                }
                offset += blockParent.children[i].innerText.length;
            }

            // console.debug(this.constructor.name, "offsetKey", offsetKey, "key", key, "offset", offset);
        
            if (offset >= 0 && this.scriptContext) {
                this.foundLine = this.scriptContext.findLineDone(key);
                // console.debug(this.constructor.name, "foundLine", this.foundLine, key);
                if (this.foundLine) {
                    this.foundPart = this.foundLine.scriptLine.findPart(offset);
                    // console.debug(this.constructor.name, "findLine", this.foundLine, "findPart", this.foundPart, "scriptContext", this.scriptContext);
                }
            }
        }

        this.editorState = editorState;
    }

   /** set the active part component, that is either "key" or "val" */
    setActivePartComponent(szActivePartComponent) { 
        if (szActivePartComponent === "key") this._szActivePartComponent = "key"; else this._szActivePartComponent = "val"; 
    }

    getActivePartComponent() {
        return(this._szActivePartComponent?this._szActivePartComponent:"key");
    }

    getPartComponentProcessor(szPartComponent) {
        if (!szPartComponent) szPartComponent = this.getActivePartComponent();
        return(this.getPart()?.getPartComponentProcessor(szPartComponent));
    }

    getEditorState() {
        return(this.editorState);
    }

    getEditorState_Selection() {
        return(this.editorState?.getSelection());
    }

    getScriptInterpreter() {
        return(this.scriptContext?.getScriptInterpreter());
    }

    getKey() {
        return(this.foundLine?.scriptLine?.getKey());
    }

    getScriptLine() {
        return(this.foundLine?.scriptLine);
    }

    getScriptLineIdx() {
        return(this.foundLine?this.foundLine.idx:-1);
    }

    getPart() {
        return(this.foundPart?.part);
    }

    getPartIdx() {
        return(this.foundPart?this.foundPart.idx:-1);
    }

    isBasePart() {
        return(this.getPartIdx() === 0);
    }

    getInterpreterInfo() {
        return(this.getPart()?.getInterpreterInfo());
    }

    getInterpreterInfoBase() {
        return(this.getScriptLine()?.getInterpreterInfoBase());
    }

    getBasePart() {
        return(this.getScriptLine()?.getPart(0));
    }
}

export default class CrcStatistics_Editor_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        this._tc_jobCode1 = new timeoutCtrl();
        this._tc_jobCode2 = new timeoutCtrl();
        this._tc_jobCode3 = new timeoutCtrl();

        this._idDom_contentHelper = undefined;

        this.state = {editorState: EditorState.createEmpty()};
    }

    _abortTimers() {
        this._tc_jobCode1.abort();
        this._tc_jobCode2.abort();
        this._tc_jobCode3.abort();
    }

    focus() {
        this.getCRC(this.getIdDOM())?.focus();        
    }

    isEditorMouseDown() {
        var crcEditor = this.getCRC(this.getIdDOM());
        if (!crcEditor) return(false);
        return(crcEditor.isMouseDown());
    }

    isEditorActive() {
        var crcEditor = this.getCRC(this.getIdDOM());
        if (!crcEditor) return(false);
        return(crcEditor.isEditorActive());
    }

    getPlainText() {
        return(this.state.editorState?this.state.editorState.getCurrentContent().getPlainText():"");
    }

    /** @param {EditorState?} editorState Optional. */
    setScriptStatus(szState, editorState) {
        if (!editorState) editorState = this.state.editorState;

        if (!this._txtEditorLast) this._txtEditorLast = "";
        if (!this._szScriptStatus) this._szScriptStatus = "dirty";

        var szAct = editorState.getCurrentContent().getPlainText().trim();
        if (szState === "" || szState == undefined) {
            // console.debug(this.constructor.name, "setScriptStatus()", "(0)", "old", this._txtEditorLast, "act", szAct, "plain", this.state.editorState.getCurrentContent().getPlainText());
            if (this._txtEditorLast === szAct) {
                console.debug(this.constructor.name, "setScriptStatus()", "(1)", this._szScriptStatus);
                return(this.szScriptStatus);
            }
            szState = "dirty";
        }
        this._szScriptStatus = szState;
        this._txtEditorLast = szAct;
        
        console.debug(this.constructor.name, "setScriptStatus()", "(2)", this._szScriptStatus);
        return(this._szScriptStatus);
    }

    /** */
    getScriptStatus() {
        if (!this._txtEditorLast) this._txtEditorLast = "";
        if (!this._szScriptStatus) this._szScriptStatus = "dirty";
        return(this._szScriptStatus);
    }

    /** **/
    setNewEditorState(editorState, szNewScriptStatus, bSuppressHelper) {
        if (bSuppressHelper === undefined) bSuppressHelper = false;
        if (szNewScriptStatus && editorState) this.setScriptStatus(szNewScriptStatus, editorState);

        console.debug(this.constructor.name, "setNewEditorState", "yyy", "ttt", "new_status", this.getScriptStatus(), "suppressHelper", bSuppressHelper);

        var result = {};
        var bSCH = !bSuppressHelper;
        switch (this.getScriptStatus()) {
            case "checkOK": // time the final script execution
                // call script interpreter
                this._tc_jobCode1.setAutoAbortTimeout(() => {
                    if (this.isContentHelperActive()) return; // cancel!
                    
                    var si = this.getParent().getScriptInterpreter();
                    si.run(
                        si.convertToScriptLineArray_DraftJsEditorContent(
                            convertToRaw(this.state.editorState.getCurrentContent())
                        ), { 
                            gdtMain: this.getParent().getGdtMain()  
                        }
                    );
                }, 5000);  // call update as soon as possible but just once ...
                break;
            
            case "checkFD": 
                this._tc_jobCode1.abort();  // run timer abortion
                break;

            case "dirty":   console.debug(this.constructor.name, "setNewEditorState", "ttt", "DIRTY seen");
            default:
                this._tc_jobCode1.abort();  // run timer abortion

                bSCH = false;
                console.debug(this.constructor.name, "setNewEditorState", "dirty", "ttt", "SET TIMER");
                this._tc_jobCode2.setAutoAbortTimeout(() => { 
                    console.debug(this.constructor.name, "setNewEditorState", "dirty", "ttt", "timer for showContentHelper reached", this.getPlainText());
                    if (this.isContentHelperActive()) return; // cancel!
                    
                    console.debug(this.constructor.name, "setNewEditorState", "dirty", "ttt", "timer for showContentHelper reached", "helper actually inactive");
                    this.quickScriptCheck(!bSuppressHelper); 
                }, 500);  // call update as soon as possible but just once ...

                break;
        }

        // show helper ...
        if (bSCH) {
            // und hier ohne Zeitverzug nach dem Editor-Update den Helper anzeigen lassen !
            console.debug(this.constructor.name, "setNewEditorState", "ttt", "SHOW HELPER NEXT UPDATE");
            this.doOnNextComponentUpdate(() => this.showContentHelper() );    
            console.debug(this.constructor.name, "setNewEditorState", "showContentHelper - timeout set");
            /*
            this._tc_jobCode3.setAutoAbortTimeout(() => {
                this.showContentHelper();
            }, 500);
            */
        }

        result.editorState = editorState;
        this.setState(result);
    }

    _onUserChangedEditField(type, editorState) {        
        if (this.isEditorActive()) this.hideContentHelper();

        console.debug(this.constructor.name, "_onUserChangedEditField", "yyy", "type", type);

        if (this.getParent().isReady()) {
            // console.log(this.constructor.name, "_onUserChangedEditField", type);
            this.setNewEditorState(editorState, "dirty");
        } else {
            console.debug(this.constructor.name, "_onUserChangedEditField", "yyy", "PARENT NOT READY");            
            // console.debug(this.constructor.name, "_onUserChangedEditField", "not responding to changes ... parent not ready");
        }

    }

    getCode() {
        return(this.getPlainText());
    }

    setCode(newCode) {
        var cs = ContentState.createFromText(newCode);
        var es = EditorState.createWithContent(cs);
        
        this._abortTimers();
        this.hideContentHelper();   // important!
        
        this.setNewEditorState(es, "dirty", true);
        console.debug(this.constructor.name, "setCode", newCode);
    }

    /** Quick scriptCheck (synchronous function) */
    quickScriptCheck(bShowHelperAfterCheck) {
        console.debug(this.constructor.name, "quickScriptCheck()", "Start");
        if (!this.getScriptStatus().startsWith("dirty")) {
            console.debug(this.constructor.name, "quickScriptCheck()", "not necessary", "cancelled");
            return(false);
        }

        var newEditorState  = this.state.editorState;
        var tmpContentState = newEditorState.getCurrentContent();
        var saveSelState    = newEditorState.getSelection();
        var bSetState       = false;
        var tmpSelState;

        this.setScriptStatus("checkFD");

        var si = this.getParent().getScriptInterpreter();
        
        var sc = si.checkScriptLineArray(
            si.convertToScriptLineArray_DraftJsEditorContent(convertToRaw(tmpContentState)), { 
                gdtMain: this.getParent().getGdtMain() 
            });

        if (!sc) {
            console.debug(this.constructor.name, "quickScriptCheck()", "failed");
            return(false);
        }

        this.getParent().setActiveScriptContext(sc);
        // this._scriptContext_lastResult = sc;       // save last check-results

        var blockMap = tmpContentState.getBlockMap();
        blockMap.forEach((block, key) => {
            block.findStyleRanges(
                (characterMetadata) => { 
                    return(characterMetadata.hasStyle("MARK_CMD"));
                }, (start, end) => {
                    tmpSelState =
                        SelectionState.createEmpty(key).merge({
                            anchorOffset: start,
                            focusOffset: end
                        });            
                    tmpContentState = Modifier.removeInlineStyle(tmpContentState, tmpSelState, "MARK_CMD");                    
                }
            );
            block.findStyleRanges(
                (characterMetadata) => { 
                    return(characterMetadata.hasStyle("MARK_PARAM"));
                }, (start, end) => {
                    tmpSelState =
                        SelectionState.createEmpty(key).merge({
                            anchorOffset: start,
                            focusOffset: end
                        });            
                    tmpContentState = Modifier.removeInlineStyle(tmpContentState, tmpSelState, "MARK_PARAM");                    
                }
            );
        });

        sc.arrScriptLinesGiven.forEach((sl) => {
            var partLastOfLine = undefined;
            sl.arrPart.forEach((part, idxPart) => {
                var bRecognizedPart = false;
                partLastOfLine = part;
                if (part.interpreterInfo?.key) bRecognizedPart = true;

                tmpSelState =
                    SelectionState.createEmpty(sl.key).merge({
                        anchorOffset: part.offset,
                        focusOffset: part.offset+part.length
                    });      
                    
                // tmpContentState = Modifier.applyEntity(tmpContentState, tmpSelState, "");

                tmpContentState = Modifier.applyInlineStyle(tmpContentState, 
                    tmpSelState, 
                    (idxPart>0?"MARK_PARAM":"MARK_CMD")+(bRecognizedPart?"":"_NOT_RECOGNIZED")
                    );
            
                /*
                tmpContentState = tmpContentState.createEntity("TOKEN", "IMMUTABLE", { part });
                var entityKey = tmpContentState.getLastCreatedEntityKey();
                console.debug("entityKey", entityKey);
                tmpContentState = Modifier.applyEntity(tmpContentState, tmpSelState, entityKey, tmpContentState.getEntity(entityKey));
                console.debug("entityMap", tmpContentState.getEntityMap(), JSON.stringify(convertToRaw(tmpContentState)));
                */
                
                bSetState = true;
            });

            if (partLastOfLine && partLastOfLine.offset+partLastOfLine.length < sl.length) {
                // console.debug(this.constructor.name, "quickScriptCheck", partLastOfLine.offset+partLastOfLine.length, "vs", sl.length)
            } else {
                // console.debug(this.constructor.name, "quickScriptCheck", "OK", partLastOfLine, sl.length)
            }
        });

        var bOk = !sc.hasErrors();
        var szScriptStatus = bOk?"checkOK":"checkFD";

        // apply all changes
        if (bSetState) {
            newEditorState = EditorState.push(newEditorState, tmpContentState, "insert-characters")
            this.setNewEditorState(
                bSetState?EditorState.forceSelection(newEditorState, saveSelState):undefined, 
                szScriptStatus, 
                !bShowHelperAfterCheck
            );
        }

        console.debug(this.constructor.name, "quickScriptCheck()", "Ende");
        return(bOk);
    }

    onMouseOverEditor(event) {
        // var elem = event.target.parentElement;
    }

    hideContentHelper() {
        var tmpId = this._idDom_contentHelper;
        this._idDom_contentHelper = null;

        if (tmpId) {
            console.debug(this.constructor.name, "hideContentHelper");
            CrcModal_01.closeModal(this.getWfCtrl(), tmpId);
        } else {
            console.debug(this.constructor.name, "hideContentHelper", "FAILED");
        }
        
    }

    isContentHelperActive() {
        var bActive = !!this._idDom_contentHelper;
        console.debug(this.constructor.name, "isContentHelperActive", bActive);
        return(bActive);
    }

    showContentHelper() {
        console.debug(this.constructor.name, "showContentHelper", this._idDom_contentHelper);
        
        if (!this.isEditorActive() || this.isEditorMouseDown()) return(false);  // editor inactive don't show!

        console.debug(this.constructor.name, "showContentHelper", "editor Active");

        // domContent = domNode that represents the documentpart under or above which the help-window shall be displayed.
        var domContent = CrcInputRich_01.getDomContentPartInFocus(this.getIdDOM());
        // console.debug(this.constructor.name, "showContentHelper", domContent);
        if (!domContent) return(false);
        console.debug(this.constructor.name, "showContentHelper", "DomContent found");

        if (this.getScriptStatus() === "dirty") return(false);
        console.debug(this.constructor.name, "showContentHelper", "dirty found");
        
        /*
        this._abortTimers();
        this.quickScriptCheck();
        */

        // keyBlock
        // offset
        // destination-left/top

        // this.state.editorState?.getSelection()?.isCollapsed()
        
        if (domContent.isConnected) {            
            console.debug(this.constructor.name, "showContentHelper", "domContent conntected");

            var rect = domContent.getBoundingClientRect();
            // console.debug("domContent.rect", domContent, rect);
            if (rect) { 
                console.debug(this.constructor.name, "showContentHelper", "rect found", rect);

                var scriptPositionInfo 
                    = new ScriptPositionInfo(
                            this.getParent().getActiveScriptContext(), 
                            domContent,
                            this.state.editorState
                        );

                console.debug(this.constructor.name, "showContentHelper", "SPI", scriptPositionInfo);

                if (scriptPositionInfo.getPart()) {
                    console.debug(this.constructor.name, "showContentHelper", "part found");

                    this._idDom_contentHelper = CrcModal_01.openAsModal( 
                        this.getWfCtrl(),
                        {   
                            id_dom: "autoselect",
                            className: "none",  // nop Classname
                            onClickAway: (event) => { 
                                this.getCRC("autoselect_child")?.handleClickAway(event) 
                            }, 
                            style: {
                                position: "absolute",
                                top: rect.bottom,
                                left: rect.left,
                                width: "300px",
                                height: "auto",
                                overflow: "none"
                            }
                        },  // additional-frame-props
                        CrcModalSelect_01,
                        { 
                            id_child: "autoselect_child", 
                            scriptPositionInfo, // deliver script info
                            onClose: () => {
                                console.debug(this.constructor.name, "onClose()", "yyy");
                                this._abortTimers();
                                // this.hideContentHelper();   // important!
                            },
                                      // change seen of CONTENT-HELPER ...
                            onChange: (crcSelect, infoOnChange) => {
                                if (infoOnChange?.restoreScriptPositionInfo) {
                                    console.debug(this.constructor.name, "restoreScriptPositionInfo", "yyy", "infoOnChange", infoOnChange);
                                    
                                    this.setNewEditorState(
                                        EditorState.forceSelection(this.state.editorState, scriptPositionInfo.origSel),
                                        undefined, 
                                        true
                                    );
                                    this._abortTimers();
                                } else {
                                    console.debug(this.constructor.name, "NOT restoreScriptPositionInfo", "yyy", "infoOnChange", infoOnChange);
 
                                    var spi = crcSelect.getScriptPositionInfo();
                                    var szToInsert = spi.getPart().getCode(); // crcSelect.getResult();

                                    if (spi.getPart().hasVal() || spi.isBasePart()) {
                                        szToInsert += " ";
                                    }

                                    // console.debug(this.constructor.name, "onChange", "szToInsert", szToInsert, "spi", spi);
                                    if (szToInsert) {
                                        if (spi.getPart()) {
                                            var tmpSelState =
                                                SelectionState.createEmpty(
                                                    spi.getKey()).merge({
                                                        anchorOffset: spi.getPart().offset,
                                                        focusOffset: spi.getPart().offset+spi.getPart().length + 1
                                                }); 

                                            // console.debug("1", tmpSelState);
                                            var tmpContentState = 
                                                Modifier.replaceText(
                                                    this.state.editorState.getCurrentContent(), 
                                                    tmpSelState, 
                                                    szToInsert
                                                );
                                            
                                            // console.debug("2");
                                            var newEditorState = 
                                                EditorState.push(
                                                    this.state.editorState, 
                                                    tmpContentState, 
                                                    "insert-characters"
                                                );
                                            
                                            // console.debug("3");
                                            tmpSelState =
                                                SelectionState.createEmpty(spi.getKey())
                                                    .merge({
                                                        anchorOffset: spi.getPart().offset+szToInsert.length,
                                                        focusOffset: spi.getPart().offset+szToInsert.length
                                                    }); 
                                            
                                            this._abortTimers();
                                            this.hideContentHelper();   // important!
                                            this.setNewEditorState(EditorState.forceSelection(newEditorState, tmpSelState), "dirty");
                                        }
                                    }
                                }
                            }
                        },
                        true
                    ); 
                } else {
                    // console.debug(this.constructor.name, "showContentHelper", "getPart nichts!", scriptPositionInfo);
                }
            }
        }

        return(true);
    }

    render() {
        console.log(this.constructor.name, "render()");
        var arrResult = 
            <CrcInputRich_01 
                    id_dom={this.getIdDOM()}
                    wfCtrl={this.getWfCtrl()} 
                    parent={this}
                    style={{ width: "300px", height: "300px", overflowY: "scroll"}} 
                    editorState={this.state.editorState}
                    onMouseOverEditor={(event) => this.onMouseOverEditor(event)}
                    onChange={(type, editorState) => this._onUserChangedEditField(type, editorState) }
                    />
            ;
            return(arrResult);
    }
}

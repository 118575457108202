import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import { Editor, EditorState, RichUtils } from 'draft-js';


export default class CrcInputRich_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        console.log(this.constructor.name, "constructor");
        this.state = {editorState: EditorState.createEmpty() };
        this._hasFocus = false;
        this._cntMouseDown
    }

    componentDidMount() {
        super.componentDidMount();

        this._refEditor = React.createRef();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    static getDomContentPartInFocus(idHtml_ofEditor) {
        var selDoc = document.getSelection();
        if (selDoc && selDoc.focusNode) {
            var found = undefined;
            var parent = selDoc.focusNode.parentElement;
            var idToFind = idHtml_ofEditor;

            do { 
                if (!found) found = (parent.dataset && parent.dataset.offsetKey)?parent:found;
                if (parent.id === idToFind) { 
                    // console.log(this.constructor.name, "found", found, "parent", parent, found.getBoundingClientRect());
                    return(found);
                }
                // console.log(this.constructor.name, "id_to_find", idToFind, "parent", parent, "editor", parent.id);
            } while (parent = parent.parentElement);
        }

        console.log(this.constructor.name, "getDomContentPartInFocus()", "selDoc", selDoc);

        return(undefined);
    }

    isEditorActive() {
        var nTriesLeft = 10;
        if (document.hasFocus()) {
            var selDoc = document.getSelection()?.focusNode;

            var pE = selDoc;
            while (pE = pE?.parentElement) {
                if (pE.id === this.getIdDOM()) {
                    console.log(this.constructor.name, "isEditorActive()", "active");
                    return(true);
                }
                if ((--nTriesLeft) <= 0) break;
            }
        }
        console.log(this.constructor.name, "isEditorActive()", "inactive", "document in focus", document.hasFocus(), "nTriesLeft", nTriesLeft);        
        return(false);

        // console.log(this.constructor.name, "isEditorActive()", this._hasFocus, "selDoc", selDoc);
        // return(this._hasFocus);

        /*
        if (!editorState) editorState = this.state.editorState;
        if (!editorState) return(false);

        console.log(this.constructor.name, "isEditorActive", this.state.editorState.getSelection().getHasFocus());
        return(this.state.editorState.getSelection().getHasFocus());
        */
    }

    onBlur() {
        this._hasFocus = false;
        console.log(this.constructor.name, "onBlur()", "hasFocus", this._hasFocus);
    }

    onFocus() {
        this._hasFocus = true;
        console.log(this.constructor.name, "onFocus()", "hasFocus", "sel", this._hasFocus, this.state.editorState.getSelection());
    }

    // simple function declared
    onChange(editorState) {
        // the props-function "onchange" is called ....
        // var selState = editorState.getSelection();
        // var keyBlock = selState.getFocusKey();
        // var offset = selState.getFocusOffset();

        /*
        // input    key & offset
        // return   corresponding htmlElement
        var selDoc = document.getSelection();

        console.log(this.constructor.name, "onChange", "editorState", keyBlock, offset, "docSel", 
            "focusOffset", document.getSelection().focusOffset, 
            "focusNode", document.getSelection().focusNode
            // ,
            // "rect", document.getSelection().focusNode.getBoundingClientRect()
        );

        var elemEditor = this._refEditor?this._refEditor.current.editor:undefined;
        var elemDataContainer = undefined;
        var elemBlock = undefined;
        var elemPart = undefined;

        if (elemEditor) {
            console.log("elemEditor", elemEditor);
            for (var i=0; i < elemEditor.children.length; ++i) {
                // console.log("data-contents", elemEditor.children[i].attributes['data-contents']);
                if (elemEditor.children[i].attributes['data-contents'] && elemEditor.children[i].attributes['data-contents'].value === "true") {
                    elemDataContainer = elemEditor.children[i];
                    break;
                }
            }
        }
        if (elemDataContainer) {
            var idxBlock = this.getBlockNumber(editorState, keyBlock);
            var keyFound;

            // fast check
            if (idxBlock >= 0 && idxBlock < elemDataContainer.children.length) {
                keyFound = elemDataContainer.children[idxBlock].attributes['data-offset-key'].value;
            }

            if (!keyFound) {
                // sequential check ... slow
                for (var i=0; i < elemDataContainer.children.length; ++i) {
                    if (elemDataContainer.children[i].attributes['data-offset-key']) 
                        keyFound = elemDataContainer.children[i].attributes['data-offset-key'].value = elemDataContainer.children[i].attributes['data-offset-key'].value;
            
                    if (keyFound === keyBlock) break;
                    // console.log(this.constructor.name, "onChange", "data-offset-key", key, elemDataContainer);
                }
            }

            if (keyFound) elemBlock = elemDataContainer.children[i];
        }

        // we have a block ... now we try to find the correct part via the given offset
        if (elemBlock) {
            var offsetTmp = 0;
            var lenTmp = 0;
            for (var i=0; i < elemBlock.children.length; ++i) {
                console.log(this.constructor.name, "elemBlock", i, elemBlock.children[i].innerText);
                lenTmp = elemBlock.children[i].innerText.length;
                if (offset >= offsetTmp && offset < offsetTmp+lenTmp) {
                    elemPart = elemBlock.children[i];
                    break;  // exit for
                }
                offsetTmp += lenTmp;
            }
        }
        */
        
        // if (elem) console.log("data-block", elem);

        this.props.onChange('default', editorState);
    }

    focus(event) {
        console.log(this.constructor.name, "focus()", event);
        
        this._refEditor.current.focus(event);  // focus on editor
    }

    handleKeyCommand(command) {
        const { editorState } = this.props;
        const newState = RichUtils.handleKeyCommand(editorState, command);

        console.log(this.constructor.name, "handleKeyCommand", "yyy", command);

        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

  
    handleBeforeInput(chars, editorState, eventTimeStamp) { 
        console.log(this.constructor.name, "handleBeforeInput", "yyy", chars);
        return("not-handled");
    }

    onTab(e) {
        const maxDepth = 4;
        console.log(this.constructor.name, "onTab", e);
        e.preventDefault();
        // this.onChange(RichUtils.onTab(e, this.props.editorState, maxDepth));
    }

    toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.props.editorState, blockType));
    }

    toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(this.props.editorState, inlineStyle)
        );
    }

    getBlockStyle(block) {
        // console.log(this.constructor.name, "getBlockStyle", block);

        switch (block.getType()) {
            case 'blockquote':
                return 'RichEditor-blockquote';
            default:
                return null;
        }
    }
    
    static getBlockNumber(editorState, keyBlock) {
        if (!keyBlock) keyBlock = editorState.getSelection().getStartKey();
        return(editorState.getCurrentContent().getBlockMap().keySeq().findIndex((k) => k === keyBlock));
    }

    isMouseDown() {
        return(this._cntMouseDown>0);
    }
    
    render() {
        const { editorState } = this.props;
        // If the user changes block type before entering any text, we can
        // either style the placeholder or hide it. Let's just hide it now.
        var className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div 
                className="RichEditor-root" 
                style={{...this.props.style}} 
                id={this.getIdDOM()}
                onMouseDown={(event) => { ++this._cntMouseDown} }
                onMouseUp={(event) => { this._cntMouseDown } }
                /*
                onClick={ 
                    (event) => { 
                        console.log(this.constructor.name, "onClick", event, document.getSelection(), window.getSelection()); 
                        this.focus(event);
                        // this._refEditor.current.dispatchEvent(event);
                        return(true);
                    } 
                }
                */
                >
                <div 
                    className={className} 
                    onMouseOver={(event) => this.props.onMouseOverEditor?this.props.onMouseOverEditor(event):undefined }
                    style={{fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace'}} 
                    >
                    <Editor
                        blockStyleFn={(block) => this.getBlockStyle(block) }
                        customStyleMap={styleMap}
                        editorState={editorState}
                        handleBeforeInput={(chars, editorState, eventTimeStamp) => { 
                                console.log(this.constructor.name, "handleBeforeInput", chars);
                                this._hasFocus = true; 
                                return(this.handleBeforeInput(chars, editorState, eventTimeStamp));
                            }
                        }
                        handleKeyCommand={
                            (command) => { 
                                this._hasFocus = true;
                                this.handleKeyCommand(command); 
                            }
                        }
                        onLeftArrow={(event) => { console.debug(this.constructor.name, "yyy", "onLeftArrow", event); } }
                        onKey={(event) => { console.debug(this.constructor.name, "yyy", "onKey", event); } }
                        onBlur={(event) => this.onBlur(event) }
                        onFocus={(event) => this.onFocus(event) }
                        onChange={(editorState) => { console.debug(this.constructor.name, "yyy", "onChange"); this.onChange(editorState); } }
                        onTab={(event) => this.onTab(event) }
                        placeholder="bitte Auswertungsanweisungen erfassen ..."
                        ref={this._refEditor}
                        spellCheck={true}
                    />
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
    MARK_CMD: {
        fontWeight: "bold",
        color: "blue"
    },
    MARK_CMD_NOT_RECOGNIZED: {
        fontWeight: "bold",
        color: "red"
    },
    MARK_PARAM: {
        fontWeight: "bold",
        color: "green"
    },
    MARK_PARAM_NOT_RECOGNIZED: {
        fontWeight: "bold",
        color: "red"
    }

};

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = e => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
          className += ' RichEditor-activeButton';
        }
        return (
          <span className={className} onMouseDown={this.onToggle}>
            {this.props.label}
          </span>
        );
    }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};


/*
// OUTTAKES
<BlockStyleControls
editorState={editorState}
onToggle={(blockType) => this.toggleBlockType(blockType)}
/>

<InlineStyleControls
editorState={editorState}
onToggle={(inlineStyle) => this.toggleInlineStyle(inlineStyle) }
/>
*/
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcFamilyMessages_Entry_01 from "./fabbi_crc_messages_entry_01.jsx";
import CrcExt_Scrollbar from "./fabbi_crc_ext_scrollbar.jsx";

export default class HtmlProcessor_Messages extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        var sb = new CrcExt_Scrollbar({
            container: "#messages-div-scrollarea",
            options:   { suppressScrollX: true }
        });

        props.HtmlElementIdPrefix = "messages";
        props.crcExtensions_entryList = [ /* ... props.crcExtensions_entryList, */ sb ];
        
        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcFamilyMessages_Entry_01
        }
        return(props);
    }
    
    onPrepareEntry(id_entry, entry) {

        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;

        var szAddedBy = dataset.added_by;

        var datasethead_user = this.getWfCtrl().getDataPool().getDataEntry(
                szAddedBy + "@" + this.getWfCtrl().m_bundleUsers.getId()
            );

        var dataset_user = null;
        if (datasethead_user === null || datasethead_user === undefined) {
            console.log(this.constructor.name, "onPrepareEntry", "USER NOT FOUND: ", szAddedBy);                
            g_wfCtrl.reportProblem({ 
                problem_id: "missing_data_user_" + szAddedBy, 
                problem_type: "data_missing", 
                data_type: "user", 
                data_id: szAddedBy 
            });
        }
        else {
            dataset_user = datasethead_user["dataset"];
        }

        datasetExtra["data-added_by"] = dataset_user;
    }
}
HtmlProcessor_Messages.ID_DIV_SECTION           = "messages-div-section";
HtmlProcessor_Messages.ID_DIV_ENTRY 	        = "messages-div-entry";
HtmlProcessor_Messages.ID_IMG_AVATAR 	        = "messages-img-avatar";
HtmlProcessor_Messages.ID_TXT_AVATAR_NAME 	    = "messages-txt-avatar-name";
HtmlProcessor_Messages.ID_TXT_AVATAR_INITIALS 	= "messages-txt-avatar-initials";
HtmlProcessor_Messages.ID_IMG_MAIN 	            = "messages-img-main";
HtmlProcessor_Messages.ID_TXT_SENDER 	        = "messages-txt-sender";
HtmlProcessor_Messages.ID_TXT_CONTENT 	        = "messages-txt-content";
HtmlProcessor_Messages.ID_TXT_TIME 	            = "messages-txt-time";


import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcTicker_Entry_01 from "./fabbi_crc_ticker_entry_01.jsx";

export default class HtmlProcessor_Ticker extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }    

    static completeProps(props) {
        super.completeProps(props);
        
        props.HtmlElementIdPrefix = "ticker";

        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcTicker_Entry_01
        }
        return(props);
    }
     
    onPrepareEntry(id_entry, entry) {

        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;
        
        // templateHtml.prepareDatasetHtml(id_entry, dataset, datasetExtra, objOptions);
    }
}

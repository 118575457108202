import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import qryProcessor from "./fabbi_qry_processor.js";
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";

export default class qryProcessor_UserInvitations extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super({ 
            bDirectlyRunProcessQueryAfterInitialization: 
                (props && props.szUserId && props.wfCtrl)?(props.szUserId === props.wfCtrl.getMainUserId()):undefined,
            ...props
        });                
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;      
        
        // console.log(this.getClassName(), "constructor", this.m_szUserId);        
    }

    // Overrides existing!
    getClassName() {
        return("qryProcessor_UserInvitations");    // to FIX the classname!
    }
    
    getMainUserId() {        
        return this.m_szUserId;
    }
    
    getCollectionPath() {
        return('users/' + this.getMainUserId() + '/family_invitations');
    }        
    
    onRun() {                
        let thisClass = this;        
        this.getPrivateDataPool().getDataMap().filter(function(entry) {                        
            return(entry["state"] === "added" && entry["pending_onRun"] === "y");
        }).forEach(function(entry) {
            // dummy
        });
        
        return(super.onRun());
    }        
    
    handleQryProcessorEvent(event) {        
        super.handleQryProcessorEvent(event);
                
        // console.log(this.getClassName(), "handleQryProcessorEvent", "ANGEKOMMEN 1");
        
        // we change, if new messages concerning the family were incoming ...
        if (event.detail.sender.constructor.name === qryProcessor_Objectives.prototype.constructor.name) { 
            // console.log(this.getClassName(), "handleQryProcessorEvent", "ANGEKOMMEN 2");
            
            if (event.detail.szSubType === qryProcessor.EVENT_subType_reportOnRunCompleted) {
                // console.log(this.getClassName(), "handleQryProcessorEvent", "ANGEKOMMEN 3");
                
                let qP = event.detail.sender;
            }
        }    

    }    
}

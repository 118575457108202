import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcSwitch3_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        // props.onInput

        this._arrConfigRadio = [
            { dataValue: "OK", dataTitle: "OK", colorBadge: "green", nameIcon: "check"},
            { dataValue: "IP", dataTitle: "in Progress", colorBadge: "grey", nameIcon: "hourglass_empty"},
            { dataValue: "FD", dataTitle: "Failed", colorBadge: "red", nameIcon: "close"}
        ];
    }

    onChangeChild(reportingChildComponent, changeEvent, options) {
        if (reportingChildComponent instanceof CrcSwitch3_Part_01) {

            if (this.props.onInput) {
                this.props.onInput(
                    reportingChildComponent.getValue(), 
                    reportingChildComponent
                );
            } else {
                console.error(this.constructor.name, "missing <onInput>-function in props!");
            }
        }
    }

    getValue() {        
        return(this.props.dataValue);
        // return(this.getParent().getValue(this, datafieldName));
    }

    render() {
        // console.log(this.constructor.name, "render()");
        return(
            <div style={{ 
                display: "inline-flex", 
                flexWrap: "nowrap", 
                width: "100%" }}>
                <div className="fab-switch-3">
                { 
                    this._arrConfigRadio.map((config, idx) =>  
                        <CrcSwitch3_Part_01 
                            wfCtrl={this.getWfCtrl()} 
                            dataValue={this.getValue()} 
                            key={idx}
                            parent={this} 
                            config={config}/>
                    )
                }         
                </div>
            </div>
        );
    }
}

class CrcSwitch3_Part_01_Config {
    constructor(options) {
        this.dataValue = "no value set!";
        this.dataTitle = null;
        this.className = null;
        this.colorBadge = undefined;
        this.colorIcon = undefined;
        this.colorBackgroundChecked = undefined;
        this.nameIcon = "check";

        Object.assign(this, options);
        // this = { ...options };

        if (!this.dataTitle) this.dataTitle = this.dataValue;
        if (!this.className) this.className = this.dataValue;
        if (!this.colorBadge && !this.colorIcon) this.colorBadge = "red";
        if (!this.colorBadge) this.colorBadge = this.colorIcon;
        if (!this.colorIcon) this.colorIcon = this.colorBadge;
        if (!this.colorBackgroundChecked) this.colorBackgroundChecked = this.colorBadge;
    }
}

class CrcSwitch3_Part_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    
        this._config = new CrcSwitch3_Part_01_Config(this.props.config);
    }

    getConfig() {
        return(this._config);
    }

    getValue() {
        return(this.getConfig().dataValue);
    }

    isChecked() {
        // console.log(this.constructor.name, this.props.dataValue, "===", this.getValue());
        return(this.props.dataValue === this.getValue());
    }

    /* Called, wenn a radio-button was clicked ... IT'S BEING SET ACTIVE!
    */
    onChange(changeEvent) {
        if (changeEvent) {
            this.getParent().onChangeChild(this, changeEvent);
        }
        return(false);
    }

    render() {
        // console.log(this.constructor.name, "render()", "dataValue", this.getConfig().dataValue);
        return( 
            <React.Fragment>
                <input 
                    className={ this.getConfig().className } 
                    name={ this.getExplicitIdDOMOfParent(true) }
                    id={ this.getIdDOM() }
                    type="radio"
                    checked={ this.isChecked() } 
                    onChange={ (c) => { this.onChange(c) } }
                    />
                <label 
                    htmlFor={ this.getIdDOM() } 
                    style={{ 
                        width: "auto", 
                        background: this.isChecked()?this.getConfig().colorBackgroundChecked:undefined 
                    }}
                > 
                <span className="hide-on-small-only">{this.getConfig().dataTitle}</span>
                <span className={ "badge pill " + this.getConfig().colorBadge} style={{ width: "auto", height: "2em" }}>
                    <i className="material-icons" style={{ color: this.getConfig().iconColor }}>
                        {this.getConfig().nameIcon}</i>
                    </span>
                </label> 
            </React.Fragment>
        );
    }         
}
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import qryProcessor_Family from "./fabbi_qry_processor_family.js";

export default class qryProcessor_UserFamilyReferences extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;        

        // console.log(this.getClassName(), "constructor", this.m_szUserId);        
    }

    getClassName() {
        return("qryProcessor_UserFamilyReferences");
    }
    
    getMainUserId() {        
        return this.m_szUserId;
    }
        
    getCollectionPath() {
        return('users/' + this.getMainUserId() + '/family_references');
    }
    
    onRun() {
        // go through freshly deleted entries
        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["state"] === "deleted" && entry["pending_onRun"] === "y");            
        }).forEach((entry) => {          
            var szFamilyId = entry["id"];
            var qryP_Family = this.getWfCtrl().m_bundleFamilies.getBundleQP(szFamilyId, "trigger");
            console.log(this.getClassName(), "onRun", "detaching Family-Processor", qryP_Family);
            this.getWfCtrl().m_bundleFamilies.detach(qryP_Family); //             
            console.log(this.getClassName(), "onRun", "detached Family-Processor", qryP_Family);
            qryP_Family.destroy(); // destroy the Bundle Member
            
            console.log(this.getClassName(), "onRun", "detached Family-Processor" , "removing child QueryProcessors concerning", szFamilyId);
            this.getWfCtrl().m_bundleFamilies.removeChildQPs(".*_" + szFamilyId);
        });        
        
        // go through new entries
        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["state"] === "added" && entry["pending_onRun"] === "y");            
        }).forEach((entry) => {
            console.log(this.getClassName(), "onRun", "attaching Family-Processor to FamilyBundle", "id", entry["id"]);
            this.getWfCtrl().m_bundleFamilies.attach(
                new qryProcessor_Family({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: this, 
                    szTriggerId: entry["id"],
                    szFamilyId: entry["id"]
                })
            );
        });
        // mark as "ready to go" (just for the case, nothing was intendedly attached ...)
        this.getWfCtrl().m_bundleFamilies.setInitialBundlePrepared();
        
        return(super.onRun());
    }
}

import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcUsers_Entry_01 from "./fabbi_crc_users_entry_01.jsx";
import CrcUsers_Entry_02 from "./fabbi_crc_users_entry_02.jsx";

export default class HtmlProcessor_Users extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        props.HtmlElementIdPrefix = "users";

        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcUsers_Entry_01
        }
        
        props.portalsForActiveEntry = [
            { 
                func_getActiveIdEntry: function() { return(this.getMainUserId()) }.bind(props.wfCtrl),
                id_dom: "div_user_active_for_menu",
                crcPrototype_entry: CrcUsers_Entry_02 
            }
        ];
        return(props);
    }
    
    onPrepareEntry(id_entry, entry) {
        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;
    }
}
HtmlProcessor_Users.ID_DIV_SECTION           = "users-div-section";
HtmlProcessor_Users.ID_DIV_ENTRY             = "users-div-entry";
HtmlProcessor_Users.ID_IMG_MAIN              = "users-img-main";
HtmlProcessor_Users.ID_SRC_IMAGE             = "users-src-image";
HtmlProcessor_Users.ID_TXT_NAME              = "users-txt-name";
HtmlProcessor_Users.ID_TXT_INFO              = "users-txt-info"           ;
HtmlProcessor_Users.ID_TXT_TIME              = "users-txt-time"           ;
HtmlProcessor_Users.ID_TXT_MSG_COUNT         = "users-txt-msg-count"      ;
HtmlProcessor_Users.ID_TXT_MSG_COUNT_NEW     = "users-txt-msg-count-new"  ;

import qryProcessorBundle_CloudFirestore from "./fabbi_qry_processor_bundle_cloudfirestore.js";
import HtmlProcessor_Users from "./fabbi_htmlprocessor_users.js";

export default class qryProcessorBundle_User extends qryProcessorBundle_CloudFirestore {
    constructor(props) {
        super({...props, htmlProcessorPrototype: HtmlProcessor_Users });
    }    

    getClassName() {
        return("qryProcessorBundle_User");    // to FIX the classname!
    }

    getCollectionPath() {
        return("users");
    }
    
    onNewDataIncomingCompleted() {        
        // console.log(this.getClassName(), "onNewDataIncomingCompleted");        
        return(super.onNewDataIncomingCompleted());
    }
    
    onNewDataProcessingCompleted() {        
        // console.log(this.getClassName(), "onNewDataProcessingCompleted");
        return(super.onNewDataProcessingCompleted());
    }
    
    onRunCompleted() {                
        // console.log(this.getClassName(), "onRunCompleted");
        return(super.onRunCompleted());
    }
}
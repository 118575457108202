import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import ControlledReactComponentExtensionParent from "./fabbi_controlled_react_component_extension_parent.jsx"; 
import CrcExt_GroupOrganizerItem from "./fabbi_crc_ext_group_organizer_item.jsx";

export default class CrcExt_GroupOrganizer extends ControlledReactComponentExtensionParent {

    constructor(props) {
        super(props);

        // this.props.underlyingCRC
        this.m_handleTimeout = undefined;
        this.m_queueToDo = [];
        this.m_isListDirty = false;

        this.m_childExtDirty = undefined;

        this.m_func_writeSortData = undefined;
        this.m_func_readSortDataObject = undefined;
    }

    setFunc_writeSortData(newFunc_writeSortData) {
        this.m_func_writeSortData = newFunc_writeSortData;
    }

    setFunc_readSortDataObject(newFunc_readSortData) {
        this.m_func_readSortDataObject = newFunc_readSortData;
    }

    underlyingChildComponentDidMount(underlyingCRC) {
        // we can do our startup-doings here
        super.underlyingChildComponentDidMount(underlyingCRC);
        this._setDirty(underlyingCRC);
    }

    underlyingChildComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot) {
        super.underlyingChildComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot);
        this._setDirty(underlyingCRC);
    }

    underlyingComponentDidMount(underlyingCRC) {
        // we can do our startup-doings here
        super.underlyingComponentDidMount(underlyingCRC);
    }

    // must return an object with ALL sort-relevant data
    readSortDataObject(underlyingCRC) {
        if (this.m_func_readSortDataObject) {
            return(this.m_func_readSortDataObject(underlyingCRC));   
        } 
        return(false);
    }

    writeSortData(underlyingCRC, objSortData) {
        if (this.m_func_writeSortData) {
            return(this.m_func_writeSortData(underlyingCRC, objSortData));
        }
        return(false);
    }

    _readSortDataObject(underlyingCRC) {
        return(this.readSortDataObject(underlyingCRC));
    }

    _writeSortData(underlyingCRC, objSortData) {
        // console.log(this.constructor.name, "writeSortData", underlyingCRC, objSortData);
        if (this.m_func_writeSortData) {
            return(this.m_func_writeSortData(underlyingCRC, objSortData));
        } else {
            return(this.writeSortData(underlyingCRC, objSortData));
        }
    }

    _setDirty(childExt_or_childCRC) {
        // console.log(this.constructor.name, "_setDirty", childExt);
        var childExt;

        if (childExt_or_childCRC instanceof ControlledReactComponent) {
            childExt = childExt_or_childCRC.getExtensionOfPrototype(CrcExt_GroupOrganizerItem)
        } else {
            childExt = childExt_or_childCRC;
        }

        if (!childExt || !this.m_childExtDirty || childExt._isUnderlyingSiblingBefore(this.m_childExtDirty)) {
            this.m_childExtDirty = childExt;
        }

        if (!this.m_isListDirty) {
            this.m_isListDirty = true;
            // console.log(this.constructor.name, "_setDirty", "_startNextTimeout");
            this._startNextTimeout(1);
        }
    }

    _isProcessingToDoQueue() {
        return(this.m_queueToDo.length > 0);
    }

    _startNextTimeout(millis) {

        if (this._isProcessingToDoQueue()) return(true);

        if (this.m_handleTimeout) {
            clearTimeout(this.m_handleTimeout);
            this.m_handleTimeout = undefined;
        }

        this.m_handleTimeout = setTimeout(
            () => {
                  if (!this._checkQueue()) {
                      // this.m_handleTimeout = null;
                      // this._startNextTimeout(100);
                  }
            },
            millis
        );
        return(true);
    }

    _checkQueue() {
        // console.log(this.constructor.name, "_checkQueue", this.m_queueToDo);
        if (!this._isProcessingToDoQueue()) {
            this._refresh();
            
            // returns FALSE to indicate an empty queue, so that the standard check-routine can
            // be initiated
            return(this._isProcessingToDoQueue());
        }

        let qdAction = this.m_queueToDo.shift(); // get first entry out of queue
        // var tmpFnc = ;
        setTimeout(
            function() {
                qdAction.fnc(); // run action
                this._checkQueue();
            }.bind(this),
            qdAction.nMsec
        );
        return true;
    }

    _addToToDoQueue(nMsec, fnc) {
        var qdAction = {
            nMsec: nMsec,
            fnc: fnc
        };
        // console.log(this.constructor.name, "_addToToDoQueue", "ADD", qdAction);
        this.m_queueToDo.push(qdAction);

        // console.log(this.constructor.name, "_addToToDoQueue", "new length", this.m_queueToDo.length, "action added", qdAction);

        if (this.m_queueToDo.length === 1) {
            // console.log(this.constructor.name, "initiating _checkQueue");
            this._checkQueue();
        }
    }

    _onDragstart(dragExt, dragevent) {
        this.m_extChildBeingDragged = dragExt;
        // console.log(this.constructor.name, "_onDragstart", "dragExt", dragExt, "dragevent", dragevent);
    }

    _onDragover(dragExt, dragevent) {
        // console.log(this.constructor.name, "_onDragover", "dragExt", dragExt, "dragevent", dragevent);
        dragevent.preventDefault();
    }

    _onDragend(dragExt, dragevent) {
        // console.log(this.constructor.name, "_onDragend", "dragExt", dragExt, "dragevent", dragevent);
        this.m_extChildBeingDragged = undefined;
    }

    _onDrop(dragExt, dragevent) {
        // console.log(this.constructor.name, "_onDrop", "dragExt", dragExt, "dragevent", dragevent);

        // get temporary SortDataObject and arrange a "write back" operation to make
        // the temporary change durable ...
        dragExt._writeSortData(dragExt._getSortDataObject());
    }

    _onDragenter(overExt, dragevent) {
        var actuExt = this.m_extChildBeingDragged;

        // console.log(this.constructor.name, "_onDragenter", "extChild", extChild, "dragevent", dragevent);
        // console.log(this.constructor.name, "_onDragenter", "Start", this.m_isListDirty, overExt === actuExt); // "targetId", dragevent.target.id, "currentTargetId", dragevent.currentTarget.id, "srcElementId", dragevent.srcElement.id);

        if (!actuExt) return;
        if (this.m_isListDirty) return;
        if (overExt === actuExt) return;
        
        var overDOM = overExt.getUnderlyingDOMElement();
        if (overDOM.classList.contains("fab-swapping")) return;

        // console.log(this.constructor.name, "_onDragenter", "Step1", "actuExt", actuExt);

        var nextDOM = overDOM.nextElementSibling,
            nextExt = this.getExtension(nextDOM, CrcExt_GroupOrganizerItem);
        var prevDOM = overDOM.previousElementSibling,
            prevExt = this.getExtension(prevDOM, CrcExt_GroupOrganizerItem);

        // if there are no neighours ... we can't do anything
        if (!nextExt && !prevExt) return;
    
        // console.log(this.constructor.name, "_onDragenter", "Step2");

        if (actuExt._compareTo(overExt) <= 0) {         
            actuExt._setTemporarySortPosition_Between(prevExt, overExt);
        } else {
            actuExt._setTemporarySortPosition_Between(overExt, nextExt);
        }

        // console.log(this.constructor.name, "_onDragenter", "Step3");
        /*
        dataset_chabuf.ts_sort = ts;                
        this.m_wfCtrl.setDragData("dataset_chabuf", dataset_chabuf);
        // console.log("DRAGENTER", dragevent, "dataset_chabuf", dataset_chabuf);
                
        if (elemTarget) {                    
            if (FabStd.isElementInView(elemTarget, true) === false) {                        
                // smoothly scroll the target into view !
                elemTarget.scrollIntoView({ behavior: "smooth", block: bToTop?"start":"end" });
            }
        }
        */    

        console.log(this.constructor.name, "_onDragEnter", "END");
    }

    _refresh() {
        // console.log("_refresh", this);
        if (this.m_mapChildren.length <= 0) {
            this.m_isListDirty = false; // es gibt nichts zum umsortieren, denn die Liste ist leer
            return(true);
        }

        // console.log(this.constructor.name, "_refresh", "start");

        var actuDOM, nextDOM;
        var nextExt, actuExt;

        actuDOM = this.m_childExtDirty?this.m_childExtDirty.getUnderlyingDOMElement():undefined;
        this.m_childExtDirty = undefined;
        // if actuDOM is undefined ...
        if (!actuDOM) {
            // take the first childExt for the job!
            var szId_childExt = this.m_mapChildren.getFirstIdEntry();
            actuDOM = this.m_mapChildren.get(szId_childExt).getUnderlyingDOMElement();
            // if actuDOM is defined we find the upmost sibling
            while (actuDOM && actuDOM.previousElementSibling) {
                actuDOM = actuDOM.previousElementSibling;
            }
        } else {
            // we have a dirty childExt ? very good ... then we use the sibling displayed before it ...
            if (actuDOM.previousElementSibling) {
                actuDOM = actuDOM.previousElementSibling;
            }
        }

        // if we couldn't find an actuDOM
        if (!actuDOM) {
            // we can't refresh anything more ... so returning true
            return(true);
        }

        // console.log(this.constructor.name, "_refresh", "preparations", "actuDOM", actuDOM, "actuExt", actuExt);

        // var nCount = 1;
        do {
            if (!nextDOM) {
                // we have a DOM-element and want to look for the appropriate
                // Extension
                actuExt = this.getExtension(actuDOM, CrcExt_GroupOrganizerItem);
            } else {
                actuDOM = nextDOM;
                actuExt = nextExt;
            }

            // console.log(this.constructor.name, "_refresh", "run", nCount, "actuDOM", actuDOM, "actuExt", actuExt);

            if (!actuDOM.nextElementSibling) {
                if (!nextDOM) return true;
                break;
            }
            nextDOM = actuDOM.nextElementSibling;
            nextExt = this.getExtension(nextDOM, CrcExt_GroupOrganizerItem);
            
            // ++nCount;
        } while (
            actuExt._compareTo(nextExt) >= 0 
        );

        if (actuExt._compareTo(nextExt) < 0) {
            this.m_isListDirty = true;

            var tempDOM;
            var tempExt;
            let arr = [];

            arr.push(actuExt);
            arr.push(nextExt);

            while (true) {
                tempDOM = nextDOM.nextElementSibling;
                if (tempDOM === null) break;

                tempExt = this.getExtension(tempDOM, CrcExt_GroupOrganizerItem);

                if (actuExt._compareTo(tempExt) >= 0)
                    break;

                nextDOM = tempDOM;
                nextExt = tempExt;
                arr.push(nextExt);
            }

            let elemFrom = actuDOM;
            let elemTo = nextDOM;

            this._addToToDoQueue(0, () => {
                if (!elemTo || !elemFrom || !elemFrom.parentNode) {
                    return;
                }

                var xMove = elemTo.offsetLeft - elemFrom.offsetLeft;
                var yMove = elemTo.offsetTop - elemFrom.offsetTop;

                var durationSwap = 500;
                var transformPropName = FabStdBro.getPropertyName_Transform();
                var trans0 = {};
                var options0 = { easing: "ease", duration: durationSwap, iterations: 1 };

                elemFrom.classList.add("fab-swapping");
                elemTo.classList.add("fab-swapping");
                elemFrom.parentNode.insertBefore(elemTo, elemFrom);

                var szTransCmd_0 = "translate(0px, 0px)";
                var szTransCmd_1 = `translate(${+xMove}px, ${+yMove}px)`;
                var szTransCmd_2 = `translate(${-xMove}px, ${-yMove}px)`;
                elemTo.style[transformPropName] = szTransCmd_1;
                elemFrom.style[transformPropName] = szTransCmd_2;
                
                trans0 = {};
                // Attention! The "array-form" of the translate-commands ... is understood
                // by firefox, but not: CHROME, EDGE
                trans0.transform = szTransCmd_0;
                // trans0["-webkit-transform"] = trans0.tramsform;
                // trans0.WebkitTransform = trans0.transform;
                // trans0.webkitTransform = trans0.transform;

                // console.log("TRANS", trans0);

                elemFrom.animate(trans0, options0)
                    .finished.then(() => { 
                        try { elemFrom.style[transformPropName] = szTransCmd_0; } catch(e) {} 
                    });

                elemTo.animate(trans0, options0)
                    .finished.then(() => { 
                        try { elemTo.style[transformPropName] = szTransCmd_0; } catch(e) {} 
                    });       
          });
          this._addToToDoQueue(550, () => {
              try {
                elemFrom.classList.remove("fab-swapping");
                elemTo.classList.remove("fab-swapping");
              } catch(e) {
                  // no problem
              }
          });
        } 
            
        // if "isProcessing" (means: is NOT empty)
        this.m_isListDirty = this._isProcessingToDoQueue();

        // console.log(this.constructor.name, "_refresh", "ended", this.m_queueToDo.length);
        return(true);
    }

    // 
    newChildExtension(underlyingChildCRC) {
        var extChild = new CrcExt_GroupOrganizerItem();
        return(extChild);
    }

    setupExtension(underlyingCRC, underlyingCRC_Ext) {

        /*
        if (underlyingCRC.m_queueToDo !== undefined) {
            if (underlyingCRC.m_queueToDo.length > 0) {
                return true;
           }
        }
        */

        // Overwrite Existing Timer means: we kill the active Timer and establish a new Timer
        // if (bOverwriteExistingTimer) {
            if (this.m_handleTimeout) {
                clearTimeout(this.m_handleTimeout);
                this.m_handleTimeout = undefined;
            }
        // }

        this._startNextTimeout(100);
      // }
    }

    _isBetween(extToCheck, extBorderA_inc, extBorderB_exc) {
        if (!extToCheck || !extBorderA_inc || !extBorderB_exc) return(false);
        
        if (extBorderA_inc._compareTo(extBorderB_exc) < 0) {
            if (
                extToCheck._compareTo(extBorderA_inc) >= 0 &&
                extToChecl._compareTo(dsvB_exc) < 0
              ) {
                return true;
            }
        } else {
            if (
                extToCheck._compareTo(extBorderB_exc) > 0 &&
                extToCheck._compareTo(extBorderA_inc) <= 0
              ) {
                return true;
            }
        }
    }

    stdFuncOnDragenter(dragevent) { 
        var overDOM = dragevent.currentTarget,
            overExt = this.getExtension(overDOM);
        var nextDOM = overDOM.nextElementSibling,
            nextExt = this.getExtension(nextDOM);
        var prevDOM = overDOM.previousElementSibling,
            prevExt = this.getExtension(prevDOM);

        // the element being dragged ... 
        var dragDOM = dragevent.target,
            dragExt = this.getExtension(dragDOM);

        var szIdHtml            = this.m_wfCtrl.getDragData("id_dom");           // id of the html-Element being dragged
 
        // ??? ist das so richtig ?
        if (dragDOM.id === szIdHtml) {
            return;
        }
        if (overDOM.classList.contains("fab-swapping")) {
            // console.log("DRAGENTER 2", "currentTarget contains fab-swapping!"); 
            return;
        }

        if (this._isListDirty() === true) {
            // console.log("DRAGENTER", "List is dirty! Action suspended!"); 
            return;
        }                
         
        // if there are no neighours ... we can't do anything
        if (!nextExt && !prevExt) {
            // console.log("DRAGENTER", "NO NEIGHBOURS - nothing to do");
            return; 
        }

        // console.log("DRAGENTER", "STEP 2"); 
        if (dragExt._compareTo(overExt) <= 0) {         
            actuExt._setTemporarySortPosition_Between(prevExt, overExt);
        } else {
            actuExt._setTemporarySortPosition_Between(overExt, nextExt);
        }
        if (overDOM) {                    
            if (FabStd.isElementInView(overDOM, true) === false) {                        
                // smoothly scroll the target into view !
                overDOM.scrollIntoView({ behavior: "smooth", block: bToTop?"start":"end" });
            }
        }
    }
}
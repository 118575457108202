import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import CrcSwitch3_01 from "./fabbi_crc_switch3_01.jsx";
import { Timestamp } from "firebase/firestore";

export default class CrcFamilyMissionObjectives_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        // console.log(this.constructor.name, "componentDidUpdate", this);
    }

    getRadioSwitchValue() {
        try {
            // console.log(this.constructor.name, "CRC", "Radioswitchvalue", this.getEdb().dataset.status);
            return(this.getEdb().dataset.status);   
        } catch (error) {
            return(undefined);
        }
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szName = dataset.name;
        var szTxt = dataset.comment;
        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szSort = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_sort, "");

        return(
            <li id={this.getIdDOM()} draggable="true" className="-card group-organizer" 
                style={{
                    boxShadow: "0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2)",
                    margin: "1rem", // ".5rem 0 1rem 0",              
                    transition: "box-shadow .25s",
                    // borderRadius: "2px",
                    backgroundColor: "#fff",
                    overflow: "hidden"
                }}>
                <div>
                    <div className="-card-default" style={{ display: "flex", margin: "1rem" }}>
                        <span className="-col" style={{ flexGrow: 1 }}>
                            {szName}
                        </span>
                        <span className="hidden">
                            {szTxt}
                        </span>
                        <span className="-col fab-time-01" style={{ marginLeft: "1rem", color: "red" }}>
                            {szAdded + " " + szSort }
                        </span>
                    </div>
                </div>
                <div style={{ display: "inline-flex", flexWrap: "nowrap", width: "100%" }}>
                    <CrcSwitch3_01 
                        wfCtrl={this.getWfCtrl()}
                        dataValue={this.getRadioSwitchValue()}
                        onInput={(newDataValue) => {
                            this.getWfCtrl().setObjectiveStatus(this.getIdEntry(), newDataValue);
                        }}
                        id_dom={this.getIdDOM() + "sw3"} 
                        parent={this} />
                    <div className="col" style={{flexGrow: 1}}>
                        <i className="material-icons" style={{ float: "right", cursor: "pointer" }} 
                            onClick={(e) => this.getWfCtrl().editObjective2(this.getIdEntry())}
                            >
                            edit
                        </i>
                    </div>
                </div>
            </li>  
        );
    }
}

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import { Timestamp } from "firebase/firestore";

export default class CrcFamilyMessages_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidMount() {
        super.componentDidMount();

        if (this.state.new) {
            // if (this.el.getElementsByClassName("__sub")[0].style.animationIterationCount > 0
            FabStdBro.addAnimation("dummyAnim", "0% { opacity: 100%;} 100% { opacity: 0%;}");
            // this.el.getElementsByClassName("__sub")[0].style.animationTimingFunction = "linear";

            var htmlElem = this.getDOMElement();

                // animation-name               animationname
                // animation-duration           5s
                // animation-timing-function    linear
                // animation-delay              5s
                // animation-iteration-count    5
                // animation-direction:         normal/alternate
                // animation-fill-mode          forwards
                // animation-play-state         running
                htmlElem.style.animation = "dummyAnim" + " " + "0.5s" + " linear 0s 10 alternate forwards running";
        }        
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szTxt = dataset.content;
        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var dataAddedBy = datasetExtra["data-added_by"];
        var szSenderImgSrc = !!dataAddedBy?dataAddedBy.image_src:"";
        var szSenderName = !!dataAddedBy?dataAddedBy.name:"";
        var szClassChatSide = "chat-right";
        var bChatRight = true;
        if (dataset.added_by === this.props.wfCtrl.getMainUserId()) {
            szClassChatSide = "chat-left";
            bChatRight = false;
        }

        var fnDragstart = undefined; // this.props.htmlProcessor.stdFuncOnDragstart(this.getIdEntry(), dataset);
        var fnDragend = undefined; // this.props.htmlProcessor.stdFuncOnDragend(this.getIdEntry(), dataset);

        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }
        
        // style={{ backgroundColor: lightblue }}
        // style={{color:red}}
        return(
            <div id={this.getIdDOM()} 
                className={"-chat CSS_DELAY "} 
                style={{ 
                    display: "flex", 
                    margin: ".5rem 1rem",
                    justifyContent: bChatRight?"flex-end":"flex-start" 
                }}
                draggable={true} 
                onDragStart={fnDragstart} 
                onDragEnd={fnDragend}
                >
                <div className="-chat-avatar" style={{order: bChatRight?2:0}}>
                    <CrcImageOrInitials_01 
                        wfCtrl={this.props.wfCtrl} 
                        image_src={szSenderImgSrc} 
                        long_name={szSenderName}/>
                </div>
                <span style={{
                    order: (bChatRight?1:0),                  
                    boxSizing: "border-box",
                    width: 0,
                    height: 0,
                    marginTop: "10px",
                    content: '',
                    transform: bChatRight?"rotate(225deg)":"rotate(45deg)",
                    transformOrigin: bChatRight?"2px 8px":"12px 18px",
                    border: "7px solid transparent",
                    borderBottomColor: bChatRight?"#2196f3":"#fff",
                    borderLeftColor: bChatRight?"#2196f3":"#fff",
                    boxShadow: "-2px 3px 4px -3px rgba(0, 0, 0, .4)"
                }}></span>
                <div className="-chat-body"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: bChatRight?"flex-end":"flex-start"
                    }}
                >
                    <div className="-chat-text" 
                        style={{
                            fontSize: ".85rem",
                            color: "#616161",                        
                            flexDirection: "row-reverse",
                            marginBottom: '0.2rem'
                        }}
                    >
                        <p style={{
                                padding: ".5rem 1rem",
                                color: bChatRight?"#fff":"#757575",
                                borderRadius: "5px",
                                backgroundColor: bChatRight?"#2196f3":"#fff",
                                boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, .14)"
                        }}>
                            {szTxt}
                        </p>
                    </div>
                    <div className="fab-time-01">
                        <p style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                            {szAdded}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class ControlledReactComponentExtension {
    constructor(props) {
        this.props = { ...props };

        // take parentExtension-Object ... if given
        this.__parentExtension = this.props.parentExtension;
    }

    getParentExtension() {
        if (!this.__parentExtension) {
            console.warn(this.constructor.name, "Please setup connection to parentExtension using 'setParentExtension' or via the props-property 'parentExtension'!");
        } 

        return(this.__parentExtension);
    }

    setParentExtension(parentExtension) {
        if (!this.__parentExtension) {
            this.__parentExtension = parentExtension;
        }
    }

    getUnderlying() {
        // return Underlying CRC ... which was extended this extension
        return(this.m_underlyingCRC);
    }

    _setUnderlying(underlyingCRC) {
        this.m_underlyingCRC = underlyingCRC;
    }

    getUnderlyingDOMElement() {
        if (!this.m_underlyingCRC) return(undefined);
        return(this.m_underlyingCRC.getDOMElement());
    }

    underlyingComponentDidMount(underlyingCRC) {
        // we can do our startup-doings here
        if (!this.m_underlyingCRC) {
            this._setUnderlying(underlyingCRC);
        }
    }

    // we can do something here ...
    underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot) {
        // console.log(this.constructor.name, "underlyingComponentDidUpdate", underlyingCRC);
    }

    underlyingComponentWillUnmount(underlyingCRC) {
        // we can do some cleanup here
    }

    // for override
    extend(underlyingCRC) {
    }

    getCRC(id_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself) {
        if (!this.m_underlyingCRC) return(undefined);

        // console.log(this.constructor.name, "getExtension-getCRC", id_or_htmlElement);
        return(this.m_underlyingCRC.getCRC(id_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself));
    }

    getExtension(id_or_htmlElement_or_CRC, prototypeExtension) {
        if (!id_or_htmlElement_or_CRC) {
            console.log(this.constructor.name, "getExtension", id_or_htmlElement_or_CRC, prototypeExtension, "UNDEFINED GIVEN");
            return(undefined);
        }

        if (id_or_htmlElement_or_CRC instanceof HTMLElement || isString(id_or_htmlElement_or_CRC)) {
            var old = id_or_htmlElement_or_CRC;
            id_or_htmlElement_or_CRC = this.getCRC(id_or_htmlElement_or_CRC, true);
            // console.log(this.constructor.name, "getExtension", old, prototypeExtension, "fetched CRC", id_or_htmlElement_or_CRC);
        }

        if (id_or_htmlElement_or_CRC instanceof ControlledReactComponent) {
            // console.log(this.constructor.name, "getExtension", id_or_htmlElement_or_CRC, prototypeExtension, "return", id_or_htmlElement_or_CRC, id_or_htmlElement_or_CRC.getExtensionOfPrototype(prototypeExtension?prototypeExtension:this));
            return(id_or_htmlElement_or_CRC.getExtensionOfPrototype(prototypeExtension?prototypeExtension:this));
        } else {
            return(undefined);
        }
    }

}
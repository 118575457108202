export default class timeoutCtrl {
    constructor() {
        this._handleAutoAbortTimeout = null;
    }

    abort() {
        this.clearAutoAbortTimeout();
    }

    clearAutoAbortTimeout() {
        if (this._handleAutoAbortTimeout) clearTimeout(this._handleAutoAbortTimeout);
        this._handleAutoAbortTimeout = null;
    }

    setAutoAbortTimeout(callback, ms) {
        this.clearAutoAbortTimeout(); 
        this._handleAutoAbortTimeout = setTimeout(callback, ms);
    }
}
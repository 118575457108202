import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';

export default class CrcInput_01 extends ControlledReactComponent {
    componentDidMount() {
        super.componentDidMount();

        this._setupEventHandlers();

        if (this.props.defaultValue && this.props.defaultValue != "") {
            this.getDOMElement_Label().classList.add("active");
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._removeEventHandlers();
    }

    _setupEventHandlers() {
        this._handleInputBound = (event) => this.onInput(event);
        this._handleFocusBound = (event) => this.onFocus(event);
        this._handleKeyDownBound = (event) => this.onKeyDown(event);

        this.getDOMElement_InputField().addEventListener("keydown", this._handleKeyDownBound);
        this.getDOMElement_InputField().addEventListener("input", this._handleInputBound);
        this.getDOMElement_InputField().addEventListener("focus", this._handleFocusBound);
    }

    onFocus(event) {
        if (this.props.onFocus) { return(this.props.onFocus(this, event)); }
    }

    onInput(event) {
        if (this.props.onInput) { return(this.props.onInput(this, event)); }
    }

    onKeyDown(event) {
        console.log(this.constructor.name, "KeyDown", event);
        if (this.props.onKeyDown) { return(this.props.onKeyDown(this, event)); }
    }

    _removeEventHandlers() {
        if (this._handleKeydownBound) this.getDOMElement_InputField().removeEventListener("keydown", this._handleKeyDownBound); 
        if (this._handleInputBound) this.getDOMElement_InputField().removeEventListener("input", this._handleInputBound);
        if (this._handleFocusBound) this.getDOMElement_InputField().removeEventListener("focus", this._handleFocusBound);
    }  

    getDOMElement_Label() {
        return(document.getElementById(this.getIdDOM_Label()));    
    }

    getDOMElement_InputField() {
        return(document.getElementById(this.getIdDOM_InputField()));    
    }

    getIdDOM_InputField() {
        return(this.getIdDOM() + "_if");
    }


    getIdDOM_Label() {
        return(this.getIdDOM() + "_lb");
    }

    getDbValue() {
        return(this.getDOMElement_InputField().value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        if (this.props.defaultValue && prevProps.defaultValue && prevProps.defaultValue !== this.props.defaultValue) {
            this.getDOMElement_InputField().value = this.props.defaultValue;
        }
    }

    render() {        
        var defaultClassName = "input-field";
        var cN = defaultClassName;

        // console.log(this.constructor.name, "InputProps", this.props.InputProps);

        return(
            <div 
                ref={this.ref}
                className={cN} 
                id={this.getIdDOM()}
                style={{...this.props.style}}
                >
                <input  
                    className={ this.props.valueIsValid?"valid":"" }
                    placeholder={this.props.placeholder} 
                    id={this.getIdDOM_InputField()}
                    type={this.props.type?this.props.type:"text"} 
                    defaultValue={(this.props.defaultValue)?this.props.defaultValue:""} 
                    required
                    {...this.props.InputProps}
                    />
                <label id={this.getIdDOM_Label()} htmlFor={this.getIdDOM_InputField()}>{this.props.placeholder}</label>
            </div>    
        );
    }    
}
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import qryProcessor_User from "./fabbi_qry_processor_user.js";

export default class qryProcessor_UserUsersAuthorized extends qryProcessor_CloudFirestore {
    constructor(props) {
        super(props);
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;        
        
        // console.log(this.getClassName(), "constructor", this.m_szUserId);        
        
        this.init();
    }

    getClassName() {
        return("qryProcessor_UserUsersAuthorized");    // to FIX the classname!
    }
    
    getMainUserId() {        
        return this.m_szUserId;
    }
    
    getCollectionPath() {
        return('users/' + this.m_szUserId + '/users_authorized');
    }
    
    onRun() {

        // go through freshly deleted entries
        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["state"] === "deleted" && entry["pending_onRun"] === "y");            
        }).forEach((entry) => {          
            var szUserId = entry["id"];
            var qryP_User = this.getWfCtrl().m_bundleUsers.getBundleQP(szUserId, "trigger");
            
            console.log(this.getClassName(), "onRun", "detaching User-Processor", qryP_User);
            this.getWfCtrl().m_bundleUsers.detach(qryP_User); //             
            
            console.log(this.getClassName(), "onRun", "detached User-Processor", qryP_User);
            qryP_User.destroy(); // destroy the Bundle Member
            
            console.log(this.getClassName(), "onRun", "detached User-Processor" , "removing child QueryProcessors concerning", szUserId);
            this.getWfCtrl().m_bundleUsers.removeChildQPs(".*_" + szUserId);            

            console.log(this.getId(), "onRun", "DELETED fron BundleUsers", szUserId);
        });                        

        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["state"] === "added" && entry["pending_onRun"] === "y");            
        }).forEach((entry) => {                   
            // console.log(thisClass.getClassName(), "adding qryProcessor_User for", entry["id"]); 
            var szUserId = entry["id"];              
            if (!this.getWfCtrl().m_bundleUsers.getBundleQP(szUserId, "trigger")) {
                // Attaching user
                console.log(this.getClassName(), "attaching user to mainbundle", szUserId);
                this.getWfCtrl().m_bundleUsers.attach(
                    new qryProcessor_User({
                        wfCtrl: this.getWfCtrl(), 
                        triggering_qryProcessor: this, 
                        szTriggerId: szUserId,
                        szUserId: szUserId
                    })
                );
            }
        });
        
        return(super.onRun());
    }
}

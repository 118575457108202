import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class Lib_GdtBasis {
    constructor() {        
    }

    gdtCumulate(gdt, arrIdxCol) {
        if (!arrIdxCol) arrIdxCol = FabStd.buildIntegerArrayFromTo(1, gdt.getNumberOfColumns()-1);

        var val, valLst;
        var p;
        var cnt;
        var funcAggStd;
        arrIdxCol.forEach((idxCol) => {
            valLst = null;
            cnt = 0;
            p = gdt.getColumnProperties(idxCol);
            funcAggStd = p.funcAggStd;

            for (var idxRow = 0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
                val = gdt.getValue(idxRow, idxCol);
                if (val !== null) {
                    if (valLst === null) {
                        valLst = 0;
                    }
                    ++cnt;
                    if (cnt > 1) {
                        valLst = funcAggStd([valLst, val]);
                    }
                }
                if (valLst !== null) gdt.setValue(idxRow, idxCol, valLst);
            }
        });

        return(gdt);
    }

    gdtUnion(gdt, gdtToAdd) {
        if (!gdt) return(gdtToAdd.clone());

        if (gdt.getNumberOfColumns() === gdtToAdd.getNumberOfColumns()) {
            var idxRowStart = gdt.getNumberOfRows();        
            gdt.addRows(gdtToAdd.getNumberOfRows());

            for (var idxRowSrc = 0; idxRowSrc < gdtToAdd.getNumberOfRows(); ++idxRowSrc) {
                for (var idxColSrc = 0; idxColSrc < gdtToAdd.getNumberOfColumns(); ++idxColSrc) {
                    gdt.setValue(idxRowStart + idxRowSrc, idxColSrc, gdtToAdd.getValue(idxRowSrc, idxColSrc));
                }
            }
        }

        return(gdt);
    }

    gdtCopyColumnProperties(gdtSrc, arrIdxColSrc, gdtDst, arrIdxColDst) {
        // if no destination column-indices are given, we assume, that 
        // we copy in index-order
        if (!arrIdxColDst) {
            arrIdxColDst = arrIdxColSrc.map((idxColSrc, idx) => idx+1);
        }
        if (arrIdxColDst.length !== arrIdxColSrc.length) return(false);

        arrIdxColSrc.forEach((idxColSrc, idx) => {
            gdtDst.setColumnProperties(
                arrIdxColDst[idx], 
                gdtSrc.getColumnProperties(idxColSrc)
            );
        });

        return(true);
    }

    gdtGetColumnLabelArray(gdt, arrIdxCol) {
        return(arrIdxCol.map((idxCol) => gdt.getColumnLabel(idxCol) ));
    }

    gdtGetFirstColumnIndex(gdt, labelFilterName) {
        var tmpLabel, arrMatch;
        for (var idxCol = 0; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
            tmpLabel = gdt.getColumnLabel(idxCol);
            arrMatch = tmpLabel.match(labelFilterName);
            if (arrMatch && arrMatch[0] === tmpLabel) {
                return(idxCol);    
            }
        }
        return(undefined);
    }

    gdtGetColumnIndexArray(gdt, arrColLabels) {
        var arrResult = [];
        var arrMatch;
        var tmpLabel;
        arrColLabels.forEach((label) => {
            for (var idxCol = 0; idxCol < gdt.getNumberOfColumns(); ++idxCol) {
                tmpLabel = gdt.getColumnLabel(idxCol);
                arrMatch = tmpLabel.match(label);
                if (arrMatch && arrMatch[0] === tmpLabel) {
                    arrResult.push(idxCol);    
                }
            }
        });
        return(arrResult);
    }

    gdtSelectColumns(gdt, arrIdxCol) {
        var arrDstCol = [];
        var gdt_new = new google.visualization.DataTable();
        
        arrIdxCol.forEach((idxColSrc) => {
            var idxColDst = gdt_new.addColumn({
                                id: gdt.getColumnId(idxColSrc),
                                type: gdt.getColumnType(idxColSrc),
                                role: gdt.getColumnRole(idxColSrc),
                                label: gdt.getColumnLabel(idxColSrc)
                            });
            gdt_new.setColumnProperties(idxColDst, { ...gdt.getColumnProperties(idxColSrc) });
            arrDstCol.push(idxColDst);
        });
        gdt_new.addRows(gdt.getNumberOfRows());
        for (var idxRow=0; idxRow < gdt.getNumberOfRows(); ++idxRow) {
            arrIdxCol.forEach((idxColSrc, i) => {
                gdt_new.setCell(idxRow, arrDstCol[i], gdt.getValue(idxRow, idxColSrc));
            });
        }
        return(gdt_new);
    }
}
import React from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import PerfectScrollbar from "./perfect-scrollbar.min.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
// import Resizable from "./fabbi_resizable.js";
// import CrcExt_Resizable from "./fabbi_crc_ext_resizable.jsx";
import CrcMainListBase_01 from "./fabbi_crc_main_list_base_01.jsx";
import CrcResizer_01 from "./fabbi_crc_resizer_01.jsx";

export default class CrcNavMainComm_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        // var res = new CrcExt_Resizable();
        // this.addExtension([ res ]);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    render() {        
        var arrResult = [];

        console.log(this.constructor.name, "render");

        arrResult.push(
            <div className={"row"} 
                style={{ ... this.props.style }}
                key={this.getIdDOM()} 
                id={this.getIdDOM()}>
                <div className={"content-wrapper-before gradient-45deg-blue-grey-blue"} style={{ top: "16px" }}>
                </div>
                <div className={"col s12 fab-no-margin"}>
                    <div className={"container fab-no-margin"}>
                        <div className={"chat-application"}>                
                            <div className={"fab-content-head"}>
                                <div className={"header-details"}>                  
                                    <ul id="tab_ctrl_1" className={"tabs hide-on-large-only disabled"} style={{ backgroundColor: "antiquewhite", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", fontSize: "12px" }}>
                                        <li className={"tab"}><a className={"active"} href="#naviA">Families</a></li>
                                        <li className={"tab"}><a href="#naviB">Missions</a></li>
                                        <li className={"tab"}><a href="#naviC">Obj/Msg</a></li>                            
                                    </ul>                                        
                                </div>
                            </div>

                            <div 
                                className={"app-chat fab-main-inner-height fab-main-inner-content"} 
                                style={{ backgroundColor: "antiquewhite" }}>

                                <div className={"card card-default border-radius-6 fab-main-inner-height"} style={{ margin: "0px" }}>
                                    <div className={"card-content chat-content p-0;"} style={{ padding: "0px", height: "inherit", display: "flex", flexDirection: "row", margin: "0px"}}>
                                        
                                        <div 
                                            id="naviA" 
                                            className={"animate fadeUp animation-fast fab-width-navi-A"} 
                                            style={{ height: "inherit" }
                                            }>
                                            <CrcMainListBase_01
                                                    wfCtrl={this.getWfCtrl()} 
                                                    parent={this}
                                                    id_container_header={"div_family_active"}
                                                    id_container_list={"families-div-section"}
                                                    styleAreaList={{ position: 'relative' }}    
                                                    id_area_list={"families-div-scrollarea"}
                                                    propsInput={{ placeholder: "Type new familyname here ..." }}
                                                    iconSubmit={"add_circle"}
                                                    func_onSubmit={(value, inpElem) => {
                                                        // g_wfCtrl.addFamily(value);
                                                        inpElem.value = "";
                                                    }}
                                                />                               
                                        </div>                       
                                                                    
                                        <div id="naviB" className={"animate fadeUp animation-fast fab-width-navi-B"} style={{ height: "inherit" }}>                                                                
                                            <CrcMainListBase_01
                                                wfCtrl={this.getWfCtrl()} 
                                                parent={this}
                                                id_container_header={"div_mission_active"}
                                                id_container_list={"missions-div-section"}
                                                styleAreaList={{ position: 'relative' }}
                                                id_area_list={"missions-div-scrollarea"}
                                                propsInput={{ placeholder: "Type new missionname here ..." }}
                                                iconSubmit={"add_circle"}
                                                func_onSubmit={(value, inpElem) => {
                                                    this.getWfCtrl().addFamilyMission(value);
                                                    inpElem.value = "";
                                                }}
                                                childrenHeader={<div style={{color: "black"}}><b>Missions</b></div>}
                                                style={{ borderStyle: "solid", borderWidth: "1px" }}
                                            />
                                        </div>

                                        <div id="naviC" className={"-chat-content-area animate fadeUp fab-width-main-C"} style={{ height: "100%" }}>
                                            <div className={"resizable"} style={{ height: "100%", minHeight: "6rem"}}>
                                                <CrcMainListBase_01
                                                    wfCtrl={this.getWfCtrl()} 
                                                    parent={this}
                                                    className={"resi"}
                                                    classNameAreaList={"fab-background-wallpaper-01"}
                                                    id_container_header={"div_family_active_for_messages"}
                                                    id_container_list={"messages-div-section"}
                                                    styleAreaList={{ position: 'relative' }}
                                                    id_area_list={"messages-div-scrollarea"}
                                                    propsInput={{ placeholder: "Type message here ..." }}
                                                    style={{
                                                        height: "50%"
                                                    }}
                                                    func_onSubmit={(value, inpElem) => {
                                                        console.log("SEND", value, inpElem);
                                                        this.getWfCtrl().sendFamilyMessage(value);
                                                        inpElem.value = "";
                                                    }}
                                                />
                                                <CrcResizer_01 wfCtrl={this.getWfCtrl()} dst={".resi"}/>

                                                <CrcMainListBase_01
                                                    wfCtrl={this.getWfCtrl()} 
                                                    parent={this}
                                                    className={"resi"}
                                                    id_area_list={"objectives-div-scrollarea"}
                                                    styleAreaList={{ position: 'relative' }}
                                                    classNameAreaList={"fab-background-wallpaper-01"}
                                                    id_container_header={"div_mission_active_for_objectives"}
                                                    id_container_list={"objectives-div-section"}
                                                    style={{
                                                        height: "49%"
                                                    }}
                                                    propsInput={{ placeholder: "Type new Objectivename here ..." }}
                                                    propsAreaHeader={{ style: { backgroundColor: "cornsilk" } }}
                                                    iconSubmit={"add_circle"}
                                                    func_onSubmit={(value, inpElem) => {
                                                        g_wfCtrl.addFamilyMissionObjective(value); 
                                                        inpElem.value = "";
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        return(arrResult);
    }    
}
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcMembers_Entry_01 from "./fabbi_crc_members_entry_01.jsx";

export default class HtmlProcessor_Members extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        props.HtmlElementIdPrefix = "members";
        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcMembers_Entry_01;
        }
        return(props);
    }
    
    onPrepareEntry(id_entry, entry) {
        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;

        let szImgSrc = "";


        // var qryP_User = this.m_wfCtrl.m_bundleUsers.getBundleQP(id_entry, "trigger");
        var userdata = this.m_wfCtrl.m_bundleUsers.getSingleDataset(id_entry);
        var szName = "unknown_" + id_entry;
        if (!!userdata) {
            console.log("userdata", userdata);
            // var userdata = qryP_User.getSingleDataset(id_entry);
            szName = userdata.name;
            szImgSrc = userdata.image_src;
        } else {
            console.log("no userdata", "id", id_entry, this.m_wfCtrl.m_bundleUsers.getDataPool());
        }
        
        datasetExtra[HtmlProcessor_Members.ID_TXT_NAME] = { innerHTML: szName };

        datasetExtra[HtmlProcessor_Members.ID_TXT_INITIALS] = { 
            class_change: ((!szImgSrc || szImgSrc.length <= 0)?"-":"+")+"invisible",
            innerHTML: FabStd.toInitials(szName)
        };
        datasetExtra[HtmlProcessor_Members.ID_IMG_MAIN] = { 
            class_change: ((!szImgSrc || szImgSrc.length <= 0)?"+":"-")+"invisible",            
            src: szImgSrc
        };
    }
}

HtmlProcessor_Members.ID_DIV_SECTION           = "members-div-section"        ;
HtmlProcessor_Members.ID_DIV_ENTRY             = "members-div-entry"          ;
HtmlProcessor_Members.ID_IMG_MAIN              = "members-img-main"           ;
HtmlProcessor_Members.ID_SRC_IMAGE             = "members-src-image"          ;
HtmlProcessor_Members.ID_TXT_NAME              = "members-txt-name"           ;
HtmlProcessor_Members.ID_TXT_INITIALS          = "members-txt-initials"       ;
HtmlProcessor_Members.ID_TXT_INFO              = "members-txt-info"           ;
HtmlProcessor_Members.ID_TXT_TIME              = "members-txt-time"           ;
HtmlProcessor_Members.ID_TXT_MSG_COUNT         = "members-txt-msg-count"      ;
HtmlProcessor_Members.ID_TXT_MSG_COUNT_NEW     = "members-txt-msg-count-new"  ;

import React from 'react';
import HtmlProcessor_Scripts_01 from './fabbi_htmlprocessor_scripts_01.js';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcTools_01 from './fabbi_crc_tools_01.jsx';
import CrcModal_01 from './fabbi_crc_modal_01.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import { collection, where, Timestamp, getDocs, query } from "firebase/firestore"; 

class CrcTabsScript extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this.state.value = undefined;
        this._arrTabVal = [];
        this._arrSelectedTab = [];

        console.debug(this.constructor.name, "Constructor", this, "props", props);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        if (this.state.value && !this.isValid(this.state.value)) {
            console.debug(this.constructor.name, "componentDidUpdate", "setLast !!!");
            this.setSelectedTab("set_last");
        }
    }

    setLastChange(date) {
        this._lastChange = date;
    }

    getLastChange() {
        return(this._lastChange);
    }

    isValid(value) {
        return(this._arrTabVal.includes(value));
    }

    getSelectedTab() {
        return(this.state.value);
    }

    setSelectedTab(value) {        
        console.debug(this.constructor.name, "setSelectedTab", this._arrTabVal, "value", value);

        if (!this.isValid(value)) {
            value = undefined;
            console.debug(this.constructor.name, "arrSelectedTabHistory", this._arrSelectedTab, "1");
            this._arrSelectedTab = this._arrSelectedTab.filter((v) => this.isValid(v));
            console.debug(this.constructor.name, "arrSelectedTabHistory", this._arrSelectedTab, "1");
            if (this._arrSelectedTab.length > 0) value = this._arrSelectedTab[this._arrSelectedTab.length-1];
        } else {
            if (this.state.value === value) return(true); // ok, nothing to set
        }

        if (!["add_new", "set_last"].includes(value)) {
            this._arrSelectedTab.push(value);
            this._arrSelectedTab = this._arrSelectedTab.filter((v, idx, arr) => {
                if (idx !== 0) if (v === arr[idx-1]) return(false);
                return(true);   
            });
        }

        console.debug(this.constructor.name, "setSelectedTab", "using value", value);
        if (value !== this.state.value) { 
            this.setState({ value });
        }
    }
    
    render() {
        // console.log(this.constructor.name, "CrcTabsScript", this.props, this.state.value);
        var val = this.state.value;
        var { value, ... p } = this.props;

        if (val) p.value = val;

        this._arrTabVal = ["add_new", ... this.props.listContent.map((c) => c.props.value)];

        var arrTmp =    
            <Tabs 
                selectionFollowsFocus
                {... p}
                id={this.getIdDOM()} 
                onChange={(event, newValue) => { 
                    console.debug(this.constructor.name, "onChange", "event", event, "newValue", newValue);

                    // after onClick was pressed in a tab ...
                    this.setSelectedTab(newValue);
                    this.setLastChange(new Date());
                    if (this.props.onChange) this.props.onChange(event, newValue, this);
                }} 
                >
                <Tab label="+" value="add_new" style={{width: "3rem"}}/>
                { this.props.listContent }
            </Tabs>;

        this._arrTabVal = ["add_new", ... this.props.listContent.map((c) => c.props.value)];

        // console.log(this.constructor.name, "Tabs-Component", arrTmp);

        return(arrTmp);
    }
}

export default class CrcTabBarScripts_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);
        
        this._qryP = this.getWfCtrl().m_listenerUserIndividualities;
        this._id_HP = "";
        this._id_htmlSuperSection = "script_01-div-tabbar";
        this.getEditor = this.props.getEditor;

        console.debug(this.constructor.name, "props", props);
    }

    getQP() {
        return(this._qryP);
    }

    getHtmlProcessor() {
        return(this.getCRC(this._id_HP));
    }

    setSelectedEntry(id_entry) {
        getCRC_entryList()?.setSelectedEntry(id_entry);
    }

    setActiveIdEntry(id_entry) {
        var cd = this.getHtmlProcessor()?.getCustomData();
        if (cd) cd.id_entry_active = id_entry;

        console.debug(this.constructor.name, "setActiveIdEntry", id_entry);    
        // this.getHtmlProcessor()?.setActiveIdEntry(id_entry); 
    }

    getSelectedTab() {
        var crcFrameTabs = undefined;
        crcFrameTabs = this.getHtmlProcessor()?.getCrcMountedEntryListFrame();
        return(crcFrameTabs?.getSelectedTab());
    }

    setSelectedTab(value) {
        console.debug(this.constructor.name, "setSelectedTab", "value", value);
        
        var crcFrameTabs = undefined;
        crcFrameTabs = this.getHtmlProcessor()?.getCrcMountedEntryListFrame();
        console.debug(this.constructor.name, "setSelectedTab", "crcFrameTabs", crcFrameTabs);
        if (crcFrameTabs) crcFrameTabs.setSelectedTab(value);
    }

    isClickAwayAllowed() {
        return(false);
        /*
        var hp = this.getCRC(this._id_HP);
        if (!hp) return(true);

        return(!hp.getCustomData().clickAwayProhibited_entryList || hp.getCustomData().clickAwayProhibited_entryList <= 0);
        */
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._qryP.removeHtmlProcessorViaDom(this._id_HP);
        this._qryP = undefined;
    
        this.getWfCtrl().removeHidden(this._id_HP);
    }

    componentDidMount() {
        super.componentDidMount();

        var propsTabs = {
            id_child:               "sub_tab",
            // onDoubleClick:          (event) => { console.debug("DOUBLECLICK"); },
            // onClick:                (event) => { console.debug(this.constructor.name, "onClick", "TabOnClick", event); },
            onChange:               (event, id_entry, crcTabs) => { 
                            
                                        console.debug(this.constructor.name, "onChange", "TabOnClick", id_entry);  
                                        var qP = this.getQP();
        
                                        if (this.getSelectedTab()) {
                                            // another entry was active before!
                                            qP.updateDoc(
                                                this.getSelectedTab(), 
                                                { codeTmp: this.getEditor().getPlainText() }, 
                                                (result) => {
                                                    this.getWfCtrl().addSnack("success", "Script temporary saved.");
                                                });
                                        }
        
                                        if (id_entry === "add_new") {
        
                                            console.debug(this.constructor.name, "add_new");
                                            
                                            var num = 1;
                                            var bas = "neu ";
                                            var objDum = { 
                                                type: "gdtTs_script",  
                                                name: "neu ",
                                                code: this.getEditor().getPlainText()
                                            };
                                            
                                            var p = getDocs(query(collection(
                                                        this.getWfCtrl().getFirestore(), 
                                                        qP.getCollectionPath()),
                                                        where("type", "==", "gdtTs_script")
                                                    ));
                                            p.then((snap) => { 
                                                for (var num=1; num<1000; ++num) {
                                                    var bFound = false;
                                                    var tmp = bas+num;
                                                    snap.forEach((doc) => {
                                                        var name = doc.data().name;
                                                        // console.debug(this.constructor.name, "snapshot", doc.id, doc.data());
                                                        if (name === tmp) bFound = true;
                                                    });
                                                    if (!bFound) { 
                                                        objDum.name = tmp;
                                                        break;
                                                    }
                                                }
                                                console.debug(this.constructor.name, "snapshot", "using name", objDum.name);
                                            
                                                console.log(this.constructor.name, "add_new", objDum);
                                                objDum.codeTmp = "";
                                                objDum.modified_by = this.getWfCtrl().getMainUserId();
                                                objDum.added_by = this.getWfCtrl().getMainUserId();
                                                objDum.ts_modified = Timestamp.now();
                                                objDum.ts_added = Timestamp.now();
                    
                                                qP.addDoc(objDum, (result) => {
                                                    console.log(this.constructor.name, "add-new-Action", result);
                                                    // her we get the entry-id ... a few moments later, the entry should be seen
                                                    // in the list ...
                                                    this.setSelectedTab(result);
        
                                                    this.getWfCtrl().addSnack("success", "New Script <<" + objDum.name + ">> created.");
                                                });
                                            });                                    
                                        } else {
                                            console.debug(this.constructor.name, "LoadScript", "id_entry", id_entry);
                                            this.setActiveIdEntry(id_entry);

                                            var dataset = qP.getSingleDataset(id_entry);
                                            if (dataset) {
                                                this.getEditor().setCode(dataset.codeTmp?dataset.codeTmp:(dataset.code?dataset.code:""));
                                            }

                                            var objDum = { 
                                                type: "gdtTs_script",
                                                id_entry_active: id_entry
                                            };
                                            qP.updateOrSetDoc("_config", objDum).then((result) => {
                                                console.log(this.constructor.name, "updateset-Action", result);
                                                this.getWfCtrl().addSnack("success", "set new id <<" + objDum.id_entry_active + ">> !");
                                            });

                                        }
                                    },
            onRenderFinshed:        () => {
            },
            indicatorColor:         "primary",
            textColor:              "primary",
            variant:                "scrollable",
            scrollButtons:          "auto"
        };

        var htmlProcessorProps = { 
            id_htmlSuperSection:            this._id_htmlSuperSection,
            onBuildAllFinished:             (htmlProcessor) => {
                console.debug(this.constructor.name, "onBuildAllFinished", "HP", htmlProcessor);
            } ,
            crcDefaultProps_entryList:      {
                onRenderFinshed:            (crc) => { 
                                                console.debug(this.constructor.name, "onRenderFinished", crc);
                                            },
                reactElem_frame:            <CrcTabsScript {...propsTabs}/>,
                crcPrototype_entry:         CrcScript_Entry_01_Tab,
                crcPropsBuilder_entry:      (id_entry) => { 
                                                console.log(this.constructor.name, "propsBuilder", id_entry); 
                                                return({ 
                                                    entryType:          "tab", 
                                                    value:              id_entry ,
                                                    getEditor:          this.getEditor,
                                                    crcParentTBS:       this
                                                }); 
                                            }
            },
            customData: {
                onDelete:   (id_entry) => { 
                                console.debug(this.constructor.name, "onDelete"); 
                                var name = this._qryP.getSingleDataset(id_entry, undefined)?.name;
                                this._qryP.deleteDoc(
                                    id_entry, 
                                    (result) => { 
                                        console.debug(this.constructor.name, "onDelete", "result", result, this.getWfCtrl(), this.getWfCtrl()?.addSnack ); 
                                        this.getWfCtrl().addSnack("success", "Script <<" + name + ">> deleted.");
                                        console.debug(this.constructor.name, "onDelete", "snack added");
                                    });
                            } 
            }
        };
        
        this._id_HP = 
            this.getWfCtrl().addHidden(
                HtmlProcessor_Scripts_01, 
                { 
                    leadingDataPool: this._qryP.getPrivateDataPool(), 
                    ...htmlProcessorProps
                }
            );
        this._qryP.addHtmlProcessorViaIdDom(this._id_HP); 
        
        // console.log(this.constructor.name, "componentDidMount", "planRun"); // , this._htmlP.getId_Div_SuperSection());
        this._qryP.planRun();
    }

    /*
    componentDidMount() {
        super.componentDidMount();

        console.debug("TEST_ID_01", "crc", this.getWfCtrl().getCRC("TEST_ID_01"));
    }
    */

    edit_gdtTsScript(id_gdtTsScript, parentElement, propsChild) {
        /*
        if (!id_gdtTsScript) {
            id_gdtTsScript = this.getSelectedId_gdtTsScript();
        }
        */
        var style = {};

        if (parentElement) {
            var z = FabStdBro.getZIndex(parentElement);
            if (!isNaN(z) && z !== 0) style.zIndex = z+1;
        }

        console.debug(this.constructor.name, "edit_gdtTsScript", id_gdtTsScript, "style", style);
        return(
            CrcModal_01.openAsModal( 
                this.getWfCtrl(),
                // (additional) frame-props
                {   
                    style 
                },  
                // Prototype of modal content
                CrcGdtTsScripts_ModalInput_01,
                    // Props of modal content
                { 
                    id_child:       id_gdtTsScript,
                    htmlProcessor:  this.getQP(),
                    ... propsChild
                }
            )); 
    }

    render() {      
        // console.log(this.constructor.name, "htmlProcessor", this._htmlP, "getId_Div_SuperSection", this._htmlP.getId_Div_SuperSection);
        var arrTmp = 
            <div style={{ width: "100%" }}>
                <span id={this._id_htmlSuperSection}>
                </span>
            </div>  ;
        return(arrTmp);
    }
}

class CrcScript_Entry_01_Tab extends CrcHtmlProcessorEntry {
 
    hideTools() {
        if (this._idDom_tools) {
            console.debug(this.constructor.name, "hideTools");
            CrcModal_01.closeModal(this.getWfCtrl(), this._idDom_tools);
            this._idDom_tools = null;
        } else {
            console.debug(this.constructor.name, "hideContentHelper", "FAILED");
        }
    }

    showTools() {
        var crc = this;
        var domContent = crc.getDOMElement();
        
        if (domContent && domContent.isConnected) {
            var rect = domContent.getBoundingClientRect();
            // console.debug("domContent.rect", domContent, rect);
            if (rect) { 
                console.debug(this.constructor.name, "trashbinChild", this._idDom_tools, rect);
                this._idDom_tools = CrcModal_01.openAsModal( 
                    this.getWfCtrl(),
                    {   
                        id_dom: "tools_frame",
                        className: "none",  // nop Classname
                        onClickAway: (event) => { 
                            // this.getCRC("trashbin_child")?.handleClickAway(event) 
                        }, 
                        style: {
                            position: "absolute",
                            top: rect.bottom,
                            left: rect.left,
                            width: "auto",
                            height: "auto",
                            overflow: "none"
                        }
                    },  // additional-frame-props
                    CrcTools_01,
                    { 
                        id_child: "tools_child",
                        onDelete: (e) => { 
                            console.log(this.constructor.name, "onDelete", "FOUND"); 
                            var cd = this.getHtmlProcessor().getCustomData();
                            if (cd.onDelete) cd.onDelete(this.getIdEntry());
                        }
                    }
                );
            }
        }

        return(true);
    }

    renderEntry(edb) {      
        // console.debug(this.constructor.name, "renderEntry", "START");
        
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;
        var szDelay="";

        // console.debug(this.constructor.name, "renderEntry", "setActiveIdEntry", this.getIdEntry(), this.isActiveEntry());

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
 
        // console.log(this.constructor.name, dataLastMsg.content);
        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }

        var isActive = () => {
            return(this.isActiveEntry() || this.isAlwaysLinkedToActiveEntry());
        };

        var renderResult;
        
        // console.debug(this.constructor.name, "value", this.getIdEntry(), "props", this.props);
        renderResult =
            <Tab 
                {...this.props} 
                id={this.getIdDOM()} 
                label={dataset.name + ((dataset.codeTmp && (dataset.codeTmp !== dataset.code))?" (*)":"") + (this.isSelected_inParentEntryList()?"!SEL!":"") } 
                draggable={true} 
                onDragStart={(event) => { console.debug(this.constructor.name, "onDragStart (3)"); this.showTools(); } } 
                onDragEnd={(event) => { console.debug(this.constructor.name, "onDragEnd (4)"); this.hideTools(); } } 
                onClick={(e) => { 
                    
                    var timeDiff = 0;
                    var timeDiffLmt = 2000;
                    var lastChange = this.getCrcMountedEntryListFrame().getLastChange();
                    if (lastChange) timeDiff = new Date() - lastChange; else timeDiff = timeDiffLmt;

                    /*
                    console.debug(e.target?.parentElement?.classList?.contains("Mui-selected")); 
                    console.debug(this.constructor.name, 
                        "TabOnClick", 
                        "value", this.props.value, 
                        "classList", e.target?.parentElement?.classList,
                        "selected", this.getCrcMountedEntryListFrame()?.getSelectedTab(), 
                        "getEditor", this.props.getEditor(), 
                        "target-dom", e.target,
                        "style", window.getComputedStyle(e.target), 
                        "lastChange", this.getCrcMountedEntryListFrame().getLastChange(), 
                        "actTime", new Date(), 
                        "timeDiff", timeDiff);
                    */
                    if (timeDiff < timeDiffLmt) {
                        // looks like it's just a click in combination with an "change"
                    } else {
                        // it's a single click without a change ...
                        // go to editor-mode ...
                        
                        this.props.crcParentTBS?.edit_gdtTsScript(
                            this.getIdEntry(), 
                            this.getDOMElement(), 
                            // props for the modal-child
                            {
                                htmlProcessor:      this.getHtmlProcessor(),
                                getEditor:          this.props.getEditor    
                            }
                        );
                    }
                    return(true);
                }
            }/>;

        // console.debug(this.constructor.name, "renderEntry", "END");
        return(renderResult);
    }
}

class CrcGdtTsScripts_ModalInput_01 extends CrcHtmlProcessorEntry {
    constructor(props) {
        super(props);

        this.getEditor = props.getEditor;

        var cd = this.getHtmlProcessor().getCustomData();
        if (!cd.clickAwayProhibited_entryList) cd.clickAwayProhibited_entryList = 1; else cd.clickAwayProhibited_entryList = cd.clickAwayProhibited_entryList+1;
    
        console.debug(this.constructor.name, "constructor", "cd", cd, "props", props);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        
        if (this.getHtmlProcessor()) {

            // mark that we are using one entry
            var cd = this.getHtmlProcessor().getCustomData();
            if (cd.clickAwayProhibited_entryList) --cd.clickAwayProhibited_entryList;

            this.getHtmlProcessor().derequestSpecialDataAction(
                this.getIdDOM()    // id_sda
                );
        }   
    }

    componentDidMount() {
        super.componentDidMount();

        console.debug(this.constructor.name, "componentDidMount", this.getIdDOM());

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().requestSpecialDataAction(
                this.getIdDOM(),    // id_sda
                this.getIdEntry(),  // id_entry
                () => { 
                    console.log(this.constructor.name, "_specialDataRequest", "UPDATE", this.getIdEntry() ); 
                    this.update();
                    // console.log(this.constructor.name, "_specialDataRequest", "UPDATE", this.getIdEntry(), "initiated" );  
                } // force a re-rendering
            );
        }
    }

    renderEntry(edb) {      
        console.log(this.constructor.name, "renderEntry", edb.dataset);

        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");

        this.state.new = false;
        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            }
        }

        // var bActive = (this.getWfCtrl().getSelectedId_Missions() === this.getIdEntry());

        var id_dom_input_name = this.getIdDOM() + "_input_name";
        var id_dom_input_description = this.getIdDOM() + "_input_description";

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>GdtTs-Script-Properties</span>
                        <span className={"fab-dragdestination"}>
                            <i className={"material-icons trash-bin"} id="trash-bin">delete</i>                    
                        </span>            
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <CrcInput_01 
                            id_dom={id_dom_input_name} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Scriptname" 
                            defaultValue={dataset.name}/>

                        <CrcInput_01 
                            id_dom={id_dom_input_description} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Description" 
                            defaultValue={dataset.description}/>
                    </div>
                    <div className={"row"}>
                        <b>Code</b><br></br>
                        <cite>
                            { dataset.codeTmp?dataset.codeTmp:dataset.code }
                        </cite>
                    </div>

                    <div className={"row"}>                   
                        <div className={"modal-footer"}>                            
                            <a className={"btn waves-effect waves-light send"} 
                                onClick={
                                    (event) => { 
                                        console.debug(this.constructor.name, "useActiveScriptCode", this.props);
                                        if (this.props.getEditor) {
                                            dataset.code = this.props.getEditor().getCode();
                                            this.update();  // re-render ...
                                        }
                                    }
                                }>use active scriptcode</a>

                            <a className={"btn waves-effect waves-light send"} 
                                onClick={
                                    (event) => { 
                                        if (this.props.getEditor) this.props.getEditor().setCode(dataset.code);
                                    }
                                }>Load</a>

                            <a className={"btn waves-effect waves-light send"} 
                                onClick={
                                    (event) => { 
                                        // user should say if active script code shall be saved
                                        // and old one overwritten ...

                                        console.log(this.constructor.name, id_dom_input_name, document.getElementById(id_dom_input_name));
                                        var obj = {};
                                        FabStd.addProp(obj, "name", this.getWfCtrl().getCRC(id_dom_input_name).getDbValue());   
                                        FabStd.addProp(obj, "description", this.getWfCtrl().getCRC(id_dom_input_description).getDbValue());   
                                        FabStd.addProp(obj, "code", dataset.codeTmp?dataset.codeTmp:dataset.code);
                                        FabStd.addProp(obj, "codeTmp", undefined);
                                        
                                        this.getHtmlProcessor().getQP().updateDoc(
                                            this.getIdEntry(), 
                                            obj).then(() => {
                                                this.getWfCtrl().addSnack("success", "Script <<" + obj.name + ">> saved.");
                                                console.log(this.constructor.name, "this", this, "this.close", this.close);

                                                // close modal window ... which is the parent of this window
                                                this.getParent().close();        
                                            }); 
                                    }
                                }>Save</a>
                        </div>  
                    </div>                
                    <p/>                            
                </div> 
            </form>
        );
    }
}
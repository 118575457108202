import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import EventTarget from "./fabbi_eventtarget.js";

export default class qryProcessorStatistics extends EventTarget {
    constructor(qryProcessor_Parent) {
        super();
        
        this.m_qPP = qryProcessor_Parent;
        this.init();
    }
    
    init() {
        this.m_bInitialized = false;
        this.m_data = {            
            count: 0,
            
            // var tsTest = Timestamp.now();
            // var tsTest = Timestamp.fromDate();

            tsFirstModified: null,
            szFirstModifiedId: "",
            tsLastModified: null,
            szLastModifiedId: "",
            
            tsFirstAdded: null,
            szFirstAddedId: "",            
            tsLastAdded: null, 
            szLastAddedId: "",
            
            func_deliverTsLastChecked: undefined,   
            tsLastChecked: null,
            count_new: 0
        };
        // some helpful data!
        this.m_smapTmp = new StdMap();
    }
    

    setLastChecked(tsLastChecked, func_deliverTsLastChecked) {
        if (FabStd.isFunction(func_deliverTsLastChecked)) {
            this.m_data.func_deliverTsLastChecked = func_deliverTsLastChecked;   
        } else {
            this.m_data.tsLastChecked = tsLastChecked;
            this.m_data.func_deliverTsLastChecked = undefined;
        }
    }
    
    getLastChecked() {
        // console.log("getLastChecked()");
        try {
            var tsLastChecked = this.m_data.func_deliverTsLastChecked();
            // console.log(this.m_qPP.constructor.name, "getLastChecked()", tsLastChecked);
            return(tsLastChecked); 
        } catch(e) {
            return(this.m_data.tsLastChecked);
        }
    }
    
    tsaUpdatesStart() {        
    }    
    
    // returns the processing-"state" of an entry. It's also taken into account,
    // if the statistic is actually being "continued" or if it's being initialised
    getEntryState(entry) {
        var szState = entry["state"];
        if (!this.m_bInitialized) {
            if (szState !== "deleted") {
                return("added"); 
            } else { 
                return(undefined);
            }
        } else {
            return(szState);
        }
    }
    
    tsaUpdates_processEntry(szId) {
        let PDP = this.m_qPP.getPrivateDataPool();
        let entry = PDP.getDataEntry(szId);
        if (!entry) {
            console.log(this.constructor.name, "tsaUpdates_processEntry", "id", szId, "ENTRY NOT FOUND!");
            return;
        }

        let dataset = entry["dataset"];
        let dataset_old = entry["dataset_old"];
        let szState = this.getEntryState(entry);

        // if state "undefined" then: don't do anything!
        if (szState === undefined) {
            console.log(this.constructor.name, "tsaUpdates_processEntry", szId, "UNDEFINED STATE");
            return;
        }
                
        this.tsaUpdates_processEntry_Level1(szId, szState, dataset, dataset_old);
        this.tsaUpdates_processEntry_Level2(szId, szState, dataset, dataset_old);
    }

    tsaUpdates_processEntry_Level1(szId, szState, dataset, dataset_old) {
        if (!!dataset && !!dataset.ts_added) {
            if (!this.m_data.tsLastAdded || dataset.ts_added > this.m_data.tsLastAdded) {
                this.m_data.tsLastAdded = dataset.ts_added;
                this.m_data.szLastAddedId = szId;
            }
            if (!this.m_data.tsFirstAdded || dataset.ts_added < this.m_data.tsFirstAdded) {
                this.m_data.tsFirstAdded = dataset.ts_added;
                this.m_data.szFirstAddedId = szId;
            }            
        }        
        if (!!dataset && !!dataset.ts_modified) {
            if (!this.m_data.tsLastModified || dataset.ts_modified > this.m_data.tsLastModified) {
                this.m_data.tsLastModified = dataset.ts_modified;
                this.m_data.szLastModifiedId = szId;
            }
            if (!this.m_data.tsFirstModified  || dataset.ts_modified < this.m_data.tsFirstModified) {
                this.m_data.tsFirstModified = dataset.ts_modified;
                this.m_data.szFirstModifiedId = szId;
            }            
        }         

        switch(szState) {
            case "added":
                ++this.m_data.count;
                // ++this.m_data.count_new;
                this.m_smapTmp.set(szId);
                break;
                        
            case "deleted":
                --this.m_data.count;
                // --this.m_data.count_new;
                break;
        }
        if (szState === "deleted") {
            this.m_smapTmp.delete(szId);
        } else {
            this.m_smapTmp.set(szId, { szId: szId, ts_added: dataset.ts_added });
        }
    }
    
    tsaUpdates_processEntry_Level2(szId, szState, dataset, dataset_old) {
    }

    tsaUpdatesEnd() {
        let thisClass = this;
        let tsLastChecked = this.getLastChecked();
        let nCount = 0;
        let iter = this.m_smapTmp.filter(function(statdata) {
            return(true);
            }).forEach(function(statdata){
                    if (statdata.ts_added !== null) {
                        if (statdata.ts_added > tsLastChecked) {
                            ++nCount;
                        }
                    }
                });
        this.m_data.count_new = nCount;
        this.m_bInitialized = true;
    }
}
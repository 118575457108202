import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import ControlledReactComponentExtension from "./fabbi_controlled_react_component_extension";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

export default class ControlledReactComponentExtensionParent extends ControlledReactComponentExtension {
    constructor(props) {
        super(props);

        this.m_mapChildren = new StdMap();
    }

    // must be overridden by derived Class!
    newChildExtension(underlyingChildCRC) {
        return(undefined);
    }

    // for override, but super must be called!
    underlyingChildComponentDidMount(underlyingChildCRC) {
        // now setup the connection "child to parent"
        var szId = underlyingChildCRC.getUniqueTempId();
        var childExt = this.m_mapChildren.get(szId);
        if (!childExt) {
            var childExt = this.newChildExtension(underlyingChildCRC);
            childExt.setParentExtension(this);
            if (!!childExt) {
                // console.log(this.constructor.name, "underlyingChildComponentDidMount", "addExtension called", underlyingChildCRC);
                underlyingChildCRC.addExtension(childExt);
                this.m_mapChildren.set(szId, childExt);
            } else {
                // no childExtension generated ... we doesn't do anything ...
            }
        }

        if (!!childExt) {
            childExt.underlyingComponentDidMount(underlyingChildCRC);
        }
    }

    // for override, but super must be called!
    underlyingChildComponentDidUpdate(underlyingChildCRC, prevProps, prevState, snapshot) {
    }

    // for override, but super must be called!
    underlyingChildComponentWillUnmount(underlyingChildCRC) {
        var szId = underlyingChildCRC.getUniqueTempId();
        this.m_mapChildren.delete(szId);
    }
}
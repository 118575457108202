import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import CrcTimeLeftDisplay_01 from "./fabbi_crc_time_left_display_01.jsx";
import CrcInfoLine_01 from "./fabbi_crc_info_line_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import { Timestamp } from "firebase/firestore";

export default class CrcFamilyMissions_Entry_02 extends CrcHtmlProcessorEntry {

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
       
        return(
            <li id={this.getIdDOM()} 
                className={"fab-list-entry-01-activeEntry"} 
                onClick={(e) => { /* fncClick(e) */ }}
                style={{
                        width: "inherit", 
                        display: "flex", 
                        flexDirection: "column", 
                        padding: "0rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem" }}
                >
                <div style={{width: "inherit", display: "flex", flexDirection: "column"}}>
                    <div style={{paddingLeft: "0.5rem", width: "inherit", display: "flex", flexDirection: "row" }}>
                        <div style={{width: "3.5rem", flexShrink: 0, flexGrow: 0}}>
                            <CrcImageOrInitials_01 wfCtrl={this.getWfCtrl()} image_src={dataset.image_src} long_name={dataset.name}/>
                        </div>
                        <div style={{flexGrow: 1, marginRight: "1rem"}}>
                            <div className={"m-0 blue-grey-text text-darken-4 font-weight-700"}><CrcInfoLine_01 wfCtrl={this.getWfCtrl()} infoContent={dataset.name}/></div>
                            <div className={"m-0 fab-info-text-fontstyle info-line"}><CrcInfoLine_01 wfCtrl={this.getWfCtrl()} infoContent={dataset.goal}/></div>       
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
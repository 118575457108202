import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import qryProcessor_User from "./fabbi_qry_processor_user.js";

export default class qryProcessor_Members extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);                
        this.m_szFamilyId = props.szFamilyId?props.szFamilyId:props.szTriggerId;        
        
        // console.log(this.getClassName(), "constructor", this.m_szFamilyId);                    
    }

    // Overrides existing!
    getClassName() {
        return("qryProcessor_Members");    // to FIX the classname!
    }

    getFamilyId() {        
        return this.m_szFamilyId;
    }
    
    getCollectionPath() {
        return('families/' + this.m_szFamilyId + "/members");
    }

    getMembers() {
        var arrMembers = [];
        this.getPrivateDataPool().getDataMap().filter((entry) => {                        
            return(entry["state"] !== "deleted");
        }).forEach((entry) => {
            arrMembers.push(entry["id"]);
        });
        return(arrMembers);
    }

    onRun() {                        
        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["state"] === "added" && entry["pending_onRun"] === "y");            
        }).forEach((entry) => {
            var szUserId = entry["id"];  

            if (!this.getWfCtrl().m_listenerUserUsersAuthorized.getPrivateDataPool().getDataEntry(szUserId, false)) {
                this.getWfCtrl().reportProblem({ 
                    problem_id: "missing_data_user_" + szUserId, 
                    problem_type: "data_missing", 
                    data_type: "user", 
                    data_id: szUserId 
                });
            } 
        });
        
        return(super.onRun());
    }        
}

import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import CrcFamilyMissions_Entry_01 from "./fabbi_crc_missions_entry_01.jsx";
import CrcFamilyMissions_Entry_02 from "./fabbi_crc_missions_entry_02.jsx";
import CrcExt_Scrollbar from "./fabbi_crc_ext_scrollbar.jsx";

export default class HtmlProcessor_Missions extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);

        var sb = new CrcExt_Scrollbar({
            container: "#missions-div-scrollarea",
            options:   { suppressScrollX: true }
        });

        props.HtmlElementIdPrefix = "missions",
        props.crcExtensions_entryList = [ /* ... props.crcExtensions_entryList, */ sb ]; 

        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcFamilyMissions_Entry_01
        }
        
        props.portalsForActiveEntry = [
            { 
                id_dom: "div_mission_active",
                crcPrototype_entry: CrcFamilyMissions_Entry_01 
            },
            { 
                id_dom: "div_mission_active_for_objectives",
                crcPrototype_entry: CrcFamilyMissions_Entry_02 
            }
        ];

        return(props);
    }
        
    onPrepareEntry(id_entry, entry) {
        
        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;

        // m_smapMissionsMissionMissions.get(id_entry).getId_LastMission
        
        let szGoal = "";
        let szTmpId = id_entry;                
        
        let qryP_Objectives = this.getLeadingDataPool().getParent().getChildQP_Objectives(id_entry);
        let objStat;
        
        if (qryP_Objectives != undefined) {
            objStat = qryP_Objectives.getStatistics();  

            // templateHtml.prepareDatasetHtml(id_entry, objStat.m_data, datasetExtra, { ...objOptions, datafieldPrefix: "obj" });   
        }

        szGoal = dataset.goal; 
        if (szGoal === undefined) szGoal = "";

        // templateHtml.prepareDatasetHtml(id_entry, dataset, datasetExtra, objOptions);
    
        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = function() {
            if (g_wfCtrl.m_listenerUserIndividualities.getSelectedId_Missions() === szTmpId) {
                g_wfCtrl.editMission(szTmpId);
            } else {
                g_wfCtrl.setSelectedId_Missions(szTmpId);                
            }
        };

        var bActive = (g_wfCtrl.m_listenerUserIndividualities.getSelectedId_Missions() === id_entry);
        
        datasetExtra[HtmlProcessor_Missions.ID_DIV_ENTRY] = { 
                class_change: (bActive?"+":"-") + "active",
                click: fncClick,
                mouseup: function () { 
                        if (!!this._longClickTimer) {
                            clearTimeout(this._longClickTimer);
                            this._longClickTimer = undefined; 
                        }
                    },
                mousedown: function() {
                    setTimeout(function () { 
                            console.log("LONG CLICK", this.getElementsByClassName("fabbi_container_row")); 
                            var c = this.getElementsByClassName("fabbi_container_row");
                            for (var i = 0; i < c.length; i++) {
                                c[i].classList.remove("invisible");
                                setTimeout(function() {
                                    this.classList.add("invisible");
                                }.bind(this), 5000);
                            }
                        }.bind(this), 500);
                    }
        };

        datasetExtra["data-objstat"] = objStat.m_data;
    }
}

HtmlProcessor_Missions.ID_DIV_SECTION       = "missions-div-section";
HtmlProcessor_Missions.ID_DIV_ENTRY 	    = "missions-div-entry";
HtmlProcessor_Missions.ID_IMG_AVATAR 	    = "missions-img-avatar";
HtmlProcessor_Missions.ID_IMG_MAIN 	        = "missions-img-main";
HtmlProcessor_Missions.ID_SRC_IMAGE         = "missions-src-image";
HtmlProcessor_Missions.ID_TXT_NAME 	        = "missions-txt-name";
HtmlProcessor_Missions.ID_TXT_GOAL          = "missions-txt-goal";
HtmlProcessor_Missions.ID_TXT_TS_DEADLINE   = "missions-txt-ts-deadline";
HtmlProcessor_Missions.ID_TLD_TS_DEADLINE   = "missions-tld-ts-deadline";
HtmlProcessor_Missions.ID_DAT_TS_DEADLINE   = "missions-dat-ts-deadline";
HtmlProcessor_Missions.ID_TIM_TS_DEADLINE   = "missions-tim-ts-deadline";
HtmlProcessor_Missions.ID_TXT_TIME 	        = "missions-txt-time";
HtmlProcessor_Missions.ID_TXT_OBJ_COUNT     = "missions-txt-obj-count";
HtmlProcessor_Missions.ID_TXT_OBJ_COUNT_IP  = "missions-txt-obj-count_IP";
HtmlProcessor_Missions.ID_TXT_OBJ_COUNT_OK  = "missions-txt-obj-count_OK";
HtmlProcessor_Missions.ID_TXT_OBJ_COUNT_FD  = "missions-txt-obj-count_FD";
HtmlProcessor_Missions.ID_TXT_OBJ_COUNT_NEW = "missions-txt-obj-count_new";

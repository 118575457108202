import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import HtmlProcessor_Objectives from "./fabbi_htmlprocessor_objectives.js";
import qryProcessorStatistics_Objectives from "./fabbi_qry_processor_statistics_objectives.js";

export default class qryProcessor_Objectives extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super({ 
            htmlProcessorPrototype: HtmlProcessor_Objectives, 
            BuildAll_ifDivSectionMissing: false,
            ...props 
            }
        );                
        this.m_szFamilyId = props.szFamilyId;     
        this.m_szMissionId = props.szMissionId?props.szMissionId:props.szTriggerId; 
    }

    // Overrides existing!
    getClassName() {
        return("qryProcessor_Objectives");    // to FIX the classname!
    }

    setStatistics() {
        this.m_statistics = new qryProcessorStatistics_Objectives(this);
    }    
    
    getFamilyId() {        
        return this.m_szFamilyId;
    }
    
    datasetChanged(szDatasetId, dataset, szChangeType) {
        super.datasetChanged(szDatasetId, dataset, szChangeType);
        
        // console.log(this.constructor.name, "datasetChanged", szChangeType);
    }

    getMissionId() {        
        return this.m_szMissionId;
    }
    
    // getMsgCount() {
    //    return(this.m_statistics.m_data.count);
    // }     
    
    getId_LastObjective() {
        return(this.m_statistics.m_data.szLastAddedId);
    }
    
    getLastObjective() {
        let szId = this.getId_LastObjective();                
        return({ szId: szId, entry: this.getPrivateDataPool().getDataEntry(szId) });
    }
    
    getCollectionPath() {
        return('families/' + this.getFamilyId() + '/missions/' + this.getMissionId() + '/objectives');
    }
    
    // is called in the "onRun"-Proceedings, for each new/modified entry
    processEntry_OnRun(szIdEntry, entry) {
        // console.log(this.constructor.name, "OBJ.", szIdEntry, entry);
        
        var dataset = entry["dataset"];
        
        if (dataset["ts_sort"] === undefined) {
            dataset["ts_sort"] = dataset["ts_added"];
            // console.log("dataset-extended", dataset);
        }        
    }    
}

import XLSX from "xlsx";

var ec = XLSX.utils.encode_cell;

function xlsx_getRaSByDefName(wb, szDefName) {
    var bGef=false;
    var range;
    var sheetName;

    console.log("xlsx_getRangeByDefName", "lookup range", szDefName);

    for (var p in wb.Workbook.Names) {
        // console.log(wb.Workbook.Names[p]);
        if (wb.Workbook.Names[p].Name === szDefName) {
            sheetName = 
                wb.Workbook.Names[p].Ref.split("!")[0];
            range = XLSX.utils.decode_range(
                wb.Workbook.Names[p].Ref.split("!")[1]);
            // console.log(p, range, wb.Workbook.Names[p].Ref.split("!")[1]);
            bGef = true;
            break;
        }
    }
    if (!bGef) return(undefined);
    
    if (range.s.c < 0) range.s.c = 0;
    if (range.e.c < 0) range.e.c = 16384-1;
    
    var nCount_EndingRowsEmpty;
    var col, row;
    var sheet;
    sheet = wb.Sheets[sheetName];
    
    // cut range-cols to used
    for(col = range.s.c; col <= range.e.c; ++col) {
        var cell_address = {c:col, r:range.s.r};
        if (!sheet[ec(cell_address)]) break;
    }
    range.e.c = col-1;
    
    // cur range-rows to used
    nCount_EndingRowsEmpty = 0
    for(row = range.s.r; row <= range.e.r; ++row) {
        for (col = range.s.c; col <= range.e.c; ++col) {
            if (sheet[ec({c:col, r:row})]) break;
        }
        if (col > range.e.c) {
            ++nCount_EndingRowsEmpty;
            if (nCount_EndingRowsEmpty > 5) {
                ++row;
                break;
            } 
        } else {
            nCount_EndingRowsEmpty = 0;
        }
    }
    // set ending row
    range.e.r = row - 1 - nCount_EndingRowsEmpty;    

    return({ range, sheet });
}

function xlsx_getUsedRaS(ws) {
    var RaS = {};
    RaS.range = XLSX.utils.decode_range(ws["!ref"]);
    RaS.sheet = ws;
    return(RaS);
}

/*
options:    
    includeColumns:    [] array of column-numbers to include (col-numbers starting with 0)
    excludeColumns:    [] array of column-numbers to exclude (col-numbers starting with 0)
    maxRows:           max. number of rows to return
*/
function xlsx_getAsAoa( RaS, options ) {
    var nCount_RowCellsEmpty;
    var row, col;
    var range, sheet;

    range = RaS.range;
    sheet = RaS.sheet;

    var pvM = new Array();
    var arrSrcCol = new Array();
    var n, nIndex;

    var tmpOpt = { 
        includeColumns: undefined,         // array of Col-Numbers
        excludeColumns: undefined,         // array of Col-Numbers
        importPrefsForCols: undefined,     // array of objects  
        maxRows: -1,
        ...options };

    if (!tmpOpt.includeColumns) {
        for (col=range.s.c; col<=range.e.c; ++col) {
            arrSrcCol.push(col);
        }
    } else {
        tmpOpt.includeColumns.forEach((col) => {
            arrSrcCol.push(col);
        });
    }
    if (tmpOpt.excludeColumns) {
        excludeColumns.forEach((col) => {
            nIndex = arrSrcCol.findIndex({value: col});
            if (nIndex > 0) arrSrcCol.delete(nIndex);
        });
    }

    var arrImpPref = [];
    for (var n=0; n < arrSrcCol.length; ++n) {
        // setup defaults
        arrImpPref.push({
            cellPropertyToImport: "v" // value 
        });
    }
    if (tmpOpt.importPrefsForCols) {
        tmpOpt.importPrefsForCols.forEach((pref) => {
            if (pref.idxCol !== undefined && pref.idxCol !== null) {
                if (pref.idxCol >= 0 && pref.idxCol < arrImpPref.length) {
                    arrImpPref[pref.idxCol] = { ... arrImpPref[pref.idxCol], ... pref };  
                } 
            } else {
                console.error("xlsx_getAsAoa", pref, "importPrefsForCols must be an array of pref-Objects. Each pref-object must have at least an idxCol-prop!");
            }
        });
    }

    for(row = range.s.r; row <= range.e.r; ++row) {
        var pvR = new Array(arrSrcCol.length);

        nCount_RowCellsEmpty = 0;
        arrSrcCol.forEach((col, idxCol) => {
            var cell_address = {c:col, r:row};
            /* if an A1-style address is needed, encode the address */
            var cell = sheet[ec(cell_address)];
            if (cell) {
                var pref = arrImpPref[idxCol];
                pvR[idxCol] = cell[pref.cellPropertyToImport];
                // console.log(cell_ref, "value", cell.v, "formattedText", cell.w, cell);
            } else {
                pvR[idxCol] = null;
                ++nCount_RowCellsEmpty;
            }
            ++n;
        });
        
        pvM.push(pvR);
        if (pvM.length === tmpOpt.maxRows) break;
    }
    return(pvM);
}

function xlsx_getSheetViaIndex(wb, nIndex) {
    if (!nIndex || nIndex < 0) nIndex = 0;
    if (wb.SheetNames.length <= nIndex) return(undefined);
    return(wb.Sheets[wb.SheetNames[nIndex]]);
}

export {
    xlsx_getSheetViaIndex,
    xlsx_getRaSByDefName,
    xlsx_getUsedRaS,
    xlsx_getAsAoa,
    XLSX
}
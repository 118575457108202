import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";

export default class CrcResizer_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._tc = new timeoutCtrl();

        this._arrResizers = [];
        this._arrResizableSections = [];

        this._eventCallbacksWindow = [];
    }

    componentDidMount() {
        super.componentDidMount();

        this._setUp();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._cleanUp();
    }

    render() {
        return(
            <div 
                id={ this.getIdDOM() }
                className={"fab-vertical-resizer resizer"} 
                style={{ height: "1%" }}
                onTouchStart={(event) => {
                }}
                onMouseDown={(event) => {
                    this._func_startResize(event);
                }}
                >        
            </div>
        );
    }

    /**
    * Setup Event Handlers etc.
    */
    _setUp() {
        console.log(this.constructor.name, "_setUp");
        /*
        if (this._arrResizers.length > 0) return;

        var arrResizableSectionParts = [];

        var tmpNode = this.getUnderlyingDOMElement().firstElementChild;
        while (tmpNode) {
            if (tmpNode.classList) {
                console.log(this.constructor.name, "tmpNode.classList", tmpNode.classList);
                // build up array with RESIZABLE-Parts together with ONE specific resizer
                if (tmpNode.classList.contains("resizer")) {          
                    this._arrResizableSections.push(arrResizableSectionParts);
                    this._arrResizers.push(tmpNode);
                    arrResizableSectionParts = [];
                } else {          
                    // collect Sectional-Resizables (resizable elements within a section between two resizers or start/end of parent)
                    arrResizableSectionParts.push(tmpNode);          
                }     
            }
            
            tmpNode = tmpNode.nextSibling;
        }

        if (arrResizableSectionParts.length > 0) {
            this._arrResizableSections.push(arrResizableSectionParts);
        }
                
        for (var i = 0;i < this._arrResizers.length; i++) {
            var currentResizer = this._arrResizers[i];
            currentResizer = this;  // save the active "resizable"-instance
            currentResizer._nIndex = i;
            currentResizer._eventCallbacks = [];

            var fncTmp = this._func_startResize.bind(currentResizer);
            currentResizer._eventCallbacks.push(fncTmp);
            currentResizer.addEventListener('mousedown', fncTmp);          
            currentResizer.addEventListener('touchstart', fncTmp);     
        }
        */
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _func_startResize(event) {        
        console.log(this.constructor.name, "_func_startResize", this);
        
        // event.preventDefault();
        var fncTmp1 = (event) => { return(this._resize(event)) };
        var fncTmp2 = (event) => { return(this._stopResize(event)) };

        this._eventCallbacksWindow.push(fncTmp1);
        this._eventCallbacksWindow.push(fncTmp2);

        window.addEventListener('mousemove', fncTmp1);
        window.addEventListener('touchmove', fncTmp1);

        window.addEventListener('mouseup', fncTmp2);
        window.addEventListener('touchend', fncTmp2);

        // we have a "pParent"-member of "this" ... that's the resizable-instance
        this._displayResizeCursor(true);
    }

    _displayResizeCursor(bDisplay) {
        if (bDisplay === true) {
            document.body.style.cursor = "row-resize";
        } else {
            document.body.style.cursor = "auto";   
        }
    }

    _doSizeChange(nChangeHeight, arrResizableSectionParts) {
        var nSectionHeightResizable = 0;
        var nSectionHeightChanged = 0;
        var tmpMark = [];

        nSectionHeightResizable = this._calcResizableHeight(arrResizableSectionParts, undefined);        
        if (nSectionHeightResizable <= 0.5) {
            return(0);
        }

        var fChangeFactor = (nSectionHeightResizable+nChangeHeight)/nSectionHeightResizable;

        var nChangeCount;
        do {
            nChangeCount = 0;
            for (var i2=0; i2 < arrResizableSectionParts.length; ++i2) {
                var elem = arrResizableSectionParts[i2];            
                if (!elem.classList.contains("no-resize") && tmpMark.indexOf(i2) < 0) {
                    var nOldHeight = elem.getBoundingClientRect().height;
                    var nNewHeight = nOldHeight * fChangeFactor;
                    elem.style.height = nNewHeight + "px";  // maybe the height can't be set ... we can't relay on that
                    var nNewHeight_Effective = elem.getBoundingClientRect().height;
                    var nChangeHeight_Effective = nNewHeight_Effective - nOldHeight;
                    nSectionHeightChanged += nChangeHeight_Effective;
                    nChangeHeight -= nChangeHeight_Effective;
                    if (Math.abs(nNewHeight - nNewHeight_Effective) >= 0.5) {
                        tmpMark.push(i2);   // mark as "no further correction possible"
                    }
                    ++nChangeCount;
                    
                    nSectionHeightResizable = this._calcResizableHeight(arrResizableSectionParts, tmpMark);
                    fChangeFactor = (nSectionHeightResizable+nChangeHeight)/nSectionHeightResizable;
                }
            }                    
        } while (nChangeCount > 0 && Math.abs(nChangeHeight) >= 0.5);

        // console.log("RESIZE", "nParentHeight", nParentHeight, "nSectionHeight", nSectionHeight, "nChangeHeight", nChangeHeight, nSectionHeightChanged);        
        return(nSectionHeightChanged);
    }

    _calcResizableHeight(arrResizableSectionParts, tmpMark) {
        var  nSectionHeightResizable = 0;

        for (var n=0; n < arrResizableSectionParts.length; ++n) {
            var elem = arrResizableSectionParts[n];
            var nTmpHeight = elem.getBoundingClientRect().height;                        
            if (nTmpHeight > 0 && !elem.classList.contains("no-resize") && (!tmpMark || tmpMark.indexOf(n) < 0)) {
                nSectionHeightResizable += nTmpHeight;
            }
        }        

        return(nSectionHeightResizable);
    }

    _calcFixChildrenHeight(elemParent, szJquerySelector_NoResize) {
        var arrC = $(elemParent).children(szJquerySelector_NoResize);
        var pxFixChildrenHeight = 0;
        for (var idx = 0; idx < arrC.length; ++idx) {
            pxFixChildrenHeight += arrC[idx].getBoundingClientRect().height;
        }
        return(pxFixChildrenHeight);
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _resize(event) {        
        this._displayResizeCursor(true);

        var y;
        if (event.pageY) {
            // bei "MouseEvent"
            y = event.pageY;
        } else {
            // bei "TouchEvent"
            y = event.changedTouches[0].pageY;
        }

        var e0 = this.getDOMElement();
        var r0 = e0.getBoundingClientRect();
        var y0t = r0.top;
        var y0b = r0.bottom;
        var pxMoveUp = 0;

        if (y < y0t) {
            pxMoveUp = y0t - y;
        } else if (y > y0b) {
            pxMoveUp = y0b - y;
        }

        var arrF = $(this.props.dst);

        arrF.sort((e1, e2) => {return(e1.getBoundingClientRect().top - e2.getBoundingClientRect().top)});
        
        // console.log(this.constructor.name, "arrF", arrF);
        
        var n=0;
        var arrPair = [ null, null ];
        var pxSumFixHeightTop = 0;
        var pxSumFixHeightBot = 0;
        var pxLastHeight = 0;
        for (var idx=0; idx < arrF.length; ++idx) {
            var r1 = arrF[idx].getBoundingClientRect();
            if (n <= 1) {
                if (r1.top > r0.top) {
                    pxSumFixHeightTop -= pxLastHeight;
                    ++n;
                } else {
                    pxSumFixHeightTop += r1.height;
                }
                if (n <= 1) arrPair[n] = idx; 
            } else {
                pxSumFixHeightBot += r1.height;
            }
            pxLastHeight = r1.height;
        }
        
        // console.log(this.constructor.name, "arrPair", arrPair);
        if (arrPair[0] !== null && arrPair[1] !==  null) {

            var e1 = arrF[arrPair[0]];
            var e2 = arrF[arrPair[1]];
            // console.log(this.constructor.name, "e1.style.height", e1.style.height, e2.style.height);
            
            if (e1.style.height.match("%").length > 0 && e2.style.height.match("%").length > 0) {

                // console.log(this.constructor.name, "% gefunden");
                var percHeight, pxHeight;

                if (pxMoveUp > 0) {
                    pxMoveUp = 
                        Math.min(
                            e1.getBoundingClientRect().height - this._calcFixChildrenHeight(e1, ".no-resize"), 
                            pxMoveUp
                        );
                } else {
                    pxMoveUp = 
                        -Math.min(
                            e2.getBoundingClientRect().height - this._calcFixChildrenHeight(e2, ".no-resize"), 
                            -pxMoveUp
                        );
                }

                percHeight = parseFloat(e1.style.height);
                pxHeight = e1.getBoundingClientRect().height;
                e1.style.height = (percHeight/pxHeight * (pxHeight - pxMoveUp)) + "%";  

                percHeight = parseFloat(e2.style.height);
                pxHeight = e2.getBoundingClientRect().height;
                e2.style.height = (percHeight/pxHeight * (pxHeight + pxMoveUp)) + "%";  
            }
            // this._doSizeChange( -pxMoveUp, [arrF[arrPair[0]]]);
            // this._doSizeChange(  pxMoveUp, [arrF[arrPair[1]]]);
        }
        return;

        var nChangeHeight = Math.min(y - this.getBoundingClientRect().top, this._calcResizableHeight(this._arrResizableSections[this._nIndex+1], undefined, true));
        var nChangeHeight_1 = this._doSizeChange( nChangeHeight, this._arrResizableSections[this._nIndex+0]);
        var nChangeHeight_2 = this._doSizeChange(-nChangeHeight_1, this._arrResizableSections[this._nIndex+1]);
        var nChangeHeight_3 = nChangeHeight_1 + nChangeHeight_2;
        if (Math.abs(nChangeHeight_3) >= 0.5) { 
            this._doSizeChange( 
                -nChangeHeight_3, 
                this._arrResizableSections[this._nIndex+0]
            );
        }
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _stopResize(event) {
        ['mousemove', 'touchmove', 'mouseup'].forEach((name) => {
            // we remove listeners for window-referencing callbacks (which are setup when "resize"-mark is clicked!)
            this._eventCallbacksWindow.forEach((cb) => {
                window.removeEventListener(name, cb);
            });
            this._eventCallbacksWindow = [];
        });
        this._displayResizeCursor(false);
    }

    /**
     * Remove Event Handlers
     */
    _cleanUp() {
        this._arrResizers.forEach((currentResizer, idx) => {
            ['mousedown', 'touchstart'].forEach((szEventName) => {
                currentResizer._eventCallbacks.forEach((cb) => {
                    currentResizer.removeEventListener(szEventName, cb);
                })
                currentResizer._eventCallbacks = [];
            });
        }); 
        
        this._arrResizableSections = [];
        this._arrResizers = [];
    }
}
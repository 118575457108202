import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import qryProcessor_Members from './fabbi_qry_processor_members.js';
import HtmlProcessor_Members from './fabbi_htmlprocessor_members.js';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import CrcDatepicker_01 from "./fabbi_crc_datepicker_01.jsx";
import { Timestamp } from "firebase/firestore";

export default class CrcMissions_ModalInput_01 extends CrcHtmlProcessorEntry {
    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        
        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().derequestSpecialDataAction(
                this.getIdDOM()    // id_sda
                );
        }   
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().requestSpecialDataAction(
                this.getIdDOM(),    // id_sda
                this.getIdEntry(),  // id_entry
                () => { 
                    console.log(this.constructor.name, "_specialDataRequest", "UPDATE", this.getIdEntry() ); 
                    this.update();
                    // console.log(this.constructor.name, "_specialDataRequest", "UPDATE", this.getIdEntry(), "initiated" );  
                } // force a re-rendering
            );
        }
    }

    renderEntry(edb) {      
        console.log(this.constructor.name, "renderEntry", edb.dataset);

        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");

        if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
            this.state.new = true; // direct access to "state" !! important in this case!
        } else {
            this.state.new = false;
        }

        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = function(e, id_mission) {
                if (this.getWfCtrl().m_listenerUserIndividualities.getSelectedId_Missions() === id_mission) {
                    // g_wfCtrl.editMission(szTmpId);
                } else {
                    this.getWfCtrl().setSelectedId_Missions(id_mission);
                    this.getWfCtrl().refreshLastChecked_Messages(id_mission);
                }
            }.bind(this);

        // var bActive = (this.getWfCtrl().getSelectedId_Missions() === this.getIdEntry());

        var id_dom_carousel = this.getIdDOM() + "_cl";
        var id_dom_input_ts_deadline = this.getIdDOM() + "_datepicker";
        var id_dom_input_name = this.getIdDOM() + "_input_name";
        var id_dom_input_goal = this.getIdDOM() + "_input_goal";

        /*
        console.log(this.constructor.name, "RENDERMI",
            this.getWfCtrl().m_bundleMissions,
            this.getIdEntry(),
            this.getWfCtrl().m_bundleMissions.getBundleQP(this.getIdEntry(), "trigger"));
        */

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>Mission-Profile</span>
                        <span className={"fab-dragdestination"}>
                            <i className={"material-icons trash-bin"} id="trash-bin">delete</i>
                        </span>            
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <CrcInput_01 
                            id_dom={id_dom_input_name} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Missionname" 
                            defaultValue={dataset.name}/>

                        <CrcInput_01 
                            id_dom={id_dom_input_goal} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Missiongoal" 
                            defaultValue={dataset.goal}/>
                        
                        <CrcDatepicker_01
                            id_dom={id_dom_input_ts_deadline}
                            wfCtrl={this.getWfCtrl()}
                            placeholder="Deadline"
                            startDate={dataset.ts_deadline?dataset.ts_deadline.toDate():undefined} 
                            />

                        <div className={"col s12"}>         
                            <label className={"btn waves-effect waves-light fabbi-file-upload"} style={{ zIndex: 0 }}>
                                <input type="file" accept="image/*" capture="user" id="missions-new-image" 
                                    onChange={
                                        function(inp) {
                                            var car = this.getWfCtrl().getCRC(id_dom_carousel); 
                                            g_wfCtrl.processNewImage_ImgSrc2(
                                                inp.target.files, 
                                                car.getCsvImgSrc.bind(car), 
                                                car.setCsvImgSrc.bind(car),
                                                this.getHtmlProcessor().getQP(), 
                                                this.getIdEntry(), 
                                                "image_src")
                                        }.bind(this)
                                    }/>
                                Image upload
                            </label>&nbsp;
                        </div>
                        
                        <div id="modal1_sub" className={"modal submodal modal-content"}>    
                        </div>            
                    </div>

                    <div className="{row}">
                        <CrcCarousel_01_Csv 
                            wfCtrl={this.getWfCtrl()}
                            id_dom={id_dom_carousel} 
                            csv={dataset.image_src} 
                            style={{border: "ridge", width: "100%", maxWidth: "300px", height: "15vh"}}/>
                    </div>

                    <div className={"row"}>                   
                        <div className={"modal-footer"}>
                            <a className={"btn waves-effect waves-light send modal-close"} id="missions-btn-leave" onClick={
                                () => { /* this.getWfCtrl().leaveMission(this.getIdEntry()); */ }
                            }>Leave Mission</a>
                            <a className={"btn waves-effect waves-light send modal-close"} id="missions-btn-save" 
                                onClick={
                                    (event) => { 
                                        console.log(this.constructor.name, id_dom_input_name, document.getElementById(id_dom_input_name));
                                        var obj = {};
                                        FabStd.addProp(obj, "name", this.getWfCtrl().getCRC(id_dom_input_name).getDbValue());   
                                        FabStd.addProp(obj, "goal", this.getWfCtrl().getCRC(id_dom_input_goal).getDbValue());   
                                        FabStd.addProp(obj, "ts_deadline", this.getWfCtrl().getCRC(id_dom_input_ts_deadline).getDbValue());   
                                        FabStd.addProp(obj, "image_src", this.getWfCtrl().getCRC(id_dom_carousel).getDbValue()); 

                                        this.getHtmlProcessor().getQP().updateDoc(
                                            this.getIdEntry(), 
                                            obj); 

                                        console.log(this.constructor.name, "this", this, "this.close", this.close);

                                        // close modal window ... which is the parent of this window
                                        this.getParent().close();
                                    }
                                }>Save</a>
                        </div>  
                    </div>                
                    <p/>                            
                </div> 

            </form>
        );
    }
}
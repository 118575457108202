import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import dataPool_HtmlProcessor from "./fabbi_datapool_htmlprocessor.js";  
import ReactDOM from 'react-dom';
import React from 'react';
import CrcEntryList_01 from "./fabbi_crc_entry_list_01.jsx";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import ControlledReactComponentParent from "./fabbi_controlled_react_component_parent.js";

export default class HtmlProcessor extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        // console.log(this.constructor.name, "constructor", this.getIdDOM(), "props.id_dom", props.id_dom);
        this._customData = { ... props.customData };
        this._cbInit_workDataPool = props.cbInit_workDataPool;  // callbackFunction to initialize datapool

        this.m_bBuildAll_ifDivSectionMissing = true;
        if (props.BuildAll_ifDivSectionMissing !== undefined) {
            this.m_bBuildAll_ifDivSectionMissing = !!props.BuildAll_ifDivSectionMissing;
        }

        this._id_htmlSuperSection       = props.id_htmlSuperSection;
        this._HtmlElementIdPrefix       = props.HtmlElementIdPrefix; 
        // ***************************************************************************    
        this._crcElem_entryList         = props.crcElem_entryList;
        this._crcPrototype_entryList    = props.crcPrototype_entryList;
        this._crcDefaultProps_entryList = props.crcDefaultProps_entryList;        
        this._crcExtensions_entryList   = props.crcExtensions_entryList;
        // ***************************************************************************
        this._portalsForActiveEntry = props.portalsForActiveEntry; // Array of { id_dom_forActiveEntry, crcPrototype_entry}

        this.m_leadingDataPool = props.leadingDataPool;
        
        this.m_workDataPool = new dataPool_HtmlProcessor(this, this.constructor.name);
        this.m_nCountAdd = 0;

        this._id_entryActive = undefined;
        
        this.m_arrTransaction_updateList_id_entry = [];

        // ********************************************************************
        // SpecialDataAction
        // ********************************************************************
        this.m_smSDA = new StdMap();          // id_sda   to id_entry 
        this.m_smSDA_idEntry = new StdMap();  // id_entry to id_sda
        // ********************************************************************
        this.m_smDirty = new StdMap();        // list of dirty leading entries
        // ********************************************************************

        this._entryListInRenderJob = false;
        this._shutDown = false;
    }

    // static function, should be also provided by derived classes for basic-props-settings!
    static completeProps(props) {
        if (!props.HtmlElementIdPrefix) props.HtmlElementIdPrefix = "id";
        if (!props.crcPrototype_entryList && !props.crcElem_entryList) props.crcPrototype_entryList = CrcEntryList_01;
        if (!props.crcDefaultProps_entryList) props.crcDefaultProps_entryList = {};
    }

    onEntryListComponentWillUnmount(crc) {
        console.debug(this.constructor.name, "onEntryListComponentWillUnmount", crc);
        this._buildAllDone = false;
        this.buildAll();
    }

    shutDown() {
        if (!this._shutDown) {
            this._shutDown = true;
            this.renderPortals();
        }
    }

    isShutDown() {
        return(this._shutDown);
    }

    getClassName() {
        return("HtmlProcessor");
    }
    
    isActiveEntry(id_entry) {
        return(id_entry === this.getActiveIdEntry());
    }

    // function to override
    getActiveIdEntry() {
        return(this._id_entryActive);
    }
    
    setActiveIdEntry(id_entry, bRefreshOnIdChange) {
        if (id_entry === this.getActiveIdEntry()) return; // no changes, break 

        console.log(this.constructor.name, "setActiveIdEntry", "renderEntry", id_entry, "onChange", "refresh", bRefreshOnIdChange);
        var id_entryActiveOld = this._id_entryActive;
        this._id_entryActive = id_entry;
        
        if (bRefreshOnIdChange === undefined || bRefreshOnIdChange === true) {
            this.refreshActiveEntry(id_entryActiveOld);   // refreshes old and new entry-display
        }
    }
    
    refreshActiveEntry(id_entryActiveOld) {
        if (id_entryActiveOld) {
            this.prepareAndProcessEntry(id_entryActiveOld);
        }

        if (id_entryActiveOld !== this._id_entryActive) {
            this.prepareAndProcessEntry(this._id_entryActive);
        }
    }

    // delivers the queryProcessor
    getQP() {
        return(this.getLeadingDataPool().getParent());
    }
    
    // setConfig_BuildAll_ifDivSectionMissing(bYes) {        
    //    this.m_bBuildAll_ifDivSectionMissing = bYes;            
    // }
    
    getLeadingDataPool() {
        return(this.m_leadingDataPool);
    }
    
    getHtmlElement(szPrefix, id_entry) {
        return(document.getElementById(getHtmlElementId(szPrefix, id_entry)));
    }

    getHtmlElementIdSuper(szBaseHtmlName) {
        return(this.getHtmlElementId(
                    szBaseHtmlName, 
                    undefined, 
                    { 
                        bOmitDataPoolId: true, 
                        bUseCssClassNamesInsteadOfIds: false 
                    }));
    }

    setHtmlElementIdPrefix(szHtmlElementIdPrefix) {
        if (szHtmlElementIdPrefix) {
            this._HtmlElementIdPrefix = szHtmlElementIdPrefix;
        }
    }
    
    getHtmlElementIdPrefix() {
        return(this._HtmlElementIdPrefix);
    }

    // the "objOptions.bOmitDataPoolId"-Parameter: DEFAULT-VALUE is false (in this case the DataPoolId will be integrated into the HtmlElementId returned)
    // the "objOptions.bUseCssClassNamesInsteadOfIds": if TRUE (default value is false), the returned "id" is prefixed with a "." and refers to a classname (which may exist multiple times in html-page)
    // the "objOptions.bOmitOptionalId"-Parameter: DEFAULT-VALUE is false
    getHtmlElementId(szBaseHtmlName, id_entry, objOptions) {        
        var szDataPoolId = "";
        var szBaseHtmlNameEnding = "";
        var arrTmp = szBaseHtmlName.split(".");

        if (!objOptions) objOptions = {};
        
        if (!objOptions.bOmitDataPoolId) objOptions.bOmitDataPoolId = false;
        if (!objOptions.bOmitOptionalId) objOptions.bOmitOptionalId = false;
        if (!objOptions.bUseCssClassNamesInsteadOfIds) objOptions.bUseCssClassNamesInsteadOfIds = false;
        
        if (arrTmp.length > 1) {
            szBaseHtmlName = arrTmp[0];
            szBaseHtmlNameEnding = "." + arrTmp[1];
        }
        
        if (!!objOptions.szOptional_AlternativeDataPoolId) {
            szDataPoolId = "_" + objOptions.szOptional_AlternativeDataPoolId;
        } else {
            if (!objOptions.bOmitDataPoolId && !!this.m_leadingDataPool.getId()) {
                szDataPoolId = "_" + this.m_leadingDataPool.getId();
            }
        }

        if (!id_entry  || id_entry === "") {
            id_entry = "";
        } else {
            id_entry = "_" + 
                HtmlProcessor.markBegin_idEntry + 
                id_entry + // the document-id or "entry-id"
                HtmlProcessor.markEnd_idEntry;
        }
                
        var szHeader = "";
        if (objOptions.bUseCssClassNamesInsteadOfIds && szBaseHtmlName.substr(0, 1) !== ".") {
            szHeader = ".";
        } 
        
        return(
                szHeader + 
                szBaseHtmlName +
                // (!objOptions.bOmitOptionalId?this.m_szOptionalId: "" */ ) + 
                szDataPoolId + 
                id_entry + 
                szBaseHtmlNameEnding
                );
    }        
    
    tsaUpdateStart() {     
        this.m_nCountAdd = 0;                
        this.m_bTransactionInProgress = true;
    }

    tsaAddIdEntryToUpdateList(id_entry) {
        if (this.isTsaInProgress() && id_entry) {
            this.m_arrTransaction_updateList_id_entry.push(id_entry);
        }
    }

    isTsaInProgress() {
        return(this.m_bTransactionInProgress);
    }

    tsaUpdateEnd() {
        this.m_bTransactionInProgress = false;

        if (this._wasEntryListInRenderJobAtLeastOnce()) {
            try { 
                // if "id_entry" is undefined ... this command will update
                // the "list" instead of the entry
                this.getCRC_entryList().updateMultipleEntries(this.m_arrTransaction_updateList_id_entry);
            } catch (e) {
                console.warn(this.constructor.name, "updateSingleEntry", "id_entry", this.m_arrTransaction_updateList_id_entry, "error", e);
            }
        }

        this.m_arrTransaction_updateList_id_entry.forEach((id_entry) => {
            if (this.isActiveEntry(id_entry)) {
                // just render active entry in existing portals
                this.updateActiveEntryInPortals();   
            }
            this._processSpecialDataAction(id_entry);        
        });

        this.m_arrTransaction_updateList_id_entry = [];
    }

    getCrcMountedEntryListFrame() {
        return(this.getCRC_entryList()?.getCrcMountedFrame());
    }

    getCRC_entryList() {
        return(this.getCRC(this.getIdDOM_entryList()));
    }

    /** Force an update of the react-EntryList or just a single Item of the List
    *  @param {String} id_entry the single entry's CRC that shall be updated (if tsa in progress, we just remember the
    *                           the update action for the end of the tsa).
    */ 
    updateSingleEntry(id_entry) {
        if (id_entry) {
            if (this.isTsaInProgress()) {
                this.tsaAddIdEntryToUpdateList(id_entry);
            } else {
                // console.log(this.constructor.name, "updateSingleEntry");
                // if we have an instance of a react-entryListGenerator 
                if (this._wasEntryListInRenderJobAtLeastOnce()) {
                    try { 
                        // if "id_entry" is undefined ... this command will update
                        // the "list" instead of the entry
                        this.getCRC_entryList().updateSingleEntry(id_entry);
                    } catch (e) {
                        console.warn(this.constructor.name, "updateSingleEntry", "id_entry", id_entry, "error", e);
                    }
                }

                if (this.isActiveEntry(id_entry)) {
                    // just render active entry in existing portals
                    this.updateActiveEntryInPortals();   
                }
         
                this._processSpecialDataAction(id_entry);        
            }
        }
    }

    getId_Div_SuperSection() {
        if (this._id_htmlSuperSection) return(this._id_htmlSuperSection);
        
        return(this.getHtmlElementIdPrefix() + "-div-section");
    }
    
    getId_Div_Section() {        
        return(this.getHtmlElementId(this.getId_Div_SuperSection(), undefined, false, false, true));
    }
    
    getId_Div_Entry(id_entry) {
        return(this.getHtmlElementId(this.getHtmlElementIdPrefix() + "-div-entry", id_entry));
    }    
    
    getLeadingDataPoolIdEntryIterator() {
        return this.m_leadingDataPool.getDataIdEntryIterator();
    }

    getLeadingDataPoolIdEntryIterator_Sorted() {
        return this.m_leadingDataPool.getDataIdEntryIterator_Sorted();
    }

    getWorkDataPoolIdEntryIterator() {
        return this.m_workDataPool.getDataIdEntryIterator();
    }

    getWorkDataPool() {
        return this.m_workDataPool;
    }

    getWorkDataPoolIdEntryIterator() {
        return this.m_workDataPool.getDataIdEntryIterator();
    }
    // ************************************************************************
    getEntryDataBundleIterator() {
        return(this.m_workDataPool.getDataIdEntryIterator());
    }    

    getEntryDataBundleIterator_Sorted() {
        return(this.m_workDataPool.getDataIdEntryIterator_Sorted());
    }    
    // ************************************************************************

    getEntryDataBundle_Empty(szIdEntry) {
        return(this.getWorkDataPool().initializedEntry(szIdEntry));
    }

    getEntryDataBundle(szIdEntry) {        
        // var entryL = this.getLeadingDataPoolEntry(szIdEntry, false);
        var entryW = this.getWorkDataPoolEntry(szIdEntry, false);    

        // console.log("CRC_ENTRYW", szIdEntry, entryW.datasetExtra);
        return(entryW);
    }

    // ************************************************************************    
    getLeadingDataPoolEntry(szIdEntry, bAddIfEntryNotExisting) {
        return this.m_leadingDataPool.getDataEntry(szIdEntry, bAddIfEntryNotExisting);        
    }
    
    getWorkDataPoolEntry(szIdEntry, bAddIfEntryNotExisting) {
        return this.m_workDataPool.getDataEntry(szIdEntry, bAddIfEntryNotExisting);        
    }

    requestSpecialDataAction(id_sda, id_entry, funcJob) {
        
        console.log(this.constructor.name, "requestSpecialDataAction", "id_sda", id_sda, "id_entry", id_entry);
        var objSDA = 
            this.m_smSDA.get(id_sda, true, 
                () => { return({ id_sda, id_entry, funcJob }) }
            );
        
        var sub_smSDA_idEntry = 
            this.m_smSDA_idEntry.get(objSDA.id_entry, true, 
                () => { return(new StdMap()) }
        );

        sub_smSDA_idEntry.set(objSDA.id_sda, objSDA);
        // this.getWfCtrl().saveToRegistry(id_sda, objSDA);

        return(objSDA);
    }

    // we saw the update of the whole list or an entry ... this function
    // does the job to accomplish the jobs requested via SDA 
    _processSpecialDataAction(id_entry) {
        // *********************************************************************************
        // SpecialDataAction processing ...
        // *********************************************************************************
        if (this.m_smSDA_idEntry.size > 0) {
            var arrIdEntry = [];
            var jobList = [];

            var sub_smSDA;
            var iter;
            var result;
            var szIdSDA;

            if (!id_entry) {
                arrIdEntry = Array.from( this.m_smSDA_idEntry.keys() );
            } else {
                arrIdEntry.push(id_entry);
                // console.log(this.constructor.name, "_processSpecialDataAction", "singelIdEntry", id_entry, "array", arrIdEntry);
            }

            arrIdEntry.forEach((id_entry) => {
                sub_smSDA = this.m_smSDA_idEntry.get(id_entry);
                if (sub_smSDA) {
                    iter = sub_smSDA.keys();
                    while (!(result = iter.next()).done) {
                        szIdSDA = result.value;
                        jobList.push(sub_smSDA.get(szIdSDA));
                    }
                }
            });
        
            jobList.forEach((job) => {
                var bDerequest = false;
                try {
                    // console.log(this.constructor.name, "_processSpecialDataAction", job);
                    if (job.funcJob() === false) {
                        console.log(this.constructor.name, "_processSpecialDataAction", "job-Function returned <false>! Derequest SDA!");
                        bDeRequest = true;
                    }
                } catch (error) {
                    console.log(this.constructor.name, "_processSpecialDataAction", "exception", error, "Derequest SDA!", "job", job);
                    bDerequest = true;
                }
                if (bDerequest) {
                    this.derequestSpecialDataAction(job.id_sda);
                }
            });
        }
        // *********************************************************************************
    }
    derequestSpecialDataAction(id_sda) {
        console.log(this.constructor.name, "derequestSpecialDataAction", "id_sda", id_sda);

        var objSDA = this.m_smSDA.get(id_sda, false);
        if (objSDA) {
            // we fetch the sub-map
            var sub_smSDA_idEntry = this.m_smSDA_idEntry.get(objSDA.id_entry);
            if (sub_smSDA_idEntry) {
                sub_smSDA_idEntry.delete(id_sda);
                // if no other entry is here in this map ... we delete the super-map
                if (sub_smSDA_idEntry.isEmpty()) {
                    this.m_smSDA_idEntry.delete(objSDA.id_entry);
                }
            }
            // kill the entry!
            this.m_smSDA.delete(id_sda);
            // we delete the data from the central registry ...
            // this.getWfCtrl().deleteFromRegistry(id_sda);
        }
    }

    _init_workDataPool() {
        this.m_workDataPool.clear();
        if (this._cbInit_workDataPool) this._cbInit_workDataPool(this.m_workDataPool, this);
    }
        
    prepareAll() {       
        this._clearDirtyEntryList();        
        this._init_workDataPool();

        var iter = this.getLeadingDataPoolIdEntryIterator_Sorted();
        var result; 

        // iterate through all entries
        while (!(result = iter.next()).done) {   
            var id_entry = result.value;
            var entry_work = this._privPrepareEntry(id_entry);
        }        
    }
    
    _privPrepareEntry(id_entry) {        
        var entry_work      = this.getWorkDataPoolEntry(id_entry);
        var entry_leading   = this.getLeadingDataPoolEntry(id_entry);
    
        if (entry_leading) {
            // console.log(this.constructor.name, "_privPrepareEntry", szState, entry_leading); 
            if (!this._isEntryStateDeleted(entry_leading) || !this._isEntryStateDeleted(entry_work)) {    
                var bNewEntry = false;            
                if (!entry_work) {                    
                    entry_work = this.getWorkDataPool().initializedEntry(id_entry);
                    bNewEntry = true;
                    // console.debug(this.constructor.name, "_privPrepareEntry", "NEW WORK ENTRY", entry_work);
                }
                Object.assign(  entry_work, 
                                { 
                                    id:             entry_leading.id,
                                    id_entry,
                                    id_qp:          entry_leading.id_qp,
                                    state:          entry_leading.state,

                                    dataset:        { ...entry_leading.dataset      },
                                    dataset_old:    { ...entry_leading.dataset_old  }
                                }
                            ); 
                this.onPrepareEntry(id_entry, entry_work);

                // console.debug(this.constructor.name, "_privPrepareEntry", entry_work);

                // shall entry be ignored ?
                if (entry_work.isIgnore()) { 
                    if (!bNewEntry) {

                        // delete it!
                        console.debug(this.constructor.name, "_privPrepareEntry", "deleting entry_work to ignore", entry_work);
                        this.getWorkDataPool().deleteDataEntry(id_entry);
                    }
                } else {
                    if (bNewEntry) {
                        // ok, DONT'T IGNORE!
                        this.getWorkDataPool().setDataEntry(id_entry, entry_work);
                    }
                }
            }
        } else {
            console.debug(this.constructor.name, "_privPrepareEntry", "leading id not found", "id_entry", id_entry, "entry_work", entry_work, "entry_leading", entry_leading);
        }

        return(entry_work);
    }    

    _isEntryStateDeleted(state_or_entry) {
        if (!state_or_entry) return(false);
        
        if (typeof state_or_entry === String) {
            return(state_or_entry === "deleted");
        } else {
            return(state_or_entry.state === "deleted");
        }
    }
    
    // this function shall be overwritten/overridden to build a "datasetExtra" from a "dataset" 
    onPrepareEntry(id_entry, entry) {        
    }

    /**  Call this to inform the processor about refreshed leading data */
    reportLeadingEntryDirty(id_entry) {
        var objInfo = { id_entry };
        this.m_smDirty.set(id_entry, objInfo); 
    }

    forEachLeadingDataPoolEntry_Sorted(funcForEach) {
        var iter = this.getLeadingDataPoolIdEntryIterator_Sorted();
        var result;            
        
        while ((!(result = iter.next()).done)) {
            funcForEach(
                this.getLeadingDataPoolEntry(result.value), // entry 
                result.value                                // key
            );
        }
    }

    forEachLeadingDataPoolKey(funcForEach) {
        var iter = this.getLeadingDataPoolIdEntryIterator();
        var result;            
        
        while ((!(result = iter.next()).done)) {
            funcForEach(
                result.value                                // key
            );
        }
    }

    forEachLeadingDataPoolEntry(funcForEach) {
        var iter = this.getLeadingDataPoolIdEntryIterator();
        var result;            
        
        while ((!(result = iter.next()).done)) {
            funcForEach(
                this.getLeadingDataPoolEntry(result.value), // entry 
                result.value                                // key
            );
        }
    }

    forEachWorkDataPoolKey(funcForEach) {
        var iter = this.getWorkDataPoolIdEntryIterator();
        var result;            
        
        while ((!(result = iter.next()).done)) {
            funcForEach(
                result.value                                // key
            );
        }
    }

    forEachWorkDataPoolEntry(funcForEach) {
        var iter = this.getWorkDataPoolIdEntryIterator();
        var result;            
        
        while ((!(result = iter.next()).done)) {
            funcForEach(
                this.getWorkDataPoolEntry(result.value),    // entry 
                result.value                                // key
            );
        }
    }

    _clearDirtyEntryList() {
        this.m_smDirty.clear();
    }

    _removeFromDirtyEntryList(id_entry) {
        if (id_entry) this.m_smDirty.delete(id_entry);
    }

    updateAllPending() {        
        // console.log(this.constructor.name, "updateAllPending");
        var arrToDo = this.m_smDirty.map((objInfo) => { console.debug(this.constructor.name, "updateAllPending", "objInfo", objInfo); return(objInfo.id_entry); });
        this._clearDirtyEntryList();

        console.debug(this.constructor.name, "arrToDo", arrToDo);

        arrToDo.forEach((id_entry) => this.prepareAndProcessEntry(id_entry) );
    }
    
    prepareAndProcessEntry(id_entry) {
        console.debug(this.constructor.name, "prepareAndProcessEntry", id_entry);
        if (!id_entry || id_entry === "") {
            console.debug(this.constructor.name, "prepareAndProcessEntry", "id_entry is empty!");
        } else {
            // prepare ...
            var entry_work = this._privPrepareEntry(id_entry);
            
            if (!entry_work) {
                console.debug(this.constructor.name, "prepareAndProcessEntry - ID NOT FOUND", id_entry);
            } else {
                // and now process ...
                console.debug(this.constructor.name, "prepareAndProcessEntry", "entry_work", entry_work);
                if (this._isEntryStateDeleted(entry_work)) {
                    this.deleteEntry(id_entry);
                } else {
                    this.modifyOrAddEntry(id_entry);
                }
            }

            // mark entry as "no longer pending"
            this._removeFromDirtyEntryList(id_entry)
        }
    }        
    
    deleteEntry(id_entry) {
        // if (id_entry === this.getActiveIdEntry()) {
            console.debug(this.constructor.name, "deleteEntry", "DELETING ACTIVE ENTRY", "id_entry", id_entry, "active_entry", this.getActiveIdEntry());
        // }
        
        // if TSA in in process this function will automatically
        // setup the update at the end of the TSA
        this.updateSingleEntry(id_entry);
    }    
        
    modifyOrAddEntry(id_entry) {
        // if TSA in in process this function will automatically
        // setup the update at the end of the TSA
        this.updateSingleEntry(id_entry);
    }        

    shutdown() {
        var htmlSection = document.getElementById(this.getId_Div_Section());              
        if (htmlSection) {    
            console.log("shutdown", "removing", this.getId_Div_Section(), htmlSection);        
            htmlSection.parentNode.removeChild(htmlSection);
        } 
    }
    
    buildAll_or_updateAllPending() {
        // console.log(this.constructor.name, "buildAll_or_updateAllPending");
        console.debug(this.constructor.name, "buildAll_or_updateAllPending");

        var bProcessed = false;

        // 1. check if the supersection exists ... if not: we don't do anything
        // 2. check if the (sub)section exists ... if so, we update the entries, marked as "pending_onRun" === "y" ... otherwise we do a full update ....            
        if (document.getElementById(this.getId_Div_SuperSection())) {
            if (!document.getElementById(this.getId_Div_Section())) {                
                if (this.m_bBuildAll_ifDivSectionMissing === true) {
                    // console.log(this.constructor.name, "buildAll_or_updateAllPending", this.getLeadingDataPool().getId(), "Starting ... buildAll()");                
                    this.buildAll();
                    bProcessed = true;
                } else {
                    // console.log(this.constructor.name, "buildAll_or_updateAllPending", "AVOIDING buildAll(), automated buildAll is deactivated!", this.m_bBuildAll_ifDivSectionMissing);
                }
            } else {
                // console.log(this.constructor.name, "buildAll_or_updateAllPending", this.getLeadingDataPool().getId(), "Starting ... updateAllPending()");
                if (!!this._buildAllDone) {
                    this.updateAllPending();
                } else {
                    this.buildAll();
                }
                bProcessed = true;
            }
        } else {
            // console.log(this.constructor.name, "buildAll_or_updateAllPending", "supersection missing, not updating");
        }

        // if not explicitely processed!
        if (!bProcessed) {
            // we clear the DirtyEntryList ...
            this._clearDirtyEntryList();
        }
    }

    hideOutput() {
        if (this._outputHidden) return;
        this._outputHidden = true;
        this.update();
    }

    isOutputHidden() {
        return(this._outputHidden);
    }

    showOutput() {
        if (!this._outputHidden) return;
        this._outputHidden = false;
        this.update();
    }

    _cleanUpSuperSection() {
        if (!this._crcPrototype_entryList) return; // if no entryList-Prototype, no clean!

        var id_htmlSuperSection = this.getId_Div_SuperSection();

        var bHtmlSuperSectionAndSection_Identical = (id_htmlSuperSection === this.getId_Div_Section());            
        if (!bHtmlSuperSectionAndSection_Identical) {
            var htmlSuperSection = document.getElementById(id_htmlSuperSection);
            if (htmlSuperSection) {
                for (var n=0; n < htmlSuperSection.childElementCount; ++n) {
                    var childElem = htmlSuperSection.children[n];
                    var crcEntryList = this.getWfCtrl().getCRC(childElem, false);
                    if (crcEntryList) {
                        // console.log(this.constructor.name, "buildAll", "checking-found entryList", n, crcEntryList.getIdDOM(), this.getIdDOM_entryList());
                        if (crcEntryList.getIdDOM() === this.getIdDOM_entryList()) {
                            crcEntryList.show();
                        } else {
                            crcEntryList.hide();
                        }
                    }
                }
            }
        }
    }
    
    buildAll(bCleanUpSuperSection) {
        this._buildAllDone = true;
        
        if (bCleanUpSuperSection) this._cleanUpSuperSection();

        console.debug(this.constructor.name, "buildAll");

        // we rebuild all entries ...
        this._clearDirtyEntryList();

        // console.log(this.constructor.name, "buildAll");
        this.prepareAll();
    
        // console.log(this.constructor.name, "buildAll");
        this.tsaUpdateStart();

        this.forEachLeadingDataPoolEntry_Sorted((entry_leading, id_entry) => {
            if (!this._isEntryStateDeleted(entry_leading)) {
                this.modifyOrAddEntry(id_entry);                
            }
        });

        this.tsaUpdateEnd();
    
        // is onBuildAllFinished defined ?
        if (this.props?.onBuildAllFinished) {
            console.debug(this.constructor.name, "buildAll", "onBuildAllFinished", "checking entries");
            this.forEachWorkDataPoolEntry((entry) => {
                console.debug(this.constructor.name, "buildAll", "onBuildAllFinished", entry);
            });
            console.debug(this.constructor.name, "buildAll", "onBuildAllFinished", "all entries listed");
            this.props.onBuildAllFinished(this);
        }
    }

    // *** PORTALS ***
    renderPortals() {

        var resultRender = [];

        if (this._portalsForActiveEntry) {
            this._portalsForActiveEntry.forEach((portal) => {

                if (!portal.sub_key) portal.sub_key = ControlledReactComponent.getNewUniqueTempId();
                if (!portal.sub_id_dom) portal.sub_id_dom = portal.sub_key;
        
                resultRender.push(this.renderPortal(portal));
            });
            this.planActiveEntryPortalsResort();
        }

        return(resultRender);
    }

    renderPortal(portal) {
        // this will "unmount" the old portal-entry ...
        var dst = document.getElementById(portal.id_dom);
        
        if (dst) {
            // if shutDown we display an empty portal ... to unmount all children ..
            if (!portal.crcPrototype_entry || this.isShutDown() ) {
                return(
                    ReactDOM.createPortal(
                        <div
                            description={"portal"}
                            key={ portal.sub_key } 
                        />, dst)
                );
            } else {
                // console.log(this.constructor.name, "renderPortal", 
                //    portal.id_dom, 
                //    portal.crcPrototype_entry, 
                //    this.isShutDown(),
                //    this.getActiveIdEntry()
                // );
                
                return(ReactDOM.createPortal(
                    <span style={ this.isOutputHidden()?{display: "none"}:{}}>
                        <portal.crcPrototype_entry 
                            { ... this._crcDefaultProps_entry }

                            parent={this} 
                            description={"portal"} 
                            key={ portal.sub_key } 
                            func_getActiveIdEntry={ portal.func_getActiveIdEntry } 
                            id_child={"ACTIVE_" + portal.sub_key } 
                            id_dom={ portal.sub_id_dom }               
                            wfCtrl={ this.getWfCtrl() } 
                            htmlProcessor={this}
                        />
                        </span>
                    , dst)
                );       
            }
        }
    }

    updateActiveEntryInPortals() {
        if (this._portalsForActiveEntry) {        
            var crc;
            this._portalsForActiveEntry.forEach((portal) => { 
                // crc = this.getChildCRC("ACTIVE_" + portal.sub_key);
                crc = this.getWfCtrl().getCRC(portal.sub_id_dom, false);
                if (crc) crc.update();
            });
            this.planActiveEntryPortalsResort();
        }
    }

    planActiveEntryPortalsResort() {
        /*
        // do after processing the event queue
        setTimeout(() => {
            this._portalsForActiveEntry.forEach((portal) => { 
                var elem = document.getElementById(portal.sub_id_dom);
                if (elem) {
                    var childElem;
                    var pN = elem.parentNode;
    
                    for (var i=0; i < pN.childElementCount; ++i) {
                        childElem = pN.children[i];
                        if (childElem === elem) {
                            childElem.classList.remove("hide");
                        } else {
                            childElem.classList.add("hide");
                        }
                    }
                }
            });
        }, 0);
        */
    }

    _wasEntryListInRenderJobAtLeastOnce() {
        return(this._entryListInRenderJob);
    }

    getIdDOM_entryList() {
        return(this.getId_Div_Section());
    }

    getDOMElement_superSection() {
        return(document.getElementById(this.getId_Div_SuperSection()));
    }

    hasEntryList() {
        return(this._crcPrototype_entryList || this._crcElem_entryList);
    }

    // ***************
    render() {
        var arrResult = [];

        arrResult.push(
            <div 
                id={this.getIdDOM()} 
                key={this.getIdDOM()} 
                name={this.constructor.name + " " + this.getId_Div_SuperSection()}
                />);

        var htmlSuperSection = this.getDOMElement_superSection();
        
        if (!htmlSuperSection) {
            console.debug(this.constructor.name, "render", "htmlSuperSection missing", "id_dom", this.getId_Div_SuperSection());
        } else {
        }
        if (htmlSuperSection && this.hasEntryList()) {
            console.debug(this.constructor.name, "render", "htmlSuperSection existing", "id_dom", this.getId_Div_SuperSection());

            // console.log(this.constructor.name, "render-entryList", this.getIdDOM_entryList());

            if (this._crcElem_entryList) {
                arrResult.push(
                    // build entryListCRC


                    ReactDOM.createPortal(    
                        <span style={ this.isOutputHidden()?{display: "none"}:{}}>                     
                            { 
                                React.cloneElement(
                                    this._crcElem_entryList, 
                                    {
                                        extensions:         this._crcExtensions_entryList,
                                        ... this._crcDefaultProps_entryList,               
                                        ... this._crcElem_entryList.props,
                                        
                                        key:                this.getIdDOM_entryList(), 
                                        id_dom:             this.getIdDOM_entryList(),  
                                        parent:             this,
                                        htmlProcessor:      this,
                                        wfCtrl:             this.getWfCtrl()
                                    }
                                )
                            }
                        </span>,
                        htmlSuperSection
                    )
                );
            } else {
                arrResult.push(
                    // build entryListCRC
                    ReactDOM.createPortal(    
                        <span style={ this.isOutputHidden()?{display: "none"}:{}}>                     
                            <this._crcPrototype_entryList 
                                extensions={this._crcExtensions_entryList}                             

                                { ... this._crcDefaultProps_entryList }

                                key={this.getIdDOM_entryList()} 
                                id_dom={this.getIdDOM_entryList()}  
                                parent={this}
                                htmlProcessor={this}
                                wfCtrl={this.getWfCtrl()}
                            />
                        </span>,
                        htmlSuperSection
                    )
                );
            }

            this._entryListInRenderJob = true;
        } else {
            console.log(
                this.constructor.name, 
                "render-entryList-failed", 
                this.getIdDOM_entryList(),
                "id_htmlSuperSection", this.getId_Div_SuperSection(),
                "htmlSuperSection", htmlSuperSection, 
                "crcPrototype_entryList", this._crcPrototype_entryList
                );
        }
        
        arrResult.push(this.renderPortals());

        return(arrResult);
    }

    getCustomData() {
        return(this._customData);
    }

    /*
    static getHtmlProcessorViaIdDom(id_dom) {
    }
    */
}

HtmlProcessor.markBegin_idEntry = "(eid)";
HtmlProcessor.markEnd_idEntry = "(/eid)";
HtmlProcessor.markRE_idEntry = /(?:\(eid\))(.*?)(?:\(\/eid\))/;
// /(\(eid\))(.*?)(\(\/eid\))/;

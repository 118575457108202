import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import DatePicker, { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de"; // the locale you want
import '../../css/custom/fabbi_styles_test.css';
import "react-datepicker/dist/react-datepicker.css";
import '../../css/custom/datepicker_overrides.css';

registerLocale("de", de); // register locale DE with the name you want

export default class CrcDatePicker_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();

        this._setupEventHandlers();

        if (this.props.defaultValue && this.props.defaultValue != "") {
            this.getDOMElement_Label().classList.add("active");
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this._removeEventHandlers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        if (this.props.startDate && prevProps.startDate && prevProps.startDate !== this.props.startDate) {
            if (this.props.startDate !== this.state.date) {
                this.setState({ date: this.props.startDate });
            }
        }
    }

    _setupEventHandlers() {
        /*
        this._handleChangeBound = function (event) { 
            console.log(this.constructor.name, "CHANGEEVENT");
        }.bind(this);

        this._handleFocusBound = function (event) {
            console.log(this.constructor.name, "FOCUS");
        }.bind(this);

        this.getDOMElement_Datepicker().addEventListener("input", this._handleChangeBound);
        this.getDOMElement_Datepicker().addEventListener("focus", this._handleFocusBound);
        */
    }

    _removeEventHandlers() {
        if (this._handleChangeBound) this.getDOMElement_Datepicker().removeEventListener("input", this._handleChangeBound);
        if (this._handleFocusBound) this.getDOMElement_Datepicker().removeEventListener("focus", this._handleFocusBound);
    }  

    getDOMElement_Label() {
        return(document.getElementById(this.getIdDOM_Label()));    
    }

    getDOMElement_Datepicker() {
        return(document.getElementById(this.getIdDOM_Datepicker()));    
    }

    getIdDOM_Datepicker() {
        return(this.getIdDOM() + "_if");
    }


    getIdDOM_Label() {
        return(this.getIdDOM() + "_lb");
    }

    getDbValue() {
        return(this.state.date?this.state.date:this.props.startDate);
    }

    render() {        
        var defaultClassName = "";
        var cN = defaultClassName;

        console.log(this.constructor.name, "render()", "HIER!");

        return(
            <div className={cN} id={this.getIdDOM()} style={{zIndex: 5 }}>
                <label 
                    style={{display: "block"}} 
                    id={this.getIdDOM_Label()} 
                    htmlFor={this.getIdDOM_Datepicker()}>
                        {this.props.placeholder}
                </label>
                <DatePicker 
                    selected={this.state.date?this.state.date:this.props.startDate}
                    locale="de"
                    timeFormat="HH:mm"
                    dateFormat="dd.MM.yyyy HH:mm"
                    showTimeInput
                    // customTimeInput={<ExampleCustomTimeInput />}
                    className={"input-field"}
                    placeholderText={this.props.placeholderText?this.props.placeholderText:this.props.placeholder}
                    onChange={ (e, d, f) => {  this.setState({date: e}); }}
                    shouldCloseOnSelect={true} 
                    />
            </div>    
        );
    }    
}
class PromiseData {
    constructor(parentChain, idPromise) {
        this.parentChain = parentChain;
        this.nIndex = undefined;
        this.id_promise = idPromise;
        this.promise = undefined;        
        this.onCleanUp = undefined;

        this.isPending = true;
        this.isFulfilled = false;
        this.isSettled = false;        

        this.isRolledBack = false;
        this.arr_onRollback = [];

        this.m_customData = {};
    }

    // no ARROW function allowed!
    pushPromise(customData, fncExecutor) {
        // just deliver to chain ...
        this.parentChain.pushPromise(customData, fncExecutor);
    }

    getCustomPromiseData() {
        return(this.m_customData);
    }

    getParentChain() {
        return(this.parentChain);
    }

    isAborted() {
        return(this.parentChain.m_bAborted);
    }

    getCustomChainData() {
        return(this.parentChain.getCustomChainData());
    }

    add_onRollback(fncOnRollback) {
        // add callback-function and bind it to "this" promiseData-object
        this.arr_onRollback.push(fncOnRollback.bind(this));
    }
}

export default class PromiseChain {
  /**
   * Constructor for Promise Chain
   * @constructor
   */
    constructor(customData) {
        // this.m_smapSrcInProgress = new StdMap();
        this.m_nCount_PromisesPending = 0;
        this.reset();
        this.m_onAllSettled = undefined;
        
        if (!!customData) {
            this.m_customData = customData;
        } else {
            this.m_customData = {};    // payload-object for customData
        }
    }

    // forceReset: if true, than the "reset" will be executed in any case
    reset(forceReset) {
        if (this.arePromisesPending() && !forceReset) {
            return(false);
        }
        this.m_arrPromisesData = [];                        
        this.m_nCount_PromisesPending = 0;
        this.m_bAborted = false;
        return(true);
    }

    // ccd is optional
    resetCustomChainData(ccd) {
        return(this.m_customData = !ccd?{}:ccd);
    }

    getCustomChainData() {
        return(this.m_customData);
    }

    _getPendingPromises() {
        var arrP = [];
        this.m_arrPromisesData.forEach((pd) => {
            if (pd.isPending) {
                arrP.push(pd.promise);                
            }
        });

        return(arrP);
    }

    _getPromiseDataIndex(id_promise) {
        for (var nInd = 0; nInd < this.m_arrPromisesData.length; ++nInd) {
            // console.log("pd", pd);                        
            if (id_promise === this.m_arrPromisesData[nInd].id_promise) { 
                return(nInd);
            }
        }
        return(-1); // not found!
    }     

    _getPromiseData(id_promise) {
        var nInd = this._getPromiseDataIndex(id_promise);
        if (nInd >= 0) return(this.m_arrPromisesData[nInd]);
        return(new PromiseData(0)); // return DUMMY
    }

    _reportPromiseFulfilled(pd, value) {
        // console.log("_reportPromiseFulfilled", pd.id_promise);
        pd.isFulfilled = true;
    }

    _reportPromiseRejected(pd, error) {        
        // console.log("_reportPromiseRejected", pd.id_promise);   
        pd.isRejected = true;
    }
    
    _reportPromiseSettled(pd) {        
        // console.log("_reportPromiseSettled", pd.id_promise, (this.m_bAborted?"ABORTED":"OK"));
        pd.isSettled = true;
        pd.isPending = false;
        --this.m_nCount_PromisesPending;        

        // Use "TIMEOUT" to go the way via the message-queue
        setTimeout(
            function check() {
                if (this.m_nCount_PromisesPending <= 0) {
                    this._startFullRollback();
                }        
            }.bind(this), 0, false);    
    }    

    _startFullRollback() {        
        if (this.arePromisesPending()) return(false);   // no way!

        var bAtLeastOneRollBack = false;

        for (var nInd = this.m_arrPromisesData.length-1; nInd >= 0; --nInd) {
            bDo_onAllSettled = false;

            // console.log("ROLLBACK", "Index", nInd);
            var pd = this.m_arrPromisesData[nInd];
            if (!pd.isSettled) {
                // should never be !! ERROR!
                break;
            }

            // if at leas one 
            if (!pd.isRolledBack) bAtLeastOneRollBack = true;
            this._doRollback(pd);
            if (this.arePromisesPending() > 0) break;
        }

        var bDo_onAllSettled = (bAtLeastOneRollBack && this.areNoPromisesPending()); 
        if (bDo_onAllSettled) {
            this._doAllSettled();
        }

        // true, if really full rollback was possible
        return(bDo_onAllSettled);
    }

    _doAllSettled() {
        console.log(this.constructor.name, "_doAllSettled", "length of arrPromisesData", this.m_arrPromisesData.length);
        if (this.m_bAborted) {
            console.log(this.constructor.name, "_doAllSettled", "chain was aborted");
            this.m_bAborted = false;
        }
        if (this.m_onAllSettled) {
            var fncGO = this.m_onAllSettled;
            
            // this.m_onAllSettled = undefined;     // deleted on 11.02.21
            
            // ... and ... GO!
            fncGO();
        }
    }

    _doRollback(pd) {        
        if (!pd.isRolledBack) {
            // console.log("_doRollback", pd.id_promise, pd);            
            for (var n=0; n < pd.arr_onRollback.length; ++n) {
                pd.arr_onRollback[n](pd);
            }
            pd.isRolledBack = true;
        }
    }

    _pushEmptyPromiseData() {        
        var pd = new PromiseData(this, "ID_" + (this.m_arrPromisesData.length + 1));        
        this.m_arrPromisesData.push(pd);
        pd.nIndex = this.m_arrPromisesData.length-1;
        ++this.m_nCount_PromisesPending;
        return(pd);
    }

    areNoPromisesPending() {
        return(this.m_nCount_PromisesPending <= 0);
    }

    arePromisesPending() {
        return(this.m_nCount_PromisesPending > 0);
    }

    isEmpty() {
        return(this.m_arrPromisesData.length === 0);
    }

    isAborted() {
        return(this.m_bAborted);
    }

    abort() {
        // don't allow any other promises to be added!
        // abort the operation as soon as possible
        if (this.arePromisesPending()) this.m_bAborted = true;
    }

    // NOTE: fncOnCompletion is not allowed to an "arrow function"
    set_onAllSettled(fncOnAllPromisesSettled) {
        this.m_onAllSettled = fncOnAllPromisesSettled.bind(this);
        // make the way through the message queue!
        setTimeout(() => { if (this.areNoPromisesPending()) this._doAllSettled(); }, 0);
    }    

    pushToDoListProcessing(arrList, funcForEach) {
        // console.debug("pushToDoListProcessing", arrList);
        var iterToDoList = arrList[Symbol.iterator]();

        function pcfExecutorWork(resolve, reject_unused) {
            // console.debug("pushToDoListProcessing", "pcfExecutorWork");
            var result;
            var ccd = this.getCustomChainData();
            var cpd = this.getCustomPromiseData();

            result = cpd.iterToDoList.next();
            // console.debug("pushToDoListProcessing", "pcfExecutorWork", "result", result);
            if (result.done) {
                // end reached, don't do anything special
                resolve({rc: "OK"});    // just resolve the actual promise
            } else {
                ++cpd.idx;

                // console.debug("pushToDoListProcessing", "idx", cpd.idx, "value", result.value);

                // end not reached ... we execute the next function ...
                var resultFunc = 
                    funcForEach(
                        result.value, // item                               
                        cpd.idx,
                        ccd
                    );
                        
                // console.debug("pushToDoListProcessing", "idx", cpd.idx, "result", result);
                if (resultFunc === undefined || resultFunc === null || !!resultFunc) {                
                    this.pushPromise(cpd, pcfExecutorWork);
                    resolve({rc: "OK"});
                } else {
                    // cancelled!
                    resolve({rc: "ERROR", idxFailed: cpd.idx});
                }
            }
        }

        this.pushPromise(
            // customData
            { 
                iterToDoList, 
                funcForEach,
                idx: -1
            },  
            pcfExecutorWork
        );
    }

    // NOTE: The fncExecutor must not be an arrow-function!
    pushPromise(customData, fncExecutor) {
        if (typeof customData === 'function') {
            /*
            if (!!fncExecutor) {
                // console.debug("pushPromise", "turnaround!");
                // console.debug("pushPromise", typeof fncExecutor, typeof customData, customData);
            }
            */
            var tmpHelp = customData;
            customData = fncExecutor;
            fncExecutor = tmpHelp;
        }
        

        let pd = this._pushEmptyPromiseData();
        if (!!customData) {
            pd.m_customData = customData;
        }
        if (this.m_bAborted) {
            pd.promise = new Promise(function(resolve, reject) {
                reject("*** CHAIN ABORTED ***");
                }.bind(pd));
        } else {
            // Standard
            if (!fncExecutor.hasOwnProperty('prototype')) { // FabStd.isFunctionBindable(fncExecutor)) {
                console.warn("pushPromise", "fnc", fncExecutor, "is not bindable and will produce errors!");
            }
            pd.promise = new Promise((resolve, reject) => setTimeout(() => { fncExecutor.bind(pd)(resolve, reject) }, 0));
        }

        pd.promise.then((value) => { pd.parentChain._reportPromiseFulfilled(pd, value); });
        pd.promise.catch((error) => { pd.parentChain._reportPromiseRejected(pd, error); });
        pd.promise.finally(() => { pd.parentChain._reportPromiseSettled(pd); });        
        // console.debug("pushPromise", "Promise created", pd.id_promise, pd);         

        return(pd.promise);
    }
}

import dataPool from "./fabbi_datapool.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";

class HtmlProcessor_DataPoolEntry {
    constructor(szIdEntry) {
        this.id = !szIdEntry?"":szIdEntry;
        this.id_entry = this.id;
        this.state = "empty"
        this.dataset = {};
        this.datasetExtra = {};
        this.ignore = false;
    }

    setIgnore() {
        this.ignore = true;
    }

    isIgnore() {
        return(this.ignore);
    }
}

export default class dataPool_HtmlProcessor extends dataPool {    
    constructor(parentHtmlProcessor, szDataPoolId) {
        super(parentHtmlProcessor, szDataPoolId);
    }

    initializedEntry(szIdEntry) {
        return(new HtmlProcessor_DataPoolEntry(szIdEntry));        
    }
    
    /** @return {HtmlProcessor} returns the Parent-HtmlProcessor */
    getHP() {
        return(this.getParent());
    }
}
import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class CrcImageOrInitials_01 extends ControlledReactComponent {
    componentDidMount() {
        super.componentDidMount();

        if (this.props.image_src && this.props.image_src.length > 0) {
            this._updateImageSrc();
        }        
    }    

    _updateImageSrc() {
        if (this.props.image_src && this.props.image_src.length > 0) {
            var szSrc = this.props.image_src.split(";")[0];

            this.getWfCtrl().getStorageManager().getURL(
                szSrc, 
                function(url) {
                    this.setState({ src: url });
                }.bind(this)
            );
        } else {
            this.setState({ src: "" });
        }     
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.image_src !== this.props.image_src) {
            this._updateImageSrc();
        }
    }

    render() {        
        var defaultClassName = "z-depth-2 fab-thumb circle fab-flexbox-center";
        var cN = defaultClassName;
        if (!!this.props.options && !!this.props.options.className) {
            cN = this.props.options.className;
        }

        if (!this.state.src || this.state.src === "") {
            return(
                <React.Fragment>
                    <div 
                        className={cN} 
                        style={{ ... this.props.style }}> 
                        { FabStd.toInitials(this.props.long_name) }
                    </div>
                </React.Fragment>
            );
        } else {
            return(            
                <React.Fragment>
                    <img 
                        className={cN} 
                        style={{ ... this.props.style }}
                        src={this.state.src} 
                        alt={this.props.long_name}
                        />
                </React.Fragment>
            );
        }
    }    
}
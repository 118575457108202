import React, { useRef } from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import CrcCarouselItem_01 from "./fabbi_crc_carousel_item_01.jsx"
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import StdMap from '/app-assets/js/fabstd/fabbi_stdmap.js';
import { easeOutQuad } from 'js-easing-functions';

/**
 * Construct Carousel instance
 * @constructor
 * @param {ReactProperties} props The member "options" of the props is resposnible for the appearance of the carousel.
 */
export default class CrcCarousel_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        // console.log(this.constructor.name, "constructor", props);

        /**
         * Options for the carousel
         * @member props#options
         * @prop {Number} duration
         * @prop {Number} dist
         * @prop {Number} shift
         * @prop {Number} padding
         * @prop {Number} numVisible
         * @prop {Boolean} fullWidth
         * @prop {Boolean} indicators
         * @prop {Boolean} noWrap
         * @prop {Function} onCycleTo
         */
        this._defaults = {
            duration: 1500, // ms
            dist: -100, // zoom scale TODO: make this more intuitive as an option
            shift: 0, // spacing for center image
            padding: 0, // Padding between non center items
            numVisible: 5, // Number of visible items in carousel
            fullWidth: false, // Change to full width styles
            indicators: false, // Toggle indicators
            noWrap: false, // Don't wrap around and cycle through items.
            onCycleTo: null, // Callback for when a new slide is cycled to.
            itemCRC: CrcCarouselItem_01 // the default itemCRC (render-class for Items)
        };
        this.options = { ...this._defaults };

        this._autoScrollBound = this._autoScroll.bind(this);
        this._trackBound = this._track.bind(this);        // Setup

        // console.log(this.constructor.name, "STYLE", props.style);
        // console.log(this.constructor.name, "constructor", "end of constructor");
    }

    _getItemCount() {
        console.log(this.constructor.name, "_getItemCount", this._getItemDataMap().size);

        return(this._getItemDataMap().size);
    }

    _getItem(nIndex) {
        var crcChild = this.getChildCRC(this._getItemDataMap().getIdEntryViaIndex(nIndex));

        if (!crcChild) {
            console.log(this.constructor.name, "_getItem", "not found", this._getItemDataMap().getIdEntryViaIndex(nIndex));
        }

        // console.log(this.constructor.name, "Index", nIndex, "map", this._getItemDataMap(), this._getItemDataMap().getIdEntryViaIndex(nIndex));
        return(crcChild);
    }

    _getItemDOMElement(nIndex) {
        var item = this._getItem(nIndex);
        if (!item) {
            console.log(this.constructor.name, "_getItemDOMElement", "oha", "index", nIndex, this._getItemDataMap().size, this._getItemDataMap().length);
        }
        // console.log(this.constructor.name, "_getItemDOMElement", "item", item, "index", nIndex);

        return(item.getDOMElement());
    }

    _getItemData(nIndex) {
        return(this._getItemDataMap().getEntryViaIndex(nIndex));
    }

    _getItemDataMap() {
        if (!this._smapData) {
            this._smapData = new StdMap();
            for (var i=1; i <= 10; ++i) {
                this._smapData.set("test_id_" + i, {id: "test_id_" + i, image_src: "Zdbp7vQ5rhRf5NXMdJVRcAOwCQi2_1581619882_260000000", long_name: "infotext " + i});
            }
        }
        return(this._smapData);
    }

    render() {
        // console.log(this.constructor.name, "render", this.getUniqueTempId(), "size", this._getItemDataMap().size);

        var settingsStyle = { width: "inherit", height: "inherit", ...this.props.style };
        var settingsClass = "carousel" + (!this.props.className?"":(" " & this.props.className)); 

        return(<div id={this.getIdDOM()} 
                    className={settingsClass} 
                    style={settingsStyle}>
            {
                this._getItemDataMap().map((data, index) => 
                    <this.options.itemCRC 
                        parent={this} 
                        wfCtrl={this.getWfCtrl()} 
                        key={data.id} 
                        id_child={data.id}
                        id_dom={this.getIdDOM() + "_" + data.id}
                        data={data}
                        />)
            }
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);

        console.log(this.constructor.name, "componentDidUpdate");
        this._startInitialization();
    } 

    // just run once after DOM-entry is created ... afterward componentDidUpdate(..) is used
    componentDidMount() {
        super.componentDidMount();
        // console.log(this.constructor.name, "componentDidMount()", this.getUniqueTempId());

        // setTimeout(() => {
                this._startInitialization();
                this._setupEventHandlers();
        //    }, 0);
    }

    _startInitialization(options) {

        // console.log(this.constructor.name, "_startInitialization", "element", this.getDOMElement());

        this.options = { ...this.options, ...this.props.options }

        // Setup
        this.hasMultipleSlides = this._getItemCount() > 1; // this.$el.find('.carousel-item').length > 1;
        // this.showIndicators = this.options.indicators && this.hasMultipleSlides;
        this.noWrap = this.options.noWrap || !this.hasMultipleSlides;
        this.pressed = false;
        this.dragged = false;
        this.center = 0;
        this.lastCenter = -1;
        this.offset = this.target = 0;
        this.images = [];
        this.itemWidth = 65; // this.$el.find('.carousel-item').first().innerWidth();
        this.itemHeight = 65; // this.$el.find('.carousel-item').first().innerHeight();
        this.dim = this.itemWidth * 2 + this.options.padding || 1; // Make sure dim is non zero for divisions.
  
        // Full Width carousel setup
        if (this.options.fullWidth) {
          this.options.dist = 0;
          this._setCarouselHeight();

          /*
          // Offset fixed items when indicators.
          if (this.showIndicators) {
            this.$el.find('.carousel-fixed-item').addClass('with-indicators');
          }
          */
        }
  
        // Iterate through slides
        /*
        this.$indicators = $('<ul class="indicators"></ul>');
        this.$el.find('.carousel-item').each(function (el, i) {
          this.images.push(el);
          if (this.showIndicators) {
            var $indicator = $('<li class="indicator-item"></li>');
  
            // Add active to first by default.
            if (i === 0) {
              $indicator[0].classList.add('active');
            }
  
            this.$indicators.append($indicator);
          }
        });
        */

        /*
        if (this.showIndicators) {
          this.$el.append(this.$indicators);
        }
        */

        this._scroll(this.offset);
    }

    _getNumVisible() {
        return(Math.min(this._getItemCount(), this.options.numVisible));
    }
    
    componentWillUnmount() {
        // console.log(this.constructor.name, "componentWillUnmount()", this.state.stage);
        this._removeEventHandlers();
    }

   /**
    * Setup Event Handlers
    */
    _setupEventHandlers() {
        this._touchmoveActive = false;

        this._handleCarouselTapBound = this._handleCarouselTap.bind(this);
        this._handleCarouselDragBound = this._handleCarouselDrag.bind(this);
        this._handleCarouselReleaseBound = this._handleCarouselRelease.bind(this);
        this._handleCarouselClickBound = this._handleCarouselClick.bind(this);

        if (typeof window.ontouchstart !== 'undefined') {   
            this._handleCarouselTouchstartBound = this._handleCarouselTouchstart.bind(this);
            this._handleCarouselTouchendBound = this._handleCarouselTouchend.bind(this);

            this.getDOMElement().addEventListener('touchstart', this._handleCarouselTouchstartBound);
            // this.getDOMElement().addEventListener('touchstart', this._handleCarouselTapBound);
            this.getDOMElement().addEventListener('touchmove', this._handleCarouselDragBound);
            // this.getDOMElement().addEventListener('touchend', this._handleCarouselReleaseBound);
            this.getDOMElement().addEventListener('touchend', this._handleCarouselTouchendBound);         
        }

        this.getDOMElement().addEventListener('mousedown', this._handleCarouselTapBound);
        this.getDOMElement().addEventListener('mousemove', this._handleCarouselDragBound);
        this.getDOMElement().addEventListener('mouseup', this._handleCarouselReleaseBound);
        this.getDOMElement().addEventListener('mouseleave', this._handleCarouselReleaseBound);
        this.getDOMElement().addEventListener('click', this._handleCarouselClickBound);

        /*
        if (this.showIndicators && this.$indicators) {
          this._handleIndicatorClickBound = this._handleIndicatorClick.bind(this);
          this.$indicators.find('.indicator-item').each(function (el, i) {
            el.addEventListener('click', this._handleIndicatorClickBound);
          }.bind(this));
        }
        */

        // Resize
        this._handleThrottledResizeBound = FabStd.throttle(this._handleResize.bind(this), 200);

        window.addEventListener('resize', this._handleThrottledResizeBound);
    }

    _removeEventHandlers() {  
        if (typeof window.ontouchstart !== 'undefined') {
            this.getDOMElement().removeEventListener('touchstart', this._handleCarouselTouchstartBound);
            this.getDOMElement().removeEventListener('touchend', this._handleCarouselTouchendBound);
 
            // this.getDOMElement().removeEventListener('touchstart', this._handleCarouselTapBound);
            this.getDOMElement().removeEventListener('touchmove', this._handleCarouselDragBound);
            // this.getDOMElement().removeEventListener('touchend', this._handleCarouselReleaseBound);
        }

        this.getDOMElement().removeEventListener('mousedown', this._handleCarouselTapBound);
        this.getDOMElement().removeEventListener('mousemove', this._handleCarouselDragBound);
        this.getDOMElement().removeEventListener('mouseup', this._handleCarouselReleaseBound);
        this.getDOMElement().removeEventListener('mouseleave', this._handleCarouselReleaseBound);
        this.getDOMElement().removeEventListener('click', this._handleCarouselClickBound);

        /*
        if (this.showIndicators && this.$indicators) {
            this.$indicators.find('.indicator-item').each(function (el, i) {
                el.removeEventListener('click', this.handleIndicatorClickBound);
            }.bind(this));
        }
        */

        window.removeEventListener('resize', this._handleThrottledResizeBound);
    }

    _handleCarouselTouchstart(e) {
        // console.log(this.constructor.name, "_handleCarouselTouchstart", e);
        var result = this._handleCarouselTap(e);
        this._touchmoveActive = true;
        return(result);
    }

    _handleCarouselTouchend(e) {
        // console.log(this.constructor.name, "_handleCarouselTouchend", e);
        var result = this._handleCarouselRelease(e);
        this._touchmoveActive = false;
        return(result);
    }
  
   /**
    * Handle Carousel Tap
    * @param {Event} e
    */
    _handleCarouselTap(e) {
        
        /* 
        // original implementation

        // Fixes firefox draggable image bug
        if (e.type === 'mousedown' && $(e.target).is('img')) {
            e.preventDefault();
        }
        this.pressed = true;
        this.dragged = false;
        this.verticalDragged = false;
        this.reference = this._xpos(e);
        this.referenceY = this._ypos(e);

        this.velocity = this.amplitude = 0;
        this.frame = this.offset;
        this.timestamp = Date.now();
        clearInterval(this.ticker);
        this.ticker = setInterval(this._trackBound, 100);
        */

        // modified implementation
        // added by FaGu ... to enable dragging with a vertical movement
        if (e.type === "mousedown" || e.type === "touchstart") {
            var found = this.getWfCtrl().getCRC(e.target, true);
            // console.log("_handleCarouselTap", "Type", e.type, e.target, found);

            // DRAGGING of an Item is only allowed if on top of z-order (z-index 0) 
            // here, we look at the "ACTIVE" element
            if (!found || !found.isActive || found.isActive() === false) {
                // console.log("_handleCarouselTap", "not active - preventing default");
                e.preventDefault();
            } else {
                this.timestampMousedownTouchstart = Date.now();

                /*
                if (!!this.lastMouseMoveY_2) {
                    // console.log("_handleCarouselTap", "TARGET", e.target.draggable);
                    var deltaY_abs = this.lastMouseMoveY_2 - this._ypos(e);
                    var deltaX_abs = this.lastMouseMoveX_2 - this._xpos(e);
                    // console.log("DRAG-DELTA-Y", deltaY_abs, this.lastMouseMoveY_2, this.lastMouseMoveY_1, this._ypos(e));
                    if (deltaY_abs === 0) {
                        // console.log("DRAG-DELTA-Y", "preventingDefault");
                        console.log("_handleCarouselTap", "deltaY_abs = 0, not moving - preventing default CANCELLED", "deltaX_abs", deltaX_abs, e);
                        if (e.type === "mousedown") {
                            this.verticalDragged = true;
                        } else {
                            e.preventDefault();
                        }
                    } else {               
                        if (e.type === "touchstart") {
                            // console.log("_handleCarouselTap", "deltaY_abs not 0", "PREVENT because touchstart");
                            e.preventDefault();
                        } else {
                            // console.log("_handleCarouselTap", "deltaY_abs not 0", "NO PREVENT");
                        }
                    }
                }
                */
            }
        }
        this.verticalDragged = false;

        this.pressed = true;
        this.dragged = false;

        this._handleMovement(e);

        this.velocity = this.amplitude = 0;
        this.frame = this.offset;
        this.timestamp = Date.now();
        clearInterval(this.ticker);
        this.ticker = setInterval(this._trackBound, 100);
    }

    _handleMovement(e) {
        // console.log("_handleMovement");
        this.reference = this._xpos(e);
        this.referenceY = this._ypos(e);
    }

    _setDragData(item) {
        console.log(this.constructor.name, "_setDragData", "for item", item);
    }

    _reportItemDragAction(szDragAction, dragevent, item) {
        console.log(this.constructor.name, "_reportItemDragAction", szDragAction, item);
        
        var bItemIsBeingDragged;
        if (szDragAction === "dragstart") {
            bItemIsBeingDragged = true;
            this._setDragData(item);
        } else {
            bItemIsBeingDragged = false;
        }
        
        if (bItemIsBeingDragged !== !!this.bItemIsBeingDragged) {
            this.bItemIsBeingDragged = bItemIsBeingDragged;
            this.pressed = false;
        }
    }

    _isDragstartAllowed() {
        return(this.pressed & (Date.now() - this.timestampMousedownTouchstart > 200) /* | !!this.verticalDragged */);
    }

   /**
    * Handle Carousel Drag
    * @param {Event} e
    */
    _handleCarouselDrag(e) {
        /*
        var x = void 0,
            y = void 0,
            delta = void 0,
            deltaY = void 0;

        if (this.pressed) {
            x = this._xpos(e);
            y = this._ypos(e);
            delta = this.reference - x;
            deltaY = Math.abs(this.referenceY - y);
            if (deltaY < 30 && !this.verticalDragged) {
                // If vertical scrolling don't allow dragging.
                if (delta > 2 || delta < -2) {
                    this.dragged = true;
                    this.reference = x;
                    this._scroll(this.offset + delta);
                }
            } else if (this.dragged) {
                // If dragging don't allow vertical scroll.
                e.preventDefault();
                e.stopPropagation();
                return false;
            } else {
                // Vertical scrolling.
                this.verticalDragged = true;
            }
        }

        if (this.dragged) {
            // If dragging don't allow vertical scroll.
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        */

        // modified implementation
        var x, y, deltaX_abs, deltaY_abs, deltaX, deltaY;

        if (this.pressed) {
            // console.log("_handleCarouselDrag", "PRESSED");
        }

        // added by FaGu to get rid of some problems caused by the dragmode
        if (this.pressed && e.buttons === 0 && e.type === "mousemove") {
            // act ... as if the mousebutton was released FIRST
            this._handleCarouselReleaseBound(e);  // 09.01.2020: added Parameter "e" due to errors
            // e.preventDefault();                   // 09.01.2020:  added
            // e.stopPropagation();                  // 09.01.2020:  added
            return(false);                        // 09.01.2020:  added return(false) 
        }

        if (this.pressed && !this.bItemIsBeingDragged) {
            // console.log("DRAG", "_handleCarouselDrag Step 2", "pressed", this.pressed, e.target);

            x = this._xpos(e);
            y = this._ypos(e);
            deltaX = this.reference - x;
            deltaY = this.referenceY - y;
            deltaX_abs = Math.abs(deltaX);
            deltaY_abs = Math.abs(deltaY);

            // console.log("_handleCarouselDrag", "PRESSED", "deltaX_abs", deltaX_abs, "deltaY_abs", deltaY_abs);

            if (deltaX_abs > 2 && deltaY_abs < 30 && !this.verticalDragged) {
                console.log("DRAG", "we are going to scroll", "not too much DELTA-Y: " + deltaY_abs);

                // if remarkable delta-x and no extreme delta-y
                this.dragged = true;
                this.reference = x;
                this._scroll(this.offset + deltaX);
            } else { 
                if (this.dragged) {
                    // console.log("DRAG", "preventing propagation 1");

                    // If dragging don't allow vertical scroll.
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                } else {
                    if (deltaY_abs > 1) {
                        // console.log("DRAG", "recognized vertical dragging");

                        // Vertical scrolling.
                        this.verticalDragged = true;

                        if (e.type === "mousemove") {
                            // console.log("DRAG MOuSEMOuVE");

                            // this._handleCarouselTap(e);
                            this._handleMovement(e);
                            return;
                        }
                    }
                }
            }          

            // added by FaGu (see "_handleCarouselTap" also)
            this.lastMouseMoveY_1 = undefined;
            this.lastMouseMoveY_2 = undefined;
        } else {
            // added by FaGu (see "_handleCarouselTap" also)
            this.lastMouseMoveY_2 = this.lastMouseMoveY_1;
            this.lastMouseMoveY_1 = this._ypos(e);
        }

        if (this.dragged) {
            // console.log("DRAG", "was DRAGGED - preventing propagation 2");

            // If dragging don't allow vertical scroll.
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }
  
   /**
    * Handle Carousel Release
    * @param {Event} e
    */
    _handleCarouselRelease(e) {
        if (this.pressed) {
            this.pressed = false;
        } else {
            return;
        }

        // console.log(this.constructor.name, "_handleCarouselRelease", e.type);
  
        clearInterval(this.ticker);

        this.target = this.offset;
        if (this.velocity > 10 || this.velocity < -10) {
            this.amplitude = 0.9 * this.velocity;
            this.target = this.offset + this.amplitude;
        }
       
        this.target = Math.round(this.target / this.dim) * this.dim;
  
        // No wrap of items.
        if (this.noWrap) {
            if (this.target >= this.dim * (this._getItemCount() - 1)) {
                this.target = this.dim * (this._getItemCount() - 1);
            } else if (this.target < 0) {
                this.target = 0;
            }
        }
        this.amplitude = this.target - this.offset;
        this.timestamp = Date.now();
        requestAnimationFrame(this._autoScrollBound);
  
        if (this.dragged) {
            e.preventDefault();
            e.stopPropagation();
        }
        return false;
    }
  
   /**
    * Handle Carousel CLick
    * @param {Event} e
    */
    _handleCarouselClick(e) {
        // Disable clicks if carousel was dragged.
        if (this.dragged) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        } else if (!this.options.fullWidth) {
            var clickedIndex = $(e.target).closest('.carousel-item').index();
            var diff = this._wrap(this.center) - clickedIndex;

            // Disable clicks if carousel was shifted by click
            if (diff !== 0) {
                e.preventDefault();
                e.stopPropagation();
            }
            this._cycleTo(clickedIndex);
        }
    }
  
   /**
    * Handle Indicator CLick
    * @param {Event} e
    */
    _handleIndicatorClick(e) {
          e.stopPropagation();
  
          var indicator = $(e.target).closest('.indicator-item');
          if (indicator.length) {
            this._cycleTo(indicator.index());
          }
    }
  
   /**
    * Handle Throttle Resize
    * @param {Event} e
    */
    _handleResize(e) {
        if (this.options.fullWidth) {
            /*
            this.itemWidth = this.$el.find('.carousel-item').first().innerWidth();
            this.imageHeight = this.$el.find('.carousel-item.active').height();
            this.dim = this.itemWidth * 2 + this.options.padding;
            this.offset = this.center * 2 * this.itemWidth;
            this.target = this.offset;
            this._setCarouselHeight(true);
            */
        } else {
            this._scroll();
        }
    }
  
   /**
    * Set carousel height based on first slide
    * @param {Booleam} imageOnly - true for image slides
    */
    _setCarouselHeight(imageOnly) {
        /*
        var _this65 = this;

        var firstSlide = 
                this.$el.find('.carousel-item.active').length ? 
                this.$el.find('.carousel-item.active').first() : 
                this.$el.find('.carousel-item').first();
        var firstImage = firstSlide.find('img').first();
        if (firstImage.length) {
            if (firstImage[0].complete) {
                // If image won't trigger the load event
                var imageHeight = firstImage.height();
                if (imageHeight > 0) {
                    this.$el.css('height', imageHeight + 'px');
                } else {
                    // If image still has no height, use the natural dimensions to calculate
                    var naturalWidth = firstImage[0].naturalWidth;
                    var naturalHeight = firstImage[0].naturalHeight;
                    var adjustedHeight = this.$el.width() / naturalWidth * naturalHeight;
                    this.$el.css('height', adjustedHeight + 'px');
                }
            } else {
                // Get height when image is loaded normally
                firstImage.one('load', function (el, i) {
                _this65.$el.css('height', el.offsetHeight + 'px');
                });
            }
        } else if (!imageOnly) {
            var slideHeight = firstSlide.height();
            this.$el.css('height', slideHeight + 'px');
        }
        */
    }
  
   /**
    * Get x position from event
    * @param {Event} e
    */
    _xpos(e) {
        // touch event
        if (e.targetTouches && e.targetTouches.length >= 1) {
            return e.targetTouches[0].clientX;
        }

        // mouse event
        return e.clientX;
    }
  
   /**
    * Get y position from event
    * @param {Event} e
    */
    _ypos(e) {
        // touch event
        if (e.targetTouches && e.targetTouches.length >= 1) {
            return e.targetTouches[0].clientY;
        }

        // mouse event
        return e.clientY;
    }
  
   /**
    * Wrap index
    * @param {Number} x
    */
    _wrap(x) {
          return x >= this._getItemCount() ? x % this._getItemCount() : x < 0 ? this._wrap(this._getItemCount() + x % this._getItemCount()) : x;
    }
  
   /**
    * Tracks scrolling information
    */
    _track() {
        var now = void 0,
            elapsed = void 0,
            delta = void 0,
            v = void 0;
  
        now = Date.now();
        elapsed = now - this.timestamp;
        this.timestamp = now;
        delta = this.offset - this.frame;
        this.frame = this.offset;

        v = 1000 * delta / (1 + elapsed);
        this.velocity = 0.8 * v + 0.2 * this.velocity;
    }
  
   /**
    * Auto scrolls to nearest carousel item.
    */
    _autoScroll() {
        var elapsed = void 0,
            delta = void 0;
  
        if (this.amplitude) {
            elapsed = Date.now() - this.timestamp;
            // OLD implementation
            // delta = this.amplitude * Math.exp(-elapsed / this.options.duration);
            // delta = this.amplitude * Math.pow(Math.exp(1), -elapsed / this.options.duration);
           
            // NEW IMPLEMENTATION
            // the AMPLITUDE is the difference between the TARGET-Position (pixels), we want to scroll to, 
            // and the OFFSET-Position (= pixels, start Position of scrolling)
            // DELTA is the rest of the way in pixels to the TARGET-Position 

            // the duration is the timeperiod after which an autoscroll-action shall come to an end
            delta = this.amplitude * (1 - easeOutQuad(elapsed, 0, 1, this.options.duration));

            if ((delta > 2 || delta < -2) && elapsed < this.options.duration) {
                this._scroll(this.target - delta);
                requestAnimationFrame(this._autoScrollBound);
            } else {
                this._scroll(this.target);
            }
        }
    }

    _updateCenter() {
        if (this._getItemCount() <= 0) return;    // no entries ? nothing to do ...

        this.lastCenter = this.center;
        this.center = Math.floor((this.offset + (this.dim / 2)) / this.dim);
    
        if (this.lastCenter !== this.center) {
            // console.log("_updateCenter", "lastCenter", this.lastCenter, "center", this.center);
            if (this.lastCenter) {
                this._getItem(this._wrap(this.lastCenter)).setActive(false);
            }
            // console.log(this.constructor.name, "_updateCenter", this.center, this._wrap(this.center), this._getItemCount());
            this._getItem(this._wrap(this.center)).setActive(true);
        }
    }
  
   /**
    * Scroll to target
    * @param {Number} x
    */
    _scroll(x) {
        // console.log(this.constructor.name, "_scroll", this.getUniqueTempId(), "this DOME", this.getDOMElement());
        if (this._getItemCount() <= 0) return; // nothing to scroll
        if (!this.getDOMElement()) return;

        // Track scrolling state
        if (!this.getDOMElement().classList.contains('scrolling')) {
            this.getDOMElement().classList.add('scrolling');
            this.scrolling = true;
        }

        if (this.scrollingTimeout != null) {
            window.clearTimeout(this.scrollingTimeout);
        }

        this.scrollingTimeout = window.setTimeout(() => {
            if (this.getDOMElement()) this.getDOMElement().classList.remove('scrolling');
            this.scrolling = false;
        }, this.options.duration);
  
        // Start actual scroll
        var i = void 0,
            half = void 0,
            delta = void 0,
            dir = void 0,
            tween = void 0,
            el = void 0,
            alignment = void 0,
            zTranslation = void 0,
            tweenedOpacity = void 0,
            centerTweenedOpacity = void 0;

        var numVisibleOffset = 1 / this._getNumVisible();
        this.offset = typeof x === 'number' ? x : this.offset;

        this._updateCenter();

        delta = this.offset - (this.center * this.dim);
        dir = delta < 0 ? 1 : -1; 
        tween = -dir * delta * 2 / this.dim;
        half = this._getItemCount() >> 1;

        // console.log("_scroll", "x", x, "lastCenter", lastCenter, "center", this.center, "offset", this.offset, "dim", this.dim, "delta", delta);

        if (this.options.fullWidth) {
            alignment = 'translateX(0)';
            centerTweenedOpacity = 1;
        } else {
            alignment = 
                "translateX(" + (this.getDOMElement().clientWidth - this.itemWidth) / 2 + "px)" + " " +
                "translateY(" + (this.getDOMElement().clientHeight - this.itemHeight) / 2 + "px)";
            centerTweenedOpacity = 1 - numVisibleOffset * tween;
        }

        /*
        // Set indicator active
        if (this.showIndicators) {
            var diff = this.center % this._getItemCount();
            var activeIndicator = this.$indicators.find('.indicator-item.active');
            if (activeIndicator.index() !== diff) {
                activeIndicator.removeClass('active');
                this.$indicators.find('.indicator-item').eq(diff)[0].classList.add('active');
            }
        }
        */

        // center-Element
        // Don't show wrapped items.
        if (!this.noWrap || this.center >= 0 && this.center < this._getItemCount()) {
            el = this._getItemDOMElement(this._wrap(this.center));

            // console.log(this.constructor.name, "_scroll", "EL", el, "index", this._wrap(this.center));

            /*
            // Add active class to center item.
            if (!$(el).hasClass('active')) {
                this.$el.find('.carousel-item').removeClass('active');
                el.classList.add('active');
            }
            */

            // i ist hier undefiniert!
            /*
            var transformString = 
                    alignment + " " + 
                    "translateX(" + -delta / 2 + "px)" + " " + 
                    "translateX(" + dir * this.options.shift * tween * i + "px)" + " " +
                    "translateZ(" + this.options.dist * tween + "px)";
            
            console.log("_tsa", "_1", transformString, centerTweenedOpacity);
            this._updateItemStyle(el, centerTweenedOpacity, 0, transformString);
            */
        }

        // right side (next to center Element)
        for (i = 1; i <= half; ++i) {
            // right side
            if (this.options.fullWidth) {
                zTranslation = this.options.dist;
                tweenedOpacity = i === half && delta < 0 ? 1 - tween : 1;
            } else {
                zTranslation = this.options.dist * (i * 2 + tween * dir);
                tweenedOpacity = 1 - numVisibleOffset * (i * 2 + tween * dir);
            }

            // Don't show wrapped items.
            if (!this.noWrap || this.center + i < this._getItemCount()) {
                el = this._getItemDOMElement(this._wrap(this.center + i));

                var _transformString = 
                    alignment + " " + 
                    "translateX(" + (this.options.shift + (this.dim * i - delta) / 2) + "px)" + " " + 
                    "translateZ(" + zTranslation + "px)";
                this._updateItemStyle(el, tweenedOpacity, -i, _transformString);
            }
  
            // left side
            if (this.options.fullWidth) {
                zTranslation = this.options.dist;
                tweenedOpacity = i === half && delta > 0 ? 1 - tween : 1;
            } else {
                zTranslation = this.options.dist * (i * 2 - tween * dir);
                tweenedOpacity = 1 - numVisibleOffset * (i * 2 - tween * dir);
            }
            // Don't show wrapped items.
            if (!this.noWrap || this.center - i >= 0) {
                el = this._getItemDOMElement(this._wrap(this.center - i));

                var _transformString2 = 
                    alignment + " " + 
                    "translateX(" + (-this.options.shift + (-this.dim * i - delta) / 2) + "px)" + " " + 
                    "translateZ(" + zTranslation + "px)";
                this._updateItemStyle(el, tweenedOpacity, -i, _transformString2);
            }
        }
  
        // center
        // Don't show wrapped items.
        if (!this.noWrap || this.center >= 0 && this.center < this._getItemCount()) {
            el = this._getItemDOMElement(this._wrap(this.center));

            var _transformString3 = 
                alignment + " " + 
                "translateX(" + -delta / 2 + "px)" + " " + 
                "translateX(" + dir * this.options.shift * tween + "px)" + " " + 
                "translateZ(" + this.options.dist * tween + "px)";
            // console.log("_tsa", "_3", _transformString3, centerTweenedOpacity);
            this._updateItemStyle(el, centerTweenedOpacity, 0, _transformString3);
        }
  
        // onCycleTo callback
        var currItem = this._getItemDOMElement(this._wrap(this.center)); 
        if (this.lastCenter !== this.center && typeof this.options.onCycleTo === 'function') {
            this.options.onCycleTo.call(this, currItem, this.dragged);
        }
  
        // One time callback
        if (typeof this.oneTimeCallback === 'function') {
            this.oneTimeCallback.call(this, currItem, this.dragged);
            this.oneTimeCallback = null;
        }
    }
  
   /**
    * Update Item Style
    * @param {Element} el
    * @param {Number} opacity
    * @param {Number} zIndex
    * @param {String} transform
    */
    _updateItemStyle(el, opacity, zIndex, transform) {
        /*
        // original implementation

        el.style[FabStdBro.getPropertyName_Transform()] = transform;
        el.style.zIndex = zIndex;
        el.style.opacity = opacity;
        el.style.visibility = 'visible';
        */

        // modified
        el.style[FabStdBro.getPropertyName_Transform()] = transform;
        el.style.zIndex = zIndex;
        el.style.opacity = opacity;
        el.style.visibility = "visible";

        /*
        var subItems = el.getElementsByClassName("fab-thumb-carousel");
        for (var i = 0; i < subItems.length; ++i) {
            var img = subItems[i];
            let szTmp = img.name;
            let szTmpId = img.id;

            // this is an asynchronous call ... but no problem within carousel
            window.g_wfCtrl.getStorageManager().getURL(szTmp, function(url) {
                var tmpElem = document.getElementById(szTmpId);
                if (!!tmpElem) {
                    tmpElem.src = url;
                }
                // console.log("CAROUSEL_LOADED", szTmpId, url);
            });
        }
        */
    }
  
   /**
    * Cycle to target
    * @param {Number} n
    * @param {Function} callback
    */
    _cycleTo(n, callback) {
        var diff = this.center % this._getItemCount() - n;

        // Account for wraparound.
        if (!this.noWrap) {
            if (diff < 0) {
                if (Math.abs(diff + this._getItemCount()) < Math.abs(diff)) {
                    diff += this._getItemCount();
                }
            } else if (diff > 0) {
                if (Math.abs(diff - this._getItemCount()) < diff) {
                    diff -= this._getItemCount();
                }
            }
        }
  
        this.target = this.dim * Math.round(this.offset / this.dim);
        // Next
        if (diff < 0) {
            this.target += this.dim * Math.abs(diff);
            // Prev
        } else if (diff > 0) {
            this.target -= this.dim * diff;
        }

        // Set one time callback
        if (typeof callback === 'function') {
            this.oneTimeCallback = callback;
        }
  
        // Scroll
        if (this.offset !== this.target) {
            this.amplitude = this.target - this.offset;
            this.timestamp = Date.now();
            requestAnimationFrame(this._autoScrollBound);
        }
    }
  
   /**
    * Cycle to next item
    * @param {Number} [n]
    */
    next(n) {
        if (n === undefined || isNaN(n)) {
            n = 1;
        }
  
        var index = this.center + n;
        if (index >= this._getItemCount() || index < 0) {
            if (this.noWrap) {
                return;
            }
  
            index = this._wrap(index);
        }
        this._cycleTo(index);
    }
  
   /**
    * Cycle to previous item
    * @param {Number} [n]
    */
    prev(n) {
        if (n === undefined || isNaN(n)) {
            n = 1;
        }
  
        var index = this.center - n;
        if (index >= this._getItemCount() || index < 0) {
            if (this.noWrap) {
                return;
            }
  
            index = this._wrap(index);
        }
  
        this._cycleTo(index);
    }
  
   /**
    * Cycle to nth item
    * @param {Number} [n]
    * @param {Function} callback
    */
    set(n, callback) {
        if (n === undefined || isNaN(n)) {
            n = 0;
        }
  
        if (n > this._getItemCount() || n < 0) {
            if (this.noWrap) {
                return;
            }
  
            n = this._wrap(n);
        }
  
        this._cycleTo(n, callback);
    }
}
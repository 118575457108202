import HtmlProcessor from "./fabbi_htmlprocessor.js";
import CrcScript_Entry_01 from "./fabbi_crc_script_entry_01.jsx";
// import CrcMainListBase_01 from "./fabbi_crc_main_list_base_01.jsx";

export default class HtmlProcessor_Scripts_01 extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        console.debug("completeProps", "HtmlProcessor_Scripts_01 a", props);
        props.HtmlElementIdPrefix = "script_01";

        if (!props.crcDefaultProps_entryList) props.crcDefaultProps_entryList = {};

        console.debug("completeProps", "HtmlProcessor_Scripts_01 b", props);
        if (!props.crcDefaultProps_entryList.crcPrototype_entry) {
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcScript_Entry_01;
        }
        
        return(props);
    }
   
    onPrepareEntry(id_entry, entry) {

        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;
        
        // console.debug(this.constructor.name, "onPrepareEntry", "id_entry", id_entry, "dataset", dataset);

        if (dataset.type !== "gdtTs_script") {
            entry.setIgnore();
        } else {
            // OK!
        }
    }
}

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import CrcTimeLeftDisplay_01 from "./fabbi_crc_time_left_display_01.jsx";
import CrcInfoLine_01 from "./fabbi_crc_info_line_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";

export default class CrcUsers_Entry_02 extends CrcHtmlProcessorEntry {
    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
        var dataObjstat = datasetExtra["data-objstat"]; 
        var dataAddedBy = datasetExtra["data-added_by"];
        var szSenderImgSrc = !!dataAddedBy?dataAddedBy.image_src:"";
        var szSenderName = !!dataAddedBy?dataAddedBy.name:"";

        // console.log("CRC", dataObjstat);

        if (!dataObjstat) dataObjstat = {};

        // console.log(this.constructor.name, "renderEntry", edb);

        return(
            <CrcImageOrInitials_01 
                id_dom={this.getIdDOM()} 
                style={{ width: "2rem", height: "2rem"}} 
                wfCtrl={this.getWfCtrl()} 
                image_src={dataset.image_src} 
                long_name={dataset.name}/>
        );
        /*
        return(
            <div id={this.getIdDOM()} className="avatar-online" style={{wrap: "no-wrap", paddingLeft: "0px", flexShrink: 0, flexGrow: 0 }}>
                <div className="avatar-online">
                    <CrcImageOrInitials_01 style={{ width: "2rem", height: "2rem"}} wfCtrl={this.getWfCtrl()} image_src={dataset.image_src} long_name={dataset.name}/>
                </div>
                <div style={{ position: "relative", left: "-1rem", top: "-1rem", height: "5px" }}><i></i></div>
            </div>

        );
        */
    }
}
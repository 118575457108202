import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import { Timestamp } from "firebase/firestore";

export default class CrcMembers_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidMount() {
        super.componentDidMount();
        
        if (this.state.new) {
            // if (this.el.getElementsByClassName("__sub")[0].style.animationIterationCount > 0
            FabStdBro.addAnimation("dummyAnim", "0% { opacity: 100%;} 100% { opacity: 0%;}");
            // this.el.getElementsByClassName("__sub")[0].style.animationTimingFunction = "linear";

            var htmlElem = this.getDOMElement();

                // animation-name               animationname
                // animation-duration           5s
                // animation-timing-function    linear
                // animation-delay              5s
                // animation-iteration-count    5
                // animation-direction:         normal/alternate
                // animation-fill-mode          forwards
                // animation-play-state         running
                htmlElem.style.animation = "dummyAnim" + " " + "0.5s" + " linear 0s 10 alternate forwards running";
        }        
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;
        var szDelay="";

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");

        // console.log(this.constructor.name, dataLastMsg.content);

        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }

        // fetch userdata for members
        var userdata = this.getWfCtrl().m_bundleUsers.getSingleDataset(this.getIdEntry());
        var szName = "unknown_" + this.getIdEntry();
        
        if (!!userdata) {
            console.log("userdata", userdata);
            szName = userdata.name;
       } else {
            console.log("no userdata", "id", this.getIdEntry(), this.getWfCtrl().m_bundleUsers.getDataPool());
        }

        // console.log(this.constructor.name, "renderEntry()", dataLastMsg.content);

        var renderResult = 
            <div 
                id={this.getIdDOM()} 
                className={"row valign-wrapper !card animate fadeUp " + szDelay} 
                style={{width: "inherit", border: "ridge", borderWidth: "medium"}}>
                <div style={{display: "flex", alignItems: "stretch", width: "inherit"}}>            
                    <div className={"col pr-0"} style={{paddingLeft: "0px", width: "3.5rem"}}>
                        <div className="chat-avatar">
                            <CrcImageOrInitials_01 
                                wfCtrl={this.getWfCtrl()} 
                                image_src={userdata.image_src} 
                                long_name={szName}/>
                        </div>
                    </div>
                    <div className={"col pr-0 fab-flexbox-left"} style={{paddingLeft: "0px", flexGrow: 1}}>
                        <p className={"m-0 blue-grey-text text-darken-4 font-weight-700"}>{szName}</p>
                    </div>
                </div>
            </div>
            ;                    

        return(renderResult);
    }
}
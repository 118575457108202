import React from 'react';
import ControlledReactComponentParent from './fabbi_controlled_react_component_parent.js';
import CrcWait_01 from './fabbi_crc_wait_01.jsx';

export default class CrcEntryList_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        this._reactElem_frame = props.reactElem_frame;
        this._crcPrototype_entry = props.crcPrototype_entry;
        this._crcDefaultProps_entry = props.crcDefaultProps_entry;
        this._crcPropsBuilder_entry = props.crcPropsBuilder_entry;
        
        this._crcPrototype_frame = props.crcPrototype_frame;
        this._crcDefaultProps_frame = props.crcDefaultProps_frame;

        this._arrIdEntrySelected = [];

        this.state.bShow = true;
    }

    childComponentWillUnmount(childCRC) {
        super.childComponentWillUnmount(childCRC);

        if (childCRC === this._crcMountedFrame) this._crcMountedFrame = undefined;
    }

    childComponentDidMount(childCRC) {
        super.childComponentDidMount(childCRC);

        // console.debug(this.constructor.name, "childComponentDidMount", childCRC);    
        // we ask the child-component, if it's the frame-element ...
        console.debug(this.constructor.name, "childComponentDidMount", "check for EntryListFrame", childCRC);  
        if (childCRC.props && childCRC.props.isFrameOf_entryList && childCRC.props.isFrameOf_entryList(this)) {
                console.debug(this.constructor.name, "childComponentDidMount", "mounted EntryListFrame found", childCRC);  
                // ok, we have an EntryList-Frame and can save it!
                this._crcMountedFrame = childCRC;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

   /*
    prepare Unmount!
    */
    componentWillUnmount() {
        super.componentWillUnmount();

        console.debug(this.constructor.name, "componentWillUnmount", this);
    
        if (this.getHtmlProcessor()) {
            // tell HtmlProcessor that the entrylist unmounts ...
            this.getHtmlProcessor().onEntryListComponentWillUnmount(this);
        }
    }

    getHtmlProcessor() {
        return(this.props.htmlProcessor);
    }

    getCrcMountedFrame() {
        return(this._crcMountedFrame);
    }

    /** Force an update of several (enlisted) Items (given via array) of the List
    *  @param {StringArray} arr_id_entry_alias_id_child every single entry given will be updated!
    */ 
    updateMultipleEntries(arr_id_entry_alias_id_child) {
        var bUpdateList = false;

        console.log(this.constructor.name, "updateMultipleEntries", arr_id_entry_alias_id_child);

        arr_id_entry_alias_id_child.some((id_entry_alias_id_child) => {
            if (this._updateSingleEntry(id_entry_alias_id_child)) {
                bUpdateList = true;
            }
            return(bUpdateList);
        });

        console.log(this.constructor.name, "updateMultipleEntries", "bUpdateList", bUpdateList);

        if (bUpdateList) {
            this.update();
        }

        return(bUpdateList);
    }

    /** Force an update of the react-EntryList or just a single Item of the List
    *  @param {String=} id_entry_alias_id_child optional if specified the single entry with the DOM-Id szIdDOM will be updated!
    */ 
    updateSingleEntry(id_entry_alias_id_child) {  
        var bUpdateList = false;

        if (id_entry_alias_id_child) {
            bUpdateList = this._updateSingleEntry(id_entry_alias_id_child);
        } else {
            // no id_entry given ... update the List instead!
            bUpdateList = true;
        }

        if (bUpdateList) {
            this.update();
        }
    }

   /*
    Return: True, if single child was successully provided an update-command!
            false, if NO single child could be updated, for it doesn't exist yet ...
    */
    _updateSingleEntry(id_entry_alias_id_child) {  
        if (id_entry_alias_id_child && !this.updateChild(id_entry_alias_id_child)) {
            // child not found! update the List!
            return(true);
        } 
        return(false);
    }

    hide() {
        if (this.state.bShow) this.setState({bShow: false});
    }

    show() {
        if (!this.state.bShow) this.setState({bShow: true});
    }

    beforeRenderEntry(edb) {
        if (this.props.onBeforeRenderEntry) return(this.props.onBeforeRenderEntry(this, edb));        
 
        var bConfig = (edb.id_entry === "_config");
        if (bConfig) {
            this.setSelectedEntry(edb.id_entry_selected);
            return(false);
        }
        return(true);
    }

    setSelectedEntry(id_entry_or_arrIdEntry) {
        console.debug(this.constructor.name, "setSelectedEntry", "newly activated", id_entry_or_arrIdEntry);
        var arrOld = this._arrIdEntrySelected;
        var arrNew = Array.isArray(id_entry_or_arrIdEntry)?id_entry_or_arrIdEntry:[id_entry_or_arrIdEntry];
        var arrUpd = [];
        arrUpd = arrNew.filter((elem) => !arrOld.includes(elem));
        arrUpd.push(... arrOld.filter((elem) => !arrNew.includes(elem)));
        this._arrIdEntrySelected = arrNew;

        if (arrUpd.length > 0) { 
            this.updateMultipleEntries(arrUpd);
        }
    }

    isSelectedEntry(id_entry) {
        return(this._arrIdEntrySelected.includes(id_entry));
    }

    getSelectedEntry() {
        if (this._arrIdEntrySelected.length > 0) return(this._arrIdEntrySelected[0]);
        return(undefined);
    }

    getSelectedEntries() {
        return([... this._arrIdEntrySelected]);
    }

    render() {        
        var bDisplayEntries = true;
        var arrTmp = [];
        var resultRender = [];
        var hp = this.getHtmlProcessor();

        // console.log(this.constructor.name, "render", "startDisplay", "CHECK", hp, this.isClosing());
        
        if (!hp) {
            bDisplayEntries = false;
        }  else {
            console.debug(this.constructor.name, "render", hp.getId_Div_SuperSection());
        }

        if (this.isClosing()) {
            // console.log(this.constructor.name, "render()", "preparing to unmount");
            bDisplayEntries = false;
        }

        var result;
        var edb;
        var iter;
        var Crc_prototype_entry

        // resultRender.push(<div>);
        if (bDisplayEntries) {
            // console.log(this.constructor.name, "render", "startDisplay", this.props.crcPrototype_entry.name);

            iter = hp.getEntryDataBundleIterator_Sorted();

            while(!(result = iter.next()).done) {
                var szIdEntry = result.value;

                Crc_prototype_entry = undefined; 
                edb = hp.getEntryDataBundle(szIdEntry);

                if (this.beforeRenderEntry(edb)) {
                    // console.debug(this.constructor.name, "render", "szIdEntry", szIdEntry, "edb", edb);

                    // "id_custom_crc_prototype_entry" can hold the ID of an crcPrototype we shall use
                    if (!Crc_prototype_entry && edb.datasetExtra && edb.datasetExtra.id_custom_crc_prototyp_entry) {
                        Crc_prototype_entry = this.getWfCtrl().getObjectPrototype(edb.datasetExtra.id_custom_crc_prototype_entry);
                    }
                    if (!Crc_prototype_entry && edb.dataset && edb.dataset.id_custom_crc_prototype_entry) {
                        Crc_prototype_entry = this.getWfCtrl().getObjectPrototype(edb.dataset.id_custom_crc_prototype_entry);
                    }
                    if (!Crc_prototype_entry) {
                        Crc_prototype_entry = this._crcPrototype_entry; // default
                    }

                    // var szTmp = hp.getId_Div_SuperSection();
                    // if (szTmp.indexOf("ript") >= 0) 
                    //    console.debug(this.constructor.name, "render", szTmp, "entry", "szIdEntry", szIdEntry);

                    if (edb.state !== "deleted") {
                        arrTmp.push(
                            <Crc_prototype_entry 
                                { ... this._crcDefaultProps_entry }
                                { ... this._crcPropsBuilder_entry?this._crcPropsBuilder_entry(edb.id_entry):{} }

                                parent={this}
                                htmlProcessor={hp} 
                                key={edb.id_entry} 
                                id_child={edb.id_entry}
                                id_entry={edb.id_entry}
                                id_dom={hp.getId_Div_Entry(edb.id_entry)}                         
                                wfCtrl={this.getWfCtrl()}
                                />             
                        );
                    }
                }
            }

            // console.log(this.constructor.name, "render", "end");
        } else {
            // console.log(this.constructor.name, "isClosing", "before renderPortals", "unmount portals ...");
            arrTmp.push(<CrcWait_01 key="wait" style={{height: "3rem", width: "3rem" }}/>);
        }

        // console.log(this.constructor.name, "render", "state-show", this.state.bShow);
        if (this._reactElem_frame) {
            console.debug(this.constructor.name, "reactElem", "Tab-Comp", "Start");
            resultRender.push(
                <span id={this.getIdDOM()} key={this.getIdDOM()} style={ !this.state.bShow?{ display: "none" }:{} }>
                   { 
                        React.cloneElement(
                            this._reactElem_frame, 
                            {
                                isFrameOf_entryList: (crc) => (crc === this) ,
                                parent: this, 
                                wfCtrl: this.getWfCtrl(),
                                ... this._reactElem_frame.props, 
                                listContent: arrTmp 
                            })
                   }                   
                </span>
            );
        } else
        if (this._crcPrototype_frame) {
            console.debug(this.constructor.name, "using alternative frame", this._crcPrototype_frame);
            
            resultRender.push(
                <span id={this.getIdDOM()} key={this.getIdDOM()} style={ !this.state.bShow?{ display: "none" }:{} }>
                    <this._crcPrototype_frame parent={this} wfCtrl={this.getWfCtrl()} { ... this._crcDefaultProps_frame }>
                        {
                            arrTmp
                        }
                    </this._crcPrototype_frame>
                </span>
            );
        } else {
            resultRender.push(
                <span id={this.getIdDOM()} key={this.getIdDOM()} style={ !this.state.bShow?{ display: "none" }:{} }>
                    { 
                        arrTmp 
                    }
                </span>
            );
        }

        return(resultRender);
    }
}

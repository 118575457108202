import qryProcessor from "./fabbi_qry_processor.js";
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import qryProcessor_UserIndividualities from "./fabbi_qry_processor_userindividualities.js";
import HtmlProcessor_Messages from "./fabbi_htmlprocessor_messages.js";

export default class qryProcessor_Messages extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super({ 
                BuildAll_ifDivSectionMissing: false, 
                htmlProcessorPrototype: HtmlProcessor_Messages, 
                ...props 
            }
        );                
        this.m_szFamilyId = props.szFamilyId?props.szFamilyId:props.szTriggerId;      
        
        // console.log(this.getClassName(), "constructor", this.m_szFamilyId);                
    }
    
    // Overrides existing!
    getClassName() {
        return("qryProcessor_Messages");    // to FIX the classname!
    }

    getFamilyId() {        
        return this.m_szFamilyId;
    }
    
    getMsgCount() {
        return(this.m_statistics.m_data.count);
    }     
    
    getId_LastMessage() {
        return(this.m_statistics.m_data.szLastAddedId);
    }
    
    getLastMessage() {
        var szId = this.getId_LastMessage();                
        return({ szId: szId, entry: this.getPrivateDataPool().getDataEntry(szId) });
    }
    
    getCollectionPath() {
        return('families/' + this.getFamilyId() + '/messages');
    }
    
    handleQryProcessorEvent(event) {        
        super.handleQryProcessorEvent(event);
                
        if (event.detail.sender.constructor.name === qryProcessor_UserIndividualities.prototype.constructor.name) { 
            if (event.detail.szSubType === qryProcessor.EVENT_subType_reportOnRunCompleted) {                
                var qP = event.detail.sender;
                var tsLC = qP.getLastChecked_Messages(this.getFamilyId());
                
                console.log("LAST_CHECKED_CHANGED", "concerning Messages for Family", this.getFamilyId(), "changed to", tsLC);                             
                this.planRun();
                                
                // we are in "qryProcessorBundle_Family": a new message or e. was recognized, so we update the chat-list!
                // this.m_HtmlProcessor.prepareAndProcessEntry(szMissionId);
            }
        }    
    }        
}

import ControlledReactComponentExtension from "./fabbi_controlled_react_component_extension.jsx"; 
import PerfectScrollbar from "./perfect-scrollbar.min.js";
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";

export default class CrcExt_Scrollbar extends ControlledReactComponentExtension {
    constructor(props) {
        super(props);

        // props.container      =>  the container that shall be extended with the scrollbar
        //                          e.g. ".className" (via classname) or "#main" (via dom-id) etc.
        // props.options        =>  options for the scrollbar

        this._tc = new timeoutCtrl();
        this._sb = null;
    }

    underlyingComponentDidMount(underlyingCRC) {
        super.underlyingComponentDidMount(underlyingCRC);

        this.updateScrollbar();
    }

    // we can do something here ...
    underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot) {
        // console.log(this.constructor.name, "underlyingComponentDidUpdate", underlyingCRC);
        super.underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot);

        this.updateScrollbar();
    }

    updateScrollbar() {
        if (!this._sb) {
            // console.log(this.constructor.name, "create PerfectScrollbar", this.props.container, this.props.options);

            try { this._sb = new PerfectScrollbar(this.props.container, this.props.options); } catch(e) { } 
        } else {
            this._tc.setAutoAbortTimeout(() => {
                // If the size of your container or content changes, call update. 
                try {
                    // console.log(this.constructor.name, "update PerfectScrollbar", this.props.container, this.props.options); 
                    this._sb.update(); } catch(e) { console.log(this.constructor.name, "error PerfectScrollbar"); this._sb = null; };
            }, 0);  // call update as soon as possible but just once ...
        }
    }

    underlyingComponentWillUnmount(underlyingCRC) {
        // we can do some cleanup here
        super.underlyingComponentWillUnmount(underlyingCRC);
    }
}
import qryProcessor from "./fabbi_qry_processor.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor_Search from "./fabbi_htmlprocessor_search.js";
import qryProcessor_Manual from "./fabbi_qry_processor_manual.js";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
import CrcSearchModal_01 from "./fabbi_crc_search_modal_01.jsx";
import Hilitor from "./fabbi_hilitor.js";

export default class qryProcessor_Search extends qryProcessor_Manual {    
    constructor(props) {  
        super({ 
                htmlProcessorPrototype: HtmlProcessor_Search,
                ...props
            }
        );    
        this.m_szUserId = props.szUserId?props.szUserId:props.szTriggerId;
    }

    getClassName() {
        return("qryProcessor_Search");    // to FIX the classname!
    }

    getMainUserId() {        
        return this.m_szUserId;
    }
    
    displaySearchResults(hilitor) { 
        console.log(this.getClassName(), "displaySearchResults", hilitor);
        
        this.deleteAllEntries();

        var iter = hilitor.m_smOccurences.keys();
        var result;
        var obj;

        while (!(result = iter.next()).done) {
            obj = hilitor.m_smOccurences.get(result.value);
            if (!!obj) { 
                console.log(this.getId(), "processEntry", "Hilitor", obj);     
                this.processEntry(result.value, obj, "added");
            }
        }

        var s = document.getElementById("search-all");
        console.log(this.constructor.name, "Style", CrcModal_01.propStyle_DropDown);
        CrcModal_01.openAsModal(
            this.getWfCtrl(), 
            { style: CrcModal_01.propStyle_DropDown }, 
            CrcSearchModal_01, 
            {
                id_child: "search-all",
                qryP: this
            }, 
            true);

        /*
        var col = document.getElementsByClassName("fab-search");
        var e;
        console.log(this.getId(), col);
        for (var i=0; i < col.length; ++i) {
            e = col[i];
            console.log(this.getId(), e);
            e.classList.remove("invisible");
            console.log(this.getId(), e);
        }
        */
    }
}
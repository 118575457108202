import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import CrcDatepicker_01 from "./fabbi_crc_datepicker_01.jsx";
import CrcSwitch3_01 from "./fabbi_crc_switch3_01.jsx";
import { Timestamp } from "firebase/firestore";

export default class CrcObjectives_ModalInput_01 extends CrcHtmlProcessorEntry {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();

        // this._qryP_Mem.processQuery();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().requestSpecialDataAction(
                this.getIdDOM(),    // id_sda
                this.getIdEntry(),  // id_entry
                () => this.update()  // force a re-rendering
            );
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().derequestSpecialDataAction(
                this.getIdDOM()    // id_sda
                );
        }
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");

        if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
            this.state.new = true; // direct access to "state" !! important in this case!
        } else {
            this.state.new = false;
        }

        /*
        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = function(e, id_objective) {
                if (this.getWfCtrl().m_listenerUserIndividualities.getSelectedId_Objectives() === id_objective) {
                    // g_wfCtrl.editObjective(szTmpId);
                } else {
                    this.getWfCtrl().setSelectedId_Objectives(id_objective);
                    this.getWfCtrl().refreshLastChecked_Messages(id_objective);
                }
            }.bind(this);
        */

        // var bActive = (this.getWfCtrl().getSelectedId_Objectives() === this.getIdEntry());

        var id_dom_input_name = this.getIdDOM() + "_input_name";
        var id_dom_input_comment = this.getIdDOM() + "_input_comment";

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>Objective-Profile</span>
                        <span className={"fab-dragdestination"}>
                            <i className={"material-icons trash-bin"} id="trash-bin">delete</i>
                        </span>            
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <CrcInput_01 
                            id_dom={id_dom_input_name} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Objectivename" 
                            defaultValue={dataset.name}/>

                        <CrcInput_01 
                            id_dom={id_dom_input_comment} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Comment" 
                            defaultValue={dataset.comment}/>
                        
                        <div id="modal1_sub" className={"modal submodal modal-content"}>    
                        </div>            

                        <CrcSwitch3_01 
                            onInput={(newValue) => {
                                this.getWfCtrl().setObjectiveStatus(this.getIdEntry(), newValue);
                            }}
                            id_dom={this.getIdDOM() + "_sw3"} 
                            wfCtrl={this.getWfCtrl()} 
                            parent={this}
                            dataValue={dataset.status}/>
                    </div>

                    <div className={"row"}>                   
                        <div className={"modal-footer"}>
                            <a className={"btn waves-effect waves-light send modal-close"} id="Objectives-btn-leave" onClick={
                                () => { /* this.getWfCtrl().leaveObjective(this.getIdEntry()); */ }
                            }>Leave Objective</a>
                            <a className={"btn waves-effect waves-light send modal-close"} id="Objectives-btn-save" 
                                onClick={
                                    (event) => { 
                                        console.log(this.constructor.name, id_dom_input_name, document.getElementById(id_dom_input_name));
                                        var obj = {};
                                        FabStd.addProp(obj, "name", this.getWfCtrl().getCRC(id_dom_input_name).getDbValue());   
                                        FabStd.addProp(obj, "comment", this.getWfCtrl().getCRC(id_dom_input_comment).getDbValue());   

                                        this.getHtmlProcessor().getQP().updateDoc(
                                            this.getIdEntry(), 
                                            obj); 

                                        console.log(this.constructor.name, "this", this, "this.close", this.close);

                                        // close modal window ... which is the parent of this window
                                        this.getParent().close();
                                    }
                                }>Save</a>
                        </div>  
                    </div>                
                    <p/>                            
                </div> 

            </form>
        );
    }
}
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import qryProcessor from "./fabbi_qry_processor.js";

export default class qryProcessorBundle extends qryProcessor {        
    constructor(props) {
        super(props);
        
        this.m_smapBundleQPs = new StdMap();        
        this.m_smapAuxData_BundleQPsDirty = new StdMap();        
        this.m_smapAuxData_BundleQPsWaitForData = new StdMap();
        this.m_smapAuxData_BundleQPsTriggerMaps = new StdMap();
    
        this.m_bInitialBundlePrepared = false;
    }

    isInitialBundlePrepared() {
        return(this.m_bInitialBundlePrepared);
    }

    setInitialBundlePrepared() {
        if (!this.m_bInitialBundlePrepared) {
            this.m_bInitialBundlePrepared = true;

            if (this.m_smapBundleQPs.length <= 0) {
                // take care, that this is not carried out at once but in Line ...
                setTimeout(() => {
                    if (this.m_smapBundleQPs.length <= 0) {
                        // for the "bundle" the incoming of data is now finished ...
                        this.privOnNewDataIncomingCompleted();
                    }
                }, 0);
            }
        }
    }
    
    setWaitForData_BundleQP(qryProcessorOfBundle_or_Id, bWaitForData) {
        var szId_QP = qryProcessor.toId(qryProcessorOfBundle_or_Id); 
        if (bWaitForData) {
            this.m_smapAuxData_BundleQPsWaitForData.set(szId_QP, { id_qp: szId_QP });
            
            // console.log(this.getClassName(), "setWaitForData_BundleQP", qryProcessor.getId());
            
            if (!this.m_bNewDataIncoming) {
                this.privOnNewDataIncoming();
            }        
        } else {
            this.m_smapAuxData_BundleQPsWaitForData.delete(szId_QP);
        }
    }

   /**
    * Collects all BundleQPs (member of this queryProcessor-Bundle).
    * @returns {Array(qryProcessor)} All the qryProcessor-Object or undefined.
    */    
    getBundleMemberQPs() {
        var arrResult = [];
        this.m_smapAuxData_BundleQPsTriggerMaps.forEach((entry) => {
            arrResult.push(entry);
        });
        return(entry);
    }
    
   /**
    * Looks up a BundleQP (member of this queryProcessor-Bundle) via a given Id.
    * @param {String} szId An ID to search for a qryProcessor.
    * @param {String} [szIdType="qryProcessor"] Type of the given Id. Default: "qryProcessor" (the id of the queryProcessorr). 
    * Also usable: "trigger" => szId is the triggerId of the looked up qryProcessor.
    * @returns {qryProcessor} The found qryProcessor-Object or undefined.
    */    
    getBundleQP(szId, szIdType = "qryProcessor") {        
        var map;
        
        switch(szIdType) {
            case "trigger":
                // Lookup a triggerid
                map = this.m_smapAuxData_BundleQPsTriggerMaps.get(szId);
                if (!!map) {
                    // return the first qryProcessor found!
                    szId = map.keys().next().value;
                } else {
                    return(undefined);
                }
                break;

            default:
                // Standard ist qryProcessor
                break;
        }

        // qryProcessor
        // Default: a qryProcessor-Id is supplied
        // console.log("getBundleQP", "Id", szId, "found", this.m_smapBundleQPs.get(szId));
        return(this.m_smapBundleQPs.get(szId));        
    }

    // Trigger-Document-Id must be supplied.
    // return: UNDEFINED if nothing found, all other cases the questioned map (SortedMap) Object.
    //         ONLY Maps with a minimum of ONE entry are returned!    
    getBundleQPIdMap_viaTriggerId(szTriggerId) {              
        return(this.m_smapAuxData_BundleQPsTriggerMaps.get(szTriggerId));        
    }    
    
    setDirty_BundleQP(qryProcessorOfBundle_or_Id) {
        var szId_QP = qryProcessor.toId(qryProcessorOfBundle_or_Id);        
        if (this.m_smapBundleQPs.has(szId_QP)) {
            this.m_smapAuxData_BundleQPsDirty.set(szId_QP, { id_qp: szId_QP });
            
            if (!this.m_bNewDataIncoming) {
                this.privOnNewDataIncoming();
            }
        }
    }
    
    setClean_BundleQP(qryProcessorOfBundle_or_Id) {
        var szId_QP = qryProcessor.toId(qryProcessorOfBundle_or_Id);  
        if (this.m_smapAuxData_BundleQPsDirty.length > 0) {
            this.m_smapAuxData_BundleQPsDirty.delete(szId_QP);
            if (this.m_smapBundleQPs.length === 0 && this.isStartUpCompleted()) {
                console.log(this.getClassName(), "setClean_BundleQP", "inform about empty Bundle!");
                this.privOnNewDataIncomingCompleted();
            }
        }
    }
        
    attach(qryP) {        
        var szId_QP = qryP.getId();
        var szId_Trigger = qryP.getTriggerId();

        if (!this.m_smapBundleQPs.has(szId_QP)) {
            this.m_smapBundleQPs.set(szId_QP, qryP);            

            this.setWaitForData_BundleQP(qryP, true);
            qryP.joinBundle(this);
            // this.setDirty_BundleQP(qryP);                                                
        }

        // add queryProcessorId to actual Trigger-Map, if not existing: add a neu Trigger-Map for the queryProcessor
        // as the "TriggerId" we use the TriggerId of the qryP that shall be attached ...
        var mapTrig = this.m_smapAuxData_BundleQPsTriggerMaps.get(szId_Trigger, true, () => { return(new StdMap()); });
        if (!mapTrig.has(szId_QP)) {
            mapTrig.set(szId_QP, { id_qp: szId_QP });
        }
    }    
    
    detach(qryProcessorOfBundle_or_Id) {      
        var szId_QP = qryProcessor.toId(qryProcessorOfBundle_or_Id);
        var qryP_Bundle = this.m_smapBundleQPs.get(szId_QP);         

        if (!qryP_Bundle) {
            console.log(this.getClassName(), "detach", "error while detaching", qryProcessorOfBundle_or_Id);
            return(false);
        }

        qryP_Bundle.leaveBundle(this);
        this.m_smapBundleQPs.delete(szId_QP);        

        var szId_Trigger = qryP_Bundle.getTriggerId();
        var mapTrig = this.m_smapAuxData_BundleQPsTriggerMaps.get(szId_Trigger);
        if (!!mapTrig) {
            // delete queryProcessor from Trigger-Map
            mapTrig.delete(szId_QP);   
            if (mapTrig.length <= 0) { 
                this.m_smapAuxData_BundleQPsTriggerMaps.delete(szId_Trigger);
            }
        }

        this.m_smapAuxData_BundleQPsDirty.delete(szId_QP);
        this.setWaitForData_BundleQP(qryP_Bundle, false); // no longer wait for data, concerning the leaving QP

        // the qryP is no longer existing! Do a last SYNC to delete the data
        // console.log("SYNC START", "bundle", qryP_Bundle.getPrivateDataPool().getId());
        this.getPrivateDataPool().syncWithSubDataPool(undefined, qryP_Bundle.getPrivateDataPool().getId());
        // console.log("SYNC ENDE");
        
        // plan a new DataProcessing!
        this.planNewDataProcessing();

        return(true);
    }
    
    buildQuery() {
        return(""); // none! this is a bundle!
    }
    
    init()  {
        // this.setStatistics();
        // this.getPrivateDataPool().setId(this.getId());
    }
    
    handleQryProcessorEvent(event) {

        switch (event.detail.szSubType) {
            case qryProcessor.EVENT_subType_reportOnNewIncomingCompleted:                                
                break;
                
            case qryProcessor.EVENT_subType_reportOnNewDataProcessingCompleted:                
                
                let qryP_ofBundle = event.detail.sender;
                if (this.m_smapBundleQPs.has(qryP_ofBundle.getId())) {
                    this.setDirty_BundleQP(qryP_ofBundle); 
                    this.setWaitForData_BundleQP(qryP_ofBundle, false);
                    
                    if (this.m_smapAuxData_BundleQPsWaitForData.length === 0 || this.m_nCount_NewDataIncomingCompleted > 0) {
                        // console.log(this.getClassName(), "handleQryProcessorEvent", "Incoming Data ready!");
                        
                        // for the "bundle" the incoming of data is now finished ...
                        this.privOnNewDataIncomingCompleted();
                    } else {
                        // console.log(this.getClassName(), "handleQryProcessorEvent", "waiting for additional data!", this.m_smapAuxData_BundleQPsWaitForData);
                    }
                }
                break;
                
            case qryProcessor.EVENT_subType_reportOnRunCompleted:
                // console.log(this.constructor.name, "handleQryProcessorEvent", event.detail.szSubType, event);
                // this.privOnRunCompleted();
                break;
        }        
        // super.handleQryProcessorEvent(event);
    }
    
    onNewDataIncoming() {
        // false = DON'T execute onNewDataIncomingCompleted() directly afterwards!
        return(false);
    }
    
    onNewDataIncomingCompleted() {
        // false = don't directly start NewDataProcessing if completed !
        //         the NewDataProcessing will be enqueued via "planNewDataProcessing"
        return(false);
    }
    
    onNewDataProcessing() {
        let arrKill = [];
        
        // console.log(thisClass.getClassName(), "onNewDataProcessing");
        this.m_smapAuxData_BundleQPsDirty.forEach(function(objAuxData) {
            var szId_QP = objAuxData.id_qp;
            // console.log("AUX", szId_QP);
            var qryP = this.m_smapBundleQPs.get(szId_QP);

            if (!!qryP) {
                // it's time for some data transfer
                this.getPrivateDataPool().syncWithSubDataPool(qryP.getPrivateDataPool(), "");
                
                // if (qP.getPrivateDataPool().getId().includes("User")) {
                //    console.log(thisClass.getClassName(), "WWW", qP.getPrivateDataPool().getId());
                // }                
                arrKill.push(szId_QP);
            } 
        }.bind(this));

        // go through KillList
        arrKill.forEach(function(szId_QP) {
            this.setClean_BundleQP(szId_QP);
        }.bind(this));
        
        return(true);
    }
    
    cleanUp() {        
    }
}

import React, { useState }  from 'react';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcStatisticsVisualizer_01 from './fabbi_crc_statistics_visualizer_01.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import ControlledReactComponentParent  from './fabbi_controlled_react_component_parent.js';
import * as FabXlsx from "../fabstd/fabbi_xlsx.js";
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import alasql from 'alasql'; 
import { toExcelDate, fromExcelDate } from 'js-excel-date-convert';
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import { gd } from 'date-fns/locale';
import { NaN, quantileSeq } from 'mathjs'
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import Lib_GdtTimeseries from "./fabbi_lib_gdt_timeseries.js";
// import { parseXML } from 'jquery';
import ScriptInterpreter_GdtTimeseries from "./fabbi_script_interpreter_gdt_timeseries.js";
// import MUIRichTextEditor from "mui-rte";
// import * as MRTEU from "./fabbi_muirichtexteditor_utils.ts";
import CrcModalSelect_01 from "./fabbi_crc_modalselect_01.jsx";
import CrcModal_01 from "./fabbi_crc_modal_01.jsx";
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import 'draft-js/dist/Draft.css';
import CrcStatistics_Editor_01 from './fabbi_crc_statistics_editor_01.jsx';
import CrcWait_01 from './fabbi_crc_wait_01.jsx';
import { convertTimestampToLocaleDateString } from '../fabstd/fabbi_standard.js';
import CrcModalScripts_01 from "./fabbi_crc_modal_scripts_01.jsx";
import CrcTabBarScripts_01 from './fabbi_crc_tabbar_scripts_01.jsx';
import { ContentState, EditorState } from "draft-js";
import { Timestamp } from "firebase/firestore";

export default class CrcStatistics_Main_01 extends ControlledReactComponentParent {
    constructor(props) {
        super(props);

        this._libGdtTs = new Lib_GdtTimeseries();
        this._si = new ScriptInterpreter_GdtTimeseries({
            funcCallback_onStart: (scriptContext) => this.onScriptStart(scriptContext),
            funcCallback_onResult: (scriptContext) => this.onScriptResult(scriptContext),
            funcCallback_onScriptLineDone: (scriptContext) => this.onScriptScriptLineDone(scriptContext),
            funcCallback_onEnd: (scriptContext) => this.onScriptEnd(scriptContext)
        });
        // this._inputRef = React.createRef();
        // this._inputRefRich = React.createRef();
        // this._editorStateRef = React.createRef();
        // console.log(this.constructor.name, "constructor", "_inputRef", this._inputRef);

        this._tc_jobCode1 = new timeoutCtrl();
        this._tc_jobCode2 = new timeoutCtrl();

        // baseData
        this._arrDataSource = [
            {
                szSrcFile: "Zeitreihe_TotalReturn_Index_01.xlsx", // "Zeitreihe_TotalReturn_Equity_01T.xlsb", // "Zeitreihe_TotalReturn_Index_01.xlsx",
                funcGdtPrepare: (gdt) => this._libGdtTs.gdtValues_Modify(gdt, (val) => val /= 100),
                columnProps: {
                    dataNumberFormat: { ... CrcStatistics_Main_01.dataNumberFormat_Pct2 },
                    funcAggStd: (arrV) => this._libGdtTs.funcAggPerformance(arrV),
                    periodType:         "d",
                    periodAggCount:     1,
                    valueType:         "relativeChange"
                }
            } /* ,
            {
                szSrcFile: "Zeitreihe_TotalReturn_Equity_01T.xlsb", // "Zeitreihe_TotalReturn_Index_01.xlsx",
                funcGdtPrepare: (gdt) => this._libGdtTs.gdtValues_Modify(gdt, (val) => val /= 100),
                columnProps: {
                    dataNumberFormat: { ... CrcStatistics_Main_01.dataNumberFormat_Pct2 },
                    funcAggStd: (arrV) => this._libGdtTs.funcAggPerformance(arrV),
                    periodType:         "d",
                    periodAggCount:     1,
                    valueType:         "relativeChange"
                }
            } */
           
            /*
            ,
            {
                szSrcFile: "Zeitreihe_ZiSa_01.xlsx", // "Zeitreihe_Preise_Index_01.xlsx" // "Zeitreihe_ZiSa_01.xlsx" // "Zeitreihe_TotalReturn_Equity_01T.xlsb"
                dataNumberFormat: { ... CrcStatistics_Main_01.dataNumberFormat_Bp0 },
                funcGdtPrepare: (gdt) => { this._libGdtTs.gdtValues_CalcPeriodicalDifferences(gdt); this._libGdtTs.gdtValues_Modify(gdt, (val) => val===null?null:(val *= 100)) },
                funcAggStd: (arrV) => google.visualization.data.sum(arrV),
                periodType:         "d",
                periodAggCount:     1,
                valueType:         "absoluteChange"         
            },
            */
            /*
            {
                szSrcFile: "ML_GOVT_OAS.xlsb", // "Zeitreihe_Preise_Index_01.xlsx" // "Zeitreihe_ZiSa_01.xlsx" // "Zeitreihe_TotalReturn_Equity_01T.xlsb"
                dataNumberFormat: { ... CrcStatistics_Main_01.dataNumberFormat_Bp0 },
                funcGdtPrepare: (gdt) => { 
                        this._libGdtTs.gdtValues_CalcPeriodicalDifferences(gdt); 
                        this._libGdtTs.gdtValues_Modify(gdt, (val) => val===null?null:(val *= 1));
                        this._libGdtTs.gdtRemoveRows_Weekends(gdt); 
                        this._libGdtTs.gdtRemoveRows_ResetLastCaldenderDayOfMonth(gdt);
                    },
                funcAggStd: (arrV) => google.visualization.data.sum(arrV),
                periodType:         "d",
                periodAggCount:     1,
                valueType:         "absoluteChange"
            }
            */

        ]; 

        this.state.bReady = false;
        this._arrItemsToVisualize = [];

        this._chainDataPrep = new PromiseChain({ 
            main:               this, 
            gdtMain:            null,
            smapDescription:    new StdMap(),
            tmp:                {} 
        });

        /*
        this._defaultTheme = createMuiTheme();
        Object.assign(this._defaultTheme, {
            overrides: {
                MUIRichTextEditor: {
                    root: {
                        borderRadius: "5px",
                        backgroundColor: "#F0F0F0",
                        width: "100%",
                        padding: "5px"
                    },
                    editor: {
                        padding: "0px",
                        color: "black",
                        borderBottom: "1px solid black" 
                    }
                }
            }
        });
        */
    }

    /*
    dlgScriptSelection() {
        console.debug(this.constructor.name, "dlgScriptSelection");
        this._idDom_scriptSelection = CrcModal_01.openAsModal( 
            this.getWfCtrl(),
            {   
                // id_dom: "script_select",
                className: "" // ,  // nop Classname
                // onClickAway: (event) => { 
                    // this.getCRC("autoselect_child")?.handleClickAway(event) 
                // }
                , 
                style: { 
                    ...CrcModal_01.propStyle_Middle1 
                }
            },  // additional-frame-props
            CrcModalScripts_01,
            { 
                id_dom: "script_select",
                id_child: "script_select_child", 
                crcEditor: this.getEditor(),
                onClose: () => { },
                onChange: () => { }
            },
            true
        ); 
    }
    */

    getScriptInterpreter() {
        return(this._si);
    }

    getGdtMain() {
        return(this._gdtMain);
    }

    onScriptStart(scriptContext) {
        console.debug(this.constructor.name, "onScriptStart");
        FabStd.resetArray(this._arrItemsToVisualize);
        // console.log(this.constructor.name, "onScriptStart", scriptContext, this._arrItemsToVisualize);
    }

    onScriptResult(scriptContext) {
        console.debug(this.constructor.name, "onScriptResult"); //, scriptContext.result);

        // just add to the items to visualize if we are not in "check"-Mode ....
        if (!scriptContext.checking) {
            this._arrItemsToVisualize.push(scriptContext.result);
            this.update();
        }
    }

    onScriptScriptLineDone(scriptContext) {
    }

    setActiveScriptContext(scriptContext) {
        this._activeScriptContext = scriptContext;
    }

    getActiveScriptContext() {
        return(this._activeScriptContext);
    }

    onScriptEnd(scriptContext) {
        // done!
        this.setActiveScriptContext(scriptContext);
        console.debug(this.constructor.name, "onScriptEnd"); //, scriptContext.result);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        /*
        this._qryP_Mem.destroy();
        this._qryP_Mem = undefined;
        */
    }

    componentDidMount() {
        super.componentDidMount();

        // this._qryP_Mem.processQuery();
        this._loadBaseData();
    }

    gdtImportFromExcelSheet(sheet, options) {
        if (!options) {
            options = { deleteRowsWithAll0Values: true };
        }

        var RaS = FabXlsx.xlsx_getUsedRaS(sheet);    
        var aoa_tmp = FabXlsx.xlsx_getAsAoa(RaS);
        aoa_tmp = this.aoa_cleanUp(aoa_tmp, options);

        return(google.visualization.arrayToDataTable(aoa_tmp));
    }

    _onBaseDataLoaded() {
        console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "Start");

        // LOADING MAIN TABLE
        this._gdtMain = undefined;

        function pcfProcessDataSourceMain(resolve, reject) {
            var { ds } = this.getCustomPromiseData();

            var tmp = {};
            this.pushPromise(
                { ds,  tmp }, function(resolve, reject) {
                    var { main, smapDescription } = this.getCustomChainData();
                    var { ds, tmp } = this.getCustomPromiseData();
 
                    tmp.gdt = main.gdtImportFromExcelSheet(ds.wsData);
                    if (tmp.gdt) {
                        // console.log(this.constructor.name, "ds", ds);
                        if (ds.wsDescription) {
                            var aoaDesc = FabXlsx.xlsx_getAsAoa(FabXlsx.xlsx_getUsedRaS(ds.wsDescription));
                            var arrIdxToName = [];
                            aoaDesc.forEach((row, idxRow) => { 
                                var objDescription = {};
                                if (idxRow === 0) {
                                    arrIdxToName = row;
                                } else {
                                    row.forEach((col, idxCol) => {
                                        objDescription[arrIdxToName[idxCol]] = col;
                                    });
                                }
                                smapDescription.set(row[0], objDescription ); 

                                var idxCol = tmp.gdt.getColumnIndex(row[0]);
                                if (idxCol > 0) {
                                    tmp.gdt.setColumnProperties(
                                        idxCol, 
                                        { 
                                            ... tmp.gdt.getColumnProperties(idxCol), 
                                            ... objDescription
                                        }
                                    );
                                }
                            });

                            /*
                            console.log(this.constructor.name, "wsDescription", 
                                FabXlsx.xlsx_getAsAoa(FabXlsx.xlsx_getUsedRaS(ds.wsDescription))
                            );
                            */
                        }

                        // preparations wanted ?
                        if (ds.funcGdtPrepare) {
                            // ****************************************************
                            this.pushPromise({ds, tmp}, function(resolve, reject) {
                                var {ds, tmp} = this.getCustomPromiseData();
                                console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "preparing", ds.szSrcFile);
                                ds.funcGdtPrepare(tmp.gdt);    // do preparations
                                resolve({rc: "OK"});
                            });
                            // ****************************************************
                        }

                        // set column properties
                        // ****************************************************
                        this.pushPromise({ds, tmp}, function(resolve, reject) {
                            var {ds, tmp} = this.getCustomPromiseData();
                            var arrColData = FabStd.buildIntegerArrayFromTo(1, tmp.gdt.getNumberOfColumns()-1);
                            arrColData.forEach((idxCol) => {
                                tmp.gdt.setColumnProperties(idxCol, { 
                                        ... tmp.gdt.getColumnProperties(idxCol),
                                        role: "data", 
                                        ... ds.columnProps
                                    }
                                );
                                // console.log("columnProps", tmp.gdt.getColumnProperties(idxCol));
                            });
                            resolve({rc: "OK"});
                        });

                        // Join datatable to main table
                        // ****************************************************
                        this.pushPromise({ds, tmp}, function(resolve, reject) {
                            var { gdtMain } = this.getCustomChainData();
                            var { ds, tmp} = this.getCustomPromiseData();
                            console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "join started", ds.szSrcFile);
                            this.getCustomChainData().gdtMain = FabStd.googleDataTableJoinAll(gdtMain, tmp.gdt);
                            console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "join finished", ds.szSrcFile);
                            resolve({rc: "OK"});
                        });
                        // ****************************************************
                        resolve({rc: "OK"});
                        // ****************************************************
                    } else {
                        resolve({rc: "ERROR Error creating GoogleDataTable!"});
                    }
                }
            );
            resolve("OK");
        }

        google.charts.load("current", {packages:["table"], 'language': 'de'});
        google.charts.setOnLoadCallback(() => { 
            this._arrDataSource.forEach((ds) => {
                this._chainDataPrep.pushPromise({ ds }, pcfProcessDataSourceMain ); 
            });
    
            this._chainDataPrep.set_onAllSettled(function() {
                console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "onAllSettled", "sorting");
    
                // console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "onAllSettled", "before this.getCustomChainData");
                var { main, gdtMain, smapDescription } = this.getCustomChainData();
                // console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "onAllSettled", "before gdtMain.sort");
                gdtMain.sort({ column: 0, desc: false });  
    
                // now ... we USE the mainTable
                main._gdtMain = gdtMain;
                main._smapDescription = smapDescription;
            
                console.debug(this.constructor.name, "_onBaseDataLoaded", new Date(), "onAllSettled", "finished");
                main._onBaseDataLoadedFinished();
            });    
        });
    }

    _onBaseDataLoadedFinished() {
        console.debug(this.constructor.name, "_onBaseDataLoadedFinished", new Date());

        // we do a little clean up
        this._libGdtTs.gdtFillNullInterimValues(this._gdtMain);        

        console.debug(this.constructor.name, "_onBaseDataLoadedFinished", new Date(), "ready");

        this.setState({bReady: true});
    }

    isReady() {
        return(this.state.bReady);
    }

    aoa_cleanUp(aoa, options) {
        var tmpOpt = {
            cnvExcelDateInCol0: true,
            deleteRowsWithAllEmptyValues: true,
            deleteRowsWithAll0Values: false, 
            ... options
        };

        return(
            aoa.filter((row, idxRow) => {
                var bEmpty = false;
                if (idxRow > 0) {
                    bEmpty = true;
                    row.forEach((col, idxCol) => {
                        if (col !== undefined && col !== null && (!tmpOpt.deleteRowsWithAll0Values || col !== 0)) {
                            // idxCol = 0 is DATE-Column
                            if (idxCol > 0)  {
                                bEmpty = false;
                            } else {
                                if (tmpOpt.cnvExcelDateInCol0) {
                                    aoa[idxRow][idxCol] = fromExcelDate(col);
                                }
                            }
                        }
                    });
                }

                return(!bEmpty || !tmpOpt.deleteRowsWithAllEmptyValues);
            }));
    }

    _loadBaseData() {

        // initialize

        function funcLoad(resolve, reject) {
            var ccd = this.getCustomChainData();
            var cpd = this.getCustomPromiseData();
            var ds = ccd.arrDataSource[cpd.idxDataSource];

            ccd.wfCtrl.getStorageManager().getURL(
                ds.szSrcFile, 
                (url) => {
                    console.debug(this.constructor.name, "URL", ds.szSrcFile);
                    // this.setState({ src: url });
                    var qr = new XMLHttpRequest();
                    qr.open('get', url);
                    qr.responseType = "arraybuffer";
                    qr.send();
                    qr.onload = 
                        (e) => {
                            console.debug(this.constructor.name, "e", "qr", qr);
                        
                            if (qr.status === 200) {
                                ds.wb = FabXlsx.XLSX.read(qr.response, {type:"buffer"});
                                // console.log(FabXlsx.xlsx_getSheetViaIndex(this._wb)["!ref"]);
                                // console.log(this.constructor.name, FabXlsx.xlsx_getSheetViaIndex(this._wb));
    
                                ds.wsData = // FabXlsx.xlsx_getSheetViaIndex(ds.wb);
                                    ds.wb.Sheets["Data"];
                                ds.wsDescription =
                                    ds.wb.Sheets["Description"];

                                console.log(this.constructor.name, "LOADED", ds.szSrcFile);
                                ds.rc = "OK";
                                resolve({ rc: ds.rc });
                            } else {
                                ds.rc = "Error when trying to load!"
                                resolve({ rc: ds.rc });
                            }
                        };
                    }
            );        
        }

        console.debug(this.constructor.name, "_loadBaseData", new Date(), "Start");
        var chainDataLoad = new PromiseChain({
                arrDataSource: this._arrDataSource, 
                wfCtrl: this.getWfCtrl(),
                onAllLoaded: () => { this._onBaseDataLoaded() } 
            });
        chainDataLoad.set_onAllSettled(function() {
            var ccd = this.getCustomChainData(); 
            console.debug(this.constructor.name, "_loadBaseData", new Date(), "End", "onAllSettled");

            if (ccd.onAllLoaded) ccd.onAllLoaded();
        });
        chainDataLoad.pushPromise(function (resolve, reject) {
            var ccd = this.getCustomChainData();
            ccd.arrDataSource.forEach((ds, idxDataSource) => {
                this.pushPromise({idxDataSource }, funcLoad);
            });
            resolve({rc: "ok", info: "all DataTables enqueued for load."});
        });
    }

    getIdDOM_Visualizer(idx) {
        return(this.getIdDOM() + "_visualizer_" + idx);
    }

    getIdDOM_InputField_JobCode() {
        return(this.getIdDOM() + "_if_jobcode");
    }

    getIdDOM_InputField(nIndex) {
        return(this.getIdDOM() + "_IF_" + nIndex);
    }

    getEditor() {
        return(this.getChildCRC(CrcStatistics_Main_01.idChild_editor));
    }

    render() {
        console.log(this.constructor.name, "render", "itemsToVisualize", this._arrItemsToVisualize.length); // , "parent-DOM", this.getParent(), this.getParentDOMElement(), this._arrItemsToVisualize.length);

        var arrResult;

        if (this.isReady()) {
            arrResult = 
                <div 
                    id={this.getIdDOM()} 
                    style={{
                        width: "inherit", 
                        height: "100%", 
                        display: "flex", 
                        flexDirection: "column", 
                        overflowY: "auto"}}>

                    <div style={{
                        padding: "1rem",
                        display: "flex", 
                        flexDirection: "row",
                        height: "7rem",
                        background: "red",
                        width: "100%"
                    }}>
                    <CrcTabBarScripts_01 
                        wfCtrl={this.getWfCtrl()} 
                        getEditor={() => this.getEditor()}
                    />
                    </div>

                    <div style={{
                        padding: "1rem",
                        display: "flex", 
                        flexDirection: "row",
                        height: "calc(100% - 6rem)"
                        }}>  
                        
                        <div style={{ display: "flex", flexDirection: "column", minWidth: "calc(100% - 30rem)", maxWidth: "calc(100% - 10rem)" }}>
                            {
                                this._arrItemsToVisualize.map((obj, idx) => <CrcStatisticsVisualizer_01 
                                    wfCtrl={this.getWfCtrl()} 
                                    key={this.getIdDOM_Visualizer(idx)}
                                    id_dom={this.getIdDOM_Visualizer(idx)} 
                                    showData={obj}
                                    style={{
                                        background: "red", 
                                        maxWidth: "100%", 
                                        height: "100%"}}/>
                                )
                            }
                            
                        </div>

                        <CrcStatistics_Editor_01 
                            id_child={ CrcStatistics_Main_01.idChild_editor }
                            si={this._si}
                            wfCtrl={this.getWfCtrl()} 
                            parent={this}/>

                    </div>
                </div>;
        } else {
            arrResult = 
                <div id={this.getIdDOM()} style={{width: "100%", height: "100%"}} className={"fab-flexbox-center"}>
                    <CrcWait_01 style={{width: "100px", height: "100px"}}></CrcWait_01>
                </div>;
        }

        return(arrResult);
    }
}

CrcStatistics_Main_01.dataNumberFormat_Pct0 = {
    pattern: "+#,##0%;-#,##0%",
    negativeColor: 'red', 
    groupingSymbol: ".", 
    fractionDigits: 0,
    decimalSybmol: ","
};
CrcStatistics_Main_01.dataNumberFormat_Pct2 = {
    pattern: "+#,##0.00%;-#,##0.00%",
    negativeColor: 'red', 
    groupingSymbol: ".", 
    fractionDigits: 2,
    decimalSybmol: ","
};
CrcStatistics_Main_01.dataNumberFormat_Bp0 = {
    suffix: " Bp",
    pattern: "+#,##0;-#,##0",
    negativeColor: 'red', 
    groupingSymbol: ".", 
    fractionDigits: 0,
    decimalSybmol: ","
};

CrcStatistics_Main_01.idChild_editor = "TsScriptEditor_01";
import React from 'react';
import Crc_SC_Base from "./fabbi_crc_sc_base.jsx";
import Crc_SC_AutocompleteSelect_01 from "./fabbi_crc_sc_autocomplete_select_01.jsx";
import Stack from '@material-ui/core/Stack';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import DatePicker from '@material-ui/lab/DatePicker';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// or @material-ui/lab/Adapter{DateFns,DayJS,Luxon,Moment} or any valid date-io adapter
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import moment from 'moment';
import deLocale from 'moment/locale/de';    // ATTENTION! THIS IS ESSENTIAL ... 

export default class Crc_SC_TrrSelect_01 extends Crc_SC_Base {
    constructor(props) {
        super(props);

        console.log(this.constructor.name, "init", "this", this, "props", this.props);

        this._result = {};
    }

    /** @override */
    getResult() {
        var result = {
            ...this._result
            // datValue: this.state.datValue
        }
        return(result);
    }

    /** @override */
    componentDidMount() {
        super.componentDidMount();
    }

    /** @override */
    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    /** */
    onSetSub(crcChild, result) {
        console.log(this.constructor.name, "onSetSub", "id", crcChild.getIdChild(), "result", result);
        switch(crcChild.getIdChild()) {
            case "count_1": this._result["count"] = result; break;
            case "type_1" : this._result["type" ] = result; break;
                break;
        }
        this.moveFocusToNextChildCrc(crcChild);
    }

    render() {
        var result =
            <span id={this.getIdDOM()}>
                <Stack>
                    <Crc_SC_AutocompleteSelect_01 
                        wfCtrl={this.getWfCtrl()}
                        id_child={"count_1"}
                        parent={this}
                        autoFocus={true}
                        style={{width: "300px"}}

                        onSet={(crcChild, result) => { this.onSetSub(crcChild, result); } }
                        onCancel={(crcChild) => console.log(this.constructor.name, "onCancel()", "crcChild", crcChild.constructor.name) }
                        showButton_Set={true}

                        { ... this.props?.percountDialogCfg }
                        { ... this.props?.percountDialogData }
                    />
                    <span>
                        <Crc_SC_AutocompleteSelect_01
                            id_child={"type_1"}
                            wfCtrl={this.getWfCtrl()} 
                            parent={this}
                            autoFocus={false}
                            style={{width: "150px"}}

                            onSet={(crcChild, result) => this.onSetSub(crcChild, result) }
                            onCancel={(crcChild) => console.log(this.constructor.name, "onCancel()", "crcChild", crcChild.constructor.name) }
                            showButton_Set={true}

                            { ... this.props?.pertypeDialogCfg }
                            { ... this.props?.pertypeDialogData }
                        />
                        <IconButton onClick={() => this.onSet() }><CheckCircleOutlineIcon aria-label="ok"/></IconButton>
                    </span>
                </Stack>
            </span>
            ;
        return(result);
    }
}
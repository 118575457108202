function csvToArray(csv, bEliminateDuplicates, bEliminateEmpties) {
    
    if (!!bEliminateDuplicates) {
        csv = csvEliminateDuplicates(csv);    
    }
    if (csv && csv.length > 0) {
        // console.log("csvToArray", csv.split(";"));
        var arr = csv.split(";");
        var p = arr.pop();
        if (p != "") arr.push(p);
        return(arr);
    } else {
        console.log("csvToArray", []);
        return([]); // return an empty array
    }
}

function parseParamAndValue(szParameterAndValue, szSeparator) {
    if (!szParameterAndValue) {
        return({
            param: "",
            value: ""
        });
    }
    if (!szSeparator) szSeparator = "=";

    var nPos = szParameterAndValue.indexOf(szSeparator);
    if (nPos <= 0) {
        return({
            param: szParameterAndValue,
            value: ""
        });
    } else {
        return({
            param: szParameterAndValue.substring(0, nPos),
            value: szParameterAndValue.substring(nPos+1)
        });
    }
}

function csvEliminateDuplicates(csv) {
    if (csv && csv.length > 0) {
        csv = csv.replace(/;{2} /g, ";");
        csv = csv.replace(/^;+|;+$ /g,"");
    }
    return(csv);
}

function arrayToCsv(array) {
    var szTmp = "";
    if (array && array.length > 0) {
        szTmp = array.join(";");
    }    
    return(szTmp);
}

function arrayRollTo(array, nNewFirstIndex) {
    var newArray = [];
    if (nNewFirstIndex === undefined || nNewFirstIndex < 0 || nNewFirstIndex >= array.length) {
        console.log("arrayRollTo", array.length, "<", nNewFirstIndex);
        nNewFirstIndex = 0;
    }

    for (var i=0; i < array.length; ++i) {
        newArray.push(array[(nNewFirstIndex+i) % array.length]);
    }   
    return(newArray);
}

function arrayGetRandomEntry(arrArray) {
    if (!arrArray) return(undefined);
    if (arrArray.length === 0) return(undefined);
    return(arrArray[randomInt(arrArray.length-1)]); 
}

function convertToString(input, default_onNoStringOrError) {    
    var szErg ;
    try { 
        if (input !== null && input !== undefined && isString(input) === true) {
            szErg = input;            
        } else {
            return(default_onNoStringOrError);
        }
    } catch(e) {        
        return(default_onNoStringOrError);
    }
    return(szErg);
}

function convertTimestampToLocaleDateTimeString(timestamp, default_onError) {
    var szDat = convertTimestampToLocaleDateString(timestamp, null);
    if (szDat === null) return(default_onError);
    
    var szTim = convertTimestampToLocaleTimeString(timestamp, null);
    if (szTim === null) return(default_onError);
    
    return(szDat + " " + szTim);
} 

function convertTimestampToLocaleDateString(timestamp, default_onError) {
    var szErg = default_onError;
    try { szErg = timestamp.toDate().toLocaleDateString(); } catch(e) { }
    return(szErg);
}

function convertTimestampToLocaleTimeString(timestamp, default_onError) {
    var szErg = default_onError;
    try { szErg = timestamp.toDate().toLocaleTimeString(); } catch(e) { }
    return(szErg);
}

function convertTimestampToDate(timestamp, default_onError) {
    var datErg = default_onError;
    try { datErg = timestamp.toDate(); } catch(e) { }
    return(datErg);
}

function csvFileParse(csvFile) {
    var rows = [];

    // semicolon-separated files
    var fieldRegEx = new RegExp('(?:\s*"((?:""|[^"])*)"\s*|\s*((?:""|[^";\r\n])*(?:""|[^"\s;\r\n]))?\s*)(;|[\r\n]+|$)', "g");   
    var row = [];
    var currMatch = null;

    while (currMatch = fieldRegEx.exec(csvFile)) {
        row.push([currMatch[1], currMatch[2]].join('')); // concatenate with potential nulls

        if (currMatch[3] != ';')
        {
            rows.push(row);
            row = [];
        }

        if (currMatch[3].length == 0)
            break;
    }
    console.log(rows);
    return(rows);
}

// lines separated with linebreak
// parts of one line separated by <szDelimeter> e.g. " " (space)
function parseDelimitedLines(lines, szDelimeter, szRegExp) {
    var aoaRows = [];

    // delimeter-separated
    if (!szRegExp || szRegExp.length <= 0)
        szRegExp = '(?:\\s*\\"((?:\\"\\"|[^\\"])*)\\"\\s*|\\s*((?:\\"\\"|[^\\"' + szDelimeter + '\\r\\n])*(?:\\"\\"|[^\\"\\s' + szDelimeter + '\\r\\n]))?\\s*)(\\' + szDelimeter + '|[\\r\\n]+|$)'; 
    
    var fieldRegEx = new RegExp(szRegExp, 'g');
    //    /(?:\s*\"((?:\"\"|[^\"])*)\"\s*|\s*((?:\"\"|[^\" \r\n])*(?:\"\"|[^\s \r\n]))?\s*)( |[\r\n]+|$)/g;
    
    var arrRow = [];
    var currMatch = null;

    while (currMatch = fieldRegEx.exec(lines)) {
        console.log("currMatch", currMatch);
        arrRow.push([currMatch[1], currMatch[2]].join('')); // concatenate with potential nulls

        if (currMatch[3] !== szDelimeter)
        {
            aoaRows.push(_rejoinBracketedParts(arrRow));
            arrRow = [];
        }

        if (currMatch[3].length <= 0)
            break;
    }
    // console.log("par", aoaRows, "RegExp", szRegExp);

    return(aoaRows);
}

function _rejoinBracketedParts(arrParts) {
    var nBracketsOpen = 0;
    var idxStart = -1;
    var arrResult = [];
    
    arrParts.map((param, idx) => {
        var regExpBrackets = /(\()|(\))/g; 
        var matchBrackets;
        while (matchBrackets = regExpBrackets.exec(param)) {
            if (matchBrackets) {
                if  (matchBrackets[1]) ++nBracketsOpen; else --nBracketsOpen;
            }
        }
        arrResult.push(param);

        if (nBracketsOpen > 0 && idxStart < 0) idxStart = idx;
        if (nBracketsOpen === 0) {
            if (idxStart >= 0) {
                var nTmp = idx - idxStart;
                for (var i=0; i < nTmp; ++i) arrResult.pop();
                for (var idxTmp=idxStart+1; idxTmp <= idx; ++idxTmp) {
                    arrResult[arrResult.length-1] += arrParts[idxTmp];
                }
            }
            idxStart = -1;
        }
        // console.log("matchBrackets", param, matchBrackets, nBracketsOpen);
    });
    return(arrResult);
}


function randomInt(nMax, nMin = 0) {
    return(nMin + Math.floor(Math.random() * (nMax - nMin + 1)));
}

function Timestamp_calcMid(ts1, ts2) {
    if (!ts1 || !ts2) return(ts1);

    var nNanoDivisor = 1000000000;
    var bResultEven = ((ts1.seconds % 2) === (ts2.seconds % 2));
    var nNanosecSum = ts1.nanoseconds + ts2.nanoseconds + (bResultEven?0:nNanoDivisor);
    var nSecSum = ts1.seconds + ts2.seconds + (bResultEven?0:(-1));

    var proto = (!!ts1)?(Object.getPrototypeOf(ts1)):(Object.getPrototypeOf(ts2));
    
    var ts = new proto(
            (nSecSum + (nNanosecSum /nNanoDivisor | 0)) / 2 | 0,
            (nNanosecSum / 2) % nNanoDivisor | 0);        
    
    /*

    var nSec1Mod = (ts1.seconds % 2);
    var nSec2Mod = (ts2.seconds % 2);            

    var nAdditionalSecFromNano = ((ts1.nanoseconds + ts2.nanoseconds) / nNanoDivisor) | 0;
    var nAdditionalNanosec = ((ts1.nanoseconds + ts2.nanoseconds) % nNanoDivisor) | 0;

    var ts = new Timestamp(
            ((ts1.seconds / 2) + (ts2.seconds / 2) + (nSec1Mod + nSec2Mod + nAdditionalSecFromNano / 2)) | 0,
            (nAdditionalNanosec / 2) | 0);

    console.log("Timestamp_calcMid", ts1, ts2, nSec1Mod, nSec2Mod, ts1.seconds, ts2.seconds, nAdditionalNanosec | 0, "ERGEBNIS", ts);
    */
    
    // console.log("Timestamp_calcMid", ts1, ts2, "ERGEBNIS", ts);
    
    return (ts);        
}

/*
    // So ... her are isString, isNumber, isDate ... coded ... in a wonderful variable style!
    ['Arguments', 'Function', 'String', 'Number', 'Date', 'RegExp'].forEach( 
        function(name) { 
            window['is' + name] = function(obj) {
                return toString.call(obj) == '[object ' + name + ']';
        }; 
    });
*/

function isArguments(obj) {
    return(toString.call(obj) == '[object Arguments]'); 
}

function isFunction(obj) {
    return(toString.call(obj) == '[object Function]'); 
}

function isString(obj) {
    return(toString.call(obj) == '[object String]'); 
}

function isNumber(obj) {
    return(toString.call(obj) == '[object Number]'); 
}

function isDate(obj) {
    return(toString.call(obj) == '[object Date]'); 
}

function isRegExp(obj) {
    return(toString.call(obj) == '[object RegExp]'); 
}

function isFunctionBindable(fnc) {
    return(fnc.hasOwnProperty('prototype'));
}

function isElementInView(element, fullyInView) {
    var view = element.offsetParent;
    
    var pageTop = view.scrollTop;
    var pageBottom = pageTop + view.offsetHeight;
    var elementTop = element.offsetTop;
    var elementBottom = elementTop + element.offsetHeight;
    
    // console.log("isElementInView", view, pageTop, pageBottom, elementTop, elementBottom);

    if (fullyInView === true) {
        return ((pageTop < elementTop) && (pageBottom > elementBottom));
    } else {
        return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
    }
}

/** adapts the content of all not "undefined" properties of the Object named <objToAdaptFrom> to the Object <objToAdaptTo> 
 * Note: Just those properties will be adapted which are already DEFINED in the To-Object. No new properties will be added.
 * @param {Object} objToAdaptTo The Object to which the contents of the From-Object shall be adapted.
 * @param {Object} objToAdaptFrom The Object whose properties shall be adapted to the To-object.
 * @returns {Object} objToAdaptTo - the To-Object is returned as convenience (the reference to objToAdaptTo is unchanged)
*/
function objPropsAdaptExisting(objToAdaptTo, objToAdaptFrom) {
    Object.keys(objToAdaptTo).forEach((ok) => { if (objToAdaptFrom[ok] !== undefined) objToAdaptTo[ok] = objToAdaptFrom[ok] });
    return(objToAdaptTo);
}

/** deletes alle properties from the input-object, leaving just the object-reference unchanged. 
 * @returns {Object} The obj is returned as convenience (the reference to obj is unchanged)  
*/
function objPropsDelete(obj) {
    Object.keys(obj).forEach((propName) => delete obj[propName]);
    return(obj);
}

/** sets or adds (if not already existing) the properties of object obj according to the object objDeliveringPropsToAddOrSet 
 * @param {Object} obj Object whose properties shall be extended (set or add)
 * @param {Object} objDeliveringPropsToAddOrSet Object whose properties shall be used to extend obj.
 * @returns {Object} The obj is returned as convenience (the reference to obj is unchanged)  
*/
function objPropsAddOrSet(obj, objDeliveringPropsToAddOrSet) {
    Object.keys(objDeliveringPropsToAddOrSet).forEach((propName) => obj[propName] = objDeliveringPropsToAddOrSet[propName]);
    return(obj);
}

function addProp(object, szPropertyName, vPropertyValue, bProperty_Enumerable, bProperty_Writable) {
    if (object === undefined) {
        object = {};
    }    

    if (bProperty_Enumerable === undefined) bProperty_Enumerable = true;
    if (bProperty_Writable === undefined) bProperty_Writable = true;

    Object.defineProperty(
        object, szPropertyName, 
        { 
            value: vPropertyValue, 
            enumerable: bProperty_Enumerable, 
            writable: bProperty_Writable
        });                                
    return(object);
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function toInitials(szNameTxt) {    
    if (!szNameTxt) {
        return(""); // no initials
    }

    const pipe = (...fns) => (x => fns.reduce((y, f) => f(y), x));      // x = inital value
    const reverseDelimitedText = (delimiter = ' ', newDelimiter = delimiter) => (text = '') => text.split(delimiter).reverse().join(newDelimiter);    
    
    const getInitialsDelimitBy = (delimiter = ' ') => (displayName = '') =>    
      displayName
        .trim()
        .split(delimiter)
        .reduce((acc, value) => `${acc}${value.charAt(0)}`, '')
        .toUpperCase();
    
    const getInitialsDelimitByComas = pipe(
        reverseDelimitedText(',', ' '),        // fns[0]       within "reduce" executed first
        getInitialsDelimitBy(' ')               // fns[1]      
    );    
    const getInitialsDelimitBySpaces = getInitialsDelimitBy(' '); // Not necessary because of the default but clearer 

    if (szNameTxt.includes(",")) {
        return(getInitialsDelimitByComas(szNameTxt));
    } else {
        return(getInitialsDelimitBySpaces(szNameTxt));
    }
}

/**
 * Returns a function, that, when invoked, will only be triggered at most once
 * during a given window of time. Normally, the throttled function will run
 * as much as it can, without ever going more than once per `wait` duration;
 * but if you'd like to disable the execution on the leading edge, pass
 * `{leading: false}`. To disable execution on the trailing edge, ditto.
 * @license https://raw.github.com/jashkenas/underscore/master/LICENSE
 * @param {function} func
 * @param {number} wait
 * @param {Object=} options
 * @returns {Function}
 */
function throttle(func, wait, options) {
    let context = void 0,
        args = void 0,
        result = void 0;
    let timeout = null;
    let previous = 0;

    let funcGlob = func;
    let waitGlob = wait;
    let optionsGlob = options

    optionsGlob || (optionsGlob = {});
    let later = () => {
        previous = optionsGlob.leading === false ? 0 : new Date().getTime();
        timeout = null;
        result = funcGlob.apply(context, args);
        context = args = null;
    };

    var actual = () => {
        var now = new Date().getTime();
        if (!previous && optionsGlob.leading === false) previous = now;
        var remaining = waitGlob - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0) {
            clearTimeout(timeout);
            timeout = null;
            previous = now;
            result = funcGlob.apply(context, args);
            context = args = null;
        } else if (!timeout && optionsGlob.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };

    return actual;
};

function addToArraySpecial(dstArray, srcArrayOrSingleObject, filterInstanceOf) {
    if (dstArray === undefined) dstArray = [];

    if (Array.isArray(srcArrayOrSingleObject)) {
        srcArrayOrSingleObject.forEach(
            (obj) => { 
                if (!filterInstanceOf || obj instanceof filterInstanceOf) {
                    dstArray.push(obj);
                } else {
                    console.log("CRCXX", "FAILED", obj instanceof filterInstanceOf, obj, filterInstanceOf);
                }
            }
        );
    } else {
        var obj = srcArrayOrSingleObject;
        if (!filterInstanceOf || obj instanceof filterInstanceOf) {
            dstArray.push(obj);
        }
    }
    return(dstArray);
}

function buildIntegerArrayFromTo(nFrom, nTo) {
    var nStep = 1;
    var arrResult = [];
    if (nTo < nFrom) nStep = -1;

    for (;nFrom <= nTo;nFrom += nStep) {
        arrResult.push(nFrom);
    }
    return(arrResult);
}   

function googleDataTableJoinAll(dt_left, dt_right) { 
    console.log(this.constructor.name, "googleDataTableJoinAll", "start");
    
    if (!dt_left && !dt_right) return(undefined);
    if (!dt_left) return(dt_right.clone());
    if (!dt_right) return(dt_left.clone());

    console.debug(this.constructor.name, "googleDataTableJoinAll", "detailed join", "start");

    var tmp = google.visualization.data.join(
            dt_left, 
            dt_right, 
            "full",     // join type 
            [[0,0]],    // key-columns
            buildIntegerArrayFromTo(1, dt_left.getNumberOfColumns()-1), // source-cols from left table 
            buildIntegerArrayFromTo(1, dt_right.getNumberOfColumns()-1) // source-cols from right table
        );

    console.debug(this.constructor.name, "googleDataTableJoinAll", "detailed join", "finished");

    return(tmp);
}

function NormStDevInv(p) {
    var a1 = -39.6968302866538, a2 = 220.946098424521, a3 = -275.928510446969;
    var a4 = 138.357751867269, a5 = -30.6647980661472, a6 = 2.50662827745924;
    var b1 = -54.4760987982241, b2 = 161.585836858041, b3 = -155.698979859887;
    var b4 = 66.8013118877197, b5 = -13.2806815528857, c1 = -7.78489400243029E-03;
    var c2 = -0.322396458041136, c3 = -2.40075827716184, c4 = -2.54973253934373;
    var c5 = 4.37466414146497, c6 = 2.93816398269878, d1 = 7.78469570904146E-03;
    var d2 = 0.32246712907004, d3 = 2.445134137143, d4 = 3.75440866190742;
    var p_low = 0.02425, p_high = 1 - p_low;
    var q, r;
    var retVal;

    if ((p < 0) || (p > 1))
    {
        alert("NormStDevInv: Argument out of range.");
        retVal = 0;
    }
    else if (p < p_low)
    {
        q = Math.sqrt(-2 * Math.log(p));
        retVal = (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    }
    else if (p <= p_high)
    {
        q = p - 0.5;
        r = q * q;
        retVal = (((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q / (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
    }
    else
    {
        q = Math.sqrt(-2 * Math.log(1 - p));
        retVal = -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) / ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
    }

    return retVal;
}

function randomNormStDev() {
    var u = 0, v = 0;
    while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    var num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
    // num = num / 10.0; // + 0.5; // Translate to 0 -> 1
    return(num);
    // if (num > 1 || num < 0) return randn_bm(); // resample between 0 and 1
    // return num;
}

function resetArray(array) {
    if (array) while (array.length > 0) array.pop();
    return(array);
}

// some mathematics

/*
// Student's T-Distribution
// ********************************************
// x: input value
// n = "degrees of freedom" (Freiheitsgrade)
// return: cumulated Probability
function calcProbability_tDistibution(x, n) {

    if (n <= 0) {
        return(null);
    }

    with (Math) {
        var A =df/2;
        var S = A+.5;
        var Z = df/(df+X*X);
        BT=Math.exp(LogGamma(S)-LogGamma(.5)-LogGamma(A)+A*Math.log(Z)+.5*Math.log(1-Z));
        if (Z<(A+1)/(S+2)) {
            betacdf=BT*Betinc(Z,A,.5)
        } else {
            betacdf=1-BT*Betinc(1-Z,.5,A)
        }
        if (X<0) {
            tcdf=betacdf/2
        } else {
            tcdf=1-betacdf/2
        }
        tcdf=Math.round(tcdf*100000)/100000;
    }
    return(tcdf);
}

function LogGamma(Z) {
	with (Math) {
		var S=1+76.18009173/Z-86.50532033/(Z+1)+24.01409822/(Z+2)-1.231739516/(Z+3)+.00120858003/(Z+4)-.00000536382/(Z+5);
		var LG= (Z-.5)*log(Z+4.5)-(Z+4.5)+log(S*2.50662827465);
	}
	return LG
}

function Betinc(X,A,B) {
	var A0=0;
	var B0=1;
	var A1=1;
	var B1=1;
	var M9=0;
	var A2=0;
	var C9;
	while (Math.abs((A1-A2)/A1)>.00001) {
		A2=A1;
		C9=-(A+M9)*(A+B+M9)*X/(A+2*M9)/(A+2*M9+1);
		A0=A1+C9*A0;
		B0=B1+C9*B0;
		M9=M9+1;
		C9=M9*(B-M9)*X/(A+2*M9-1)/(A+2*M9);
		A1=A0+C9*A1;
		B1=B0+C9*B1;
		A0=A0/B1;
		B0=B0/B1;
		A1=A1/B1;
		B1=1;
	}
	return A1/A
}
// **********************************************************************


// ChiQuadratDistribution
// **********************************************************************
function calcProbability_ChiQuadratDistribution(x, n) {
    if (n <=0) {
        return(null); // degrees of freedom must bie positive
    }

    Chisqcdf=Gammacdf(Z/2,DF/2)
	Chisqcdf=Math.round(Chisqcdf*100000)/100000;
    return(Chisqcdf);
}

function Gcf(X,A) {        // Good for X>A+1
	with (Math) {
		var A0=0;
		var B0=1;
		var A1=1;
		var B1=X;
		var AOLD=0;
		var N=0;
		while (abs((A1-AOLD)/A1)>.00001) {
			AOLD=A1;
			N=N+1;
			A0=A1+(N-A)*A0;
			B0=B1+(N-A)*B0;
			A1=X*A0+N*A1;
			B1=X*B0+N*B1;
			A0=A0/B1;
			B0=B0/B1;
			A1=A1/B1;
			B1=1;
		}
		var Prob=exp(A*log(X)-X-LogGamma(A))*A1;
	}
	return 1-Prob
}

function Gser(X,A) {        // Good for X<A+1.
    with (Math) {
		var T9=1/A;
		var G=T9;
		var I=1;
		while (T9>G*.00001) {
			T9=T9*X/(A+I);
			G=G+T9;
			I=I+1;
		}
		G=G*exp(A*log(X)-X-LogGamma(A));
    }
    return G
}

function Gammacdf(x,a) {
	var GI;
	if (x<=0) {
		GI=0
	} else if (x<a+1) {
		GI=Gser(x,a)
	} else {
		GI=Gcf(x,a)
	}
	return GI
}
// **********************************************************************

*/

/**
 * Returns a function, that, when invoked, will only be triggered at most once
 * @param {Array} arrObj Array of Objects of same prototype.
 * @returns {Object} An Object that allows to execute the known function names of (all) the Array-member-Objects owned functions. 
 * The return-values are collected in a result-array.
 */
 function arrayToMultiUsable(arrObj) {
    // console.debug("multiUsable", "length:", arrObj.length);
    if (!arrObj || arrObj.length < 1) return(arrObj);

    class sameObjectPrototypeCollection {
        constructor(arrObj) {
            this._arrObj = arrObj;
        }
    }

    var coll = new sameObjectPrototypeCollection(arrObj);
    var prototype = Object.getPrototypeOf(coll._arrObj[0]);
    var mapObjProp  = {};
        
    // console.debug("multiUsable", "prototype", prototype);
    arrayToMultiUsable_sub(mapObjProp, prototype);

    // console.debug("multiUsable", "step2.5", mapObjProp);
    Object.defineProperties(sameObjectPrototypeCollection.prototype, mapObjProp);
    
    // console.debug("multiUsable", "step3", coll);
    return(coll);
}

/** Sub-Function for "arrayToMultiUsable" */
function arrayToMultiUsable_sub(mapObjProp, prototype) {
    // console.debug("multiUsable", "step2.0", prototype.constructor.name); // , "super", Object.getPrototypeOf(prototype).constructor.name);
    var objDesc = Object.getOwnPropertyDescriptors(prototype);
    // console.debug("multiUsable", "step2.1", objDesc, Object.keys(objDesc).length);
    var superPrototype = Object.getPrototypeOf(prototype);

    Object.keys(objDesc).forEach((key, i) => {
        var desc = objDesc[key];
        // console.debug("multiusable", "iterate", i, desc, key);
        if (desc.value) {
            var funcName = key!="constructor"?key:desc.value.name;
            // console.debug("multiusable", "iterate", i, funcName);

            // we have a prototype
            // if (desc.value.prototype) {
                // go through prototype
            //    console.log(i, "prototype found", desc.value.prototype);
                // arrayToMultiUsable_sub(mapObjProp, desc.value.prototype);
            // } else {
            
                var objProp = {
                    value:  function(someArguments) {
                        // console.log("FUNCTIONCALL", this, this.constructor.name, "arguments", arguments);
                        var arrResult=[];
                        // pass argument-List to all OBJECTS in List
                        this._arrObj.forEach((obj) => arrResult.push(obj[funcName](...arguments)) );
                        return(arrResult);
                    }
                };
                mapObjProp[funcName] = objProp;
                // console.debug("multiUsable", i, "funcName", funcName, "desc", desc);
        }
    });
    if (superPrototype) {
        arrayToMultiUsable_sub(mapObjProp, superPrototype);
    }
}

export {
    addProp,
    addToArraySpecial, 
    arrayGetRandomEntry,
    arrayToCsv,
    arrayToMultiUsable,
    arrayRollTo,
    buildIntegerArrayFromTo, 
//    calcProbability_ChiQuadratDistribution,
//    calcProbability_tDistibution,
    convertTimestampToDate, 
    convertTimestampToLocaleDateString, 
    convertTimestampToLocaleDateTimeString, 
    convertTimestampToLocaleTimeString, 
    convertToString,
    csvEliminateDuplicates,
    csvToArray,
    csvFileParse,
    googleDataTableJoinAll,
    isArguments,
    isElementInView,
    isFunction,
    isFunctionBindable,
    isString,
    isNumber,
    isDate,
    isRegExp,
    NormStDevInv,
    objPropsAdaptExisting,
    objPropsAddOrSet,
    objPropsDelete,
    parseDelimitedLines,
    parseParamAndValue, // separated, pair
    randomInt,
    randomNormStDev, 
    resetArray,
    throttle,
    Timestamp_calcMid,
    toInitials,
    validateEmail
};

import React, { Component, useRef } from 'react';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import ReactDOM from 'react-dom';
import ControlledReactComponentExtension from './fabbi_controlled_react_component_extension.jsx';
// import ControlledReactComponentExtensionParent from './fabbi_controlled_react_component_extension_parent.jsx';

export default class ControlledReactComponent extends React.Component {
    constructor(props) {
        super(props);

        // is this a child-CRC ?
        if (this.props.id_child) {
            if (!this.props.wfCtrl || !this.props.parent) {
                console.error(
                    this.constructor.name, 
                    "An obligatory props-object is missing!", 
                    (!this.props.wfCtrl?"wfCtrl ":"") + 
                    (!this.props.parent?"parent ":"")  
                );
            }
        }

        // remember, this one was not mounted yet
        this._mounted = false;

        this.state = { };

        this.__idTempUnique = ControlledReactComponent.getNewUniqueTempId();
        
        // use extensions
        if (!!props.extensions) {
            // console.log(this.constructor.name, "CRCXX EXTENSIONS", props.extensions);
            this.addExtensions(props.extensions);

            // console.log(this.constructor.name, "CRCXX EXTENSIONS", this.__arrExtensions[0] instanceof ControlledReactComponentExtension, this.__arrExtensions[0] instanceof ControlledReactComponentExtensionParent, this.__arrExtensions.length);
        } else {
            this.__arrExtensions = [];  // dummy
        }

        // next-Component-Update ... toDoList
        this._arrDoOnNextComponentUpdate = [];

        // update-statistic
        this._tsLastRenderFinished = 0;
    }

    doOnNextComponentUpdate(funcToDoOnNextUpdate) {
        this._arrDoOnNextComponentUpdate.push(funcToDoOnNextUpdate);
    }
    
    getIdChild() {
        return(this.props.id_child);
    }

   /* forces an "unmount" of this CRC
    */
    unmount() {
        if (!this.getDOMElement()) {
            console.log(this.constructor.name, "unmount()", "failed (no DOM-Element)");
            return(false);
        }

        // first ... try to unmount DIRECTLY ...
        if (!ReactDOM.unmountComponentAtNode(this.getDOMElement())) {
            // second try ...
            var pN = this.getDOMElement().parentNode;
            if (!pN) return(false);

            return(ReactDOM.unmountComponentAtNode(pN));
        } else {
            return(true);
        }
    }

    addExtensions(singleExtensionOrArrayOfExtensions) {
        this.__arrExtensions = FabStd.addToArraySpecial(
            this.__arrExtensions, 
            singleExtensionOrArrayOfExtensions, 
            ControlledReactComponentExtension   // add only if instance of "ControlledReactComponentExtensions"
        );
        return(true);
    }

    addExtension(singleExtensionOrArrayOfExtensions) {
        return(this.addExtensions(singleExtensionOrArrayOfExtensions));
    }

    getUniqueTempId() {
        return(this.__idTempUnique );
    }

    isMounted() {
        return(this._mounted);
    }

    _newUpdatePromise() {
        // console.log(this.constructor.name, "_newUpdatePromise");
        var p = new Promise((resolve, reject) => {
            // resolve will normally be called lateron
            var tsStart = Date.now();
            var iv;
            iv = setInterval(() => { 
                if (this._tsLastRenderFinished > tsStart) {
                    // console.log(this.constructor.name, "_newUpdatePromise", "intervall check", "RESOLVING", iv);
                    clearInterval(iv);
                    resolve({ rc: "OK" });
                }
            }, 5); // every 5 msec check!
            setTimeout(() => { 
                clearInterval(iv); 
                resolve({rc: "ERR Timeout reached! No update seen!" }) }, 30*1000);
        });
        return(p);
    }

    _reportRenderFinished() {
        this._tsLastRenderFinished = Date.now();

        var funcHandler = this.props?.onRenderFinshed;
        if (funcHandler) {
            console.debug(this.constructor.name, "onRenderFinished", "funcHandler", funcHandler);
            funcHandler(this);
        }
    }

    /*
    stateChange is optional
    */
    update(stateChange) {
        var p = this._newUpdatePromise();
        this.setState(stateChange?stateChange:{});
        return(p);
    }

    getParent() {
        return(this.props.parent);
    }

    getDOMElement() {
        var domElement = document.getElementById(this.getIdDOM());
        if (!domElement) {
            console.debug(this.constructor.name, "getDOMElement", "not found", "id", this.getIdDOM());
            var dummy;
            dummy = 0;
        }
        return(domElement);
    }  

    getParentDOMElement() {
        if (this.getParent()) {
            return(this.getParent().getDOMElement());
        }
    }

   /** getExplicitIdDOMOfParent
    *  @param {Boolean=false} bIfNoParentsIdDOM_ReturnNextParentsIdDOM optional. if true, then - if this element doesn't have an own "id_dom" 
    *                         (id of the DOM-Elemen), the "id_dom" of the parent-element (or - if not available - the parent's parent and so on) 
    *                         is returned. 
    *                         Default is False. 
    *  @return {String}       The own id_dom or the one of the parent.
    */ 
    getExplicitIdDOMOfParent(bIfNoParentsIdDOM_ReturnNextParentsIdDOM) {
        try {
            return(this.getParent().getExplicitIdDOM(bIfNoParentsIdDOM_ReturnNextParentsIdDOM)); 
        } catch (error) {  
            console.warn(this.constructor.name, "getExplicitIdDOMOfParent", "failed, no parent found.");
            // don't do anything special
            return(undefined);
        }
    }

    getIdDOM() {
        var szId = this.getExplicitIdDOM(false, false);
        if (!szId) szId = this.getUniqueTempId();
        return(szId);
    }

   /** getExplicitIdDOM
    *  @param {Boolean=false} bIfNoOwnIdDOM_ReturnNextParentsIdDOM optional. if true, then - if this element doesn't have an own "id_dom" 
    *                         (id of the DOM-Elemen), the "id_dom" of the parent-element is returned. 
    *                         Default is False. 
    *  @param {Boolean=true} bIfNoParentsIdDOM_ReturnNextParentsIdDOM optional. 
    *                         Is only used, if bIfNoOwnIdDOM_ReturnNextParentsIdDOM was set to true. 
    *                         If true, then - if no direct parent "dom id" was found, then we try to return the next parent's dom_id
    *                         and so on. 
    *                         Default is true.
    *  @return {String}       The own id_dom or the one of the parent.
    */ 
    getExplicitIdDOM(
            bIfNoOwnIdDOM_ReturnNextParentsIdDOM, 
            bIfNoParentsIdDOM_ReturnNextParentsIdDOM) {

        // console.log("CRC", "GetIdDOM", this.props.id_dom, this.props.parent.id_dom);
        if (this.props.id_dom) return(this.props.id_dom);

        if (bIfNoOwnIdDOM_ReturnNextParentsIdDOM !== true) {
            bIfNoOwnIdDOM_ReturnNextParentsIdDOM = false;
        }

        if (bIfNoOwnIdDOM_ReturnNextParentsIdDOM) {
            if (bIfNoParentsIdDOM_ReturnNextParentsIdDOM !== false) bIfNoParentsIdDOM_ReturnNextParentsIdDOM = true;

            return(this.getExplicitIdDOMOfParent(bIfNoParentsIdDOM_ReturnNextParentsIdDOM));
        }
        return(undefined);
    }


    getWfCtrl() {
        return(this.props.wfCtrl);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // go through all Extension and tell them, that the "componentDidUpdate"
        this.__arrExtensions.forEach((ext) => {
            ext.underlyingComponentDidUpdate(this, prevProps, prevState, snapshot);
        });        

        // report that a rendering-process has been finished
        this._reportRenderFinished();

        // report to parent
        if (this.getParent() && this.getParent().childComponentDidUpdate) {
            this.getParent().childComponentDidUpdate(this, prevProps, prevState, snapshot);
        }

        if (this.state && this.state.onComponentDidUpdate) {
            this.state.onComponentDidUpdate(this, prevProps, prevState, snapshot);
            this.state.onComponentDidUpdate = undefined;
        }

        // execute toDoListe "for next update"
        var funcToDo, len = this._arrDoOnNextComponentUpdate.length;
        for (var i=0; i < len; ++i) {
            funcToDo = this._arrDoOnNextComponentUpdate.pop();
            // console.log("funcToDo", funcToDo);
            if (funcToDo) funcToDo();
        } 
    } 

    componentDidMount() {
        this._mounted = true;

        // console.log(this.constructor.name, "CRC componentDidMount", this);        
        if (!!this.props.wfCtrl) {
            this.props.wfCtrl.registerControlledReactComponent(this);
        }

        // do we have a special action defined ?
        if (this.props.onComponentDidMount) this.props.onComponentDidMount(this);
        
        // go through all Extension and tell them, that the "underlyingComponentDidMount"
        this.__arrExtensions.forEach((ext) => {
            ext.underlyingComponentDidMount(this);
        });

        // report that a rendering-process has been finished
        this._reportRenderFinished();

        // report to parent
        if (this.getParent() && this.getParent().childComponentDidMount) {
            this.getParent().childComponentDidMount(this);
        }
    }
    
    componentWillUnmount() {
        // go through all Extensions and tell them, the underlying component will unmount
        this.__arrExtensions.forEach((ext) => {
            ext.underlyingComponentWillUnmount(this);
        });

        // console.log(this.constructor.name, "CRCUNMOUNT componentWillUnmount", this);
        // super.componentWillUnmount();
        if (this.props.wfCtrl) {            
            this.props.wfCtrl.deregisterControlledReactComponent(this);
        }

        // report to parent
        if (this.getParent() && this.getParent().childComponentWillUnmount) {
            this.getParent().childComponentWillUnmount(this);
        }
    }

    getCRC(id_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself) {
        if (!this.props.wfCtrl) {
            console.error(this.constructor.name, "getCRC", "Problem: no wfCtrl was provided in props!");
            return(undefined);
        }
        // console.log(this.constructor.name, "getCRC", "by getExtension", id_or_htmlElement);
        return(this.props.wfCtrl.getCRC(id_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself));
    }

    getExtensionOfPrototype(prototypeExtension) {
        var ext = undefined;
        for (var i = 0; i < this.__arrExtensions.length; ++i) {
            if (this.__arrExtensions[i] instanceof prototypeExtension) {
                ext = this.__arrExtensions[i];
                break;
            }
        }
        // console.log(this.constructor.name, "getExtensionOfPrototype", "result", ext);
        return(ext);
    }

    static getNewUniqueTempId() {
        if (!window.__crcCount) window.__crcCount = 1; else ++window.__crcCount;
        return("__crc__" + window.__crcCount);       
    }
}
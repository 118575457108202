import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js"; 
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import qryProcessor_Members from "./fabbi_qry_processor_members.js";
import qryProcessor_UserFamilyReferences from "./fabbi_qry_processor_userfamilyreferences.js";
import CrcUserInvitations_Entry_01 from "./fabbi_crc_user_invitations_entry_01.jsx";
import { writeBatch, Timestamp } from "firebase/firestore";

export default class HtmlProcessor_UserInvitations extends HtmlProcessor {    
    constructor(props) {
        super(props);
    }

    static completeProps(props) {
        super.completeProps(props);
        
        props.HtmlElementIdPrefix = "userinvitations";

        if (!props.crcDefaultProps_entryList.crcPrototype_entry) { 
            props.crcDefaultProps_entryList.crcPrototype_entry = CrcUserInvitations_Entry_01
        }
        return(props);
    }
   
    onPrepareEntry(id_entry, entry) {
   
        var dataset = entry.dataset;
        var datasetExtra = entry.datasetExtra;

        datasetExtra[HtmlProcessor_UserInvitations.ID_TXT_NAME] = { innerHTML: dataset.name };
        datasetExtra[HtmlProcessor_UserInvitations.ID_TXT_NAME_FAMILY] = { innerHTML: dataset.name_family };
        datasetExtra[HtmlProcessor_UserInvitations.ID_TXT_GREETING] = { innerHTML: dataset.greeting };

        var fncAccept;
        var fncReject;
        
        let szFamilyId = id_entry;        // identity!        
        fncAccept = function() {
            // we have to
            // add the user to the family                        
            // add the family-reference            
            // add the user to the foreign "users_authorized"
            // add the family-users to "users_authorized"'            
            // delete the invitation AFTER 
            var tsa = writeBatch(this.getWfCtrl().getFirestore());
            var qpTmp;
            
            // we have to return a promise
            console.log("TRANSACTION", tsa);
            let szUserId = g_wfCtrl.getMainUserId();                

            // add new member to family
            qpTmp = new qryProcessor_Members({ 
                wfCtrl: g_wfCtrl, 
                szTriggerId: szFamilyId, 
                szFamilyId: szFamilyId, 
                bDirectlyRunProcessQueryAfterInitialization: false
            });

            qpTmp.setDoc_viaTransaction(tsa, szUserId, {
                added_by: szUserId,
                modified_by: szUserId, 
                ts_added: Timestamp.now(), 
                ts_modified: Timestamp.now()                                       
            });

            // add the new family-reference
            qpTmp = new qryProcessor_UserFamilyReferences({ 
                wfCtrl: g_wfCtrl, 
                szTriggerId: szUserId, 
                szUserId: szUserId, 
                bDirectlyRunProcessQueryAfterInitialization: false 
            });
            qpTmp.setDoc_viaTransaction(tsa, szFamilyId, {
                added_by: szUserId,
                modified_by: szUserId, 
                ts_added: Timestamp.now(), 
                ts_modified: Timestamp.now()
            });                    

            // start batched writes
            tsa.commit().then(
                function tsaSuccess(t) { 
                    console.log("TRANSACTION SUCCESS"); 
                    g_wfCtrl.setSelectedId_Families();
                }.bind(this) )
            .catch(                                    
                function tsaFailed(error) { 
                    console.log("TRANSACTION FAILED", error); 
                }.bind(this) );
        }.bind(this);

        fncReject = function() {
            console.log("TRANSACTION FAILED")
        };

        datasetExtra[HtmlProcessor_UserInvitations.ID_TRG_ACCEPT] = { click: fncAccept } ;
        datasetExtra[HtmlProcessor_UserInvitations.ID_TRG_REJECT] = { click: fncReject } ;
    }
}
HtmlProcessor_UserInvitations.ID_DIV_SECTION           = "userinvitations-div-section"      ;
HtmlProcessor_UserInvitations.ID_DIV_ENTRY             = "userinvitations-div-entry"        ;
HtmlProcessor_UserInvitations.ID_IMG_MAIN              = "userinvitations-img-main"         ;
HtmlProcessor_UserInvitations.ID_TXT_NAME              = "userinvitations-txt-name"         ;
HtmlProcessor_UserInvitations.ID_TXT_NAME_FAMILY       = "userinvitations-txt-name-family"  ;
HtmlProcessor_UserInvitations.ID_TXT_GREETING          = "userinvitations-txt-greeting"     ;
HtmlProcessor_UserInvitations.ID_TXT_TIME              = "userinvitations-txt-time"         ;
HtmlProcessor_UserInvitations.ID_TRG_ACCEPT            = "userinvitations-trg-accept"       ;
HtmlProcessor_UserInvitations.ID_TRG_REJECT            = "userinvitations-trg-reject"       ;

import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcInfoLine_01 from './fabbi_crc_info_line_01.jsx';
import { Timestamp } from "firebase/firestore";

export default class CrcFamilies_Entry_01 extends CrcHtmlProcessorEntry {

    componentDidMount() {
        super.componentDidMount();
        
        if (this.state.new) {
            // if (this.el.getElementsByClassName("__sub")[0].style.animationIterationCount > 0
            FabStdBro.addAnimation("dummyAnim", "0% { opacity: 100%;} 100% { opacity: 0%;}");
            // this.el.getElementsByClassName("__sub")[0].style.animationTimingFunction = "linear";

            var htmlElem = this.getDOMElement();

                // animation-name               animationname
                // animation-duration           5s
                // animation-timing-function    linear
                // animation-delay              5s
                // animation-iteration-count    5
                // animation-direction:         normal/alternate
                // animation-fill-mode          forwards
                // animation-play-state         running
                htmlElem.style.animation = "dummyAnim" + " " + "0.5s" + " linear 0s 10 alternate forwards running";
        }        
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
        var dataMsgstat = datasetExtra["data-msgstat"];
        var dataLastMsg = datasetExtra["data-last_msg"];

        if (!dataMsgstat) dataMsgstat = {};
        if (!dataLastMsg) dataLastMsg = {};

        var szLstMsgAdded = FabStd.convertTimestampToLocaleDateTimeString(dataLastMsg.ts_added, "");

        // console.log(this.constructor.name, dataLastMsg.content);

        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }
        
        var fncClick = (e) => {
            if (this.isActiveEntry()) {
                g_wfCtrl.editFamily2(this.getIdEntry());
            } else {
                this.getWfCtrl().setSelectedId_Families(this.getIdEntry());
                this.getWfCtrl().refreshLastChecked_Messages(this.getIdEntry());
            }
        };

        // console.log(this.constructor.name, "renderEntry()", dataLastMsg.content);

        // style={{ backgroundColor: lightblue }}
        // style={{color:red}}
        return(
            <li id={this.getIdDOM()} 
                className={
                    (this.isAlwaysLinkedToActiveEntry()?"fab-list-entry-01-activeEntry":"fab-list-entry-01") + 
                    " animate fadeUp CSS_DELAY" + 
                    ((!this.isAlwaysLinkedToActiveEntry() && this.isActiveEntry())?" active":"")
                }  
                onClick={(e) => fncClick(e)}
                style={{
                        width: "inherit", 
                        display: "flex", 
                        flexDirection: "column", 
                        paddingLeft: "0rem",
                        paddingRight: "0rem",
                        paddingTop: "1rem",
                        paddingBottom: "1rem" }}
                >
                <div style={{width: "inherit", display: "flex", flexDirection: "column"}}>
                    <div style={{paddingLeft: "0.5rem", width: "inherit", display: "flex", flexDirection: "row" }}>
                        <div style={{width: "3.5rem", flexShrink: 0, flexGrow: 0}}>
                            <CrcImageOrInitials_01 wfCtrl={this.getWfCtrl()} image_src={dataset.image_src} long_name={dataset.name}/>
                        </div>
                        <div style={{flexGrow: 1, marginRight: "1rem"}}>
                            <p className={"blue-grey-text text-darken-4 font-weight-700"}>{dataset.name}</p>
                            <CrcInfoLine_01 className={"fab-info-text-fontstyle"} parent={this} infoContent={dataLastMsg.content}/>
                        </div>            
                    </div>
                </div>

                <div style={{ 
                        display: "flex", 
                        paddingRight: "1rem",
                        flexDirection: "row", 
                        flexWrap: "nowrap", 
                        justifyContent: "right", 
                        alignItems: "flex-end"
                        }}>
                    <span className={"fab-time-01"}>{szLstMsgAdded}</span>
                    <span className={"new badge blue"} data-badge-caption="">{dataMsgstat.count_new}</span>
                </div>

            </li>
        );
    }
}
import React from 'react';
import Crc_SC_Base from "./fabbi_crc_sc_base.jsx";
import {getVisibleSelectionRect} from "draft-js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/core/Autocomplete';
import DatePicker from '@material-ui/lab/DatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {matchSorter} from 'match-sorter';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

class OptionContent {
    constructor(initProps) {
        this.label = "";
        Object.assign(this, initProps); // copy all props to this object
    }

    getLabel() {
        return(this.label)
    }

    getDescription() {
        return(this.description);
    }

    compare(optVs) {
        if (!optVs.getLabel) return(false);
        return(this.getLabel()?.toUpperCase() === optVs.getLabel()?.toUpperCase());
    }
}

export default class Crc_SC_AutocompleteSelect_01 extends Crc_SC_Base {
    constructor(props) {
        super(props);
          
        this.icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        this.checkedIcon = <CheckBoxIcon fontSize="small" />;
        this._timerEnter = undefined;

        this._refAutocomplete = React.createRef();
        this._refTextfield = React.createRef();
        this._refListbox = React.createRef();

        this.arrResult_OptionsSelected = null;

        this.state.inputPlaceholderAndLabel_base = "";
        this.state.valTextfield = this.props.valTextfield;

        // props.onSet
        // props.onEnter 
        this.state.showButton_Set = !!props.showButton_Set;
        this.state.open = props.open?props.open:false;

        this.state.arrOptionsSelectable = (props.arrOptionsSelectable?props.arrOptionsSelectable:[]).map((elem) => new OptionContent(elem)); // [ {label: "das erste", index: 1}];
        this.state.arrOptionsSelected = (props.arrOptionsSelected?props.arrOptionsSelected:[]).map((elem) => new OptionContent(elem));
        this.state.idxOptionKeyboardFocused = -1;

        console.log(this.constructor.name, "init", "this", this, "props", this.props);
        
        /*
        this.state.filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: option => option.title
        });
        */
    }

    /** @override */
    getResult() {
        var result = {
            arrOptionsSelected: this.arrResult_OptionsSelected,
            valTextfield:       this.state.valTextfield
        }
        console.log(this.constructor.name, "getResult()", result);
        return(result);
    }

    componentDidMount() {
        super.componentDidMount();

        // this._refTextfield?.current?.focus(); // focus on
    }

    renderGroup(params) {
        return(
            params.children
        );
    }

    isMultiple() {
        return(!!this.props.multiple);
    }

    getInputPlaceholderAndLabel() {
        if (this.isMultiple() && this.arrResult_OptionsSelected?.length > 0) {
            return(this.state.inputPlaceholderAndLabel_base + " (" + this.arrResult_OptionsSelected.length + ")");
        } else {
            return(this.state.inputPlaceholderAndLabel_base);
        }
    }

    groupBy(option) {
        return(option.label);
    }

    render() {
        return(this.renderAutocompleteWithOptions_01());
    }

    _sortMatchOptions_01(valToMatch, arrOptionsSelectable) {
        if (!arrOptionsSelectable) arrOptionsSelectable = this.state.arrOptionsSelectable;

        var arrOptionsToDisplay; 
        arrOptionsToDisplay = matchSorter(arrOptionsSelectable, valToMatch, {
            keys: ['label', 'description'],
            threshold: matchSorter.rankings.NO_MATCH
        }).slice(0, 100);

        arrOptionsToDisplay.forEach((opt, idxSort) => {
            opt.idxSort = idxSort;
        });

        // console.log(this.constructor.name, "_sortMatchOptions_01", arrOptionsToDisplay);
        return(arrOptionsToDisplay);
    }

    getInputId_01() {
        return(this.getIdDOM() + "_if1" );
    }

    getOptionId_01(idx) {
        return(this.getInputId_01() + "-option-" + idx );
    }

    filterOptions_01(arrOptionsSelectable, param) {
        // param -members are 
        // 1.  inputValue       (the value of the textfield)
        // 2.  getOptionLabel   (the function to get the LABEL of an option, usually used to determine whether selected or not)
        // if (options.length <= 5) return(options);

        // console.log("optionsInputValue", options, param); // inputValue); 
        var nCountOld = this._arrOptionsDisplayed?.length;
        var bReset = false;
        var arrOld = this._arrOptionsDisplayed; 
        
        if (!arrOld || arrOld.length <= 0) bReset = true;

        this._arrOptionsDisplayed = this._sortMatchOptions_01(param.inputValue, arrOptionsSelectable);
        
        if (this.state.idxOptionKeyboardFocused < 0 || this.state.idxOptionKeyboardFocused >= this._arrOptionsDisplayed.length) {
            bReset = true;
        }
        
        if (!bReset && nCountOld !== this._arrOptionsDisplayed.length) bReset = true;
        if (!bReset) {
            for (var i=0; i <= this.state.idxOptionKeyboardFocused; ++i) {
                if (arrOld[i].compare(this._arrOptionsDisplayed[i]) !== true) { 
                    bReset = true;
                }
            }
        }

        if (bReset) this.state.idxOptionKeyboardFocused = -1;
        return(this._arrOptionsDisplayed);
    }

    renderAutocompleteWithOptions_01() {
        return( 
            <span id={this.getIdDOM()} key={this.getIdDOM()}>
                <Autocomplete
                    disableClearable={true}
                    forcePopupIcon={false}
                    popupIcon={undefined}
                    closeIcon={undefined}
                    style={this.props.style}
                    autoComplete={!this.isMultiple()}
                    // autoHighlight={false}
                    // autoSelect={false}
                    ref={this._refAutocomplete}
                    ListboxProps={{ref: this._refListbox }}
                    filterSelectedOptions={false}
                    multiple={this.isMultiple()}
                    disableListWrap
                    clearOnEscape={true}
                    disableCloseOnSelect={!!this.isMultiple()}
                    limitTags={5}
                    onPaste={(event) => {
                        console.log(this.constructor.name, "onPaste()", "autocomplete", event);
                    }}
                    key={ this.getInputId_01() }
                    id={  this.getInputId_01() }
                    filterOptions={ (arrOptionsSelectable, param) => this.filterOptions_01(arrOptionsSelectable, param) }
                    options={ this.state.arrOptionsSelectable }
                    getOptionLabel={ (option) => { return((option && option.getLabel)?option.getLabel():""); } }
                    // getOptionSelected={ (opt1, opt2) => opt1.compare(opt2) }
                    isOptionEqualToValue={ (opt1, opt2) => opt1.compare(opt2) }
                    renderGroup={(params) => this.renderGroup(params) }
                    groupBy={(option) => this.groupBy(option) }
                    style={{ width: "auto" }}
                    inputValue={ this.state.valTextfield }
                    open={!!this.state.open}
                    value={ (this.isMultiple() || this.state.arrOptionsSelected.length === 0)?this.state.arrOptionsSelected:this.state.arrOptionsSelected[0] }
                    onClose={(event) => { 
                        console.log(this.constructor.name, "close ordered", "event", event); 
                        if (event && event.type === "mousedown") return;

                        this.setState({ open: false }); 
                        setTimeout(() => { this.onCancel( { reason: event.code } ) });
                    } }
                    onOpen={(event) => { console.log(this.constructor.name, "onOpen", event); this.setState({ open: true }); }}
                    onChange={(event, arrOptionsSelected, reason) => this.onChangeSelectedOptions_01(event, arrOptionsSelected, reason) }
                    renderTags={((value, getTagProps) => this.renderTags_01(value, getTagProps)) }
                    renderInput={(params) => this.renderInput_01(params) }
                    renderOption={(option, info) => this.renderOption_01(option, info) }               
                />
            </span>
        );  
    }

    onChangeSelectedOptions_01(event, arrOptionsSelected, reason) {
        // console.log(this.constructor.name, "onChangeSelectedOptions_01", "event", event, "selected", arrOptionsSelected, "reaseon", reason);
        // console.log(this.constructor.name, "onChangeSelectedOptions_01", "event.type", event?.type);
        // do we have a mouseclick ?
        if (event?.type === "click" && event?.currentTarget) {
            var idx = +event.currentTarget.getAttribute('data-option-index');
            // console.log(this.constructor.name, "onChangeSelectedOptions_01", "idx", idx);
            if (idx >= 0) this.setKeyboardFocus_01(idx);
        }

        this._setOptionsSelected_01(arrOptionsSelected);
    }

    _setOptionsSelected_01(arrOptionsSelected) {
        // console.debug(this.constructor.name, new Date(), "_setOptionsSelected_01", "arrOptionsSelected", "act", arrOptionsSelected, "prev", this.arrResult_OptionsSelected);
        if (!arrOptionsSelected) { 
            // this.arrResult_OptionsSelected = null;
        } else {
            if (arrOptionsSelected.map) this.arrResult_OptionsSelected = arrOptionsSelected; else this.arrResult_OptionsSelected = [ arrOptionsSelected ]; 
        
            var objToSet = {
                arrOptionsSelected: arrOptionsSelected
            };
            if (!this.isMultiple()) objToSet.valTextfield = arrOptionsSelected[0]?.getLabel();

            // console.debug(this.constructor.name, new Date(), "_setOptionsSelected_01", "objToSet", objToSet);
            this.setState(objToSet);

            if (!this.isMultiple()) {
                // console.log(this.constructor.name, "onSet", "clicked arrOptionsSelected (non-multiple)");
                setTimeout(() => { this.onSet() });
            }
        }
    }

    renderTags_01(value, getTagProps) {
        // console.log(this.constructor.name, "renderTags_01()", "value", value, "getTagProps", getTagProps);
        return(<React.Fragment></React.Fragment>);
    }

    renderOption_01(option, componentState) {
        // console.debug("renderOption_01", new Date(), "option", option, "option[aria-selected]", option["aria-selected"], "componentState", componentState);
        // console.debug(this.constructor.name, "renderOption_01", componentState.idxSort);

        return(
            <React.Fragment>
                <div key={componentState.idxSort}>
                    {componentState.idxSort===this.state.idxOptionKeyboardFocused?"(*)":""}
                    <Checkbox
                        size="small"
                        icon={this.icon}
                        checkedIcon={this.checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={ option["aria-selected"] }
                        name={ this.getIdDOM() }
                        onClick={(event) => {
                            this.toggleOption_01(componentState.idxSort);
                        }}
                        // onClick={ (event) => { console.log("CLICKEDOPTION", option); option.onClick(event); } }
                    />
                    {
                        componentState.idxSort===this.state.idxOptionKeyboardFocused?
                            ( 
                                <span id={this.getIdDOM_KeyboardFocus_01()}>
                                { 
                                    componentState.getLabel?componentState.getLabel():"ERROR"
                                }
                                </span>
                            )
                            :
                            (
                                componentState.getLabel?componentState.getLabel():"ERROR"
                            )
                    }
                    {
                        componentState.getDescription()?(" (" + componentState.getDescription() + ")"):""
                    }
                </div>
            </React.Fragment>
        );
    }

    getIdDOM_KeyboardFocus_01() {
        return(this.getIdDOM() + "_keyboardFocus");
    }

    moveKeyboardFocus_01(nSteps) {
        var newPos = this.state.idxOptionKeyboardFocused+nSteps;
        return(this.setKeyboardFocus_01(newPos));
    }

    setKeyboardFocus_01(newPos) {    
        // console.log(this.constructor.name, "setKeyboardFocus()", "newPos (prev.)", newPos, "max", this._arrOptionsDisplayed);
        if (newPos > -1 && this._arrOptionsDisplayed) {
            if (newPos >= this._arrOptionsDisplayed.length) { 
                newPos = this._arrOptionsDisplayed.length - 1;
            } 
        } else {
            newPos = -1;
        }

        // console.log(this.constructor.name, "setKeyboardFocus()", "newPos", newPos);
        this.setState({ idxOptionKeyboardFocused: newPos });
        setTimeout(() => {
            var domElem = document.getElementById(this.getIdDOM_KeyboardFocus_01());
            // console.log("visibilityCheck", "domElem", domElem);
            // if (domElem) console.log("isElementInView", FabStd.isElementInView(domElem));
            // if (domElem && !FabStd.isElementInView(domElem)) {
                // var listboxNode = this._refListbox.current.parentElement.querySelector('[role="listbox"]');
                // console.log("listboxNode", listboxNode);
            if (domElem)
                domElem.scrollIntoView({ scrollMode: 'if-needed', block: 'nearest', behavior: "smooth" });
            // }
        }, 0);
    }

    renderInput_01(params) {
        return(
            <TextField 
                key={"input_01"}

                autoFocus={this.props.autoFocus}
                inputRef={this._refTextfield}

                // style={{height: "1em"}} 
                {...params } 

                onPaste={(event) => {
                    console.log(this.constructor.name, "onPaste()", "textfield", event, event.clipboardData.getData('text/plain' /* 'text/html' */));

                    var pastedText = event.clipboardData.getData('text/plain');
                    
                    if (!pastedText && pastedText.length) {
                        return;
                    }

                    // Parse the pasted text from Excel into rows.
                    // Pasted text is usually separated by a new line for each row,
                    // but a single cell can contain multiple lines, which is what
                    // we pars out in the first `replace`.
                    //
                    // We find all text within double-quotes ('"') which has new
                    // lines, and put the text within the quotes into capture
                    // groups. For each match, we replace its contents again, by
                    // removing the new lines with spaces.
                    //
                    // Then lastly, once we've joined all the multi line cells, we
                    // split the entire pasted content on new lines, which gives
                    // us an array of each row.
                    //
                    // Since Windows usually uses weird line-endings, we need to
                    // ensure we check for each of the different possible
                    // line-endings in every regexp.
                    //
                    // It also handles cells which contains quotes. There appears
                    // to be two ways this is handled. In Google Docs, quotes within
                    // cells are always doubled up when pasting, so " becomes "".
                    // In Libre Office, the quotes are not normal quotes, some
                    // other character is used, so we don't need to handle it any
                    // differently.
                    var rows = pastedText.replace(/"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg, function (match, p1) {
                        // This function runs for each cell with multi lined text.
                        return p1
                            // Replace any double double-quotes with a single
                            // double-quote
                            .replace(/""/g, '"')
                            // Replace all new lines with spaces.
                            .replace(/\r\n|\n\r|\n|\r/g, ' ')
                        ;
                    })
                    // Split each line into rows
                    .split(/\r\n|\n\r|\n|\r/g);
                
                    console.log(this.constructor.name, "onPaste()", "rows", rows);
                }}

                InputProps={{
                    ...params.InputProps,
                    // startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                    endAdornment:
                        (!this.state.showButton_Set)?undefined:  
                            (<InputAdornment position="end">
                                <IconButton onClick={() => this.onSet() }><CheckCircleOutlineIcon aria-label="ok"/></IconButton>
                            </InputAdornment>)
                }}

                label={this.getInputPlaceholderAndLabel()}
                // inputProps={{ ...params.inputProps, style: { height: "auto" }}}
                onKeyDown={(e) => {
                    console.log(this.constructor.name, "onKeyDown", "renderInput_01()", e.key); // , this._refListbox, "autocomplete-highlighted"); // , this._refAutocomplete?.current?.highlightedIndexRef);
                    // console.log(this.constructor.name, "onKeyDown", "renderInput_01()", "test", this._test);

                    if (e.key === "Escape") {
                        if (!this.state.open) {
                            console.log(this.constructor.name, "simulateClickAway", "reason", "ESCAPE");
                            this.onCancel({ reason: "Escape" });
                        } else {
                            // collapse open state on escape
                            this.setState({open: false});
                        }
                    }

                    if (this.isMultiple() && e.key === "Backspace" && this.state.valTextfield?.length <= 0) {
                        console.log(this.constructor.name, "onKeyDown", "renderInput_01()", "trying to prevent");
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }

                    if (e.key === "Tab") {
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }

                    if (e.key === "ArrowDown") {
                        this.moveKeyboardFocus_01(1);  // down
                    }
                    if (e.key === "ArrowUp") {
                        this.moveKeyboardFocus_01(-1); // up
                    }

                    if (e.key === "End") {
                        // useEventCallback()

                        console.log(this.constructor.name, "onKeyDown", "renderInput_01()", "trying to prevent");
                        e.preventDefault();
                        e.stopPropagation();
                        return;
                    }

                    if (e.key === "Enter") {
                        console.log(this.constructor.name, "Enter pressed", this.state.idxOptionKeyboardFocused, this.state.open);
                        if (this.state.idxOptionKeyboardFocused >= 0 && this.state.open) {
                            this.toggleOption_01(this.state.idxOptionKeyboardFocused);

                            e.preventDefault();
                            e.stopPropagation();
                            return;
                        }
                        
                        if (!this.isMultiple()) {
                            if (this.state.valTextfield?.length > 0) {
                                var options = this._sortMatchOptions_01(this.state.valTextfield);
                                if (options && options.length > 0 && !this.isMultiple()) {
                                    console.log(this.constructor.name, "ENTER", options, options[0], this.state.valTextfield);
                                    if (options[0].label.startsWith(this.state.valTextfield)) {
                                        this._setOptionsSelected_01([ options[0] ]);
                                        return; // and leave this function at this point!
                                    }
                                }
                            }    
                        }
                    }
                }} 
                onChange={(event) => {
                    // console.log(this.constructor.name, "TEXTFIELD", "onChange()", "value", event.target.value, "event", event);
                    this.setState({valTextfield: event.target.value });
                }} 
                variant="outlined"
                // helperText="TEST HELP" 
                />
        );
    }

    toggleOption_01(idxOption) {   
        var selectedOpt = this._arrOptionsDisplayed[idxOption];
        var arrNew = [];
        var bAddSelectedOpt = false;

        if (this.isMultiple()) {
            // console.debug(this.constructor.name, "toggleOption_01()", new Date(), "selectedOpt", selectedOpt, this.state.arrOptionsSelected);

            var nLenOld = this.state.arrOptionsSelected.length; 
            var arrNew = this.state.arrOptionsSelected.filter((opt) => !opt.compare(selectedOpt) );
            if (nLenOld === arrNew.length) bAddSelectedOpt = true;
        } else {
            bAddSelectedOpt = true;
        }
        if (bAddSelectedOpt) arrNew.push(selectedOpt);
        this._setOptionsSelected_01(arrNew);
        this.setKeyboardFocus_01(idxOption);
        // console.log(this.constructor.name, "toggleOption_01()", "arrNew", arrNew);
    }
}
import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcCarousel_01_Csv from './fabbi_crc_carousel_01_csv.jsx';
import CrcInput_01 from "./fabbi_crc_input_01.jsx";

export default class CrcUsers_ModalInput_01 extends CrcHtmlProcessorEntry {
    constructor(props) {
        super(props);

        this._idUser = this.getHtmlProcessor().getActiveIdEntry();
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().derequestSpecialDataAction(
                this.getIdDOM()    // id_sda
                );
        }
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.getHtmlProcessor()) {
            this.getHtmlProcessor().requestSpecialDataAction(
                this.getIdDOM(),    // id_sda
                this.getIdEntry(),  // id_entry
                () => this.update()  // force a re-rendering
            );
        }
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");

        if (dataset.ts_Added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }

        // var bActive = (this.getWfCtrl().getSelectedId_Users() === this.getIdEntry());

        var id_dom_carousel = this.getIdDOM() + "_cl";
        var id_dom_input_name = this.getIdDOM() + "_input_name";

        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <div className={"fab-headline"}>
                        <span>User-Profile</span>
                        <span className={"fab-dragdestination"}>
                            <i className={"material-icons trash-bin"} id="trash-bin">delete</i>
                        </span>            
                    </div>        
                    <p/>             
                    <div className={"row"}>
                        <CrcInput_01 
                            id_dom={id_dom_input_name} 
                            wfCtrl={this.getWfCtrl()} 
                            placeholder="Username" 
                            defaultValue={dataset.name}/>

                        <div className={"col s12"}>         
                            <label className={"btn waves-effect waves-light fabbi-file-upload"}>
                                <input type="file" accept="image/*" capture="user" id="users-new-image" 
                                    onChange={
                                        function(inp) {
                                            var car = this.getWfCtrl().getCRC(id_dom_carousel); 
                                            g_wfCtrl.processNewImage_ImgSrc2(
                                                inp.target.files, 
                                                car.getCsvImgSrc.bind(car), 
                                                () => {},
                                                this.getWfCtrl().m_bundleUsers.getBundleQP(this.getIdEntry(), "trigger"), 
                                                this.getIdEntry(), 
                                                "image_src")
                                        }.bind(this)
                                    }/>
                                Image upload
                            </label>&nbsp;
                        </div>
                        
                        <div id="modal1_sub" className={"modal submodal modal-content"}>    
                        </div>            
                    </div>

                    <div className="{row}">
                        <CrcCarousel_01_Csv 
                            wfCtrl={this.getWfCtrl()}
                            id_dom={id_dom_carousel} 
                            csv={dataset.image_src} 
                            style={{border: "ridge", width: "100%", maxWidth: "300px", height: "15vh"}}/>
                    </div>

                    <div className={"row"}>                                           
                        <div className={"modal-footer"}>
                            <a className={"btn waves-effect waves-light send modal-close"} id="users-btn-save" 
                                onClick={
                                    (event) => { 
                                        console.log(this.constructor.name, id_dom_input_name, document.getElementById(id_dom_input_name));
                                        var obj = {};
                                        FabStd.addProp(obj, "name", this.getWfCtrl().getCRC(id_dom_input_name).getDbValue());   
                                        FabStd.addProp(obj, "image_src", this.getWfCtrl().getCRC(id_dom_carousel).getDbValue()); 
                
                                        this.getWfCtrl().m_bundleUsers.getBundleQP(this.getIdEntry(), "trigger").updateDoc(this.getIdEntry(), obj);
                                        
                                        console.log(this.constructor.name, "this", this, "this.close", this.close);

                                        // close modal window ... which is the parent of this window
                                        this.getParent().close();
                                    }
                                }>Save</a>
                        </div>  
                    </div>                
                    <p/>                            
                </div> 

            </form>
        );
    }
}
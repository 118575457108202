import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import PerfectScrollbar from "./perfect-scrollbar.min.js";
// import React from 'react';

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cname, szContent) {
    document.cookie = cname + "=" + szContent + ";path=/";
}

function getEffectiveUrlViaCSS(szRelevantCssFileTitle, szCssClassName) {
    let cssRules;
    for (var i=0; i < document.styleSheets.length; ++i) {        
        if (document.styleSheets[i].href && document.styleSheets[i].href.indexOf(szRelevantCssFileTitle) >= 0) {            
            cssRules = document.styleSheets[i].rules;
            // console.log("fabbi_styles", cssRules, cssRules.length);
            for (var n = 0; n < cssRules.length; ++n) {                
                var nam = cssRules[n];           
                if (nam.selectorText === "." + szCssClassName) {
                    // console.log("gef", nam);
                    var szContent = nam.style.content;
                    // console.log("content", szContent);
                    var patt = /(").*(")|(').*(')/g; // "regular Expression" 1
                    var arrHits = szContent.match(patt);
                    szContent = arrHits[0];
                    szContent = szContent.slice(1, szContent.length-1); // szContent.replace(/(")/g, '');
                    // console.log("GEFUNDEN", szContent);
                    return(szContent);
                }
            }
            break;
        }
    }   
    return(undefined);     
}

function getPropertyName_Transform() {
    // Setup cross browser string
    var xform = 'transform';
    ['Webkit', 'Moz', 'O', 'ms'].every((prefix) => {
        var e = prefix + 'Transform';
        if (typeof document.body.style[e] !== 'undefined') {
            xform = e;
            return false;
        }
        return true;
    });
    return(xform);
}

function isDeepChildNodeOf(htmlElementToCheck, parentHtmlElement) {
    do {
        if (htmlElementToCheck === parentHtmlElement) return(true);
    } while((htmlElementToCheck = htmlElementToCheck.parentNode));
    return(false);
}

function cnvRemToPx(rem) {
    if (!window.__factorRemToPx) {
        window.__factorRemToPx = 
            parseInt(window.getComputedStyle(document.documentElement)['font-size']);
    }

    // console.log("cnv", window.__factorRemToPx);
    return(window.__factorRemToPx * rem);
}

function getZIndex(htmlElement) {
    if (!htmlElement) return(0);
    if (htmlElement instanceof Element) {
        var z = window.getComputedStyle(htmlElement).getPropertyValue('z-index');
        if (isNaN(z)) return getZIndex(htmlElement.parentNode);
        return(z);
    } else {
        return(0);
    }
}

function freeAnimation(name) {
    if (!!window.__dynamicStyles) {
        var result = window.__dynamicStylesMap.get(name);
        if (!!result) {
            window.__dynamicStylesFreeIndices.push(result);
            window.__dynamicStylesMap.delete(name);
        }
    }
}

function addAnimation(name, body) {
    if (!window.__dynamicStyles) {
        window.__dynamicStyles = document.createElement('style');
        window.__dynamicStyles.type = 'text/css';
        document.head.appendChild(window.__dynamicStyles);
    
        window.__dynamicStylesMap = new StdMap();
        window.__dynamicStylesFreeIndices = []; 
    }

    var nDstInd = window.__dynamicStyles.length;        
    freeAnimation(name);
    if (window.__dynamicStylesFreeIndices.length > 0) {
        nDstInd = window.__dynamicStylesFreeIndices.pop();
        window.__dynamicStyles.sheet.deleteRule(nDstInd);
    }
    window.__dynamicStylesMap.set(name, nDstInd);

    var szKF = "@keyframes " + name + " { " + body + "}";
    // console.log("keyframe", szKF);
    window.__dynamicStyles.sheet.insertRule(szKF, window.__dynamicStyles.length);
}

/*
addAnimation('myAnimation', `
  0% { transform: rotate(0); }
  20% { transform: rotate(${ 360 * Math.random() }deg); }
  60% { transform: rotate(${ -360 * Math.random() }deg); }
  90% { transform: rotate(${ 360 * Math.random() }deg); }
  100% { transform: rotate(${ 0 }deg); }
`);
*/

function forceReflow(htmlElement) {
    // the read-access to "offsetHeight" triggers the "reflow" => browser processes changes to HtmlCode/Styles
    if (!htmlElement) {
        document.body.offsetHeight;
    } else {
        htmlElement.offsetHeight;
    }
}

function reactOnInputWithDelay(event_or_inputHtmlElement, fncExecutor_onInput) {

    console.log(reactOnInputWithDelay);

    var target = event_or_inputHtmlElement;        
    var bAddListener = false;
    var nTimeout_msec = 500;

    if (!!event_or_inputHtmlElement.target) {
        target = event_or_inputHtmlElement.target;
    } else {
        bAddListener = true;
    }

    var fncTimeout = (e) => {                         
            var data = {
                target: target,
                valLastSeen: target.value,
                fncExecutor_onInput: fncExecutor_onInput                
            };    
            setTimeout(function() {
                // no more changes seen ?
                if (data.target.value === data.valLastSeen) {
                    // console.log("reactOnInputWithDelay", "Executing");
                    // let's do our job!            
                    data.fncExecutor_onInput(e);
                } else {
                    // console.log("reactOnInputWithDelay", "job cancelled", target, data.valLastSeen);                    
                }
            }.bind(data), nTimeout_msec);
        };

    if (bAddListener) {
        console.log("reactOnInputWithDelay", "listener setup");
        target.addEventListener("input", fncTimeout, false);
    } else {
        console.log("reactOnInputWithDelay", "direct");
        fncTimeout(event_or_inputHtmlElement);
    }
}

/**
 * isInViewport
 * @param {HtmlElement} elem Element that shall be checked if in Viewport! 
 * @return true = yes, in Viewport, false = no, not in Viewport
 */
function isInViewport(elem) {
    // console.log("isInViewport");
    var bounding = elem.getBoundingClientRect();
    // console.log("isInViewport", bounding, window.innerHeight, document.documentElement.clientHeight, window.innerHeight | document.documentElement.clientHeight);

    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function isTouchDevice() {
    try {
        document.createEvent("TouchEvent");
        return true;
    } catch (e) {
        return false;
    }
}

function initMaterializeItems_Collapsibles() {
    // Init collapsible
    $(".collapsible").collapsible({
        accordion: true,
        onOpenStart: function() {
            // Removed open class first and add open at collapsible active
            $(".collapsible > li.open").removeClass("open");
            setTimeout(function() {
            $("#slide-out > li.active > a")
                .parent()
                .addClass("open");
            }, 10);
        }
    });

    // Add open class on init
    $("#slide-out > li.active > a")
        .parent()
        .addClass("open");

    // Open active menu for multi level
    if (
        $("li.active .collapsible-sub .collapsible").find("a.active").length > 0
    ) {
        $("li.active .collapsible-sub .collapsible")
            .find("a.active")
            .closest("div.collapsible-body")
            .show();
        $("li.active .collapsible-sub .collapsible")
            .find("a.active")
            .closest("div.collapsible-body")
            .closest("li")
            .addClass("active");
    }
}

function initMaterializeItems_Navigation() {
    // Auto Scroll menu to the active item
    var position;
    if (
        $(".sidenav-main li a.active")
            .parent("li.active")
            .parent("ul.collapsible-sub").length > 0
        ) {
        position = $(".sidenav-main li a.active")
                    .parent("li.active")
                    .parent("ul.collapsible-sub")
                    .position();
    } else {
        position = $(".sidenav-main li a.active")
                    .parent("li.active")
                    .position();
    }

    setTimeout(function() {
        if (position !== undefined) {
            $(".sidenav-main ul")
                .stop()
                .animate({ scrollTop: position.top - 300 }, 300);
        }
    }, 300);


    // Collapsible navigation menu
    $(".nav-collapsible .navbar-toggler").click(function() {

        console.log("navbar-toggler");

        // Toggle navigation expan and collapse on radio click
        if (
            $(".sidenav-main").hasClass("nav-expanded") && !$(".sidenav-main").hasClass("nav-lock")
        ) {
            $(".sidenav-main").toggleClass("nav-expanded");
            $("#main").toggleClass("main-full");
        } else {
            $("#main").toggleClass("main-full");
        }

        // Set navigation lock / unlock with radio icon
        if (
            $(this)
                .children()
                .text() == "radio_button_unchecked"
        ) {
            console.log("sidebar",  "1");
            $(this)
                .children()
                .text("radio_button_checked");
            $(".sidenav-main").addClass("nav-lock");
            $(".navbar .nav-collapsible").addClass("sideNav-lock");
        } else {
            console.log("sidebar",  "2");
            $(this)
                .children()
                .text("radio_button_unchecked");
            $(".sidenav-main").removeClass("nav-lock");
            $(".navbar .nav-collapsible").removeClass("sideNav-lock");
        }
    });

    // Expand navigation on mouseenter event
    $(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").mouseenter(
        function() {
            console.log("mouseenter");

            if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
                $(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
                    .addClass("nav-expanded")
                    .removeClass("nav-collapsed");

                $("#slide-out > li.close > a")
                    .parent()
                    .addClass("open")
                    .removeClass("close");

                setTimeout(
                    function() {
                        // Open only if collapsible have the children
                        if ($(".collapsible .open").children().length > 1) {
                            $(".collapsible").collapsible(
                                "open",
                                $(".collapsible .open").index()
                            );
                        }
                    }
                , 100);
            }
        }
    );

    // Collapse navigation on mouseleave event
    $(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").mouseleave(
        function() {
            console.log("mouseleave");

            if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
                var openLength = $(".collapsible .open").children().length;
                
                $(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
                    .addClass("nav-collapsed")
                    .removeClass("nav-expanded");

                $("#slide-out > li.open > a")
                    .parent()
                    .addClass("close")
                    .removeClass("open");

                setTimeout(
                    function() {
                        // Open only if collapsible have the children
                        if (openLength > 1) {
                            $(".collapsible").collapsible(
                                "close",
                                $(".collapsible .close").index()
                            );
                        }
                    }
                , 100);
            }
        }
    );

    // other sidebars

    //Main Left Sidebar Menu // sidebar-collapse
    $(".sidenav").sidenav({
        edge: "left" // Choose the horizontal origin
    });

    //Main Right Sidebar
    $(".slide-out-right-sidenav").sidenav({
        edge: "right"
    });

    //Main Right Sidebar Chat
    $(".slide-out-right-sidenav-chat").sidenav({
        edge: "right"
    });

}

function initMaterializeItems_Search() {
    // Search class for focus
    $(".header-search-input")
        .focus(
            function() {
                $(this)
                .parent("div")
                .addClass("header-search-wrapper-focus");
            })
        .blur(
            function() {
                $(this)
                .parent("div")
                .removeClass("header-search-wrapper-focus");
        });

    //Search box form small screen
    $(".search-button").click(
        function(e) {
            if ($(".search-sm").is(":hidden")) {
                $(".search-sm").show();
                $(".search-box-sm").focus();
            } else {
                $(".search-sm").hide();
                $(".search-box-sm").val("");
            }
        });

    $(".search-sm-close").click(
        function(e) {
            $(".search-sm").hide();
            $(".search-box-sm").val("");
        });
}

function initMaterializeItems_Breadcrumbs() {
    //Breadcrumbs with image
    if ($("#breadcrumbs-wrapper").attr("data-image")) {
        var imageUrl = $("#breadcrumbs-wrapper").attr("data-image");
        $("#breadcrumbs-wrapper").addClass("breadcrumbs-bg-image");
        $("#breadcrumbs-wrapper").css("background-image", "url(" + imageUrl + ")");
    }
}

function initMaterializeItems_Checks() {
    // Check first if any of the task is checked
    $("#task-card input:checkbox").each(
        function() {
            checkbox_check(this);
        }
    );

    // Task check box
    $("#task-card input:checkbox").change(
        function() {
            checkbox_check(this);
        }
    );
}

// Check Uncheck function
function checkbox_check(el) {
    if (!$(el).is(":checked")) {
        $(el)
            .next()
            .css("text-decoration", "none"); // or addClass
    } else {
        $(el)
            .next()
            .css("text-decoration", "line-through"); //or addClass
    }
  }

function initMaterializeItems_Tabs() {
    //Init tabs
    $(".tabs").tabs();

    /*
    $("#tabs-swipe-demo").tabs({
        swipeable: true
      });
    */
}

function initMaterializeItems_FormSelects() {
    $("select").formSelect();
}

/*
  // Set checkbox on forms.html to indeterminate
  var indeterminateCheckbox = document.getElementById("indeterminate-checkbox");
  if (indeterminateCheckbox !== null)
    indeterminateCheckbox.indeterminate = true;
*/

function initMaterializeItems_Sliders() {
    // Materialize Slider
    $(".slider").slider({
        full_width: true
    });
}

function initMaterializeItems_DropDowns() {
    // Commom, Translation & Horizontal Dropdown
    $(".dropdown-trigger").dropdown();

    // Commom, Translation
    $(".dropdown-button").dropdown({
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        hover: true,
        gutter: 0,
        coverTrigger: true,
        alignment: "left"
        // stopPropagation: false
    });
}

function initMaterializeItems_Notifications() {
    // Notification, Profile, Translation, Settings Dropdown & Horizontal Dropdown
    $(
        ".notification-button, .profile-button, .translation-button, .dropdown-settings, .dropdown-menu"
    ).dropdown({
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        hover: false,
        gutter: 0,
        coverTrigger: false,
        alignment: "right"
        // stopPropagation: false
    });
}

function initMaterializeItems_FloatingActionButtons() {
    // Fab
    $(".fixed-action-btn").floatingActionButton();

    $(".fixed-action-btn.horizontal").floatingActionButton({
        direction: "left"
    });

    $(".fixed-action-btn.click-to-toggle").floatingActionButton({
        direction: "left",
        hoverEnabled: false
    });

    $(".fixed-action-btn.toolbar").floatingActionButton({
        toolbarEnabled: true
    });
}

function initMaterializeItems_ScrollSpy() {
    // Materialize scrollSpy
    $(".scrollspy").scrollSpy();
}

function initMaterializeItems_Tooltips() {
    // Materialize tooltip
    $(".tooltipped").tooltip({
        delay: 50
    });
}

function initMaterializeItems_PerfectScrollbars() {
    // Perfect Scrollbar
    $("select")
        .not(".disabled")
        .select();

    var leftnav = $(".page-topbar").height();
    var leftnavHeight = window.innerHeight - leftnav;
    var righttnav = $("#slide-out-right").height();

    if ($("#slide-out.leftside-navigation").length > 0) {
        if (!$("#slide-out.leftside-navigation").hasClass("native-scroll")) {
            var ps_leftside_nav = 
                new PerfectScrollbar(
                    ".leftside-navigation", {
                        wheelSpeed: 2,
                        wheelPropagation: false,
                        minScrollbarLength: 20
                    }
                );
        }
    }
    if ($(".slide-out-right-body").length > 0) {
        var ps_slideout_right = 
            new PerfectScrollbar(
                ".slide-out-right-body, .chat-body .collection", {
                    suppressScrollX: true
                }
            );
    }

    if ($(".chat-body .collection").length > 0) {
        var ps_slideout_chat = new PerfectScrollbar(".chat-body .collection", {
            suppressScrollX: true
        });
    }

    // Char scroll till bottom of the char content area
    var chatScrollAuto = $(
        "#right-sidebar-nav #slide-out-chat .chat-body .collection"
    );
    if (chatScrollAuto.length > 0) {
        chatScrollAuto[0].scrollTop = chatScrollAuto[0].scrollHeight;
    }
}

// Fullscreen
function toggleFullScreen() {
    if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen) ) {
        if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
        } else 
            if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else 
                if (document.documentElement.webkitRequestFullScreen) {
                    document.documentElement.webkitRequestFullScreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                } else 
                    if (document.documentElement.msRequestFullscreen) {
                        if (document.msFullscreenElement) {
                            document.msExitFullscreen();
                        } else {
                            document.documentElement.msRequestFullscreen();
                        }
                    }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else 
                if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else 
                    if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    }
        }
  }

function initMaterializeItems_ToggleFullscreen() {
    $(".toggle-fullscreen").click(function() {
        toggleFullScreen();
    });
}

function initMaterializeItems_Mobile() {
    // Detect touch screen and enable scrollbar if necessary
    if (isTouchDevice()) {
        $("#nav-mobile").css({
            overflow: "auto"
        });
    }
}

function initMaterializeItems_Resizetable() {
    resizetable();

    $(window).on("resize", function() {
        resizetable();
    });
}

function resizetable() {
    if ($(window).width() < 976) {
        if ($(".vertical-layout.vertical-gradient-menu .sidenav-dark .brand-logo").length > 0) {
            $(".vertical-layout.vertical-gradient-menu .sidenav-dark .brand-logo img")
                .attr(
                    "src",
                    "../../../app-assets/images/logo/materialize-logo-color.png"
                );
        }

        if ($(".vertical-layout.vertical-dark-menu .sidenav-dark .brand-logo").length > 0) {
            $( ".vertical-layout.vertical-dark-menu .sidenav-dark .brand-logo img")
                .attr(
                    "src",
                    "../../../app-assets/images/logo/materialize-logo-color.png"
                );
        }

        if ($(".vertical-layout.vertical-modern-menu .sidenav-light .brand-logo").length > 0) {
            $(".vertical-layout.vertical-modern-menu .sidenav-light .brand-logo img")
                .attr(
                    "src", 
                    "../../../app-assets/images/logo/materialize-logo.png"
                );
        }
    } else {
        if ($(".vertical-layout.vertical-gradient-menu .sidenav-dark .brand-logo").length > 0) {
            $(".vertical-layout.vertical-gradient-menu .sidenav-dark .brand-logo img")
                .attr(
                    "src", 
                    "../../../app-assets/images/logo/materialize-logo.png"
                );
        }
        if ($(".vertical-layout.vertical-dark-menu .sidenav-dark .brand-logo").length > 0) {
            $(".vertical-layout.vertical-dark-menu .sidenav-dark .brand-logo img")
                .attr(
                    "src", 
                    "../../../app-assets/images/logo/materialize-logo.png"
                );
        }
        if ($(".vertical-layout.vertical-modern-menu .sidenav-light .brand-logo").length > 0) {
            $(".vertical-layout.vertical-modern-menu .sidenav-light .brand-logo img")
                .attr(
                    "src",
                    "../../../app-assets/images/logo/materialize-logo-color.png"
                );
        }
    }
}

/*
// Add message to chat
function slide_out_chat() {
    var message = $(".search").val();
    if (message != "") {
        var html =
            '<li class="collection-item display-flex avatar justify-content-end pl-5 pb-0" data-target="slide-out-chat"><div class="user-content speech-bubble-right">' +
            '<p class="medium-small">' +
        message +
            "</p>" +
            "</div></li>";

        $("#right-sidebar-nav #slide-out-chat .chat-body .collection").append(html);
        $(".search").val("");
        var charScroll = $(
            "#right-sidebar-nav #slide-out-chat .chat-body .collection"
        );
        if (charScroll.length > 0) {
            charScroll[0].scrollTop = charScroll[0].scrollHeight;
        }
    }  
}
*/


        /*
        console.log(this.constructor.name, "Tabs-Component", arrTmp);
        var arrMap = React.Children.map(arrTmp.props.children, (child) => { console.debug(this.constructor.name, "Tabs-Component", "child", child); return(child); });

        console.log(this.constructor.name, "Tabs-Component", "map", arrMap);

        var RecursiveWrapper = (reactElem) => {
            var wrappedChildren = React.Children.map(
                reactElem.props.children,
                child => {
                    // const type = child.type === 'p' ? 'span' : child.type;
                    var type = child.type;

                    // has props and children ?
                    if (child.props && child.props.children) {
                        console.debug(this.constructor.name, "Tab-Components", "no ending point", child)
                        return React.cloneElement(
                            {
                                ...child,
                                type // substitute original type
                            },
                            {
                                ...child.props,
                                // Wrap grandchildren too
                                children: RecursiveWrapper(child)
                            }
                        );
                    }

                    console.debug(this.constructor.name, "Tab-Components", "ending point", child);
                    return undefined;   // filter out!
                }
            );
            
            return (
                <React.Fragment>
                    {wrappedChildren}
                </React.Fragment>
            );
        }

        arrTmp = React.cloneElement(arrTmp, { ...arrTmp.props, children: RecursiveWrapper(arrTmp) });
        */

            /*
            var replaceChildWithChildren = (reactElem) => {
                var arrChildren = React.Children.map(
                    reactElem.props.children,
                    child => {
                        // const type = child.type === 'p' ? 'span' : child.type;
                        var type = child.type;
    
                        // has props and children ?
                        if (child.props && child.props.children) {
                            console.debug(this.constructor.name, "Tab-Components", "no ending point", child)
                            return React.cloneElement(
                                {
                                    ...child,
                                    type // substitute original type
                                },
                                {
                                    ...child.props,
                                    // Wrap grandchildren too
                                    children: replaceChildWithChildren(child)
                                }
                            );
                        } else {
                            console.debug(this.constructor.name, "Tab-Components", "ending point", child);
                            if (type === "insert_listcontent") {
                                return(arrTmp); // replace
                            } else {
                                return(child);  // do nothing!
                            }
                        }
                    }
                );
                
                return (
                    <React.Fragment>
                        {arrChildren}
                    </React.Fragment>
                );
            }
    
            arrTmp = React.cloneElement(this._reactElem_frame, { ...this._reactElem_frame.props, children: replaceChildWithChildren(this._reactElem_frame) });
            console.debug(this.constructor.name, "entry_list_frame", arrTmp);
            */

export {
    addAnimation,
    cnvRemToPx,
    forceReflow,
    freeAnimation,
    getCookie,
    getEffectiveUrlViaCSS,
    getPropertyName_Transform,
    getZIndex, 
    initMaterializeItems_Breadcrumbs,
    initMaterializeItems_Checks,
    initMaterializeItems_Collapsibles,
    initMaterializeItems_DropDowns,
    initMaterializeItems_FloatingActionButtons,
    initMaterializeItems_FormSelects,
    initMaterializeItems_Mobile,
    initMaterializeItems_Navigation,
    initMaterializeItems_Notifications,
    initMaterializeItems_PerfectScrollbars,
    initMaterializeItems_Resizetable,
    initMaterializeItems_ScrollSpy,
    initMaterializeItems_Search,
    initMaterializeItems_Sliders,
    initMaterializeItems_Tabs,
    initMaterializeItems_ToggleFullscreen,
    initMaterializeItems_Tooltips,
    isDeepChildNodeOf,
    isInViewport,
    isTouchDevice,
    reactOnInputWithDelay,
    setCookie
};

import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import EventTarget from "./fabbi_eventtarget.js";
import qryProcessor from "./fabbi_qry_processor.js";
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import Hilitor from "./fabbi_hilitor.js";
import ControlledReactComponentRegister from "./fabbi_controlled_react_component_register.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import StorageManager from "./fabbi_storagemanager.js";
import firebase_ctrl from "./fabbi_firebase_ctrl.js";
 
export default class workflowControl extends EventTarget {        
    constructor(dataPoolInstance, firebaseConfig) {
        super();

        console.log("workflowControl", "constructor", "url-location", window.location);

        // save the "start"-Values of the browser-app
        // we assume, that the "workflowControl" is initialized directly after
        // inital calling of the app's-url
        // *******************************************************************
        this.urlStart = window.location;
        this.urlStartParams = new URLSearchParams(window.location.search);
        // *******************************************************************

        // "private" member of the class
        this.m_bStartUpCompleted = false;
        
        this.m_localConfig = {
                authType: ""    
        };

        this.m_smapProblems = new StdMap();
        this.m_smDragData = new StdMap();
        this.m_smRegistry = new StdMap();

        this.m_regCRC = new ControlledReactComponentRegister("id_dom"); // Register of Controlled React Componentes
        this.m_smQPR = new StdMap(); // QueryProcessor-REGISTER!
        this.m_smCtrl = new StdMap();       
        this.m_dataPool = dataPoolInstance;     // MainDataPool with "EMPTY" Id
        this.m_smSupervision = new StdMap();
        this.m_smDoOnEvent = new StdMap();    // first-level-id: EVENT-Name, second-level: ID to execute, third-level: "func" => content: function to execute

        this.m_nStat_Count_qryProcessorsUnrun = 0;
        this.m_nStat_LastCount_qryProcessorsUnrun = -1; // just to mark that we don't have a real "LastCount"-value                
        
        let thisClass = this;
        this.addEventListener(workflowControl.EVENT, function(event) { 
            thisClass.handleWorkflowControlEvent(event);
        });
                
        // qryProcessor-Events
        this.addEventListener(qryProcessor.EVENT, function(event) { 
            thisClass.handleQryProcessorEvent(event);
        });

        this.m_Hilitor = new Hilitor();

        // a map for Objectprototypes-IDs (String) and it's usable object-prototype
        this._smapObjPrototypes = new StdMap(); 

        console.log(this.constructor.name, "Constructor");

        // authentication
        this.m_firebaseCtrl = new firebase_ctrl(firebaseConfig);     
        this.m_StoM = new StorageManager(this.m_firebaseCtrl);   // (File-)Storage-Manager
        // look at "startUp_prepareAuthentication()" ... there the listener for authentications is activate
    }

    // *************************************************************************************
    addObjectPrototype(szObjPrototypeId, objPrototype) {
        this._smapObjPrototypes.set(szObjPrototypeId, objPrototype);
    }
    getObjectPrototype(szObjPrototypeId) {
        return(this._smapObjPrototypes.set(szObjPrototypeId, objPrototype));
    }
    // *************************************************************************************

    getUrlToSignIn(email) {
        var urlToSignIn = this.urlStart.origin + "?" + "action=signin"; 
        if (email) urlToSignIn = urlToSignIn + "&email=" + email;
        return(urlToSignIn);
    }

    logDataPool() {
        this.m_dataPool.logContent();
    }

    deleteFromRegistry(szId) {
        this.m_smRegistry.delete(szId);
    }

    loadFromRegistry(szId) {
        return(this.m_smRegistry.get(szId));
    }

    saveToRegistry(szId, objData) {
        this.m_smRegistry.set(szId, objData);
    }

    getStorageManager() {
        return(this.m_StoM);
    }

    getFirestore() {
        return(this.m_firebaseCtrl.getFirestore());
    }
    
    isTouchDevice() {
        if (this.m_bIsTouchDevice === undefined) {
            this.m_bIsTouchDevice = FabStdBro.isTouchDevice();
        }
        return(this.m_bIsTouchDevice);
    }
    
    clearAllDragData() {
        this.m_smDragData.clear();
    }        
    
    setDragData(szKey, vNewContent) {
        this.m_smDragData.set(szKey, vNewContent);
    }
    
    getDragData(szKey) {
        return(this.m_smDragData.get(szKey, true, () => undefined ));
    }
    
    getQP(szQueryProcessorId) {
        return(this.m_smQPR.get(szQueryProcessorId));
    }
    
    planActionOnEvent(szId, szEventSubType, func_toExecute, func_check) {                        
        var main;
        main = this.m_smDoOnEvent.get(szEventSubType);
        if (!main) {                    
            main = new StdMap();
            this.m_smDoOnEvent.set(szEventSubType, main);          
        }

        var event;
        event = main.get(szId); 
        if (event === undefined) {
            event = 
                new CustomEvent(workflowControl.EVENT_ACTION, {
                    detail: {                    
                        szSubType:          szEventSubType,     // the "subType" determines, on which EVENT we want to react
                        szId:               szId,                    
                        sender:             this,
                        receiver:           this,                                        
                        func_check:         func_check,         // function true/false which tells if an execution is allowed (true) or not (false). If not ... it is suspended ...
                        func_toExecute:     func_toExecute                    
                    }
            });            
            main.set(szId, event);
        }
    }
        
    privReportEvent(szEventSubType) {
        this.checkEventAndExecutePlannedAction(szEventSubType);
    }
    
    checkEventAndExecutePlannedAction(szEventSubType) {                
        // CHECK and EXECUTE the 
        let main = this.m_smDoOnEvent.get(szEventSubType);
        var arrFunc = [];
        var arrKill = [];
        
        if (!!main) {
            var iter = main.keys();
            var result = iter.next();            
            var bOk = true;
            var sub;    
            var szId = "";
            while (!result.done) {                
                szId = result.value;
                sub = main.get(szId);
                
                if (!!sub.detail.func_check) {
                    bOk = sub.detail.func_check();
                }
                if (bOk) {
                    arrFunc.push(sub.detail.func_toExecute);
                    arrKill.push(szId);
                }
                result = iter.next();
            }
            
            arrKill.forEach(function(szTmpId) {
                main.delete(szTmpId);
            });
            if (main.length === 0) {
                this.m_smDoOnEvent.delete(szEventSubType);
            }
            
            arrFunc.forEach(function(func_toExecute) {
                func_toExecute();   // and ... execute!
            });
        }
    }
    
    onStartUpCompleted() {           
    }

    existProblems() {
        return(!this.m_smapProblems?0:this.m_smapProblems.size > 0);
    }

    // function to override. Is called if a change occurred and all listeners finished successfully and NO PROBLEMS WERE DETECTED!
    onAllRun() {
        console.log(this.constructor.name, "onAllRun", "and no problems there!");
    }
    
    onAllRun_ProblemManagement() {        
        // ****************************************************************************************
        // Problem management
        // ****************************************************************************************
        console.log(this.constructor.name, "onAllRun_ProblemManagement", "ProblemCount", this.m_smapProblems.length);        

        var nRetryMSec = 0;
        if (nRetryMSec == 0 && !this.isAllRun()) {
            console.log("onAllRun_ProblemManagement", "problem-solution suspended!  Processors unrun!");
            nRetryMSec = 100;
        }
        if (nRetryMSec == 0 && !!this._nCount_IsSolving && this._nCount_IsSolving > 0) {
            console.log("onAllRun_ProblemManagement", "problem-solution underway! suspend! Count: ", this._nCount_IsSolving);
            nRetryMSec = 100;
        } 

        if (nRetryMSec > 0) {
            if (!this._problemManagementTimer) {
                this._problemManagementTimer =  
                    setTimeout(function() {
                        this._problemManagementTimer = undefined;
                        this.onAllRun_ProblemManagement();                    
                    }.bind(this), nRetryMSec);
            }
            return;
        }

        // Go through problems ... and try to find solve them!
        var iter = this.m_smapProblems.keys();
        var result = iter.next();            
        while (!result.done) {   
            var szProblemId = result.value;
            // console.log(this.constructor.name, "onAllRun_ProblemManagement", "ProblemId", szProblemId, "Problem", this.m_smapProblems.get(szProblemId));

            var problem = this.m_smapProblems.get(szProblemId)            
            this.onTryToFixProblem(problem);

            result = iter.next();
        } 
        // ****************************************************************************************
    }

    onTryToFixProblem(problem) {        
    }

    getProblem(szProblemId) {
        return(this.m_smapProblems.get(szProblemId));
    }

    reportProblem(newProblem) {       
        if (!newProblem) return;
        if (!newProblem.problem_id) return;

        console.log(this.constructor.name, "reportProblem", "problem", newProblem);

        var p = this.m_smapProblems.get(newProblem.problem_id, false);
        if (!p) {                        
            // ******************************************************
            // EXTEND PROBLEM OBJECT
            // ******************************************************
            newProblem.bSolved = false;
            newProblem.bSolving = false;
    
            newProblem.isSolved = function() {
                return(this.bSolved);
            };
            newProblem.isSolving = function() {
                return(this.bSolving);
            };            
            newProblem.setSolved = function() {                
                this.bSolved = true;
                if (this.bSolving) {
                    this.bSolving = false;
                    this._changeCount_IsSolving(-1);
                } else {
                    this.bSolving = false;
                }
            };

            newProblem.getCount_IsSolving  = function(nChange) {
                if (!this._nCount_IsSolving) {
                    this._nCount_IsSolving = 0;
                }
                return(this._nCount_IsSolving);
            }.bind(this);

            newProblem._changeCount_IsSolving = function(nChange) {                                                                
                if (!this._nCount_IsSolving) this._nCount_IsSolving = 0;
                this._nCount_IsSolving = this._nCount_IsSolving + nChange;

                if (!!this._timerClearSolved) {
                    clearTimeout(this._timerClearSolved);
                    this._timerClearSolved = undefined;                    
                }

                if (this._nCount_IsSolving === 0) {
                    this._timerClearSolved = setTimeout(function () {
                        if (this._nCount_IsSolving === 0) {
                            console.log(this.constructor.name, "problem", "clear solved");
                            this.m_smapProblems.clear();

                            // provoke a new supervision-action!                            
                            this.enqueueWorkflowControlEvent(this, workflowControl.EVENT_subType_refreshSupervision);
                        }
                    }.bind(this), 100);
                } 

            }.bind(this);

            newProblem.setSolving = function() {
                this.bSolved = false;
                this.bSolving = true;
                this._changeCount_IsSolving(1);
            };
            // ******************************************************
            newProblem.datReportFirst = new Date(); // save Current DateTime
            this.m_smapProblems.set(newProblem.problem_id, newProblem);
        } else {
            newProblem = p;
        }

        newProblem.datReportLast = new Date(); // save Current DateTime
        newProblem.nCount = !newProblem.nCount?1:(newProblem.nCount+1);
    }

    logUnrun() {
        var iter = this.m_smQPR.keys();
        var result;
        var qp;
        while (!(result = iter.next()).done) {
            qp = this.m_smQPR.get(result.value);
            if (!qp.bRunCompleted) {
                console.log(this.constructor.name, "unrun is", qp.getId(), qp);
            }
        }
    }

    isAllRun() {
        return(
            this.m_nStat_Count_qryProcessorsUnrun === 0 // &&  this.m_bStat_SupervisionDirty
            );
    }

    _activityStatusSetDirty() {
        this.m_bStat_SupervisionDirty = true;
    }

    _activityStatusUpdate() {
        var iter = this.m_smSupervision.keys();
        var result = iter.next();
        var nCount_qryProcessorsUnrun = 0;
        
        while (!result.done) {
            var szKey = result.value;
            var szStatus = this.m_smSupervision.get(szKey).get("status_activity");

            if (szStatus !== qryProcessor.EVENT_subType_reportOnRunCompleted) {  
                // console.log(this.constructor.name, "refreshSupervisionAnalysis", "unrun", szKey, szStatus);
                ++nCount_qryProcessorsUnrun;
                this._activityStatusSetDirty();
            }
            result = iter.next();
        }
        
        this.m_nStat_LastCount_qryProcessorsUnrun = this.m_nStat_Count_qryProcessorsUnrun;
        this.m_nStat_Count_qryProcessorsUnrun = nCount_qryProcessorsUnrun;
    }
    
    refreshSupervisionAnalysis() {
        
        // console.log(this.constructor.name, "refreshSupervisionAnalysis", "this.m_nStat_Count_qryProcessorsUnrun", this.m_nStat_Count_qryProcessorsUnrun);
        this._activityStatusUpdate();

        if (this.isAllRun()) {                
            /*
            console.log(this.constructor.name, "refreshSupervisionAnalysis", 
                this.m_smSupervision.size - this.m_nStat_Count_qryProcessorsUnrun, 
                "of", 
                this.m_smSupervision.size, 
                "qryProcessors have run!");                
            */

            // take care, that the "StartUpCompleted"-Procedure is only run ONCE
            if (!this.m_bStartUpCompleted) {
                this.m_bStartUpCompleted = true;        
                
                this.enqueueWorkflowControlEvent(this, workflowControl.EVENT_subType_justFunction, function () {                          
                    this.onStartUpCompleted();
                }.bind(this));
            }           

            this.privReportEvent(workflowControl.EVENT_subType_allQueryProcessorsReportRunCompleted);
            this.enqueueWorkflowControlEvent(this, workflowControl.EVENT_subType_allQueryProcessorsReportRunCompleted);
        } else {
            /*
            console.log(this.constructor.name, "refreshSupervisionAnalysis",
                "just ", 
                this.m_smSupervision.size - this.m_nStat_Count_qryProcessorsUnrun, 
                "of", 
                this.m_smSupervision.size, 
                "qryProcessors have run!");                
            */
        }
    }
    
    handleQryProcessorEvent(event) {
        // console.log(this.constructor.name, "handleQryProcessorEvent", event);
        
        var bSetStatusActivity = false;
        var bDeleteSupervision = false;
        var bRefreshSupervision = false;

        var qryP;
        var szId_QP;
        if (event.detail.sender instanceof qryProcessor) {
            qryP = event.detail.sender;
            szId_QP = qryP.getId();
        }

        switch(event.detail.szSubType) {     
            case qryProcessor.EVENT_subType_reportStartUp:
                bSetStatusActivity = true;    
                // add the query-processor-data to the "QueryProcessorRegister"
                if (!!szId_QP) {
                    // adding queryProcessor to REGISTER
                    this.m_smQPR.set(szId_QP, qryP);
                }
                break;

            case qryProcessor.EVENT_subType_reportStartUpCompleted:
                bSetStatusActivity = true;

                // console.log(this.constructor.name, "handleQryProcessorEvent", "reportStartUpCompleted", event.detail.sender instanceof qryProcessor);
                // add the query-processor-data to the "QueryProcessorRegister"
                if (!!szId_QP) {
                    // adding queryProcessor to REGISTER
                    this.m_smQPR.set(szId_QP, qryP);
                }
                break;
            case workflowControl.EVENT_subType_refreshSupervision:                
                bRefreshSupervision = true;                    
                break;
            case qryProcessor.EVENT_subType_reportOnNewDataIncomingCompleted:
            case qryProcessor.EVENT_subType_reportOnNewDataProcessingCompleted:
            case qryProcessor.EVENT_subType_reportOnRunCompleted:
                bSetStatusActivity = true;
                bRefreshSupervision = true;
                break;
            case qryProcessor.EVENT_subType_reportCleanUpCompleted:
                if (!!szId_QP) {
                    this.m_smQPR.delete(szId_QP);
                    console.log(this.constructor.name, "handleQryProcessorEvent", "deleting qryP", szId_QP, " from QP-Registry");
                    bDeleteSupervision = true;
                }
                break;
        }       
                
        if (bSetStatusActivity) {
            if (!!qryP) {
                // set supervision-data ...
                var smData = this.m_smSupervision.get(szId_QP, true, () => new StdMap());                
                smData.set("status_activity", event.detail.szSubType);
            }
        } 
        if (bDeleteSupervision) {
            this.m_smSupervision.delete(szId_QP);
        }

        if (bRefreshSupervision) {
            // console.log(this.constructor.name, "handleQryProcessorEvent", event.detail.sender.getId(), event.detail.szSubType);            
            this.refreshSupervisionAnalysis();                        
        }                
        
        if (event.detail.func_toExecute != undefined) {
            // so, we execute the function!
            event.detail.func_toExecute();
        }
    }
    
    handleWorkflowControlEvent(event) {
        // console.log(this.constructor.name, "handleWorkflowControlEvent", event.detail.szSubType, event);
        if (event.detail.func_toExecute != undefined) {                        
            // so, we execute the function!
            event.detail.func_toExecute();            
        }

        switch(event.detail.szSubType) { 
            case workflowControl.EVENT_subType_allQueryProcessorsReportRunCompleted:
                
                if (this.existProblems()) {
                    this.onAllRun_ProblemManagement();
                } else {
                    this.onAllRun();
                }                
                break;
        }
    }
    
    enqueueWorkflowControlEvent(sender, szEventSubType, func_toExecute) {       
        // console.log(this.constructor.name, "enqueueWorkflowControlEvent", szEventSubType, func_toExecute);        
        var event = 
            new CustomEvent(workflowControl.EVENT, {
                detail: {
                    sender:     sender,
                    receiver:   this,
                    szSubType:  szEventSubType,
                    func_toExecute: func_toExecute
                }
            });
        this.dispatchEvent(event);
    }    
    
    getDataPool() {
        return(this.m_dataPool);
    }    

    // if not already triggered, triggers a the execution of the given funcWorkflowTrigger_IdData-function via message loops
    planNewDataIncoming(qryProcessor) {        
        let thisClass           = this.m_smCtrl;        
        let szWorkFlowItemId    = "planNewDataIncoming_" + qryProcessor.getId();
        
        if (!thisClass.has(szWorkFlowItemId)) {            
            thisClass.set(szWorkFlowItemId, qryProcessor);            
            setTimeout(function() {             
                let jP = thisClass.get(szWorkFlowItemId); 
                thisClass.delete(szWorkFlowItemId);                                            
                jP.privOnNewDataIncoming();
            }, 0);
        } 
    }        
    
    // if not already triggered, triggers a the execution of the given funcWorkflowTrigger_IdData-function via message loops
    planNewDataProcessing(qryProcessor) {        
        let thisClass           = this.m_smCtrl;        
        let szWorkFlowItemId    = "planNewDataProcessing_" + qryProcessor.getId();
        
        if (!thisClass.has(szWorkFlowItemId)) {            
            thisClass.set(szWorkFlowItemId, qryProcessor);            
            setTimeout(function() {             
                let jP = thisClass.get(szWorkFlowItemId); 
                thisClass.delete(szWorkFlowItemId);                                            
                jP.privOnNewDataProcessing();
            }, 0);
        } 
    }    
    
    // if not already triggered, triggers a the execution of the given funcWorkflowTrigger_IdData-function via message loop
    planRun(qryProcessor) {        
        let thisClass           = this.m_smCtrl;        
        let szWorkFlowItemId    = "planRun_" + qryProcessor.getId();
        
        if (!thisClass.has(szWorkFlowItemId)) {            
            thisClass.set(szWorkFlowItemId, qryProcessor);            
            setTimeout(function() {                         
                let jP = thisClass.get(szWorkFlowItemId); 
                thisClass.delete(szWorkFlowItemId);                                            
                jP.privOnRun();
            }, 0);
        }
    }

    prepareDragstart() {
        try { dragevent.dataTransfer.clearData(); } catch(e) {}
        this.clearAllDragData();
    }

    finishDragend() {
        this.clearAllDragData();
        try { dragevent.dataTransfer.clearData(); } catch(e) {}
              
    }

    processStdBtn_DbUpdate(btnElem) {
        var data; 
        var szIdRegistry = g_wfCtrl.domeGet_idRegistry(btnElem); 
        data = g_wfCtrl.loadFromRegistry(szIdRegistry); 
        console.log('SDA', 'data', szIdRegistry, data); 
        var object = data.templateHtml.buildDbDatasetForWriteback(); 
        g_wfCtrl.getQP(data.id_qp).updateDoc(data.id_entry, object); 
        return(false);        
    }

    domeGet_idRegistry(srcElem_or_elemArray) {        
        return(HtmlProcessor.domeGet_idRegistry(srcElem_or_elemArray));
    }

    domeGet_idEntry(srcElem_or_elemArray) {        
        return(HtmlProcessor.domeGet_idEntry(srcElem_or_elemArray));
    }

   /** (Non-Static) Function used to set values of DOM-Elements in a standardized way !
    *  @param {HtmlElement} dstElem DOM-Element whose attribute (or classlist) shall be set/modified.
    *  @param {any} vNewValue New value, that will be used to set/modify.
    *  @param {String} szAttribute Name of the element-attribute that is foreseen to be changed.
    *  @param {Object=} domeSetParam optional additional Parameter which is used on some cases (to produce some follow-up action or so).
    */        
    domeSet(dstElem, vNewValue, szAttribute, domeSetParam) {
        return(HtmlProcessor.domeSet(dstElem, vNewValue, szAttribute, this, domeSetParam));
    }

    // *********************************************
    // ControlledReactComponents
    // **********************************************
    getCRC(id_dom_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself) {
        return(this.m_regCRC.getCRC(id_dom_or_htmlElement, bForHtmlElements_ReturnNextParentCRCIfNoCRCItself));
    }

    registerControlledReactComponent(crc) {
        return(this.m_regCRC.registerControlledReactComponent(crc));
    }

    deregisterControlledReactComponent(crc) {
        return(this.m_regCRC.deregisterControlledReactComponent(crc));
    }
    // *********************************************
}
workflowControl.EVENT = "EVENT_workflowControl";

workflowControl.EVENT_subType_refreshSupervision = "EVENT_subType_refreshSupervision";
workflowControl.EVENT_subType_justFunction = "EVENT_subType_justFunction";
workflowControl.EVENT_subType_allQueryProcessorsReportRunCompleted = "EVENT_subType_allQueryProcessorsReportRunCompleted";
workflowControl.EVENT_ACTION = "EVENT_ACTION_workflowControl";
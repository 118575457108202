import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import workflowControl from "./fabbi_workflowcontrol.js";
import PromiseChain from "/app-assets/js/fabstd/fabbi_promise_chain.js";
import qryProcessorBundle_Family from "./fabbi_qry_processor_bundle_family.js";
import qryProcessorBundle_User from "./fabbi_qry_processor_bundle_user.js";
import qryProcessor_User from "./fabbi_qry_processor_user.js";
import qryProcessor_UserFamilyReferences from "./fabbi_qry_processor_userfamilyreferences.js";
import qryProcessor_UserIndividualities from "./fabbi_qry_processor_userindividualities.js";
import qryProcessor_UserUsersAuthorized from "./fabbi_qry_processor_userusersauthorized.js";
import qryProcessor_UserUsersAuthorized_FamilyReferences from "./fabbi_qry_processor_userusersauthorized_familyreferences.js";
import qryProcessor_KnownEmails from "./fabbi_qry_processor_knownemails.js";
import qryProcessor_UserInvitations from "./fabbi_qry_processor_userinvitations.js";
import qryProcessor_Family from "./fabbi_qry_processor_family.js";
import qryProcessor_Members from "./fabbi_qry_processor_members.js";
import qryProcessor_Ticker from "./fabbi_qry_processor_ticker.js";
import qryProcessor_Search from "./fabbi_qry_processor_search.js";
import qryProcessor from "./fabbi_qry_processor.js";
import React from 'react';
import ReactDOM from 'react-dom';
import ControlledReactComponentRegister from './fabbi_controlled_react_component_register.js';
import CrcModal_01 from './fabbi_crc_modal_01.jsx';
import CrcSignIn_ModalInput_01 from "./fabbi_crc_sign_in_modalinput_01.jsx";
import CrcSignUp_ModalInput_01 from "./fabbi_crc_sign_up_modalinput_01.jsx";
import CrcUsers_ModalInput_01 from './fabbi_crc_users_modalinput_01.jsx';
import CrcFamilies_ModalInput_01 from './fabbi_crc_families_modalinput_01.jsx';
import CrcMissions_ModalInput_01 from './fabbi_crc_missions_modalinput_01.jsx';
import CrcObjectives_ModalInput_01 from './fabbi_crc_objectives_modalinput_01.jsx';
import CrcTestModal_01 from "./fabbi_crc_test_modal_01.jsx";
import CrcReceivedInvitations_ModalInput_01 from './fabbi_crc_received_invitations_modalinput_01.jsx';
import CrcSendInvitation_ModalInput_01 from './fabbi_crc_send_invitation_modalinput_01.jsx';
import CrcMainFrame_01 from "./fabbi_crc_nav_mainframe_01.jsx";
import HtmlProcessor from "./fabbi_htmlprocessor.js";
import { writeBatch, Timestamp } from "firebase/firestore";

// import CrcDatePicker_01 from "./fabbi_crc_datepicker_01.jsx";

// import ReactQuill from 'react-quill'; // ES6
// import { Editor, PieChartDemo, LineChartDemo, DataTable2 } from "./fabbi_react.jsx";
// import { Environment } from "ag-grid-community";

export default class workflowControl_FamiPla extends workflowControl {
    constructor(dataPoolInstance, firebaseConfig) {        
        super(dataPoolInstance, firebaseConfig);
      
        console.log("workflowControl_FamiPla", "constructor");
        
        this.m_smItemStates = new StdMap();      

        // main-page
        this.m_resizable = undefined;

        console.log(this.constructor.name, "add fling-listener");
        // build up some reactions to "horizontal flings" ...    
        window.addEventListener("fling", (flingEvent) => {         
            // document.getElementById("debug_message").innerHTML = " - FLING " + flingEvent.detail.szDirection;
            this.processFling(flingEvent.detail.szDirection);
            // document.getElementById("debug_message").innerHTML = " - FLING2 " + flingEvent.detail.szDirection;
        });        

        // htmlResources
        this._htmlResources = {};
        
        // crcRegister of nav-components
        this._regCRC = new ControlledReactComponentRegister();

        // startUp_loadResources is launched ... when finished "onStartUp_LoadResourcesFinished" is called ...
        this.startUp_loadResources();
    }

   /*
    is called after NavComponents were instanciated ....
   */
    registerNavComponent(crcNavComponent) {
        console.log(this.constructor.name, "crcNavComponent", crcNavComponent);
        this._regCRC.registerControlledReactComponent(crcNavComponent);
    }

    startUp_loadResources() {
        var chainLoadParts = new PromiseChain({ wfCtrl: this });
        
        chainLoadParts.set_onAllSettled(function () {
            var { wfCtrl } = this.getCustomChainData();
            wfCtrl.onStartUp_LoadResourcesFinished();
        });
        chainLoadParts.pushPromise(undefined, function(loadStarted, loadNotStarted) {
            var { wfCtrl } = this.getCustomChainData();
            var bTouchDevice = wfCtrl.isTouchDevice();
    
            console.log(this.constructor.name, "startUp_loadResources", "this is a Touchdevice", bTouchDevice);  
            [   
                // { url: bTouchDevice?"part_001b.html":"part_001.html", css: bTouchDevice?"html-part-main-b":"html-part-main", variable: "g_szHtmlPart_001" },
                { url: "preloader01.html", css: "html-part-preloader", variable: "g_szHtmlPreloader_001" }
            ].forEach((entry) => {
                
                var qr = new XMLHttpRequest();
                if (!!entry.css) {
                    var szTmp = FabStdBro.getEffectiveUrlViaCSS("fabbi_styles", entry.css);
                    if (szTmp !== "") {
                        entry.url = szTmp;
                    } else {
                        console.log(this.constructor.name, "startUp_loadResources", "undefined is css:", entry.css);
                    }
                }
    
                if (!!entry.url) {
                    this.pushPromise({ qr: qr, entry: entry }, function(loadFinished, loadFailed) {
                        var { wfCtrl } = this.getCustomChainData();
                        var { qr, entry } = this.getCustomPromiseData();
                        qr.open('get', entry.url);
                        qr.send();
                        qr.onload = (e) => {        
                            wfCtrl._htmlResources[entry.variable] = qr.responseText;        
                            console.log(this.constructor.name, "startUp_loadResources", "loadFinished", entry.variable, "url", entry.url);
                            loadFinished("OK");
                        };
                    });
                }
            }); 
            // main resolved!   
            loadStarted("OK");      
        });
        // ******************************************************************    
    }

    // is called after settlement of "startUpLoadResources"
    onStartUp_LoadResourcesFinished() {

        var props = { wfCtrl: this, id_dom: "main_frame" };

        ReactDOM.render(
            React.createElement(CrcMainFrame_01, props), 
            document.getElementById("root")
        );

        this.startUp_prepareAuthentication();
    }

    startUp_prepareAuthentication() {
        console.log(this.constructor.name, "startUp_prepareAuthentication", "activating readiness for authentications");
        this.m_firebaseCtrl.setupAuthStateListener(
            (userData) => this.onFirebaseSignIn(userData), 
            (userData) => this.onFirebaseSignOut(userData));
    }

    onFirebaseSignIn(userData) {
        if (
            this.getMainUserData() && 
            this.getMainUserData().email && 
            this.getMainUserData().email !== userData.email) {
            console.log(this.constructor.name, "onFirebaseSignIn", "ERROR", "double Authorization! Signing out!");
            firebase.auth().signOut();                    
            return;
        }
                    
        var { emailVerified, uid, displayName, isAnonymous } = userData;
        var providerData = userData.providerData[0];
                
        if (!emailVerified) {
            // document.getElementById('quickstart-verify-email').disabled = false;
        }             

        // setup local config
        this.m_localConfig.authType = providerData.providerId;    // "password" = eMail und Password; "google.com" = GOOGLE
        
        console.log(this.constructor.name, "onFirebaseSignIn", "user signed in: " + displayName);
        FabStdBro.setCookie(this.constructor.name, "onFirebaseSignIn", "local_config", JSON.stringify(this.m_localConfig));        

        if (this.startUp_UserAuthenticated(userData)) {
            // next step
            this.startUp_prepareDOM();
        }
    }

    onFirebaseSignOut(userData) {
        console.log(this.constructor.name, "onFirebaseSignOut", "authStateChangeCount", this.getFirebaseCtrl().getEventCount_AuthStateChanged());

        if (this.getFirebaseCtrl().getEventCount_AuthStateChanged() > 1) {
            // if a "signedOut" is seen and there was a "signedIn"-User before,
            // we force RELOADING the webapp (location.reload()) to clean up everything 
            console.log(this.constructor.name, "onFirebaseSignOut", "force reload");
            location.reload(); 
        } 
    }
        
   /*
    */
    startUp_UserAuthenticated(userData) {
        console.log(this.constructor.name, "startUp_UserAuthenticated");
        if (!userData) {
            console.log(this.constructor.name, "startUp", "FAILED", "no userData provided!");
            return(false);
        }
        if (!userData.uid) {
            console.log(this.constructor.name, "startUp", "FAILED", "no userData.uid found!");
            return(false);
        }

        this.m_UserData = userData;

        return(true);
    }

   /*
    startUp DOM-Preparations after user authentication
    DOM will not be ready at once
   */
    startUp_prepareDOM() {
        if (!this.isUserAuthenticated()) return(false);
        
        // lookup "nav_main"
        var crcNav = this.getCRC_MainFrame();
        if (crcNav) {
            console.log(this.constructor.name, "startUp_prepareDOM", "nav update", "demanded");
            crcNav.updateAllNav({ bAuthenticated: true })
                .then((result) => {
                    // the update-function returns a promise, which is resolved returning an returncode
                    // result.rc of "OK" ... if the update was successful
                    if (result.rc === "OK") {
                        // OK ... that's what we wanted!
                        console.log(this.constructor.name, "startUp_prepareDOM", "nav update", "finished");
                        this.startUp_prepareDOMFinished();
                    } else {
                        console.log(this.constructor.name, "startUp_prepareDOM", "nav update", "failed");
                    }
                })
                .finally(() => {
                    console.log(this.constructor.name, "startUp_prepareDOM", "finally") 
                });
        } else {
            console.log(this.constructor.name, "startUp_prepareDOM", "mainframe not found", "FAILED!");
        }
    }

   /*
    startUp DOM after user authentication AND after DOM preparation
   */
    startUp_prepareDOMFinished() {
        var szUserId = this.getMainUserId();

        console.log(this.constructor.name, "startUp_prepareDOMFinished", "User-ID", szUserId);

        this.m_qpSearch = new qryProcessor_Search({ wfCtrl: this, szTriggerId: "MAIN"});
        this.m_qpTicker = new qryProcessor_Ticker({ wfCtrl: this, szTriggerId: "MAIN"});
        this.m_bundleFamilies = new qryProcessorBundle_Family({ wfCtrl: this, szTriggerId: "MAIN"});
        this.m_bundleUsers = new qryProcessorBundle_User({ wfCtrl: this, szTriggerId: "MAIN"});
        
        this.m_listenerUserFamilyReferences = null;
        this.m_listenerUserUsersAuthorized = null; 
        this.m_listenerUserIndividualities = null;

        var qryP_init = { wfCtrl: this, szTriggerId: szUserId, szUserId: szUserId }

        // add the main user
        this.m_bundleUsers.attach(
            new qryProcessor_User(qryP_init)
        );

        this.m_listenerUserIndividualities = new qryProcessor_UserIndividualities(qryP_init);  
        this.m_listenerUserFamilyReferences = new qryProcessor_UserFamilyReferences(qryP_init);            
        this.m_listenerUserUsersAuthorized = new qryProcessor_UserUsersAuthorized(qryP_init);  
        this.m_listenerUserInvitations = new qryProcessor_UserInvitations(qryP_init);     
        
        FabStdBro.setCookie("active_missions", "yes");        
        
        console.log("COOKIE 1:", FabStdBro.getCookie("active_missions"));            
    }    

    // *****************************************************************
    // *****************************************************************
    addSnack(severity, content, optProps) {
        console.debug(this.constructor.name, "addSnack", severity, content, optProps);
        var crcSB = this.getCRC_MainFrame().getCRC_SnackBar();
        if (crcSB) crcSB.addSnack(severity, content, optProps); else console.debug(this.constructor.name, "addSnack", "snackbar NOT found!");
    }

    addHidden(crcPrototype, props) {
        return(this.getCRC_MainFrame().addHidden(crcPrototype, props));
    }
    
    removeHidden(id_dom) {
        return(this.getCRC_MainFrame().removeHidden(id_dom));
    }

    doWithHidden(crcPrototypeFilter, funcAdditionalFilter, funcToDo) {
        this.getCRC_MainFrame().doWithHidden(crcPrototypeFilter, funcAdditionalFilter, funcToDo);
    }

    // Shows just the output of ONE htmlProcessor (the specified one) which is populating
    // the supersection (used in the the specified htmlProcessor), the output of all other htmlProcessors
    // will be hidden
    showOutputOfJustOneHtmlProcessor(htmlProcessor) {
        if (htmlProcessor) {
            this.getCRC_MainFrame().doWithHidden(
                htmlProcessor.prototype,
                (crc, objCrcBlueprint) => { return(crc.getId_Div_SuperSection() === htmlProcessor.getId_Div_SuperSection()); },
                (crc, objCrcBlueprint) => { /* console.log("HIDING", htmlProcessor === crc, crc, htmlProcessor); */ (htmlProcessor=== crc)?crc.showOutput():crc.hideOutput() }
            );
        }
    }

    addVisible(crcPrototype, props) {
        return(this.getCRC_MainFrame().addVisible(crcPrototype, props));
    }
    removeVisible(id_dom) {
        return(this.getCRC_MainFrame().removeVisible(id_dom));
    }

    // *****************************************************************
    startDelayed(funcToStart) {
        setTimeout(funcToStart, 5);
    }
    // *****************************************************************    
    /**
     * Handles the sign up (create new user-Button)
     */
    signUp() {
        console.log(this.constructor.name, "signUp");

        this.openAsModal(
            { /* frame props */ }, 
            CrcSignUp_ModalInput_01, 
            { 
                /* conent props */
            }, true);

        return(true);
    }

    /**
     * Handles the sign in button press.
     */
    _signIn(default_email) {
        console.log(this.constructor.name, "signIn");

        this.openAsModal(
            { /* frame props */ }, 
            CrcSignIn_ModalInput_01, 
            { 
                /* conent props */
                email: default_email
            }, true);
    }

    _signOut() {
        this.getFirebaseCtrl().signOut();
    }
    // *****************************************************************
    signIn(default_email) {
        this.startDelayed(() => this._signIn(default_email) );
    }

    signUp() {
        this.startDelayed(() => this._signUp());
    }

    signOut() {            
        this.startDelayed(() => this._signOut());
    }
    // *****************************************************************

    testCmd_Obj1() {
        var hp = new HtmlProcessor({});
        
        var coll = FabStd.arrayToMultiUsable([ hp ]);
                /*
            // console.log("descName", key, "desc", desc, "val", desc.value, "typeof:", typeof(desc.value), "n:", desc.value.name, "l:", desc.value.length);
            console.log(funcName);
            allHtmlProcessor.prototype[funcName] = function(args) {
                console.log("FUNCTIONCALL");
                var arrResult=[];
                // pass argument-List to all hps in List
                this._arrHP.forEach((hp) => arrResult.push(hp[funcName](...arguments)) );
                return(arrResult);
            }; // .bind(all);
        });
        */
        console.log("coll", coll);
        coll.buildAll("TEST"); // for Test
    }

    testCmd_pc() {
        var customData = { b: 1};
        var arrList = [ "john", "max"];
        var chainTest = new PromiseChain({ main: "blabla" });
        chainTest.pushListOfFunctionCallsOne(arrList, customData)
    }

    testCmd_RegExp(testString) {
        var arrParam = [];

        arrParam = testString.split(" ");
        
        var nBracketsOpen = 0;
        var idxToJoinTo = -1;
        arrParam.map((param, idx) => {
            var regExpBrackets = /(\()|(\))/g; 
            var matchBrackets;
            while (matchBrackets = regExpBrackets.exec(param)) {
                if (matchBrackets) {
                    if  (matchBrackets[1]) ++nBracketsOpen; else --nBracketsOpen;
                }
            }
            if (nBracketsOpen > 0 && idxToJoinTo < 0) idxToJoinTo = idx;
            if (nBracketsOpen === 0) {
                if (idxToJoinTo >= 0) {
                    for (var idxTmp=idxToJoinTo+1; idxTmp <= idx; ++idxTmp) {
                        arrParam[idxToJoinTo] += arrParam[idxTmp];
                        arrParam[idxTmp] = null;
                    }
                }
                idxToJoinTo = -1;
            }
            // console.log("matchBrackets", param, matchBrackets, nBracketsOpen);
        });
        arrParam = arrParam.filter((param) => param !== null);
        console.log("arrParam-Result", arrParam);
    }

    testCmd_02() {
        this.m_qpTicker.processEntry(undefined, { subject: "Testeintrag", info: "Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla Blablabla" }, "added");            
    }
    
    testCmd_01() {
        console.log("here we go!", this.m_smQPR);
        var iter = this.m_smQPR.keys();
        var result = iter.next();            
        while(!result.done) {    
            console.log("qryProcessor-Id", result.value);
            result = iter.next();
        }

        console.log("DataPool-Contents");
        this.getDataPool().logContent();
    }

    setActiveModule(szModule) {
        this.getCRC_MainFrame().setActiveModule(szModule);
    }

    getActiveModule() {
        return(this.getCRC_MainFrame().getActiveModule());
    }

    getCRC(id_dom) {
        return(this.m_regCRC.getCRC(id_dom));
    }

    getCRC_MainOuterFrame() {
        return(this.getCRC("main_outer_frame"));
    }

    getCRC_MainFrame() {
        return(this.getCRC("main_frame"));
    }

    dlgModal_GoogleMaps() {
        this.setActiveModule("google");
    }

    // is called by supervision if all processors have finished AND 
    // there are no open problems
    onAllRun() {
        console.log(this.constructor.name, "onAllRun");

        var szFamilyId = this.getSelectedId_Families();
        var arrFam = this.m_bundleFamilies.getFamilies();
        if (!arrFam.includes(szFamilyId)) {
            console.log("onAllRun", szFamilyId);
            szFamilyId = FabStd.arrayGetRandomEntry(arrFam);
            if (!szFamilyId) szFamilyId = "";                
            this.setSelectedId_Families(szFamilyId);                
        }
    }
    
    // is called after a "RunCompleted"-Signal arrived from all Database listeners
    onStartUpCompleted() {            
        super.onStartUpCompleted();   

        console.log(this.constructor.name, "onStartUpCompleted");

        /*
        var props = 
            { id_dom: "root_123", wfCtrl: this, csv: "Zdbp7vQ5rhRf5NXMdJVRcAOwCQi2_1581619882_260000000;Zdbp7vQ5rhRf5NXMdJVRcAOwCQi2_1581619882_260000000;Zdbp7vQ5rhRf5NXMdJVRcAOwCQi2_1581619882_260000000" };
    
        ReactDOM.render(
            React.createElement(CrcCarousel_01_Csv, props), 
            document.getElementById("root_123")
            );
            */

           // document.getElementById("root_123").style.height = "50px";

        var props = 
            { id_dom: "root_123", wfCtrl: this };
    
        /*
        ReactDOM.render(
            React.createElement(CrcDatePicker_01, props), 
            document.getElementById("root_123")
            );
        */

        /*
        console.log(this.constructor.name, "onStartUpCompleted");
        var pr = { aaa: "test", id: "bla", wfCtrl: this };
        ReactDOM.render(
            React.createElement(ShoppingList, pr), 
            document.getElementById("root")
            );
        */
        // var TT;
        // console.log("TESTTEST", document.getElementById("reacttest"), TT=ReactDOM.findDOMNode(document.getElementById("reacttest")));
        // TT.setState({ aaa: "Dusselmussel"});
        // ReactDOM.render(React.createElement(Editor, null), document.getElementById("root"));
        // ReactDOM.render(React.createElement(DataTable2, null), document.getElementById("root"));
        // ReactDOM.render(React.createElement(PieChartDemo, null), document.getElementById("root"));
        // ReactDOM.render(React.createElement(LineChartDemo, null), document.getElementById("root"));

        /*
        var mapUsers = // this.m_listenerUserUsersAuthorized.getPrivateDataPool().getDataMap();
            this.m_bundleUsers.getPrivateDataPool().getDataMap();
        console.log("onStartUpCompleted", "users found:", mapUsers.length);
        */
        var szId = "qryProcessor_User" + "_" + this.getMainUserId();
        var qpU = this.m_bundleUsers.getBundleQP(szId);
        if (!qpU) {
            console.log(this.constructor.name, "onStartUpCompleted", "no main-user-listener! ERROR!", "id", szId, "bundle", this.m_bundleUsers);
            return;
        }

        var dataset_user = qpU.getSingleDataset(this.getMainUserId());
        console.log(this.constructor.name, "onStartUpCompleted", "dataset_user", dataset_user.name, dataset_user.email, dataset_user);

        function ensureEmail(szEmail) {
            console.log("ensureEmail", "EMAIL", szEmail);
            let chainEnsureEmail = new PromiseChain();
        
            chainEnsureEmail.pushPromise(function(resolve, reject) {
                var test = new qryProcessor_KnownEmails({ 
                    wfCtrl: g_wfCtrl, 
                    szUserId: g_wfCtrl.getMainUserId(), 
                    szTriggerId: g_wfCtrl.getMainUserId()
                });                                
                test.readDoc(szEmail).then((result) => {
                    if (result.rc === "OK") {
                        if (!result.dataSnapshot.exists) {
                            // email missing!
                            console.log("ensureEmail", "EMAIL", szEmail, "is missing!");
                            resolve( { rc: "MISSING", szEmail: szEmail });
                        } else { 
                            console.log("ensureEmail", "EMAIL", szEmail, "is existing!");
                            resolve( { rc: "OK", szEmail: szEmail });
                        }
                    } else {
                        console.log("ensureEmail", "EMAIL", szEmail, "couldn't be checked!");
                        resolve( { rc: "ERROR", szEmail: szEmail });
                    }
                });
            }).then((result) => {
                if (result.rc === "MISSING") {
                    console.log("ensureEmail", "EMAIL", szEmail, "is being added!");
                    chainEnsureEmail.pushPromise(function(resolve, reject) {
                        var tmpQP = new qryProcessor_KnownEmails({ 
                            wfCtrl: g_wfCtrl, 
                            szUserId: g_wfCtrl.getMainUserId(),
                            szTriggerId: g_wfCtrl.getMainUserId()
                        }); 
                        tmpQP.setDoc(result.szEmail, {
                            modified_by: g_wfCtrl.getMainUserId(),
                            added_by: g_wfCtrl.getMainUserId(),
                            ts_modified: Timestamp.now(),
                            ts_added: Timestamp.now()                                
                        }).then((result) => {
                            console.log("ensureEmail", "EMAIL", szEmail, "adding finished!", "result", result);
                            resolve( { result } );  // END CHAIN!
                        });
                    });
                }
            });
        }
        
        if (!dataset_user.ts_added) {
            console.log("onStartUpCompleted", "Mainuser-Data is empty!", g_wfCtrl.m_UserData);
            qpU.setDoc(this.getMainUserId(), {
                    name: (!!this.m_UserData.displayName)?this.m_UserData.displayName:this.m_UserData.email,
                    email: this.m_UserData.email,
                    modified_by: this.getMainUserId(),
                    added_by: this.getMainUserId(),
                    ts_modified: Timestamp.now(),
                    ts_added: Timestamp.now()
                }
            ).then((result) => {
                if (result.rc === "OK") {
                    // ensureEmail
                    ensureEmail(g_wfCtrl.m_UserData.email);
                } else {
                    // failure
                    resolve(result); 
                }
            });
        } else {
            // Userdata exist ... we ensure that the email-adress is in "knownEmails"
            ensureEmail(this.m_UserData.email);
        }
        
        this.refreshSelectedId_Families();

        console.log("reactOnInputWithDelay", "started");
        FabStdBro.reactOnInputWithDelay(document.getElementById("search-all"), (e) => {
            console.log("reactOnInputWithDelay", "search-all", "Searchall used: ", e.srcElement.value);
            // this.m_dataPool.search(e.srcElement.value);
            this.m_Hilitor.remove();
            this.m_Hilitor.apply(e.srcElement.value);

            this.m_qpSearch.displaySearchResults(this.m_Hilitor);
        } );

        /*
        document.getElementById("search-all").addEventListener(
            "input", (e) => {
                console.log("search-all", "Searchall used: ", e.srcElement.value);
                this.m_dataPool.search(e.srcElement.value);
                this.m_Hilitor.remove();
                this.m_Hilitor.apply(e.srcElement.value);
            });
        */
    }    

    // this function called via workflowcontrol, if a problem is a ready to be treated/fixed
    onTryToFixProblem(problem) {                    

        if (!!problem.isSolving()) {            
            console.log("onTryToFixProblem", "Problem's solution already underway!'", "id", problem.problem_id);
            return;
        }
        
        if (!!problem.isSolved()) {            
            console.log("onTryToFixProblem", "Problem already solved! Skipping!", "id", problem.problem_id);
            return;
        }
        
        if (!problem.problem_type) {
            console.log("onTryToFixProblem", "No <problem.type> set! Problem skipped!");
            return;
        }

        console.log("onTryToFixProblem", "processing problem", problem);
        problem.setSolving();

        // ************************************************************************
        // prepare the PromiseChain used to fix the problem
        // ************************************************************************
        var fixChain;   
        fixChain = new PromiseChain({
            // we save, what we need to mark the problem as solved 
            // after some asynchronous calls ...
            problem_id: problem.problem_id
        });            
        fixChain.set_onAllSettled(function () {
            // actions to do, when Promise-chain terminates
            // this = "promiseChain"
            var cd = this.getCustomChainData();
            var problem = g_wfCtrl.getProblem(cd.problem_id);
            if (!!problem) {
                console.log("problem", "solved", "id", problem.problem_id, "data_id", problem.data_id);
                problem.setSolved();    // mark problem as solved

                var qryP = g_wfCtrl.getQP(problem.data_id);
                if (!!qryP) {
                    console.log("problem", "solved", "id", problem.problem_id, "error", qryP.getLastError());
                    if (!!qryP.getLastError()) {
                        // console RESTARTING 
                        console.log("problem", "solved", "restarting", qryP.getId());
                        qryP.restart();
                    }
                }
            }
        });            
        // ************************************************************************

        switch (problem.problem_type) {
            // general "cloudfirestore"-issues
            case "error_cloudfirestore_queryProcessor":    
                var qryP = problem.data_qp; // we get the relevant qp
                var qryPP = qryP.getTriggerQP();
                var qryPUP = qryP.getUltimateTriggerQP();
                
                if (!qryP.getLastError() || qryP.isTerminating()) {
                    // the Problem seems to be solved already! very good!
                    console.log("problem", "looks as if there's no error! directly set solved");                        
                    problem.setSolved(); 
                    break;
                }                    

                // permission denied - USERs
                if (qryP instanceof qryProcessor_User) {
                    var szUserId = qryP.getTriggerId();
                    if (qryPP instanceof qryProcessor_UserUsersAuthorized || qryPP instanceof qryProcessor_Members) {
                        this.addSolTri_PermissionDeniedUser_01(fixChain ,szUserId);
                    } else {
                        // no!
                        console.log("problem", "unexpected programming issue in", qryPP.getClassName());
                    }
                } else { 
                    if (!!qryPUP)  {     
                        console.log("problem", qryP.getClassName(), "PUP", qryPUP.getClassName());

                        // everything whose ultimate parent/trigger is of type "qryProcessor_UserFamilyReferences"
                        if (qryPUP instanceof qryProcessor_UserFamilyReferences) {
                            console.log("problem", "ultimate parent is UFR", qryP.getId(), "Trigger-PUP", qryPUP.getTriggerId());

                            var qryP_T = qryP.getDeepTriggerQP((qryP_Act, qryP_Trig) => { return(qryP_Trig instanceof qryProcessor_Family); });
                            console.log("problem", "TRIGGER_FOUND", qryP_T.getClassName(), "family_id", qryP_T.getTriggerId());
                            var qryP_Family = qryP_T.getTriggerQP();
                            var szFamilyId = qryP_T.getTriggerId();

                            // we delete family_id-reference for "szFamilyId" IF EXISTING
                            // just shut down listeners and child-listeners, if NOT EXISTING
                            if (!!szFamilyId) {
                                console.log("problem", "deleting family-reference to", szFamilyId);
                                qryPUP.deleteDoc(szFamilyId).then((result) => {
                                    console.log("problem", "deleteDoc", "rc", result.rc, "Error", result.error);
                                });
                            }
                            // if (!!szFamilyId && !!this.m_listenerUserFamilyReferences.getSingleDataset(szFamilyId)) {
                            //    this.m_listenerUserFamilyReferences.deleteDoc(szFamilyId);
                            // }
                        }
                    }
                }

                break;

            // other problems
            case "data_missing":
                var szDataId = problem.data_id;
                switch(problem.data_type) {
                    case "user":                            
                        // a "user" couldn't be found in the "users"-collection
                        // the "users"-collection is powered via the "users-authorized"-collection, which has to be
                        // extended in some cases                            
                        this.addSolTri_MissingDataUser_01(fixChain, szDataId);
                        break;
                }
                break;
        }                    

        // ??? something should be done ...
        if (fixChain.isEmpty()) {                
            problem.setSolved();
        }
    }    

    addSolTri_PermissionDeniedUser_01(fixChain, szUserId) {    
        console.log("problem", "addSolTri_PermissionDeniedUser_01", "user_id", szUserId);

        var szSomeValidFamilyId = FabStd.arrayGetRandomEntry(this.m_bundleFamilies.getFamilies(szUserId));
        console.log("problem", "addSolTri_PermissionDeniedUser_01", "some_valid_family_id", szSomeValidFamilyId);

        fixChain.pushPromise( 
            { 
                user_id: szUserId, 
                some_valid_family_id: szSomeValidFamilyId,
                // we look at the authorizee's data (the authorizer has access to data, he's referenced by)
                qpTmp: new qryProcessor_UserUsersAuthorized_FamilyReferences({ 
                            wfCtrl: g_wfCtrl, 
                            szTriggerId: szUserId, 
                            szUserId: szUserId, 
                            szUserAuthorizedId: g_wfCtrl.getMainUserId()
                        }) 
            }, 
            function(resolve_main, reject_unused) {
                // qpTmp => qryProcessor_UserUsersAuthorized_FamilyReferences
                this.getCustomPromiseData().qpTmp.readQuery().then((result) => {
                    // console.log("problem", result);                    
                    console.log("problem", "addSolTri_PermissionDeniedUser_01", "readQuery", result.rc);
                    if (result.rc === "OK") {                                        
                        this.getCustomPromiseData().bChecked_UserAuthorizationViaFamilyReference = false;

                        result.querySnapshot.forEach(function(snap) {
                            var szFamilyId = snap.id;
                            console.log("problem", "addSolTri_PermissionDeniedUser_01", "checking Family", szFamilyId);

                            var bUser_InFamilyMembers = g_wfCtrl.m_bundleFamilies.getFamilyMemberIds(szFamilyId).includes(szUserId);
                            var bUser_InFamilyMembersInvited = g_wfCtrl.m_bundleFamilies.getFamilyMemberInvitedIds(szFamilyId).includes(szUserId);
                            if (!bUser_InFamilyMembers /* & !bUser_InFamilyMembersInvited */) {                                
                                console.log("problem", "addSolTri_PermissionDeniedUser_01", "User", szUserId, "NO FAMIlY MEMBER");
                                // so! time to kill from the "Users_authorized"-list
                                // from "UserUsersAuthorized_FamilyReferences"                                
                                this.pushPromise(
                                    { 
                                        qpTmp: this.getCustomPromiseData().qpTmp 
                                    }, 
                                    function (resolve_deleted) {
                                        console.log("problem", "addSolTri_PermissionDeniedUser_01", "pushing", "deleteDoc");
                                        this.getCustomPromiseData().qpTmp.deleteDoc(szFamilyId).then(function(result) {
                                            console.log("problem", "addSolTri_PermissionDeniedUser_01", "delete_rc", result.rc);
                                            resolve_deleted({ rc: result.rc });
                                    }.bind(this));
                                }); 
                            } else {
                                // OK, looks like it's okay ...
                                this.getCustomPromiseData().bChecked_UserAuthorizationViaFamilyReference = true;
                            }                            
                            // check - if the user is "member" of the family                                                
                        }.bind(this));

                        // *****************************************
                        // what to do, if all sub-promises are done!
                        // *****************************************
                        this.add_onRollback(function()  {     
                            console.log("problem", "addSolTri_PermissionDeniedUser_01", "Rollback");
                            if (!this.isAborted()) {
                                if (!this.getCustomPromiseData().some_valid_family_id && !this.getCustomPromiseData().bChecked_UserAuthorizationViaFamilyReference) {                                    
                                    // so! time to kill the user completely from the "Users_authorized"-list                                    
                                    this.pushPromise(
                                        { user_id: this.getCustomPromiseData().user_id },
                                        function (resolve_deleted) {
                                            var szUserId = this.getCustomPromiseData().user_id;
                                            console.log("problem", "addSolTri_PermissionDeniedUser_01", "UserId", szUserId, "delete from UsersAuthorized");
                                            g_wfCtrl.m_listenerUserUsersAuthorized.deleteDoc(szUserId).then(function(result) {
                                                console.log("problem", "addSolTri_PermissionDeniedUser_01", "UserId", szUserId, "delete from UsersAuthorized", result.rc);                                                    
                                                resolve_deleted({ rc: result.rc });
                                        }.bind(this));
                                    });
                                } else {
                                    // start another solution-trial ...
                                    g_wfCtrl.addSolTri_resetUserAuthorizations_01(this.getParentChain(), this.getCustomPromiseData().user_id);
                                }
                            }
                        }.bind(this));
                        // *****************************************
                    }
                    resolve_main({ rc: result.rc });    // resolve and return error code
                });
            });
    }

    addSolTri_MissingDataUser_01(fixChain, szUserId) {
        console.log("problem", "addSolTri_MissingDataUser_01", "user_id", szUserId);

        // --- extend fixChain to check for entries in UserUsers_Authorized ---
        fixChain.pushPromise(
            { user_id: szUserId }, 
            function(resolved_fix, reject_unused) {
                // a "user" couldn't be found in the "users"-collection
                // the "users"-collection is powered via the "users-authorized"-collection, which has to be
                // extended in some cases                
                var szUserId = this.getCustomPromiseData().user_id;
                if (!g_wfCtrl.m_listenerUserUsersAuthorized.getSingleDataset(szUserId)) {
                    g_wfCtrl.addSolTri_resetUserAuthorizations_01(this.getParentChain(), szUserId);
                    resolved_fix({rc: "OK" });
                } 
            });  // end of fixChain.pushPromise-Call
    }        

    addSolTri_resetUserAuthorizations_01(fixChain, szUserId) {
        console.log("problem", "addSolTri_resetUserAuthorizations_01", "user_id", szUserId);

        // --- extend fixChain to check for entries in UserUsers_Authorized ---
        fixChain.pushPromise(
            { user_id: szUserId }, 
            function(resolved_fix, reject_unused) {
                // a "user" couldn't be found in the "users"-collection
                // the "users"-collection is powered via the "users-authorized"-collection, which has to be
                // extended in some cases                
                var szUserId = this.getCustomPromiseData().user_id;
                // ok ... looks like the user is not authorized yet.
                var arrFam = g_wfCtrl.m_bundleFamilies.getFamilies(szUserId);                
                
                if (arrFam.length > 0) {                                     
                    // console.log("tryToFixProblem", "found families with user", arrFam, "Bundle-Members", g_wfCtrl.m_bundleFamilies.m_smapBundleQPs);                                   
                    var szFamilyId = FabStd.arrayGetRandomEntry(arrFam); // we use a randomly selected index (so different try-to-fix-runs will differ)
                    console.log("problem", "addSolTri_resetUserAuthorizations_01", "user_id", szUserId, "correcting-try via familyId", szFamilyId);

                    // UserUsersAuthorized (foreign)
                    this.pushPromise(
                        { 
                            family_id: szFamilyId, 
                            qpTmp: new qryProcessor_UserUsersAuthorized({ 
                                        wfCtrl: g_wfCtrl, 
                                        szUserId: szUserId, 
                                        szTriggerId: szUserId, 
                                        bDirectlyRunProcessQueryAfterInitialization: false 
                                    }) 
                        }, 
                        function (resolve_setDoc, resolve_unused) {
                            this.getCustomPromiseData().qpTmp.setDoc(
                                g_wfCtrl.getMainUserId(),
                                { 
                                    family_id:  this.getCustomPromiseData().family_id 
                                }).then(
                                    ()=>{ resolve_setDoc({ rc: "OK" }); }, 
                                    ()=>{ resolve_setDoc({ rc: "ERROR" }); }
                                    );
                        });
                    
                    // qryProcessor_UserUsersAuthorized_FamilyReferences (foreign)
                    this.pushPromise(
                        { 
                            family_id:  szFamilyId, 
                            qpTmp:      new qryProcessor_UserUsersAuthorized_FamilyReferences({
                                            wfCtrl: g_wfCtrl, 
                                            szTriggerId: szUserId, 
                                            szUserId: szUserId, 
                                            szUserAuthorizedId: g_wfCtrl.getMainUserId()
                                        }) 
                        }, 
                        function (resolve_setDoc, resolve_unused) {
                            this.getCustomPromiseData().qpTmp.setDoc(this.getCustomPromiseData().family_id, { 
                                added_by: g_wfCtrl.getMainUserId(),  
                                modified_by: g_wfCtrl.getMainUserId(),  
                                ts_added: Timestamp.now(),
                                ts_modified: Timestamp.now()
                            }).then(()=>{ resolve_setDoc({ rc: "OK" }); }, ()=>{ resolve_setDoc({rc: "ERROR" }); }); 
                        });
                    
                    // UserUsersAuthorized (own)
                    this.pushPromise(
                        { 
                            family_id: szFamilyId, 
                            user_id: szUserId 
                        },
                        function (resolve_setDoc, resolve_unused) {
                            g_wfCtrl.m_listenerUserUsersAuthorized.setDoc(
                                this.getCustomPromiseData().user_id, 
                                { 
                                    family_id: this.getCustomPromiseData().family_id
                                }).then(()=>{ resolve_setDoc({ rc: "OK" }); }, ()=>{ resolve_setDoc({rc: "ERROR" }); }); 
                        });

                    // UserUsersAuthorized_FamilyReferences (own)
                    this.pushPromise(
                        { 
                            family_id:  szFamilyId, 
                            qpTmp:      new qryProcessor_UserUsersAuthorized_FamilyReferences({
                                            wfCtrl: g_wfCtrl, 
                                            szTriggerId: g_wfCtrl.getMainUserId(), 
                                            szUserId: g_wfCtrl.getMainUserId(), 
                                            szUserAuthorizedId: szUserId 
                                        }) 
                        },
                        function (resolve_setDoc, resolve_unused) {                        
                            this.getCustomPromiseData().qpTmp.setDoc(this.getCustomPromiseData().family_id, { 
                                added_by: g_wfCtrl.getMainUserId(),  
                                modified_by: g_wfCtrl.getMainUserId(),  
                                ts_added: Timestamp.now(),
                                ts_modified: Timestamp.now()
                            }).then(()=>{ resolve_setDoc({ rc: "OK" }); }, ()=>{ resolve_setDoc({rc: "ERROR" }); }); 
                    });
                } else {
                    this.pushPromise(
                        { 
                            user_id:    szUserId, 
                            qpTmp:      new qryProcessor_UserUsersAuthorized_FamilyReferences({
                                            wfCtrl: g_wfCtrl, 
                                            szTriggerId: g_wfCtrl.getMainUserId(), 
                                            szUserId: g_wfCtrl.getMainUserId(), 
                                            szUserAuthorizedId: szUserId 
                                        }) 
                        },
                        function (resolve_delDocTsa, resolve_unused) {                        
                            this.getCustomPromiseData().qpTmp.readQuery(szUserId)
                                .then(function(result) {
                                    // console.log("problem", "size", result.querySnapshot.size);
                                    if (result.querySnapshot.size === 0) {
                                        resolve_delDocTsa({rc: "OK"});
                                    } else {
                                        let tsa = writeBatch(this.getFirestore());
                                        result.querySnapshot.forEach(function(snap) {
                                            // console.log("problem", "snap", snap);
                                            this.getCustomPromiseData().qpTmp.deleteDoc_viaTransaction(tsa, snap.id);
                                        }.bind(this));
                                        
                                        g_wfCtrl.m_listenerUserUsersAuthorized.deleteDoc_viaTransaction(tsa, this.getCustomPromiseData().user_id);
                                        tsa.commit().then(
                                            () => { resolve_delDocTsa({rc: "OK"}); }, 
                                            () => { resolve_delDocTsa({rc: "ERROR"}); } );
                                    }
                                }.bind(this), 
                                ()=>{ resolve_delDocTsa({rc: "ERROR" }); }); 
                        });
                }
                resolved_fix({rc: "OK" });
            });  // end of fixChain.pushPromise-Call
    }        


    prepareTrashbins(parentNode) {
        let thisClass = this;

        // prepare trash-bins 
        // ***********************
        if (!parentNode) {
            parentNode = document;                
        }

        var tmp = parentNode.querySelectorAll('.trash-bin');
        for (var i=0; i < tmp.length; ++i) {
            var elem = tmp[i];

            elem.addEventListener('drop', function(dragevent) { 
                console.log("DELETE-DROP", "TYPE", thisClass.getDragData("drag_type"), "thisClass", thisClass); 
                var szDragType = thisClass.getDragData("drag_type");
                var szId_qryProcessor;
                var szId_Entry;
                var qp;

                switch(szDragType) {                                            
                    case "document":  {
                            szId_qryProcessor = thisClass.getDragData("id_qp");
                            // var dataset = thisClass.getDragData("dataset");
                            szId_Entry = thisClass.getDragData("id_entry");
                            console.log("DELETE-DROP", "TYPE", thisClass.getDragData("drag_type"), "thisClass", thisClass, "id", szId_qryProcessor, "qp", thisClass.getQP);
                            qp = thisClass.getQP(szId_qryProcessor);
                            // console.log("DELETE-DROP", "parent:", parent, "id:", szId, "dataset:", dataset, parent.constructor.name);
                                                
                            // execute Document-Deletion
                            qp.deleteDoc(szId_Entry);
                        }
                        break;

                    case "document_image": {
                            szId_qryProcessor       = thisClass.getDragData("id_qp");
                            szId_Entry              = thisClass.getDragData("id_entry");           
                            let szId_storageFile    = thisClass.getDragData("id_storageFile");
                            let szId_DOM            = thisClass.getDragData("id_dom");          // DOM (Html) Name, which shall be updated after successful job
                            
                            // console.log("DELETE-DROP", "TYPE", thisClass.getDragData("drag_type"), "thisClass", thisClass, "Id", szId_qryProcessor, "Fnc", thisClass.getQP); 
                            qp                      = thisClass.getQP(szId_qryProcessor);

                            var szOld = qp.getSingleDatasetField(szId_Entry, "image_src", () => "");
                            
                            var szNew = szOld.split(";").filter((szTest) => {var bTest = (szTest !== szId_storageFile); console.log(szTest, szId_storageFile, bTest); return(bTest);}).join(";");
                            
                            console.log("DELETE-OPERATION", "OldSplit", szOld.split(";"));
                            console.log("DELETE-OPERATION", szDragType, "qryProcessorId", szId_qryProcessor, "Entry-Id", szId_Entry, "Image", szId_storageFile, "old", szOld, "new", szNew, "parentQP", parent);

                            var obj = FabStd.addProp(undefined, "image_src", szNew);  
                            qp.updateDoc(szId_Entry, obj, (szDatasetId, dataset, error) => {
                                // successful (= no error) ?
                                if (error === undefined) {
                                    thisClass.getStorageManager().deleteFile(szId_storageFile).then(() => {
                                        // document.getElementById(szId_DOM).value = dataset["image_src"];
                                    }).finally(() => {
                                        thisClass.domeSet(document.getElementById(szId_DOM), dataset.image_src, "value");
                                    });
                                }
                            });
                        }
                        break;
                }
            }, false);

            elem.addEventListener('dragover', function(event) { 
                event.preventDefault(); //                console.log("DELETE-DRAGOVER", event); 
            }, false);            
        }            
    }

    processFling(szDirection) { 
        var tabCtrl = document.getElementById("tab_ctrl_1");
        var arrTabs = tabCtrl.querySelectorAll(".tab");
        var arrNav = [];
        var nInd = 0;
        var szNewHref = "";
        for (var i=0; i < arrTabs.length; ++i) {
            var anchor = arrTabs[i].querySelector("a");                
            nInd = anchor.classList.contains("active")?i:nInd;
            arrNav.push(anchor);
            // console.log("processFling", anchor);
        }

        if (nInd >= 0 && nInd < arrNav.length) {
            nInd += (szDirection === "left"?-1:1);
            nInd = Math.min(Math.max(0, nInd), arrNav.length-1);  

            // var szNewHref = arrNav[nInd];                
            // window.location.href = szNewHref;
            arrNav[nInd].click();
            // document.getElementById("debug_message").innerHTML = " - FLING - PROCESS " + szNewHref + "Direction: " + szDirection;
        }
    }            
    
    reportClick(szReporter, szComplementaryId) {        
        
        /*
        let cssRules = undefined;
        for (var i=0; i < document.styleSheets.length; ++i) {
            if (document.styleSheets[i].href.indexOf("fabbi_styles") >= 0) {
                cssRules = document.styleSheets[i].rules;
                break;
            }
        }        
        
        console.log(this.constructor.name, "reportClick", cssRules);
        console.log(this.constructor.name, "reportClick", szReporter, szComplementaryId);    
        */
        
        // Setup cross browser string
        let xform = 'transform';
        ['webkit', 'Moz', 'O', 'ms'].every((prefix) => {
            var e = prefix + 'Transform';
            if (typeof document.body.style[e] !== 'undefined') {
            xform = e;
            return false;
            }
            return true;
        });        
                
        console.log("reportClick", szReporter, xform);
        
        switch(szReporter) {
            case "HtmlProcessor_Missions": 
            case "HtmlProcessor_Families": 
                // fab-width-navi-A
                var change = document.querySelectorAll('.fab-width-navi-A, .fab-width-navi-B, .fab-width-navi-C');
                
                /*
                change.forEach((item) => {
                    // item.style.width = '100%';
                    item.classList.remove("animate");
                    item.classList.remove("fadeUp");
                    if (item.style[xform] === 'translateX(-100%)')  {
                        item.style[xform] = 'translateX(0%)';
                    } else {
                        item.style[xform] = 'translateX(-100%)';
                    }
                });                
                */
                
                // console.log("TEXTSTYLE", szReporter, "Style:", cssRules[".fab-width-navi-A"].style);
                break;
        }
    }
    
    addFamilyMission(szNewMissionName, arg2, arg3) {        
        console.log(this.constructor.name, "addFamilyMission", szNewMissionName, arg2, arg3, this.getSelectedId_Families());
        
        let qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(this.getSelectedId_Families());
        
        if (szNewMissionName.trim() != "") {                    
            qryP_Mis.addDoc({
                name: szNewMissionName,                
                modified_by: this.getMainUserId(),
                added_by: this.getMainUserId(),
                ts_modified: Timestamp.now(),
                ts_added: Timestamp.now()
            });
        }        
    }    
    
    addFamilyMissionObjective(szNewObjectiveName, arg2, arg3) {        
        console.log(this.constructor.name, "addFamilyMissionObjective", szNewObjectiveName, arg2, arg3, this.getSelectedId_Families(), this.getSelectedId_Missions());
        
        let qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(this.getSelectedId_Families());
        let qryP_Obj = qryP_Mis.getChildQP_Objectives(this.getSelectedId_Missions());
        
        if (szNewObjectiveName.trim() != "") {        
            qryP_Obj.addDoc({
                name: szNewObjectiveName,                
                modified_by: this.getMainUserId(),
                added_by: this.getMainUserId(),
                ts_modified: Timestamp.now(),
                ts_added: Timestamp.now()
            });
        }        
    }
    
    sendFamilyMessage(szMsgContent, arg2, arg3) {
        console.log(this.constructor.name, "sendFamilyMessage", szMsgContent, arg2, arg3, this.getSelectedId_Families());
        
        let msg = this.m_bundleFamilies.getSingleChildQP("qryProcessor_Messages_" + this.getSelectedId_Families());
        
        if (szMsgContent.trim() != "") {        
            msg.addDoc({
                content: szMsgContent,                
                modified_by: this.getMainUserId(),
                added_by: this.getMainUserId(),
                ts_modified: Timestamp.now(),
                ts_added: Timestamp.now()
            });
        }
    }

    getFirebaseCtrl() {
        return(this.m_firebaseCtrl);
    }

    isUserAuthenticated() {
        console.log(this.constructor.name, "isUserAuthenticated", this.getMainUserId());
        return(this.getMainUserId() && this.getMainUserId() !== "");
    }

    getMainUserData() {
        return(this.m_UserData);
    }

    getMainUserId() {
        if (!this.getMainUserData()) return(undefined);
        return(this.getMainUserData().uid);
    }
    
    // Overriden
    prepareDragstart() {
        console.log("dragdroptouch", "prepareDragstart - MAIN");
        $('.fab-dragdestination').addClass("fab-highlight");
        super.prepareDragstart();            
    }

    // Overriden
    finishDragend() {
        console.log("dragdroptouch", "finishDragend - MAIN");
        super.finishDragend();
        $('.fab-dragdestination').removeClass("fab-highlight");
    }        
    
    refreshSelectedId_Missions() {        
        var szSelected = this.getSelectedId_Missions();
        
        console.log(this.constructor.name, "refreshSelectedId_Missions", szSelected);
        
        this.onChanged_SelectedId_Missions(szSelected, szSelected);
    }

    getSelectedId_Missions() {                
        return(this.m_listenerUserIndividualities.getSelectedId_Missions());
    }
    
    switchMainSection(szNewActiveMainSection) {
        var main = document.getElementById("main");
        // var sel = $("#main > div");
        // console.log("SWITCH", sel);
        var mainNew;
        var tmpNode = main.firstElementChild;
        while (!!tmpNode) {
            if (tmpNode.classList !== undefined) {
                if (tmpNode.id === szNewActiveMainSection) {
                    mainNew = tmpNode;                    
                } else {
                    tmpNode.classList.add("invisible");
                }
            }
            tmpNode = tmpNode.nextSibling;
        }
        if (mainNew !== undefined) {
            mainNew.classList.remove("invisible");
        }
    }

    /**
     * Opens a ControlledReactComponent as a modal window
     * @param {String} crcInstance The ControlledReactComponent that shall be used as ModalInput.
     * @param {boolean} [bOpenDismissible=false] Optional Variable. If the modal window shall be "removable" 
     * by clicking somewhere outside of it, than you should set this one to "true".   
     *       
     * @return {Object} The instance-object of the modal-window will be returned.
     */        
    openAsModal(crcFrameProps, crcContentPrototype, crcContentProps, bOpenDismissible) {
        return(CrcModal_01.openAsModal(this, crcFrameProps, crcContentPrototype, crcContentProps, bOpenDismissible));
    }

    dlgReceivedInvitations() {


        this.openAsModal(
            {   style: {
                }
            },  // additional-frame-props
            // CrcReceivedInvitations_ModalInput_01,
            CrcTestModal_01,
            { 
                id_child: "RECEIVED_INVITATIONS"
            }, 
            true); 
    }


    dlgSendInvitation(szFamilyId) {
        if (!szFamilyId) {
            szFamilyId = this.getSelectedId_Families();
        }
        if (!szFamilyId) {
            return;
        }

        this.openAsModal(
            {   style: CrcModal_01.propStyle_Middle1
            },  // additional-frame-props
            CrcSendInvitation_ModalInput_01,
            { 
                id_child: "SEND_INVITATION",
                id_family: szFamilyId
            }, 
            true); 
    }

    leaveFamily(szFamilyId, szLeavingUserId) {
        if (!szLeavingUserId) {
            szLeavingUserId = this.getMainUserId();
        }
        if (!szFamilyId) {
            szFamilyId = this.getSelectedId_Families();
        }
        console.log(this.constructor.name, "leaveFamily", szFamilyId, window.location); 

        let szDocId = szFamilyId;
        var bundle = this.m_bundleFamilies;
        var resultMap = bundle.getBundleQPIdMap_viaTriggerId(szDocId); 
        if (!resultMap) return;
        var szId_QP = resultMap.keys().next().value;
        console.log("leaveFamily", szId_QP, bundle.m_smapBundleQPs);
        
        let qryP = bundle.getBundleQP(szId_QP);  // query-processor of famly

        // get "family-members"-qryProcessor
        let qryP_Members = bundle.getChildQP_Members(szFamilyId);

        // remove from family_members and remove alle familymembers from users_authorized
        // 
        // 1. collect family-members
        // 2. delete from family-members
        // 3. remove from "authorized_users"
        // 4. send problem 
        let chainLeaveFamily = new PromiseChain();
        let tsa = new writeBatch(this.getFirestore());

        chainLeaveFamily.pushPromise(function(resolve, reject) {
            // build tsa-payload
            qryP_Members.deleteDoc_viaTransaction(tsa, szLeavingUserId);
            g_wfCtrl.m_listenerUserUsersAuthorized.deleteDoc_viaTransaction(tsa, szLeavingUserId);
            
            tsa.commit().then(
                function () { 
                    console.log("leaveFamily", "success"); 
                    g_wfCtrl.m_qpTicker.processEntry(undefined, { subject: "leaveFamily", info: "you just left." }, "added");
                    resolve("OK"); 
                }.bind(this), 
                function (error) { 
                    console.log("leaveFamily-Failure"); 
                    resolve(error); 
                }.bind(this));
        });
    }

    getSelectedId_Missions() {
        return(this.m_listenerUserIndividualities.getSelectedId_Missions());
    }

    getHtmlProcessor_UserIndividualities() {
        return(this.m_listenerUserIndividualities.getTopHtmlProcessor());
    }

    getHtmlProcessor_Families() {
        return(this.m_bundleFamilies.getTopHtmlProcessor());
    }

    getHtmlProcessor_Users() {
        return(this.m_bundleUsers.getTopHtmlProcessor());
    }

    getHtmlProcessor_Missions(id_family) {
        if (!id_family) id_family = this.getSelectedId_Families();  // if no id provided, use "active" family   
        var qp = this.m_bundleFamilies.getChildQP_Missions(id_family);
        if (!qp) return(undefined);
        return(qp.getTopHtmlProcessor());
    }

    getHtmlProcessor_Objectives(id_family, id_mission) {
        if (!id_family) id_family = this.getSelectedId_Families();
        if (!id_mission) id_mission = this.getSelectedId_Missions();        
                
        // update objectives-display          
        var qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(id_family);
        if (!qryP_Mis) return(undefined);

        var qryP_Obj = qryP_Mis.getChildQP_Objectives(id_mission);
        if (!qryP_Obj) return(undefined);

        return(qryP_Obj.getTopHtmlProcessor());
    }

    editFamily2(szFamilyId) {
        if (!szFamilyId) {
            szFamilyId = this.getSelectedId_Families();
        }
        console.log(this.constructor.name, "editFamily2", szFamilyId, window.location);            
    
        this.openAsModal(
            {   style: {
                }
            },  // additional-frame-props
            CrcFamilies_ModalInput_01,
            { 
                id_child: szFamilyId,
                htmlProcessor: this.getHtmlProcessor_Families()
            }, 
            true); 
    }

    editUser2(szUserId) {
        if (!szUserId) {
            szUserId = this.getMainUserId(); 
        }
        console.log(this.constructor.name, "editUser2", szUserId, window.location);            

        var bundle = this.m_bundleUsers;        
    
        this.openAsModal(
            {   style: {
                }
            },  // additional-frame-props
            CrcUsers_ModalInput_01,
            { 
                id_child: szUserId,
                htmlProcessor: this.getHtmlProcessor_Users()
            }, 
            true); 
    }
        
    editMission2(szMissionId) {
        if (!szMissionId) {
            szMissionId = this.getSelectedId_Missions();
        }
        console.log(this.constructor.name, "editMission2", szMissionId, window.location);            

        this.openAsModal( 
            {   style: {
                }
            },  // additional-frame-props
            CrcMissions_ModalInput_01,
            { 
                id_child: szMissionId,
                htmlProcessor: this.getHtmlProcessor_Missions()
            }, 
            true); 
    }
           
    setObjectiveStatus(szObjectiveId, szNewStatus) {
        var szFam = this.getSelectedId_Families();        
        var szMis = this.getSelectedId_Missions(); 
                
        // update missions-display          
        let qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(szFam);
        let qryP_Obj = qryP_Mis.getChildQP_Objectives(szMis);
        
        var datasetStatus = {
            status: szNewStatus
        };                                                 
        qryP_Obj.updateDoc(szObjectiveId, datasetStatus);   
    }
    
    editObjective2(szObjectiveId) {
        if (!szObjectiveId) {
            szObjectiveId = this.getSelectedId_Objectives();
        }

        this.openAsModal(
            {   style: {
                }
            },  // additional-frame-props
            CrcObjectives_ModalInput_01,
            { 
                id_child: szObjectiveId, 
                htmlProcessor: this.getHtmlProcessor_Objectives()
            }, 
            true); 
    }   

    getSelectedId_gdtTsScript() {
        return(this.m_listenerUserIndividualities.getSelectedId_gdtTsScript());
    }

    setSelectedId_gdtTsScript(id_gdtTsScript) {                
        this.m_listenerUserIndividualities.setSelectedId_gdtTsScript(id_gdtTsScript);
    }           

    setSelectedId_Missions(szMissionId) {                
        this.m_listenerUserIndividualities.setSelectedId_Missions(szMissionId);
    }           
    
    // will be called by listenerUserInidividualities if a databasedata-change is recognized
    onChanged_SelectedId_Missions(szOldId, szNewId) {
        console.log(this.constructor.name, "onChanged_SelectedId_Missions", szOldId, szNewId);    

        var szFam = this.getSelectedId_Families();        

        // update missions-display        
        var qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(szFam);
        if (qryP_Mis && qryP_Mis.getTopHtmlProcessor()) {
            console.log(this.constructor.name, "onChanged_SelectedId_Missions", szOldId, szNewId, "renderEntry", "MISSIONS");
            
            // active Mission update!        
            qryP_Mis.getTopHtmlProcessor().setActiveIdEntry(szNewId);
            
            var misobj = qryP_Mis.getChildQP_Objectives(szNewId);  // szNewId = a Mission Id
            if (misobj && misobj.getTopHtmlProcessor()) {

                this.showOutputOfJustOneHtmlProcessor(misobj.getTopHtmlProcessor());
                
                console.log(this.constructor.name, "onChanged_SelectedId_Missions", szOldId, szNewId, "OBJECTIVES");

                if (document.getElementById('messages-div-scrollarea')) {
                    document.getElementById('messages-div-scrollarea').scrollTop = 0;
                    setTimeout(function() {             
                        document.getElementById('messages-div-scrollarea').scrollTop = 
                            document.getElementById('messages-div-scrollarea').scrollHeight;       // scroll down!
                    }, 10);            
                }
            }
        }
    }
    
    refreshSelectedId_Families() {        
        var szSelected = this.getSelectedId_Families();
        
        console.log(this.constructor.name, "refreshSelectedId_Families", szSelected);
        
        this.onChanged_SelectedId_Families(szSelected, szSelected);
    }

    getSelectedId_Families() {                
        return(this.m_listenerUserIndividualities.getSelectedId_Families());
    }            
    
    setSelectedId_Families(szFamilyId) {  
        // if (!szFamilyId) {
        //    szFamilyId = this.m_listenerUserIndividualities.getSelectedId_Families();
        // }
        
        var arrFam = this.m_bundleFamilies.getFamilies();
        if (!arrFam.includes(szFamilyId)) {
            szFamilyId = arrFam.length>0?arrFam[0]:"";
            // szFamilyId = "";
        }            

        console.log("setSelectedId_Families", "szFamilyId", szFamilyId);
        if (this.m_listenerUserIndividualities.getSelectedId_Families() !== szFamilyId) {
            this.m_listenerUserIndividualities.setSelectedId_Families(szFamilyId);
        }
    }            

    refreshLastChecked_Messages(szFamilyId) {                
        this.m_listenerUserIndividualities.refreshLastChecked_Messages(szFamilyId);
    }            
    
    // will be called by listenerUserInidividualities if a databasedata-change is recognized
    onChanged_SelectedId_Families(szOldId, szNewId) {
        console.log(this.constructor.name, "onChanged_SelectedId_Families", szOldId, szNewId);
        
        if (this.m_bundleFamilies && this.m_bundleFamilies.getTopHtmlProcessor()) {

            // active Family update!        
            this.m_bundleFamilies.getTopHtmlProcessor().setActiveIdEntry(szNewId);
            
            // update messages-display
            var qryP_Msg = this.m_bundleFamilies.getChildQP_Messages(szNewId);   // szNewId = a FamilyId
            var qryP_Mis = this.m_bundleFamilies.getChildQP_Missions(szNewId); // szNewId = a FamilyId
            
            if (qryP_Msg &&  qryP_Mis && qryP_Msg.getTopHtmlProcessor() && qryP_Mis.getTopHtmlProcessor()) {

                this.showOutputOfJustOneHtmlProcessor(qryP_Msg.getTopHtmlProcessor());
                this.showOutputOfJustOneHtmlProcessor(qryP_Mis.getTopHtmlProcessor());
                
                this.refreshSelectedId_Missions();
                
                if (document.getElementById('messages-div-scrollarea')) {
                    document.getElementById('messages-div-scrollarea').scrollTop = 0;
                    setTimeout(function() {             
                        document.getElementById('messages-div-scrollarea').scrollTop = 
                            document.getElementById('messages-div-scrollarea').scrollHeight;       // scroll down!            
                    }, 10);
                }
            }
        }
    }

    processNewImage_ImgSrc2(    
        newFiles,               // from "inp.target.files"
        func_getCsvImgSrc,
        func_setCsvImgSrc,
        qryP,                   // the query-processor that shall be used for the database-update-operation
        szDocId,                // document-id (for the write-operation)
        szDatafieldName_ImgSrc  // standard would be "image_src"  
    ) {
        var pc = new PromiseChain({
            newFiles: newFiles,
            func_getCsvImgSrc: func_getCsvImgSrc,
            func_setCsvImgSrc: func_setCsvImgSrc, 
            qryP: qryP, 
            id_doc: szDocId, 
            fieldName_imgSrc: szDatafieldName_ImgSrc
        });

        console.log(this.constructor.name, "qryP", qryP);

        pc.pushPromise({}, function(resolve, reject_unused) {

            for (var i=0; i < this.getCustomChainData().newFiles.length; ++i) {
                var file = this.getCustomChainData().newFiles[i];
                
                console.log("processNewImage_ImgSrc", "PROCESSING FILE", file);
                g_wfCtrl.getStorageManager().putFile(file, g_wfCtrl.getMainUserId()).then((snapshot) => {                                         
                    if (snapshot.state === "success") {                            
                        var szImgId = snapshot.metadata.name;
                                                
                        var arrTmp = FabStd.csvToArray(this.getCustomChainData().func_getCsvImgSrc());                        
                        if (arrTmp.indexOf(szImgId) < 0) arrTmp.push(szImgId);

                        // console.log(this.constructor.name, "arrTmp", arrTmp);

                        // select the first image
                        FabStd.arrayRollTo(arrTmp, arrTmp.indexOf(szImgId));

                        // console.log(this.constructor.name, "arrTmp", arrTmp);

                        // build a data-object!
                        var obj = {};
                        FabStd.addProp(obj, szDatafieldName_ImgSrc, FabStd.arrayToCsv(arrTmp));
                        
                        // console.log(this.constructor.name, "arrTmp", arrTmp, "joined", FabStd.arrayToCsv(arrTmp));

                        // write to database!
                        this.pushPromise({file: file, obj: obj}, function(resolve, reject_unused) {
                            this.getCustomChainData().qryP.updateDoc(
                                this.getCustomChainData().id_doc, 
                                this.getCustomPromiseData().obj, 
                                (szDocId, tmpData, error) => {
                                    if (error !== undefined) {                                        
                                        // error detected!
                                        resolve({rc: error});
                                    } else {
                                        console.log("processNewImage_ImgSrc", "PUTFILE SUCCESSFUL", tmpData[szDatafieldName_ImgSrc]); 
                                        if (this.getCustomChainData().func_setCsvImgSrc) {
                                            this.getCustomChainData().func_setCsvImgSrc(obj[this.getCustomChainData().fieldName_imgSrc]);
                                            // g_wfCtrl.domeSet(elemDom_ImgSrc, txtImgSrc, "value");
                                        }
                                        resolve({rc: "ok"});
                                    }
                            });
                        });
                        
                        resolve({rc: (snapshot.state === "success"?"ok":snapshot.error) });
                    } else {
                        resolve({rc: "ok"});
                    }                    
                });
            }

            resolve({rc: "ok"});
        });

        return(false);     
    }
}
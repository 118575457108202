import ControlledReactComponentExtension from "./fabbi_controlled_react_component_extension.jsx"; 
import timeoutCtrl from "/app-assets/js/fabstd/fabbi_timeout_ctrl.js";

export default class CrcExt_Resizable extends ControlledReactComponentExtension {
    constructor(props) {
        super(props);

        this._tc = new timeoutCtrl();

        this._arrResizers = [];
        this._arrResizableSections = [];
    }

    underlyingComponentDidMount(underlyingCRC) {
        super.underlyingComponentDidMount(underlyingCRC);

        this.updateResizer();
    }

    // we can do something here ...
    underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot) {
        // console.log(this.constructor.name, "underlyingComponentDidUpdate", underlyingCRC);
        super.underlyingComponentDidUpdate(underlyingCRC, prevProps, prevState, snapshot);

        this.updateResizer();
    }

    updateResizer() {
        this._cleanUp();
        this._setUp();
    }

    underlyingComponentWillUnmount(underlyingCRC) {
        // we can do some cleanup here
        super.underlyingComponentWillUnmount(underlyingCRC);
        
        this._cleanUp();
    }

    /**
    * Setup Event Handlers etc.
    */
    _setUp() {
        console.log(this.constructor.name, "_setUp");
        if (this._arrResizers.length > 0) return;

        var arrResizableSectionParts = [];

        var tmpNode = this.getUnderlyingDOMElement().firstElementChild;
        while (tmpNode) {
            if (tmpNode.classList) {
                console.log(this.constructor.name, "tmpNode.classList", tmpNode.classList);
                // build up array with RESIZABLE-Parts together with ONE specific resizer
                if (tmpNode.classList.contains("resizer")) {          
                    this._arrResizableSections.push(arrResizableSectionParts);
                    this._arrResizers.push(tmpNode);
                    arrResizableSectionParts = [];
                } else {          
                    // collect Sectional-Resizables (resizable elements within a section between two resizers or start/end of parent)
                    arrResizableSectionParts.push(tmpNode);          
                }     
            }
            
            tmpNode = tmpNode.nextSibling;
        }

        if (arrResizableSectionParts.length > 0) {
            this._arrResizableSections.push(arrResizableSectionParts);
        }
                
        for (var i = 0;i < this._arrResizers.length; i++) {
            var currentResizer = this._arrResizers[i];
            currentResizer._pParent = this;  // save the active "resizable"-instance
            currentResizer._nIndex = i;
            currentResizer._eventCallbacks = [];

            var fncTmp = this._func_startResize.bind(currentResizer);
            currentResizer._eventCallbacks.push(fncTmp);
            currentResizer.addEventListener('mousedown', fncTmp);          
            currentResizer.addEventListener('touchstart', fncTmp);     
        }
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _func_startResize(event) {        
        console.log(this.constructor.name, "_func_startResize");
        
        // event.preventDefault();
        var fncTmp1 = this._pParent._resize.bind(this);
        var fncTmp2 = this._pParent._stopResize.bind(this);

        this._eventCallbacksWindow.push(fncTmp1);
        this._eventCallbacksWindow.push(fncTmp2);

        window.addEventListener('mousemove', fncTmp1);
        window.addEventListener('touchmove', fncTmp1);

        window.addEventListener('mouseup', fncTmp2);
        window.addEventListener('touchend', fncTmp2);

        // we have a "pParent"-member of "this" ... that's the resizable-instance
        this._pParent._displayResizeCursor(true);
    }

    _displayResizeCursor(bDisplay) {
        if (bDisplay === true) {
            document.body.style.cursor = "row-resize";
        } else {
            document.body.style.cursor = "auto";   
        }
    }

    _doSizeChange(nChangeHeight, arrResizableSectionParts) {
        var nSectionHeightResizable = 0;
        var nSectionHeightChanged = 0;
        var tmpMark = [];

        nSectionHeightResizable = this._calcResizableHeight(arrResizableSectionParts, undefined);        
        if (nSectionHeightResizable <= 0.5) {
            return(0);
        }

        var fChangeFactor = (nSectionHeightResizable+nChangeHeight)/nSectionHeightResizable;

        var nChangeCount;
        do {
            nChangeCount = 0;
            for (var i2=0; i2 < arrResizableSectionParts.length; ++i2) {
                var elem = arrResizableSectionParts[i2];            
                if (!elem.classList.contains("no-resize") && tmpMark.indexOf(i2) < 0) {
                    var nOldHeight = elem.getBoundingClientRect().height;
                    var nNewHeight = nOldHeight * fChangeFactor;
                    elem.style.height = nNewHeight + "px";  // maybe the height can't be set ... we can't relay on that
                    var nNewHeight_Effective = elem.getBoundingClientRect().height;
                    var nChangeHeight_Effective = nNewHeight_Effective - nOldHeight;
                    nSectionHeightChanged += nChangeHeight_Effective;
                    nChangeHeight -= nChangeHeight_Effective;
                    if (Math.abs(nNewHeight - nNewHeight_Effective) >= 0.5) {
                        tmpMark.push(i2);   // mark as "no further correction possible"
                    }
                    ++nChangeCount;
                    
                    nSectionHeightResizable = this._calcResizableHeight(arrResizableSectionParts, tmpMark);
                    fChangeFactor = (nSectionHeightResizable+nChangeHeight)/nSectionHeightResizable;
                }
            }                    
        } while (nChangeCount > 0 && Math.abs(nChangeHeight) >= 0.5);

        // console.log("RESIZE", "nParentHeight", nParentHeight, "nSectionHeight", nSectionHeight, "nChangeHeight", nChangeHeight, nSectionHeightChanged);        
        return(nSectionHeightChanged);
    }

    _calcResizableHeight(arrResizableSectionParts, tmpMark) {
        var  nSectionHeightResizable = 0;

        for (var n=0; n < arrResizableSectionParts.length; ++n) {
            var elem = arrResizableSectionParts[n];
            var nTmpHeight = elem.getBoundingClientRect().height;                        
            if (nTmpHeight > 0 && !elem.classList.contains("no-resize") && (!tmpMark || tmpMark.indexOf(n) < 0)) {
                nSectionHeightResizable += nTmpHeight;
            }
        }        

        return(nSectionHeightResizable);
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _resize(event) {        
        this._pParent._displayResizeCursor(true);

        var y;
        if (event.pageY !== undefined) {
            // bei "MouseEvent"
            y = event.pageY;
        } else {
            // bei "TouchEvent"
            y = event.changedTouches[0].pageY;
        }

        var nChangeHeight = Math.min(y - this.getBoundingClientRect().top, this._pParent._calcResizableHeight(this._pParent._arrResizableSections[this._nIndex+1], undefined, true));
        var nChangeHeight_1 = this._pParent._doSizeChange( nChangeHeight, this._pParent._arrResizableSections[this._nIndex+0]);
        var nChangeHeight_2 = this._pParent._doSizeChange(-nChangeHeight_1, this._pParent._arrResizableSections[this._nIndex+1]);
        var nChangeHeight_3 = nChangeHeight_1 + nChangeHeight_2;
        if (Math.abs(nChangeHeight_3) >= 0.5) { 
            this._pParent._doSizeChange( 
                -nChangeHeight_3, 
                this._pParent._arrResizableSections[this._nIndex+0]
            );
        }
    }

    // event Handler-function, called, BOUND to "currentResizer"
    _stopResize(event) {
        ['mousemove', 'touchmove', 'mouseup'].forEach((name) => {
            // we remove listeners for window-referencing callbacks (which are setup when "resize"-mark is clicked!)
            this._eventCallbacksWindow.forEach((cb) => {
                window.removeEventListener(name, cb);
            });
            this._eventCallbacksWindow = [];
        });
        this._pParent._displayResizeCursor(false);
    }

    /**
     * Remove Event Handlers
     */
    _cleanUp() {
        this._arrResizers.forEach((currentResizer, idx) => {
            ['mousedown', 'touchstart'].forEach((szEventName) => {
                currentResizer._eventCallbacks.forEach((cb) => {
                    currentResizer.removeEventListener(szEventName, cb);
                })
                currentResizer._eventCallbacks = [];
            });
        }); 
        
        this._arrResizableSections = [];
        this._arrResizers = [];
    }
}
import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class CrcUsers_Entry_01 extends CrcHtmlProcessorEntry {

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
 
        return(<span id={this.getIdDOM()}/>);
    }
}
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import { doc } from "firebase/firestore";

export default class qryProcessor_Family extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super(props);
        
        this.m_szFamilyId = props.szFamilyId?props.szFamilyId:props.szTriggerId;        
        
        // console.log(this.getClassName(), "constructor", this.m_szFamilyId);              
    }

    getClassName() {
        return("qryProcessor_Family");
    }

    getFamilyId() {        
        return this.m_szFamilyId;
    }
    
    getCollectionPath() {
        return('families');
    }
    
    buildQuery() {        
        return(doc(this.getWfCtrl().getFirestore(), this.getCollectionPath(), this.m_szFamilyId));
    }
}

import React from 'react';
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import DeleteIcon from '@material-ui/icons/Delete';

export default class CrcTools_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div style={{ height: "auto", width: "auto", ...this.props.style, backgroundColor: this.state.overDelete?"blue":"" }}>
                <DeleteIcon
                    onDrop= {(event) => { event.preventDefault(); console.debug(this.constructor.name, "onDelete"); if (this.props.onDelete) this.props.onDelete(event); else console.debug(this.constructor.name, "onDelete", "NOPE"); } } 
                    onDragOver= {(event) => { this.setState({ overDelete: true }); event.preventDefault(); }} 
                    onDragLeave={(event) => { this.setState({ overDelete: false }); }}
                    />
           </div>
      );
    }
}
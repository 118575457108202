import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import qryProcessor from "./fabbi_qry_processor.js";
import qryProcessor_CloudFirestore from "./fabbi_qry_processor_cloudfirestore.js";
import qryProcessor_Objectives from "./fabbi_qry_processor_objectives.js";
import HtmlProcessor_Missions from "./fabbi_htmlprocessor_missions.js";

export default class qryProcessor_Missions extends qryProcessor_CloudFirestore {    
    constructor(props) {
        super({ 
                htmlProcessorPrototype: HtmlProcessor_Missions, 
                BuildAll_ifDivSectionMissing: false,
                ...props
            });                
        this.m_szFamilyId = props.szFamilyId?props.szFamilyId:props.szTriggerId;      
    }

    // Overrides existing!
    getClassName() {
        return("qryProcessor_Missions");    // to FIX the classname!
    }
    
    getFamilyId() {        
        return this.m_szFamilyId;
    }

    getChildQP_Objectives(szMissionId) {        
        return(this.getSingleChildQP("qryProcessor_Objectives" + ".*" + szMissionId));        
    }        
    
    getMisCount() {
        return(this.m_statistics.m_data.count);
    }     
    
    getId_LastMission() {
        return(this.m_statistics.m_data.szLastAddedId);
    }
    
    getLastMission() {
        let szId = this.getId_LastMission();                
        return({ szId: szId, entry: this.getPrivateDataPool().getDataEntry(szId) });
    }
    
    getCollectionPath() {
        return('families/' + this.getFamilyId() + '/missions');
    }        
    
    onRun() {                
        this.getPrivateDataPool().getDataMap().filter((entry) => {                                    
            // ".*" is the RegExp-pendant to a wildcard ("*") ... so we look for something ending with a specific mission-id (entry["id"])
            return(entry["state"] === "added" && entry["pending_onRun"] === "y" && !this.hasChildQP(".*" + entry["id"]));
        }).forEach((entry) => {
            var obj = 
                new qryProcessor_Objectives({
                    wfCtrl: this.getWfCtrl(), 
                    triggering_qryProcessor: this, 
                    szFamilyId: this.getFamilyId(), 
                    szMissionId: entry["id"],
                    szTriggerId: entry["id"] 
                });            
            this.addChildQP(obj);            
        });
        
        return(super.onRun());
    }        
    
    handleQryProcessorEvent(event) {        
        super.handleQryProcessorEvent(event);
                
        // console.log(this.constructor.name, "handleQryProcessorEvent", "ANGEKOMMEN 1");
        
        // we change, if new messages concerning the family were incoming ...
        if (event.detail.sender.constructor.name === qryProcessor_Objectives.prototype.constructor.name) { 
            // console.log(this.constructor.name, "handleQryProcessorEvent", "ANGEKOMMEN 2");
            
            if (event.detail.szSubType === qryProcessor.EVENT_subType_reportOnRunCompleted) {
                // console.log(this.constructor.name, "handleQryProcessorEvent", "ANGEKOMMEN 3");
                
                var qP = event.detail.sender;
                var szMissionId = qP.getMissionId();
                // var szFamilyId = qP.getFamilyId();
                
                // console.log(this.constructor.name, "handleQryProcessorEvent", "ANGEKOMMEN 4 concerning Mission:", szMissionId);
                
                // we are in "qryProcessorBundle_Family": a new message or e. was recognized, so we update the chat-list!
                if (this._hasHtmlProcessor()) {
                    this.useHtmlProcessors()?.prepareAndProcessEntry(szMissionId);
                }
            }
        }    

    }    
}

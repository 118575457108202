import React from 'react';
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import {getVisibleSelectionRect} from "draft-js";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DatePicker from '@material-ui/lab/DatePicker';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TodayIcon from '@material-ui/icons/Today';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import {createTheme, ThemeProvider } from '@material-ui/core/styles';
import {matchSorter} from 'match-sorter';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import { PictureInPictureTwoTone, TrendingUpSharp } from '@material-ui/icons';
// or @material-ui/lab/Adapter{DateFns,DayJS,Luxon,Moment} or any valid date-io adapter
import AdapterMoment from '@material-ui/lab/AdapterMoment';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import moment from 'moment';
import deLocale from 'moment/locale/de';    // ATTENTION! THIS IS ESSENTIAL ... 
import Crc_SC_AutocompleteSelect_01 from './fabbi_crc_sc_autocomplete_select_01.jsx';
import Crc_SC_DatePicker_01 from './fabbi_crc_sc_datepicker_01.jsx';
import Crc_SC_TrrSelect_01 from './fabbi_crc_sc_trrselect_01.jsx';
import Crc_SC_WeightsSelect_01 from './fabbi_crc_sc_weights_select_01.jsx';
import * as FabStdBro from "./fabbi_standardbrowser.js";

const smallMP = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: { 
                    background: "white"
                }    
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    padding: "0px !important",
                    color: "black !important"
                },
                inputRoot: {
                    // height: "251px"     // WORKS !
                    fontFamily: "Inconsolata, Menlo, Consolas, monospace",
                    background: "wheat",
                    padding: "0px !important"
                },
                inputFocused: {
                    // color: "orange",
                    borderBottom: "none !important",
                    boxShadow: "none !important"
                },
                input: {
                    color: "red",
                    padding: "0px 4px 0px 6px !important"
                    // marginBottom: "5px",
                },
                listbox: {
                    fontFamily: "Inconsolata, Menlo, Consolas, monospace"
                },
                option: {
                    height: "1.0em",
                    color: "yellow",
                    '&[data-focus="true"]': {
                            color: "yellow"
                    },
                    "&:dataFocus": {
                        color: "blue"
                    }
                },
                optionFocused: {
                    color: "green"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {  
                root: {
                    '&[data-shrink="false"]': {
                        transform: "translate(14px, 11px) scale(1)"
                    },
                    color: "greenyellow"
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: { 
                    // works!
                    fontFamily: "Inconsolata, Menlo, Consolas, monospace",
                    background: "wheat"
                },
                input: {
                    borderBottom: "none !important",
                    boxShadow: "none !important",
                    height: "auto !important",
                    margin: "8px !important"
                }
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: "0px",
                    color: "black !important"
                    // marginLeft: "5px",
                    // marginRight: "5px",
                    // paddingLeft: "5px",
                    // paddingRight: "5px",
                    // paddingBottom: "0px"
                    // height: "auto !important",
                    // color: "black"
                },
                inputFocused: {
                    color: "magenta"
                },
                input: {
                    color: "red",
                    borderBottom: "none !important",
                    boxShadow: "none !important",
                    height: "auto !important",
                    margin: "8px !important",
                    // marginBottom: "5px",
                    // border: "5px solid #0000ff",
                    "&:hover": {
                        color: "orange"
                    }
                    // ,
                    // "&:not($focused)": {
                    //    color: "white"
                    // }
                }
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                input: {
                    color: "blue",
                    // padding: "0px 4px 0px 6px !important"
                    borderBottom: "none !important",
                    boxShadow: "none !important",
                    height: "auto !important",
                    paddingBottom: "25px !important",
                    // margin: "8px !important",
                    // marginBottom: "5px",
                    // border: "5px solid #0000ff",
                    "&:hover": {
                        color: "green"
                    }
                    // ,
                    // "&:not($focused)": {
                    //    color: "white"
                    // }
                }
            }
        }
    }
});

export default class CrcModalSelect_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._dlgConfig = {};
        this._dlgData = {};

        this.icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        this.checkedIcon = <CheckBoxIcon fontSize="small" />;
        this._timerEnter = undefined;

        this._refAutocomplete = React.createRef();
        this._refTextfield = React.createRef();
        this._refListbox = React.createRef();

        this.arrResult_OptionsSelected = null;

        this.state.multiple = false;
        this.state.open = false;
        this.state.type = "";
        this.state.inputPlaceholderAndLabel_base = "";
        this.state.valTextfield = null;
        this.state.arrOptionsFull = []; // [ {label: "das erste", index: 1}];
        this.state.arrDefaultOptionsSelected = [];
        this.state.idxOptionKeyboardFocused = -1;
        this.state.maxDate = null;
        this.state.minDate = null;
        this.state.datValue = null;

        /*
        this.state.filterOptions = createFilterOptions({
            matchFrom: 'start',
            stringify: option => option.title
        });
        */

        this._init();
    }

    componentDidMount() {
        super.componentDidMount();

        // console.log(this.constructor.name, "_refTextfield", this._refTextfield.current);
        // this._refTextfield.current.value = "select";
        // console.log(this.constructor.name, "_refTextfield", this._refTextfield.current);
        // this._refTextfield?.current?.focus(); // focus on

        console.log(this.constructor.name, "componentDidMount()", this.getParentDOMElement());

        /*
        var dom = this.getParentDOMElement();
        var pxMove = -500;
        var pxLeftFrom = dom.offsetLeft;
        var pxLeftTo = dom.offsetLeft + pxMove;

        var szAnim = 
                "0%   {left: " + pxLeftFrom  + "px; width: " + (dom.offsetWidth + 0     ) + "px } " + 
                "100% {left: " + pxLeftTo    + "px; width: " + (dom.offsetWidth - pxMove) + "px } ";
        var szAnimName = this.getIdDOM() + "_anim";

        FabStdBro.addAnimation(szAnimName, szAnim);                  
        
        var duration = 0.5;
        var delay = 0
        var szAnimCmd = szAnimName + " " + duration + "s" + " ease " + delay + "s 1 normal forwards running";
        dom.style.animation = szAnimCmd;

        FabStdBro.freeAnimation(szAnimName);                  

        console.log(this.constructor.name, "componentDidMount()", "anim", szAnim, "animCmd", szAnimCmd);
        */
    }

    handleClickAway() {
        console.log(this.constructor.name, "HANDLE CLICK AWAY");
        this.closeDialog(false);
    }

    getScriptPositionInfo() {
        return(this.props.scriptPositionInfo);
    }

    closeDialog(bNotify_onChange, infoOnChange) {
        setTimeout(() => {
            this.getParent().close();   // close modal-frame    
            if (bNotify_onChange || !!infoOnChange) { 
                // this.setResult(this.state.spi);
                console.debug(this.constructor.name, "closeDialog", "yyy", infoOnChange);
                this.onChange(infoOnChange);
            }
        });
    }

    onChange(infoOnChange) {
        // notify "onChange"
        if (this.props.onChange) {
            setTimeout(() => this.props.onChange(this, infoOnChange));
        }
    }

    getDialogType() {
        return(this._dlgConfig?.type);
    }

    renderInput() {
        var szDlgType = this.getDialogType();
        if (!szDlgType) szDlgType = "options_01";

        console.log(this.constructor.name, "renderInput", "DlgType", szDlgType);

        switch (szDlgType) {
            case "options_01":
                return(
                    <Crc_SC_AutocompleteSelect_01 
                        wfCtrl={this.getWfCtrl()}
                        autoFocus={true}
                        style={{width: "300px"}}

                        onSet={(crcChild, result) => this.onSet_01(crcChild, result) }
                        onCancel={(crcChild, cancelInfo) => this.onCancel_01(crcChild, cancelInfo) }
                        showButton_Set={true}

                        { ... this.getDialogCfg() }
                        { ... this.getDialogData() }
                    />
                );
            case "dateSelection_01": 
                return(
                    <Crc_SC_DatePicker_01 
                        wfCtrl={this.getWfCtrl()}
                        autoFocus={true}
                        onSet={(crcChild, result) => this.onSet_01(crcChild, result) }
                        onCancel={(crcChild, cancelInfo) => this.onCancel_01(crcChild, cancelInfo) }

                        { ... this.getDialogCfg() }
                        { ... this.getDialogData() }
                    />
                    );
            case "trrSelection_01":
                return(
                    <Crc_SC_TrrSelect_01 
                        wfCtrl={this.getWfCtrl()}
                        onSet={(crcChild, result) => this.onSet_01(crcChild, result) }

                        { ... this.getDialogCfg() }
                        { ... this.getDialogData() }
                    />                    
                );

                case "weights_01":
                    return(
                        <Crc_SC_WeightsSelect_01 
                            wfCtrl={this.getWfCtrl()}
                            onSet={(crcChild, result) => this.onSet_01(crcChild, result) }
    
                            { ... this.getDialogCfg() }
                            { ... this.getDialogData() }
                        />                    
                    );
        }

        /*
        if (!this.getDlgType()) { 
            if (this.state.arrOptionsFull.length > 0) {
                return(
                    <Crc_SC_AutocompleteSelect_01 
                        style={{width: "300px"}}

                        onSet={(crcChild, result) => this.onSet_01(crcChild, result) }
                        onCancel={(crcChild) => console.log(this.constructor.name, "onCancel()", "crcChild", crcChild.constructor.name) }
                        showButton_Set={true}

                        open={this.state.open}
                        multiple={this.state.multiple}
                        valDefaultTextfield={this.state.valTextfield}
                        arrDefaultOptionsSelected={this.state.arrDefaultOptionsSelected}
                        arrOptionsFull={this.state.arrOptionsFull}
                    />
                );
                return(
                    <div style={{
                            display: "flex",
                            direction: "column",
                            boxPack: "justify",
                            justifyContent: "space-between"
                        }}>
                    <Crc_SC_AutocompleteSelect_01 
                        style={{width: "200px"}}
                        open={this.state.open}
                        showButton_Set={true}
                        onSet={(crc) => console.log(this.constructor.name, "onSet()", "crc", crc.constructor.name) }
                        onCancel={(crc) => console.log(this.constructor.name, "onCancel()", "crc", crc.constructor.name) }
                        multiple={this.state.multiple}
                        valDefaultTextfield={this.state.valTextfield}
                        arrDefaultOptionsSelected={this.state.arrDefaultOptionsSelected}
                        arrOptionsFull={this.state.arrOptionsFull}
                    />
                    <Crc_SC_AutocompleteSelect_01 
                        style={{width: "100px"}}
                        open={this.state.open}
                        showButton_Set={true}
                        onSet={(crc) => console.log(this.constructor.name, "onSet()", "crc", crc.constructor.name) }
                        onCancel={(crc) => console.log(this.constructor.name, "onCancel()", "crc", crc.constructor.name) }
                        multiple={!this.state.multiple}
                        valDefaultTextfield={this.state.valTextfield}
                        arrDefaultOptionsSelected={this.state.arrDefaultOptionsSelected}
                        arrOptionsFull={this.state.arrOptionsFull}
                    />
                    </div>
                );
            }
        } else {
            switch(this.state.type) {
                case "dateSelection": 
                    return(
                        <Crc_SC_DatePicker_01 
                            onSet={(crcChild, result) => this.onSet_01(crcChild, result) }
                            onCancel={(crcChild) => this.onCancel_01(crcChild) }

                            datMin={ this.state.datMin }
                            datMax={ this.state.datMax }
                            datValue={ this.state.datValue }
                        />
                        );
                case "trrSelection":
                    return(this.renderTRRSelection_04());
            }
        }

        return(this.renderSoloInput_02());
        */

        // if (!this.state.inputType.arrOptions || this.state.inputType.arrOptions?.length <= 0) return(this.renderSoloInput_02());
        // return(this.renderAutocompleteWithOptions_01());
    }

    onCancel_01(crcChild, cancelInfo) {
        console.log(this.constructor.name, "onCancel_01", "yyy", crcChild, cancelInfo);
    
        this.closeDialog(false, { restoreScriptPositionInfo: (cancelInfo?.reason === "Escape") });
    }

    onSet_01(crcChild, result) {
        var szAPC;
        var pcp;
        var spi = this.getScriptPositionInfo();

        if (!spi) return;

        console.log(this.constructor.name, "onSet_01", crcChild, "result", result);

        // key or val
        if (spi.getPart().isValRecognized()) szAPC = "val"; else szAPC = "key";

        // spi.setActivePartComponent(szAPC);
        pcp = spi.getPartComponentProcessor(szAPC);
        if (pcp) {
            pcp.setResultSelectOptions(result);
            pcp.endUserDialog();
        }

        this.closeDialog(true);
    }

    _init() {
        var spi = this.getScriptPositionInfo();
        var szAPC;
        var pcp;

        // console.log(this.constructor.name, "_init()");
        if (!spi) return;

        // key or val
        if (spi.getPart().isValRecognized()) szAPC = "val"; else szAPC = "key";
        // console.log("szAPC", szAPC, "hasII", spi.getPart().isValRecognized(), spi.getPart());

        // spi.setActivePartComponent(szAPC);
        pcp = spi.getPartComponentProcessor(szAPC);
        if (pcp) {
            pcp.startUserDialog();

            var result = pcp?.buildSelectOptions();
            var cfg = pcp?.getCfgUserDialog();
        }

        this.setDialogCfg(cfg);
        this.setDialogData(result);
    }

    /** */
    setDialogCfg(cfg) {
        Object.assign(this._dlgConfig, cfg);
    }

    /** */
    getDialogCfg() {
        return(this._dlgConfig);
    }

    /** */
    setDialogData(data) {
        Object.assign(this._dlgData, data);
    }

    /** */
    getDialogData() {
        return(this._dlgData);
    }

    getSpiKeyUtterance() {
        if (this.state._spi_part && this.getSpiMode() === "val") {
            return(this.state._spi_part.getKey());
        }
    }

    getSpiMode() {
        return(this.state.spiMode);
    }

    getInputPlaceholderAndLabel() {
        if (this.state.multiple && this.arrResult_OptionsSelected?.length > 0) {
            return(this.state.inputPlaceholderAndLabel_base + " (" + this.arrResult_OptionsSelected.length + ")");
        } else {
            return(this.state.inputPlaceholderAndLabel_base);
        }
    }

    render() {
        // var rect = getVisibleSelectionRect(window);

        // { this.renderAutocompleteWithOptions_01() }
        // { this.renderSoloInput_02() }
        return(
            <div id={this.getIdDOM()} className={""} style={{paddingTop: "2px" }}>
                <ThemeProvider theme={smallMP}>
                    { this.renderInput() }         
                </ThemeProvider>    
            </div>
        );
    }

    renderTRRSelection_04() {
        return( 
            <Autocomplete
                disableClearable={true}
                forcePopupIcon={false}
                popupIcon={undefined}
                closeIcon={undefined}

                // key={index}
                // autoComplete={true}
                // autoHighlight={false}
                // autoSelect={false}
                ref={this._refAutocomplete}
                ListboxProps={{ref: this._refListbox }}
                filterSelectedOptions={false}
                multiple={false}
                disableListWrap
                clearOnEscape={true}
                disableCloseOnSelect={false}
                limitTags={1}
                id={  this.getInputId_01() }
                filterOptions={ (arrOptionsFull, param) => this.filterOptions_01(arrOptionsFull, param) }
                options={ this.state.arrOptionsFull }
                getOptionLabel={ (option) => { return((option && option.getLabel)?option.getLabel():""); } }
                getOptionSelected={ (opt1, opt2) => opt1.compare(opt2) }
                renderGroup={(params) => this.renderGroup(params) }
                groupBy={(option) => this.groupBy(option) }
                style={{ width: 300 }}
                inputValue={ this.state.valTextfield }
                open={this.state.open}
                value={ this.state.optSelected }
                onClose={(event) => { console.log(this.constructor.name, "close ordered", "event", event); } }
                onOpen={(event) => { this.setState({ open: true }); }}
                onChange={(event, arrOptionsSelected, reason) => this.onChangeSelectedOptions_01(event, arrOptionsSelected, reason) }
                // renderTags={((value, getTagProps) => this.renderTags_01(value, getTagProps)) }
                renderInput={(params) => this.renderInput_01(params) }
                renderOption={(option, info) => this.renderOption_01(option, info) }               
            />
        );  
    }

    renderSoloInput_02(params) {
        return(
            <TextField
                size="small"
                inputRef={this._refTextfield} 
                label={this.getInputPlaceholderAndLabel() }
                value={this.state.valTextfield}
                variant="outlined"

                onKeyDown={(e) => {
                    console.log(this.constructor.name, "onKeyDown", e, e.key);
                    
                    if (e.key === "ArrowUp") {
                    }
                    if (e.key === "ArrowDown") {
                    }
                    
                    if (e.key === "Escape") {
                        console.debug(this.constructor.name, "closeDialog", "yyy", "ESCAPE", "launching closeDialog");
                        this.closeDialog(false, { restoreScriptPositionInfo: true });
                    }

                    if (e.key === "Enter") {
                        // e.stopPropagation();
                        // e.preventDefault();
                        console.log(this.constructor.name, "closeDialog", "reason", "ENTER");
                        this.closeDialog(true);
                    }
                }} 

                onChange={(event) => {
                    console.log(this.constructor.name, "TEXTFIELD", "onChange", event.target.value);
                    this.setState({valTextfield: event.target.value });
                }}/>
        );
    }
}
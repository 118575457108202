import React from 'react';
import CrcHtmlProcessorEntry from './fabbi_crc_htmlprocessor_entry.jsx';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import { grey } from '@material-ui/core/colors';
import { SaveAlt } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Tab from '@material-ui/core/Tab';
import { Timestamp } from "firebase/firestore";

export default class CrcScript_Entry_01 extends CrcHtmlProcessorEntry {
    componentDidMount() {
        super.componentDidMount();
        
        if (this.state.new) {

            var htmlElem = this.getDOMElement();

            if (htmlElem) {
                // if (this.el.getElementsByClassName("__sub")[0].style.animationIterationCount > 0
                FabStdBro.addAnimation("dummyAnim", "0% { opacity: 100%;} 100% { opacity: 0%;}");
                // this.el.getElementsByClassName("__sub")[0].style.animationTimingFunction = "linear";

                // animation-name               animationname
                // animation-duration           5s
                // animation-timing-function    linear
                // animation-delay              5s
                // animation-iteration-count    5
                // animation-direction:         normal/alternate
                // animation-fill-mode          forwards
                // animation-play-state         running
                htmlElem.style.animation = "dummyAnim" + " " + "0.5s" + " linear 0s 10 alternate forwards running";
            }
        }        
    }

    renderEntry(edb) {      
        var dataset = edb.dataset;
        var datasetExtra = edb.datasetExtra;
        var szDelay="";

        // console.debug(this.constructor.name, "renderEntry", "setActiveIdEntry", this.getIdEntry(), this.isActiveEntry());

        var szAdded = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_added, "");
        // var szDeadline = FabStd.convertTimestampToLocaleDateTimeString(dataset.ts_deadline, "");
        /*
        var dataMsgstat = datasetExtra["data-msgstat"];
        var dataLastMsg = datasetExtra["data-last_msg"];
        var dataAddedBy = datasetExtra["data-added_by"];
        var szSenderImgSrc = !!dataAddedBy?dataAddedBy.image_src:"";
        var szSenderName = !!dataAddedBy?dataAddedBy.name:"";
        
        if (!dataMsgstat) dataMsgstat = {};
        if (!dataLastMsg) dataLastMsg = {};

        var szLstMsgAdded = FabStd.convertTimestampToLocaleDateTimeString(dataLastMsg.ts_added, "");
        */

        // console.log(this.constructor.name, dataLastMsg.content);

        if (dataset.ts_added) {
            if (((new Date()).getTime() - FabStd.convertTimestampToDate(dataset.ts_added).getTime()) < 60000) {
                this.state.new = true; // direct access to "state" !! important in this case!
            } else {
                this.state.new = false;
            }
        }

        var isActive = () => {
            return(this.isActiveEntry() || this.isAlwaysLinkedToActiveEntry());
        };

        // the function is INTENTIONALLY not bound ... it will be bound - lateron - to the html-element, the listener will be added to
        var fncClick = (e) => {
            console.log(this.constructor.name, "onClick", "event", e, "dataset", dataset); // , "zIndex", FabStdBro.getZIndex(this.getParentDOMElement()));

            if (isActive()) {
                // this.getWfCtrl().edit_gdtTsScript(this.getIdEntry(), this.getParentDOMElement(), this.getHtmlProcessor());
            } else {
                this.getWfCtrl().setSelectedId_gdtTsScript(this.getIdEntry());
                this.getHtmlProcessor().setActiveIdEntry(this.getIdEntry());
                console.log(this.constructor.name, "onClick", "setting active entry", this.getIdEntry());                
            
                // just update this entry
                this.update();
            }
        };

        // fetch userdata for members
        /*
        var userdata = this.getWfCtrl().m_bundleUsers.getSingleDataset(this.getIdEntry());
        var szName = "unknown_" + this.getIdEntry();
        
        if (!!userdata) {
            console.log("userdata", userdata);
            szName = userdata.name;
        } else {
            console.log("no userdata", "id", this.getIdEntry(), this.getWfCtrl().m_bundleUsers.getDataPool());
        }
        */

        // console.log(this.constructor.name, "renderEntry()", dataset);

        var renderResult;
        
        if (this.props.entryType === "tab") {
            renderResult =
                <Tab 
                    {...this.props}
                    label={dataset.name } 
                    value={this.getIdEntry()} 
                    draggable={true}
                    onDragStart={(event) => { console.debug(this.constructor.name, "onDragStart (3)");} }
                    onDragEnd={(event) => { console.debug(this.constructor.name, "onDragEnd (4)");} }
                    onClick={(e) => { 
                        // if (this.isActiveEntry()
                        console.debug("TabOnClick", e, "style", window.getComputedStyle(e.target)); 
                        /*
                        if (this.getIdEntry() === this.getHtmlProcessor().getCustomData().id_entry_active) {
                            this.getWfCtrl().edit_gdtTsScript(this.getIdEntry(), this.getParentDOMElement(), this.getHtmlProcessor());
                        }
                        */
                    }
                }/>;
        } else {
            renderResult = 
                <li id={this.getIdDOM()} 
                    className={
                        // (this.isAlwaysLinkedToActiveEntry()?"fab-list-entry-01-activeEntry":"fab-list-entry-01") + 
                        (isActive()?"fab-list-entry-01-activeEntry":"fab-list-entry-01") +
                        " animate fadeUp CSS_DELAY"
                    }  
                    onClick={(e) => fncClick(e)}
                    style={{
                            width: "inherit", 
                            display: "flex", 
                            flexDirection: "column", 
                            paddingLeft: "0rem",
                            paddingRight: "0rem",
                            paddingTop: "0.5rem",
                            paddingBottom: "0.5rem",
                            backgroundColor: isActive()?"lightGreen":undefined 
                        }}
                    >
                    <div style={{width: "inherit", display: "flex", flexDirection: "column"}}>
                        <div style={{paddingLeft: "0.5rem", width: "inherit", display: "flex", flexDirection: "row" }}>
                            <div style={{width: "3.5rem", flexShrink: 0, flexGrow: 0}}>
                                <CrcImageOrInitials_01 wfCtrl={this.getWfCtrl()} image_src={dataset.name} long_name={dataset.name}/>
                            </div>
                            <div style={{flexGrow: 1, marginRight: "1rem"}}>
                                <p className={"blue-grey-text text-darken-4 font-weight-700"}>{dataset.name}</p>
                            </div>
                            <div style={{width: "3rem", padding: "0rem", flexShrink: 0, flexGrow: 0}}>
                                <Tooltip title="save active scriptcode here" interactive="true">
                                    <IconButton>
                                        <SaveAlt 
                                            onClick={(e) => { 
                                                console.debug("CLICKED");
                                                e.stopPropagation();
                                                e.preventDefault(); 
                                            }}></SaveAlt></IconButton>
                                </Tooltip>
                            </div>            
                        </div>
                    </div>

                    <div style={{ 
                            display: "flex", 
                            paddingRight: "1rem",
                            flexDirection: "row", 
                            flexWrap: "nowrap", 
                            justifyContent: "right", 
                            alignItems: "flex-end"
                            }}>
                        <span className={"fab-time-01"}>{szAdded}</span>
                    </div>

                </li>
                ;                    
            }
        return(renderResult);
    }
}
import React from 'react';
import ControlledReactComponent from "./fabbi_controlled_react_component.js";

export default class CrcWait_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);
    }

    render() {
        return(

            <div className="preloader-wrapper big active" style={{ height: "inherit", width: "inherit", ...this.props.style}}>
                <div className="spinner-layer spinner-blue">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
            
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>

                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>

                <div className="spinner-layer spinner-red">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>

                <div className="spinner-layer spinner-yellow">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>

                <div className="spinner-layer spinner-green">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="gap-patch">
                        <div className="circle"></div>
                    </div>
                    
                    <div className="circle-clipper right">
                        <div className="circle"></div>
                    </div>
                </div>

            </div>
      );
    }
}
import React from 'react';
import ControlledReactComponent from './fabbi_controlled_react_component.js';
import CrcImageOrInitials_01 from "./fabbi_crc_image_or_initials_01.jsx";
import * as FabStdBro from "./fabbi_standardbrowser.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";

export default class CrcCarouselItem_01 extends ControlledReactComponent {

    componentDidMount() {
        super.componentDidMount();
        // console.log(this.constructor.name, "componentDidMount");
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this._removeListener_DragAction();
    }

    _reportDragAction(szDragAction, dragevent) {
        this.getParent()._reportItemDragAction(szDragAction, dragevent, this);
    }

    _setListener_DragAction() {
        if (!this._listenerDragstart) {
            this._listenerDragstart = 
                (e) => { 
                    if (!this.getParent()._isDragstartAllowed()) {
                        // console.log("DRAGSTART_ITEM", "is not allowed!"); 

                        // cancel dragstart
                        e.preventDefault();
                        e.stopPropagation();
                        return(false);
                    }
                    
                    // console.log("DRAGSTART_ITEM");
                    var src = this.getDOMElement(); 
                    var crt = src.cloneNode(true);
                    // src.classList.add("fab-highlight");
                    src.style.border = "thin solid #EE0000";
                    crt.style.opacity = 0.5;
                    document.body.appendChild(crt);
                    e.dataTransfer.setDragImage(crt, crt.clientWidth/2, crt.clientHeight/2);
                    this.bIsBeingDragged = true; 
                    this._reportDragAction("dragstart", e);
                    return(true);
                };

            this._listenerDragend= 
                (e) => {
                    this.bIsBeingDragged = false;
                    this._reportDragAction("dragend", e);    
                };
            this.getDOMElement().addEventListener("dragstart", this._listenerDragstart);
            this.getDOMElement().addEventListener("dragend", this._listenerDragend);
        }
    }

    _removeListener_DragAction() {
        if (this._listenerDragstart) {
            this.getDOMElement().removeEventListener("dragstart", this._listenerDragstart);
            this.getDOMElement().removeEventListener("dragend", this._listenerDragend);
            this._listenerDragstart = undefined;
            this._listenerDragend = undefined;
            if (this.bIsBeingDragged) {
                this._reportDragAction("dragend_nolistener", undefined);
            }
        }
    }

    isActive() {
        return(!!this.bActive);
    }

    setActive(bActive) {
        // if undefined ... this shall be set to TRUE !
        this.bActive = (bActive === false ? false : true);

        if (this.bActive) {
            this._setListener_DragAction();
            this.getDOMElement().draggable = true;
        } else {
            this._removeListener_DragAction();
            this.getDOMElement().draggable = false;
        }
    }

    render() { 
        return(
            <React.Fragment>
                <div 
                    id={this.getIdDOM()} 
                    className="carousel-item" 
                    style={{ 
                        display: "flex", 
                        alignItems: "center", 
                        width: "65px", 
                        height: "65px", 
                        border: "thin solid #DDDDDD"
                        }}>
                    
                    <div style={{ height: "inherit", width: "inherit" }}>
                            
                        <CrcImageOrInitials_01 
                            wfCtrl={this.getWfCtrl()}
                            draggable="false" 
                            options={{className: "fab-thumb-carousel"}} 
                            image_src={this.props.data.image_src} 
                            long_name={this.props.data.long_name}
                            />
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }    
}
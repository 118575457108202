import StdMap from "/app-assets/js/fabstd/fabbi_stdmap.js";
import EventTarget from "./fabbi_eventtarget.js";
import dataPool_qryProcessor from "./fabbi_datapool_qryprocessor.js";
import qryProcessorStatistics from "./fabbi_qry_processor_statistics.js";
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import HtmlProcessor from "./fabbi_htmlprocessor.js";

export default class qryProcessor extends EventTarget {
    
    /*
    constructor(props) {
        var { 
            wfCtrl, 
            arrConfig_HtmlProcessor, 
            szTriggerId, 
            triggering_qryProcessor,
            bDirectlyRunProcessQueryAfterInitialization,  
            ...other } = props;
        this.m_wfCtrl = wfCtrl;

        // not given from outside, created on the run
        this.m_arrIdDomHtmlProcessor = [];  // Array

        
        // arrConfig_HtmlProcessor[0].prototype // DerivedFromHtmlProcessor
        // arrConfig_HtmlProcessor[0].props
        
        arrConfig_HtmlProcessor.forEach((cfgHP) => {
            this.addHtmlProcessorIdDom(
                this.getWfCtrl().addHidden(
                    cfgHP.prototype, 
                    { 
                        leadingDataPool: this.getPrivateDataPool(), 
                        ...cfgHP.props
                    }
                )
            )
        });
    }
    */

    constructor(
            props
            /*
            wfCtrl, 
            htmlProcessorPrototype, 
            triggering_qryProcessor, 
            szTriggerId, 
            objConfig,
            htmlProcessorProps 
            */
            ) {        
        super();

        var {
            wfCtrl                  , 
            htmlProcessorPrototype  ,
            htmlProcessorProps,
            triggering_qryProcessor ,
            szTriggerId, 
            ...rest          
        } = props;

        this.m_wfCtrl = wfCtrl;        
        this.m_triggering_qryProcessor = triggering_qryProcessor;                
        this.m_szTriggerId = szTriggerId; 

        this.m_objConfig = !rest?{}:rest;

        this.m_arrIdDomHtmlProcessor = [];  // Array

        // console.log("qryProcessor-base", "start");
        
        if (this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization === undefined) {
            // default: is "true"
            this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization = true;
        } else {
            this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization = !!this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization;
        }

        this.m_PDP = new dataPool_qryProcessor(this, null);    // private DataPool // null means: will be set later on!                
        this.m_smapRequestForReport = new StdMap();
        this.m_smapBundlesJoined = new StdMap();
        this.m_smapChildQP = new StdMap();       // Sub-ChildProcessor-s
        // this.m_HtmlProcessor = null;            
        
        this.m_subscription = null;        
        
        this.m_bNewDataIncoming = false;
        this.m_bNewDataIncomingCompleted = false;
        this.m_bNewDataProcessing = false;
        this.m_bNewDataProcessingCompleted = false;
        this.m_bRun = false;
        this.m_bRunCompleted = false;
        
        this.m_nCount_NewDataIncomingCompleted = 0;
        this.m_nCount_NewDataProcessingCompleted = 0;
        this.m_nCount_RunCompleted = 0;
        
        // After NewDataProcessing is finished ... it's time to run!
                 
        this.m_bStartUpCompleted = false;  
        this.m_nCountEvent_DataChanged = 0;                   

        this.m_LastError = null;        // no error
        this.m_bTerminating = false;    // the qryProcessor shall be ended as soon as possible!

        this.setLastError(null);
        this.setTerminating(false);
        this.setStatistics();
        
        this.getPrivateDataPool().setId(this.getId());

        this.addEventListener(qryProcessor.EVENT, (event) => { this.handleQryProcessorEvent(event); });                
    
        // this.getWfCtrl().setupHtmlProcessor(props);
        if (htmlProcessorPrototype) {
            this.addHtmlProcessorViaIdDom( 
                this.getWfCtrl().addHidden(
                    htmlProcessorPrototype, 
                    { 
                        leadingDataPool: this.getPrivateDataPool(), 
                        ...htmlProcessorProps
                    }
                )
            );
        }

        // inform workflow-control about new qryProcessor
        this.sendQryProcessorEvent(this.getWfCtrl(), qryProcessor.EVENT_subType_reportStartUp);

        console.log(this.getClassName(), "directly run", this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization );
        setTimeout(() => { this.init(); }, 0);
    }

    getTopHtmlProcessor() {
        if (!this._hasHtmlProcessor()) return(undefined);
        
        // the "top" HtmlProcessor ist the one at Index 0
        return(this.getWfCtrl().getCRC(this.m_arrIdDomHtmlProcessor[0]));
    }

    useHtmlProcessors() {
        if (!this._hasHtmlProcessor()) return(undefined);
        
        var arrTmp = this.m_arrIdDomHtmlProcessor.map((id_dom) => { 
            var hp = this.getWfCtrl().getCRC(id_dom);
            if (!hp) console.log(this.constructor.name, "HP-NOT-READY:", id_dom); else console.log(this.constructor.name, "HP-READY", id_dom, hp);
            return(hp); 
        }).filter((hp) => !!hp);

        if (arrTmp.length < 1) return(undefined);

        // the functions of the returned "MutliUsable" can be called
        // like it was ONE 
        var mu = FabStd.arrayToMultiUsable(arrTmp);

        console.log("multiUsable", mu);
        return(mu);

        // console.log(this.constructor.name, "getHtmlProcessor", this._id_dom_htmlProcessor, "crc", this.getWfCtrl().getCRC(this._id_dom_htmlProcessor));
        // return(this.getWfCtrl().getCRC(this._id_dom_htmlProcessor));
    }

    addHtmlProcessorViaIdDom(id_dom_htmlProcessor) {
        this.m_arrIdDomHtmlProcessor.push(id_dom_htmlProcessor);
    }

    removeHtmlProcessorViaDom(id_dom_htmlProcessor) {
        this.m_arrIdDomHtmlProcessor = this.m_arrIdDomHtmlProcessor.filter((id) => id !== id_dom_htmlProcessor);
    }

    getFirstDataset(optional_toReturn_onErrorNullOrUndefined) {
        var pdp = this.getPrivateDataPool();
        var szDatasetId = pdp.getFirstDataIdEntry();
    
        if (!szDatasetId) return(optional_toReturn_onErrorNullOrUndefined);
        return(this.getSingleDataset(szDatasetId, optional_toReturn_onErrorNullOrUndefined));
    }
    
    getSingleDataset(szDatasetId, optional_toReturn_onErrorNullOrUndefined) {
        var pdp = this.getPrivateDataPool();
        var entry = pdp.getDataEntry(szDatasetId, false);
        if (!entry) return(optional_toReturn_onErrorNullOrUndefined);

        var dataset = entry["dataset"];
        if (!dataset) return(optional_toReturn_onErrorNullOrUndefined);
        
        return(dataset);
    }
    
    getSingleDatasetField(szDatasetId, szDatafieldName, optional_toReturn_onErrorNullOrUndefined) {
        var result = optional_toReturn_onErrorNullOrUndefined;
        try {
            var ds = this.getPrivateDataPool().getDataEntry(szDatasetId, false)["dataset"];
            result = ds[szDatafieldName];        
        } catch(e) {            
        }
        
        if (!result) {
            return(optional_toReturn_onErrorNullOrUndefined);
        }        
        return(result); 
    }      

    setStatistics() {
        this.m_statistics = new qryProcessorStatistics(this);
    }

    setLastChecked(tsLastChecked, func_deliverTsLastChecked) {        
        this.m_statistics.setLastChecked(tsLastChecked, func_deliverTsLastChecked);
    }

    getStatistics() {
        return(this.m_statistics);
    }
    
    sendQryProcessorEvent(destination, szEventSubType) {
        if (!!destination) {
            var event = 
                new CustomEvent(qryProcessor.EVENT, {                                
                    detail: {
                        sender:     this,
                        receiver:   destination,
                        szSubType:  szEventSubType
                    }
                });
            destination.dispatchEvent(event);
        }
    }
    
    handleQryProcessorEvent(event) {        
        /*
        console.log(this.getClassName(), "handleQryProcessorEvent", event);
                
        switch (event.detail.szSubType) {
            case qryProcessor.EVENT_subType_reportOnNewDataIncomingCompleted:
                this.privOnNewDataIncomingCompleted();
                break;
            case qryProcessor.EVENT_subType_reportOnNewDataProcessingCompleted:
                this.privOnNewDataProcessingCompleted();
                break;                
            case qryProcessor.EVENT_subType_reportOnRunCompleted:
                this.privOnRunCompleted();
                break;                
        }
        */
    }

    // **************************************************
    // **************************************************
    // stage 1: data incoming
    // **************************************************
    privOnNewDataIncoming() {
        this.m_bNewDataIncoming = true;
        this.m_bNewDataIncomingCompleted = false;
        
        if (this.onNewDataIncoming()) {
            this.privOnNewDataIncomingCompleted();
        }
    }    
    
    privOnNewDataIncomingCompleted() {
        this.m_bNewDataIncoming = false;
        this.m_bNewDataIncomingCompleted = true;
        ++this.m_nCount_NewDataIncomingCompleted;
        
        var bDirectlyStartNextStep = this.onNewDataIncomingCompleted();
        
        this.broadcastEventReport(qryProcessor.EVENT_subType_reportOnNewDataIncomingCompleted);
        
        if (bDirectlyStartNextStep) {
            // console.log(this.getClassName(), "privOnNewDataIncomingCompleted", "directly start privOnNewDataProcessing()");
            this.privOnNewDataProcessing();
        } else {
            // console.log(this.getClassName(), "privOnNewDataIncomingCompleted", "plan to start privOnNewDataProcessing()");
            this.planNewDataProcessing();
        }                
    }
    
    // **************************************************
    // stage 2: data processing
    // **************************************************
    privOnNewDataProcessing() {
        this.m_bNewDataProcessing = true;
        this.m_bNewDataProcessingCompleted = false;
        
        if (this.onNewDataProcessing()) {
            this.privOnNewDataProcessingCompleted();
        }
    }
    
    privOnNewDataProcessingCompleted() {
        this.m_bNewDataProcessing = false;
        this.m_bNewDataProcessingCompleted = true;
        ++this.m_nCount_NewDataProcessingCompleted;
        
        var bDirectlyStartNextStep = this.onNewDataProcessingCompleted();
                
        this.broadcastEventReport(qryProcessor.EVENT_subType_reportOnNewDataProcessingCompleted);
        
        if (bDirectlyStartNextStep) {
            this.privOnRun();
        } else {
            this.planRun();
        }
    }
    
    // reportFromTriggeredClass_NewDataProcessingCompleted() {                
    // }

    // **************************************************
    // stage 3: after data processing ... it's time to run
    // **************************************************
    privOnRun() {
        // console.log(this.constructor.name, "privOnRun"); 

        this.bRun = true;
        this.bRunCompleted = false;
        
        if (this.onRun()) {            
            this.privOnRunCompleted();
        }
    }

    privOnRunCompleted() {
        // console.log(this.constructor.name, "privOnRunCompleted"); 

        this.bRun = false;
        this.bRunCompleted = true;
        ++this.m_nCount_RunCompleted;                
        
        this.onRunCompleted();
        
        // console.log(this.constructor.name, "privOnRunCompleted", "broadcasting"); 
        this.broadcastEventReport(qryProcessor.EVENT_subType_reportOnRunCompleted);
    }
    // **************************************************
    // **************************************************    
    datasetChanged(szDatasetId, dataset, szChangeType) {                        
        var entry = this.getPrivateDataPool().getDataEntry(szDatasetId, true);
        
        if (szChangeType === "modified" && entry.state === "empty") szChangeType = "added";                    
        
        switch(szChangeType) {
            case "added":                  
                entry["id"] = szDatasetId;
                entry["id_qp"] = this.getId();   // set the id of the query-processor (seems to be useful in some cases)                              
                entry["dataset"] = dataset;                
                entry["state"] = "added";                 
                break;
                
            case "modified":
                entry["dataset_old"] = entry["dataset"];
                entry["dataset"] = dataset;
                entry["state"] = "modified";
                break;
                
            case "removed":
            case "deleted":                
                entry["dataset_old"] = entry["dataset"];
                entry["dataset"] = dataset;
                entry["state"] = "deleted";
            break;

            case "empty":   // ok
                break;
                
            default:
                console.log(this.getClassName(), "datasetChanged", "unknown change type: " + szChangeType, "Id: ", szDatasetId);
                break;                
        }
        
        // mark entry as "pending" for "onRun"
        entry["pending_onRun"] = "y";
        entry["pending_broadcast"] = "y";
    }    
    // **************************************************
    // **************************************************    
        
    planNewDataIncoming() {
        this.m_wfCtrl.planNewDataIncoming(this);
    }

    onNewDataIncoming() {
        return(true);
    }
    
    onNewDataIncomingCompleted() {
        return(true);
    }
        
    planNewDataProcessing() {
        this.m_wfCtrl.planNewDataProcessing(this);
    }    
    
    onNewDataProcessing() {
        return(true);
    }
    
    onNewDataProcessingCompleted() {
        return(true);
    }
            
    planRun() {
        this.m_wfCtrl.planRun(this);
    }

    // doProcessing_OnRun 
    // starts an iteration through all changed ("added", "modified", "deleted") entries
    // and launches callback-functions for each.
    // 
    // The parameterizable callback-functions 
    //      optional_callbackFunction_processEntry_OnRun        => if not provided: nothing special will be done
    //      and the 
    //      optional_callbackFunction_processEntry_Statistics   => if not provided: simply the standard "m_Stat.tsaUpdates_ProcessEntry"-Function will be called!
    // must have one parameter => here the entry-Id is provided!
    // Afer a full iteration-run, all "states" of the entries are reset to "" (empty)!
    doProcessing_OnRun(optional_callbackFunction_processEntry_OnRun, optional_callbackFunction_processEntry_Statistics) {                
        this.m_statistics.tsaUpdatesStart();
        
        this.getPrivateDataPool().getDataMap().filter((entry) => {            
            return(entry["pending_onRun"] === "y");
        }).forEach((entry) => {            
            var id_entry = entry["id"];   
            if (typeof optional_callbackFunction_processEntry_OnRun === 'function') {
                optional_callbackFunction_processEntry_OnRun(id_entry);                        
            } else {
                this.processEntry_OnRun(id_entry, entry);
            }
            if (typeof optional_callbackFunction_processEntry_Statistics === 'function') {
                optional_callbackFunction_processEntry_Statistics(id_entry);
            } else {
                // use standard-implementation ... if no special function was supplied
                this.m_statistics.tsaUpdates_processEntry(id_entry);
            }
                        
            // "onRun" is no longer pending for this entry!
            entry["pending_onRun"] = "n";
            // go through HtmlProcessors
            this.m_arrIdDomHtmlProcessor.map((id_dom) => { 
                var hp = this.getWfCtrl().getCRC(id_dom);
                if (hp) hp.reportLeadingEntryDirty(id_entry);
            });
        });
        this.m_statistics.tsaUpdatesEnd();
    }

    // onRun is called (via message queue) after new data arrived and is ready        
    onRun() {        
        // by default, we "doProcessing" only, if this instance doesn't belong to a bundle        
        if (this.m_smapBundlesJoined.length === 0) {
            // console.log(this.getClassName(), "onRun", "this is not a bundle-member");
            this.doProcessing_OnRun(null, null);
        } else {
            // console.log(this.getClassName(), "onRun", "this is a bundle-member");
        }
        return(true);
    }    
    
    // is called for all changed ("added", "modified", "deleted") ... to override
    processEntry_OnRun(id_entry, entry) {
    }
    
    onRunCompleted() {                        
        console.debug(this.getClassName(), "onRunCompleted()");

        // console.log(this.constructor.name, "onRunCompleted", "before HtmlProcessor")
        // console.log(this.getClassName(), "Trigger", this.getTriggerId(), "onRunCompleted", "dataentries", this.getPrivateDataPool().getDataMap().length);
        
        // ***************************************************************
        // NOT FOR BUNDLES !!!
        // ***************************************************************
        // by default, we sync only, if this is not a member of a bundle !
        if (this.m_smapBundlesJoined.length === 0) {
            // console.log(this.getClassName(), "onRunCompleted");

            if (this.getDataPool()) {
                var PDP = this.getPrivateDataPool();                            
                // console.log(this.getClassName(), "onRunCompleted", "syncing with (Main)DataPool", "Private-DataPool-Id is: ", PDP.getId());                
                this.getDataPool().syncWithSubDataPool(PDP);
            }
        }
        
        // an render html (just if we have some htmlProcessors)
        this.onRenderHtml();                
    }
    
    _hasHtmlProcessor() {
        return(this.m_arrIdDomHtmlProcessor.length > 0);
    }

    onRenderHtml() {
        // console.log(this.getClassName(), "onRenderHtml", this.getPrivateDataPool().getId(), this.getPrivateDataPool());
        
        if (this._hasHtmlProcessor()) {
            console.debug(this.getClassName(), "onRenderHtml()", "HtmlProcessors found:", this.m_arrIdDomHtmlProcessor.length);
            this.useHtmlProcessors()?.buildAll_or_updateAllPending();
        } else {
            console.debug(this.getClassName(), "onRenderHtml()", "NO HTMLPROCESSOR");
        }
        // this.doWithHtmlProcessors("buildAll_or_updateAllPending");
    }

    shutdownHtmlProcessing() {        
        if (this._hasHtmlProcessor()) {
            console.log(this.getId(), "shutdownHtmlProcessing");
            this.useHtmlProcessors()?.shutdown();
        }   
        // this.doWithHtmlProcessors("shutdown");
    }
    
    _isFitting(szStr, szSingleStr_or_StrArray) {
        if (!szSingleStr_or_StrArray) {
            return(true);
        }
        
        if (Array.isArray(szSingleStr_or_StrArray)) {
            for (var i=0; i < szSingleStr_or_StrArray.length; ++i) {                
                if (szSingleStr_or_StrArray[i] === szStr) {
                    return(true);
                }
            }
        } else {
            return(szSingleStr_or_StrArray === szStr);
        }
        
        return(false);
    }
    
    broadcastEventReport(szEventSubType) {        
        // the trigger is always informed!
        this.sendQryProcessorEvent(this.m_triggering_qryProcessor, szEventSubType);
                
        // the specific requests for report are processed
        this.m_smapRequestForReport.forEach((rfr) => {               
            if (this.m_triggering_qryProcessor !== rfr.eventTarget_reportRecipient) {
                if (this._isFitting(szEventSubType, rfr.szOptional_SingleEventSubType_or_TypeArray)) {
                    var bSend = true;
                    if (rfr.szOptional_SingleId_or_IdArray !== null && rfr.szOptional_SingleId_or_IdArray !== undefined) {
                        bSend = false;
                        this.getPrivateDataPool().getDataMap().filter((entry) => {
                            return(entry["pending_broadcast"] === "y" && this._isFitting(entry["id"], rfr.szOptional_SingleId_or_IdArray));
                        }).forEach((entry) => {
                                // ok, a broadcast shall be done!
                                bSend = true;                                
                                // console.log(this.getClassName(), "CHECK_RFR", "FITTING", entry["id"]);
                            });                        
                    }
                    
                    if (bSend)  {
                        this.sendQryProcessorEvent(rfr.eventTarget_reportRecipient, szEventSubType);                        
                    }                    
                }
            }
        });    
        
        // console.log(this.constructor.name, "broadcastEventReport", szEventSubType);

        // send info to workflowControl ... always
        this.sendQryProcessorEvent(this.getWfCtrl(), szEventSubType);
        
        // *********************************
        // **** MARK BROADCASTS as DONE ****
        // *********************************
        if (szEventSubType === qryProcessor.EVENT_subType_reportOnRunCompleted) {
            this.getPrivateDataPool().getDataMap().filter((entry) => {
                return(entry["pending_broadcast"] === "y");
            }).forEach((entry) => {
                entry["pending_broadcast"] = "n";
            });     
        }
        // *********************************
    }

    // return true/false 
    hasChildQP(RegExp_Filter) {
        return(!!this.getSingleChildQP(RegExp_Filter));
    }

    // return the FIRST fitting ChildQP
    getSingleChildQP(RegExp_Filter) {                    
        var iter = this.m_smapChildQP.values();
        var result = iter.next();

        while (!result.done) {                            
            var qryP = result.value;
            if (!!qryP.getId().match(RegExp_Filter)) {
                return(qryP);
            }
            result = iter.next();                
        }        
        return(undefined);
    }

    // returns an ARRAY of all fitting ChildQPs
    getChildQPs(RegExp_Filter) {
        var arrFound = [];
        this.m_smapChildQP.filter((qryP) => {
            return(!!qryP.getId().match(RegExp_Filter));
        }).forEach((entry) => {
            arrFound.push(entry);
        });        
        // console.log("getChildQP_Messages", "return found!", arrFound);
        return(arrFound);     
    }

    // Adds an intantiated queryProcessor and connects it to "this" one (parent-queryProcessor)
    // A "request for report" is automatically added.
    addChildQP(childQueryProcessor) {
        if (!this.m_smapChildQP.has(childQueryProcessor.getId())) {
            // console.log(this.getClassName(), "addChildQP", childQueryProcessor.getId());
            this.m_smapChildQP.set(childQueryProcessor.getId(), childQueryProcessor);
            childQueryProcessor.addRequestForReport(this.getId(), this);
        } else {
            console.log(this.getClassName(), "addChildQP", "subQueryProcessor not added! already existing!", childQueryProcessor);
        }
    }

    removeChildQPs(RegExp_Filter) {
        var arrGefId = this.getChildQPs(RegExp_Filter);
        arrGefId.forEach((szId) => { this.removeChildQP(szId); });
    }

    removeAllChildQPs() {
        console.log(this.getClassName(), "removeAllChildQPs");
        while (!this.m_smapChildQP.isEmpty()) {            
            var id_entry = this.m_smapChildQP.getFirstIdEntry();            
            this.removeChildQP(id_entry);
        }
    }

    removeChildQP(childQueryProcessorId_or_childQueryProcessor) {
        var childQueryProcessorId;
        if (typeof childQueryProcessorId_or_childQueryProcessor !== "string") { 
            childQueryProcessorId = childQueryProcessorId_or_childQueryProcessor.getId();
        } else {
            childQueryProcessorId = childQueryProcessorId_or_childQueryProcessor;
        }

        console.log(this.getClassName(), "removeChildQP", "Id", childQueryProcessorId);
        var qryP = this.m_smapChildQP.get(childQueryProcessorId);
        if (!!qryP) {                        
            this.m_smapChildQP.delete(childQueryProcessorId);
            qryP.deleteRequestForReport(this.getId());  // no more reports wanted!
            qryP.destroy(); // destroy the qryProcessor finally
        }
    }
    
    addRequestForReport(szRequestId, eventTarget_reportRecipient, szOptional_SingleId_or_IdArray, szOptional_SingleEventSubType_or_TypeArray) {
        if (!this.m_smapRequestForReport.has(szRequestId)) {
            // eventTarget_reportRecipient
            // id-filter (array) or RegExp()
            var rfr = Object.assign({}, qryProcessor.REQUEST_FOR_REPORT);
            
            rfr.eventTarget_reportRecipient                 = eventTarget_reportRecipient;            
            rfr.szOptional_SingleId_or_IdArray              = szOptional_SingleId_or_IdArray;
            rfr.szOptional_SingleEventSubType_or_TypeArray  = szOptional_SingleEventSubType_or_TypeArray;
            
            this.m_smapRequestForReport.set(szRequestId, rfr); // eventTarget_reportRecipient);                    
            return(true);
        } else {
            console.log(this.getId(), "addRequestForReport", "FAILED id already existing, id", szRequestId);
            return(false);
        }
    }  
    
    deleteRequestForReport(szRequestId) {        
        if (this.m_smapRequestForReport.has(szRequestId)) {
            return(this.m_smapRequestForReport.delete(szRequestId));
        } else {
            console.log(this.getId(), "deleteRequestForReport", "FAILED, id not found", szRequestId);
        }
        return(false);
    }    
    
    // should only be called from a processorBundle-class itself
    joinBundle(qryProcessorBundle) {                  
        this.addRequestForReport(qryProcessorBundle.getId(), qryProcessorBundle);
        this.m_smapBundlesJoined.set(qryProcessorBundle.getId(), qryProcessorBundle);
    }

    /*
    leaveAllBundles() {
        var szParentId;        
        while (!this.m_smapBundlesJoined.isEmpty()) {
            szParentId = this.m_smapBundlesJoined.getFirstIdEntry();
            this.leaveBundle(szParentId);
        }
    }
    */
    
    // should only be called from a processorBundle-class itself
    leaveBundle(qryProcessorBundle_or_Id) {
        var szId;
        if (typeof qryProcessorBundle_or_Id === "string") {
            szId = qryProcessorBundle_or_Id;
        } else {
            // looks like a qryProcessor
            szId = qryProcessorBundle_or_Id.getId();
        }
        this.deleteRequestForReport(szId);        
        return(this.m_smapBundlesJoined.delete(szId));
    }

    setLastError(error) {
        this.m_LastError = error;
    }

    isError() {
        return(!!this.m_LastError);
    }

    getLastError() {
        return(this.m_LastError);
    }

    setTerminating(bTerminating) {
        if (bTerminating === undefined) bTerminating = true;
        this.m_bTerminating = !!bTerminating;   // ensure, that we have a boolean here!
    }

    isTerminating() {
        return(this.m_bTerminating);
    }
    
    isDataEmpty() {
        return(this.m_PDP.isDataEmpty());
    }
    
    isStartUpCompleted() {
        return(this.m_bStartUpCompleted);
    }
    
    getUltimateTriggerQP() {
        var qpP = this._getNextTriggerQP();
        return(qpP === this?undefined:qpP);
    }    

    // recursive call
    _getNextTriggerQP(fncPrematureStop) {
        var qpP = this.getTriggerQP();
        if (typeof fncPrematureStop === 'function') {
            if (fncPrematureStop(this, qpP)) return(this);
        }
        return(!qpP?this:qpP._getNextTriggerQP(fncPrematureStop));
    }

    getDeepTriggerQP(fncCheck) {
        var qpP = this._getNextTriggerQP(fncCheck);
        return(qpP);
    }

    getTriggerQP() {
        return(this.m_triggering_qryProcessor);
    }

    getId_ofTriggerClass() {
        if (!!this.m_triggering_qryProcessor) {
            return(this.m_triggering_qryProcessor.getId());
        } else {
            return("");
        }
    }
    
    getTriggerId() {
        return(this.m_szTriggerId);
    }

    getClassName() {
        // Standard ... if no other name was presented
        return(this.constructor.name);
    }
    
    getId() {
        return(this.getClassName() + "_" + this.m_szTriggerId);
    }
    
    getWfCtrl() {
        return(this.m_wfCtrl);
    }
    
    getDataPool() {
        return(this.m_wfCtrl.getDataPool());
    }
    
    getPrivateDataPool() {
        return(this.m_PDP);
    }
    
    init() {         
        if (this.m_objConfig.bDirectlyRunProcessQueryAfterInitialization) {
            this.privProcessQuery();
        }
    }        
    
    buildQuery() {
        console.log(this.getClassName(), "Must be overriden by deriving class!");
    }

    privOnStartUpCompleted() {
        if (!this.m_bStartUpCompleted) {
            // if we start processing queries, we are finished!
            this.m_bStartUpCompleted = true;

            // this signals, that we have an active QUERY, which will listen ...         
            this.broadcastEventReport(qryProcessor.EVENT_subType_reportStartUpCompleted);
        }
    }
    
    privProcessQuery() {
        this.processQuery();

        this.privOnStartUpCompleted();
    }
    
    processQuery() {
        console.log(this.getClassName(), "processQuery()", "must be overridden!");
    }
    
    /**
     * Teardown qryProcessor
     */
    destroy() {        
        console.log(this.getId(), "is being destroyed!");
        if (!this.m_smapBundlesJoined.isEmpty()) {
            console.log(this.getId(), "is being destroyed!", "Destruction prevented! Attached to bundle(s)!");
            return;
        }
        this.setTerminating(true);                       
        // console.log(this.getId(), "is being destroyed!", "leaveAllBundles"); 
        // this.leaveAllBundles();                 // leave all Bundles!
        console.log(this.getId(), "is being destroyed!", "removeAllChildQPs");
        this.removeAllChildQPs();   // remove all childs!
        console.log(this.getId(), "is being destroyed!", "cleanUp");
        this.cleanUp();
        console.log(this.getId(), "is being destroyed!", "Shutting down Html-processing");
        this.shutdownHtmlProcessing();
        console.log(this.getId(), "is being destroyed!", "broadcastEventReport (last)");                        
        this.broadcastEventReport(qryProcessor.EVENT_subType_reportDestroyCompleted);
    }    

    restart() {
        this.privUnsubscribe();
        this.processQuery();
    }

    privUnsubscribe() {
        if (!!this.m_subscription) {
            try {
                this.m_subscription.unsubscribe();
            } catch(error) {
                console.log(this.getId(), "privUnsubscribe", "Error", error);
            }
            this.m_subscription = undefined;
        }
    }
    
    cleanUp() {
        this.privUnsubscribe();
        this.broadcastEventReport(qryProcessor.EVENT_subType_reportCleanUpCompleted);
    }
    
   /**
    * Static Tool-function. An qryProcessor is the input.
    * @param {qryProcesor} qryP A qryProcessor-Object.
    * @returns {String[]} An String-Array containing the DocumentIds holded by the queryProcessors.
    * If qryP is "undefined" or "null" an empty String-Array is returned.
    */    
    static getDocumentIdsArray(qryP) {
        var arrIds = [];
        if (!!qryP) {
            var iter = qryP.getPrivateDataPool().getDataIdEntryIterator();
            var result = iter.next();
            while (!result.done) {
                arrIds.push(result.value);
                result = iter.next();
            }
        }
        return(arrIds);
    }

    static toId(qryProcessor_or_qryProcessorId) {        
        if (typeof qryProcessor_or_qryProcessorId === "string") {
            return(qryProcessor_or_qryProcessorId);
        } else {
            return(qryProcessor_or_qryProcessorId.getId());
        }
    }
}
qryProcessor.EVENT = "EVENT_qryProcessor";
qryProcessor.EVENT_subType_reportStartUp = "reportStartUp";
qryProcessor.EVENT_subType_reportStartUpCompleted = "reportStartUpCompleted";
qryProcessor.EVENT_subType_reportOnNewDataIncomingCompleted = "reportOnNewDataIncomingCompleted";
qryProcessor.EVENT_subType_reportOnNewDataProcessingCompleted = "reportOnNewDataProcessingCompleted";
qryProcessor.EVENT_subType_reportOnRunCompleted = "reportOnRunCompleted";
qryProcessor.EVENT_subType_reportCleanUpCompleted = "reportCleanUpCompleted";
qryProcessor.EVENT_subType_reportDestroyCompleted = "reportDestroyCompleted";
qryProcessor.REQUEST_FOR_REPORT = {
    eventTarget_reportRecipient: null,
    szOptional_SingleEventSubType_or_TypeArray: null,
    szOptional_SingleId_or_IdArray: null
};
import React from 'react';
import * as FabStd from "/app-assets/js/fabstd/fabbi_standard.js";
import ControlledReactComponent from "./fabbi_controlled_react_component.js";
import CrcInput_01 from "./fabbi_crc_input_01.jsx";
import TextField from '@material-ui/core/TextField';

export default class CrcTestModal_01 extends ControlledReactComponent {
    constructor(props) {
        super(props);

        this._ctrl = this.getWfCtrl().getFirebaseCtrl();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getIdDOM_Input() {
        return(this.getIdDOM() + "_input");
    }

    getIdDOM_Textarea() {
        return(this.getIdDOM() + "_textarea");
    }

    render() {
        return(
            <form id={this.getIdDOM()}>
                <div className={"modal-content"}>
                    <CrcInput_01 
                        id_dom={this.getIdDOM_Input()} 
                        wfCtrl={this.getWfCtrl()} 
                        InputProps={{
                            onChange:
                                (event) => { 
                                    console.log(this.constructor.name, "onChange", event.target.value);
                                    FabStd.parseDelimitedLines(
                                        document.getElementById(this.getIdDOM_Textarea()).value, 
                                        " ", 
                                        event.target.value)
                            }}
                        }
                        defaultValue='(?:\s*\"((?:\"\"|[^\"])*)\"\s*|\s*((?:\"\"|[^\" \r\n])*(?:\"\"|[^\s \r\n]))?\s*)( |[\r\n]+|$)'
                        placeholder="TEST"
                        type="text"
                        required
                        />  
                    
                    <TextField 
                        id={ this.getIdDOM_Textarea() } 
                        multiline 
                        fullWidth 
                        rowsMax={10} 
                        label="jobCode" 
                        variant="filled"
                        onChange={(event) => { 
                            var szValue = event.target.value;
                        }}
                        />
                </div>
            </form>  
        );
    }
}